export function normalizeSearchString(str: string): string {
  return str
    .toLowerCase()
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "");
}

export function fuzzyMatch(query: string, value: string): boolean {
  const normalizedQuery = normalizeSearchString(query);
  const normalizedValue = normalizeSearchString(value);
  return normalizedValue.includes(normalizedQuery);
}
