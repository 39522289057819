import React from "react";
import Style from "style";

interface ILabeledTextProps {
  fontSize?: string;
  color?: string;
  text: string | React.ReactElement;
  label: string;
}
export default function LabeledText({
  fontSize = "4rem",
  color,
  text,
  label,
}: ILabeledTextProps): React.ReactElement {
  const style: React.CSSProperties = {
    display: "flex",
    flex: "1 1 auto",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignContent: "center",
    textAlign: "center" as const,
  };

  const textSectionStyle = {};

  const textStyle = {
    color: color || Style.colors.primaryFg,
    fontSize: fontSize,
  };

  const labelStyle = {
    color: Style.colors.mutedFg,
    fontSize: "0.75rem",
    fontWeight: 300,
  };

  return (
    <div style={style}>
      <div style={textSectionStyle}>
        <span style={textStyle}>{text}</span>
      </div>
      <div style={labelStyle}>{label}</div>
    </div>
  );
}
