import { IEntry } from "./index";

export default class Trie {
  content: Record<string, Record<string, any>> = {};
  getKeyObject(key: string, create = false): Record<string, any> {
    const chars = key === "" ? [key] : Array.from(key);
    let obj = this.content;

    for (let i = 0; i < chars.length; i++) {
      if (!obj[chars[i]])
        if (create) {
          obj[chars[i]] = {};
        } else {
          return { values: [] };
        }
      obj = obj[chars[i]];
    }
    return obj;
  }

  get(key: string): IEntry[] {
    const obj = this.getKeyObject(key);
    return obj.values || [];
  }

  getPrefix(key: string): IEntry[] {
    const inner = (key: string, obj?: Record<string, any>) => {
      if (!obj) {
        obj = this.getKeyObject(key);
      }
      const result = obj.values ? [...obj.values] : [];
      for (const char in obj) {
        if (char === "values" || !obj[char]) {
          continue;
        }
        result.push(...inner(key + char, obj[char]));
      }
      return result;
    };
    return inner(key);
  }

  push(key: string, value: IEntry): void {
    const obj = this.getKeyObject(key, true);

    if (!obj.values) {
      obj.values = [];
    }
    if (!obj.values.includes(value)) {
      obj.values.push(value);
    }
  }
}
