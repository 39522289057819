import L10n from "localization";
import React from "react";
import Globals from "globals";
import Device from "device";
import { helpCircleOutline } from "ionicons/icons";
import { IonButton, IonIcon } from "@ionic/react";

export default function Support(): JSX.Element {
  return (
    <IonButton
      fill="clear"
      onClick={() => {
        Device.openExternalLink(Globals.helpEndpoint);
      }}
    >
      <IonIcon slot="start" icon={helpCircleOutline} />
      {L10n.localize((s) => s.general.help)}
    </IonButton>
  );
}
