import { Capacitor } from "@capacitor/core";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import logEvent from "analytics";
import Device from "device";
import EventBus from "eventBus";
import { History } from "history";
import L10n from "localization";
import Network from "network";
import React from "react";
import { useHistory } from "react-router-dom";

const groupIdKey = "group_id";

export function usePushNotifPermissionRequest(): void {
  React.useEffect(() => {
    PushNotifs.init(true);
  }, []);
}

class PushNotifController {
  private history?: History;

  setHistory(h: History) {
    this.history = h;
  }

  async init(requestIfNecessary = false): Promise<void> {
    if (!Capacitor.isPluginAvailable("PushNotifications")) {
      return;
    }

    if (!Device.hasToken()) {
      return;
    }

    try {
      const permission = await PushNotifications.checkPermissions();
      switch (permission.receive) {
        case "denied":
          return;
        case "granted":
          break;
        case "prompt":
        case "prompt-with-rationale": {
          if (requestIfNecessary === false) {
            return;
          }
          const ok = confirm(L10n.localize((s) => s.pushNotifs.permissionRequestMessage));
          if (!ok) {
            return;
          }
        }
      }
    } catch {
      return;
    }

    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === "granted") {
        PushNotifications.register();
      } else {
        // TODO: handle failure.
      }
    });

    await PushNotifications.removeAllListeners();

    PushNotifications.addListener("registration", (token: Token) => {
      Network.fetch("PATCH", `/users/devices/${Device.id}`, { push_token: token.value })
        .then(() => {
          // Good.
        })
        .catch(() => {
          // :(
        });
    });

    PushNotifications.addListener("registrationError", (error: any) => {
      // TODO: handle failure.
    });

    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification: PushNotificationSchema) => {
        // Notification received while app was open.
        logEvent("received_push_notif");

        const groupID = notification.data[groupIdKey];
        if (groupID) {
          EventBus.emit("studyGroupPushNotifReceived", { groupID });
          const ok = confirm(notification.body);
          if (ok) {
            this.history?.push(`/groups/${groupID}`);
          }
        }
      },
    );

    PushNotifications.addListener("pushNotificationActionPerformed", (action: ActionPerformed) => {
      // User launched app by tapping notification.
      logEvent("tapped_push_notif");

      const groupID = action.notification.data[groupIdKey];
      if (groupID) {
        EventBus.emit("studyGroupPushNotifReceived", { groupID });
        this.history?.push(`/groups/${groupID}`);
      }
    });
  }
}

const PushNotifs = new PushNotifController();

// PushNotifHandler is just here to get a handle to the history object and inject it into PushNotifs.
export function PushNotifHandler(): null {
  const history = useHistory();

  React.useEffect(() => {
    PushNotifs.setHistory(history);
  }, [history]);

  return null;
}

export default PushNotifs;
