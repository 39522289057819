import { showPrompt } from "@components/prompt";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonLabel,
} from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { Operation } from "@models/operation";
import { IDeckUpdateOperation } from "@operations/deck";
import DeckEditScreen from "@screens/deckEdit";
import GradeHistogram from "components/gradeHistogram";
import VSplit from "components/vsplit";
import { useDeckFields } from "fields/hooks/useDeckFields";
import { defaultFields } from "fields/lib";
import { MAGIC_LAYOUT_ID, magicLayoutFor } from "fields/magicLayout";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { useIsPhone } from "hooks/util/useMediaQuery";
import { warningOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";
import HistorySection from "screens/deck/history";
import { footerHeightPx } from "style";
import Style from "style";
import * as SwiperJS from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { ID } from "types/ID";
import CardsSection from "./cardsSection";
import GradeSection from "./gradeSection";
import HistoryResponses from "./historyResponses";
import HistoryStats from "./historyStats";
import SettingsSection from "./settingsSection";

interface IProps {
  isDownloading?: boolean;
  handleDismissCardCreateModal: () => void;
  displayCount: number;
  deck?: IDeck;
  knols?: MapView<ID, IKnol>;
  filteredKnols: MapView<ID, IKnol>;
  knolsLastUpdated?: Date;
  loading: boolean;
}

export default function DeckScreenInfoSection(props: IProps): JSX.Element | null {
  const {
    deck,
    knols,
    filteredKnols,
    isDownloading,
    handleDismissCardCreateModal,
    knolsLastUpdated,
  } = props;

  const onDismissModal = () => {};
  const dfs = React.useMemo(() => defaultFields(), []);
  dfs[0].name = "front";
  dfs[1].name = "back";
  const defaultConfig = React.useMemo(() => ({ fields: dfs }), [dfs]);

  const [presentDeckEditModal] = useIonCardModalWithDismiss(DeckEditScreen, {
    deck: deck,
    onDismiss: onDismissModal,
  });

  const deckFields = useDeckFields(deck);

  const isPhone = useIsPhone();

  let grades = (
    <>
      <GradeSection knols={knols?.values()} />
      <Swiper
        className="history-swiper"
        modules={[SwiperJS.Keyboard, SwiperJS.Pagination, SwiperJS.Navigation]}
        pagination={{ el: ".swiper-custom-pagination" }}
        navigation
        height={250}
        style={{ height: "250px" }}
        keyboard={true}
      >
        <SwiperSlide key={0}>
          <GradeHistogram
            deck={deck}
            knols={knols?.values()}
            filteredKnols={filteredKnols?.values()}
            height={"9rem"}
            selectedGrades={deck?.user_config?.settings?.reviewGrades}
          />
        </SwiperSlide>
        <SwiperSlide key={1}>
          <HistoryResponses
            deckID={deck?.id}
            knolsLastUpdated={knolsLastUpdated}
            widescreenChartHeight={"7.6rem"}
          />
        </SwiperSlide>
        <SwiperSlide key={2}>
          <HistoryStats deck={deck} knolsLastUpdated={knolsLastUpdated} />
        </SwiperSlide>
      </Swiper>
      <div className="swiper-custom-pagination" />
      <div style={{ marginLeft: Style.edgePadding, marginRight: Style.edgePadding }} />
    </>
  );

  let top = grades;
  if (!isPhone) {
    grades = (
      <>
        <GradeSection knols={filteredKnols?.values()} />
        <div
          style={{
            marginLeft: Style.edgePadding,
            marginRight: Style.edgePadding,
          }}
        >
          <GradeHistogram
            deck={deck}
            knols={knols?.values()}
            filteredKnols={filteredKnols?.values()}
            height={"7.6rem"}
            selectedGrades={deck?.user_config?.settings?.reviewGrades}
          />
        </div>
      </>
    );
    const history = (
      <HistorySection
        knolsLastUpdated={knolsLastUpdated}
        deck={deck}
        widescreenChartHeight={"6.5rem"}
      />
    );
    top = <VSplit left={grades} right={history} leftBasis="50%" />;
  }

  const isEligbleForUpgrade =
    deck?.status &&
    deck.status === 1 &&
    deck.layouts?.length === 2 &&
    ((deck.layouts[0].id === "b38854d62caf44d3bb36a8d9609b004e" &&
      deck.layouts[1].id === "61d97f8c245f46f0a91950e4f4d60ec5") ||
      (deck.layouts[0].id === "acf3bd5e3fc94d64a9b21d7a531a6563" &&
        deck.layouts[1].id === "a4abf3245c0340c39e43216fcd714dfc"));

  const handleUpgradeDeck = () => {
    if (!deck || !isEligbleForUpgrade) {
      return;
    }
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.deck.confirmUpgrade),
      promptType: "confirm",
      callback: () => {
        const op: IDeckUpdateOperation = {
          ...Operation.operationDefaults(),
          type: "UPDATE",
          object_type: "deck",
          object_parameters: {
            id: deck.id,
            name: deck.name,
            description: deck.description,
            layout_id: MAGIC_LAYOUT_ID,
            layouts: [magicLayoutFor(defaultConfig)],
            config: defaultConfig,
          },
        };
        Operation.operateAndSave(op);
      },
    });
  };

  return (
    <div style={{ paddingBottom: footerHeightPx }}>
      {props.loading || knols?.size ? top : undefined}
      {isEligbleForUpgrade && (
        <IonCard button color="warning" onClick={handleUpgradeDeck}>
          <IonCardHeader>
            <IonCardTitle style={{ display: "flex", alignItems: "center" }}>
              <IonIcon icon={warningOutline} style={{ minWidth: "48px", marginRight: 8 }} />
              <IonLabel>{L10n.localize((s) => s.deck.canUpgrade)}</IonLabel>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>{L10n.localize((s) => s.deck.canUpgradeBody)}</IonCardContent>
        </IonCard>
      )}
      {deckFields && deckFields.length < 1 ? (
        <IonCard button color="danger" onClick={presentDeckEditModal}>
          <IonCardHeader>
            <IonCardTitle style={{ display: "flex", alignItems: "center" }}>
              <IonIcon icon={warningOutline} style={{ marginRight: 8 }} />
              <IonLabel>{L10n.localize((s) => s.deck.missingFieldsDetectedHeader)}</IonLabel>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>{L10n.localize((s) => s.deck.missingFieldsDetectedBody)}</IonCardContent>
        </IonCard>
      ) : (
        <CardsSection
          loading={props.loading}
          deck={deck}
          knols={knols}
          filteredKnols={props.filteredKnols}
          isDownloading={isDownloading}
          handleDismissCardCreateModal={handleDismissCardCreateModal}
        />
      )}
      <SettingsSection
        deck={deck}
        knols={knols}
        knolsLastUpdated={knolsLastUpdated}
        numTotalCards={knols?.size ?? 0}
        numFilteredCards={props.filteredKnols.size}
      />
    </div>
  );
}
