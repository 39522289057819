import Clickable from "components/clickable";
import React from "react";
import Style from "style";

interface IProps {
  border?: boolean;
  fontSize?: string;
  iconPadding?: string;
  iconRight?: boolean;
  iconStyle?: React.CSSProperties;
  preventUnfocus?: boolean;
  fill?: boolean;
  justify?: string;
  dangerous?: boolean;
  disabled?: boolean;
  icon?: string;
  text?: any;
  className?: string;
  onClick?: () => void;
  url?: string;
  children?: JSX.Element;
  bold?: boolean;
  color?: string;
  borderWidth?: string;
  borderRadius?: string;
}
export default function Button(props: IProps): JSX.Element {
  const {
    border = false,
    fontSize = null,
    iconPadding = "initial",
    iconRight = false,
    iconStyle = {},
    preventUnfocus = false,
    color = Style.colors.actionableFg,
    borderWidth = "1px",
    borderRadius = "4px",
  } = props;

  const style: React.CSSProperties = {
    alignItems: "center",
    color: color,
    display: "flex",
    minHeight: "42px",
    minWidth: "48px",
    cursor: "pointer",
  };

  if (props.fill) {
    style.height = "100%";
    style.width = "100%";
  }

  if (props.justify === "left") {
    style.paddingLeft = "1rem";
    style.paddingRight = "0";
    style.justifyContent = "flex-start";
  } else if (props.justify === "right") {
    style.paddingRight = "1rem";
    style.paddingLeft = "0";
    style.justifyContent = "flex-end";
  } else {
    style.padding = "0 1rem";
    style.justifyContent = "center";
  }

  if (props.dangerous) {
    style.color = Style.colors.dangerousFg;
  }

  if (props.disabled) {
    style.cursor = "default";
    style.color = Style.colors.disabledFg;
  }

  if (border) {
    style.border = `${borderWidth} solid ${color}`;
    style.borderRadius = borderRadius;
  }

  const content: JSX.Element[] = [];
  if (props.icon !== null) {
    const s = { fontSize: "1.8rem", padding: iconPadding };
    content.push(<i className={props.icon} key="button-icon" style={{ ...s, ...iconStyle }} />);
  }
  if (props.text) {
    const t = (
      <span
        key="button-text"
        style={{
          fontSize: fontSize ?? 20,
          marginLeft: props.icon ? "1rem" : undefined,
          fontWeight: props.bold ? 600 : undefined,
        }}
      >
        {props.text}
      </span>
    );
    if (iconRight) {
      content.unshift(t);
    } else {
      content.push(t);
    }
  }
  if (props.icon && props.text && !props.justify) {
    style.justifyContent = "center";
  }

  const { children } = props;

  return (
    <Clickable
      className={props.className}
      disabled={props.disabled}
      onClick={props.onClick}
      preventUnfocus={preventUnfocus}
      style={style}
      url={props.url}
    >
      {children ?? content}
    </Clickable>
  );
}
