import NestedNav from "@components/nestedNav";
import {
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  RadioGroupChangeEventDetail,
  RadioGroupCustomEvent,
} from "@ionic/react";
import React from "react";

export interface IRadioSelectOption<T> {
  name: string;
  val: T;
  desc?: string;
}

interface IProps<T> {
  title: string;
  options: IRadioSelectOption<T>[];
  selectedItemVal: string;
  onChange: (val: T, ev: RadioGroupCustomEvent<RadioGroupChangeEventDetail<any>>) => void;
}

export default function RadioSelectSettingNav<T extends string>(props: IProps<T>): JSX.Element {
  const { options, selectedItemVal, title, onChange } = props;

  return (
    <NestedNav title={title}>
      <IonList>
        <IonRadioGroup
          value={selectedItemVal}
          onIonChange={(e: RadioGroupCustomEvent) => onChange(e.detail.value, e)}
        >
          {options.map(({ name, val, desc }) => {
            return (
              <IonItem key={val}>
                <IonRadio justify="space-between" value={val}>
                  <IonLabel className="ion-text-wrap">
                    {name}
                    {desc ? <p>{desc}</p> : undefined}
                  </IonLabel>
                </IonRadio>
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
    </NestedNav>
  );
}
