import idb, { IFolderRow } from "@data/idb";
import { filterIterable } from "@lib/iterable";
import { Deck, IDeck } from "@models/deck";
import { IDeckSettings } from "@models/deckSettings";
import { IKnol, Knol } from "@models/knol";
import { descendsFrom } from "@screens/folder/overview";
import EventBus from "eventBus";
import { ID } from "types/ID";
import Events from "../../../../node_modules/eventemitter3/index";

type CacheEventName = "knolUpdated" | "deckUpdated" | "deckMoved" | "folderUpdated";
class SuperCache {
  events: Events<CacheEventName>;
  decks: Map<ID, IDeck> | undefined;
  folders: Map<string, IFolderRow> | undefined;
  knols: Map<ID, IKnol> | undefined;
  deckIDToKnolIDs: Map<ID, Set<ID>> | undefined;

  constructor() {
    this.events = new Events();
  }

  // private emit<E extends CacheEventName>(name: E, event: IEvents[E]) {
  //   this.events.emit(name, event);
  // }

  async init() {
    this.decks = new Map();
    this.deckIDToKnolIDs = new Map();
    const decks = await idb.db.getAll("decks");
    for (const deck of decks) {
      this.decks.set(deck.id, deck);
      this.deckIDToKnolIDs.set(deck.id, new Set());
    }

    this.folders = new Map();
    await this.loadFolders();

    this.knols = new Map();
    await this.loadKnols();

    EventBus.on("deckAdded", this.deckAddedHandler);
    EventBus.on("deckDeleted", this.decksRemovedHandler);
    EventBus.on("deckGradesReset", this.deckGradesResetHandler);
    EventBus.on("deckMoved", this.deckMovedHandler);
    EventBus.on("deckRemoved", this.decksRemovedHandler);
    EventBus.on("deckUpdated", this.deckUpdatedHandler);
    EventBus.on("knolUpdated", this.knolUpdatedHandler);
    EventBus.on("folderCreated", this.loadFolders);
    EventBus.on("folderDeleted", this.folderUpdateHandler);
    EventBus.on("folderUpdated", this.folderUpdateHandler);
    EventBus.on("folderRenamed", this.folderRenamedHandler);

    for (const deck of decks) {
      this.events.emit("deckUpdated", { ID: deck.id });
    }
  }

  clear() {
    this.decks?.clear();
    this.deckIDToKnolIDs?.clear();
    this.decks?.clear();
    this.folders?.clear();
    this.knols?.clear();
    this.events.emit("folderUpdated", { name: "" });
  }

  private loadFolders = async () => {
    const folders = await idb.db.getAll("folders");
    for (const folder of folders) {
      this.folders?.set(folder.name, folder);
    }
    this.events.emit("folderUpdated");
  };

  private decksRemovedHandler = async (opts: { deckIDs: string[] }) => {
    for (const id of opts.deckIDs) {
      await this.deckUpdatedHandler({ ID: id });
    }
  };

  private deckGradesResetHandler = async (opts: { ID: string }) => {
    await this.loadKnols(opts.ID);
    this.events.emit("knolUpdated", { deckID: opts.ID });
  };

  private loadKnols = async (deckID?: ID) => {
    const tx = idb.db.transaction(["knols"], "readonly");
    const store = tx.objectStore("knols");
    const idx = store.index("deck_id");
    const query = deckID ? IDBKeyRange.only(deckID) : undefined;
    for await (const cursor of idx.iterate(query)) {
      const knol = cursor.value;
      if (deckID && knol.deck_id !== deckID) {
        // Since the index is in place, this shouldn't be reached. Belt and suspenders.
        continue;
      }
      this.knols?.set(knol.id, knol);
      this.deckIDToKnolIDs?.get(knol.deck_id)?.add(knol.id);
    }
    await tx.done;
  };

  private deckMovedHandler = async (opts: { ID: string; folder?: string }) => {
    await this.deckUpdatedHandler({ ID: opts.ID });
    this.events.emit("deckMoved");
  };

  private folderUpdateHandler = async (opts: { name: string }) => {
    const folder = await idb.db.get("folders", opts.name);
    if (folder) {
      this.folders?.set(folder.name, folder);
    } else {
      this.folders?.delete(opts.name);
    }
    this.events.emit("folderUpdated", { name: opts.name });
  };

  private folderRenamedHandler = async (opts: { tag_name_prev: string; tag_name: string }) => {
    const folder = await idb.db.get("folders", opts.tag_name);
    this.folders?.delete(opts.tag_name_prev);
    if (folder) {
      this.folders?.set(folder.name, folder);
    }
    this.events.emit("folderUpdated", { name: opts.tag_name });
  };

  private deckAddedHandler = async (opts: { ID: string }) => {
    const deck = await idb.db.get("decks", opts.ID);
    if (deck) {
      this.decks?.set(deck.id, deck);
      this.deckIDToKnolIDs?.set(deck.id, new Set());
      await this.loadKnols(deck.id);
      this.events.emit("deckUpdated", { ID: deck.id });
    }
  };

  private deckUpdatedHandler = async (opts: { ID: string }) => {
    const deck = await idb.db.get("decks", opts.ID);
    if (deck) {
      this.decks?.set(deck.id, deck);
      if (!this.deckIDToKnolIDs?.has(deck.id)) {
        this.deckIDToKnolIDs?.set(deck.id, new Set());
      }
      if (Deck.isArchived(deck)) {
        // Clear its knols.
        for (const knolID of this?.deckIDToKnolIDs?.get(opts.ID) ?? []) {
          this?.knols?.delete(knolID);
        }
      }
      this.events.emit("deckUpdated", { ID: deck.id });
    } else {
      // Delete it.
      this.decks?.delete(opts.ID);
      for (const knolID of this?.deckIDToKnolIDs?.get(opts.ID) ?? []) {
        this?.knols?.delete(knolID);
      }
      this.deckIDToKnolIDs?.delete(opts.ID);
      this.events.emit("deckUpdated", { ID: opts.ID });
    }
  };

  private knolUpdatedHandler = async (event: { knolID: ID; deckID: ID }) => {
    const deck = this.decks?.get(event.deckID);
    if (deck) {
      const newKnol = await Knol.WithGrade(event.knolID, deck.layout_id);
      if (newKnol) {
        this.deckIDToKnolIDs?.get(event.deckID)?.add(event.knolID);
        this.knols?.set(event.knolID, newKnol as IKnol);
      } else {
        this.deckIDToKnolIDs?.get(event.deckID)?.delete(event.knolID);
        this.knols?.delete(event.knolID);
      }
      this.events.emit("knolUpdated", event);
    }
  };

  filteredKnolIDsFor(
    knolIDs: Iterable<ID>,
    settings: IDeckSettings,
    excludeIgnored = true,
    sort = false,
  ): Set<ID> {
    let filtered: Iterable<ID> = knolIDs;

    const reviewGrades = settings?.reviewGrades;
    if (reviewGrades && reviewGrades.length > 0) {
      filtered = filterIterable(filtered, (knolID) => {
        const knol = this.knols?.get(knolID);
        if (!knol) {
          return false;
        }
        const grade = Knol.GetGrade(knol);
        return Knol.isGradeInSet(grade, reviewGrades);
      });
    }

    if (settings?.reviewTags?.length) {
      const tagSet = new Set(settings.reviewTags);
      filtered = filterIterable(filtered, (knolID) => {
        const knol = this.knols?.get(knolID);
        return knol?.tags?.some((tag) => tagSet.has(tag));
      });
    }

    // Remove ignored cards.
    if (excludeIgnored) {
      filtered = filterIterable(filtered, (knolID) => {
        const knol = this.knols?.get(knolID);
        const ignored = knol?.tags?.some((tag) => tag === Knol.IGNORE_TAG);
        return !ignored;
      });
    }

    if (sort) {
      const filteredKnolIDs: ID[] = [];
      for (const id of filtered) {
        filteredKnolIDs.push(id);
      }
      if (settings?.sortOrder) {
        const sorter = Deck.sortKnols(settings.sortOrder);
        filteredKnolIDs.sort((i1, i2) => {
          const [k1, k2] = [this.knols?.get(i1), this.knols?.get(i2)];
          if (!k1 || !k2) {
            // Shouldn't happen.
            return 0;
          }
          return sorter(k1, k2);
        });
      }
      return new Set(filteredKnolIDs);
    }

    const filteredKnolIDs = new Set<ID>();
    for (const id of filtered) {
      filteredKnolIDs.add(id);
    }
    return filteredKnolIDs;
  }

  decksInFolder(name: string): Set<ID> | undefined {
    if (!this.decks) {
      return undefined;
    }
    const deckIDs = new Set<ID>();
    for (const [id, deck] of this.decks) {
      if (descendsFrom(deck, name) && !Deck.isArchived(deck)) {
        deckIDs.add(id);
      }
    }
    return deckIDs;
  }
}
export const superCache = new SuperCache();
