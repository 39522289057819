import { CheckboxCustomEvent, IonCard, IonCheckbox } from "@ionic/react";
import React from "react";
import { LuAlertTriangle } from "react-icons/lu";
import Style from "style";
import { IGeneratedRow } from "./lib";

interface IProps {
  genIndex: number;
  rows: IGeneratedRow[];
  columns: string[];
  updateRow: (genIndex: number, rowIndex: number, row: IGeneratedRow) => void;
}
export default function GenTable({ genIndex, rows, columns, updateRow }: IProps) {
  return (
    <IonCard style={{ width: "calc(100% - 24px)", marginBottom: 16 }}>
      <div className="gen-table-holder">
        <table>
          <thead>
            <tr>
              <td key="checkbox" style={{ padding: 0 }} />
              {columns?.map((col) => {
                const numCols = columns.length;
                const width = `${Math.round((1.0 / numCols) * 100)}%`;
                return (
                  <td key={col} className="content-col" style={{ width }}>
                    {col}
                  </td>
                );
              })}
              <td key="duplicate" style={{ padding: 0 }} />
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => {
              return (
                <tr key={i} style={{ color: row.enabled ? undefined : Style.colors.disabledFg }}>
                  <td key="checkbox" style={{ padding: 0 }}>
                    <IonCheckbox
                      checked={row.enabled}
                      onIonChange={(e: CheckboxCustomEvent) =>
                        updateRow(genIndex, i, { ...row, enabled: e.detail.checked })
                      }
                    />
                  </td>
                  {columns?.map((col) => {
                    const val = row.values[col];
                    return (
                      <td key={col} className="content-col">
                        <textarea
                          value={val}
                          disabled={row.enabled === false}
                          onChange={(e) =>
                            updateRow(genIndex, i, {
                              ...row,
                              values: { ...row.values, [col]: e.target.value },
                            })
                          }
                        />
                      </td>
                    );
                  })}
                  <td key="duplicate" style={{ padding: 0 }}>
                    {row.duplicate ? <LuAlertTriangle size={24} /> : undefined}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </IonCard>
  );
}
