import {
  IonItemDivider,
  IonItemSliding,
  IonReorderGroup,
  ItemReorderEventDetail,
} from "@ionic/react";
import { DeckFields } from "@models/deck";
import { IField } from "fields/fields";
import { FieldsMutation } from "fields/hooks/useFieldsReducer";
import { SIDES, SideVisibility } from "fields/sides";
import L10n from "localization";
import React from "react";
import EditableFieldListItem from "./editableFieldListItem";

interface IProps {
  disabled?: boolean;
  fields: DeckFields;
  dispatch: React.Dispatch<FieldsMutation>;
  isExistingDeck?: boolean;
}
export default function ReorderableGroupedFieldItems(props: IProps): JSX.Element {
  const { fields, dispatch, isExistingDeck, disabled } = props;

  const initialItems = ["FRONT_DIVIDER", "BACK_DIVIDER"] as (string | IField)[];

  const itemsArray = [...initialItems];
  let lastBothIndex = -1;
  let lastFrontIndex = 0;
  let lastBackIndex = 1;

  for (const field of fields) {
    if (field.sides[0] && field.sides[1]) {
      itemsArray.splice(lastBothIndex + 1, 0, field);
      lastBothIndex++;
      lastFrontIndex++;
      lastBackIndex++;
    } else if (field.sides[0] && !field.sides[1]) {
      itemsArray.splice(lastFrontIndex + 1, 0, field);
      lastFrontIndex++;
      lastBackIndex++;
    } else if (!field.sides[0] && field.sides[1]) {
      itemsArray.splice(lastBackIndex + 1, 0, field);
      lastBackIndex++;
    }
  }

  const groupMap = {
    BOTH_DIVIDER: L10n.localize((s) => s.general.bothSides),
    FRONT_DIVIDER: L10n.localize((s) => s.general.frontSide),
    BACK_DIVIDER: L10n.localize((s) => s.general.backSide),
  };

  function handleReorder(event: CustomEvent<ItemReorderEventDetail>) {
    const { from, to } = event.detail;

    // Clone the items within the array.
    const items = itemsArray.map((item) => {
      if (typeof item === "string") {
        return item;
      }
      return { ...item };
    });

    const [itemMoving] = items.splice(from, 1);
    items.splice(Math.max(to, 1), 0, itemMoving);

    if (typeof itemMoving !== "string") {
      // Check that the item is not a divider.
      const newSides = determineNewSides(to, items);
      itemMoving.sides = newSides;
      // Instead of dispatching a single update such as:
      // dispatch?.({ type: "modify", fieldName: itemMoving.name, field: itemMoving });
      // We do a total replace so that the side update AND the position in the
      // total fields index is updated appropriately. We filter out
      // the dividers so they aren't included in the update.
      dispatch({
        type: "load",
        fields: items.filter((item) => typeof item !== "string") as DeckFields,
      });
    }
    event.preventDefault();
    event.stopImmediatePropagation();
    event.detail.complete();
  }

  function determineNewSides(to: number, itemsArray: (string | IField)[]): SideVisibility {
    const frontDividerIndex = itemsArray.indexOf("FRONT_DIVIDER");
    const backDividerIndex = itemsArray.indexOf("BACK_DIVIDER");

    if (to <= frontDividerIndex) {
      return SIDES.BOTH;
    }
    if (to >= 0 && to <= backDividerIndex) {
      return SIDES.FRONT;
    }
    if (to >= backDividerIndex) {
      return SIDES.BACK;
    }
    return SIDES.BACK;
  }

  // We render the BOTH_DIVIDER first so that it cannot be moved down
  // by reordering items above it. Since it is not in the indices of
  // the item array, we take this into account when handling reorder.
  return (
    <>
      <IonItemSliding key="BOTH_DIVIDER">
        <IonItemDivider
          style={{ margin: "4px 0px", borderRadius: "4px", border: "1px solid #DDD" }}
        >
          {groupMap.BOTH_DIVIDER}
        </IonItemDivider>
      </IonItemSliding>
      <IonReorderGroup onIonItemReorder={handleReorder} disabled={disabled}>
        {itemsArray.map((i) => {
          if (typeof i === "string") {
            return (
              <IonItemSliding key={i}>
                <IonItemDivider
                  style={{ margin: "4px 0px", borderRadius: "4px", border: "1px solid #DDD" }}
                >
                  {groupMap[i as keyof typeof groupMap]}
                </IonItemDivider>
              </IonItemSliding>
            );
          }
          return (
            <EditableFieldListItem
              key={i.name}
              field={i}
              disabled={disabled}
              fields={fields}
              dispatch={dispatch}
              isExistingDeck={isExistingDeck}
            />
          );
        })}
      </IonReorderGroup>
    </>
  );
}
