import { fieldMap } from "@models/deck";
import { FieldAttrs, IFieldEditorProps } from "fields/fieldType";
import { isBFF69, ttsLanguages } from "fields/lang";
import { fieldLangFollowingRef } from "fields/lib";
import { hasFieldRef } from "fields/sources/lib";
import { IFieldTTS } from "fields/types/tts";
import { SSMLGender, isSSMLGender } from "lib/tts";
import React from "react";
import { defaultCardFontSize } from "settings";
import FieldEditorGeneric from "./fieldEditorGeneric";
import FontSizeSelectorItem from "./fontSizeSelectorItem";
import { LanguageSelectorItem } from "./languageSelectorItem";
import VoiceSelectorItem from "./voiceSelectorItem";

export default function FieldEditorTTS({
  fields,
  field,
  onChange,
  creatingConfig,
}: IFieldEditorProps<IFieldTTS>): JSX.Element {
  const fontSizePx = field.attributes?.fontSizePx ?? defaultCardFontSize;

  type Attrs = FieldAttrs<IFieldTTS>;

  function changeAttr<A extends keyof Attrs>(key: A, val: Attrs[A]) {
    onChange({
      ...field,
      attributes: {
        ...field.attributes,
        [key]: val,
      },
    });
  }

  function handleFontSizeChange(px: number) {
    changeAttr("fontSizePx", px);
  }

  function handleLanguageChange(code: string | undefined) {
    if (code && isBFF69(code)) {
      changeAttr("lang", code);
    }
  }

  function handleVoiceChange(gender: SSMLGender | undefined) {
    if (isSSMLGender(gender)) {
      changeAttr("gender", gender);
    } else {
      changeAttr("gender", undefined);
    }
  }

  const fmap = fieldMap(fields);
  const langCode = fieldLangFollowingRef(field, fmap);
  const hasRef = hasFieldRef(field.source);
  const langs = ttsLanguages();

  return (
    <FieldEditorGeneric
      fields={fields}
      field={field}
      onChange={onChange}
      creatingConfig={creatingConfig}
    >
      <FontSizeSelectorItem fontSizePx={fontSizePx} onChange={handleFontSizeChange} />

      {hasRef ? undefined : (
        <LanguageSelectorItem
          field={field}
          langCode={langCode}
          langs={langs}
          disabled={hasRef}
          onChange={handleLanguageChange}
        />
      )}

      <VoiceSelectorItem
        gender={field.attributes?.gender}
        onChange={handleVoiceChange}
        langCode={langCode}
      />
    </FieldEditorGeneric>
  );
}
