import FieldEditFormattedText from "fields/editors/fieldEditorFormattedText";
import { IBaseTextField, validateTranslationPipelineLang } from "fields/lib";
import { IFieldType } from "fields/fieldType";
import FieldValueEditorText from "fields/valueEditors/fieldValueEditorText";
import TextValue from "fields/values/textValue";
import L10n from "localization";
import { IoText } from "react-icons/io5";
import { BFF69, fieldLang, isBFF69 } from "fields/lang";
import { parseSides } from "fields/sides";

export const textFieldType: IFieldType<"text"> = {
  name: () => L10n.localize((s) => s.field.typeText),
  description: () => L10n.localize((s) => s.field.typeTextDescription),
  icon: IoText,
  sampleValue(field) {
    const lang = fieldLang(field);
    if (lang) {
      return `${field.name} (${lang})`;
    }
    return field.name;
  },
  fmlTag: "text",
  type: "text",
  parseField(attrs, srcs): IFieldText {
    const fontSizePx = parseInt(attrs["fontSizePx"]);
    const hint = Boolean(attrs["hint"]);

    const lang: BFF69 | undefined = isBFF69(attrs["lang"]) ? attrs["lang"] : undefined;

    return {
      name: attrs["name"],
      type: "text",
      sides: parseSides(attrs["sides"]),
      source: srcs[0],
      hint,
      attributes: {
        fontSizePx: isNaN(fontSizePx) ? undefined : fontSizePx,
        lang,
      },
    };
  },

  loadFML(fml) {
    return fml;
  },

  dumpFML(value) {
    return value;
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: TextValue,
  editorComponent: FieldEditFormattedText,
  valueEditorComponent: FieldValueEditorText,

  convert(field) {
    switch (field.type) {
      case "richtext":
      case "text":
      case "tts":
        return { ...field, type: this.type };
      default:
        return { ...field, type: this.type, attributes: undefined };
    }
  },

  string(value) {
    return value;
  },

  valid: validateTranslationPipelineLang,
};

export interface IFieldText extends IBaseTextField {
  type: "text";
}
