import React from "react";
import L10n from "../localization";
import Style from "../style";

export default function Reveal({ children }: { children: JSX.Element }) {
  const [isRevealed, setIsRevealed] = React.useState(false);

  return isRevealed ? (
    children
  ) : (
    <a
      style={{ color: Style.colors.actionableBg, cursor: "pointer" }}
      onClick={() => {
        setIsRevealed(true);
      }}
    >
      {L10n.localize((s) => s.general.attribution)}
    </a>
  );
}
