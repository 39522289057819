import { Camera, CameraResultType, ImageOptions } from "@capacitor/camera";
import L10n from "localization";

export async function getPhoto(options?: Partial<ImageOptions>) {
  const image = await Camera.getPhoto({
    quality: 90,
    allowEditing: false, // NOTE: if this is set to true, the photo is constrained to a square aspect ratio on iOS.
    saveToGallery: false,
    resultType: CameraResultType.Base64,
    webUseInput: true,
    promptLabelHeader: L10n.localize((s) => s.getStarted.photo),
    promptLabelCancel: L10n.localize((s) => s.actions.cancel),
    promptLabelPhoto: L10n.localize((s) => s.actions.selectPhoto),
    promptLabelPicture: L10n.localize((s) => s.actions.takePicture),
    ...options,
  });
  return image;
}
