import { DeckFields, fieldMap } from "@models/deck";
import { ReplateContext } from "@vendor/replate";
import Value from "cardRendering/value";

interface IProps {
  cardID: string;
  renderer: React.FunctionComponent;
  values: Record<string, string>;
  overrideValues: Record<string, string>;
  fields: DeckFields | undefined;
}
export default function GridCellContentsRenderer(props: IProps) {
  const { values, renderer, overrideValues } = props;

  const renderProps: Record<string, ReplateContext> = {};
  const combinedValues = { ...values, ...overrideValues };
  if (Object.values(combinedValues).length > 0) {
    const fmap = fieldMap(props.fields);
    for (const [k, v] of Object.entries(combinedValues)) {
      const field = fmap[k];
      const value = Value({
        value: v,
        values,
        options: {
          disableAudioPlayback: true,
          disableTouchEvents: true,
          persistDownloadedBlobs: false,
        },
        field,
        fieldMap: fmap,
      });
      renderProps[k] = value;
    }

    return renderer(renderProps);
  }
  return null;
}
