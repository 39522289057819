import { fieldTypeMap, IField } from "fields/fields";
import { IFieldEditorProps } from "fields/fieldType";
import React from "react";

export default function FieldEditor(props: IFieldEditorProps<IField>): JSX.Element | null {
  switch (props.field.type) {
    case "audio": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "chinese": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "code": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "image": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "japanese": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "richtext": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "tex": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "text": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "tts": {
      const { field } = props;
      const component = fieldTypeMap[field.type].editorComponent;
      return React.createElement(component, { ...props, field });
    }
  }
}
