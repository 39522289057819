import color from "color";
import React, { ReactNode } from "react";
import Style from "style";
import { IgnoreIcon, StarIcon } from "style";

const borderRadius = 4;

export function MarkedIcon() {
  return (
    <span
      style={{
        float: "left",
        marginLeft: "2px",
        marginTop: "2px",
      }}
    >
      <StarIcon />
    </span>
  );
}

export function IgnoredIcon() {
  return (
    <span
      style={{
        float: "left",
        marginLeft: "2px",
        marginTop: "2px",
      }}
    >
      <IgnoreIcon />
    </span>
  );
}

export default function Header(props: { bgColor?: string; children: ReactNode }): JSX.Element {
  const { bgColor, children } = props;

  const style = {
    textAlign: "right",
    paddingRight: "0.3rem",
    fontSize: "medium",
    color: Style.currentTheme === "night" ? "var(--ion-color-step-800)" : Style.colors.primaryBg,
    textShadow: Style.currentTheme === "night" ? undefined : "0 -1px 1px rgba(0,0,0,0.3)",
    backgroundColor: bgColor ? color(bgColor).alpha(0.2).string() : undefined,
    background: bgColor
      ? `linear-gradient(135deg, ${color(bgColor).alpha(0.65).string()} 0%, ${color(bgColor)
          .alpha(0.8)
          .string()} 100%)`
      : undefined,
    zIndex: 2,
    paddingTop: 3,
    paddingBottom: 3,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    userSelect: "none",
  } as React.CSSProperties;

  return <div style={style}>{children}</div>;
}
