import { showPrompt } from "@components/prompt";
import {
  InputCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import Network from "network";
import React from "react";

interface IChangePasswordModal {
  dismiss: () => void;
}

export default function ChangePasswordModal(props: IChangePasswordModal) {
  const { dismiss } = props;

  const [currPassword, setCurrPassword] = React.useState("");
  const currPasswordInvalid = currPassword.length < 1;

  const [newPassword, setNewPassword] = React.useState("");
  const newPasswordInvalid = newPassword.length < 1;

  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState("");
  const newPasswordConfirmInvalid = newPasswordConfirm.length < 1;

  const disabled = currPasswordInvalid || newPasswordInvalid || newPasswordConfirmInvalid;

  function handleDismiss() {
    dismiss();
  }

  async function handleSubmit() {
    if (newPassword !== newPasswordConfirm) {
      return showPrompt({
        title: L10n.localize((s) => s.general.attention),
        prompt: L10n.localize((s) => s.account.passwordMismatch),
        promptType: "alert",
      });
    }

    const data = {
      passwordOld: currPassword,
      passwordNew: newPassword,
    };
    try {
      await Network.fetch("POST", "/users/password", data);
      showPrompt({
        title: L10n.localize((s) => s.general.attention),
        prompt: L10n.localize((s) => s.account.changePasswordSuccess),
        promptType: "alert",
      });
      dismiss();
    } catch (e) {
      showPrompt({
        title: L10n.localize((s) => s.general.attention),
        prompt: L10n.localize((s) => s.account.changePasswordError),
        promptType: "alert",
      });
    }
  }

  return (
    <IonContent
      {...cyAttr("change-pw-modal")}
      style={
        { "--background": "var(--ion-color-light)" } as React.CSSProperties & {
          "--background": string;
        }
      }
    >
      <IonToolbar>
        <IonTitle>{L10n.localize((s) => s.account.changePassword)}</IonTitle>
        <IonButtons slot="secondary">
          <IonButton onClick={handleDismiss}>{L10n.localize((s) => s.actions.cancel)}</IonButton>
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton
            onClick={handleSubmit}
            disabled={disabled}
            style={{ textTransform: "capitalize" }}
          >
            {L10n.localize((s) => s.actions.change)}
          </IonButton>
        </IonButtons>
      </IonToolbar>

      <IonList style={{ marginTop: 32 }}>
        <IonItem>
          <IonInput
            label={L10n.localize((s) => s.account.currentPassword)}
            value={currPassword}
            onIonInput={(evt: InputCustomEvent) => setCurrPassword(evt.detail.value ?? "")}
            type="password"
            required
          />
        </IonItem>

        <IonItem>
          <IonInput
            label={L10n.localize((s) => s.account.newPassword)}
            value={newPassword}
            onIonInput={(evt: InputCustomEvent) => setNewPassword(evt.detail.value ?? "")}
            type="password"
            required
          />
        </IonItem>

        <IonItem>
          <IonInput
            label={L10n.localize((s) => s.account.confirmPassword)}
            value={newPasswordConfirm}
            onIonInput={(evt: InputCustomEvent) => setNewPasswordConfirm(evt.detail.value ?? "")}
            type="password"
            required
          />
        </IonItem>
      </IonList>
    </IonContent>
  );
}
