import { IonButton, IonIcon } from "@ionic/react";
import { clipboardOutline } from "ionicons/icons";
import React from "react";

interface IProps {
  onPaste: (text: string) => void;
}
export default function PasteButton({ onPaste }: IProps) {
  async function handlePaste() {
    try {
      const text = await navigator.clipboard.readText();
      if (text) {
        onPaste(text);
      }
    } catch {
      // Can happen if user rejects permission.
    }
  }
  return (
    <IonButton
      style={{ position: "absolute", zIndex: 31337, right: 8, bottom: 2 }}
      onClick={handlePaste}
      fill="clear"
    >
      <IonIcon icon={clipboardOutline} />
    </IonButton>
  );
}
