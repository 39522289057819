import { filterIterable } from "@lib/iterable";
import { Deck } from "@models/deck";
import { IDeckSettings } from "@models/deckSettings";
import { IKnol } from "@models/knol";

// Pulls card data based on current deck options
export async function getCardDataWithOptions(
  knols: Iterable<IKnol>,
  settings: IDeckSettings,
): Promise<Iterable<IKnol>> {
  let cards = knols;

  // Apply tag filter.
  if (settings.reviewTags?.length > 0) {
    const tagSet = new Set(settings.reviewTags);
    cards = filterIterable(cards, (c) => {
      for (const tag of c.tags ?? []) {
        if (tagSet.has(tag)) {
          return true;
        }
      }
      return false;
    });
  }

  // Apply grade filter.
  const reviewGrades = settings.reviewGrades;
  if (reviewGrades.length > 0) {
    cards = Deck.cardsWithGrade(cards, reviewGrades);
  }

  return cards;
}
