import { showPrompt } from "@components/prompt";
import { IDeck } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import L10n from "localization";
import { useCallback } from "react";

export default function useDeleteKnols(deck: IDeck | undefined) {
  const deleteKnols = useCallback(
    (knols: IKnol[], callback?: () => void) => {
      if (knols.length < 1 || !deck) {
        return;
      }
      const plural = knols.length > 1;
      showPrompt({
        title: L10n.localize((s) => s.actions.confirm),
        prompt: plural
          ? L10n.localize((s) => s.card.confirmRemovalPlural)
          : L10n.localize((s) => s.card.confirmRemoval),
        promptType: "dangerousConfirm",
        dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.remove).toLocaleUpperCase(),
        callback: async () => {
          const promises = knols.map((knol) => Knol.Delete(knol.id, deck.id));
          await Promise.all(promises);
          callback?.();
        },
      });
    },
    [deck],
  );
  return deleteKnols;
}
