import L10n from "localization";
import React from "react";
import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
} from "@ionic/react";
import {
  laptopOutline,
  phonePortraitOutline,
  logoWindows,
  logoAndroid,
  desktopOutline,
  trashOutline,
} from "ionicons/icons";
import { ID } from "types/ID";
import { RiMapPinUserLine } from "react-icons/ri";

interface IProps {
  id: string;
  name: string;
  clientVersion?: { major: string; minor: string; patch: string };
  platform: string;
  lastSyncedAt: string;
  version?: string;
  isThisDevice: boolean;
  handleDelete: (id: ID) => void;
}

export default function DeviceStatus(props: IProps): JSX.Element {
  let name = props.name || "";
  let platform = props.platform || "";

  if (platform === "ios" && name === "mac-catalyst") {
    platform = "macos";
    name = "Mac";
  }

  let iconName: string;
  switch (platform.toLowerCase()) {
    case "macintel":
      iconName = laptopOutline;
      break;
    case "macos":
      iconName = laptopOutline;
      break;
    case "ios":
      iconName = phonePortraitOutline;
      break;
    case "windows":
    case "win32":
    case "win64":
    case "win":
      iconName = logoWindows;
      break;
    case "android":
      iconName = logoAndroid;
      break;
    default:
      iconName = desktopOutline;
  }

  platform = (() => {
    switch (platform.toLowerCase()) {
      case "macos":
      case "macintel":
        return L10n.localize((s) => s.platform.mac);
      case "ios":
        return L10n.localize((s) => s.platform.ios);
      case "windows":
      case "win32":
      case "win64":
      case "win":
        return L10n.localize((s) => s.platform.win);
      case "android":
        return L10n.localize((s) => s.platform.android);
      default:
        if (props.version?.includes("Electron")) {
          return "Desktop";
        }
        return "";
    }
  })();

  const shortID = props.id.substring(0, 6);
  const clientVersion = props.clientVersion?.major
    ? `v${props.clientVersion.major}.${props.clientVersion.minor}.${props.clientVersion.patch}`
    : "";

  if (name.indexOf("Web Client") > -1) {
    name = "Web Client";
  }

  const label = platform ? `${name} (${platform})` : name;
  return (
    <IonItemSliding>
      <IonItem lines="full">
        <IonIcon slot="start" icon={iconName} />
        <IonLabel>
          {label}
          <p>
            {clientVersion}
            <span style={{ display: "inline", marginLeft: 6, fontFamily: "monospace" }}>
              {shortID}
            </span>
          </p>
        </IonLabel>
        {props.isThisDevice && (
          <div slot="end">
            <RiMapPinUserLine size={24} />
          </div>
        )}
      </IonItem>
      {!props.isThisDevice && (
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={() => props.handleDelete(props.id)}>
            <IonIcon slot="icon-only" icon={trashOutline} />
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
}
