import idb from "@data/idb";
import { DeckFields, IDeck } from "@models/deck";
import L10n from "localization";
import React from "react";
import FieldsWarningOrError from "./fieldsWarningOrError";

interface IProps {
  deck: IDeck;
  fields: DeckFields;
  onResurrectField: (name: string) => void;
}

export default function UnusedDeckFields({ deck, fields, onResurrectField }: IProps) {
  if (!deck) {
    return null;
  }
  const hasNoFieldsUsed = fields ? fields.length < 1 : true;
  const [results, setResults] = React.useState<Record<string, number>>({});

  React.useEffect(() => {
    async function getKnols() {
      const tx = idb.db.transaction("knols", "readonly");
      const store = tx.objectStore("knols");
      const index = store.index("deck_id");
      const knols = await index.getAll(IDBKeyRange.only(deck.id)); // Assuming 'deck.id' is the id of the current deck

      const valueKeysCount: Record<string, number> = {};

      knols.forEach((knol) => {
        Object.keys(knol.values ?? {}).forEach((key) => {
          valueKeysCount[key] = (valueKeysCount[key] || 0) + 1;
        });
      });

      setResults(valueKeysCount);
    }

    if (deck.id) {
      getKnols();
    }
  }, [deck]);

  const fieldNames = fields.map((field) => field.name);
  const unusedFields = Object.keys(results)
    .filter((key) => !fieldNames.includes(key))
    .reduce(
      (acc, key) => {
        acc[key] = results[key];
        return acc;
      },
      {} as Record<string, number>,
    );

  if (!unusedFields || Object.keys(unusedFields).length < 1) {
    return null;
  }

  return (
    <FieldsWarningOrError
      title={L10n.localize((s) => s.deck.unusedFieldsDetectedHeader)}
      description={L10n.localize((s) => s.deck.unusedFieldsDetectedBody)}
      type={hasNoFieldsUsed ? "error" : "warning"}
      onResurrectField={onResurrectField}
      unusedFields={unusedFields}
    />
  );
}
