import Empty from "@components/empty";
import { IDeck } from "@models/deck";
import Num from "components/number";
import StatRow from "components/statRow";
import EventBus from "eventBus";
import {
  getTotalNumberReviewsTodayForDeck,
  getTotalReviewsByDateForDeck,
  getTotalReviewsForDeck,
} from "hooks/data/responseHistory";
import { useIsPhone } from "hooks/util/useMediaQuery";
import L10n from "localization";
import React from "react";
import Style from "style";

const HistoryStats = (props: {
  deck?: IDeck;
  knolsLastUpdated?: Date;
}) => {
  const { deck, knolsLastUpdated } = props;

  const isPhone = useIsPhone();

  const [numTotalReviews, setNumTotalReviews] = React.useState<number | undefined>();
  const [numTotalReviewsToday, setNumTotalReviewsToday] = React.useState<number | undefined>(
    undefined,
  );
  const [numAverageReviewsPerDay, setNumAverageReviewsPerDay] = React.useState<number | undefined>(
    undefined,
  );
  // const [totalResponseDurationMs, setTotalResponseDurationMs] = React.useState<number | undefined>(
  //   undefined,
  // );

  const fetchHistory = React.useCallback(() => {
    if (!deck) {
      return;
    }

    getTotalReviewsByDateForDeck(deck.id)
      .then((t) => {
        const numAverageReviewsPerDay = t
          ? Math.round(
              Array.from(t.values()).reduce((acc, numReviews) => acc + numReviews, 0) / t.size,
            )
          : 0;
        setNumAverageReviewsPerDay(numAverageReviewsPerDay);
      })
      .catch(() => {});

    getTotalNumberReviewsTodayForDeck(deck.id).then((n) => setNumTotalReviewsToday(n));
    getTotalReviewsForDeck(deck.id).then((n) => setNumTotalReviews(n));
  }, [deck]);

  React.useEffect(() => {
    if (!deck) {
      return;
    }
    if (deck !== undefined) {
      fetchHistory();
      EventBus.on("responseInserted", fetchHistory);
      return () => {
        EventBus.off("responseInserted", fetchHistory);
      };
    }
  }, [deck, knolsLastUpdated]);

  if (numTotalReviews === 0) {
    return <Empty text={L10n.localize((s) => s.deck.noReviews)} />;
  }

  const historyStatsStyle = !isPhone
    ? {
        marginLeft: Style.edgePadding,
        marginRight: Style.edgePadding,
      }
    : {
        paddingTop: Style.edgePadding,
        paddingRight: Style.edgePadding,
      };

  // const hasTotalResponseDuration = !!totalResponseDurationMs;

  // const totalTimeString = hasTotalResponseDuration
  //   ? millis2TimeString(totalResponseDurationMs)
  //   : L10n.localize((s) => s.general.none);

  // const avgTimeString = (() => {
  //   if (hasTotalResponseDuration && numTotalReviews != null) {
  //     const numSecs = Math.round((totalResponseDurationMs / numTotalReviews) * 10) / 10;
  //     return numSecs + L10n.localize((s) => s.time.second);
  //   }
  //   return L10n.localize((s) => s.general.none);
  // })();

  return (
    <div style={historyStatsStyle}>
      <StatRow label={L10n.localize((s) => s.review.numToday)}>
        <Num num={numTotalReviewsToday} />
      </StatRow>
      <StatRow label={L10n.localize((s) => s.review.numPerDay)}>
        <Num
          num={
            numAverageReviewsPerDay !== undefined ? Math.round(numAverageReviewsPerDay) : Number.NaN
          }
        />
      </StatRow>
      <StatRow label={L10n.localize((s) => s.review.total)}>
        <Num num={numTotalReviews} />
      </StatRow>
      {/* <StatRow label={L10n.localize((s) => s.review.totalTime)}>{totalTimeString}</StatRow>
      <StatRow label={L10n.localize((s) => s.review.averageTime)}>{avgTimeString}</StatRow> */}
    </div>
  );
};

export default HistoryStats;
