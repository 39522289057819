import { useCallback, useState } from "react";
import useDisableKeyboardScroll from "./util/useDisableKeyboardScroll";

export default function useKeyboardHeight(onShow?: () => void, onHide?: () => void) {
  const [kbHeightPx, setKbHeightPx] = useState(0);
  const handleKeyboardShow = useCallback(
    (heightPx: number) => {
      setKbHeightPx(heightPx);
      onShow?.();
    },
    [onShow],
  );
  const handleKeyboardHide = useCallback(() => {
    setKbHeightPx(0);
    onHide?.();
  }, []);
  useDisableKeyboardScroll(handleKeyboardShow, handleKeyboardHide);
  return kbHeightPx;
}
