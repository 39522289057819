import coreGradePercent from "@core/gradePercent";
import { Deck } from "@models/deck";
import { IKnol } from "@models/knol";
import Grade from "components/grade";
import LabeledText from "components/labeledText";
import StatRow from "components/statRow";
import { useIsPhone } from "hooks/util/useMediaQuery";
import L10n from "localization";
import React from "react";
import Style from "style";

export default function GradeSection(props: { knols?: Iterable<IKnol> }): JSX.Element {
  const { knols } = props;
  let averagePriority;
  let gradePercent: number | undefined;

  if (knols) {
    averagePriority = Deck.cardsAveragePriority(knols);
    if (averagePriority !== undefined) {
      gradePercent = coreGradePercent(averagePriority);
    }
  }

  const isPhone = useIsPhone();
  const grade = isPhone ? (
    <StatRow label={L10n.localize((s) => s.grade.singular)}>
      {<Grade percent={gradePercent} />}
    </StatRow>
  ) : (
    <div style={{ display: "flex", flexBasis: "auto", flexGrow: 1 }}>
      <LabeledText
        fontSize="3.5rem"
        label={L10n.localize((s) => s.grade.singular)}
        text={<Grade percent={gradePercent} />}
      />
    </div>
  );

  const cardStats = // for widescreen
    (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexBasis: "auto",
          paddingTop: Style.edgePadding,
          paddingRight: Style.edgePadding,
          flexWrap: "nowrap",
        }}
      >
        {grade}
      </div>
    );

  if (isPhone) {
    return grade;
  }
  return cardStats;
}
