import { useNewFolderHandler } from "@components/newFolderButton";
import ScreenMenu, { MenuButton } from "@components/screenMenu";
import { AlertInput, useIonAlert } from "@ionic/react";
import { IDeck } from "@models/deck";
import { useDecks } from "hooks/data/deckList";
import L10n from "localization";
import React, { useCallback } from "react";
import {
  LuFilePlus,
  LuFolderEdit,
  LuFolderInput,
  LuFolderOutput,
  LuFolderTree,
  LuFolderX,
} from "react-icons/lu";
import { ID } from "types/ID";

function useAddToFolderDeckSelector({
  onSelect,
}: {
  onSelect: (decks: IDeck[]) => void;
}): () => void {
  const decks = useDecks();
  const [showAlert] = useIonAlert();

  const sortedDecks = (decks.val ?? []).sort((d1, d2) =>
    (d1.name ?? "") < (d2.name ?? "") ? -1 : 1,
  );

  const inputs: AlertInput[] = sortedDecks.map((deck) => {
    return {
      type: "checkbox",
      name: deck.name,
      value: deck,
      label: deck.name,
      checked: sortedDecks.length === 0,
    };
  });

  function show() {
    showAlert({
      header: L10n.localize((s) => s.folder.moveDeckHere),
      inputs,
      buttons: [
        L10n.localize((s) => s.actions.cancel),
        {
          text: L10n.localize((s) => s.actions.ok),
          handler: (decks: IDeck[]) => {
            onSelect(decks);
          },
        },
      ],
    });
  }

  return show;
}

export function Menu({
  folder,
  createDeck,
  addToFolder,
  removeFromFolder,
  decks,
  archiveFolder,
  renameFolder,
}: {
  folder: string;
  createDeck: () => void;
  removeFromFolder: (deckIDs: ID[]) => Promise<void>;
  addToFolder: (decks: IDeck[]) => Promise<void>;
  archiveFolder: () => Promise<void>;
  renameFolder: () => Promise<void>;
  decks: IDeck[];
}) {
  const showDeckSelect = useAddToFolderDeckSelector({
    onSelect: addToFolder,
  });

  const handleNewFolder = useNewFolderHandler(folder);

  const [showRemoveDeckAlert] = useIonAlert();
  const handleRemoveDeck = useCallback(() => {
    const inputs: AlertInput[] = decks.map((d) => ({
      name: d.id,
      type: "checkbox",
      label: d.name,
      value: d.id,
    }));
    showRemoveDeckAlert({
      translucent: true,
      header: L10n.localize((s) => s.folder.removeDeckFrom),
      inputs,
      buttons: [
        {
          text: L10n.localize((s) => s.actions.cancel),
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: L10n.localize((s) => s.actions.remove),
          handler: async (deckIDs: string[]) => {
            await removeFromFolder(deckIDs);
          },
        },
      ],
    });
  }, [decks, removeFromFolder, showRemoveDeckAlert]);

  return (
    <ScreenMenu>
      <MenuButton
        label={L10n.localize((s) => s.deck.new)}
        icon={<LuFilePlus size={24} />}
        onClick={createDeck}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.moveDeckHere)}
        icon={<LuFolderInput size={24} />}
        onClick={showDeckSelect}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.removeDeckFrom)}
        icon={<LuFolderOutput size={24} />}
        onClick={handleRemoveDeck}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.newSubfolder)}
        icon={<LuFolderTree size={22} />}
        onClick={handleNewFolder}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.folder.rename)}
        icon={<LuFolderEdit size={24} />}
        onClick={renameFolder}
        detail={false}
      />
      <MenuButton
        label={L10n.localize((s) => s.archive.verb)}
        icon={<LuFolderX size={24} />}
        onClick={archiveFolder}
        color="danger"
        detail={false}
        lines="none"
      />
    </ScreenMenu>
  );
}
