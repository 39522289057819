import { DeckFieldMap, IField, fieldTypeMap } from "fields/fields";
import { fieldLang } from "fields/lang";
import { AsyncVal, useAsyncValFromPromiseState } from "hooks/util/useAsyncValState";
import { renderTranslation } from "./translation";
import { SourcePipelineNode, manualSourcePipelineNode } from "./types";

// ttsRefFieldString gets the string value of a ref that a TTS field draws from.
// TODO: support richtext as well? Would need to strip HTML tags.
export function ttsRefFieldString(field: IField, val: string): string | undefined {
  const fieldType = field.type;
  switch (fieldType) {
    case "text":
      return val;
    case "japanese": {
      const fieldTypeStruct = fieldTypeMap[fieldType];
      const parsedVal = fieldTypeStruct.loadFML(val);
      if (!parsedVal) {
        return;
      }
      return fieldTypeStruct.string?.(parsedVal);
    }
    case "chinese": {
      const fieldTypeStruct = fieldTypeMap[fieldType];
      const parsedVal = fieldTypeStruct.loadFML(val);
      if (!parsedVal) {
        return;
      }
      return fieldTypeStruct.string?.(parsedVal);
    }
  }
}

export function pipelineIncludes(
  node: SourcePipelineNode,
  fieldName: string,
  fmap: DeckFieldMap,
): boolean {
  if (node === manualSourcePipelineNode) {
    return false;
  }

  switch (node.type) {
    case "ref": {
      if (node.name === fieldName) {
        return true;
      }
      const nextField = fmap[node.name];
      if (!nextField) {
        return false;
      }
      return pipelineIncludes(nextField.source, fieldName, fmap);
    }
    case "translation": {
      return pipelineIncludes(node.source, fieldName, fmap);
    }
  }
}

export function hasFieldRef(node: SourcePipelineNode): boolean {
  if (node === manualSourcePipelineNode) {
    return false;
  }

  switch (node.type) {
    case "translation":
      return hasFieldRef(node.source);
    case "ref":
      return true;
  }
}

export function matchesPipeline(node: SourcePipelineNode, fieldName: string): boolean {
  if (node === manualSourcePipelineNode) {
    return false;
  }

  switch (node.type) {
    case "translation":
      return matchesPipeline(node.source, fieldName);
    case "ref":
      return node.name === fieldName;
  }
}

export function includesPipelineNodeType(node: SourcePipelineNode, nodeType: string): boolean {
  if (node === manualSourcePipelineNode) {
    return false;
  }

  switch (node.type) {
    case "translation": {
      if (nodeType === "translation") {
        return true;
      }
      return includesPipelineNodeType(node.source, nodeType);
    }
    case "ref":
      return nodeType === "ref";
  }
}

interface IPipelineResult {
  text: string;
  lang?: string;
}

// executePipeline recursively descends through the source pipeline rooted at,
// i.e. outputting to, the `node`, with given final desired output lang.
// At each step it bubbles up the resulting text and lang of the text.
export async function executePipeline(
  node: SourcePipelineNode,
  inputField: IField,
  inputText: string,
  outputLang: string | undefined,
): Promise<IPipelineResult | null> {
  if (node === manualSourcePipelineNode) {
    return null;
  }

  switch (node.type) {
    case "translation": {
      const pipeResp = await executePipeline(node.source, inputField, inputText, outputLang);
      if (!pipeResp) {
        return null;
      }
      const { text, lang: sourceLang } = pipeResp;
      const t9nResp = await renderTranslation(text, outputLang, sourceLang);
      if (!t9nResp) {
        return null;
      }
      return { text: t9nResp.translatedText, lang: t9nResp.destination };
    }
    case "ref": {
      if (node.name === inputField.name) {
        const lang = fieldLang(inputField);
        return { text: inputText, lang };
      } else {
        return null;
      }
    }
  }
}

export function useValuePipeline(
  node: SourcePipelineNode,
  inputField: IField,
  inputText: string,
  outputLang: string | undefined,
): AsyncVal<IPipelineResult | null> {
  const [val, fetcher] = useAsyncValFromPromiseState<IPipelineResult | null>(null);
  fetcher(executePipeline(node, inputField, inputText, outputLang));
  return val;
}
