import React from "react";
import Prompt from "@components/promptDialog";
import { IonModal } from "@ionic/react";
import AlertModal from "components/alertModal";
import { IPromptOptions } from "@components/prompt";

interface IModalProps {
  isOpen: boolean;
  onHide: () => void;
  promptProps: IPromptOptions | null;
}
export default function Modal(props: IModalProps): JSX.Element {
  const { isOpen, onHide, promptProps } = props;

  function handleHide() {
    onHide();
    promptProps?.onHide?.();
  }

  const promptRef = React.useRef<Prompt>(null);
  function handlePresent() {
    promptRef.current?.modalDidShow();
  }

  // If a cancel callback shows another prompt, it must be wrapped in a
  // setTimeout(cb, 0) in order to work. That's what this mess does, so
  // the consumers of showPrompt don't have to worry about it.
  const cancelCallback = promptProps?.cancelCallback ?? (() => null);
  function onCancel() {
    setTimeout(cancelCallback, 0);
  }
  if (promptProps) {
    promptProps.cancelCallback = onCancel;
  }

  const promptType = promptProps?.promptType ?? "input";

  if (promptType === "custom" || promptProps?.type === "radio") {
    return (
      <IonModal
        animated={false}
        isOpen={isOpen}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore when presentingElement is undefined rather than null, the modal flows under the iPhone notch ears.
        presentingElement={null}
        onDidDismiss={handleHide}
        onDidPresent={handlePresent}
      >
        {isOpen && <Prompt ref={promptRef} {...{ ...promptProps, isOpen }} />}
      </IonModal>
    );
  }

  return (
    <AlertModal
      isOpen={isOpen}
      onDidDismiss={handleHide}
      onDidPresent={handlePresent}
      promptProps={promptProps}
    />
  );
}
