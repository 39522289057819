import { IKnol, Knol } from "@models/knol";
import cardPriority from "./cardPriority";
import priorityToGrade from "./priorityToGrade";

export function gradedCards(cards: IKnol[]): {
  [name: string]: IKnol[];
} {
  const gradedCards = {
    NEW: [] as IKnol[],
    AAA: [] as IKnol[],
    AA: [] as IKnol[],
    A: [] as IKnol[],
    B: [] as IKnol[],
    C: [] as IKnol[],
    D: [] as IKnol[],
    E: [] as IKnol[],
    F: [] as IKnol[],
  };
  for (const card of cards) {
    const grade = priorityToGrade(cardPriority(Knol.GetGrade(card)));
    if (grade) {
      gradedCards[grade].push(card);
    }
  }
  return gradedCards;
}
