import React from "react";
import { frequencyFor } from "./lib";
import { IPDPContentProps } from "./pdpContent";
import { Products } from "./products";
import { IProduct, IServerProduct } from "./types";

interface IProps extends IPDPContentProps {
  serverProducts: IServerProduct[] | undefined;
  onPurchaseComplete: () => void;
  description: string;
}
export default function WebPDPContent({
  userID,
  email,
  logger,
  reason,
  onPurchaseComplete,
  serverProducts,
  dismiss,
  description,
}: IProps) {
  function handlePurchase(product: IProduct) {
    logger.log("user_indicated_purchase_complete", { product });
    dismiss();
    onPurchaseComplete();
  }

  const prods: IProduct[] = (serverProducts ?? []).map((prod) => {
    return {
      ...prod,
      frequency: frequencyFor(prod.id),
    };
  });

  return (
    <Products
      reason={reason}
      description={description}
      onPurchase={handlePurchase}
      products={prods}
      productsLoading={serverProducts === undefined}
      gateway="stripe"
      userID={userID}
      email={email}
      logger={logger}
    />
  );
}
