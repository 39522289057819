import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import CardScreenModal from "./card/cardScreenModal";

export function useCardModal({
  deck,
  knol,
  onDismiss,
}: {
  deck: IDeck | undefined;
  knol?: IKnol;
  initialActiveKey?: string;
  onDismiss?: () => void;
}): [() => void, () => void] {
  const [present, dismiss] = useIonCardModalWithDismiss(CardScreenModal, {
    knol,
    deck,
    onDismiss,
    conditionalDismiss: true,
  });
  return [present, dismiss];
}
