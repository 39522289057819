import { DeckFields } from "@models/deck";
import { fieldTypeMap } from "fields/fields";
import { appLang } from "fields/lang";
import { SIDES } from "fields/sides";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function chineseRecognition(): IMarketItem {
  const chineseRecognitionFields: DeckFields = [
    {
      name: fieldNames.Chinese(),
      type: "chinese",
      sides: SIDES.BOTH,
      attributes: {
        pinyinMode: "hint",
      },
    },
    {
      name: fieldNames.Translation(),
      type: "text",
      sides: SIDES.BACK,
      attributes: { lang: appLang() },
      source: {
        type: "translation",
        source: {
          type: "ref",
          name: fieldNames.Chinese(),
        },
      },
    },
  ];

  return {
    base: "chinese",
    name: L10n.localize((s) => s.field.typeChinese),
    fields: chineseRecognitionFields,
    description: L10n.localize((s) => s.field.typeChineseDescription),
    values: {
      [fieldNames.Chinese()]: fieldTypeMap.chinese.dumpFML({
        chinese: "你好，世界！",
      }),
      [fieldNames.Translation()]: fieldTypeMap.text.dumpFML("Hello, world!"),
    },
  };
}
