import { IonItem, IonLabel } from "@ionic/react";
import React from "react";

interface IClickableLabeledSectionProps {
  label: string | React.ReactElement;
  href?: string;
  children: any;
  disabled?: boolean;
  style?: React.CSSProperties;
  extras?: React.ReactElement;
}
export default function ClickableLabeledSection(props: IClickableLabeledSectionProps): JSX.Element {
  const { label, href, children, disabled, style = {}, extras } = props;

  return (
    <div style={style}>
      <IonItem
        lines={"none"}
        routerLink={disabled ? undefined : href}
        detail={disabled ? undefined : href !== undefined}
        color={"light"}
      >
        <IonLabel>{label}</IonLabel>
        {extras}
      </IonItem>
      {children}
    </div>
  );
}
