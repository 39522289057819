import React from "react";
import { showPrompt } from "@components/prompt";
import Network from "network";
import L10n from "localization";
import {
  IGroupHeader,
  IGroup,
  IStudyGroupsResponse,
  IPendingGroupJoinRequest,
  IPendingGroupJoinRequestsResponse,
} from "./types";
import {
  AsyncVal,
  useAsyncValFromPromiseState,
  useAsyncValState,
} from "hooks/util/useAsyncValState";

export function usePendingGroupJoinRequests(): [
  AsyncVal<IPendingGroupJoinRequest[]>,
  () => Promise<void>,
] {
  const [requests, fetchRequests] = useAsyncValFromPromiseState<IPendingGroupJoinRequest[]>([]);

  const load = React.useCallback(() => {
    return fetchRequests(
      Network.fetch<IPendingGroupJoinRequestsResponse>("GET", "/users/join_requests").then(
        (resp) => resp.groups,
      ),
    );
  }, [fetchRequests]);

  React.useEffect(() => {
    load();
  }, [load]);

  return [requests, load];
}

export default function useStudyGroups(): [
  IGroupHeader[],
  { loading: boolean; error: boolean },
  () => Promise<void>,
] {
  const [groups, setGroups] = React.useState<IGroupHeader[]>([]);
  const [loadingState, setLoadingState] = React.useState({ loading: true, error: false });

  const fetchGroups = React.useCallback<() => Promise<void>>(() => {
    setLoadingState({ loading: true, error: false });

    return new Promise((resolve, reject) => {
      Network.fetch<IStudyGroupsResponse>("GET", "/users/groups")
        .then((resp) => {
          setGroups(resp?.groups ?? []);
          setLoadingState({ loading: false, error: false });
          resolve();
        })
        .catch(() => {
          setLoadingState({ loading: false, error: true });
          showPrompt({
            prompt: L10n.localize((s) => s.error.communication),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
          reject();
        });
    });

    /*
     * Uncomment for test data.
     *
    setGroups([]);
    setLoading(false);
    */
  }, [setLoadingState, setGroups]);

  React.useEffect(() => {
    fetchGroups().catch(() => {
      // Failed to fetch groups. Oh well.
    });
  }, [fetchGroups]);

  return [groups, loadingState, fetchGroups];
}

export function useStudyGroup(id: string): [AsyncVal<IGroup>, () => Promise<void>] {
  const [group, setGroup, setGroupErr] = useAsyncValState<IGroup>();

  const fetchGroup = React.useCallback<() => Promise<void>>(() => {
    return new Promise((resolve, reject) => {
      Network.fetch<IGroup>("GET", `/users/groups/${id}`)
        .then((resp) => {
          setGroup(resp);
          resolve();
        })
        .catch((err) => {
          setGroupErr(err);
          showPrompt({
            prompt: L10n.localize((s) => s.error.internal),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
          reject();
        });
    });
  }, [id]);

  React.useEffect(() => {
    fetchGroup().catch(() => {
      // The error is already set, so don't need to do anything here.
    });
  }, [fetchGroup]);

  return [group, fetchGroup];
}
