import EventBus from "eventBus";
import moment from "../../node_modules/moment/moment";
import { IL10nLeaf, L10nFun, Locales, combinedStrings, locales } from "./locales";

const selectedLocaleLocalStorageKey = "AnkiApp.settings.selectedLocale";

export function getSelectedLocale() {
  return localStorage.getItem(selectedLocaleLocalStorageKey);
}

export function userLocale() {
  return getSelectedLocale() || navigator.language.toLocaleLowerCase();
}

class Localization {
  readonly defaultLocale: Locales = "en";
  currentLocale: Locales = "en";
  getCurrentLocale(): Locales {
    return this.currentLocale;
  }
  switchLocale(localeString: Locales): Locales {
    if (locales.includes(localeString)) {
      this.currentLocale = localeString;
    } else {
      const fallback = locales.find((el) => {
        return el.substr(0, 2) === localeString.substr(0, 2);
      });
      if (fallback) {
        this.currentLocale = fallback;
      }
    }
    localStorage[selectedLocaleLocalStorageKey] = this.currentLocale;
    moment.locale([this.currentLocale, "en"]);
    EventBus.emit("localeUpdated");
    return this.currentLocale;
  }

  /**
   * Looks up a localized string in the current locale
   * @param selector callback to pick IL10nLeaf given the localized string tree
   * @returns appropriate localized string (or func) given current locale
   */
  localize<T = L10nFun | string>(selector: (strs: typeof combinedStrings) => IL10nLeaf<T>): T {
    const selection = selector(combinedStrings);
    const fallback = selection.en;
    return selection[this.currentLocale] ?? fallback;
  }

  get localeNames() {
    return combinedStrings.general.localeName;
  }
}

const L10n = new Localization();
export default L10n;
// export default new L10n();
