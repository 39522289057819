import { DeckFields } from "@models/deck";
import { fieldTypeMap } from "fields/fields";
import { SIDES } from "fields/sides";
import { sampleImageID, sampleImageType } from "fields/types/image";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function imageRecognition(): IMarketItem {
  const imageRecognitionFields: DeckFields = [
    { name: fieldNames.Image(), type: "image", sides: SIDES.BOTH },
    {
      name: L10n.localize((s) => s.preInstalledLayouts.textUnderImage),
      type: "text",
      sides: SIDES.BOTH,
      attributes: { fontSizePx: 18 },
    },
    { name: fieldNames.Text(), type: "text", sides: SIDES.BACK },
  ];

  return {
    base: "image",
    name: L10n.localize((s) => s.preInstalledLayouts.imageRecognition),
    description: L10n.localize((s) => s.field.typeImageDescription),
    fields: imageRecognitionFields,
    values: {
      [fieldNames.Image()]: fieldTypeMap.image.dumpFML({
        id: sampleImageID,
        type: sampleImageType,
      }),
      [L10n.localize((s) => s.preInstalledLayouts.textUnderImage)]: "",
      [fieldNames.Text()]: "Hippocampus",
    },
  };
}
