import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonLabel } from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { useCardModal } from "@screens/card";
import { useFlagAndContext } from "featureFlags";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React from "react";
import { LuFilePlus, LuWand2 } from "react-icons/lu";
import { ID } from "types/ID";
import GenCardsModal from "./genCardsModal";

interface IProps {
  deck: IDeck;
  knols: MapView<ID, IKnol>;
}
export default function EmptyDeck(props: IProps) {
  const genFlag = useFlagAndContext("features");
  const hasGenFlag = genFlag?.flag?.ai_card_create !== undefined;
  const [presentGenCardsModal] = useIonCardModalWithDismiss(GenCardsModal, {
    deck: props.deck,
    knols: props.knols,
    conditionalDismiss: true,
  });

  const [presentCardCreateModal] = useCardModal({
    deck: props.deck,
  });

  return (
    <div style={{ maxWidth: 640, margin: "0 auto" }}>
      {hasGenFlag ? (
        <IonCard button onClick={presentGenCardsModal}>
          <IonCardHeader>
            <IonCardTitle style={{ display: "flex", alignItems: "center" }} color="primary">
              <LuWand2 size={24} style={{ marginRight: 6 }} />
              <IonLabel>{L10n.localize((s) => s.card.generate)}</IonLabel>
            </IonCardTitle>
          </IonCardHeader>
          <IonCardContent>{L10n.localize((s) => s.generation.featureDescription)}</IonCardContent>
        </IonCard>
      ) : undefined}
      <IonCard button onClick={presentCardCreateModal}>
        <IonCardHeader>
          <IonCardTitle style={{ display: "flex", alignItems: "center" }} color="primary">
            <LuFilePlus size={24} style={{ marginRight: 6 }} />
            <IonLabel>{L10n.localize((s) => s.card.create)}</IonLabel>
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>{L10n.localize((s) => s.card.createFeatureDescription)}</IonCardContent>
      </IonCard>
    </div>
  );
}
