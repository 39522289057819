import { IonIcon } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import React from "react";

interface IProps {
  selected?: boolean;
}

export default function SelectionIndicator({ selected }: IProps) {
  return (
    <IonIcon
      icon={checkmarkOutline}
      slot="start"
      style={{ opacity: selected ? undefined : 0 }}
      color="primary"
    />
  );
}
