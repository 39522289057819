import L10n from "localization";
import React from "react";
import Style from "style";

interface IProps {
  showAppleTerms?: boolean;
}
export default function FinePrint(props: IProps) {
  const { showAppleTerms } = props;

  const appleTermsStyle = {
    fontSize: "11px",
    fontWeight: 300,
    marginTop: 16,
  } as React.CSSProperties;

  const appleTerms =
    "Payment will be charged to your Apple ID account at the confirmation of purchase. The subscription will automatically renew unless you cancel at least 24 hours before the end of the current period. Your account will be charged for renewal within 24 hours prior to the end of the current period. You can manage and cancel your subscription by going to your App Store account settings after purchase.";

  const linkStyle = { color: Style.colors.secondaryFg };

  const style = {
    display: "flex",
    flexDirection: "row",
    marginTop: 16,
    justifyContent: "center",
  } as React.CSSProperties;

  return (
    <div style={{ color: Style.colors.mutedFg, marginTop: 48 }}>
      {showAppleTerms && <div style={appleTermsStyle}>{appleTerms}</div>}
      <div style={style}>
        <a href="https://www.ankiapp.com/terms.html" style={linkStyle}>
          {L10n.localize((s) => s.account.termsOfService)}
        </a>
        <span style={{ margin: "0 6px" }}>&</span>
        <a href="https://www.ankiapp.com/privacy.html" style={linkStyle}>
          {L10n.localize((s) => s.account.privacyPolicy)}
        </a>
      </div>
    </div>
  );
}
