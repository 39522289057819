import showPDPCancelSurvey from "@components/pdpCancelSurvey";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonModal,
} from "@ionic/react";
import { User } from "@models/user";
import Globals from "globals";
import { closeCircle } from "ionicons/icons";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React, { useCallback, useMemo } from "react";
import PDPLogger from "./logger";
import PDPContent from "./pdpContent";

export interface IShowParams {
  reason?: () => string;
  source: string;
  logContext?: Record<string, any>;
  onPurchaseComplete?: () => void;
  onDismiss?: () => void;
  userID?: string;
  email?: string;
}

export default function usePDP(
  params: IShowParams,
): [(context?: Record<string, unknown>) => void, () => void] {
  const { reason, source, logContext, onPurchaseComplete, onDismiss, userID, email } = params;

  const logger = useMemo(
    () => new PDPLogger(reason?.(), source, logContext),
    [reason, source, logContext],
  );

  const sidePaddingPx = 12;

  const reasonRef = React.useRef(() => {
    return reason?.();
  });

  const dismiss = () => {
    logger.log("pdp_hidden");
    onDismiss?.();
    close();
    showPDPCancelSurvey();
  };

  const [present, close] = useIonModal(
    <IonPage>
      <IonToolbar>
        <IonTitle style={{ padding: 0 }}>{L10n.localize((s) => s.account.upgrade)}</IonTitle>
        <IonButtons slot="primary">
          <IonButton onClick={dismiss} {...cyAttr("close-pdp-button")}>
            <IonIcon icon={closeCircle} color="medium" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent
        style={
          {
            "--padding-bottom": "env(safe-area-inset-bottom)",
            "--padding-start": `${sidePaddingPx}px`,
            "--padding-end": `${sidePaddingPx}px`,
          } as React.CSSProperties & {
            "--padding-bottom": string;
            "--padding-start": string;
            "--padding-end": string;
          }
        }
      >
        <PDPContent
          dismiss={dismiss}
          platform={Globals.platform}
          userID={userID ?? User.id()}
          email={email ?? User.email()}
          logger={logger}
          onPurchaseComplete={onPurchaseComplete}
          reason={reasonRef.current()}
        />
      </IonContent>
    </IonPage>,
  );

  const show = useCallback(
    (context?: Record<string, unknown>) => {
      logger.reason = reasonRef.current();
      if (context) {
        logger.context = context;
      }
      logger.log("triggering_pdp");
      // @ts-ignore we want this to be a card modal
      present({ presentingElement: null });
    },
    [logger, present, reasonRef],
  );
  return [show, close];
}
