import gradePercent from "@core/gradePercent";
import idb from "@data/idb";
import IDB from "@data/idb";
import { rootFolders, subFolderMagicSeparator } from "@data/lib/folders";
import { MapLike } from "@lib/mapView";
import { IDeckTagDeleteOperation, IDeckTagUpdateOperation } from "@operations/deckTag";
import EventBus from "eventBus";
import { superCache } from "hooks/data/superCache";
import moment from "moment";
import { ID } from "types/ID";
import { Deck, IDeck } from "./deck";
import { Operation } from "./operation";

export const folderDeckItemMagicID = "$$folder$$";

export async function createFolder(name: string) {
  const tx = IDB.db.transaction(["decks", "folders"], "readwrite");
  let folderAdded = false;
  const row = await tx.objectStore("folders").get(name);
  if (!row) {
    await tx.objectStore("folders").add({ name });
    folderAdded = true;
  }
  if (folderAdded) {
    EventBus.emit("folderCreated");
  }
}

export function subfolderSQLLikePattern(folder: string): string {
  return `${folder}${subFolderMagicSeparator}%`;
}

export function localOrRemoteDeckFolder(deck: IDeck) {
  return deck.tags?.[0];
}

export async function folderNumAverageReviewsPerDay(
  decks: MapLike<ID, IDeck> | undefined,
): Promise<number> {
  const minDatetime = moment().endOf("day").utc().subtract(1, "days").toDate();

  const tx = idb.db.transaction("responses", "readonly");
  const responsesStore = tx.objectStore("responses");
  const index = responsesStore.index("created_at");

  // Get all responses before `minDatetime`
  let cursor = await index.openCursor(null, "prev");
  const countMap = new Map();

  while (cursor) {
    if (new Date(cursor.value.created_at) < minDatetime && decks?.has(cursor.value.deck_id)) {
      const dateKey = cursor.value.created_at.split("T")[0];
      countMap.set(dateKey, (countMap.get(dateKey) || 0) + 1);
    }
    cursor = await cursor.continue();
  }

  const totalDays = countMap.size;
  const totalCount = Array.from(countMap.values()).reduce((acc, val) => acc + val, 0);

  return totalDays > 0 ? totalCount / totalDays : 0;
}

export async function decksGradePercent(decks: IDeck[]) {
  const priority = await decksAveragePriority(decks);
  return gradePercent(priority);
}

async function decksAveragePriority(decks: IDeck[]) {
  const cardsData = await Deck.cardsData(decks);
  return Deck.cardsAveragePriority(cardsData);
}

export function parseSubfolders(folder: string): string[] {
  return folder.split(subFolderMagicSeparator);
}

export function join(parts: string[]): string {
  return parts.join(subFolderMagicSeparator);
}

function split(path: string): string[] {
  return path.split(subFolderMagicSeparator);
}

export function formFullFolderPath(folder: string, underFolder?: string): string {
  const subfolderPrefix: string[] = [];
  if (underFolder) {
    subfolderPrefix.push(underFolder);
  }
  subfolderPrefix.push(folder);
  const path = join(subfolderPrefix);
  return path;
}

// Strips the folder path prefix off of a deck path (name incl. folder separators).
export function basename(path: string): string {
  const parts = split(path);
  return parts[parts.length - 1];
}

// Returns the root in the path.
export function root(path: string): string {
  const parts = split(path);
  return parts[0];
}

export function isRootLevel(path: string): boolean {
  return root(path) === path;
}

// Strips the deck name suffix of a deck path (name incl. folder separators).
export function dirname(path: string): string {
  const parts = split(path);
  parts.pop();
  const folder = join(parts);
  return folder;
}

export function trimFolderPrefix(folder: string, path: string): string {
  const prefix = `${folder}${subFolderMagicSeparator}`;
  if (path.startsWith(prefix)) {
    return path.substring(prefix.length);
  }
  return path;
}

export async function archiveFolder(folder: string) {
  // Get all decks in this folder or subfolders.
  const decks = await Deck.getAllDecksInFolders(folder, {
    includeSubFolders: true,
  });

  // Split into a) decks that are exclusively in this folder (or subfolders) or b) decks that are in mutliple folders at once.
  function exclusivelyInFolderOrSubfolders(deck: IDeck): boolean {
    if (!deck.tags) {
      // Weird, but could happen due to a race condition after fetching decks initially.
      // In that case, just treat the deck as not being exclusively in this folder (safer).
      return false;
    }
    return deck.tags.every(
      (tag) => tag === folder || tag.startsWith(folder + subFolderMagicSeparator),
    );
  }
  const uniTaggedDecks = decks.filter((deck) => exclusivelyInFolderOrSubfolders(deck));
  const multiTaggedDecks = decks.filter((deck) => !exclusivelyInFolderOrSubfolders(deck));

  // Archive decks that are exclusively in this folder or subfolders.
  const promises = uniTaggedDecks.map((deck) => Deck.archive(deck.id));
  await Promise.all(promises);

  // Remove multi-tagged decks from this folder or subfolders.
  await Promise.all(
    multiTaggedDecks.map(async (deck) => {
      for (const tag of deck.tags ?? []) {
        if (tag === folder || tag.startsWith(folder + subFolderMagicSeparator)) {
          await Deck.removeTagFromDeck(deck.id, tag);
        }
      }
    }),
  );

  // Delete folder.
  const op: IDeckTagDeleteOperation = {
    ...Operation.operationDefaults(),
    type: "DELETE",
    object_type: "deck_tag",
    object_parameters: {
      tag_name: folder,
    },
  };
  return Operation.operateAndSave(op);
}

export async function renameFolder(folder: string, newName: string) {
  const op: IDeckTagUpdateOperation = {
    ...Operation.operationDefaults(),
    type: "UPDATE",
    object_type: "deck_tag",
    object_parameters: {
      tag_name_prev: folder,
      tag_name: newName,
    },
  };
  return Operation.operateAndSave(op);
}

export function getAllFoldersIn(folder?: string | null): string[] {
  if (!superCache.folders) {
    return [];
  }
  const folders = Array.from(superCache.folders.keys());
  if (folder === null) {
    return rootFolders(folders);
  }
  if (folder === undefined) {
    return folders;
  }
  // Return subfolders of folder.
  return folders.filter((f) => f.startsWith(folder + subFolderMagicSeparator));
}

// expandIntermediateFolders returns a full set of folders given a list of ::-separated folders.
export function expandIntermediateFolders(folders: string[]): string[] {
  const set = new Set<string>();
  for (const folder of folders) {
    const subfolderParts = parseSubfolders(folder);
    for (let i = 1; i <= subfolderParts.length; i++) {
      const prefix = join(subfolderParts.slice(0, i));
      set.add(prefix);
    }
  }
  return Array.from(set);
}
