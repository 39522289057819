import { ITypedBlobURL } from "@data/idb";
import { Orientation } from "@models/deckSettings";
import { IField } from "fields/fields";
import { hintClass } from "hooks/util/useHint";
import React from "react";
import { ID } from "types/ID";
import FieldValue from "../fields/values/fieldValue";
import StandardValue from "./standardValue";

export const BLOB_TYPE_ERROR_LOADING = "error";
export const fieldClass = "field";

export interface IValueOptions {
  deckID?: ID;
  disableTouchEvents?: boolean;
  disableAudioPlayback?: boolean;
  persistDownloadedBlobs: boolean;
  disableAudioControlsRendering?: boolean;
  preventAudioOrchestration?: boolean;
  orientation?: Orientation;
  query?: string;
}

export interface IRenderingContext {
  autoplayAudio?: boolean;
  sideNum?: number;
}

export interface IValueProps {
  value: string;
  values: Record<string, string>;
  blobIdToObjectUrl?: Record<ID, ITypedBlobURL>;
  options: IValueOptions;
  field: IField | undefined;
  fieldMap: Record<string, IField>;
}

export default function Value({
  value,
  values,
  blobIdToObjectUrl,
  options,
  field,
  fieldMap,
}: IValueProps) {
  if (!field) {
    return StandardValue(value, options, blobIdToObjectUrl);
  }
  return function RenderValue(ctx: IRenderingContext) {
    const fv = FieldValue({
      field,
      fieldMap,
      value,
      values,
      options,
      ctx,
      blobIdToObjectUrl,
    });
    const classes = [fieldClass];
    if (field.hint) {
      // TODO: apply this hint class within the individual renderers, to avoid extraneous <div>?
      classes.push(hintClass);
    }
    return <div className={classes.join(" ")}>{fv}</div>;
  };
}
