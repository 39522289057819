import IAPv2, { IRestoreProductsResponse, hexToUUIDv4, IAPProductPrices } from "iap";
import { IProduct } from "./types";
import { User } from "models/user";

export interface IIAPProduct extends IProduct {
  frequency: string | null;
  iapProductId: string;
}

export interface IProductsState {
  description: string;
  iapProducts: IAPProductPrices;
  products: IIAPProduct[];
}

export async function restoreProducts(): Promise<IRestoreProductsResponse> {
  const userId = User.id();
  const userIdUUID = hexToUUIDv4(userId);
  if (userIdUUID === null) {
    throw "Invalid userId";
  }
  return IAPv2.restoreProducts({ userIdUUID });
}
