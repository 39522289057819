import UpgradeBanner from "@components/banner/upgradeBanner";
import Empty from "@components/empty";
import FileDropZone from "@components/fileDropZone";
import { Dot } from "@components/infoDot";
import { showPrompt } from "@components/prompt";
import TipDot from "@components/tipDot";
import { IInboxItem } from "@data/idb";
import { IonButton, IonCard, IonCardHeader, IonCardSubtitle, IonIcon } from "@ionic/react";
import { Inbox } from "@models/inbox";
import logEvent from "analytics";
import ScreenComponent from "components/screen";
import EventBus from "eventBus";
import { History } from "history";
import useErrorAlert from "hooks/util/useErrorAlert";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { addOutline } from "ionicons/icons";
import Lib from "lib";
import L10n from "localization";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import DeckCreateScreen from "./deckCreate";
import InboxDeckSelectModal from "./home/inboxDeckSelectModal";
import { getPhoto } from "@lib/camera";

export function useInboxCaptureFlow() {
  const [showPhotoPickerError] = useErrorAlert({ code: "PICKING_PHOTO" });
  const history = useHistory();
  const location = useLocation();
  const pickPhoto = useCallback(async () => {
    try {
      const image = await getPhoto();
      const base64 = image.base64String;
      if (!base64) {
        return null;
      }

      const mimeType = `image/${image.format}`;
      const blob = Lib.base64ToBlob(base64, mimeType, 512);
      await Inbox.addBlobToInbox(blob);
      if (location.pathname !== "/inbox") {
        history.push("/inbox");
      }
      logEvent("inbox_event", { step: "item_added" });
    } catch (err) {
      if (err instanceof Error && err.message === "User cancelled photos app") {
        return;
      }
      if (err instanceof Error && err.message.startsWith("User denied access")) {
        showPrompt({
          prompt: L10n.localize((s) => s.errors.photoPermissionDenied),
          promptType: "alert",
          title: L10n.localize((s) => s.general.attention),
        });
        return;
      }
      showPhotoPickerError(err);
    }
  }, [showPhotoPickerError, history.push, location.pathname]);
  return pickPhoto;
}

function InboxItem({ item, history }: { item: IInboxItem; history: IProps["history"] }) {
  const [blobURI, setBlobURI] = useState<string>();
  useEffect(() => {
    const uri = URL.createObjectURL(item.blob);
    setBlobURI(uri);
    return () => {
      URL.revokeObjectURL(uri);
    };
  }, [item]);

  const handleDelete = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      if (item.saved_at) {
        Inbox.deleteItem(item).catch(() => {
          // uh oh
        });
        return;
      }
      showPrompt({
        title: L10n.localize((s) => s.actions.confirm),
        prompt: L10n.localize((s) => s.actions.confirmDelete),
        promptType: "dangerousConfirm",
        dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.delete).toLocaleUpperCase(),
        callback: async () => {
          Inbox.deleteItem(item).catch(() => {
            // uh oh
          });
        },
      });
    },
    [item],
  );

  const handleOpenDeck = useCallback(
    (evt: React.MouseEvent) => {
      evt.stopPropagation();
      history.push(`/decks/local/${item.saved_to_deck_id}`);
    },
    [item],
  );

  const [presentNewDeckModal] = useIonCardModalWithDismiss(DeckCreateScreen, { history });
  const [handleClick] = useIonCardModalWithDismiss(InboxDeckSelectModal, {
    item,
    presentNewDeckModal,
    history,
  });
  return (
    <IonCard button onClick={handleClick} style={{ maxWidth: 320 }}>
      {item.saved_at ? undefined : <Dot style={{ position: "absolute", top: 6, right: 6 }} />}
      {blobURI ? <img src={blobURI} /> : undefined}
      <IonCardHeader>
        <IonCardSubtitle>{item.created_at.toLocaleString()}</IonCardSubtitle>
      </IonCardHeader>
      <IonButton color="danger" fill="clear" onClick={handleDelete}>
        {item.saved_at
          ? L10n.localize((s) => s.actions.remove)
          : L10n.localize((s) => s.actions.delete)}
      </IonButton>
      {item.saved_at ? (
        <IonButton fill="clear" onClick={handleOpenDeck}>
          {L10n.localize((s) => s.actions.open)}
        </IonButton>
      ) : undefined}
    </IonCard>
  );
}

export function InboxBody(props: IProps) {
  const [items, setItems] = useState<IInboxItem[]>([]);
  useEffect(() => {
    function refetch() {
      Inbox.getInboxItems()
        .then(setItems)
        .catch(() => {
          // oh no
        });
    }
    refetch();
    EventBus.on("inboxUpdated", refetch);
    return () => {
      EventBus.off("inboxUpdated", refetch);
    };
  }, []);

  const handleFileDropped = useCallback(async (file: File) => {
    if (file.type.startsWith("image/")) {
      await Inbox.addBlobToInbox(file);
      logEvent("inbox_event", { step: "item_added" });
    }
  }, []);

  return (
    <FileDropZone
      style={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        minHeight: "100%",
      }}
      onFileSelected={handleFileDropped}
    >
      {items.length < 1 ? <Empty text={L10n.localize((s) => s.inbox.empty)} /> : undefined}
      {items.map((item) => (
        <InboxItem key={item.sha256} item={item} history={props.history} />
      ))}
      {items.length > 0 ? (
        <TipDot dotID="inbox_body" message={L10n.localize((s) => s.inbox.bodyTip)} />
      ) : undefined}
    </FileDropZone>
  );
}

export function AddToInboxButton() {
  const pickPhoto = useInboxCaptureFlow();
  return (
    <IonButton onClick={pickPhoto} size="large">
      <IonIcon icon={addOutline} />
    </IonButton>
  );
}

interface IProps {
  history: History;
}
export default function InboxScreen(props: IProps): JSX.Element {
  return (
    <ScreenComponent
      id="main"
      slug="Inbox"
      defaultBackLink="/"
      headerBanner={<UpgradeBanner />}
      title={L10n.localize((s) => s.general.inbox)}
      rightButton={<AddToInboxButton />}
      content={<InboxBody history={props.history} />}
    />
  );
}
