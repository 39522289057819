import React from "react";
import { IGroupHeader } from "./types";
import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { peopleOutline } from "ionicons/icons";

interface IProps {
  header: IGroupHeader;
}
export default function StudyGroupListItem(props: IProps): JSX.Element {
  const { header } = props;
  const { id, name } = header;

  return (
    <IonItem button routerLink={`/groups/${id}`}>
      <IonIcon slot="start" icon={peopleOutline} />
      <IonLabel>
        <h2>{name}</h2>
      </IonLabel>
    </IonItem>
  );
}
