// Monkey-patch to centralize logging.
// Captures original window.console in a closure and delegates to it,
// while channeling log messages into a circular queue for ability to see recent messages.

import * as Sentry from "@sentry/browser";
import Globals from "./globals";

const MAX_MESSAGES = 10;

type Level = "log" | "info" | "warn" | "error";

class Logger {
  buffer: Array<[Date, Level, any[]]> = [];
  console: Console;

  constructor() {
    this.console = window.console ?? console;
  }

  setUserId = (userId: string) => {
    if (Globals.sentryDSN) {
      Sentry.setUser({ id: userId });
    }
  };

  record = (level: Level, message: any[]) => {
    while (this.buffer.length >= MAX_MESSAGES) {
      this.buffer.shift();
    }

    this.buffer.push([new Date(), level, message]);
  };

  dump = () => {
    JSON.stringify(this.buffer);
  };

  log = (...rest: any[]) => {
    this.console.log(...rest);
    this.record("log", rest);
  };

  info = (...rest: any[]) => {
    this.console.info(...rest);
    this.record("info", rest);
  };

  warn = (...rest: any[]) => {
    this.console.warn(...rest);
    this.record("warn", rest);
  };

  error = (...rest: any[]) => {
    this.console.error(...rest);
    this.record("error", rest);
  };
}

export default new Logger();
