import { IDeckInsertOperation } from "@operations/deck";
import { ISubscriptionInsertOperation } from "@operations/subscription";
import { ALL_LAYOUT_ID } from "fields/magicLayout";
import { ID } from "types/ID";
import Events from "../../node_modules/eventemitter3/index";
import { Deck, IDeckData, IDeckUserConfig } from "./models/deck";
import { Op, Operation } from "./models/operation";
import Network from "./network";

export type DownloadStatus = "waiting" | "error" | "downloading" | "cancelled" | "complete";

export interface IDeckDownload {
  id: string;
  numKnols?: number;
  name?: string;
  numLayouts?: number;
  knolsToFetch?: number;
  status: DownloadStatus;
  knolFetchAborter?: AbortController;
  analyticsID?: ID;
  user_config?: IDeckUserConfig;
}

class DeckDownloadManager {
  decks: { [id: string]: IDeckDownload } = {};
  events: Events<string | symbol>;

  constructor() {
    this.events = new Events();
  }

  public clear() {
    this.decks = {};
    this.events.emit("clear");
  }

  public isDownloading(deckID: string): boolean {
    const d = this.decks[deckID];
    if (d) {
      if (d.status !== "cancelled" && d.status !== "error") {
        return true;
      }
    }
    return false;
  }

  public cancelDownload(deckID: string) {
    if (this.isDownloading(deckID)) {
      this.decks[deckID].status = "cancelled";
      this.decks[deckID].knolFetchAborter?.abort();
      this.events?.emit("update", this.decks?.[deckID]);
    }
    delete this.decks[deckID];
  }

  public async addDownload(uri: string): Promise<void> {
    const data = await Network.fetch<IDeckData>("GET", uri, undefined);

    const tags = (data.tags || []).map((t) => ({ name: t }));
    const opqueue = [] as Op[];

    const layout_id = data.layout_id ?? data.layouts?.[0].id ?? ALL_LAYOUT_ID;

    const deckop: IDeckInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "deck",
      object_parameters: {
        created_at: data.created_at,
        description: data.description || "",
        id: data.id,
        knols: data.knols,
        layouts: data.layouts,
        layout_id: layout_id,
        modified_at: data.modified_at,
        name: data.name,
        status: data.status,
        config: data.config,
        user_config: data.user_config,
        tags,
      },
    };
    opqueue.push(deckop);

    if (Deck.needsSubscription(data.status as Deck.DeckStatus)) {
      const op: ISubscriptionInsertOperation = {
        ...Operation.operationDefaults(),
        type: "INSERT",
        object_type: "subscription",
        object_parameters: {
          user_id: localStorage["AnkiApp.user.id"],
          deck_id: data.id,
          modified_at: data.modified_at,
          deck_name: data.name,
          deck_description: data.description,
        },
      };
      opqueue.push(op);
    }
    await Operation.operate(false, opqueue);
  }
}

export default new DeckDownloadManager();
