import React from "react";
import L10n from "localization";
import { IonList, IonItem, IonRange, IonLabel, RangeCustomEvent } from "@ionic/react";
import { minCardFontSize, maxCardFontSize } from "settings";

function pxSuffixer(px: number): string {
  return `${px}px`;
}

interface IProps {
  fontSizePx: number;
  onFontSizeChange: (px: number) => void;
  inset?: boolean;
}
export default function FontSizeControl(props: IProps): JSX.Element {
  const { fontSizePx, onFontSizeChange, inset } = props;

  return (
    <IonList inset={inset}>
      <IonItem lines="none">
        <IonRange
          aria-label="fontSizePx"
          value={fontSizePx}
          pin
          pinFormatter={pxSuffixer}
          min={minCardFontSize}
          max={maxCardFontSize}
          step={2}
          snaps={true}
          debounce={200}
          onIonChange={(e: RangeCustomEvent) => onFontSizeChange(e.detail.value as number)}
          color="secondary"
        >
          <IonLabel slot="start" style={{ fontSize: minCardFontSize, flexGrow: 0 }}>
            Aa
          </IonLabel>
          <IonLabel slot="end" style={{ fontSize: maxCardFontSize, flexGrow: 0 }}>
            Aa
          </IonLabel>
        </IonRange>
      </IonItem>
      <IonItem className="ion-item-center" style={{ textAlign: "center", flex: "auto" }}>
        <IonLabel style={{ marginTop: 8, fontSize: `${fontSizePx}px` }}>
          {L10n.localize((s) => s.settings.fontSizeExampleText)}
        </IonLabel>
      </IonItem>
    </IonList>
  );
}
