import { IDB_TRUE } from "@data/idb";
import { Deck } from "@models/deck";
import { AsyncVal, useAsyncValFromPromiseState } from "hooks/util/useAsyncValState";
import React from "react";

export default function useIsLocalDeck(id: string): AsyncVal<boolean> {
  const [isLocal, setIsLocal] = useAsyncValFromPromiseState(false);

  React.useEffect(() => {
    setIsLocal(
      Deck.find(id).then((deck) => {
        return deck !== null && deck?.local === IDB_TRUE;
      }),
    );
  }, [id, setIsLocal]);

  return isLocal;
}
