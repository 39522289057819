import ListAddItem from "@components/listAddItem";
import { showPrompt } from "@components/prompt";
import { IonButton, IonIcon, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { trashOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";

function ListItem({ field, onDelete }: { field: string; onDelete?: () => void }) {
  return (
    <IonItem>
      <IonLabel>{field}</IonLabel>
      {onDelete && (
        <IonButton slot="end" fill="clear" onClick={onDelete}>
          <IonIcon icon={trashOutline} />
        </IonButton>
      )}
    </IonItem>
  );
}

interface IProps {
  fields?: string[];
  onFieldsChange: (fields: string[]) => void;
  editable?: boolean;
}
export default function EditLayoutFields({
  fields,
  onFieldsChange,
  editable,
}: IProps): JSX.Element {
  if (!fields) {
    return <></>;
  }
  function onAddField() {
    return showPrompt({
      title: L10n.localize((s) => s.field.new),
      prompt: L10n.localize((s) => s.general.name),
      promptType: "input",
      callback: (response) => {
        const fieldName = response?.trim().replace(/\s/g, "_") ?? "";
        if (fieldName === "" || fields!.includes(fieldName)) {
          return;
        }
        return onFieldsChange(fields!.concat(fieldName));
      },
    });
  }

  function deleteField(fieldName: string) {
    if (fields!.includes(fieldName)) {
      onFieldsChange(fields!.filter((f) => f !== fieldName));
    }
  }

  const items: JSX.Element[] = [];

  for (const field of fields) {
    if (editable) {
      items.push(<ListItem key={field} field={field} onDelete={() => deleteField(field)} />);
    } else {
      items.push(<ListItem key={field} field={field} />);
    }
  }
  if (editable) {
    items.push(<ListAddItem key="$$listadditem$$" onClick={onAddField} />);
  }
  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.field.plural)}</IonListHeader>
      {items}
    </IonList>
  );
}
