import { FieldsContext } from "@screens/deckCreate";
import { useFieldEditorModal } from "fields/editors/fieldEditorModal";
import { IField } from "fields/fields";
import { useCallback, useContext } from "react";

export default function useEditFieldType(field: IField) {
  const { fields, dispatch } = useContext(FieldsContext);

  const handleFieldEditSave = useCallback(
    (newField: IField) => {
      dispatch({ type: "modify", fieldName: field.name, field: newField });
    },
    [dispatch, field.name],
  );

  const [presentFieldEditor] = useFieldEditorModal({
    initField: field,
    name: field.name,
    fields: fields ?? [],
    onSave: handleFieldEditSave,
    nameEditable: false,
  });

  return presentFieldEditor;
}
