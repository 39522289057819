import { ResponseCounts } from "@screens/review/recap/sessionStats";
import BarChart, { IBar } from "components/barChart";
import Globals, { TResponseName } from "globals";
import L10n from "localization";
import React, { useEffect, useState } from "react";
import Style from "style";

function barFor(resp: TResponseName, count: number): IBar {
  const color = Style.colors[resp];
  const label = L10n.localize((s) => s.response[resp]);
  return {
    color,
    count,
    hideEmpty: false,
    label,
  };
}

function barsFor(counts: ResponseCounts): IBar[] {
  return Object.values(Globals.RESPONSE_NAMES).map((resp) => {
    const count = counts[resp] ?? 0;
    return barFor(resp, count);
  });
}

const zeroCounts: ResponseCounts = {
  fail: 0,
  hard: 0,
  good: 0,
  easy: 0,
};
const zeroBars = barsFor(zeroCounts);

interface IResponseHistogramProps {
  counts: ResponseCounts;
  height?: string;
}
export default function ResponseHistogram({
  counts,
  height,
}: IResponseHistogramProps): React.ReactElement {
  // HACK: initialize bars with count 0, then update them, to get growth effect.
  // It would be nicer to encapsulate this behavior in BarChart, but trickier
  // to get right and avoid bouncing from zero due to object refs changing.
  const [bars, setBars] = useState(zeroBars);
  useEffect(() => {
    const bars = barsFor(counts);
    setBars(bars);
  }, [counts]);

  return <BarChart height={height} bars={bars} count={true} grow={true} />;
}
