import IconInput from "@components/iconInput";
import { showPrompt } from "@components/prompt";
import { IonButton, IonChip, IonIcon, IonLabel, IonList } from "@ionic/react";
import emailSpellChecker from "@zootools/email-spell-checker";
import Globals from "globals";
import { warningOutline } from "ionicons/icons";
import { keyOutline, mailOutline } from "ionicons/icons";
import L10n from "localization";
import React, { FormEvent } from "react";
import Style from "style";

interface IProps {
  disabled: boolean;
  onSignup: (email: string, password: string) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  passwordConfirm: string;
  setPasswordConfirm: (password: string) => void;
}
export default function Signup(props: IProps) {
  const {
    disabled,
    onSignup,
    email,
    setEmail,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
  } = props;

  const [emailWarning, setEmailWarning] = React.useState<string>("");

  function handleSignup(e: FormEvent) {
    e.preventDefault();
    if (saveShouldBeDisabled()) {
      return;
    }

    if (password !== passwordConfirm) {
      showPrompt({
        prompt: L10n.localize((s) => s.account.passwordMismatch),
        promptType: "alert",
        title: L10n.localize((s) => s.general.attention),
      });
    }

    return onSignup(email, password);
  }

  function saveShouldBeDisabled() {
    return (
      email.length < 1 ||
      !Globals.emailValidationRegex.test(email) ||
      password.length < 1 ||
      passwordConfirm.length < 1 ||
      password !== passwordConfirm
    );
  }

  const emailSuggest = React.useRef<number>();

  function handleEmailChange(newEmail: string): void {
    setEmail(newEmail);
    const suggestedEmail = emailSpellChecker.run({
      email: newEmail,
    });
    if (suggestedEmail) {
      clearTimeout?.(emailSuggest.current);
      emailSuggest.current = window.setTimeout(
        () => {
          setEmailWarning(suggestedEmail.full);
        },
        2000,
        suggestedEmail,
      );
    }
  }

  function handleEmailSuggestion(): void {
    setEmail(emailWarning);
    setEmailWarning("");
  }

  const termsStyle = {
    marginBottom: "1.5rem",
    marginTop: 24,
    textAlign: "center",
  } as React.CSSProperties;

  const linkStyle = { color: Style.colors.actionableFg };

  const mode = Globals.platform === "ios" ? "ios" : "md";

  return (
    <form onSubmit={handleSignup} id="signup-form">
      <IonList>
        <IconInput
          id="signup-email"
          icon={mailOutline}
          value={email}
          type="email"
          disabled={disabled}
          autocomplete="username"
          placeholder={L10n.localize((s) => s.account.email)}
          onChange={handleEmailChange}
        />
        {emailWarning && (
          <IonChip color="warning" outline onClick={handleEmailSuggestion}>
            <IonIcon icon={warningOutline} />
            <IonLabel>
              {L10n.localize((s) => s.account.emailSuggest)}{" "}
              <span style={{ textDecoration: "underline" }}>{emailWarning}</span>?
            </IonLabel>
          </IonChip>
        )}

        <IconInput
          id="signup-password"
          icon={keyOutline}
          value={password}
          type="password"
          disabled={disabled}
          autocomplete="new-password"
          placeholder={L10n.localize((s) => s.account.password)}
          onChange={(v: string) => setPassword(v)}
        />
        <IconInput
          id="signup-confirm"
          icon={keyOutline}
          value={passwordConfirm}
          type="password"
          disabled={disabled}
          onSubmit={handleSignup}
          autocomplete="new-password"
          placeholder={L10n.localize((s) => s.account.confirmPassword)}
          onChange={(v: string) => setPasswordConfirm(v)}
        />
      </IonList>

      <IonButton
        data-cy={
          Globals.environment === "cypress" && !disabled ? "enabled-signup-button" : undefined
        }
        style={{ marginTop: 12 }}
        expand="block"
        shape={mode === "ios" ? "round" : undefined}
        disabled={disabled || saveShouldBeDisabled()}
        onClick={handleSignup}
      >
        {L10n.localize((s) => s.account.signUp)}
      </IonButton>

      <div style={termsStyle}>
        <div>By signing up you agree to </div>
        <a href="https://www.ankiapp.com/terms.html?s=app&l=signup" style={linkStyle}>
          {L10n.localize((s) => s.account.termsOfService)}
        </a>
        <span style={{ margin: "0 6px" }}>&</span>
        <a href="https://www.ankiapp.com/privacy.html?s=app&l=signup" style={linkStyle}>
          {L10n.localize((s) => s.account.privacyPolicy)}
        </a>
      </div>
      <input type="submit" hidden />
    </form>
  );
}
