import React from "react";
import Style from "style";
import { IonList } from "@ionic/react";
import DeckDescriptionItem from "@screens/deckCreate/deckDescriptionItem";
import DeckNameItem from "@screens/deckCreate/deckNameItem";

interface IProps {
  deckDescription?: string;
  deckName: string;
  isEditable?: boolean;
  onDeckNameChange: (name?: string) => void;
  onDeckDescriptionChange: (description?: string) => void;
}
export default function DeckInfo(props: IProps): JSX.Element {
  const {
    isEditable = false,
    deckDescription,
    deckName,
    onDeckNameChange,
    onDeckDescriptionChange,
  } = props;

  return (
    <IonList style={{ paddingTop: Style.edgePadding }}>
      <DeckNameItem name={deckName} onDeckNameChange={onDeckNameChange} isEditable={isEditable} />
      <DeckDescriptionItem
        description={deckDescription}
        onDeckDescriptionChange={onDeckDescriptionChange}
        isEditable={isEditable}
      />
    </IonList>
  );
}
