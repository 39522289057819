import { DeckFields } from "@models/deck";
import { SIDES } from "fields/sides";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function richText(): IMarketItem {
  const richTextFields: DeckFields = [
    { name: fieldNames.Front(), type: "richtext", sides: SIDES.BOTH },
    { name: fieldNames.Back(), type: "richtext", sides: SIDES.BACK },
  ];
  return {
    base: "rich-text",
    name: L10n.localize((s) => s.preInstalledLayouts.richText),
    fields: richTextFields,
    description: L10n.localize((s) => s.field.typeRichTextDescription),
    values: {
      [fieldNames.Front()]: "Main points",
      [fieldNames.Back()]: `
      <ul style="text-align: left; margin-left: 16px;">
        <li><u>ABC</u></li>
        <li><b>123</b></li>
        <li><img src="/nyan.png" height=18 /></li>
      </ul>
    `,
    },
  };
}
