import { IonItem } from "@ionic/react";
import React from "react";
import ConfigEditorItem from "./configEditorItem";
import ConfigSpecificOptionsChinese from "./configSpecificOptionsChinese";
import ConfigSpecificOptionsJapanese from "./configSpecificOptionsJapanese";
import ConfigSpecificOptionsLanguage from "./configSpecificOptionsLanguage";
import { IConfigSelection } from "./main";

export type LanguageConfigTranslationSetting = "off" | "from-foreign" | "from-local";

interface IProps {
  config: IConfigSelection;
  onChange: (cfg: IConfigSelection) => void;
}
export default function ConfigSpecificOptions({ config, onChange }: IProps) {
  switch (config.base) {
    case "japanese":
      return <ConfigSpecificOptionsJapanese config={config} onChange={onChange} />;
    case "chinese":
      return <ConfigSpecificOptionsChinese config={config} onChange={onChange} />;
    case "language":
      return <ConfigSpecificOptionsLanguage config={config} onChange={onChange} />;
  }

  return (
    <IonItem lines="none" style={{ marginTop: 16 }}>
      <ConfigEditorItem
        base={config.base}
        name={config.name}
        fields={config.fields}
        values={config.values}
        onSave={onChange}
        selected
      />
    </IonItem>
  );
}
