import L10n from "localization";
import React from "react";
import Style from "../style";

interface IProps {
  abbreviate?: boolean;
  commatize?: boolean;
  fontSize?: undefined;
  numDecimals?: number;
  percent?: number; // Must be either null (indicating the card is NEW) or a number in range [0, 1.0]
  showLetter?: boolean;
  showPercent?: boolean;
  num?: number;
}
export default function Num(props: IProps) {
  const {
    abbreviate = false,
    commatize = true, // Should commas (or other appropriate separator) be inserted in long strings of numbers.
    fontSize = undefined,
    num = 0,
  } = props; // Display e.g. 1234 as 1.2k.

  const style = {
    color: num === 0 ? Style.colors.mutedFg : undefined,
    fontSize: fontSize,
  };

  if (abbreviate && num > 999) {
    // Shorten it to something like 1.3k.
  } else if (commatize) {
    //num = numeral(num).format("0,0");
  }
  return (
    <span style={style}>
      <span>
        {Number.isNaN(num) ? "..." : new Intl.NumberFormat(L10n.currentLocale).format(num)}
      </span>
    </span>
  );
}
