import { DeckFields, IDeck } from "@models/deck";
import { IField } from "fields/fields";
import clone from "lib/clone";
import { useEffect, useReducer, useRef, useState } from "react";
import { useDeckFields } from "./useDeckFields";

interface IFieldsAddMutation {
  type: "add";
  field: IField;
}

interface IFieldsRemoveMutation {
  type: "remove";
  fieldName: string;
}

interface IFieldsReorderMutation {
  type: "reorder";
  fromIdx: number;
  toIdx: number;
}

interface IFieldMutation {
  type: "modify";
  fieldName: string;
  field: IField;
  fromIdx?: number;
  toIdx?: number;
}

interface IFieldsLoadMutation {
  type: "load";
  fields: DeckFields;
}

export type FieldsMutation =
  | IFieldsLoadMutation
  | IFieldsAddMutation
  | IFieldsRemoveMutation
  | IFieldsReorderMutation
  | IFieldMutation;

function fieldsReducer(state: DeckFields, action: FieldsMutation): DeckFields {
  const copy = clone(state);

  function reorderInPlace(copy: DeckFields, fromIdx: number, toIdx: number) {
    // Remove field from position fromIdx.
    const name = copy.splice(fromIdx, 1)[0];

    // Re-insert field at position toIdx.
    copy.splice(toIdx, 0, name);
  }

  switch (action.type) {
    case "load": {
      return action.fields;
    }
    case "add": {
      copy.push(action.field);
      return copy;
    }
    case "remove": {
      return copy.filter((field) => field.name !== action.fieldName);
    }
    case "reorder": {
      const { toIdx, fromIdx } = action;
      reorderInPlace(copy, fromIdx, toIdx);
      return copy;
    }
    case "modify": {
      const { fieldName, field, fromIdx, toIdx } = action;

      // Swap, if reorder coordinates specified.
      if (fromIdx !== undefined && toIdx !== undefined) {
        reorderInPlace(copy, fromIdx, toIdx);
      }

      // Modify.
      const fieldIdx = copy.findIndex((f) => f.name === fieldName);
      if (fieldIdx < 0) {
        return state;
      }
      copy[fieldIdx] = field;
      return copy;
    }
  }
}

function fieldsReducerLogger(state: DeckFields, action: FieldsMutation): DeckFields {
  return fieldsReducer(state, action);
}

export default function useFieldsReducer(
  init: DeckFields,
): [DeckFields, React.Dispatch<FieldsMutation>] {
  return useReducer(fieldsReducerLogger, init);
}

const emptyFields: DeckFields = [];

export function useDeckFieldsReducer(
  deck: IDeck | undefined,
  inferFieldsForLegacyDecks = false,
): [DeckFields, React.Dispatch<FieldsMutation>, boolean, boolean] {
  const fieldsVal = useDeckFields(deck, inferFieldsForLegacyDecks);
  const [fields, dispatch] = useFieldsReducer(emptyFields);
  const [loaded, setLoaded] = useState(false);

  // Track edited state.
  const initialFields = useRef<DeckFields>();

  useEffect(() => {
    if (fieldsVal) {
      dispatch({ type: "load", fields: fieldsVal });
      setLoaded(true);

      initialFields.current = clone(fieldsVal);
    }
  }, [dispatch, fieldsVal]);

  const edited =
    initialFields.current !== undefined &&
    JSON.stringify(initialFields.current) !== JSON.stringify(fields);

  return [fields, dispatch, loaded, edited];
}
