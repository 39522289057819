import UpgradeBanner from "@components/banner/upgradeBanner";
import { showPrompt } from "@components/prompt";
import { IonList } from "@ionic/react";
import { Deck } from "@models/deck";
import ScreenComponent from "components/screen";
import EventBus from "eventBus";
import { IDeckListDeckRow, useDeckList } from "hooks/data/deckList";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import React, { useCallback } from "react";
import DeckItem from "./decks/deckItem";
import { deckListRowMatchesQuery } from "./search/deckResults";
import Empty from "@components/empty";

function ArchiveItem(props: {
  row: IDeckListDeckRow;
}): JSX.Element | null {
  const { row } = props;
  const { deck } = row;

  const name = Deck.displayName(deck.name ?? L10n.localize((s) => s.general.none));

  const [showRestoreError] = useErrorAlert({ code: "RESTORING_DECK" });
  const [showDeleteError] = useErrorAlert({ code: "DELETING_DECK" });

  const handleClick = useCallback(() => {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.archive.confirmRestore),
      promptType: "confirm",
      callback: async () => {
        try {
          await Deck.unarchive(deck);
          EventBus.emit("deckUpdated", { ID: deck.id });
        } catch (err) {
          showRestoreError(err);
        }
      },
    });
    return;
  }, [deck]);

  async function handleDelete() {
    if (!deck || !deck.status || deck.status !== Deck.STATUS_PRIVATE) {
      return;
    }

    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.deck.confirmRemovalDangerous),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.delete).toLocaleUpperCase(),
      callback: async () => {
        try {
          await Deck.PermanentlyDeleteDeck(deck.id);
        } catch (err) {
          showDeleteError(err);
        }
      },
    });
  }

  return (
    <DeckItem
      key={deck.id}
      type={row.type}
      name={name}
      onClick={handleClick}
      numKnols={row.count}
      hideMetadata
      hideStatusIcon
      local={deck.local}
      shared={deck.shared}
      onDelete={handleDelete}
      archived
    />
  );
}

export function ArchiveBody({ query }: { query?: string | undefined }) {
  const deckList = useDeckList({ archiveOnly: true });
  const matchingRows =
    query !== undefined
      ? deckList.rows.filter((r) => deckListRowMatchesQuery(r, query))
      : deckList.rows;
  const decks: IDeckListDeckRow[] = [];
  for (const row of matchingRows) {
    if (row.type === "deck") {
      decks.push(row);
    }
  }

  if (!deckList.loading && deckList.rows.length < 1) {
    return <Empty text={L10n.localize((s) => s.archive.empty)} />;
  }

  return (
    <IonList>
      {decks.map((r) => (
        <ArchiveItem key={r.type + r.deck.id} row={r} />
      ))}
    </IonList>
  );
}

export default function ArchiveScreen(): JSX.Element {
  const [query, setQuery] = React.useState<string>();
  return (
    <ScreenComponent
      id="main"
      slug="Archive"
      defaultBackLink="/"
      headerBanner={<UpgradeBanner />}
      title={L10n.localize((s) => s.archive.noun)}
      onLiveSearch={setQuery}
      searchDebounceMs={50}
      content={<ArchiveBody query={query} />}
    />
  );
}
