import { folderDisplayString } from "@components/folderWithSubfoldersString";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonNote,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Deck, IDeck, IDeckConfig } from "@models/deck";
import { Operation } from "@models/operation";
import { IDeckUpdateOperation } from "@operations/deck";
import FieldsWarningOrError from "@screens/deckConfig/fieldsWarningOrError";
import UnusedDeckFields from "@screens/deckConfig/unusedDeckFields";
import { FieldsContext } from "@screens/deckCreate";
import DeckInfo from "components/deckInfo";
import { IField } from "fields/fields";
import { useIsLegacyDeck } from "fields/magicLayout";
import { SIDES } from "fields/sides";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import useErrorAlert from "hooks/util/useErrorAlert";
import { checkmarkCircleOutline } from "ionicons/icons";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React, { useContext, useCallback } from "react";
import Style from "style";
import FieldList from "../../fields/components/fieldList";

enum DeckFieldsSidesState {
  None = 0,
  AllFront = 1,
  AllBack = 2,
  Mixed = 3,
}

function checkSides(items: IField[]): DeckFieldsSidesState {
  if (!items || items.length < 1) {
    return DeckFieldsSidesState.None;
  }
  const allFront = items.every((item) => item.sides[0] === 1 && item.sides[1] === 0);
  const allBack = items.every((item) => item.sides[0] === 0 && item.sides[1] === 1);

  if (allFront) {
    return DeckFieldsSidesState.AllFront;
  }
  if (allBack) {
    return DeckFieldsSidesState.AllBack;
  }
  return DeckFieldsSidesState.Mixed;
}

export default function DeckEditNav(props: {
  deck: IDeck;
  dismiss: () => void;
}): JSX.Element {
  const [state, setState] = React.useState({
    loading: true,
    isEditable: false,
    initialDeckName: "",
    initialFolder: undefined as string | undefined,
    initialDeckDescription: "",
  });

  const fieldsCtx = useContext(FieldsContext);

  const [name, setName] = React.useState("");
  const [folder, setFolder] = React.useState<string>();
  const [description, setDescription] = React.useState("");
  const [presentSaveToast] = useDismissibleToast();

  const deck = props.deck;

  React.useEffect(() => {
    const name = deck.name ?? "";
    const folder = deck.tags?.[0] ?? "";
    const description = deck.description ?? "";
    setState({
      loading: false,
      isEditable: deck.status === Deck.STATUS_PRIVATE,
      initialDeckName: name,
      initialFolder: folder,
      initialDeckDescription: description,
    });
    setName(name);
    setFolder(folder);
    setDescription(description);
  }, [deck]);

  const modified =
    name !== state.initialDeckName ||
    description !== state.initialDeckDescription ||
    folder !== state.initialFolder ||
    fieldsCtx.modified === true;

  const saveDisabled = !state.isEditable || !modified || !name;

  // Legacy decks don't get to edit fields, because you could get into
  // a state you can't get out of (lose blob rendering).
  const legacy = useIsLegacyDeck(deck);
  const disableFields = legacy || !state.isEditable;

  const [showSaveErrorAlert] = useErrorAlert({ code: "SAVING_DECK" });
  const onSave = useCallback(() => {
    if (!state.isEditable || !modified) {
      return;
    }

    let config: IDeckConfig | undefined;
    if (!legacy) {
      // NOTE: if we ever let legacy decks edit field configs, to migrate
      // off of being legacy, this must change.
      config = {
        fields: fieldsCtx.fields,
      };
    }

    const op: IDeckUpdateOperation = {
      ...Operation.operationDefaults(),
      type: "UPDATE",
      object_type: "deck",
      object_parameters: {
        id: deck.id,
        name,
        description,
        config,
      },
    };

    Operation.operateAndSave(op)
      .then(() => {
        presentSaveToast({
          message: L10n.localize((s) => s.actions.saved),
          icon: checkmarkCircleOutline,
          position: "top",
          color: "success",
          duration: 1000,
        });
        props.dismiss();
      })
      .catch((err) => {
        showSaveErrorAlert(err);
      });
  }, [
    showSaveErrorAlert,
    deck.id,
    description,
    name,
    fieldsCtx.fields,
    legacy,
    state.isEditable,
    modified,
    presentSaveToast,
    props.dismiss,
  ]);

  function onDeckNameChange(name?: string) {
    if (!state.isEditable || !name) {
      return;
    }
    setName(name);
  }

  function onDeckDescriptionChange(description?: string) {
    if (!state.isEditable || !description) {
      return;
    }
    setDescription(description);
  }

  function handleClose() {
    // TODO: confirm cancel.
    props.dismiss();
  }

  const title = state.isEditable
    ? L10n.localize((s) => s.deck.edit)
    : L10n.localize((s) => s.deck.info);
  const fullSlug = `${title}-screen`;

  function resurrectField(name: string) {
    const field: IField = { name, type: "richtext", sides: SIDES.BACK };
    fieldsCtx.dispatch({ type: "add", field });
  }

  const sideState = checkSides(fieldsCtx.fields);
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton color={modified ? "danger" : "primary"} onClick={handleClose}>
              {modified
                ? L10n.localize((s) => s.actions.cancel)
                : L10n.localize((s) => s.actions.close)}
            </IonButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
          <IonButtons slot="end">
            <IonButton disabled={saveDisabled} onClick={onSave}>
              {L10n.localize((s) => s.actions.save)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent
        {...cyAttr(fullSlug)}
        style={
          { "--padding-bottom": "96px" } as React.CSSProperties & { "--padding-bottom": string }
        }
      >
        <div
          style={{
            paddingLeft: Style.edgePadding,
            paddingRight: Style.edgePadding,
          }}
        >
          <DeckInfo
            deckName={name}
            deckDescription={description}
            isEditable={state.isEditable}
            onDeckNameChange={onDeckNameChange}
            onDeckDescriptionChange={onDeckDescriptionChange}
          />

          <IonItem>
            <IonLabel>
              <h2>{L10n.localize((s) => s.folder.singular)}</h2>
            </IonLabel>
            <IonNote slot="end">
              {folder ? folderDisplayString(folder) : L10n.localize((s) => s.general.none)}
            </IonNote>
          </IonItem>
          {legacy ? undefined : (
            <>
              {sideState === DeckFieldsSidesState.None && (
                <FieldsWarningOrError
                  type="error"
                  title={L10n.localize((s) => s.deck.missingFieldsDetectedHeader)}
                  description={L10n.localize((s) => s.deck.missingFieldsDetectedBody)}
                  onResurrectField={resurrectField}
                />
              )}
              <UnusedDeckFields
                deck={deck}
                fields={fieldsCtx.fields}
                onResurrectField={resurrectField}
              />
              {sideState === DeckFieldsSidesState.AllFront && (
                <FieldsWarningOrError
                  onResurrectField={resurrectField}
                  type="warning"
                  title={L10n.localize((s) => s.deck.fieldsOnlyOnFrontHeader)}
                  description={L10n.localize((s) => s.deck.fieldsOnlyOnFrontBody)}
                />
              )}
              {sideState === DeckFieldsSidesState.AllBack && (
                <FieldsWarningOrError
                  type="warning"
                  onResurrectField={resurrectField}
                  title={L10n.localize((s) => s.deck.fieldsOnlyOnBackHeader)}
                  description={L10n.localize((s) => s.deck.fieldsOnlyOnBackBody)}
                />
              )}
              <FieldList
                fields={fieldsCtx.fields}
                dispatch={fieldsCtx.dispatch}
                isExistingDeck
                disabled={disableFields}
              />
            </>
          )}
        </div>
      </IonContent>
    </>
  );
}
