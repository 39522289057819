import FeatureFlags from "featureFlags";
import { Platform, isMacAppStoreElectron } from "globals";
import { AsyncVal } from "hooks/util/useAsyncValState";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import Network from "network";
import React, { useEffect, useState } from "react";
import AndroidPDPContent from "./androidPDPContent";
import ApplePDPContent from "./applePDPContent";
import PDPLogger from "./logger";
import { IServerProduct } from "./types";
import WebPDPContent from "./webPDPContent";

export interface IPDPContentProps {
  userID: string;
  email: string;
  reason?: string;
  dismiss: () => void;
  logger: PDPLogger;
}

interface IProps extends IPDPContentProps {
  platform: Platform;
  onPurchaseComplete?: () => void;
}
export default function PDPContent({
  logger,
  reason,
  platform,
  userID,
  email,
  onPurchaseComplete,
  dismiss,
}: IProps) {
  function handlePurchaseComplete() {
    onPurchaseComplete?.();
    FeatureFlags.invalidateFlagCache();
  }

  const [showProductsFetchError] = useErrorAlert({ code: "FETCHING_PRODUCTS" });
  const [productsState, setProductsState] = useState<AsyncVal<IServerProduct[]>>({
    val: undefined,
    loading: false,
    error: undefined,
  });
  useEffect(() => {
    setProductsState({ loading: true });
    Network.fetch<IServerProduct[]>("GET", "/products", {
      locale: L10n.currentLocale,
      email,
    })
      .then((products) => {
        logger.log("products_fetched", { products });
        setProductsState({ val: products, loading: false });
      })
      .catch((error) => {
        logger.log("products_fetch_failed", { err: error });
        setProductsState({ loading: false, error });
        showProductsFetchError(error);
      });
  }, [email, logger, showProductsFetchError]);

  const { val: products } = productsState;

  // HACK: to condense the subscription UI,
  // I'm just showing the product description for one product,
  // because they have identical features just different durations.
  const description = products?.[0]?.description ?? "";

  const pdpProps = {
    serverProducts: products,
    onPurchaseComplete: handlePurchaseComplete,
    userID,
    email,
    logger,
    dismiss,
    description,
    reason,
  };

  if (isMacAppStoreElectron()) {
    return <ApplePDPContent {...pdpProps} />;
  }

  switch (platform) {
    case "web":
      return <WebPDPContent {...pdpProps} />;
    case "ios":
      return <ApplePDPContent {...pdpProps} />;
    case "android":
      return <AndroidPDPContent {...pdpProps} />;
  }
}
