import React from "react";
import hljs from "highlight.js/lib/common";
import "highlight.js/styles/github-dark.css";
import { editableValueClassName } from "./editableValueWrapper";

interface IProps {
  children: React.ReactNode;
  lang?: string;
}

export default function CodeWrapper({ children, lang }: IProps): React.ReactElement {
  const defaultLang = "c";
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const codeEl =
      (ref.current?.getElementsByClassName(editableValueClassName)[0] as HTMLElement) ??
      ref.current;
    if (codeEl) {
      hljs.highlightElement(codeEl);
    }
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div
        style={{
          fontFamily: "monospace, monospace",
          whiteSpace: "pre-line",
          textAlign: "start",
          padding: 18,
        }}
        ref={ref}
        className={`lang-${lang ?? defaultLang}`}
      >
        {children}
      </div>
    </div>
  );
}
