import React from "react";
import { FieldType, fieldTypeMap } from "fields/fields";
import { checkmarkOutline } from "ionicons/icons";
import { IonItem, IonLabel, IonIcon, IonNote } from "@ionic/react";
import FieldTypeIcon from "./fieldTypeIcon";
import { defaultFieldType } from "fields/lib";

interface IProps {
  type: FieldType;
  selected?: boolean;
  detail?: boolean;
  onClick?: () => void;
  note?: string;
  disabled?: boolean;
}

export default function FieldTypeListItem({
  type,
  selected,
  detail = false,
  onClick,
  note,
  disabled,
}: IProps) {
  const fieldType = fieldTypeMap[type] ?? fieldTypeMap[defaultFieldType];
  const name = fieldType.name();
  const desc = fieldType.description();
  return (
    <IonItem key={type} button detail={detail} onClick={onClick} disabled={disabled}>
      <FieldTypeIcon type={type} />
      <IonLabel className="ion-text-wrap">
        <h2>{name}</h2>
        <p>{desc}</p>
      </IonLabel>
      {selected ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
      {note ? <IonNote style={{ textTransform: "capitalize" }}>{note}</IonNote> : undefined}
    </IonItem>
  );
}
