export function isEmptyIterable<T>(iterable: Iterable<T>): boolean {
  const iterator = iterable[Symbol.iterator]();
  const firstResult = iterator.next();
  // Explicitly compare `done` to true, treating undefined as false
  return firstResult.done === true;
}

export function* mapIterable<T, U>(iterable: Iterable<T>, transform: (x: T) => U): Generator<U> {
  for (const item of iterable) {
    yield transform(item);
  }
}

export function* takeFirstN<T>(iterable: Iterable<T>, n: number): Generator<T> {
  let count = 0;
  for (const item of iterable) {
    if (count >= n) return; // Stop after yielding N elements
    yield item;
    count++;
  }
}

export function* filterIterable<T>(
  iterable: Iterable<T>,
  filter: (x: T) => boolean | undefined,
): Generator<T> {
  for (const item of iterable) {
    if (filter(item)) {
      yield item;
    }
  }
}
