import React from "react";

export default function NestingIndicator({ level }: { level: number }) {
  if (!level) {
    return null;
  }
  return (
    <span
      style={{ marginLeft: 16 * (level - 1), marginRight: 4, color: "var(--ion-color-medium)" }}
    >
      ┗
    </span>
  );
}
