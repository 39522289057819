import React from "react";
import { IMember } from "./types";
import L10n from "localization";
import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
} from "@ionic/react";
import { User } from "@models/user";
import { personOutline, trash } from "ionicons/icons";
import { RiVipCrownLine } from "react-icons/ri";

export default function StudyGroupMemberView(props: {
  member: IMember;
  isLast: boolean;
  isOwner?: boolean;
  onKickMember?: (member: IMember) => void;
}): JSX.Element {
  const { member, isLast, onKickMember, isOwner = false } = props;
  const { email, activity } = member;

  const { last_7d_reviews_count } = activity;
  const reviewCountLast7d = new Intl.NumberFormat(L10n.currentLocale).format(last_7d_reviews_count);

  const isMe = email === User.email();

  const name = `${member.name_first ?? ""} ${member.name_last ?? ""}`.trim();
  const label = isMe ? L10n.localize((s) => s.groups.me) : name || email;

  return (
    <IonItemSliding>
      <IonItem lines={isLast ? "none" : undefined}>
        {isOwner ? (
          <div slot="start">
            <RiVipCrownLine size={24} />
          </div>
        ) : (
          <IonIcon slot="start" icon={personOutline} />
        )}
        <IonLabel>
          <h2>{label}</h2>
          <p>
            {L10n.localize((s) => s.groups.last7d)}: {reviewCountLast7d}{" "}
            {L10n.localize((s) =>
              last_7d_reviews_count === 1 ? s.review.singular : s.review.plural,
            )}
          </p>
        </IonLabel>
        <IonNote />
      </IonItem>
      {!isMe && !isOwner && onKickMember && (
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={() => onKickMember(member)}>
            <IonIcon slot="icon-only" icon={trash} />
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
}
