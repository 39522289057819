import { ISection } from "@components/sidebar";
import { ArchiveBody } from "@screens/archive";
import { InboxBody } from "@screens/inbox";
import { StudyGroupsSection } from "@screens/studyGroups";
import { History } from "history";
import React from "react";
import { BodySection, SortOptions } from "../decks/types";
import CombinedFolderAndDeckList from "./combinedFolderAndDeckList";
import HomeSection from "./homeSection";

interface IProps {
  query?: string;
  sortOrder?: SortOptions;
  handleSort: (s: SortOptions) => void;
  activeSection: BodySection;
  setActiveSection: (section: BodySection) => void;
  maxItems: number;
  sections: ISection[];
  history: History;
  onNewDeck: () => void;
  onImportDeck: () => void;
  onAddToInbox: () => void;
}
export default function HomeBody(props: IProps): JSX.Element | null {
  const { query, sortOrder, handleSort, activeSection, setActiveSection, maxItems, sections } =
    props;

  let body: JSX.Element | null = null;
  switch (activeSection) {
    case "decks":
      body = (
        <CombinedFolderAndDeckList
          handleSort={handleSort}
          sortOrder={sortOrder}
          query={query}
          maxItems={maxItems}
          onNewDeck={props.onNewDeck}
          onImportDeck={props.onImportDeck}
          onAddToInbox={props.onAddToInbox}
          history={props.history}
        />
      );
      break;
    case "studygroups":
      body = <StudyGroupsSection />;
      break;
    case "archive":
      body = <ArchiveBody query={query} />;
      break;
    case "inbox":
      body = <InboxBody history={props.history} />;
      break;
  }

  return (
    <HomeSection
      sections={sections}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
    >
      {body}
    </HomeSection>
  );
}
