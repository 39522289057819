import NestedNav from "@components/nestedNav";
import React from "react";
import L10n from "localization";
import useStateTee from "hooks/util/useStateTee";
import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";

export const defaultAnnotationMode: AnnotationMode = "hint";

export type AnnotationMode = "hint" | "always" | "back" | "off";
interface IModeWithDescription {
  mode: AnnotationMode;
  name: string;
  desc: string;
}

export function modesWithDescriptions(): IModeWithDescription[] {
  return [
    {
      mode: "hint",
      name: L10n.localize((s) => s.field.hint),
      desc: L10n.localize((s) => s.field.annotationModeHintDescription),
    },
    {
      mode: "always",
      name: L10n.localize((s) => s.general.always),
      desc: L10n.localize((s) => s.field.annotationModeAlwaysDescription),
    },
    {
      mode: "back",
      name: L10n.localize((s) => s.general.back),
      desc: L10n.localize((s) => s.field.annotationModeBackDescription),
    },
    {
      mode: "off",
      name: L10n.localize((s) => s.general.off),
      desc: L10n.localize((s) => s.field.annotationModeOffDescription),
    },
  ];
}

export function annotationModeName(mode?: AnnotationMode): string {
  switch (mode) {
    case "always":
      return L10n.localize((s) => s.general.always);
    case "back":
      return L10n.localize((s) => s.general.back);
    case "hint":
      return L10n.localize((s) => s.field.hint);
    case "off":
      return L10n.localize((s) => s.general.off);
    default:
      return L10n.localize((s) => s.field.hint);
  }
}

export const annotationModes = modesWithDescriptions().map(({ mode }) => mode);

interface IProps {
  mode?: AnnotationMode;
  onSelect: (newMode: AnnotationMode) => void;
  title: string;
}
export default function AnnotationModeNav(props: IProps) {
  // HACK: Ionic's Navs don't update properly when their props change :(
  const [selectedMode, selectMode] = useStateTee<AnnotationMode>(
    props.mode ?? defaultAnnotationMode,
    props.onSelect,
  );
  const modes = modesWithDescriptions();

  return (
    <NestedNav title={props.title}>
      <IonList>
        {modes.map(({ mode, name, desc }) => {
          const selected = mode === selectedMode;
          return (
            <IonItem key={mode} button detail={false} onClick={() => selectMode(mode)}>
              <IonLabel>
                <h2>{name}</h2>
                <p>{desc}</p>
              </IonLabel>
              {selected ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
            </IonItem>
          );
        })}
      </IonList>
    </NestedNav>
  );
}
