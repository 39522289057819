import React from "react";
import { ID } from "types/ID";
import AudioBlob from "./audioBlob";
import ImageBlob from "./imageBlob";

interface IBlobOptions {
  id: ID;
  type?: string;
  url?: string;
  disableAudioPlayback: boolean;
  disableImageZoom: boolean;
  preventAudioOrchestration: boolean;
}

export default function TypedBlob(props: IBlobOptions): JSX.Element | null {
  const { id, type, url, disableAudioPlayback, disableImageZoom, preventAudioOrchestration } =
    props;

  const isAudio =
    type?.startsWith("audio") ||
    type === "video/3gpp" ||
    type === "video/webm" ||
    type === "video/mp4";
  if (url && isAudio) {
    return (
      <AudioBlob
        disabled={disableAudioPlayback}
        preventOrchestration={preventAudioOrchestration}
        id={id}
        url={url}
      />
    );
  }

  const isImage = type?.startsWith("image");
  if (url && isImage) {
    return <ImageBlob blobId={id} disableZoom={disableImageZoom} url={url} />;
  }

  return null;
}
