import TextFieldLangSelector from "fields/components/textFieldLangSelector";
import { IFieldEditorProps } from "fields/fieldType";
import { IFieldRichText } from "fields/types/richText";
import React from "react";
import FieldEditorGeneric from "./fieldEditorGeneric";

export default function FieldEditRichText({
  fields,
  field,
  onChange,
  creatingConfig,
}: IFieldEditorProps<IFieldRichText>): JSX.Element {
  return (
    <FieldEditorGeneric
      fields={fields}
      field={field}
      onChange={onChange}
      creatingConfig={creatingConfig}
    >
      <TextFieldLangSelector field={field} onChange={onChange} langCode={field.attributes?.lang} />
    </FieldEditorGeneric>
  );
}
