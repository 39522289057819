import { IonItem, IonLabel, IonNavLink, IonNote } from "@ionic/react";
import L10n from "localization";
import React from "react";
import { defaultCardFontSize } from "settings";
import FieldEditorGeneric from "./fieldEditorGeneric";
import FontSizeNav from "./fontSizeNav";
import { IFieldChinese } from "fields/types/chinese";
import AnnotationModeNav, { AnnotationMode, annotationModeName } from "./annotationModeNav";
import { LanguageSelectorItem } from "./languageSelectorItem";
import { chineseLangs, humanLanguages, isBFF69, translationLanguages } from "fields/lang";
import { includesPipelineNodeType } from "fields/sources/lib";
import { IFieldEditorProps } from "fields/fieldType";

export default function FieldEditorChinese({
  field,
  fields,
  onChange,
  creatingConfig,
}: IFieldEditorProps<IFieldChinese>): JSX.Element {
  const fontSizePx = field.attributes?.fontSizePx ?? defaultCardFontSize;
  function handleFontSizeChange(px: number) {
    onChange({
      ...field,
      attributes: {
        ...field.attributes,
        fontSizePx: px,
      },
    });
  }

  function handlePinyinMode(mode: AnnotationMode) {
    onChange({
      ...field,
      attributes: {
        ...field.attributes,
        pinyinMode: mode,
      },
    });
  }

  function handleLanguageChange(lang: string | undefined) {
    if (lang && isBFF69(lang)) {
      onChange({
        ...field,
        attributes: {
          ...field.attributes,
          lang,
        },
      });
    }
  }

  const baseLangs = includesPipelineNodeType(field.source, "translation")
    ? translationLanguages()
    : humanLanguages();
  const langs = baseLangs.filter((hl) => chineseLangs.includes(hl.code));

  return (
    <FieldEditorGeneric
      field={field}
      fields={fields}
      onChange={onChange}
      creatingConfig={creatingConfig}
    >
      <IonNavLink
        routerDirection="forward"
        component={() => (
          <FontSizeNav fontSizePx={fontSizePx} onFontSizeChange={handleFontSizeChange} />
        )}
      >
        <IonItem button>
          <IonLabel>{L10n.localize((s) => s.general.fontSize)}</IonLabel>
          <IonNote slot="end">{fontSizePx}px</IonNote>
        </IonItem>
      </IonNavLink>

      <LanguageSelectorItem
        field={field}
        langCode={field.attributes?.lang}
        langs={langs}
        onChange={handleLanguageChange}
      />

      <IonNavLink
        routerDirection="forward"
        component={() => (
          <AnnotationModeNav
            title={L10n.localize((s) => s.field.pinyin)}
            mode={field.attributes?.pinyinMode}
            onSelect={handlePinyinMode}
          />
        )}
      >
        <IonItem button>
          <IonLabel>{L10n.localize((s) => s.field.pinyin)}</IonLabel>
          <IonNote slot="end">{annotationModeName(field.attributes?.pinyinMode)}</IonNote>
        </IonItem>
      </IonNavLink>
    </FieldEditorGeneric>
  );
}
