import TextInterpolator from "cardRendering/textInterpolator";
import TTSWrapper from "cardRendering/ttsWrapper";
import Lib from "lib";
import React from "react";
import { isSpeechSupported } from "speech";
import Replate, { type INodeCompiler, type IBodyCompiler, ReplateContext } from "vendor/replate";

export const frontSideMagicKey = "FrontSide";

export function extractInterpolationVarsFromTemplate(template: string): string[] {
  // TODO: dedupe with interpolationTransformer etc... below.
  const attrInterpolationRegex = /{{([^}]+?)}}/g;
  const bracketedAttrInterpolationRegex = /{{\[([^\]]+?)\]}}/g;

  const vars: string[] = [];
  for (const match of template.matchAll(attrInterpolationRegex)) {
    const v = match[1];
    vars.push(v);
  }
  for (const match of template.matchAll(bracketedAttrInterpolationRegex)) {
    const v = match[1];
    vars.push(v);
  }

  return vars;
}

export default function Template(
  template: string,
  name: string,
  disableTouchEvents: boolean,
): React.FunctionComponent {
  if (disableTouchEvents == null) {
    disableTouchEvents = false;
  }
  const conditionalTransformer: IBodyCompiler = {
    regex: /^{{#if (\w+?)}}(.*?)(?:{{else}}(.*?))?{{\/if}}/,
    compile(match, recursiveCompile) {
      const conditionContext = {};

      const condition = match[1];
      const ifTrue = match[2];

      if (match.length === 4) {
        const ifFalse = match[3];
        return (context) => {
          if (context[condition]) {
            return recursiveCompile(ifTrue)(conditionContext);
          }
          return recursiveCompile(ifFalse)(conditionContext);
        };
      }
      return (context) => {
        if (context[condition]) {
          return recursiveCompile(ifTrue)(conditionContext);
        }
      };
    },
  };

  const frontSideTransformer: IBodyCompiler = {
    regex: /^{{FrontSide}}/,
    compile(match) {
      return (context) => {
        // Disable autoplay of audio from the front side (it already played on the front).
        // This merge({}, a, b) pattern merges b over a without modifying a.
        const frontContext = Object.assign({}, context, {
          autoplayAudio: false,
          key: "FrontSide",
        });

        return context.FrontSide(frontContext);
      };
    },
  };

  const interpolationTransformer: IBodyCompiler = {
    regex: /^{{([^}]+?)}}/,
    compile(match, _, keyGen) {
      const varName = match[1];

      // eslint-disable-next-line react/display-name
      return (context) => {
        const val = context[varName];
        const varValue = val != null && typeof val === "function" ? val(context) : null;

        return <TextInterpolator key={varName + keyGen()} text={varValue} />;
      };
    },
  };

  const bracketedInterpolationTransformer: IBodyCompiler = {
    regex: /^{{\[([^\]]+?)\]}}/,
    compile(match, _, keyGen) {
      const varName = match[1];

      // eslint-disable-next-line react/display-name
      return (context) => {
        const val = context[varName];
        const varValue = val != null && typeof val === "function" ? val(context) : null;

        return <TextInterpolator key={varName + keyGen()} text={varValue} />;
      };
    },
  };

  const bodyTransformers: IBodyCompiler[] = [
    conditionalTransformer,
    frontSideTransformer,
    bracketedInterpolationTransformer,
    interpolationTransformer,
  ];

  const ttsTransformer: INodeCompiler = {
    match(nodeName, attrs) {
      let needle;
      const hasClass =
        ((needle = "tts"), Array.from((attrs.className || "").split(" ")).includes(needle));
      const hasType = attrs["data-type"] === "tts";

      return (hasClass || hasType) && isSpeechSupported();
    },

    compile(elName, attrs, children, recursiveCompile, keyGen) {
      const lang = attrs["data-lang"] || "en-US";

      // eslint-disable-next-line react/display-name
      return (context) => (
        <TTSWrapper
          autoplayAudio={context.autoplayAudio}
          disableClick={disableTouchEvents || context.disableClick}
          key={keyGen()}
          lang={lang}
          otherProps={Lib.omit(attrs, ["data-type", "data-rate", "data-lang"])}
          rate={attrs["data-rate"]}
        >
          {children(context)}
        </TTSWrapper>
      );
    },
  };

  const codeTransformer: INodeCompiler = {
    match(nodeName, attrs) {
      return nodeName === "code";
    },
    compile(elName, attrs, children, recursiveCompile, keyGen) {
      const CodeWrapper = require("cardRendering/codeWrapper").default;

      // eslint-disable-next-line react/display-name
      return (context) => (
        <CodeWrapper key={keyGen()} lang={attrs["data-lang"]}>
          {children(context)}
        </CodeWrapper>
      );
    },
  };

  const texTransformer: INodeCompiler = {
    match(nodeName, attrs) {
      const hasType = attrs["data-type"] === "tex";
      return hasType;
    },
    compile(elName, attrs, children, recursiveCompile, keyGen) {
      const TexWrapper = require("cardRendering/texWrapper").default;

      // eslint-disable-next-line react/display-name
      return (context) => <TexWrapper key={keyGen()}>{children(context)}</TexWrapper>;
    },
  };

  // attrInterpolationTransformer supports interpolation of {{var}} and {{[var]}} macros, within quoted tag attributes (e.g. link tag href attributes).
  const attrInterpolationRegex = /^{{([^}]+?)}}/;
  const bracketedAttrInterpolationRegex = /^{{\[([^\]]+?)\]}}/;
  const attrInterpolationTransformer: INodeCompiler = {
    match(elName, attributes) {
      for (const entry of Object.entries(attributes)) {
        const v = entry[1];
        if (
          typeof v === "string" &&
          (v.match(attrInterpolationRegex) || v.match(bracketedAttrInterpolationRegex))
        ) {
          return true;
        }
      }
      return false;
    },
    compile(elName, attributes, children, recursiveCompile, keyGen) {
      const interp = (k: string, v: string, context: ReplateContext, regex: RegExp) =>
        v.replace(regex, (fullMatch: string, interpVar: string) => {
          const val = context[interpVar];
          if (val != null && typeof val === "function") {
            return val(context);
          }
          return null;
        });

      return (context) => {
        const interpolatedAttrs: { [key: string]: any } = {};
        Object.entries(attributes).forEach(([k, v]) => {
          if (typeof v === "string" && v.match(bracketedAttrInterpolationRegex)) {
            interpolatedAttrs[k] = interp(k, v, context, bracketedAttrInterpolationRegex);
          } else if (typeof v === "string" && v.match(attrInterpolationRegex)) {
            interpolatedAttrs[k] = interp(k, v, context, attrInterpolationRegex);
          } else {
            interpolatedAttrs[k] = v;
          }
        });
        interpolatedAttrs.key = keyGen();
        return React.createElement(elName, interpolatedAttrs, children(context));
      };
    },
  };

  const nodeTransformers: INodeCompiler[] = [
    ttsTransformer,
    codeTransformer,
    texTransformer,
    attrInterpolationTransformer,
  ];

  const compiler = new Replate({
    body: bodyTransformers,
    node: nodeTransformers,
  });
  const wrapper = compiler.compile(`<div>${template ?? ""}</div>`) as any;

  // NOTE: the table layout solution used on Windows would probably work in these other cases too, but I'm not consolidating them, because we already deployed 2.0.0 on other platforms. In the future, we settle on one or the other and clean out this conditional (at that point IE Edge may be launched, making this code branch work).
  const outerStyle = {
    alignItems: "center",
    display: "flex",
    flex: "1",
    height: "100%",
    maxWidth: "100%", // This was necessary to get text to wrap on iOS 17.5.
  };

  const innerStyle = {
    maxHeight: "100%",
    width: "100%", // HACK: card contents was not taking up full width without this.
  };

  const compiledClass: React.FunctionComponent = (props) => {
    const inner = wrapper(props);
    return (
      <div key="wrapper" style={outerStyle}>
        <div style={innerStyle}>{inner}</div>
      </div>
    );
  };

  return compiledClass;
}
