import L10n from "localization";
import React from "react";
import { IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";
import { createOutline } from "ionicons/icons";

export default function ListAddItem({ onClick }: { onClick?: () => void }): JSX.Element {
  return (
    <IonItem onClick={onClick}>
      <IonButton fill="clear" size="large" expand="full" style={{ width: "100%" }}>
        <IonIcon icon={createOutline} size="large" />
        <IonLabel style={{ fontSize: "1.4rem", marginTop: "2px", marginLeft: "4px" }}>
          {L10n.localize((s) => s.actions.new)}
        </IonLabel>
      </IonButton>
    </IonItem>
  );
}
