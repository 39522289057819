import React from "react";
import L10n from "localization";
import Style from "style";
import color from "color";
import { useIsPhone } from "hooks/util/useMediaQuery";

interface IDisconnectedProps {
  text?: string;
  marginBottom?: string;
  marginTop?: string;
  style?: React.CSSProperties;
}
export default function Disconnected({
  text = undefined,
  marginBottom = "1rem",
  marginTop,
  style,
}: IDisconnectedProps): React.ReactElement {
  const isPhone = useIsPhone();

  const containerStyle: React.CSSProperties = {
    textAlign: "center",
    width: "100%",
    marginTop: marginTop ? marginTop : isPhone ? "1rem" : "8rem",
    marginBottom,
    color: Style.colors.mutedFg,
    ...style,
  };

  const iconStyle: React.CSSProperties = {
    fontSize: "5rem",
    color: color(Style.colors.dangerousFg).lighten(0.7).string(),
  };

  const textStyle: React.CSSProperties = {
    fontSize: "1.2rem",
    marginTop: "-1rem",
  };

  return (
    <div style={containerStyle}>
      <i style={iconStyle} className="ion-ios-cloud-outline" />
      <div style={textStyle}>{text || L10n.localize((s) => s.error.communication)}</div>
    </div>
  );
}
