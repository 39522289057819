import { DeckFields } from "@models/deck";
import { SIDES } from "fields/sides";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function equationTeX(): IMarketItem {
  const equationTeXFields: DeckFields = [
    { name: fieldNames.Equation(), type: "tex", sides: SIDES.BOTH },
    { name: fieldNames.Back(), type: "text", sides: SIDES.BACK },
  ];

  return {
    base: "equation-tex",
    name: L10n.localize((s) => s.preInstalledLayouts.equationTeX),
    description: L10n.localize((s) => s.field.typeTeXDescription),
    fields: equationTeXFields,
    values: {
      [fieldNames.Equation()]: "$-b \\pm \\sqrt{b^2 - 4ac} \\over 2a$ \\bye",
      [fieldNames.Back()]: "Quadratic formula",
    },
  };
}
