import UnlimitedIcon from "@components/unlimitedIcon";
import EventBus from "eventBus";
import L10n from "localization";
import React from "react";
import { IoCheckbox, IoClose } from "react-icons/io5";
import Style from "style";
import { IServerProduct } from "unlimited/types";

export default function UpgradeTable({ products }: { products: IServerProduct[] }): JSX.Element {
  let productMetadata: string[] = [];
  if (products && products.length > 0) {
    const newMeta = products[0]?.description?.trimEnd()?.split("\n");
    if (newMeta) {
      productMetadata = newMeta;
    }
  }

  const [, setTriggerRender] = React.useState(false);

  React.useEffect(() => {
    const handleThemeChange = () => {
      setTriggerRender((prev) => !prev);
    };
    EventBus.on("themeChange", handleThemeChange);
    return () => {
      EventBus.off("themeChange", handleThemeChange);
    };
  }, []);

  return (
    <table
      style={{
        tableLayout: "fixed",
        width: "100%",
        borderCollapse: "collapse",
      }}
    >
      <colgroup>
        <col style={{ width: "60%" }} />
        <col
          style={{
            width: "15%",
            minWidth: 128,
            border: `1px solid ${Style.colors.actionableMutedFg}`,
          }}
        />
        <col style={{ width: "25%", minWidth: 196 }} />
      </colgroup>
      <tbody>
        <tr>
          <th />
          <th
            style={{
              backgroundColor: Style.colors.actionableMutedFg,
              padding: 4,
            }}
          >
            <div
              style={{
                backgroundColor: Style.colors.actionableMutedFg,
                fontStyle: "italic",
                fontSize: 10,
                color: Style.colors.mutedFg,
                fontWeight: "400",
                padding: 4,
                paddingBottom: "0px",
              }}
            >
              {L10n.localize((s) => s.profile.currentProduct)}
            </div>
            {L10n.localize((s) => s.account.basic)}
          </th>
          <th style={{ padding: 4 }}>
            <UnlimitedIcon style={{ paddingTop: "2px" }} narrow={true} />
          </th>
        </tr>
        {productMetadata.map((pitchPoint, i) => {
          const pitchPointSansBullet = pitchPoint.replace(/^•\s/, "");
          return (
            <tr key={i} style={{ backgroundColor: i % 2 === 0 ? Style.colors.secondaryBg : "" }}>
              <td style={{ textAlign: "left", padding: 8, paddingLeft: 8 }}>
                {pitchPointSansBullet}
              </td>
              <td style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                <IoClose color={Style.colors.dangerousFg} />
              </td>
              <td style={{ display: "table-cell", verticalAlign: "middle", textAlign: "center" }}>
                <IoCheckbox style={{ color: "var(--ion-color-success)" }} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
