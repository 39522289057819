import React from "react";
import { ProductFrequency } from "./types";

interface IPriceProps {
  displayPrice: string;
  frequency: ProductFrequency;
}
export default function Price(props: IPriceProps) {
  const { displayPrice, frequency } = props;

  let price = displayPrice;
  if (displayPrice.endsWith(" USD") && !displayPrice.startsWith("$")) {
    price = `$${displayPrice}`;
  }

  return (
    <>
      <span>{price}</span>
      {frequency && (
        <>
          <span style={{ fontSize: "80%", marginLeft: 1, marginRight: 1 }}>/</span>
          <span style={{ fontSize: "80%" }}>{frequency}</span>
        </>
      )}
    </>
  );
}
