import InfoDot from "@components/infoDot";
import Tag from "@components/tag";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import MapView from "@lib/mapView";
import { Deck, IDeck } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { sortWithTrailingNumber } from "lib";
import L10n from "localization";
import React from "react";
import { ID } from "types/ID";
import TagSelectSettingModal from "./tagSelectSettingModal";

export default function TagsSettingItem(props: {
  deck: IDeck;
  knols?: MapView<ID, IKnol>;
  knolsLastUpdated?: Date;
  reviewTags: string[];
  numTotalCards: number;
  numFilteredCards: number;
}): JSX.Element | null {
  const { deck, reviewTags, numTotalCards, numFilteredCards } = props;
  const selectedTagsSet = new Set(reviewTags);
  const allTags = Deck.knolTags(props.knols?.values(), [Knol.IGNORE_TAG]);

  function toggleSelected(tag: string) {
    if (selectedTagsSet.has(tag)) {
      selectedTagsSet.delete(tag);
    } else {
      selectedTagsSet.add(tag);
    }
    Deck.setSetting(deck, "reviewTags", Array.from(selectedTagsSet).sort());
  }

  const specialTags = [Knol.IGNORE_TAG, Knol.MARKED_TAG];
  const tagsSansSpecialTags = allTags?.filter((t) => !specialTags.includes(t));
  let sortedTags = sortWithTrailingNumber(tagsSansSpecialTags ?? []);

  if (allTags?.includes(Knol.MARKED_TAG)) {
    sortedTags = [Knol.MARKED_TAG].concat(sortedTags);
  }

  const noTags = sortedTags.length < 1;

  const numSelected = reviewTags.length;
  const label =
    noTags || numSelected < 1 ? (
      L10n.localize((s) => s.general.none)
    ) : numSelected === 1 ? (
      <Tag name={reviewTags[0]} noChip />
    ) : (
      `${numSelected}/${sortedTags.length}`
    );

  const options = sortedTags.map((tag) => ({
    name: tag,
    val: tag,
    checked: selectedTagsSet.has(tag),
    onChange: () => toggleSelected(tag),
  }));

  const [showModal] = useIonCardModalWithDismiss(TagSelectSettingModal, {
    options,
    title: L10n.localize((s) => s.tag.plural),
    numTotalCards,
    numFilteredCards,
  });

  return (
    <IonItem button onClick={showModal}>
      <InfoDot id="review_tags_setting" />
      <IonLabel>{L10n.localize((s) => s.tag.plural)}</IonLabel>
      <IonNote slot="end">{label}</IonNote>
    </IonItem>
  );
}
