import { DeckFields } from "@models/deck";
import { FieldType, fieldTypeMap, fieldTypes, IField } from "fields/fields";
import {
  ISourcePipelineRefNode,
  ISourcePipelineTranslationNode,
  manualSourcePipelineNode,
  SourcePipelineNode,
} from "fields/sources/types";
import { IParseMessage, IParseResult } from "./parser";
import L10n from "localization";

export function parseFields(deckNodeChildren: Element[]): IParseResult<DeckFields> {
  const warnings: IParseMessage[] = [];

  const fieldsEls = deckNodeChildren.filter((el) => el.tagName === "fields");
  if (fieldsEls.length !== 1) {
    return {
      val: null,
      // biome-ignore lint/style/noUnusedTemplateLiteral: need the `` to preserve tag
      warnings: [{ msg: `Expected exactly 1 <fields> tag.` }],
    };
  }
  const fieldsEl = fieldsEls[0];

  const fieldEls = Array.from(fieldsEl.children);
  if (fieldEls.length < 1) {
    return {
      val: null,
      warnings: [{ msg: L10n.localize((s) => s.deck.missingFieldsDetectedBody) }],
    };
  }

  const fields: IField[] = [];

  for (const fieldEl of fieldEls) {
    const fieldResult = parseField(fieldEl);
    warnings.push(...(fieldResult.warnings ?? []));
    const field = fieldResult.val;
    if (!field) {
      continue;
    }
    fields.push(field);
  }

  return {
    val: fields,
    warnings,
  };
}

function parseField(fieldEl: Element): IParseResult<IField> {
  // Not sure why, but having this at the module-level (outside of function),
  // was causing the app to throw an exception and whitescreen.
  const fmlTagToFieldType: Record<string, FieldType> = {};
  for (const fieldType of fieldTypes) {
    const fmlTagName = fieldTypeMap[fieldType].fmlTag;
    fmlTagToFieldType[fmlTagName] = fieldType;
  }

  const tagName = fieldEl.tagName;
  const fieldType = fmlTagToFieldType[tagName];
  if (!fieldType) {
    return {
      val: null,
      errors: [{ msg: `Unknown field type: <${tagName}>` }],
    };
  }

  const attrs: Record<string, string> = {};
  for (let i = 0; i < fieldEl.attributes.length; i++) {
    const attr = fieldEl.attributes[i];
    attrs[attr.name] = attr.value;
  }

  const children = Array.from(fieldEl.children);
  const sourceEl = children.find((el) => el.tagName === "sources");
  const sourcesResult = parseSources(sourceEl);
  const sources = sourcesResult.val ?? [];
  const header = fieldTypeMap[fieldType];
  return { val: header.parseField(attrs, sources) };
}

function parseSources(sourcesEl: Element | undefined): IParseResult<SourcePipelineNode[]> {
  if (!sourcesEl) {
    return { val: [] };
  }

  const children = Array.from(sourcesEl.children);
  const sources: SourcePipelineNode[] = [];
  const warnings: IParseMessage[] = [];
  for (const el of children) {
    const sourceResult = parseSource(el);
    warnings.push(...(sourceResult.warnings ?? []));
    const source = sourceResult.val;
    if (source) {
      sources.push(source);
    }
  }

  return { val: sources, warnings };
}

function parseSource(sourceEl: Element | undefined): IParseResult<SourcePipelineNode> {
  if (sourceEl === undefined) {
    return { val: manualSourcePipelineNode };
  }

  switch (sourceEl.tagName) {
    case "ref": {
      const name = sourceEl.getAttribute("name");
      if (!name) {
        return { val: null, errors: [{ msg: "Missing required attribute: name" }] };
      }
      const source: ISourcePipelineRefNode = {
        type: "ref",
        name,
      };
      return { val: source };
    }
    case "translation": {
      const children = Array.from(sourceEl.children);
      const firstChild = children[0];
      const nestedSourceResult = parseSource(firstChild);
      const nestedSource = nestedSourceResult.val;
      if (nestedSource === null) {
        return { val: null, errors: [{ msg: "Failed to parse source node" }] };
      }
      const source: ISourcePipelineTranslationNode = {
        type: "translation",
        source: nestedSource,
      };
      return { val: source };
    }
    default: {
      return { val: null, errors: [{ msg: `Unknown source type: <${sourceEl.tagName}>` }] };
    }
  }
}
