import { IDBAppSchema } from "@data/idb";
import { IBaseOperation } from "@models/operation";
import EventBus from "eventBus";
import { IDBPTransaction, StoreNames } from "idb";

export interface IKnolTagInsertOperation extends IBaseOperation {
  object_type: "knol_tag";
  type: "INSERT";
  object_parameters: {
    knol_id: string;
    tag_name: string;
  };
}

export interface IKnolTagDeleteOperation extends IBaseOperation {
  object_type: "knol_tag";
  type: "DELETE";
  object_parameters: {
    knol_id: string;
    tag_name: string;
  };
}

export type KnolTagOperation = IKnolTagDeleteOperation | IKnolTagInsertOperation;

const DELETE = async (
  op: IKnolTagDeleteOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const knolID = op.object_parameters.knol_id;
  const tagName = op.object_parameters.tag_name;
  const knolsStore = tx.objectStore("knols");
  const knol = await knolsStore.get(knolID);

  if (knol?.tags) {
    knol.tags = knol.tags.filter((tag) => tag !== tagName);
    await knolsStore.put(knol);
  }

  return () => {
    if (knol?.tags) {
      EventBus.emit("knolUpdated", { knolID, deckID: knol.deck_id });
    }
  };
};

const INSERT = async (
  op: IKnolTagInsertOperation,
  tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
) => {
  const knolID = op.object_parameters.knol_id;
  const tagName = op.object_parameters.tag_name;
  const knolsStore = tx.objectStore("knols");
  const knol = await knolsStore.get(knolID);

  if (knol) {
    knol.tags = knol.tags || [];
    if (!knol.tags.includes(tagName)) {
      knol.tags.push(tagName);
      await knolsStore.put(knol);
    }
  }

  return () => {
    if (knol) {
      EventBus.emit("knolUpdated", { knolID, deckID: knol.deck_id });
    }
  };
};

export { DELETE, INSERT };
