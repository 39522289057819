import { IonIcon } from "@ionic/react";
import { useIsPhone } from "hooks/util/useMediaQuery";
import { fileTrayOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";
import Style from "style";

interface IProps {
  text?: string;
  marginTop?: string;
  marginBottom?: string;
  center?: boolean;
  style?: React.CSSProperties;
  actionEl?: React.ReactNode;
}
export default function Empty({
  text = undefined,
  marginTop = undefined,
  marginBottom = undefined,
  style = {},
  center,
  actionEl,
}: IProps): React.ReactElement {
  const isPhone = useIsPhone();

  const containerStyle = {
    textAlign: "center",
    width: "100%",
    maxWidth: 360,
    marginTop: marginTop !== undefined ? marginTop : isPhone ? "1rem" : "8rem",
    marginBottom,
    color: Style.colors.mutedFg,
  } as React.CSSProperties;

  const iconStyle = { fontSize: "4rem", "--ionicon-stroke-width": 16 };

  const textStyle = {
    fontSize: "1rem",
    fontWeight: 300,
  } as React.CSSProperties;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: center ? "0 auto" : undefined,
      }}
    >
      <div style={{ ...containerStyle, ...style }}>
        <IonIcon style={iconStyle} icon={fileTrayOutline} />
        <div
          style={textStyle}
          dangerouslySetInnerHTML={{
            __html: text || L10n.localize((s) => s.general.none),
          }}
        />
        {actionEl}
      </div>
    </div>
  );
}
