import { IonBadge, IonIcon, IonItem, IonLabel, IonListHeader, useIonAlert } from "@ionic/react";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { personAddOutline } from "ionicons/icons";
import L10n from "localization";
import Network from "network";
import React from "react";
import { IGroup, IGroupJoinRequest } from "./types";

interface IProps {
  group: IGroup;
  onChange: () => void;
}
export default function Requests(props: IProps): JSX.Element | null {
  const { group, onChange } = props;
  const { join_requests } = group;

  const sortedRequests = (join_requests ?? []).sort((i1, i2) =>
    i1.created_at < i2.created_at ? -1 : 1,
  );

  const [presentToast] = useDismissibleToast();

  async function reject(request: IGroupJoinRequest) {
    try {
      await Network.fetch(
        "DELETE",
        `/users/groups/${group.id}/join_requests/${request.user_id}`,
        {},
      );
      onChange();
    } catch {
      presentToast({
        message: L10n.localize((s) => s.groups.joinRequestRejectFailed),
        color: "warning",
        duration: 3000,
      });
    }
  }

  async function accept(request: IGroupJoinRequest) {
    try {
      await Network.fetch("POST", `/users/groups/${group.id}/members/${request.user_id}`, {});
      onChange();
    } catch {
      presentToast({
        message: L10n.localize((s) => s.groups.joinRequestAcceptFailed),
        color: "warning",
        duration: 3000,
      });
    }
  }

  const acceptVal = "accept";
  const rejectVal = "reject";

  const [presentAlert] = useIonAlert();
  function handleClick(request: IGroupJoinRequest) {
    const name = `${request.name_first ?? ""} ${request.name_last ?? ""}`.trim();
    const message = name ? `${name} (${request.email})` : request.email;

    presentAlert({
      header: L10n.localize((s) => s.groups.joinRequest),
      message,
      inputs: [
        {
          label: L10n.localize((s) => s.actions.accept),
          type: "radio",
          value: acceptVal,
          checked: true,
        },
        { label: L10n.localize((s) => s.actions.reject), type: "radio", value: rejectVal },
      ],
      buttons: [
        { text: L10n.localize((s) => s.actions.cancel), role: "cancel" },
        {
          text: L10n.localize((s) => s.actions.ok),
          role: "ok",
          handler: (x) => {
            if (x === acceptVal) {
              accept(request);
            } else if (x === rejectVal) {
              reject(request);
            }
          },
        },
      ],
    });
  }

  if (sortedRequests.length < 1) {
    return null;
  }

  return (
    <>
      {sortedRequests.length > 0 && (
        <IonListHeader>
          <IonLabel>
            {L10n.localize((s) => s.groups.requests)}
            <IonBadge slot="start" style={{ marginLeft: 4 }}>
              {sortedRequests.length}
            </IonBadge>
          </IonLabel>
        </IonListHeader>
      )}

      {sortedRequests.map((request, idx) => {
        const name = `${request.name_first ?? ""} ${request.name_last ?? ""}`.trim();
        const isLast = idx === sortedRequests.length - 1;
        return (
          <IonItem
            key={idx}
            lines={isLast ? "none" : undefined}
            detail
            onClick={() => handleClick(request)}
            style={{ cursor: "pointer" }}
          >
            <IonIcon slot="start" icon={personAddOutline} />
            <IonLabel>
              {name}
              <p>{request.email}</p>
            </IonLabel>
          </IonItem>
        );
      })}
    </>
  );
}
