import { IonItem, IonLabel } from "@ionic/react";
import { formFullFolderPath } from "@models/folder";
import { rootFolder } from "hooks/data/folders";
import { folderOutline } from "ionicons/icons";
import React from "react";
import NestingIndicator from "./nestingIndicator";
import NewFolderItem from "./newFolderItem";
import SelectionIndicator from "./selectionIndicator";

interface IFolderTreeNode {
  fullFolder: string;
  folder: string;
  subfolders: IFolderTreeNode[];
}
export function hierarchifyFolders(folders: string[], underFolder?: string): IFolderTreeNode[] {
  const roots = new Set<string>();
  folders.forEach((f) => {
    const root = rootFolder(f, underFolder);
    if (root) {
      roots.add(root);
    }
  });

  const rootList = Array.from(roots);
  return rootList.map((folder) => {
    const prefix = formFullFolderPath(folder, underFolder);
    const subfolders = hierarchifyFolders(folders, prefix);
    return { folder, subfolders, fullFolder: prefix };
  });
}

interface IFolderTreeNodeItem extends IFolderTreeNode {
  level: number;
  isSelected: (fullFolder: string) => boolean;
  onClick: (fullFolder: string) => void;
  onAddFolder?: (folder: string) => void;
}
export default function FolderTreeNodeListItem({
  folder,
  subfolders,
  fullFolder,
  level = 0,
  isSelected,
  onClick,
  onAddFolder,
}: IFolderTreeNodeItem) {
  const selected = isSelected(fullFolder);
  return (
    <>
      <IonItem key={folder} button onClick={() => onClick(fullFolder)} detailIcon={folderOutline}>
        <SelectionIndicator selected={selected} />
        <IonLabel>
          <NestingIndicator level={level} />
          {folder}
        </IonLabel>
      </IonItem>
      {selected && onAddFolder ? (
        <NewFolderItem onAdd={onAddFolder} underFolder={fullFolder} level={level + 1} />
      ) : undefined}
      {subfolders.map((sf) => (
        <FolderTreeNodeListItem
          key={sf.folder}
          {...sf}
          level={level + 1}
          onClick={onClick}
          isSelected={isSelected}
          onAddFolder={onAddFolder}
        />
      ))}
    </>
  );
}
