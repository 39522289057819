import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPopover,
  useIonPopover,
} from "@ionic/react";
import { DotID, clearDot } from "dots";
import { checkmark, ellipsisHorizontal } from "ionicons/icons";
import cyAttr from "lib/cyAttr";
import React from "react";
import InfoDot from "./infoDot";

export const MenuCloseContext = React.createContext(() => null);

interface IProps {
  children: React.ReactNode;
  disabled?: boolean;
  dotID?: DotID;
  onOpen?: () => void;
  onClose?: () => void;
  icon?: string;
}
export default function ScreenMenu({
  children,
  disabled,
  dotID,
  onOpen,
  onClose,
  icon,
}: IProps): JSX.Element {
  const ref = React.useRef(null);
  const [present] = useIonPopover(() => <IonList>{children}</IonList>, {});

  return (
    <IonButton
      ref={ref}
      disabled={disabled}
      {...cyAttr("more-menu-button")}
      size="large"
      fill="clear"
      onClick={(evt) => {
        if (dotID) {
          clearDot(dotID).catch(() => {});
        }
        present({
          dismissOnSelect: true,
          translucent: true,
          reference: "event",
          event: evt as unknown as Event, // HACK: I don't know why this casting is necessary :(
          onDidDismiss: onClose,
        });
        onOpen?.();
      }}
    >
      {dotID ? <InfoDot id={dotID} numBounces={4} /> : undefined}
      <IonIcon icon={icon ?? ellipsisHorizontal} />
    </IonButton>
  );
}

interface IMenuButtonProps {
  detail?: boolean;
  disabled?: boolean;
  label: string;
  lines?: "none" | undefined;
  icon: string | React.ReactElement;
  color?: string;
  toggled?: boolean;
  onClick?: (e?: any) => void;
  tooltip?: string;
  dotID?: DotID;
}
export function MenuButton({
  detail,
  disabled,
  icon,
  label,
  lines,
  onClick,
  toggled,
  tooltip,
  color = "primary",
  dotID,
}: IMenuButtonProps): JSX.Element {
  return (
    <IonItem
      id={`hover-trigger${label}`}
      {...cyAttr(`${label}-menuButton`)}
      button
      lines={lines}
      detail={detail}
      disabled={disabled}
      onClick={onClick}
    >
      {tooltip && (
        <IonPopover trigger={`hover-trigger${label}`} triggerAction="hover">
          <IonContent className="ion-padding">{tooltip}</IonContent>
        </IonPopover>
      )}
      {typeof icon === "string" ? (
        <IonIcon slot="start" icon={icon} color={color} />
      ) : (
        <IonButton slot="start" color={color} fill="clear" style={{ marginInline: "-6px 12px" }}>
          {icon}
        </IonButton>
      )}
      <IonLabel color={color} className="ion-text-wrap">
        {label}
      </IonLabel>
      {toggled ? (
        <IonIcon slot="end" color="primary hey" icon={checkmark} />
      ) : dotID ? (
        <InfoDot slot="end" id={dotID} numBounces={4} />
      ) : undefined}
    </IonItem>
  );
}
