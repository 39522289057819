import { reviewCardClass } from "@cardRendering/card";
import CardPreview from "@cardRendering/cardPreview";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { TResponseName } from "globals";
import { useIsPhone } from "hooks/util/useMediaQuery";
import L10n from "localization";
import React from "react";
import Style from "style";
import { ID } from "types/ID";

interface IProps {
  layoutID: ID;
  knol: IKnol;
  deck: IDeck;
  response: TResponseName;
  durationMs: number;
  forceRefreshCounter?: number;
}

export default function ResponseCard(props: IProps): JSX.Element | null {
  const { knol, deck, response, durationMs } = props;

  const cardMarginPx = 8;
  const style = {
    margin: cardMarginPx,
    display: "flex",
    flexDirection: "column",
    marginBottom: 8,
  } as React.CSSProperties;

  const durationSecs = durationMs / 1000.0;
  const durationSecsRounded = Math.round(durationSecs * 10) / 10;
  const durationStr = durationSecsRounded + L10n.localize((s) => s.time.second);
  const orientation = deck?.user_config?.settings?.cardOrientation;

  const label = L10n.localize((s) => s.response[response]);
  const respColor = Style.colors[response];

  const isPhone = useIsPhone();

  return (
    <div style={style} className={reviewCardClass}>
      <CardPreview
        border
        isClickable
        showHeader
        knol={knol}
        deck={deck}
        visibleSide={orientation === "reversed" ? 1 : 0}
        disableAudioControlsRendering={isPhone}
        overflow="hidden"
      />
      <div
        style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: 2 }}
      >
        <div
          style={{
            textAlign: "left",
            color: respColor,
            border: `1px solid ${respColor}`,
            borderRadius: 4,
            padding: "1px 8px",
            marginTop: 2,
          }}
        >
          {label}
        </div>
        <div style={{ textAlign: "right", color: Style.colors.mutedFg, marginTop: 4 }}>
          {durationStr}
        </div>
      </div>
    </div>
  );
}
