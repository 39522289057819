import { useHintClass } from "hooks/util/useHint";
import React from "react";

export interface ICardSideSharedProps {
  rubberbandScroll: boolean;
  autoplayAudio: boolean;
  overflow: string | null;
  refreshCounter?: number;
}

type ICardSideProps = {
  sideNum: 0 | 1;
  sideRenderers: any[]; //React.FunctionComponent[];
  compiledRendererProps: any;
} & ICardSideSharedProps;

export default function CardSide(props: ICardSideProps): JSX.Element | null {
  const {
    autoplayAudio,
    sideNum,
    sideRenderers,
    compiledRendererProps,
    overflow,
    rubberbandScroll,
    refreshCounter,
  } = props;

  const style: React.CSSProperties = {
    display: "flex",
    WebkitOverflowScrolling: rubberbandScroll ? "touch" : "auto", // HACK: this makes scrolling scroll the entire screen when the bottom is reached, otherwise (-webkit-overflow-scrolling: touch) it won't.
    width: "100%",
    maxWidth: "100vw",
    overflowX: "auto",
    height: "100%",
    zIndex: 1,
    ...(overflow
      ? // overflow set indicates this card is contained in a little box.
        {
          position: "absolute",
          left: 0,
          right: 0,
          overflow: overflow,
        }
      : // If not set, full screen review, let it spread out and underflow header and footer.
        undefined),
  };

  const renderer = sideRenderers?.[sideNum];

  const rendererProps = {
    ...compiledRendererProps,
    autoplayAudio,
    refreshCounter,
    FrontSide: sideRenderers?.[0] ?? (() => null),
    sideNum,
  };

  const hintElRef = useHintClass();

  if (!renderer) {
    return null;
  }

  return (
    <div ref={hintElRef} style={style} className={`side-${sideNum}`}>
      {renderer(rendererProps)}
    </div>
  );
}
