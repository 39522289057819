import { IBaseOperation } from "@models/operation";

export interface ISubscriptionInsertOperation extends IBaseOperation {
  object_type: "subscription";
  type: "INSERT";
  object_parameters: {
    user_id: string;
    deck_id: string;
    modified_at: string;
    deck_name: string;
    deck_description?: string;
  };
}

export type SubscriptionOperation = ISubscriptionInsertOperation;

const INSERT = async () => {
  return () => {};
};

export { INSERT };
