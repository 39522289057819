import { IonItem, IonLabel, IonNavLink, IonNote } from "@ionic/react";
import L10n from "localization";
import React from "react";
import { defaultCardFontSize } from "settings";
import FieldEditorGeneric from "./fieldEditorGeneric";
import { IFieldJapanese } from "fields/types/japanese";
import AnnotationModeNav, { AnnotationMode, annotationModeName } from "./annotationModeNav";
import { IFieldEditorProps } from "fields/fieldType";
import FontSizeSelectorItem from "./fontSizeSelectorItem";

export default function FieldEditJapanese({
  fields,
  field,
  onChange,
  creatingConfig,
}: IFieldEditorProps<IFieldJapanese>): JSX.Element {
  const fontSizePx = field.attributes?.fontSizePx ?? defaultCardFontSize;
  function handleFontSizeChange(px: number) {
    onChange({
      ...field,
      attributes: {
        ...field.attributes,
        fontSizePx: px,
      },
    });
  }

  function handleFuriganaMode(mode: AnnotationMode) {
    onChange({
      ...field,
      attributes: {
        ...field.attributes,
        furiganaMode: mode,
      },
    });
  }

  return (
    <FieldEditorGeneric
      fields={fields}
      field={field}
      onChange={onChange}
      creatingConfig={creatingConfig}
    >
      <FontSizeSelectorItem fontSizePx={fontSizePx} onChange={handleFontSizeChange} />

      <IonNavLink
        routerDirection="forward"
        component={() => (
          <AnnotationModeNav
            title={L10n.localize((s) => s.field.furigana)}
            mode={field.attributes?.furiganaMode}
            onSelect={handleFuriganaMode}
          />
        )}
      >
        <IonItem button>
          <IonLabel>{L10n.localize((s) => s.field.furigana)}</IonLabel>
          <IonNote slot="end">{annotationModeName(field.attributes?.furiganaMode)}</IonNote>
        </IonItem>
      </IonNavLink>
    </FieldEditorGeneric>
  );
}
