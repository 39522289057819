import L10n from "localization";
import { getLocalStorage, removeLocalStorage, setLocalStorage } from "storage";

export interface IName {
  first?: string | undefined;
  last?: string | undefined;
}

export function getEmptyNamePlaceholder(): IName {
  return {
    first: L10n.localize((s) => s.general.firstName),
    last: L10n.localize((s) => s.general.lastName),
  };
}

const firstNameKey = "AnkiApp.user.nameFirst";
const lastNameKey = "AnkiApp.user.nameLast";

export async function getName(): Promise<IName> {
  const first = await getLocalStorage(firstNameKey);
  const last = await getLocalStorage(lastNameKey);
  return { first, last };
}

export async function setName(name: IName) {
  if (name.first) {
    await setLocalStorage(firstNameKey, name.first);
  } else {
    await removeLocalStorage(firstNameKey);
  }

  if (name.last) {
    await setLocalStorage(lastNameKey, name.last);
  } else {
    await removeLocalStorage(lastNameKey);
  }
}
