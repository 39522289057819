import logEvent from "analytics";
import { IBlobUploaderMessage } from "workers/blobUploader";
import { IBlobUploadQueue, IBlobWorkerResult, blobWorkerSuccess } from "./blobNetwork";
import { handleIndexedDBRefreshRequiredErrorIfNecessary } from "errors/indexedDBRefreshRequired";

export async function uploadBlobInBackground(
  upload: IBlobUploadQueue,
  deviceID: string,
  deviceToken: string,
): Promise<void> {
  const worker = new Worker("/workers/blobUploader.js");

  return new Promise((resolve, reject) => {
    worker.onmessage = (ev: MessageEvent<IBlobWorkerResult>) => {
      if (ev.data.status === blobWorkerSuccess) {
        resolve();
      } else {
        reject(ev.data.error);
      }
    };

    const workerMessage: IBlobUploaderMessage = {
      ...upload,
      deviceID: deviceID,
      deviceToken: deviceToken,
    };
    worker.postMessage(workerMessage);
  });
}

export async function uploadBlobsInBackground(
  queue: IBlobUploadQueue[],
  deviceID: string,
  deviceToken: string,
): Promise<void> {
  for (const item of queue) {
    try {
      await uploadBlobInBackground(item, deviceID, deviceToken);
    } catch (err) {
      // Upload will be retried, because it's only removed from the queue upon success.
      await handleIndexedDBRefreshRequiredErrorIfNecessary(err);
      logEvent("blob_upload_failed", {
        err: err instanceof Error ? `${err.name}: ${err.message}` : "???",
        errStack: err instanceof Error ? err.stack : undefined,
        blobID: item.blobID,
        knolID: item.knolID,
      });
    }
  }
}
