import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import L10n from "localization";
import React from "react";

export default function HelpScreen() {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.general.help)}</IonTitle>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <iframe
          sandbox="allow-scripts allow-same-origin"
          title={L10n.localize((s) => s.general.help)}
          style={{ width: "100%", height: "100%", border: "none" }}
          allowFullScreen
          src="https://www.ankiapp.com/support"
        />
      </IonContent>
    </>
  );
}
