import InfoDot from "@components/infoDot";
import RadioSelectSettingModal from "@components/radioSelectSettingModal";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { Orientation } from "@models/deckSettings";
import { DotID, clearDot } from "dots";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback } from "react";

interface IProps {
  orientation?: Orientation;
  onChange: (orientation: Orientation) => void;
}
export default function OrientationSelectorItem(props: IProps): JSX.Element {
  const { orientation = "normal", onChange } = props;

  const options: Array<{ name: string; val: Orientation; desc: string }> = [
    {
      name: L10n.localize((s) => s.general.normal),
      val: "normal",
      desc: L10n.localize((s) => s.general.normalOrientationDescription),
    },
    {
      name: L10n.localize((s) => s.general.reversed),
      val: "reversed",
      desc: L10n.localize((s) => s.general.reversedOrientationDescription),
    },
    {
      name: L10n.localize((s) => s.general.mixed),
      val: "mixed",
      desc: L10n.localize((s) => s.general.mixedOrientationDescription),
    },
  ];

  const dotID: DotID = "review_orientation_setting";
  const [showModal] = useIonCardModalWithDismiss(RadioSelectSettingModal<Orientation>, {
    options,
    title: L10n.localize((s) => s.deck.cardOrientation),
    onChange,
    selectedItemVal: orientation,
    description: L10n.localize((s) => s.deck.cardOrientationDescription),
  });

  const handleClick = useCallback(() => {
    clearDot(dotID).catch(() => {});
    showModal();
  }, [showModal]);

  const selectedItemName = options.find(({ val }) => val === orientation)?.name ?? "";

  return (
    <IonItem button onClick={handleClick}>
      <InfoDot id={dotID} />
      <IonLabel style={{ flexBasis: "content", flexShrink: 0 }}>
        {L10n.localize((s) => s.deck.cardOrientation)}
      </IonLabel>
      <IonNote
        slot="end"
        style={{
          overflowX: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {selectedItemName}
      </IonNote>
    </IonItem>
  );
}
