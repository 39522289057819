import draw from "@core/draw";
import { shuffle } from "@core/shuffle";
import { MapLike } from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IDeckSettings, Orientation, cardsPerReviewAllValue } from "@models/deckSettings";
import { IKnol } from "@models/knol";
import { ILayout } from "@models/layout";
import { ALL_LAYOUT_ID } from "fields/magicLayout";
import { ID } from "types/ID";
import { getCardDataWithOptions } from "./lib";

function uniformSample<T>(array: T[]): T | undefined {
  if (array.length === 0) {
    return undefined;
  }

  const randomIndex: number = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

export interface IReviewSession {
  settings: IDeckSettings;
  knols: IKnol[];
  knolMap: MapLike<ID, IKnol>;
  decks: MapLike<ID, IDeck>;
  knolIDToLayout: Map<ID, ILayout>;
  cardOrientations: Record<ID, Orientation>;
}

export async function initReviewSession({
  decks,
  knols,
  settings,
}: {
  decks: MapLike<ID, IDeck>;
  knols: MapLike<ID, IKnol>;
  settings: IDeckSettings;
}): Promise<IReviewSession> {
  // Select from knols according to settings.
  const cardData = await getCardDataWithOptions(knols.values(), settings);
  let cards = Array.from(cardData);

  const { reviewMode, cardsPerReview, cardOrientation: deckOrientation } = settings;

  const numCards = cardsPerReview === cardsPerReviewAllValue ? cards.length : cardsPerReview;
  const maxCards = Math.min(cards.length, numCards);

  switch (reviewMode.name) {
    case "shuffled": {
      cards = shuffle(cards);
      cards = cards.slice(0, maxCards);
      break;
    }
    case "in_created_order": {
      cards = cards.sort((a, b) => {
        if (!a.created_at || !b.created_at) {
          return 0;
        }
        return a.created_at.getTime() - b.created_at.getTime();
      });
      const skip = reviewMode.skip;
      const min = Math.min(skip, cards.length);
      cards = cards.slice(min, maxCards);
      break;
    }
    case "srs": {
      // TODO make this work.
      const knolIDs = draw(cards, maxCards);
      cards = knolIDs
        .map((id) => knols.get(id))
        .filter((knol): knol is IKnol => knol !== undefined);
      break;
    }
  }

  const layoutMap = new Map<ID, ILayout>();
  for (const deck of decks.values()) {
    for (const layout of deck.layouts ?? []) {
      layoutMap.set(layout.id, layout);
    }
  }

  // Determine layout to use for each card.
  const knolIDToLayout = new Map<ID, ILayout>();
  for (const knol of knols.values()) {
    const deck = decks.get(knol.deck_id);
    const layoutID = deck?.layout_id;
    if (layoutID === ALL_LAYOUT_ID) {
      // Pick a random one.
      const layout = uniformSample(deck?.layouts ?? []);
      if (layout) {
        knolIDToLayout.set(knol.id, layout);
      }
    } else {
      if (layoutID) {
        const layout = layoutMap.get(layoutID);
        if (layout) {
          knolIDToLayout.set(knol.id, layout);
        }
      }
    }
  }

  // Determine card orientations.
  const cardOrientations: Record<ID, Orientation> = {};
  for (const id of cards.map((c) => c.id)) {
    switch (deckOrientation) {
      case "normal":
      case "reversed":
        cardOrientations[id] = deckOrientation;
        break;
      case "mixed":
        cardOrientations[id] = Math.random() >= 0.5 ? "normal" : "reversed";
    }
  }

  const session: IReviewSession = {
    settings,
    decks,
    knols: cards,
    knolMap: knols,
    knolIDToLayout,
    cardOrientations,
  };

  return session;
}
