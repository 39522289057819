import StandardValue from "@cardRendering/standardValue";
import { IValueOptions } from "@cardRendering/value";
import { visibleOn } from "@lib/orientation";
import { IFieldValueProps } from "fields/fieldType";
import { IFieldRichText } from "fields/types/richText";
import React, { useMemo } from "react";

export default function RichTextValue({
  value,
  field,
  ctx,
  blobIdToObjectUrl,
  options,
}: IFieldValueProps<IFieldRichText>): JSX.Element {
  const { sideNum } = ctx;
  const visible =
    sideNum === undefined ? true : visibleOn(sideNum, field.sides, options.orientation);
  const opts = useMemo((): IValueOptions => {
    return { ...options, preventAudioOrchestration: !visible };
  }, [options, visible]);

  const template = StandardValue(value, opts, blobIdToObjectUrl);
  const richVal = (template as any)(ctx);

  return <div style={{ fontSize: field.attributes?.fontSizePx }}>{richVal}</div>;
}
