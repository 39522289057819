import RadioSelectSettingNavItem, {
  IRadioSelectOption,
} from "@components/radioSelectSettingNavItem";
import { IonItem } from "@ionic/react";
import { LanguageSelectorItem } from "fields/editors/languageSelectorItem";
import { BFF69, humanLanguages, validTTSLangCodes } from "fields/lang";
import { fieldNames } from "fields/layoutMarket/fieldNames";
import { languageDeckTypeDefaultForeignText } from "fields/layoutMarket/language";
import { IBaseTextField } from "fields/lib";
import L10n from "localization";
import React from "react";
import ConfigSelectorItem from "./configSelectorItem";
import { IConfigSelection } from "./main";

interface IProps {
  config: IConfigSelection;
  onChange: (cfg: IConfigSelection) => void;
}
export default function ConfigSpecificOptionsLanguage({ config, onChange }: IProps) {
  const frontField = config.fields[0] as IBaseTextField;
  const backField = config.fields[1] as IBaseTextField;

  const frontLang = frontField.attributes?.lang;
  const backLang = backField.attributes?.lang;
  const missingLang = frontLang === undefined || backLang === undefined;

  const langs = humanLanguages();
  const frontLangName = langs.find((lang) => lang.code === frontLang)?.name;
  const backLangName = langs.find((lang) => lang.code === backLang)?.name;

  let translationSetting = "off";
  if (frontField.source) {
    translationSetting = "from-back";
  } else if (backField.source) {
    translationSetting = "from-front";
  }

  const values = {
    [fieldNames.Text()]: frontLangName ?? languageDeckTypeDefaultForeignText,
    [fieldNames.Translation()]: backLangName ?? L10n.localize((s) => s.general.translation),
  };

  const ttsOptions: IRadioSelectOption<"off" | "both" | "front" | "back">[] = [
    { name: L10n.localize((s) => s.general.off), val: "off" },
  ];
  const ttsSupportedFrontLang = frontLang && validTTSLangCodes.includes(frontLang);
  const ttsSupportedBackLang = backLang && validTTSLangCodes.includes(backLang);
  if (ttsSupportedFrontLang && ttsSupportedBackLang) {
    ttsOptions.push({ name: L10n.localize((s) => s.general.both), val: "both" });
  }
  if (ttsSupportedFrontLang) {
    ttsOptions.push({ name: frontLangName ?? "", val: "front" });
  }
  if (ttsSupportedBackLang) {
    ttsOptions.push({ name: backLangName ?? "", val: "back" });
  }

  let ttsSetting = "off";
  if (
    frontField.type === "tts" &&
    ttsSupportedFrontLang &&
    backField.type === "tts" &&
    ttsSupportedBackLang
  ) {
    ttsSetting = "both";
  } else if (frontField.type === "tts" && ttsSupportedFrontLang) {
    ttsSetting = "front";
  } else if (backField.type === "tts" && ttsSupportedBackLang) {
    ttsSetting = "back";
  }

  return (
    <>
      <IonItem lines="none" style={{ marginTop: 16 }}>
        <ConfigSelectorItem
          base={config.base}
          name={config.name}
          fields={config.fields}
          values={values}
          onSelect={() => null}
        />
      </IonItem>
      <LanguageSelectorItem
        label={L10n.localize((s) => s.general.languageFront)}
        field={frontField}
        langCode={frontLang}
        langs={langs}
        langRequired
        onChange={(code) => {
          const newConfig = JSON.parse(JSON.stringify(config));
          newConfig.fields[0].attributes.lang = code;
          if (code && !validTTSLangCodes.includes(code as BFF69)) {
            newConfig.fields[0].type = "text";
          }
          onChange(newConfig);
        }}
      />
      <LanguageSelectorItem
        label={L10n.localize((s) => s.general.languageBack)}
        field={backField}
        langCode={backLang}
        langs={langs}
        langRequired
        onChange={(code) => {
          const newConfig = JSON.parse(JSON.stringify(config));
          newConfig.fields[1].attributes.lang = code;
          if (code && !validTTSLangCodes.includes(code as BFF69)) {
            newConfig.fields[1].type = "text";
          }
          onChange(newConfig);
        }}
      />
      <RadioSelectSettingNavItem<"off" | "both" | "front" | "back">
        title={L10n.localize((s) => s.general.textToSpeech)}
        description={L10n.localize((s) => s.general.textToSpeechDescription)}
        disabled={missingLang}
        selectedItemVal={ttsSetting}
        onChange={(ttsChoice) => {
          const newConfig = JSON.parse(JSON.stringify(config));
          switch (ttsChoice) {
            case "off":
              newConfig.fields[0].type = "text";
              newConfig.fields[1].type = "text";
              onChange(newConfig);
              break;
            case "both":
              newConfig.fields[0].type = "tts";
              newConfig.fields[1].type = "tts";
              onChange(newConfig);
              break;
            case "front":
              newConfig.fields[0].type = "tts";
              newConfig.fields[1].type = "text";
              console.log({ config, newConfig });
              onChange(newConfig);
              break;
            case "back":
              newConfig.fields[0].type = "text";
              newConfig.fields[1].type = "tts";
              onChange(newConfig);
              break;
          }
        }}
        options={ttsOptions}
      />
      <RadioSelectSettingNavItem<"off" | "from-front" | "from-back">
        title={L10n.localize((s) => s.general.translation)}
        description={L10n.localize((s) => s.general.translationDescription)}
        disabled={missingLang}
        selectedItemVal={translationSetting}
        onChange={(value) => {
          const newConfig = JSON.parse(JSON.stringify(config));
          switch (value) {
            case "off":
              delete newConfig.fields[0].source;
              delete newConfig.fields[1].source;
              onChange(newConfig);
              break;
            case "from-front":
              delete newConfig.fields[0].source;
              newConfig.fields[1].source = {
                type: "translation",
                source: {
                  type: "ref",
                  name: frontField.name,
                },
              };
              onChange(newConfig);
              break;
            case "from-back":
              newConfig.fields[0].source = {
                type: "translation",
                source: {
                  type: "ref",
                  name: backField.name,
                },
              };
              delete newConfig.fields[1].source;
              onChange(newConfig);
              break;
          }
        }}
        options={[
          { name: L10n.localize((s) => s.general.off), val: "off" },
          { name: `${frontLangName} → ${backLangName}`, val: "from-front" },
          { name: `${backLangName} → ${frontLangName}`, val: "from-back" },
        ]}
      />
    </>
  );
}
