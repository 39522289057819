import { deckListRowMatchesQuery } from "@screens/search/deckResults";
import { History } from "history";
import { useDeckListWithFolders } from "hooks/data/deckList";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import React, { useEffect } from "react";
import LocalAndRemoteDecks from "../decks/localAndRemoteDecks";
import sortRows from "../decks/sortRows";
import { SortOptions } from "../decks/types";
import GetStartedModal from "./getStartedModal";

interface IProps {
  query?: string;
  sortOrder?: SortOptions;
  handleSort: (s: SortOptions) => void;
  maxItems: number;
  onNewDeck: () => void;
  onImportDeck: () => void;
  onAddToInbox: () => void;
  history: History;
}
export default function CombinedFolderAndDeckList(props: IProps): JSX.Element {
  const { query, sortOrder, handleSort, maxItems } = props;

  // Convert to folders and eliminate redundancy.
  const deckList = useDeckListWithFolders(!query ? null : undefined);
  const matchingRows =
    query !== undefined
      ? deckList.rows.filter((r) => deckListRowMatchesQuery(r, query))
      : deckList.rows;
  const sortedRows = sortRows(sortOrder, matchingRows);
  const displayedRows = sortedRows.slice(0, maxItems);

  const [presentGetStartedModal] = useIonCardModalWithDismiss(GetStartedModal, {
    onAddToInbox: props.onAddToInbox,
    history: props.history,
  });
  const empty = !deckList.loading && deckList.rows.length < 1;
  useEffect(() => {
    if (empty) {
      presentGetStartedModal();
    }
  }, [presentGetStartedModal, empty]);

  return (
    <LocalAndRemoteDecks
      loadingLocal={deckList.loading}
      loadingRemote={deckList.loading}
      totalRows={deckList.rows.length}
      sortedRows={displayedRows}
      sortOrder={sortOrder}
      handleSort={handleSort}
      errorLoadingRemoteDecks={deckList.error !== undefined}
      presentGetStartedModal={presentGetStartedModal}
    />
  );
}
