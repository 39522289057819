import { DeckFields } from "@models/deck";
import { fieldTypeMap } from "fields/fields";
import { appLang } from "fields/lang";
import { SIDES } from "fields/sides";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export const languageDeckTypeDefaultForeignText = "Lorem ipsum";

export default function languageRecognition(): IMarketItem {
  const frontName = fieldNames.Text();
  const languageRecognitionFields: DeckFields = [
    {
      name: frontName,
      type: "tts",
      sides: SIDES.BOTH,
      attributes: {
        lang: undefined,
      },
    },
    {
      name: fieldNames.Translation(),
      type: "text",
      sides: SIDES.BACK,
      attributes: { lang: appLang() },
    },
  ];

  return {
    base: "language",
    name: L10n.localize((s) => s.preInstalledLayouts.language),
    fields: languageRecognitionFields,
    description: L10n.localize((s) => s.field.typeLanguageDescription),
    values: {
      [frontName]: fieldTypeMap.text.dumpFML(languageDeckTypeDefaultForeignText),
      [fieldNames.Translation()]: fieldTypeMap.text.dumpFML(
        L10n.localize((s) => s.general.translation),
      ),
    },
  };
}
