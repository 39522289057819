import Globals from "./globals";

function voiceFor(lang: string): SpeechSynthesisVoice | null {
  const allVoices = window.speechSynthesis?.getVoices() ?? [];
  const langVoices = allVoices.filter((v) => v.lang.toLowerCase() === lang.toLowerCase());

  // NOTE: the com.apple.voice.* voices sound pretty good.
  // Better than com.apple.eloquence.
  // Under Chrome on macOS, getVoices() returns a good en-US voice first,
  // but not so under Safari.
  const voice = langVoices.find((v) => v.voiceURI.startsWith("com.apple.voice")) ?? langVoices[0];

  return voice ?? null;
}

export const isSpeechSupported = (): boolean => {
  if (Globals.platform === "android") {
    return "TTS" in window;
  }
  return "speechSynthesis" in window;
};

export const speechSynthesisSpeak = (
  text: string,
  options: { lang: string; rate: number },
  onstart: () => void,
  onend: () => void,
): void => {
  const u = new SpeechSynthesisUtterance();
  u.text = text;
  Object.assign(u, options);
  u.onstart = onstart;
  u.onend = onend;

  u.voice = voiceFor(options.lang);

  window.speechSynthesis.speak(u);
};
