import idb from "@data/idb";
import MapView from "@lib/mapView";
import { Deck, IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { Operation } from "@models/operation";
import { IDeckUpdateOperation } from "@operations/deck";
import { IDeckResponse } from "@screens/deckDownload";
import Network from "network";
import React from "react";

interface IRemoteKnolsData extends Omit<IKnol, "modified_at" | "created_at"> {
  modified_at: string;
  created_at: string;
}

export default function useUpdateModifiedKnols(deck?: IDeck, knols?: MapView<string, IKnol>) {
  React.useEffect(() => {
    if (!deck || !knols) {
      return;
    }

    async function updateDeck(meta: IDeckResponse) {
      const op: IDeckUpdateOperation = {
        ...Operation.operationDefaults(),
        type: "UPDATE",
        object_type: "deck",
        object_parameters: {
          id: meta.id,
          name: meta.name,
          description: meta.description || "",
          config: meta.config,
          modified_at: meta.modified_at,
          created_at: meta.created_at,
        },
      };
      return Operation.operate(false, [op]);
    }

    async function getDiff(deck: IDeck, knols: MapView<string, IKnol>) {
      const remoteKnolsData = await Network.fetch<{ knols: IRemoteKnolsData[] }>(
        "GET",
        `/decks/${deck.id}/knols`,
      );
      const needsUpdate: IRemoteKnolsData[] = [];
      const needsFetch: IRemoteKnolsData[] = [];
      const localKnolIDs = new Set(knols.keys());
      const remoteKnolsDataMap: Map<string, IRemoteKnolsData> = new Map();
      for (const k of remoteKnolsData?.knols ?? []) {
        // We checked this knol so remove from local set;
        // this will allow us to see if there are any local knols
        // left over that we should delete.
        localKnolIDs.delete(k.id);
        remoteKnolsDataMap.set(k.id, k);
        const localKnol = knols.get(k.id);
        if (!localKnol) {
          needsFetch.push(k);
          continue;
        }
        const modDate = new Date(k.modified_at);
        if (localKnol.modified_at && modDate > localKnol.modified_at) {
          needsUpdate.push(k);
        }
      }
      const tx = idb.db.transaction("knols", "readwrite");
      const knolStore = tx.objectStore("knols");
      for (const k of needsUpdate) {
        const knolToUpdate = await knolStore.get(k.id);
        if (knolToUpdate) {
          const { values, ...rest } = knolToUpdate; // Avoid using delete operator
          const nk = remoteKnolsDataMap.get(k.id);
          if (nk) {
            await knolStore.put({
              ...rest,
              ...nk,
              deck_id: deck.id,
              created_at: new Date(nk.created_at),
              modified_at: new Date(nk.created_at),
            });
          }
        }
      }
      for (const k of needsFetch) {
        const nk = remoteKnolsDataMap.get(k.id);
        if (nk) {
          await knolStore.put({
            ...k,
            ...nk,
            deck_id: deck.id,
            created_at: new Date(nk.created_at),
            modified_at: new Date(nk.created_at),
          });
        }
      }
      for (const k of localKnolIDs) {
        await knolStore.delete(k);
      }
      await tx.done;
    }

    async function checkForUpdate(deck: IDeck) {
      await Operation.doSync();
      const resp = await Network.fetch<IDeckResponse>("GET", `/decks/${deck.id}?modified_at`);
      if (!knols) {
        return;
      }
      if (deck.modified_at < new Date(resp.modified_at || new Date())) {
        await getDiff(deck, knols);
        await updateDeck(resp);
      }
    }

    if (!(deck.status === Deck.STATUS_PUBLIC) && deck.modified_at) {
      checkForUpdate(deck).catch(() => {
        // ignore
      });
    }
  }, [deck, deck?.id, knols]);
}
