import { IonIcon, IonItem, IonLabel, IonList, IonListHeader } from "@ionic/react";
import L10n from "localization";
import React from "react";
import { addCircle } from "ionicons/icons";
import { DeckFields } from "@models/deck";
import { FieldsMutation } from "../hooks/useFieldsReducer";
import { defaultFieldType, IField } from "fields/fields";
import { useFieldEditorModal } from "fields/editors/fieldEditorModal";
import { defaultFieldName } from "fields/lib";
import { SIDES } from "fields/sides";
import ReorderableGroupedFieldItems from "./reorderableGroupedFieldItems";

export interface IFieldVisibility {
  front: boolean;
  back: boolean;
}

interface IProps {
  fields: DeckFields;
  dispatch: React.Dispatch<FieldsMutation>;
  isExistingDeck?: boolean;
  disabled?: boolean;
}

function FieldList({ fields, dispatch, isExistingDeck, disabled }: IProps) {
  function handleNewField(field: IField) {
    dispatch({ type: "add", field });
  }

  const defaultName = defaultFieldName(defaultFieldType, fields);
  const defaultField: IField = {
    name: defaultName,
    type: defaultFieldType,
    sides: SIDES.BACK,
  };
  const [presentNewFieldModal] = useFieldEditorModal({
    initField: defaultField,
    fields: fields.concat([defaultField]),
    name: defaultField.name,
    nameEditable: true,
    onSave: handleNewField,
  });

  const canAddField = !disabled;

  return (
    <IonList lines="full">
      <IonListHeader>
        <IonLabel>{L10n.localize((s) => s.field.plural)}</IonLabel>
      </IonListHeader>
      {canAddField ? (
        <IonItem button detail={false} onClick={presentNewFieldModal}>
          <IonIcon icon={addCircle} color="success" slot="start" />
          <IonLabel color="primary">{L10n.localize((s) => s.field.new)}</IonLabel>
        </IonItem>
      ) : undefined}
      <ReorderableGroupedFieldItems
        fields={fields}
        dispatch={dispatch}
        isExistingDeck={isExistingDeck}
        disabled={disabled}
      />
    </IonList>
  );
}

export default React.memo(FieldList);
