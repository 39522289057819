import { WebPlugin, registerPlugin } from "@capacitor/core";
import { User } from "@models/user";
import logEvent from "analytics";
import IAPv2Electron from "electronIAP";
import Globals, { isMacAppStoreElectron } from "globals";

export type IAPProductPrices = Record<string, string>;

function isValidHexadecimal(input: string): boolean {
  // Regular expression to match valid hexadecimal characters
  const hexRegex = /^[0-9a-fA-F]+$/;

  // Test if the input string matches the hexadecimal regex
  return hexRegex.test(input);
}

export type UUIDv4 = string;
export function hexToUUIDv4(hex: string): UUIDv4 | null {
  if (!isValidHexadecimal(hex)) {
    return null;
  }
  if (hex.length !== 32) {
    return null;
  }
  return [
    hex.substring(0, 8),
    hex.substring(8, 12),
    hex.substring(12, 16),
    hex.substring(16, 20),
    hex.substring(20, 32),
  ].join("-");
}

export interface IRestoreProductsResponse {
  responses?: string[];
  cancelled?: boolean;
}

export interface IBuyProductResponse {
  state?: "success" | "pending" | "unverified" | "cancelled";
  response?: string;
}

export interface IAPv2Plugin {
  getProducts(): Promise<IAPProductPrices>;
  restoreProducts(args: {
    userIdUUID: UUIDv4;
  }): Promise<IRestoreProductsResponse>;
  buyProduct(args: {
    productId: string;
    userIdUUID: UUIDv4;
  }): Promise<IBuyProductResponse>;
  addListener(name: string, callback: (info: any) => void): void;
}

class IAPv2Web extends WebPlugin implements IAPv2Plugin {
  async getProducts(): Promise<IAPProductPrices> {
    if (isMacAppStoreElectron()) {
      return IAPv2Electron.getProducts();
    }
    throw this.unavailable("IAPv2 only available on iOS");
  }
  async restoreProducts(args: {
    userIdUUID: string;
  }): Promise<IRestoreProductsResponse> {
    if (isMacAppStoreElectron()) {
      return IAPv2Electron.restoreProducts(args);
    }
    throw this.unavailable("IAPv2 only available on iOS");
  }
  async buyProduct(args: {
    productId: string;
    userIdUUID: string;
  }): Promise<IBuyProductResponse> {
    if (isMacAppStoreElectron()) {
      return IAPv2Electron.buyProduct(args);
    }
    throw this.unavailable("IAPv2 only available on iOS");
  }
}

const IAPv2 = registerPlugin<IAPv2Plugin>("IAPv2", {
  web: () => new IAPv2Web(),
});

if (Globals.platform === "ios") {
  IAPv2.addListener("backgroundPurchaseUpdate", (info: any) => {
    if (typeof info === "object") {
      const state = info["state"];
      logEvent("background_iap_update", { ...info });
      switch (state) {
        case "success": {
          User.updateHasUnlimited();
          break;
        }
        case "error": {
          break;
        }
        default: {
          break;
        }
      }
    }
  });
}

export default IAPv2;
