import {
  handleIndexedDBRefreshRequiredError,
  isIndexedDBRefreshRequiredError,
} from "./indexedDBRefreshRequired";

export async function registerGlobalErrorHandlers() {
  window.addEventListener("unhandledrejection", function (evt: PromiseRejectionEvent) {
    const err = evt.reason;
    if (isIndexedDBRefreshRequiredError(err)) {
      handleIndexedDBRefreshRequiredError(err);
      evt.preventDefault();
    }
  });

  window.addEventListener("error", function (evt: ErrorEvent) {
    const err = evt.error;
    if (isIndexedDBRefreshRequiredError(err)) {
      handleIndexedDBRefreshRequiredError(err);
      evt.preventDefault();
    }
  });

  // Uncommment to test.
  // await triggerIndexedDBConnectionLost();
  // await triggerIndexedDBInternalError();
}
