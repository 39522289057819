import { humanTimeAgo } from "fields/lang";
import React, { useState, useEffect } from "react";

interface IProps {
  time: Date;
  updateIntervalSecs?: number;
}

const TimeSince: React.FC<IProps> = ({ time, updateIntervalSecs = 60 }) => {
  const [, setTick] = useState(0);

  const update = () => setTick((tick) => tick + 1);

  useEffect(() => {
    const interval = setInterval(update, updateIntervalSecs * 1000);

    return () => clearInterval(interval);
  }, [updateIntervalSecs]);

  if (!time) {
    return null;
  }

  const since = humanTimeAgo(time);

  return <span>{since}</span>;
};

export default TimeSince;
