import { IonTextarea, TextareaCustomEvent } from "@ionic/react";
import React from "react";
import Style from "style";

interface IProps {
  onChange: (value: string) => void;
  readOnly?: boolean;
  value: string;
  lineNumbers: boolean;
  mode: string;
  theme: string;
  style?: React.CSSProperties;
}
export default function CodeEditor(props: IProps): JSX.Element {
  const { onChange, readOnly, value, style } = props;

  const editorStyle = {
    fontFamily: "monospace",
    fontSize: 10,
    border: Style.colors.barBorder,
    paddingLeft: 6,
    paddingRight: 6,
    ...style,
  };

  const editor = readOnly ? (
    // HACK: without this, it was possible to focus the readOnly textarea causing the keyboard to quasi-open (showed the field-focus bar but not the keyboard itself). That was confusing and weird, so this hack just makes the text static if we're not running CodeMirror.
    <div style={editorStyle}>{value}</div>
  ) : (
    <IonTextarea
      value={value}
      disabled={readOnly}
      debounce={500}
      onIonInput={(evt: TextareaCustomEvent) => {
        const val = evt.target.value ?? "";
        if (val) {
          onChange(val);
        }
      }}
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck={false}
      style={editorStyle}
      rows={8}
    />
  );

  return editor;
}
