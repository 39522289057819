import { modalController } from "@ionic/core";
import { useIonModal } from "@ionic/react";
import React from "react";
import Style from "style";

function getActiveIonRouterOutlet() {
  return document.getElementsByTagName("ion-router-outlet")?.[0];
}

export async function getModalPresentingElement() {
  return (await modalController.getTop()) ?? getActiveIonRouterOutlet();
}

export default function useIonModalWithDismiss<T extends React.FC<any>>(
  component: T,
  props: { onDismiss?: () => void; canDismiss?: boolean; conditionalDismiss?: boolean } & Omit<
    React.ComponentProps<T>,
    "dismiss" | "setCanDismiss"
  >,
): [(opts?: any) => void, () => void] {
  const { canDismiss = true, conditionalDismiss } = props;

  // This machinery lets modals control whether they can be dismissed or not.
  const dismissConfig = React.useRef(canDismiss);

  const setCanDismiss = React.useCallback(
    (canDismiss: boolean) => {
      dismissConfig.current = canDismiss;
    },
    [dismissConfig],
  );

  const handleCanDismiss = React.useCallback(() => {
    return dismissConfig.current;
  }, [dismissConfig]);

  const handleManualDismiss = React.useCallback(
    (data?: any, role?: string) => {
      // NOTE: this is only called when the modal is dismissed by calling the dismiss function returned from useIonModal. It is not called when the modal is swiped to dismiss.

      // Force the modal to be dismissible.
      dismissConfig.current = true;

      // Dismiss it.
      // NOTE: there's some weird circularity here.
      // May be better to use  modalController.dismiss.
      // Then this function can be wrapped in useCallback.
      return modalController.dismiss(data, role).catch(() => {
        // Failing to catch this may have been causing "Non-Error promise rejection captured with value: overlay does not exist".
        // Ideally this can just be refactored away when making swipe to dismiss work.
      });
    },
    [dismissConfig],
  );

  const [present, dismiss] = useIonModal(component, {
    ...props,
    dismiss: handleManualDismiss,
    setCanDismiss: conditionalDismiss ? setCanDismiss : undefined,
  });

  const { onDismiss } = props;

  const presentModal = React.useCallback<(opts?: any) => void>(
    (opts) => {
      function handleWillDismiss() {
        // NOTE: this is called when the modal is dismissing, whether due to swipe or calling dismiss().
        const handler = opts?.onDismiss ?? onDismiss;
        if (typeof handler === "function") {
          handler();
        }

        // HACK: the iOS status bar is supposed to React to the color of the view underneath it,
        // but that appears to be flaky at least with Ionic (or something we're doing?).
        // This resets the status bar color, to its current setting, which causes fixes the
        // issue where it ends up being a white status bar on white background color or vice versa.
        Style.setStatusBar();
      }
      present({
        ...opts,
        canDismiss: conditionalDismiss ? handleCanDismiss : canDismiss,
        onWillDismiss: handleWillDismiss,
      });
    },
    [present, canDismiss, onDismiss, conditionalDismiss, handleCanDismiss],
  );

  return [presentModal, dismiss];
}

export function useIonCardModalWithDismiss<T extends React.FC<any>>(
  component: T,
  props: { onDismiss?: () => void; canDismiss?: boolean; conditionalDismiss?: boolean } & Omit<
    React.ComponentProps<T>,
    "dismiss" | "setCanDismiss"
  >,
): [(opts?: any) => void, () => void] {
  const [present, dismiss] = useIonModalWithDismiss(component, props);

  const canDismiss = props.canDismiss ?? true;

  const presentCard = React.useCallback(
    async (opts = {}) => {
      const presentingElement = await getModalPresentingElement();
      present({ presentingElement, canDismiss, ...opts });
    },
    [present, canDismiss],
  );

  return [presentCard, dismiss];
}
