import Grade from "@components/grade";
import AppNumber from "@components/number";
import TagsSection from "@components/tagsSection";
import TimeSince from "@components/timeSince";
import cardPriority from "@core/cardPriority";
import gradePercent from "@core/gradePercent";
import { IonItem, IonLabel, IonList, IonListHeader, IonNote } from "@ionic/react";
import { IKnol, Knol } from "@models/knol";
import L10n from "localization";
import React from "react";

interface IProps {
  tags: string[];
  knol?: IKnol;
  removeTag: (tag: string) => void;
}

export default function Info({ tags = [], knol, removeTag }: IProps) {
  const kwg = knol ? Knol.GetGrade(knol) : null;
  const gradePct = kwg ? gradePercent(cardPriority(kwg)) : undefined;
  const numResponses = kwg?.num_responses;

  return (
    <>
      {knol && (
        <IonList>
          <IonListHeader>{L10n.localize((s) => s.general.info)}</IonListHeader>
          <IonItem>
            <IonLabel>
              <h2>{L10n.localize((s) => s.sort.createdAt)}</h2>
            </IonLabel>
            <IonNote slot="end">
              {knol?.created_at ? <TimeSince time={knol.created_at} /> : undefined}
            </IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>{L10n.localize((s) => s.review.total)}</h2>
            </IonLabel>
            <IonNote slot="end">{knol ? <AppNumber num={numResponses} /> : undefined}</IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>
              <h2>{L10n.localize((s) => s.grade.singular)}</h2>
            </IonLabel>
            <IonNote slot="end">
              <Grade percent={gradePct} />
            </IonNote>
          </IonItem>
        </IonList>
      )}
      {tags.length > 0 ? (
        <TagsSection tags={tags} removeTag={removeTag} confirmRemoval />
      ) : undefined}
    </>
  );
}
