import { IonNav } from "@ionic/react";
import { DeckFields } from "@models/deck";
import { FieldsMutation } from "fields/hooks/useFieldsReducer";
import { History } from "history";
import React, { createContext, useCallback } from "react";
import DeckCreateMainNav from "./deckCreate/main";

// Props don't seem to pass nicely through <IonNav>'s.
// For that reason, we use a React Context here (https://reactjs.org/docs/context.html).
// Because the deck fields data structure is complex, and we will need to
// make modifications within the structure (e.g. change a field name or type),
// we use the "reducer" pattern, rather than state.
// See https://reactjs.org/docs/hooks-reference.html#usereducer).

export const FieldsContext = createContext<{
  fields: DeckFields;
  dispatch: React.Dispatch<FieldsMutation>;
  modified?: boolean;
}>({
  fields: [],
  dispatch: () => null,
});

export default function DeckCreateScreen(props: {
  history: History;
  dismiss: () => Promise<void>;
}): JSX.Element {
  const { history, dismiss } = props;

  // NOTE: this useCallback is necessary to prevent DeckCreateMainNav from getting
  // recreated on re-renders (which will happen when fields state changes).
  const navRoot = useCallback(
    () => <DeckCreateMainNav history={history} dismiss={dismiss} />,
    [history, dismiss],
  );

  return <IonNav root={navRoot} />;
}
