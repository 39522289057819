import Button from "@components/button";
import { IonButton } from "@ionic/react";
import Globals from "globals";
import React from "react";
import Style from "style";

interface IProps {
  disabled?: boolean;
  onClick: () => void;
  children: any;
  dangerous?: boolean;
  isTheaterMode?: boolean;
  style?: any;
}
export default function CTAButton(props: IProps) {
  const { disabled, onClick, children, dangerous, isTheaterMode, style } = props;

  const useIonButton = Globals.platform !== "ios";

  let size: "large" | "small" | "default" = "large";
  if (dangerous) {
    size = "default";
  }
  if (isTheaterMode) {
    size = "small";
  }

  let ionicColor: React.ComponentProps<typeof IonButton>["color"] | undefined = undefined;
  let iosColor: string | undefined = undefined;
  if (dangerous) {
    ionicColor = "danger";
  } else if (Style.currentTheme === "night") {
    ionicColor = "success";
    iosColor = "var(--ion-color-success, #2dd36f)";
  }

  return useIonButton ? (
    <IonButton
      mode="md"
      disabled={disabled}
      color={ionicColor}
      fill={dangerous ? "clear" : "outline"}
      size={size}
      expand="block"
      onClick={onClick}
      style={{
        "--border-radius": "16px",
        "--background": Style.colors.primaryBg,
        "--box-shadow": Style.currentTheme === "night" ? "0 0 8px #333" : "0 0 8px #CCC",
        minHeight: 96,
        ...style,
      }}
    >
      {children}
    </IonButton>
  ) : (
    <Button
      disabled={disabled}
      border={!dangerous}
      onClick={onClick}
      dangerous={dangerous}
      color={iosColor}
      borderWidth="2px"
      borderRadius="8px"
    >
      {children}
    </Button>
  );
}
