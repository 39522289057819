import { Histogram, gradesByIncreasingPriority } from "./gradeHistogram";

export function adjustGradeCountsByAvailability(available: Histogram, counts: Histogram) {
  // Shift any cards drawn from an empty grade into lowest grade with spares.
  function highestPriorityGradeWithSpares() {
    for (const grade of gradesByIncreasingPriority) {
      if (available[grade] - counts[grade] > 0) {
        return grade;
      }
    }
  }

  for (const grade of gradesByIncreasingPriority) {
    while (counts[grade] > available[grade]) {
      counts[grade] -= 1;
      const spare = highestPriorityGradeWithSpares();
      if (spare) {
        counts[spare] += 1;
      } else {
        console.log("No spare grades!", counts, available);
      }
    }
  }
  return counts;
}
