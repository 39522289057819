import React from "react";
import Style from "style";

interface IProps {
  isFinalPill: boolean;
  onTouchStart: React.TouchEventHandler<HTMLDivElement>;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  disabled: boolean;
  active: boolean;
  children: React.ReactNode;
}

export default function Pill({
  isFinalPill = false,
  disabled = true,
  active = false,
  onTouchStart,
  onClick,
  children,
}: IProps): JSX.Element {
  let style = {
    width: "100%",
    textAlign: "center",
    padding: "0.5rem",
    cursor: !disabled ? "pointer" : undefined,
  } as React.CSSProperties;

  style = Object.assign(
    style,
    disabled
      ? {
          backgroundColor: Style.colors.secondaryBg,
          borderColor: Style.colors.actionableMutedFg,
          color: Style.colors.actionableMutedFg,
        }
      : active
        ? {
            backgroundColor: Style.colors.actionableBg,
            color: Style.colors.primaryBg,
          }
        : {
            backgroundColor: Style.colors.primaryBg,
            color: Style.colors.actionableFg,
            borderRight: !isFinalPill
              ? `${Style.hairline} solid ${Style.colors.actionableMutedFg}`
              : undefined,
          },
  );

  return (
    <div style={style} onTouchStart={onTouchStart} onClick={onClick}>
      {children}
    </div>
  );
}
