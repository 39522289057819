import clone from "lib/clone";
import { useReducer } from "react";

export type FieldToValueMap = Record<string, string>;

interface IUpdateMutation {
  type: "update";
  key: string;
  value: string;
}

interface ILoadMutation {
  type: "load";
  newState: Record<string, string>;
}

export type Mutation = IUpdateMutation | ILoadMutation;

function editingValuesReducer(
  state: Record<string, string>,
  action: Mutation,
): Record<string, string> {
  switch (action.type) {
    case "load":
      return action.newState;
    case "update": {
      const copy = clone(state);
      const { key, value } = action;
      if (copy[key]) {
        copy[key] = value;
      } else {
        copy[key] = value;
      }
      return copy;
    }
  }
}

export default function useEditingValuesReducer(
  init: Record<string, string> = {},
): [Record<string, string>, React.Dispatch<Mutation>] {
  return useReducer(editingValuesReducer, init);
}
