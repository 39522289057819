import { Haptics, ImpactStyle } from "@capacitor/haptics";
import timeAdjustedScore from "@core/timeAdjustedScore";
import { ITypedBlobURL } from "@data/idb";
import { MapLike } from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IDeckSettings } from "@models/deckSettings";
import { IKnol } from "@models/knol";
import { Response } from "@models/response";
import logEvent from "analytics";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import Globals, { TResponseValue, baseScores } from "globals";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import { useCallback, useRef } from "react";
import { getHapticsEnabled } from "settings";
import { ID } from "types/ID";
import usePDP from "unlimited/pdp";
import { checkReviewGate } from "unlimited/reviewGate";
import { initReviewSession } from "./initReviewSession";
import { blobIDToURL, loadBlobURLs, revokeBlobURLs } from "./reviewSessionBlobs";
import { ICardResponse } from "./types";
import { IReviewSessionState, useSessionReducer } from "./useSessionReducer";

export interface IPresentationData {
  blobIDToURL: Record<ID, ITypedBlobURL>;
  knolIDToSideBlobIDs: Record<ID, Array<ID[]>>; // knol ID => blob IDs on each side of the card
  knolIDToMissingBlobs: Record<ID, boolean>; // knol ID => whether any blobs failed to load
}

async function recordResponse(
  response: TResponseValue,
  durationMs: number,
  knol: IKnol,
  layoutID: ID,
) {
  const baseScore = baseScores[response];
  const score =
    response === Globals.RESPONSES.AUTO ? baseScore : timeAdjustedScore(baseScore, durationMs);

  return Response.record(knol, layoutID, score, durationMs);
}

export interface IReviewSessionController {
  state: IReviewSessionState;
  beginReviewSession: (src?: string) => Promise<void>;
  skipToRecap: () => void;
  skip: () => void;
  flip: () => void;
  inputResponse: (response: TResponseValue) => Promise<void>;
  setPaused: (paused: boolean) => void;
  responses: React.MutableRefObject<ICardResponse[]>;
  presentationData: React.MutableRefObject<IPresentationData>;
  reloadPresentationData: () => Promise<void>;
  cleanup: () => void;
}
export function useReviewSessionController(
  decks: MapLike<ID, IDeck> | undefined,
  knols: MapLike<ID, IKnol> | undefined,
  settings: React.MutableRefObject<IDeckSettings>,
): IReviewSessionController {
  const [state, dispatch] = useSessionReducer();
  const flip = useCallback(() => {
    dispatch({ type: "flip" });
  }, [dispatch]);

  // NOTE: pdpReason must be defined with useCallback or it will cause showFatalPDP to change each call.
  const pdpReason = useCallback(() => L10n.localize((s) => s.account.reviewGateGateReason), []);
  const [showFatalPDP] = usePDP({
    source: "reviewgate_gate_review_screen",
    reason: pdpReason,
  });

  const responses = useRef<ICardResponse[]>([]);
  const [showResponseInsertError] = useErrorAlert({
    code: "INSERTING_RESPONSE",
    message: L10n.localize((s) => s.errors.recordingResponse),
  });
  const inputResponse = useCallback(
    async (response: TResponseValue) => {
      if (state.screenMode !== "review" || state.loading) {
        logEvent("response_input_blocked", {
          screenMode: state.screenMode,
          loading: state.loading,
        });
        return;
      }
      const endTimeMs = new Date().getTime();
      const durationMs = Math.max(endTimeMs - state.activeCard.startTimeMs, 0);

      if (getHapticsEnabled()) {
        Haptics.impact({ style: ImpactStyle.Light }).catch(() => {
          // Whatever.
        });
      }

      const knol = state.knols[state.activeCard.index];
      const deck = state.decks.get(knol.deck_id);
      const layoutID = deck?.layout_id ?? MAGIC_LAYOUT_ID; // TODO: support ALL_LAYOUT_ID
      recordResponse(response, durationMs, knol, layoutID).catch((err) => {
        showResponseInsertError(err);
      });

      const rn = Globals.RESPONSE_NAMES[response];
      responses.current.push({
        knol,
        response: rn,
        durationMs,
        layoutID,
      });

      const gate = await checkReviewGate();
      if (gate) {
        if (gate.level === "gate") {
          dispatch({ type: "skipToRecap" });
          showFatalPDP({ context: gate });
          return;
        }
      }
      dispatch({ type: "advance" });
    },
    [
      dispatch,
      showResponseInsertError,
      state.activeCard.index,
      state.knols,
      state.activeCard.startTimeMs,
      state.loading,
      state.screenMode,
      state.decks.get,
      showFatalPDP,
    ],
  );

  const skip = useCallback(() => {
    dispatch({ type: "advance" });
  }, [dispatch]);

  const setPaused = useCallback(
    (paused: boolean) => {
      dispatch({ type: "setPaused", paused });
    },
    [dispatch],
  );

  const reloadPresentationData = useCallback(async () => {
    try {
      await loadBlobURLs(state, presentationData.current);
    } catch (err) {
      dispatch({ type: "error", error: err });
      return;
    }
    dispatch({ type: "updatePresentationData" });
  }, [state, dispatch]);

  const presentationData = useRef<IPresentationData>({
    blobIDToURL: {},
    knolIDToSideBlobIDs: {},
    knolIDToMissingBlobs: {},
  });
  const beginReviewSession = useCallback(
    async (src?: string) => {
      if (!decks || !knols) {
        return;
      }
      dispatch({ type: "loading" });

      const session = await initReviewSession({ decks, knols, settings: settings.current });
      responses.current = [];
      presentationData.current = {
        blobIDToURL: blobIDToURL, // NOTE: this is the global map.
        knolIDToSideBlobIDs: {},
        knolIDToMissingBlobs: {},
      };

      // Clean up any leftover blob URLs from prior session.
      revokeBlobURLs();

      try {
        await loadBlobURLs(session, presentationData.current);
      } catch (err) {
        dispatch({ type: "error", error: err });
        return;
      }

      checkReviewGate();
      dispatch({ type: "begin", session });
    },
    [decks, knols, settings, dispatch],
  );

  const skipToRecap = useCallback(() => {
    dispatch({ type: "skipToRecap" });
  }, [dispatch]);

  return {
    state,
    beginReviewSession,
    skipToRecap,
    skip,
    flip,
    inputResponse,
    setPaused,
    responses,
    presentationData,
    reloadPresentationData,
    cleanup: revokeBlobURLs,
  };
}
