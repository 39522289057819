import { logEventImmediately } from "analytics";

// WebKit has a bug where it sometimes throws an UnknownError with this message.
// References:
// - https://bugs.webkit.org/show_bug.cgi?id=197050
// - https://stackoverflow.com/a/56496297
const indexedDBRefreshRequiredMessage =
  "Connection to Indexed Database server lost. Refresh the page to try again";

const indexedDBInternalErrorMessage =
  "An internal error was encountered in the Indexed Database server";

const indexedDBConnectionClosingMessage =
  "Failed to execute 'transaction' on 'IDBDatabase': The database connection is closing.";

const indexedDBNonExistentCursorMessage = "Attempt to iterate a cursor that doesn't exist";

function err2msg(err: unknown): string | undefined {
  try {
    if (typeof err === "object" && err !== null && "message" in err) {
      return err["message"] as string;
    }
  } catch {
    // Whatever.
  }
}

// Returns true if the error is an IndexedDB refresh required error.
export function isIndexedDBRefreshRequiredError(err: unknown): boolean {
  const msg = err2msg(err);
  try {
    if (isIndexedDBRefreshRequiredMessage(msg)) {
      return true;
    }
  } catch {
    return false;
  }
  return false;
}

export function isIndexedDBRefreshRequiredMessage(msg: string | undefined) {
  return (
    msg?.includes(indexedDBRefreshRequiredMessage) ||
    msg?.includes(indexedDBInternalErrorMessage) ||
    msg?.includes(indexedDBConnectionClosingMessage) ||
    msg?.includes(indexedDBNonExistentCursorMessage)
  );
}

const lastRefreshedAtStorageKey = "indexedDBRefreshRequiredLastRefreshAt";

// Threshold to prevent infinite reload loop from happening.
const refreshThresholdMillis = 10_000;

export async function handleIndexedDBRefreshRequiredError(err: unknown) {
  const now = new Date();
  const nowMillis = now.getTime();

  const lastRefreshAt = localStorage.getItem(lastRefreshedAtStorageKey);
  const lastRefreshAtMillis = Date.parse(lastRefreshAt ?? "");
  const deltaMillis = nowMillis - lastRefreshAtMillis;
  const refresh = isNaN(deltaMillis) || deltaMillis >= refreshThresholdMillis;

  try {
    const payload = {
      refreshing: refresh,
      lastRefreshAt,
      errorMsg: err2msg(err),
    };
    await logEventImmediately("indexeddb_refresh_required", payload);
  } catch {
    // Oh well.
  }

  if (refresh) {
    localStorage.setItem(lastRefreshedAtStorageKey, now.toISOString());
    window.location.reload();
  }
}

// Returns true if handling is necessary.
export async function handleIndexedDBRefreshRequiredErrorIfNecessary(
  err: unknown,
): Promise<boolean> {
  if (isIndexedDBRefreshRequiredError(err)) {
    await handleIndexedDBRefreshRequiredError(err);
    return true;
  }
  return false;
}

// For testing.
export async function triggerIndexedDBConnectionLost() {
  const err = new Error(indexedDBRefreshRequiredMessage);
  err.name = "UnknownError";
  throw err;
}

export async function triggerIndexedDBInternalError() {
  const err = new Error(indexedDBInternalErrorMessage);
  err.name = "UnknownError";
  throw err;
}

export async function triggerIndexedDBConnectionClosing() {
  const err = new Error(indexedDBConnectionClosingMessage);
  err.name = "UnknownError";
  throw err;
}
