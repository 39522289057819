import { useCallback, useRef, useState } from "react";

export default function useDebounce(
  millis: number,
): [(cb: () => Promise<void>) => void, () => void, boolean] {
  const handle = useRef<NodeJS.Timeout>();
  const [timing, setTiming] = useState(false);

  const reset = useCallback(() => {
    clearTimeout(handle.current);
    setTiming(false);
  }, []);

  const start = useCallback(
    (cb: () => Promise<void>) => {
      clearTimeout(handle.current);

      async function handler() {
        await cb();
        setTiming(false);
      }

      handle.current = setTimeout(handler, millis);
      setTiming(true);
    },
    [millis],
  );

  return [start, reset, timing];
}
