import TimeSince from "@components/timeSince";
import { IonIcon } from "@ionic/react";
import { ISyncStatus } from "@models/operation";
import {
  alertCircleOutline,
  checkmarkCircleOutline,
  cloudOfflineOutline,
  syncOutline,
} from "ionicons/icons";
import L10n from "localization";
import React = require("react");

interface ISyncStatusProps {
  isSyncing: boolean;
  lastSyncStatus?: ISyncStatus;
}

export default function SyncStatus(props: ISyncStatusProps): JSX.Element {
  const { isSyncing, lastSyncStatus } = props;
  if (isSyncing) {
    return (
      <>
        <span>{L10n.localize((s) => s.sync.inProgress)}</span>
        <IonIcon
          key="syncing"
          style={{
            marginLeft: 4,
            fontSize: "22px",
            animation: "loading-spinner 1250ms infinite linear",
          }}
          icon={syncOutline}
        />
      </>
    );
  }
  if (lastSyncStatus) {
    if (lastSyncStatus?.statusCode >= 200 && lastSyncStatus?.statusCode <= 299) {
      return (
        <>
          <TimeSince time={new Date(lastSyncStatus.timestamp)} />
          <IonIcon
            color="success"
            style={{ marginLeft: 4, fontSize: "24px" }}
            icon={checkmarkCircleOutline}
          />
        </>
      );
    } else if (lastSyncStatus.statusCode >= 400 && lastSyncStatus.statusCode <= 599) {
      return <IonIcon color="warning" style={{ fontSize: "24px" }} icon={alertCircleOutline} />;
    } else if (lastSyncStatus.statusCode === 0 || lastSyncStatus.statusText === "offline") {
      return (
        <>
          <span>{L10n.localize((s) => s.sync.offline)}</span>
          <IonIcon style={{ marginLeft: 4, fontSize: "24px" }} icon={cloudOfflineOutline} />
        </>
      );
    }
  }
  return <></>;
}
