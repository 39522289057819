import { IKnol, Knol } from "@models/knol";
import { adjustGradeCountsByAvailability } from "./adjustGradeCountsByAvailability";
import cardPriority from "./cardPriority";
import { Grade } from "./grade";
import { Histogram, gradeHistogram } from "./gradeHistogram";
import { gradedCards } from "./gradedCards";
import { newCards } from "./newCards";
import { probBounds } from "./probBounds";
import { randomGradeCounts } from "./randomGradeCounts";
import { shuffle } from "./shuffle";

/** Draw a hand of cards with grades random, but centered around the given grade. */
export default function drawAroundGrade(cards: IKnol[], grade: Grade, limit: number): string[] {
  const histogram = gradeHistogram(cards);

  if (!histogram[grade]) {
    return [];
  }

  const totalCards = Object.values(histogram).reduce((a, b) => a + b);

  const handSize = Math.min(limit, totalCards);

  if (handSize < 1) {
    return [];
  }

  const _probBounds = probBounds(grade);
  const _randomGradeCounts = randomGradeCounts(handSize, _probBounds);
  const gradeCounts = adjustGradeCountsByAvailability(histogram, _randomGradeCounts);

  // Non-new cards.
  const nonNewCardIds: string[] = [];
  const _gradedCards = gradedCards(cards);

  // Check if all cards are grade "A" or higher.
  let aCards: IKnol[] = [];
  let allAs = true;
  for (const _grade of Object.keys(_gradedCards)) {
    if (_grade === "A" || _grade === "AA" || _grade === "AAA") {
      aCards = aCards.concat(_gradedCards[_grade]);
    } else {
      if ((_gradedCards[_grade] || []).length > 0) {
        allAs = false;
      }
    }
  }

  // If all cards are grade "A" or higher, take a random selection of them;
  // otherwise, select the highest priority-cards in each grade.
  if (allAs) {
    const aCounts = gradeCounts.A + gradeCounts.AA + gradeCounts.AAA;
    const shuffledACards = shuffle(aCards);
    for (let i = 0; i < aCounts; i++) {
      nonNewCardIds.push(shuffledACards[i].id);
    }
  } else {
    for (const _grade of Object.keys(_gradedCards)) {
      if (_grade === "NEW") {
        continue;
      }

      const count = gradeCounts[_grade as keyof Histogram];
      if (count && count > 0) {
        const descByPri = _gradedCards[_grade].sort(
          (c1, c2) => cardPriority(Knol.GetGrade(c2)) - cardPriority(Knol.GetGrade(c1)),
        );
        for (let i = 0; i < count; i++) {
          nonNewCardIds.push(descByPri[i].id);
        }
      }
    }
  }

  // New cards.
  const shuffledNewCardIds: string[] = [];
  const shuffledNewCards = shuffle(newCards(cards));
  for (let i = 0; i < gradeCounts.NEW; i++) {
    shuffledNewCardIds.push(shuffledNewCards[i].id);
  }

  // Final card ids.
  return shuffle(nonNewCardIds.concat(shuffledNewCardIds));
}
