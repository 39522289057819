import { IonApp, IonRouterOutlet, IonTabs, setupIonicReact, useIonAlert } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import L10n from "localization";
import React, { useRef } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
// import "./theme/variables.css";

import { BottomTabBar } from "@components/bottomTabBar";
import { ScreenModal } from "@components/prompt";
import { IDeck } from "@models/deck";
import { createFolder } from "@models/folder";
import ArchiveScreen from "@screens/archive";
import AuthScreen from "@screens/auth";
import { useCardModal } from "@screens/card";
import CardsScreen from "@screens/deck/cards";
import DeckScreen from "@screens/deck/deck";
import DeckCreateScreen from "@screens/deckCreate";
import FolderOverviewScreen from "@screens/folder/overview";
import GroupJoinHandler from "@screens/groupJoinHandler";
import HomeScreen from "@screens/home";
import ImportScreen from "@screens/import";
import InboxScreen from "@screens/inbox";
import ProfileScreen from "@screens/profile";
import SearchScreen from "@screens/search";
import SessionScreen from "@screens/session";
import StatsScreen from "@screens/stats";
import { StudyGroupScreen } from "@screens/studyGroup";
import { StudyGroupsScreen } from "@screens/studyGroups";
import DeeplinkHandler from "deeplinkHandler";
import Device from "device";
import Globals from "globals";
import useLocalPrivateDeckSelect from "hooks/util/useDeckSelect";
import useIonModalWithDismiss from "hooks/util/useIonModalWithDismiss";
import { PushNotifHandler } from "pushnotifs";
import { ID } from "types/ID";

const isWin = navigator.platform?.toLowerCase().includes("win");
const isAndroid = Globals.platform === "android";
export const ionicMode = isWin || isAndroid ? "md" : "ios";
setupIonicReact({ mode: ionicMode, innerHTMLTemplatesEnabled: true });

export function isAuthed(): boolean {
  return Device.hasToken();
}

function useMoreModalWithHistory<T extends React.FC<any>>(
  component: T,
  routerRef: React.RefObject<IonReactRouter>,
  outletRef: React.RefObject<HTMLIonRouterOutletElement>,
) {
  const props: any = {
    history: routerRef.current?.history,
  };
  const [present] = useIonModalWithDismiss(component, props);

  return () => {
    present({ presentingElement: outletRef.current, canDismiss: true });
  };
}

// HACK: this one takes some explaining.
// 1. The card create modal requires a deckID prop.
// 2. The useIonModal hook only supports setting component props at the time you call the hook, not at the time you call the present() function that it returns. Because we use an alert, via the useDeckSelect hook, to get the deckID to set, we only know the deckID at the time we want to call present(), not at the time we initially call the useIonModal hook.
// 3. Ionic does support imperatively showing modals, using their modalController API, BUT, when I tried calling that, I get an error. I think they just have a bug where it's not set up properly when used from a React-based app (Angular seems to be their primary or at least their best-supported target).
// So the solution here is to use the useDeckSelect hook to get deckID using an Ionic Alert, set that using a state hook, and wrap the CardCreateScreen with a component that pulls in the deckID from state, along with a useEffect hook that's tuned to pop up the modal at the right time.
// It ain't pretty, but it works.
function useCardCreateModal() {
  const [state, setState] = React.useState<{ deck: IDeck | undefined; counter: number }>({
    deck: undefined,
    counter: 0,
  });
  const { deck, counter } = state;

  const presentDeckSelect = useLocalPrivateDeckSelect({
    header: L10n.localize((s) => s.card.create),
    message: L10n.localize((s) => s.create.instructions),
    onSelect: (deck) => {
      setState({ deck, counter: counter + 1 });
    },
  });

  const [presentModal] = useCardModal({ deck });
  React.useEffect(() => {
    if (deck) {
      presentModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deck, counter]);

  return presentDeckSelect;
}

function useNewFolderModal(routerRef: React.RefObject<IonReactRouter>) {
  const [present] = useIonAlert();

  function handleNewFolder() {
    const inputName = "folder";
    present({
      header: L10n.localize((s) => s.folder.singular),
      inputs: [{ id: "folder-name-alert", name: inputName }],
      buttons: [
        {
          text: L10n.localize((s) => s.actions.cancel),
          role: "cancel",
        },
        {
          text: L10n.localize((s) => s.actions.ok),
          role: "confirm",
        },
      ],
      onDidDismiss: async (evt) => {
        if (evt?.detail && evt.detail.role !== "cancel") {
          const folderName = evt.detail.data?.values?.[inputName];
          if (folderName) {
            await createFolder(folderName);
            routerRef.current?.history.push(`/folders/${encodeURIComponent(folderName)}`);
          }
        }
      },
    }).then(() => {
      const i = document.querySelector("#folder-name-alert");
      if (i) {
        setTimeout(() => {
          (i as HTMLInputElement).focus();
        }, 20); // magic sauce
      }
    });
  }

  return handleNewFolder;
}

function Router() {
  const routerRef = useRef<IonReactRouter>(null);
  const outletRef = useRef<HTMLIonRouterOutletElement>(null);

  const presentNewDeckModal = useMoreModalWithHistory(DeckCreateScreen, routerRef, outletRef);
  const presentImportDeckModal = useMoreModalWithHistory(ImportScreen, routerRef, outletRef);
  const presentNewCardModal = useCardCreateModal();
  const presentNewFolderModal = useNewFolderModal(routerRef);

  return (
    <IonReactRouter ref={routerRef}>
      <PushNotifHandler />
      <DeeplinkHandler />
      <IonTabs>
        <IonRouterOutlet ref={outletRef} animated={true}>
          <Redirect exact path="/" to="/home" />
          <Route
            exact
            path="/home"
            render={(props: RouteComponentProps) => (
              <HomeScreen
                history={props.history}
                onNewDeck={presentNewDeckModal}
                onImportDeck={presentImportDeckModal}
                onNewFolder={presentNewFolderModal}
                onNewCard={presentNewCardModal}
              />
            )}
          />
          <Route
            exact
            path="/g/:token"
            render={(props: RouteComponentProps<{ token: string }>) => (
              <GroupJoinHandler history={props.history} token={props.match.params.token} />
            )}
          />
          <Route exact path="/stats" render={() => <StatsScreen />} />
          <Route
            exact
            path="/search"
            render={(props: RouteComponentProps) => <SearchScreen history={props.history} />}
          />
          <Route path="/profile" component={ProfileScreen} />
          <Route
            exact
            path="/create/import"
            render={(props: RouteComponentProps) => <ImportScreen history={props.history} />}
          />
          <Route exact path="/groups" render={() => <StudyGroupsScreen />} />
          <Route
            exact
            path="/groups/:groupId"
            render={(props: RouteComponentProps<{ groupId: string }>) => (
              <StudyGroupScreen id={props.match.params.groupId} history={props.history} />
            )}
          />
          <Route
            exact
            path="/decks/local/:deckID"
            render={(props: RouteComponentProps<{ deckID: string }>) => (
              <DeckScreen deckID={props.match.params.deckID} />
            )}
          />
          <Route
            exact
            path="/decks/local/:deckID/cards"
            render={(props: RouteComponentProps<{ deckID: string }>) => (
              <CardsScreen deckID={props.match.params.deckID} />
            )}
          />
          <Route
            exact
            path="/decks/local/:deckID/review"
            render={(props: RouteComponentProps<{ deckID: ID }>) => (
              <SessionScreen spec={{ deckID: decodeURIComponent(props.match.params.deckID) }} />
            )}
          />
          <Route
            exact
            path="/review"
            render={(props: RouteComponentProps) => <SessionScreen spec="omni" />}
          />
          <Route exact path="/archive" render={(props: RouteComponentProps) => <ArchiveScreen />} />
          <Route
            exact
            path="/inbox"
            render={(props: RouteComponentProps) => <InboxScreen history={props.history} />}
          />
          <Route
            exact
            path="/folders/:folder"
            render={(props: RouteComponentProps<{ folder: string }>) => (
              <FolderOverviewScreen
                folder={decodeURIComponent(props.match.params.folder)}
                onCreateDeck={presentNewDeckModal}
                history={props.history}
              />
            )}
          />
          <Route
            exact
            path="/folders/:folder/review"
            render={(props: RouteComponentProps<{ folder: string }>) => (
              <SessionScreen spec={{ folder: decodeURIComponent(props.match.params.folder) }} />
            )}
          />
          <Route exact path="/auth" render={() => <AuthScreen />} />
        </IonRouterOutlet>
        <BottomTabBar slot="bottom" translucent />
      </IonTabs>
    </IonReactRouter>
  );
}

export default function IonicApp(): JSX.Element {
  return (
    <IonApp>
      <Router />
      <ScreenModal />
    </IonApp>
  );
}
