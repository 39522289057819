import Globals from "./globals";
import L10n from "./localization";
import Lib from "./lib";
import { showPrompt } from "@components/prompt";
import EventBus from "eventBus";

export const authgateMsgId = "UP003";

export interface IServerMessage {
  lvl: "warn" | "info";
  msg: string;
  id?: string;
}

export function shouldPromptFor(msg: IServerMessage): boolean {
  return msg.lvl?.toLowerCase() === "warn";
}

const msgIDUpgradePrefix = "UP";
export function shouldUpgradePromptFor(msg: IServerMessage): boolean {
  return msg.id?.startsWith(msgIDUpgradePrefix) ?? false;
}

export function shouldSuppress(msg: IServerMessage): boolean {
  if (!msg.id) {
    return false;
  }
  const shown = Globals.msgIDsShownInSession[msg.id];
  return shown;
}

function recordPromptShown(msg: IServerMessage) {
  const id = msg.id;
  if (!id) {
    return;
  }
  Globals.msgIDsShownInSession[id] = true;
}

export function parseHeader(msgHeader: string | null | undefined): IServerMessage[] {
  if (!msgHeader) {
    return [];
  }
  try {
    const arr = Lib.base64DecToArr(msgHeader);
    const buf = new Uint8Array(arr);
    const json = new TextDecoder().decode(buf);
    const srvMsgs: IServerMessage[] = JSON.parse(json);
    return srvMsgs;
  } catch (e) {
    return [];
  }
}

export function combinedMessage(msgs: IServerMessage[]): string {
  const sep = " ";
  return msgs.map((m) => m.msg).join(sep);
}

export function handleMsgs(allMsgs: IServerMessage[]): void {
  // Exclude messages that should be suppressed.
  const msgs = allMsgs.filter((m) => !shouldSuppress(m));

  const shouldPrompt = msgs.some(shouldPromptFor);
  if (!shouldPrompt) {
    return;
  }

  for (const m of msgs) {
    recordPromptShown(m);
  }

  const prompt = () => combinedMessage(msgs);

  const shouldUpgradePrompt = msgs.some(shouldUpgradePromptFor);
  if (shouldUpgradePrompt) {
    EventBus.emit("showPDP", { source: "msg", reason: prompt });
    return;
  }

  showPrompt({
    prompt: prompt(),
    promptType: "alert",
    title: L10n.localize((s) => s.general.attention),
  });
}
