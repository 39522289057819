import { IonItem, IonLabel } from "@ionic/react";
import { logSearchEvent } from "analytics/search";
import React, { useCallback } from "react";

interface IBrowseDeck {
  id: string;
  name: string;
  description: string;
}

export interface IBrowseCategory {
  name: string;
  decks: IBrowseDeck[];
  icon_class?: string;
  query: string;
  score?: number;
}

export default function Category(props: {
  cat: IBrowseCategory;
  search: (query: string) => void;
}): JSX.Element {
  const { cat, search } = props;

  const handleClick = useCallback(() => {
    logSearchEvent("clicked_category", {
      category: cat.name,
    });
    search(cat.name);
  }, [search, cat.name]);

  return (
    <IonItem onClick={handleClick} detail>
      <IonLabel style={{ flexDirection: "row", flex: 1 }}>
        <h1 style={{ display: "inline", marginLeft: 8 }}>{cat.name}</h1>
      </IonLabel>
    </IonItem>
  );
}
