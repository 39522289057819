import { useLoadingText } from "@components/loadingText";
import { IonTextarea, TextareaCustomEvent } from "@ionic/react";
import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import useAutoFocusIonTextarea from "fields/hooks/useAutoFocusIonTextarea";
import useSetLangIonTextarea from "fields/hooks/useSetLangIonTextarea";
import useValueEditorSourcePipeline from "fields/sources/useSourcePipeline";
import { IFieldText } from "fields/types/text";
import { IFieldTTS } from "fields/types/tts";
import L10n from "localization";
import React, { useRef } from "react";

export default function FieldValueEditorText({
  field,
  name,
  value,
  onChange,
  editorRef,
  autoFocus,
  editable,
}: IFieldValueEditorProps<IFieldText | IFieldTTS>): JSX.Element {
  const lang = field.attributes?.lang;
  const ref = useRef<HTMLIonTextareaElement>(null);
  useSetLangIonTextarea(lang, ref);
  useAutoFocusIonTextarea(autoFocus, ref.current);

  const pipelineUpdatePending = useValueEditorSourcePipeline(field, onChange);
  const loadingText = useLoadingText(
    pipelineUpdatePending,
    L10n.localize((s) => s.general.processing),
  );

  function handleChange(val: string) {
    onChange(name, val);
  }

  const disabled = pipelineUpdatePending;

  return (
    <FieldValueEditorItem
      name={name}
      field={field}
      editorRef={editorRef}
      onTextPaste={disabled ? undefined : handleChange}
    >
      <IonTextarea
        aria-label={name}
        ref={ref}
        readonly={editable === false}
        autoGrow
        disabled={disabled}
        value={pipelineUpdatePending ? loadingText : value}
        spellCheck
        rows={1}
        placeholder={L10n.localize((s) => s.general.clickToEdit)}
        onIonInput={(evt: TextareaCustomEvent) => {
          const val = evt.target.value;
          if (val !== null && val !== undefined) {
            handleChange(val);
          }
        }}
      />
    </FieldValueEditorItem>
  );
}
