import React from "react";
import L10n from "localization";
import { Grade } from "@core/grade";

export default function Letter(props: { letter: Grade | null }): JSX.Element | null {
  const { letter } = props;

  if (!letter) {
    return null;
  }

  const humanLetter = L10n.localize((s) => s.grade[letter]);
  const style = { marginLeft: "0.10em" };
  return <span style={style}>{humanLetter}</span>;
}
