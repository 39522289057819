import { DeckFieldMap } from "fields/lib";
import { IParseMessage, IParseResult } from "./parser";
import { fieldTypeMap } from "fields/fields";

export interface IParsedCard {
  values: Record<string, string>;
  tags: string[];
}

export function parseCards(
  deckNodeChildren: Element[],
  fields: DeckFieldMap,
): IParseResult<IParsedCard[]> {
  const cardsEls = deckNodeChildren.filter((el) => el.tagName === "cards");
  if (cardsEls.length !== 1) {
    // biome-ignore lint/style/noUnusedTemplateLiteral: need the `` to preserve tag
    return { val: null, errors: [{ msg: `Expected single <cards> element.` }] };
  }
  const cardsEl = cardsEls[0];

  const cardEls = Array.from(cardsEl.children);

  const cardResults = cardEls.map((el) => parseCard(el, fields));
  const warnings: IParseMessage[] = [];
  const errors: IParseMessage[] = [];
  const parsedCards: IParsedCard[] = [];
  for (const result of cardResults) {
    if (result.val) {
      parsedCards.push(result.val);
    }
    warnings.push(...(result.warnings ?? []));
    errors.push(...(result.errors ?? []));
  }

  return { val: parsedCards, warnings, errors };
}

function parseCard(cardEl: Element, fields: DeckFieldMap): IParseResult<IParsedCard> {
  const warnings: IParseMessage[] = [];

  const tagName = cardEl.tagName;
  if (tagName !== "card") {
    // biome-ignore lint/style/noUnusedTemplateLiteral: need the `` to preserve tag
    return { val: null, errors: [{ msg: `Expected <card> element.` }] };
  }

  let tags: string[] = [];
  const cardTags = cardEl.getAttribute("tags");
  if (cardTags) {
    tags = decodeURI(cardTags).split(",");
  }
  const fieldValues: Record<string, string> = {};

  const fieldEls = Array.from(cardEl.children);
  for (const fieldEl of fieldEls) {
    const fieldName = fieldEl.getAttribute("name");
    if (!fieldName) {
      warnings.push({ msg: `Nameless field detected <${fieldEl}>.` });
      continue;
    }

    const field = fields[fieldName];
    if (!field) {
      warnings.push({ msg: `Unexpected field encountered <${fieldName}>.` });
    }

    const fieldType = fieldTypeMap[field.type];
    if (!fieldType) {
      warnings.push({ msg: `Unexpected field type encountered <${field.type}>.` });
    }

    if (fieldEl.tagName !== fieldType.fmlTag) {
      return {
        val: null,
        errors: [{ msg: `Expected <${fieldType.fmlTag}> tag; got <${fieldEl.tagName}> instead.` }],
      };
    }

    // Check that value of field is valid given field type.
    const fieldChildren = Array.from(fieldEl.children);
    switch (fieldType.type) {
      case "audio": {
        if (fieldChildren.length !== 0 || fieldEl.textContent !== "") {
          // biome-ignore lint/style/noUnusedTemplateLiteral: need the `` to preserve tag
          warnings.push({ msg: `Expected <audio> tag to have no children.` });
        }
        fieldEl.removeAttribute("name");
        const value = fieldEl.outerHTML.trim();
        fieldValues[fieldName] = value;
        break;
      }
      case "image": {
        if (fieldChildren.length !== 0 || fieldEl.textContent !== "") {
          // biome-ignore lint/style/noUnusedTemplateLiteral: need the `` to preserve tag
          warnings.push({ msg: `Expected <image> tag to have no children.` });
        }
        fieldEl.removeAttribute("name");
        const value = fieldEl.outerHTML.trim();
        fieldValues[fieldName] = value;
        break;
      }
      default: {
        const value = fieldEl.innerHTML.trim();
        fieldValues[fieldName] = value;
      }
    }
  }

  return { val: { values: fieldValues, tags }, warnings };
}
