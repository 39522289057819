import React from "react";
import { IProduct } from "unlimited/types";
import CTAButton from "./ctaButton";
import Product from "./product";

interface IProductProps<T extends IProduct> {
  product: T;
  loading: boolean;
  onPurchase: (product: T) => void;
}

type IProductButtonProps<T extends IProduct> = IProductProps<T>;
export default function ProductButton<T extends IProduct>(props: IProductButtonProps<T>) {
  const { product, onPurchase, loading } = props;

  const onClick = () => onPurchase(product);

  return (
    <div style={{ marginBottom: 18 }}>
      <CTAButton disabled={loading} onClick={onClick}>
        <Product product={product} />
      </CTAButton>
    </div>
  );
}
