import { IDB_FALSE } from "@data/idb";
import DeckDownloadScreen from "@screens/deckDownload";
import DeckItem from "@screens/decks/deckItem";
import { logSearchEvent } from "analytics/search";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import Network from "network";
import React, { useCallback } from "react";
import { useHistory } from "react-router";
import { IDeckSearchResult } from "./useSearchResults";

interface IDeckResultProps {
  deck: IDeckSearchResult;
  sources: any;
  query: string;
}
export default function DeckResult(props: IDeckResultProps): JSX.Element {
  const { deck, sources, query } = props;

  const history = useHistory();
  const [presentDownloadDeckModal] = useIonCardModalWithDismiss(DeckDownloadScreen, {
    deck: deck,
    deckID: deck.id,
    history: history,
    searchQuery: query,
  });

  const handleClick = useCallback(() => {
    logSearchEvent("clicked_result", {
      query,
      deck_id: deck.id,
    });
    presentDownloadDeckModal();
  }, [presentDownloadDeckModal, props.query]);

  return (
    <DeckItem
      key={deck.source + deck.id}
      onClick={handleClick}
      type="deck"
      local={IDB_FALSE}
      name={deck.name}
      numKnols={deck.num_cards}
      leftIcon={
        <img
          style={{
            flex: "0 0 auto",
            height: "48px",
            alignSelf: "center",
          }}
          src={Network.endpoint + sources[deck.source!].icon_uri}
          alt={sources[deck.source!].attribution}
        />
      }
      hideModifiedAt
      hideStatusIcon
      hideTimestamp
      hideGrade
    />
  );
}
