import React from "react";
import { FieldType, fieldTypeMap } from "fields/fields";
import { defaultFieldType } from "fields/lib";
import { IonButton } from "@ionic/react";

interface IProps {
  type: FieldType;
  onClick?: React.MouseEventHandler<HTMLIonButtonElement>;
  small?: boolean;
}
export default function FieldTypeIcon({ type, onClick, small }: IProps) {
  const marginRight = small ? 6 : 20;
  const sizePx = small ? 14 : 28;
  const fieldType = fieldTypeMap[type] ?? fieldTypeMap[defaultFieldType];
  const icon = fieldType.icon;
  return (
    <IonButton
      slot="start" // NOTE: necessary for horizontal scroll of rich text toolbar.
      className="item icon"
      tabIndex={-1}
      color="medium"
      fill="clear"
      style={{
        margin: "4px 0px",
        minHeight: sizePx,
        minWidth: sizePx,
        flex: "0 0 auto",

        // Match the style of <IonIcon>.
        "--padding-start": 0,
        "--padding-end": 0,
        marginRight,
      }}
      onMouseDown={onClick} // NOTE: must do onMouseDown to prevent IonNavLink from responding too.
    >
      {React.createElement(icon, {
        className: "ion-color ion-color-medium",
        style: { height: sizePx, width: sizePx },
      })}
    </IonButton>
  );
}
