import { Capacitor } from "@capacitor/core";
import { Directory, Filesystem } from "@capacitor/filesystem";
import UpgradeBanner from "@components/banner/upgradeBanner";
import LoadingIndicator from "@components/loadingIndicator";
import { showPrompt } from "@components/prompt";
import BlobStore from "@data/idb/blobStore";
import { IonRefresher, IonRefresherContent, RefresherEventDetail } from "@ionic/react";
import { Deck, dummyDeck, exportDeckToFMLZip } from "@models/deck";
import { trimFolderPrefix } from "@models/folder";
import { Inbox } from "@models/inbox";
import { FileOpener } from "com.ankiapp.client.plugin.file-opener";
import ScreenComponent from "components/screen";
import DeckDownloadManager from "deckDownloadManager";
import { isLegacyDeck } from "fields/magicLayout";
import { useLens } from "hooks/data/useLens";
import useErrorAlert from "hooks/util/useErrorAlert";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { BaseSyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { ID } from "types/ID";
import EmptyDeck from "./emptyDeck";
import GenCardsModal from "./genCardsModal";
import useRefreshCounter from "./hooks/useRefreshCounter";
import useUpdateModifiedKnols from "./hooks/useUpdateModifiedKnols";
import DeckScreenInfoSection from "./info";
import Menu from "./menu";

interface IProps {
  deckID: ID;
}

export default function DeckScreen(props: IProps) {
  const cacheSpec = useMemo(() => ({ deckID: props.deckID }), [props.deckID]);
  const [displayCount, forceRefresh] = useRefreshCounter();
  const [showError] = useErrorAlert({ code: "EXPORTING_DECK" });

  const { decks, knols, filteredKnols, knolsUpdatedAt, loading } = useLens(cacheSpec);
  const deck = decks.get(props.deckID);

  useUpdateModifiedKnols(deck, knols);

  const [presentGenCardsModal] = useIonCardModalWithDismiss(GenCardsModal, {
    deck: deck ?? dummyDeck,
    knols: knols,
    onDismiss: forceRefresh,
    conditionalDismiss: true,
  });
  useEffect(() => {
    if (Inbox.isItemQueuedForGeneration()) {
      presentGenCardsModal();
    }
  }, [presentGenCardsModal]);

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    forceRefresh();
    setTimeout(() => {
      event.detail.complete();
      BlobStore.downloadQueuedBlobs(props.deckID).catch(() => null);
    }, 1000);
  }

  const [isArchiving, setIsArchiving] = React.useState(false);
  function handleArchiveDeck() {
    if (!deck) {
      return;
    }
    setIsArchiving(true);
    Deck.archive(deck.id)
      .then(() => {
        DeckDownloadManager.cancelDownload(deck.id);
        history.back();
      })
      .catch(() => {
        // Uh oh.
      })
      .finally(() => {
        setIsArchiving(false);
      });
  }

  const [showResetError] = useErrorAlert({ code: "RESETTING_GRADES" });
  const [resetting, setResetting] = useState(false);
  const handleResetGrades = useCallback(() => {
    if (!deck?.id) {
      return;
    }
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.deck.confirmResetGrades),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.deck.resetGrades).toLocaleUpperCase(),
      callback: async () => {
        setResetting(true);
        Deck.execResetGradesOperation(deck.id)
          .catch((err) => {
            showResetError(err);
          })
          .finally(() => {
            setResetting(false);
          });
      },
    });
  }, [deck?.id, showResetError]);

  const handleExport = async (e: BaseSyntheticEvent<MouseEvent>) => {
    if (!deck || !knols) {
      return;
    }
    const zipFile = await exportDeckToFMLZip(deck, knols.values());
    if (Capacitor.isNativePlatform()) {
      const name = Deck.nameForExport(deck);
      const fileName = `${name}.zip`;
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Data = reader.result;
        if (base64Data && typeof base64Data === "string") {
          const fileResult = await Filesystem.writeFile({
            path: fileName,
            data: base64Data,
            directory: Directory.Documents,
            recursive: true,
          });
          await FileOpener.open({
            filePath: fileResult.uri,
            contentType: "application/zip",
            openWithDefault: false,
          });
        } else {
          showError(null);
        }
      };
      reader.readAsDataURL(zipFile);
    } else {
      // Web platform
      const a = document.createElement("a");
      const blob = new Blob([zipFile], { type: "application/zip" });
      const url = URL.createObjectURL(blob);
      a.setAttribute("href", url);
      a.setAttribute("download", deck.name ?? "");
      a.dispatchEvent(e.nativeEvent);
    }
  };

  const legacy = isLegacyDeck(deck?.layouts);

  const handleResetFilters = useCallback(() => {
    if (!deck) {
      return;
    }
    Deck.setSettings(deck, { reviewTags: [], reviewGrades: [] });
  }, [deck]);

  // Handle case where deck has "F::D" style folder prefix on the deck name.
  const displayName = trimFolderPrefix(deck?.tags?.[0] ?? "", deck?.name ?? "");

  const isEditable = deck?.status === Deck.STATUS_PRIVATE;
  const isEmpty = !loading && knols.size < 1;
  return (
    <ScreenComponent
      headerBanner={<UpgradeBanner narrow />}
      content={
        loading ? null : isEmpty && isEditable && deck ? (
          <EmptyDeck deck={deck} knols={knols} />
        ) : (
          <>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <DeckScreenInfoSection
              deck={deck}
              isDownloading={false}
              loading={loading}
              knols={knols}
              filteredKnols={filteredKnols}
              handleDismissCardCreateModal={forceRefresh}
              displayCount={displayCount}
              knolsLastUpdated={knolsUpdatedAt}
            />
            {resetting ? <LoadingIndicator eventName="knolGradeReset" /> : undefined}
          </>
        )
      }
      slug="Deck"
      defaultBackLink="/"
      title={displayName}
      backText={L10n.localize((s) => s.deck.plural)}
      rightButton={
        deck && (
          <Menu
            deck={deck}
            knols={knols}
            handleExport={handleExport}
            isLegacyDeck={legacy}
            isEditable={isEditable}
            handleArchiveDeck={handleArchiveDeck}
            handleResetGrades={handleResetGrades}
            onDismissModal={forceRefresh}
            onResetFilters={handleResetFilters}
            presentGenCardsModal={presentGenCardsModal}
          />
        )
      }
      helpQuery={L10n.localize((s) => s.deck.singular)}
      disabled={isArchiving}
    />
  );
}
