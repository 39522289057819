import { Histogram, gradesByIncreasingPriority } from "./gradeHistogram";

export function randomGradeCounts(handSize: number, probBounds: Histogram): Histogram {
  const randomGrade = (r: number) => {
    for (const grade of gradesByIncreasingPriority) {
      if (r < probBounds[grade]) {
        return grade;
      }
    }
  };

  // Initialize counts to 0.
  const counts = {} as Histogram;
  for (const grade of gradesByIncreasingPriority) {
    counts[grade] = 0;
  }

  // Draw randomly.
  for (let i = 0; i < handSize; i++) {
    const grade = randomGrade(Math.random());
    if (grade) {
      counts[grade] += 1;
    }
  }
  return counts;
}
