import FooterButton from "@components/footerButton";
import L10n from "localization";
import React from "react";

interface IProps {
  paused: boolean;
  onPause: () => void;
  onResume: () => void;
  isTheaterMode?: boolean;
}

export default function PauseResumeButton({
  paused,
  onPause,
  onResume,
  isTheaterMode,
}: IProps): JSX.Element {
  // HACK: this is necessary to make the contained FooterButtons vertically
  // expand to full height, in Safari.
  const containerStyle: React.CSSProperties = {
    alignContent: "stretch",
    display: "flex",
    flexDirection: "row",
  };

  const borderTopSize = 2;

  return (
    <div style={containerStyle}>
      {paused ? (
        <FooterButton
          onClick={onResume}
          borderTopSize={borderTopSize}
          isTheaterMode={isTheaterMode}
        >
          {L10n.localize((s) => s.actions.resume)}
        </FooterButton>
      ) : (
        <FooterButton onClick={onPause} borderTopSize={borderTopSize} isTheaterMode={isTheaterMode}>
          {L10n.localize((s) => s.actions.pause)}
        </FooterButton>
      )}
    </div>
  );
}
