import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonMenuButton,
  IonNote,
  IonTitle,
  IonToggle,
  IonToolbar,
  ToggleCustomEvent,
} from "@ionic/react";
import flags, { useFlagAndContext } from "featureFlags";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import Network from "network";
import React, { useCallback } from "react";

export default function BetaScreen(): JSX.Element {
  const betaFlag = useFlagAndContext("beta_channel");
  const isBetaEnabled = betaFlag?.flag?.has_opted_in;

  const [showError] = useErrorAlert({ code: "TOGGLING_BETA" });
  const toggleBeta = useCallback(
    async (optIn: boolean) => {
      try {
        await Network.fetch("POST", "/users/beta", { is_opted_in: optIn });
        await flags.updateFlagCache();
      } catch (err) {
        showError(err);
      }
    },
    [showError],
  );

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.general.beta)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonList inset>
          <IonItem>
            <IonToggle
              checked={isBetaEnabled}
              onIonChange={(e: ToggleCustomEvent) => toggleBeta(!isBetaEnabled)}
            >
              <span className="ion-text-wrap">{L10n.localize((s) => s.beta.enable)}</span>
            </IonToggle>
          </IonItem>
        </IonList>
        <IonNote>
          <p className="ion-margin-horizontal ion-text-wrap">
            {L10n.localize((s) => s.beta.description)}
          </p>
        </IonNote>
      </IonContent>
    </>
  );
}
