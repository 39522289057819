export function debounce<T extends (...args: any[]) => any>(
  func: T,
  millis: number,
): [(...args: Parameters<T>) => void, () => void] {
  let timeoutID: string | number | NodeJS.Timeout;

  const cancel = () => {
    clearTimeout(timeoutID);
  };

  const debounced = (...args: Parameters<T>): void => {
    if (timeoutID !== null) {
      clearTimeout(timeoutID);
    }

    timeoutID = setTimeout(() => func(...args), millis);
  };

  return [debounced, cancel];
}
