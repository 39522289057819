import {
  InputCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonInput,
  IonItem,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import * as NameStore from "@data/stores/name";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import Network from "network";
import React from "react";

export function useNamePrompt({
  canCancel,
  onDismiss,
}: {
  canCancel: boolean;
  onDismiss?: () => void;
}) {
  const [present] = useIonCardModalWithDismiss(NamePromptModal, {
    canCancel,
    onDismiss,
  });

  return present;
}

interface IProps {
  dismiss: () => void;
  canCancel: boolean;
}

export default function NamePromptModal(props: IProps): JSX.Element {
  const { dismiss, canCancel } = props;

  const [firstName, setFirstName] = React.useState<string>();
  const [lastName, setLastName] = React.useState<string>();
  React.useEffect(() => {
    NameStore.getName().then((name) => {
      setFirstName(name.first);
      setLastName(name.last);
    });
  }, []);

  const saveDisabled = (firstName?.trim() ?? "").length < 1;

  async function handleSave() {
    if (saveDisabled) {
      return;
    }

    const first = firstName === "" ? undefined : firstName;
    const last = lastName === "" ? undefined : lastName;

    try {
      await Network.fetch("POST", "/users/account/name", {
        name_first: first,
        name_last: last,
      });
      NameStore.setName({ first, last });
    } catch {
      alert(">_<");
    }

    dismiss();
  }

  const listStyle = { margin: 16, borderRadius: 12, paddingBottom: 8 } as React.CSSProperties;

  return (
    <IonPage>
      <IonToolbar>
        <IonTitle>{L10n.localize((s) => s.general.name)}</IonTitle>
        {canCancel && (
          <IonButtons slot="secondary">
            <IonButton onClick={dismiss}>{L10n.localize((s) => s.actions.cancel)}</IonButton>
          </IonButtons>
        )}
        <IonButtons slot="primary">
          <IonButton disabled={saveDisabled} onClick={handleSave}>
            {L10n.localize((s) => s.actions.save)}
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent
        style={
          {
            marginBottom: "env(safe-area-inset-bottom)",
            "--background": "var(--ion-color-light)",
            paddingTop: 32,
          } as React.CSSProperties & { "--background": string }
        }
      >
        <IonList style={listStyle}>
          <IonItem>
            <IonInput
              value={firstName}
              label={L10n.localize((s) => s.general.firstName)}
              autocomplete="given-name"
              autoCapitalize="words"
              onIonInput={(e: InputCustomEvent) => setFirstName(e.detail.value ?? undefined)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonInput
              label={L10n.localize((s) => s.general.lastName)}
              value={lastName}
              autocomplete="family-name"
              autoCapitalize="words"
              placeholder={L10n.localize((s) => s.general.optional)}
              onIonInput={(e: InputCustomEvent) => setLastName(e.detail.value ?? undefined)}
            />
          </IonItem>
        </IonList>
        <div style={{ marginLeft: 32, fontSize: 14 }}>
          {L10n.localize((s) => s.groups.namePromptInstruction)}
        </div>
      </IonContent>
    </IonPage>
  );
}
