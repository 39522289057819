import { showPrompt } from "@components/prompt";
import ScreenComponent from "@components/screen";
import { IonButton, IonLoading, IonNote, IonSpinner } from "@ionic/react";
import { Deck, IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { logSearchEvent } from "analytics/search";
import deckDownloadManager from "deckDownloadManager";
import { History } from "history";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import Network from "network";
import React from "react";
import { ID } from "types/ID";
import Content from "./deckDownload/content";
import useIsLocalDeck from "./deckDownload/useIsLocalDeck";

interface IProps {
  deck?: IDeck;
  deckID: ID;
  dismiss: () => void;
  history: History;
  searchQuery?: string;
}

export interface IDeckResponse extends Omit<IDeck, "created_at" | "modified_at"> {
  source?: string;
  num_knols?: number;
  created_at?: string;
  modified_at?: string;
  knols?: IKnol[];
}

const numDeckDownloadPreviews = 10;

export default function DeckDownloadScreen(props: IProps): JSX.Element {
  const { deckID, dismiss, history } = props;

  const aborterRef = React.useRef(new AbortController());

  const [deck, setDeck] = React.useState<IDeckResponse | undefined>(
    props.deck
      ? {
          ...props.deck,
          created_at: props.deck.created_at?.toString(),
          modified_at: props.deck.modified_at?.toString(),
        }
      : undefined,
  );

  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isDownloading, setIsDownloading] = React.useState(false);

  const [showDeleteError] = useErrorAlert({ code: "DELETING_DECK" });
  const [showNetworkError] = useErrorAlert({
    code: "NETWORK_ERROR",
    message: L10n.localize((s) => s.error.communication),
  });

  React.useEffect(() => {
    setIsDownloading(true);
    async function fetchDownloadData(deckID: ID) {
      const uri = `/decks/${deckID}?num_knols=${numDeckDownloadPreviews}`;
      const deck = await Network.fetch<IDeckResponse>("GET", uri, undefined, aborterRef.current);
      setDeck(deck);
      setIsDownloading(false);
    }
    fetchDownloadData(deckID).catch((err) => {
      setIsDownloading(false);
      showNetworkError(err);
    });
    if (props.searchQuery) {
      logSearchEvent("downloaded_result", {
        query: props.searchQuery,
        deck_id: deckID,
      });
    }

    return () => {
      aborterRef.current.abort();
    };
  }, [deckID]);

  async function handleDelete() {
    if (!deck || !deck.status || deck.status !== Deck.STATUS_PRIVATE) {
      return;
    }

    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.deck.confirmRemovalDangerous),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.delete).toLocaleUpperCase(),
      callback: async () => {
        setIsDeleting(true);
        deckDownloadManager.cancelDownload(deckID);
        try {
          await Deck.PermanentlyDeleteDeck(deck.id);
          handleDismiss();
        } catch (err) {
          showDeleteError(err);
          setIsDeleting(false);
        }
      },
    });
  }

  function handleDownload(): void {
    setIsDownloading(true);
    Deck.download(deckID)
      .then(() => {
        history.push(`/decks/local/${deckID}`);
        dismiss();
      })
      .catch((err) => {
        showPrompt({
          title: L10n.localize((s) => s.general.attention),
          prompt: L10n.localize((s) => s.error.communication),
          promptType: "alert",
        });
      })
      .finally(() => {
        setIsDownloading(false);
      });
  }

  const handleDismiss = () => {
    aborterRef.current.abort();
    dismiss();
  };

  const disabled = isDeleting; // TODO expand to include loading, etc...
  const isLocal = useIsLocalDeck(deckID);
  return (
    <ScreenComponent
      slug="Download Deck"
      noBigTitle
      onBack={handleDismiss}
      leftButton={
        <IonButton onClick={handleDismiss}>{L10n.localize((s) => s.actions.close)}</IonButton>
      }
      rightButton={
        isLocal.val ? (
          <IonButton
            disabled={disabled}
            onClick={() => {
              history.replace(`/decks/local/${deckID}`);
              dismiss();
            }}
          >
            {L10n.localize((s) => s.actions.open)}
          </IonButton>
        ) : isLocal.loading || isDownloading ? (
          <IonNote slot="end">
            <IonSpinner name="crescent" />
          </IonNote>
        ) : (
          <IonButton disabled={disabled} onClick={handleDownload}>
            {L10n.localize((s) => s.actions.download)}
          </IonButton>
        )
      }
      content={
        <>
          <IonLoading spinner="crescent" translucent isOpen={isDeleting} />
          <Content
            isDownloading={isDownloading}
            handleDelete={handleDelete}
            isDeleting={isDeleting}
            deck={deck}
          />
        </>
      }
    />
  );
}
