// UnknownBlob represents a blob for which the ID is known, but not the type (yet).

import { ITypedBlobURL } from "@data/idb";
import BlobStore from "@data/idb/blobStore";
import { useAsyncValState } from "hooks/util/useAsyncValState";
import React from "react";
import { ID } from "types/ID";
import LoadingBlob from "./loadingBlob";
import MissingBlob from "./missingBlob";
import TypedBlob from "./typedBlob";

interface IBlobOptions {
  id: ID;
  disableAudioPlayback: boolean;
  disableImageZoom: boolean;
  persistDownloadedBlobs: boolean;
  preventAudioOrchestration: boolean;
  deckID?: ID;
}

export default function UnknownBlob(props: IBlobOptions): JSX.Element | null {
  const {
    id,
    disableAudioPlayback,
    disableImageZoom,
    persistDownloadedBlobs,
    deckID,
    preventAudioOrchestration,
  } = props;

  const [blobInfoAsyncVal, setBlobInfo, setErr] = useAsyncValState<ITypedBlobURL>();

  React.useEffect(() => {
    BlobStore.getBlobURL({ id, persistDownloadedBlob: persistDownloadedBlobs, deckID })
      .then((result) => {
        if (result) {
          const { url, type } = result;
          setBlobInfo({ url, type });
        } else {
          setErr(new Error("blob not found"));
        }
      })
      .catch((err) => {
        setErr(err);
      });

    return () => {
      BlobStore.releaseBlobURL(id);
    };
  }, [id, setBlobInfo, setErr, persistDownloadedBlobs, deckID]);

  if (blobInfoAsyncVal.loading) {
    return <LoadingBlob />;
  }

  if (blobInfoAsyncVal.error) {
    return <MissingBlob blobID={id} />;
  }

  return (
    <TypedBlob
      id={id}
      type={blobInfoAsyncVal.val?.type}
      url={blobInfoAsyncVal.val?.url}
      disableAudioPlayback={disableAudioPlayback}
      disableImageZoom={disableImageZoom}
      preventAudioOrchestration={preventAudioOrchestration}
    />
  );
}
