import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonProgressBar,
} from "@ionic/react";
import { IDeck } from "@models/deck";
import Empty from "components/empty";
import ScreenComponent from "components/screen";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React from "react";
import Style from "style";
import LayoutEditScreen from "./layoutEdit";

interface IFieldProps {
  name: string;
  trailingComma?: boolean;
}
function Field({ name, trailingComma = true }: IFieldProps) {
  const spanStyle = {
    color: Style.colors.secondaryFg,
    marginRight: "0.3rem",
  };
  let text: string = name;
  if (trailingComma) {
    text += ",";
  }

  return <span style={spanStyle}>{text}</span>;
}

interface ILayoutItemProps {
  id: string;
  deck: IDeck;
  name: string;
}
function LayoutItem({ id, name, deck }: ILayoutItemProps) {
  const [presentLayoutEditModal] = useIonCardModalWithDismiss(LayoutEditScreen, {
    deck: deck,
    layoutID: id,
    conditionalDismiss: true,
  });

  const keys = deck.layouts?.find((layout) => layout.id === id)?.knol_keys || [];

  return (
    <IonCard button onClick={presentLayoutEditModal}>
      <IonCardHeader>
        <IonCardTitle>{name}</IonCardTitle>
      </IonCardHeader>
      <IonCardContent>
        {keys.map((k: string, idx: number) => {
          return <Field key={k} name={k} trailingComma={idx !== keys.length - 1} />;
        })}
      </IonCardContent>
    </IonCard>
  );
}

interface ILayoutListProps {
  deck: IDeck;
}
function LayoutList({ deck }: ILayoutListProps) {
  if (!deck || !deck.layouts || deck.layouts.length < 1) {
    return <Empty text={L10n.localize((s) => s.layout.none)} />;
  }
  const layouts = deck.layouts;
  return (
    <>
      {!layouts && <IonProgressBar type="indeterminate" />}
      <IonList>
        {layouts.map((layout) => {
          return <LayoutItem key={layout.id} id={layout.id} name={layout.name} deck={deck} />;
        })}
      </IonList>
    </>
  );
}

export default function DeckLayoutsScreen(props: {
  dismiss: () => void;
  deck: IDeck;
}): React.ReactElement {
  const { dismiss, deck } = props;

  const [presentLayoutEditModal] = useIonCardModalWithDismiss(LayoutEditScreen, {
    deck: deck,
    conditionalDismiss: true,
  });

  return (
    <ScreenComponent
      title={L10n.localize((s) => s.layout.plural)}
      helpQuery="layout"
      leftButton={<IonButton onClick={dismiss}>{L10n.localize((s) => s.actions.close)}</IonButton>}
      content={<LayoutList deck={deck} />}
      rightButton={
        <IonButton onClick={presentLayoutEditModal}>
          {L10n.localize((s) => s.actions.new)}
        </IonButton>
      }
    />
  );
}
