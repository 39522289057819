import TipDot from "@components/tipDot";
import { IInboxItem } from "@data/idb";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
  SearchbarCustomEvent,
} from "@ionic/react";
import { Inbox } from "@models/inbox";
import DeckListItem from "@screens/decks/deckListItem";
import { deckListRowMatchesQuery } from "@screens/search/deckResults";
import { History } from "history";
import { useDeckList } from "hooks/data/deckList";
import { ionicMode } from "ionicApp";
import L10n from "localization";
import React, { useCallback, useState } from "react";

interface IProps {
  item: IInboxItem;
  dismiss: () => Promise<void>;
  presentNewDeckModal: (opts?: {
    onDidDismiss?: (ev: { detail: HTMLIonModalElementEventMap["didDismiss"] }) => void;
  }) => void;
  history: History;
}

export default function InboxDeckSelectModal(props: IProps) {
  const [query, setQuery] = useState<string>();
  const { rows } = useDeckList();
  const matchingRows =
    query !== undefined ? rows.filter((r) => deckListRowMatchesQuery(r, query)) : rows;
  const sortedRows = matchingRows.sort((r1, r2) => {
    if (r1.type === "deck" && r2.type === "deck") {
      return r2.deck.modified_at.getTime() - r1.deck.modified_at.getTime();
    }
    // This should never happen.
    return 0;
  });

  const handleNewDeck = useCallback(async () => {
    await props.dismiss();
    Inbox.queueItemForGeneration(props.item);
    props.presentNewDeckModal({
      onDidDismiss: (ev: { detail: HTMLIonModalElementEventMap["didDismiss"] }): void => {
        // HACK: after the new deck modal dismisses, it then navigates to the
        // deck screen, if it saved. If it canceled, it would remain here.
        // So wait a split second to see if we ended up on the deck screen.
        const canceled = ev.detail?.role !== "save";
        if (canceled) {
          Inbox.clearItemQueuedForGeneration();
        }
      },
    });
  }, [props.presentNewDeckModal]);

  const handleDeckSelected = useCallback(async () => {
    Inbox.queueItemForGeneration(props.item);
    await props.dismiss();
  }, [props.item]);

  return (
    <IonPage style={{ marginBottom: "env(safe-area-inset-bottom)" }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.deck.choose)}</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={props.dismiss}>{L10n.localize((s) => s.actions.close)}</IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            value={query}
            onIonInput={(e: SearchbarCustomEvent) => {
              const newVal = e.detail.value;
              if (newVal !== undefined && newVal !== null) {
                setQuery(newVal);
              }
            }}
            placeholder={L10n.localize((s) => s.actions.search)}
            style={
              {
                "--box-shadow": ionicMode === "md" ? "none" : undefined,
              } as React.CSSProperties & { "--box-shadow": string }
            }
          />
        </IonToolbar>
      </IonHeader>
      <IonContent color="light">
        <IonList>
          <IonItem button onClick={handleNewDeck}>
            {L10n.localize((s) => s.deck.new)}
          </IonItem>
          {sortedRows.map((r) => {
            if (r.type !== "deck") {
              return null;
            }
            return (
              <DeckListItem
                key={r.deck.id}
                row={r}
                history={props.history}
                onClick={handleDeckSelected}
                inlineDownload
              />
            );
          })}
        </IonList>
        <TipDot
          dotID="inbox_deck_select_modal"
          message={L10n.localize((s) => s.inbox.deckSelectModalTip)}
          hiddenStyle={{ width: "100%" }}
        />
      </IonContent>
    </IonPage>
  );
}
