import { fieldTypeMap } from "fields/fields";
import { IFieldValueProps } from "fields/fieldType";
import { IFieldImage, sampleImageID, sampleImageURL } from "fields/types/image";
import useAsyncBlobVal from "hooks/util/useAsyncBlobVal";
import React from "react";

export default function ImageValue({
  value,
  blobIdToObjectUrl,
}: IFieldValueProps<IFieldImage>): JSX.Element {
  const parsedVal = fieldTypeMap.image.loadFML(value);
  const id = parsedVal?.id;
  const blobVal = useAsyncBlobVal(id, id === sampleImageID, sampleImageURL, blobIdToObjectUrl);

  return <img src={blobVal?.url} />;
}
