// StandardValue is the pre-Typed Fields rendering path.

import type { ITypedBlobURL } from "@data/idb";
import ImageBlob from "cardRendering/imageBlob";
import TexWrapper from "cardRendering/texWrapper";
import React from "react";
import type { ID } from "types/ID";
import Replate, { type IBodyCompiler, type INodeCompiler } from "vendor/replate";
import MissingBlob from "./missingBlob";
import TypedBlob from "./typedBlob";
import UnknownBlob from "./unknownBlob";
import { BLOB_TYPE_ERROR_LOADING, type IValueOptions } from "./value";

function escapeRegExp(s: string) {
  return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export default function StandardValue(
  value: string,
  options: IValueOptions,
  blobIdToObjectUrl?: Record<ID, ITypedBlobURL>,
) {
  const {
    disableTouchEvents = false,
    disableAudioPlayback = false,
    persistDownloadedBlobs = true,
    preventAudioOrchestration = false,
    deckID,
    query,
  } = options;

  // This is used to append a value to a blobId to make it unique within this
  // Value, so that the suffixed value can safely be used as a React key. This
  // only matters for the edge case of multiple {{blob X}} interpolations with
  // the same X, within this Value, which will probably never happen.
  const blobIdKeySuffixes: Record<string, number> = {};

  const queryTransformer: IBodyCompiler = {
    regex: new RegExp(`^${escapeRegExp(query ?? "")}`, "i"),
    compile(match, recursiveCompile, keyGen) {
      return function QueryTransformerComponent(context) {
        // NOTE: query may be lowercase, so we replace with the matched text itself.
        const replacement = match[0];
        return (
          <span key={keyGen()} className="__match__">
            {replacement}
          </span>
        );
      };
    },
  };

  const blobTransformer: IBodyCompiler = {
    regex: /^{{blob (.+?)}}/,
    compile(match, recursiveCompile, keyGen) {
      const blobId = match[1];

      return function BlobTransformerComponent(context) {
        // NOTE: this suffixing is done so that any loading placeholder will
        // be matched with this final ImageBlob, even if multiple ImageBlob's
        // have the same blobId (shouldn't happen).
        const idSuffix = blobIdKeySuffixes[blobId] || 0;
        blobIdKeySuffixes[blobId] = idSuffix + 1;
        const key = blobId + idSuffix;

        // If the blob is already loaded, render "synchronously"...
        const typedURL = blobIdToObjectUrl?.[blobId];

        if (typedURL?.type === BLOB_TYPE_ERROR_LOADING) {
          return <MissingBlob key={key} blobID={blobId} />;
        }

        if (typedURL) {
          return (
            <TypedBlob
              key={key}
              id={blobId}
              type={typedURL.type}
              url={typedURL.url}
              disableAudioPlayback={disableAudioPlayback}
              disableImageZoom={disableTouchEvents}
              preventAudioOrchestration={preventAudioOrchestration}
            />
          );
        }

        // ...otherwise, load the blob on-demand, then render.
        return (
          <UnknownBlob
            key={key}
            id={blobId}
            disableAudioPlayback={disableAudioPlayback}
            disableImageZoom={disableTouchEvents}
            persistDownloadedBlobs={persistDownloadedBlobs}
            preventAudioOrchestration={preventAudioOrchestration}
            deckID={deckID}
          />
        );
      };
    },
  };

  const loadingImageBlobTransformer: INodeCompiler = {
    match(nodeName, attrs) {
      return nodeName === "img" && attrs["data-blob-loading"] === "true" && attrs["data-blob-id"];
    },
    compile(elName, attrs, children, recursiveCompile, keyGen) {
      return function LoadingImageBlobTransformerComponent(context) {
        // NOTE: we render with an ImageBlob (and use the blob ID as key), to
        // re-use the same DOM node when the final image loads.

        const blobId = attrs["data-blob-id"];
        const idSuffix = blobIdKeySuffixes[blobId] || 0;
        blobIdKeySuffixes[blobId] = idSuffix + 1;

        return (
          <ImageBlob
            key={blobId + idSuffix}
            blobId={blobId}
            disableZoom={true}
            loading={true}
            url={attrs.src}
          />
        );
      };
    },
  };

  const texTransformer: INodeCompiler = {
    match(nodeName, attrs) {
      const hasType = attrs["data-type"] === "tex";
      return hasType;
    },
    compile(elName, attrs, children, recursiveCompile, keyGen) {
      return function TexTransformerComponent(context) {
        return <TexWrapper key={keyGen()}>{children(context)}</TexWrapper>;
      };
    },
  };

  const compiler = new Replate({
    body: query ? [blobTransformer, queryTransformer] : [blobTransformer],
    node: [loadingImageBlobTransformer, texTransformer],
  });
  const template = compiler.compile(value);
  return template;
}
