import React from "react";
import { hintClass } from "hooks/util/useHint";
import { backOnlyClass } from "@components/layoutStyle";
import { fieldTypeMap } from "fields/fields";
import { IFieldChinese } from "fields/types/chinese";
import { Tokenize, IToken } from "@vendor/chinese-tokenizer";

interface IProps {
  field: IFieldChinese;
  value: string;
}

export default function ChineseValue({ value, field }: IProps): JSX.Element | null {
  const parsedVal = value !== undefined ? fieldTypeMap.chinese.loadFML(value) : undefined;
  const chinese = parsedVal?.chinese;
  const pinyinMode = field.attributes?.pinyinMode;

  let className = "";

  if (pinyinMode !== "off") {
    if (pinyinMode === "hint") {
      className = hintClass;
    } else if (pinyinMode === "back") {
      className = backOnlyClass;
    }
  }

  const [tokenized, setTokenized] = React.useState<IToken[]>([]);

  React.useEffect(() => {
    if (pinyinMode !== "off" && chinese && chinese !== "") {
      Tokenize(chinese).then((t) => {
        setTokenized(t);
      });
    }
  }, [pinyinMode, chinese]);

  return !chinese ? null : (
    <div style={{ fontSize: field.attributes?.fontSizePx }}>
      {pinyinMode === "off" ? (
        parsedVal.chinese
      ) : (
        <>
          {tokenized.map((t, i) => {
            return (
              <ruby key={i} style={{ WebkitRubyPosition: "after" }}>
                {t.text}
                {t.matches.length > 0 && (
                  <rt className={className} style={{ wordSpacing: "-4px" }}>
                    {t.matches[0].pinyinPretty}
                  </rt>
                )}
              </ruby>
            );
          })}
        </>
      )}
    </div>
  );
}
