import { User } from "@models/user";
import { IDeviceNetworkCredentials } from "blobs/blobNetwork";
import { IServerMessage, handleMsgs, parseHeader } from "msgs";
import Device from "./device";
import Globals from "./globals";
import L10n, { userLocale } from "./localization";

export function getNetworkCredentials(): IDeviceNetworkCredentials {
  return {
    deviceID: Device.getID(),
    deviceToken: Device.getToken(),
  };
}

export class NetworkError extends Error {
  statusCode: number;
  message: string;
  msgs: IServerMessage[];
  headers: any;
  name = "NetworkError";
  constructor(code: number, headers: any, msgs: IServerMessage[], errorMsg = "") {
    super(errorMsg);
    Object.setPrototypeOf(this, NetworkError.prototype);
    this.statusCode = code;
    this.headers = headers;
    this.message = errorMsg;
    this.msgs = msgs;
  }
}

type SupportedActions = "GET" | "POST" | "DELETE" | "PATCH" | "PUT";

const outstandingFetches = new Set<AbortController>();

interface IFetchOptions {
  action: SupportedActions;
  endpoint?: string;
  path: string;
  handle401?: boolean;
  data?: Record<string, any> | FormData | Blob | File | null;
  aborter?: AbortController;
  headers?: HeadersInit;
}

interface IAnkiAppHeaders {
  "Accept-Language"?: string | null;
  "AnkiApp-Client-Version"?: string;
  "AnkiApp-Client-Id"?: string;
  "AnkiApp-Client-Token"?: string | null;
}

// biome-ignore lint/complexity/noStaticOnlyClass: TODO
export default class Network {
  public static endpoint = Globals.apiEndpoint;

  static abortAllFetches(): void {
    for (const aborter of outstandingFetches) {
      aborter?.abort?.();
    }
    outstandingFetches.clear();
  }

  public static async fetch<T>(
    action: SupportedActions,
    path: string,
    data?: Record<string, any>,
    aborter?: AbortController,
    handle401 = true,
  ): Promise<T> {
    const r = await this.fetchWithMetadata<T>({
      action,
      path,
      handle401,
      data,
      aborter,
    });
    return r?.[0];
  }

  // TODO: replace fetch with this, then rename back to fetch. (easier to pass options this way)
  public static async fetchWithOptions<T>(opts: IFetchOptions): Promise<T> {
    const r = await this.fetchWithMetadata<T>(opts);
    return r?.[0];
  }

  public static async fetchWithMetadata<T>({
    action,
    path,
    handle401 = true,
    endpoint = Network.endpoint,
    data,
    aborter,
    headers: extraHeaders,
  }: IFetchOptions): Promise<[T, { status: number; msgs: IServerMessage[]; headers: Headers }]> {
    if (!aborter) {
      aborter = new AbortController();
    }
    outstandingFetches.add(aborter);

    const allHeaders = new Headers(extraHeaders);
    const customHeaders: IAnkiAppHeaders = {
      "Accept-Language": userLocale(),
      "AnkiApp-Client-Version": Globals.version,
      "AnkiApp-Client-Id": Device.getID(),
      "AnkiApp-Client-Token": Device.getToken(),
    };

    for (const [key, value] of Object.entries(customHeaders)) {
      if (value) allHeaders.set(key, value);
    }

    const isFileOrForm =
      data instanceof FormData ||
      data instanceof Blob ||
      data instanceof File ||
      data?.constructor?.name === "File";

    if (action === "POST" && data && !isFileOrForm) {
      allHeaders.set("Content-Type", "application/json");
    } else if ((action === "GET" || action === "DELETE") && data) {
      path +=
        "?" +
        Object.entries(data)
          .map(([k, v]) => `${k}=${v}`)
          .join("&");
      data = null;
    }

    let body: any = null;
    if (isFileOrForm) {
      body = data;
    } else if (data) {
      body = JSON.stringify(data);
    }

    let encounteredErrorResponse = false;

    let response: Response;

    try {
      response = await fetch(`${endpoint}${path}`, {
        method: action,
        headers: allHeaders,
        signal: aborter.signal,
        body,
      });
    } catch (e) {
      // e is AbortError or TypeError
      if (e instanceof Error && e.name === "AbortError") {
        // TODO: figure out the right way to deal with this.
        // Throwing an exception is probably the right way, but
        // don't want to spam error messages when Network.fetch
        // consumers are just being responsible and using an aborter
        // to kill the network request when destructing.
        return [null, null] as any;
      } else if (e instanceof Error) {
        throw new NetworkError(0, null, [], e.message);
      } else {
        throw e;
      }
    }
    outstandingFetches.delete(aborter);
    if (!response.ok) {
      encounteredErrorResponse = true;
    }

    // Handle messages about usage quotas exceeded, etc...
    const msgHeader = response.headers.get("X-AnkiApp-Msg");
    const msgs = parseHeader(msgHeader);
    const isLoginPath = path === "/users/login";
    let shouldHandleMsg = true;
    if (isLoginPath && response.status === 403) {
      shouldHandleMsg = false;
    }
    if (shouldHandleMsg) {
      handleMsgs(msgs);
    }

    if (encounteredErrorResponse) {
      // 401 logout
      if (response.status === 401 && User.isLoggedIn() && handle401) {
        alert(L10n.localize((s) => s.auth.sessionExpired));
        User.logout();
      }

      // 412 client out of date
      if (response.status === 412 && User.isLoggedIn()) {
        alert(L10n.localize((s) => s.general.clientOutdated));
        User.logout();
      }

      const headerContentType = response.headers.get("Content-Type");
      if (headerContentType === "application/json") {
        try {
          const r = await response.json();
          throw new NetworkError(response.status, response.headers, msgs, r.error);
        } catch {
          throw new NetworkError(response.status, response.headers, msgs, undefined);
        }
      } else {
        const r = await response.text();
        throw new NetworkError(response.status, response.headers, msgs, r);
      }
    }

    try {
      const contentType = response.headers.get("Content-Type");
      const isBlob =
        contentType?.startsWith("image/") ||
        contentType?.startsWith("audio/") ||
        contentType?.startsWith("video/");

      if (contentType === "application/json") {
        const r = await response.json();
        return [r, { status: response.status, msgs, headers: response.headers }];
      } else if (isBlob) {
        const r = await response.blob();
        return [r as any, { status: response.status, msgs, headers: response.headers }];
      } else {
        const r = (await response.text()) as any;
        return [r, { status: response.status, msgs, headers: response.headers }];
      }
    } catch (e) {
      // For the case of API returning non-text response or bad content-type
      throw new NetworkError(response.status, response.headers, msgs, "Failed to parse response");
    }
  }
}
