import { IonItem, IonLabel, IonNavLink, IonNote } from "@ionic/react";
import { IField } from "fields/fields";
import L10n from "localization";
import React, { useState } from "react";
import RefFieldNav from "./refFieldNav";
import { fieldMap } from "@models/deck";
import { pipelineIncludes } from "fields/sources/lib";

// A field is a valid ref field for destField if it's source pipeline does not
// include destField. In other words, no circular references are allowed.
export function validRefFields(fields: IField[], destField: string): IField[] {
  const fmap = fieldMap(fields);

  return fields.filter((f) => {
    if (f.name === destField) {
      return false;
    }

    // No circular references.
    if (pipelineIncludes(f.source, destField, fmap)) {
      return false;
    }

    return true;
  });
}

interface IProps {
  fields: IField[];
  field: IField;
  onSelect: (name: string | undefined) => void;
  required?: boolean;
  initRefField?: string;
  disabledFields?: string[];
}
export default function RefFieldSelectorItem({
  fields,
  field,
  onSelect,
  required,
  initRefField,
  disabledFields,
}: IProps) {
  const refFields = validRefFields(fields, field.name);

  const [refField, setRefField] = useState(initRefField);
  function handleSelect(name: string | undefined) {
    setRefField(name);
    onSelect(name);
  }

  const noneSelected = refField === undefined;

  return (
    <IonNavLink
      routerDirection="forward"
      component={() => (
        <RefFieldNav
          refField={refField}
          onSelect={handleSelect}
          refFields={refFields}
          disabledFields={disabledFields}
        />
      )}
    >
      <IonItem button>
        <IonLabel>{L10n.localize((s) => s.field.translationSource)}</IonLabel>
        <IonNote slot="end" color={noneSelected && required ? "danger" : undefined}>
          {refField ?? L10n.localize((s) => s.general.none)}
        </IonNote>
      </IonItem>
    </IonNavLink>
  );
}
