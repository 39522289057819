export type FML = string;

export function serializeFML({
  tagName,
  attrs = {},
  body,
  requireTag = false,
}: {
  tagName: string;
  attrs?: Record<string, string | undefined>;
  body?: string;
  requireTag?: boolean;
}): string {
  let attrStr = Object.entries(attrs)
    .filter(([key, val]) => val !== undefined)
    .map(([key, val]) => `${key}="${val}"`)
    .join(" ");

  // Put spacing on attrStr itself to avoid unnecessary space in tag when no attrs.
  if (attrStr !== "") {
    attrStr = ` ${attrStr}`;
  }

  if (body !== undefined) {
    const escaped = escapeXML(body);
    if (attrStr || requireTag) {
      return `<${tagName}${attrStr}>${escaped}</${tagName}>`;
    }
    // No attributes. Just dump bare body.
    return escaped;
  }

  return `<${tagName}${attrStr} />`;
}

interface IFMLParseResult {
  attrs: Record<string, string>;
  body: string;
}
export function parseFML({
  tagName,
  attrList = [],
  fml,
  requireTag = false,
}: {
  tagName: string;
  attrList?: string[];
  fml: FML;
  requireTag?: boolean;
}): IFMLParseResult | null {
  const parser = new DOMParser();
  const parsed = parser.parseFromString(fml, "text/html");

  // Only allow multiple nodes within a rich text field.
  const topLevelNodes = Array.from(parsed.body.childNodes);
  if (topLevelNodes.length > 1) {
    if (tagName === "rich-text") {
      return { attrs: {}, body: unescapeXML(parsed.body.innerHTML) };
    }
    return null;
  }

  // Enforce tag requirement (if required).
  const topLevelTags = Array.from(parsed.body.children);
  if (requireTag) {
    if (topLevelTags.length !== 1) {
      return null;
    }
    if (topLevelTags[0].tagName.toLowerCase() !== tagName) {
      return null;
    }
  }

  // Handle mis-matching wrapper tag.
  if (topLevelTags.length === 1 && topLevelTags[0].tagName.toLowerCase() !== tagName) {
    // May be leftover from switching field types. Ignore wrapper.
    return { attrs: {}, body: unescapeXML(topLevelTags[0].innerHTML) };
  }

  // Handle lack of wrapper tag.
  if (topLevelTags.length < 1) {
    return { attrs: {}, body: unescapeXML(parsed.body.innerHTML) };
  }

  // At this point, we've got a wrapper tag and it matches the expected tag name.
  const tag = topLevelTags[0];

  const attrs = {} as Record<string, string>;
  for (const attr of attrList) {
    const val = tag.getAttribute(attr);
    if (val) {
      attrs[attr] = val;
    }
  }

  const body = unescapeXML(tag.innerHTML);

  return { attrs, body };
}

function escapeXML(input: string): string {
  return input.replace(/[<>&'"]/g, (char: string) => {
    switch (char) {
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case "&":
        return "&amp;";
      case "'":
        return "&apos;";
      case '"':
        return "&quot;";
      default:
        return char;
    }
  });
}

function unescapeXML(input: string): string {
  return input.replace(/&lt;|&gt;|&amp;|&apos;|&quot;/g, (entity: string) => {
    switch (entity) {
      case "&lt;":
        return "<";
      case "&gt;":
        return ">";
      case "&amp;":
        return "&";
      case "&apos;":
        return "'";
      case "&quot;":
        return '"';
      default:
        return entity;
    }
  });
}
