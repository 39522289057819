import React from "react";
import Subtitle from "@components/subtitle";
import GradeBubble from "./gradeBubble";
import Tag from "components/tag";
import { Grade } from "@core/grade";
import { sortWithTrailingNumber } from "lib";

export default function FilterBar(props: { tags: string[]; grades: Grade[] }) {
  const { tags, grades } = props;

  if (tags.length < 1 && grades.length < 1) {
    return null;
  }

  const sortedTags = sortWithTrailingNumber(tags);

  const tagEls = sortedTags.map((tag) => <Tag key={`tag-${tag}`} name={tag} />);
  const gradeEls = grades.map((grade) => <GradeBubble key={`grade-${grade}`} grade={grade} />);
  return (
    <Subtitle
      text={gradeEls.concat(tagEls)}
      style={{ overflowX: "auto", textOverflow: "initial" }}
    />
  );
}
