import { showPrompt } from "@components/prompt";
import MapView from "@lib/mapView";
import { Deck } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import deck from "@screens/deck/deck";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import useErrorAlert from "hooks/util/useErrorAlert";
import { checkmarkCircleOutline } from "ionicons/icons";
import L10n from "localization";
import { useCallback } from "react";
import { ID } from "types/ID";

export default function useTagKnols(allKnols: MapView<ID, IKnol>) {
  const suggestions = Deck.knolTags(allKnols.values(), []);
  const [presentToast] = useDismissibleToast();
  const [showError] = useErrorAlert({ code: "TAGGING_KNOL" });
  const deleteKnols = useCallback(
    (knols: IKnol[]) => {
      if (knols.length < 1) {
        return;
      }

      showPrompt({
        promptType: "input",
        title: L10n.localize((s) => s.tag.singular),
        prompt: L10n.localize((s) => s.general.name),
        validationRegex: /.+/,
        suggestions,
        callback: async (response) => {
          const tagName = response?.trim().toLowerCase() ?? "";
          if (tagName === "") {
            return;
          }
          const promises = knols.map((knol) => Knol.AddTag(knol.id, tagName));
          Promise.all(promises)
            .then(() => {
              presentToast({
                message: L10n.localize((s) => s.general.done),
                icon: checkmarkCircleOutline,
                color: "success",
                duration: 1000,
              });
            })
            .catch(showError);
        },
      });
    },
    [deck],
  );
  return deleteKnols;
}
