import { AnnotationMode } from "fields/editors/annotationModeNav";
import FieldEditorChinese from "fields/editors/fieldEditorChinese";
import { IFieldType } from "fields/fieldType";
import { FML, parseFML, serializeFML } from "fields/fml";
import { BFF69 } from "fields/lang";
import { IBaseField, validateTranslationPipelineLang } from "fields/lib";
import { parseSides } from "fields/sides";
import FieldValueEditorChinese from "fields/valueEditors/fieldValueEditorChinese";
import ChineseValue from "fields/values/chineseValue";
import L10n from "localization";
import { IoLanguage } from "react-icons/io5";

export const chineseFieldType: IFieldType<"chinese"> = {
  fmlTag: "chinese",
  type: "chinese",
  name: () => L10n.localize((s) => s.field.typeChinese),
  description: () => L10n.localize((s) => s.field.typeChineseDescription),
  icon: IoLanguage,
  sampleValue() {
    return this.dumpFML({ chinese: "你好，世界！" });
  },
  loadFML(fml: FML) {
    const res = parseFML({ tagName: this.fmlTag, fml });
    if (!res) {
      return null;
    }
    const { body } = res;
    return { chinese: body };
  },

  updateValue(_, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  dumpFML({ chinese }) {
    return serializeFML({ tagName: this.fmlTag, attrs: {}, body: chinese });
  },

  valueComponent: ChineseValue,
  editorComponent: FieldEditorChinese,
  valueEditorComponent: FieldValueEditorChinese,

  convert(field) {
    return { ...field, type: this.type, attributes: undefined };
  },

  string(value) {
    return value.chinese;
  },

  parseField(attrs, srcs) {
    const fontSizePx = parseInt(attrs["fontSizePx"]);

    return {
      name: attrs["name"],
      type: "chinese",
      sides: parseSides(attrs["sides"]),
      source: srcs[0],
      attributes: {
        fontSizePx: isNaN(fontSizePx) ? undefined : fontSizePx,
      },
    };
  },

  valid(field) {
    if (!field.attributes?.lang) {
      return false;
    }
    return validateTranslationPipelineLang(field);
  },
};

export type ChineseLang = "zh-CN" | "zh-TW";
export const chineseLangs: string[] = ["zh-CN", "zh-TW"];

export interface IFieldChinese extends IBaseField {
  type: "chinese";
  attributes?: {
    fontSizePx?: number;
    lang?: BFF69;
    pinyinMode?: AnnotationMode;
  };
}

export interface IChineseFieldValue {
  chinese: string;
}
