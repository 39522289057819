import { IonItem, IonLabel } from "@ionic/react";
import { closeOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";
import SelectionIndicator from "./selectionIndicator";

export default function NoFolderItem({
  onSelect,
  selected,
}: {
  onSelect: () => void;
  selected?: boolean;
}) {
  return (
    <IonItem onClick={() => onSelect()} button detailIcon={closeOutline}>
      <SelectionIndicator selected={selected} />
      <IonLabel color="primary">{L10n.localize((s) => s.general.none)}</IonLabel>
    </IonItem>
  );
}
