import { IDB_FALSE, IDB_TRUE, IndexedDBBoolean } from "@data/idb";
import { Deck, IDeck, IDeckData } from "@models/deck";
import { Operation } from "@models/operation";
import { User } from "@models/user";
import logEvent from "analytics";
import EventBus from "eventBus";
import Network from "network";

const path = "/decks/";

export async function syncOperationsAndRefetchRemoteDecks() {
  if (!User.isLoggedIn()) {
    return;
  }
  await Operation.doSync();
  await fetchAndMigrateRemoteDecks();
}

export function remoteDeckToIDBDeck({
  deck,
  shared = IDB_FALSE,
}: {
  deck: IDeckData;
  shared?: IndexedDBBoolean;
}): IDeck {
  return {
    id: deck.id,
    created_at: new Date(deck.created_at),
    modified_at: new Date(deck.modified_at),
    status: deck.status,
    name: deck.name,
    description: deck.description,
    layout_id: deck.layout_id,
    tags: deck.tags ?? [],
    local: IDB_FALSE,
    shared,
  };
}

// NOTE: this function is intentionally not exported.
// It *must* be called after an operations sync, otherwise the
// migration logic may cause problems when client and server are out of sync.
async function fetchAndMigrateRemoteDecks() {
  const data = await Network.fetch<IRemoteDecksState>("GET", path);
  const loadDeck = async (deck: IDeckData, shared: IndexedDBBoolean = IDB_FALSE) => {
    const newRow = remoteDeckToIDBDeck({ deck, shared });
    return Deck.migrateDeckFromNet(newRow);
  };

  async function loadDecks() {
    let changed = false;
    for (const deck of data?.user ?? []) {
      const deckChanged = await loadDeck(deck);
      changed ||= deckChanged;
    }
    for (const deck of data?.share ?? []) {
      const deckChanged = await loadDeck(deck, IDB_TRUE);
      changed ||= deckChanged;
    }
    for (const deck of data?.subscriptions ?? []) {
      const deckChanged = await loadDeck(deck);
      changed ||= deckChanged;
    }
    return changed;
  }
  loadDecks()
    .then((changed) => {
      EventBus.emit("remoteDecksMigrated");
      if (changed) {
        logEvent("remote_decks_fetched_and_migrated");
      }
    })
    .catch((err) => {
      // Uh oh.
    });
}

export interface IRemoteDecksState {
  share: IDeckData[];
  user: IDeckData[];
  subscriptions: IDeckData[];
}
