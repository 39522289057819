import { IonButton, IonCard, IonCardContent } from "@ionic/react";
import React from "react";
import { LuXCircle } from "react-icons/lu";

interface IProps {
  fileBase64: string | undefined;
  prompt: string | null | undefined;
  clearAttachment?: () => void;
}
export default function PromptCard({ fileBase64, prompt, clearAttachment }: IProps) {
  return (
    <IonCard style={{ width: "calc(100% - 24px)", padding: 8, marginBottom: 6 }}>
      {fileBase64 ? <img src={fileBase64} /> : undefined}

      {prompt ? (
        <IonCardContent style={{ whiteSpace: "pre-wrap", userSelect: "text" }}>
          {prompt}
        </IonCardContent>
      ) : clearAttachment !== undefined ? (
        <IonButton fill="clear" onClick={clearAttachment}>
          <LuXCircle size={24} />
        </IonButton>
      ) : undefined}
    </IonCard>
  );
}
