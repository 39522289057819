import { IonIcon, IonItem, IonItemDivider, IonList, IonNote } from "@ionic/react";
import { BodySection } from "@screens/decks/types";
import { useIsPhone } from "hooks/util/useMediaQuery";
import React from "react";
import Style from "style";

export interface ISection {
  text: string;
  id: BodySection;
  icon: string | JSX.Element | undefined;
  count?: number;
  withoutChevron?: boolean;
}

export default function SideBar(props: {
  sections: ISection[];
  activeSection: string;
  setActiveSection: (cat: BodySection) => void;
}): JSX.Element {
  const { sections, activeSection, setActiveSection } = props;

  const isPhone = useIsPhone();

  return (
    <IonList style={{ width: "100%" }}>
      {sections.map((section, i) => {
        const isActive = section.id === activeSection;
        const isLast = i === sections.length - 1;
        const { icon, count } = section;

        return (
          <IonItem
            key={section.id}
            button
            lines={isLast ? "none" : undefined}
            onClick={() => setActiveSection(section.id)}
            style={{ color: isActive && Style.colors.actionableFg }}
          >
            {icon &&
              (typeof icon === "string" ? (
                <IonIcon
                  slot="start"
                  icon={icon}
                  style={{
                    marginRight: 16,
                    color: isActive && Style.colors.actionableFg,
                  }}
                />
              ) : (
                icon
              ))}
            <h3 style={{ margin: 0 }}>{section.text}</h3>
            {count && (
              <IonNote
                slot="end"
                style={{ color: isActive ? Style.colors.actionableFg : undefined }}
              >
                {count}
              </IonNote>
            )}
          </IonItem>
        );
      })}
      {isPhone && <IonItemDivider style={{ minHeight: 6 }} />}
    </IonList>
  );
}
