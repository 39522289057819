// Starting in v7.0, decks are created without layouts.

import { IDeck, IDeckConfig } from "@models/deck";
import { ILayout, Layout } from "@models/layout";
import Globals from "globals";

// This layout ID indicates the deck is from v7+.
export const MAGIC_LAYOUT_ID = "f".repeat(32);
export const ALL_LAYOUT_ID = "a".repeat(32);

export function magicLayoutFor(config: IDeckConfig) {
  return {
    id: MAGIC_LAYOUT_ID,
    name: "",
    response_type_id: Globals.basicResponseType.id,
    status: Layout.STATUS_PRIVATE,
    style: "",
    templates: [],
    knol_keys: config.fields.map((f) => f.name),
  };
}

export function isModernDeck(deck: IDeck): boolean {
  return !isLegacyDeck(deck.layouts);
}

export function isLegacyDeck(layouts?: ILayout[]): boolean {
  if (!layouts) {
    return false;
  }
  const isModernDeck =
    layouts.length < 1 || (layouts.length === 1 && layouts[0].id === MAGIC_LAYOUT_ID);
  return !isModernDeck;
}

export function useIsLegacyDeck(deck?: IDeck): boolean {
  return isLegacyDeck(deck?.layouts ?? []);
}
