import { DeckFields } from "@models/deck";
import { fieldTypeMap } from "fields/fields";
import { SIDES } from "fields/sides";
import { sampleAudioID, sampleAudioType } from "fields/types/audio";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function listeningAudio(): IMarketItem {
  const listeningAudioFields: DeckFields = [
    { name: fieldNames.Speech(), type: "audio", sides: SIDES.BOTH },
    { name: fieldNames.Text(), type: "text", sides: SIDES.BACK },
  ];

  return {
    base: "audio",
    name: L10n.localize((s) => s.preInstalledLayouts.listeningAudio),
    fields: listeningAudioFields,
    values: {
      [fieldNames.Speech()]: fieldTypeMap.audio.dumpFML({
        id: sampleAudioID,
        type: sampleAudioType,
      }),
      [fieldNames.Text()]: "Für Elise",
    },
    description: L10n.localize((s) => s.field.typeAudioDescription),
  };
}
