import {
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonPage,
  IonRouterOutlet,
  IonSplitPane,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import cyAttr from "@lib/cyAttr";
import L10n from "localization";
import React, { useRef } from "react";
import {
  LuHelpCircle,
  LuInfo,
  LuPhone,
  LuSettings,
  LuSmartphone,
  LuUserCircle2,
} from "react-icons/lu";
import { Route } from "react-router-dom";
import About from "./profile/about";
import Account from "./profile/account";
import BetaScreen from "./profile/beta";
import ContactScreen from "./profile/contact";
import Devices from "./profile/devices";
import HelpScreen from "./profile/help";
import SettingsScreen from "./settings";

const Profile = ({ location }: { location: Location }) => {
  const isActiveRoute = (route: string) => {
    if (route === "/profile/account") {
      return location.pathname === route || location.pathname === "/profile";
    }
    return location.pathname === route;
  };

  const menuItems = [
    {
      path: "/profile",
      icon: <LuUserCircle2 size={24} style={{ marginRight: 12 }} />,
      label: L10n.localize((s) => s.general.account),
    },
    {
      path: "/profile/settings",
      icon: <LuSettings size={24} style={{ marginRight: 12 }} />,
      label: L10n.localize((s) => s.general.settings),
    },
    // {
    //   path: "/profile/beta",
    //   icon: <LuTestTube2 size={24} style={{ marginRight: 12 }} />, // Assuming an icon for beta, replace with appropriate icon
    //   label: L10n.localize((s) => s.general.beta),
    // },
    {
      path: "/profile/devices",
      icon: <LuSmartphone size={24} style={{ marginRight: 12 }} />,
      label: L10n.localize((s) => s.general.devices),
    },
    {
      path: "/profile/help",
      icon: <LuHelpCircle size={24} style={{ marginRight: 12 }} />,
      label: L10n.localize((s) => s.general.help),
    },
    {
      path: "/profile/contact",
      icon: <LuPhone size={24} style={{ marginRight: 12 }} />,
      label: L10n.localize((s) => s.actions.contact),
    },
    {
      path: "/profile/about",
      icon: <LuInfo size={24} style={{ marginRight: 12 }} />,
      label: L10n.localize((s) => s.general.about),
    },
  ];

  const menu = useRef<HTMLIonMenuElement>(null);

  const MenuItem = ({
    path,
    icon,
    label,
  }: { path: string; icon: string | JSX.Element; label: string }) => {
    // Not sure why but I had to do the hide logic in here otherwise the menu wouldn't update when the flags updated.
    // const betaFlag = useFlagAndContext("beta_channel");
    // const isBetaEligible = betaFlag?.flag?.is_eligible;
    // const isBetaItem = path === "/profile/beta";
    // if (isBetaItem && !isBetaEligible) {
    //   return null;
    // }
    // const dotID: DotID = "beta_screen";

    function handleClick() {
      // if (isBetaItem) {
      //   clearDot(dotID);
      // }
      menu.current?.close();
    }

    return (
      <IonItem
        routerLink={path}
        routerDirection="none"
        detail={true}
        color={isActiveRoute(path) ? "light" : ""}
        style={isActiveRoute(path) ? { fontWeight: 600 } : {}}
        onClick={handleClick}
      >
        {/* isBetaItem ? <InfoDot id={dotID} /> : undefined */}
        {typeof icon === "string" ? <IonIcon icon={icon} slot="start" /> : icon}
        <IonLabel>{label}</IonLabel>
      </IonItem>
    );
  };

  const root = (
    <IonPage {...cyAttr("Profile-screen")}>
      <IonRouterOutlet animated={false}>
        <IonSplitPane contentId="profile-content">
          <IonMenu ref={menu} contentId="profile-content" side="start">
            <IonHeader>
              <IonToolbar>
                <IonTitle>{L10n.localize((s) => s.general.profile)} </IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList>
                {menuItems.map((item) => {
                  return (
                    <MenuItem
                      key={item.path}
                      path={item.path}
                      icon={item.icon}
                      label={item.label}
                    />
                  );
                })}
              </IonList>
            </IonContent>
          </IonMenu>
          <IonPage id="profile-content" className="with-tab-bar">
            <Route path="/profile" component={Account} exact />
            <Route path="/profile/account" component={Account} exact />
            <Route path="/profile/settings" component={SettingsScreen} exact />
            <Route path="/profile/beta" component={BetaScreen} exact />
            <Route path="/profile/devices" component={Devices} exact />
            <Route path="/profile/help" component={HelpScreen} exact />
            <Route path="/profile/contact" component={ContactScreen} exact />
            <Route path="/profile/about" component={About} exact />
          </IonPage>
        </IonSplitPane>
      </IonRouterOutlet>
    </IonPage>
  );
  return root;
};
export default Profile;
