import { subFolderMagicSeparator } from "@data/lib/folders";
import React from "react";

export const displayedFolderSep = "›";

export function folderDisplayString(folder: string): string {
  return folder.split(subFolderMagicSeparator).join(displayedFolderSep);
}

export default function FolderWithSubfoldersString({ folder }: { folder: string }) {
  return <>{folderDisplayString(folder)}</>;
}
