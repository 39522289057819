import { useEffect } from "react";

// This is needed to handle the slide transition.
const delayMillis = 250;

export default function useAutoFocusIonTextarea(
  autoFocus: boolean | undefined,
  textarea: HTMLIonTextareaElement | null,
) {
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        textarea?.setFocus();
      }, delayMillis);
    }
  }, [autoFocus, textarea]);
}
