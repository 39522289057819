import logEvent from "analytics";
import EventBus from "eventBus";
import { History } from "history";
import {
  groupJoinTokenStorageKey,
  groupLinkJoinAnalyticsKey,
} from "hooks/util/useGroupJoinRequest";
import React from "react";
import { setSessionStorage } from "storage";

interface IProps {
  history: History;
  token: string;
}

export default function GroupJoinHandler({ history, token }: IProps): null {
  React.useEffect(() => {
    setSessionStorage(groupJoinTokenStorageKey, token)
      .then(() => {
        logEvent(groupLinkJoinAnalyticsKey, { step: "token_stored", token });
        EventBus.emit("groupJoinTokenSet");
      })
      .finally(() => {
        history.replace("/");
      });
  }, [token]);

  return null;
}
