import { IonIcon } from "@ionic/react";
import EventBus from "eventBus";
import { timerOutline } from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import Style from "style";

function Block({ live }: { live: boolean }) {
  return (
    <div
      style={{
        width: 12,
        height: 8,
        display: "block",
        marginTop: 3,
        backgroundColor: live ? Style.colors.actionableFg : "var(--ion-color-light)",
      }}
    />
  );
}

function Blocks({ total, remaining }: { total: number; remaining: number }) {
  const blocks: boolean[] = [];
  for (let i = 0; i < total; i++) {
    const live = i < remaining;
    blocks.push(live);
  }

  return (
    <div>
      {blocks.map((live, i) => (
        <Block key={i} live={live} />
      ))}
    </div>
  );
}

interface IProps {
  isTheaterMode?: boolean;
}
export default function TimerHUD({ isTheaterMode }: IProps): JSX.Element {
  const [state, setState] = useState({ totalMillis: 0, remainingMillis: 0 });
  const { totalMillis, remainingMillis } = state;
  const tickMs = 50;

  useEffect(() => {
    function reset() {
      setState({ totalMillis: 0, remainingMillis: 0 });
    }

    function start(millis: number) {
      setState({ totalMillis: millis, remainingMillis: millis });
    }

    function pause() {
      // NOTE: this setTimeout was necessary to avoid the following warning.
      // Warning: Cannot update a component (`TimerHUD`) while rendering a different component (`SessionScreen`).
      setTimeout(reset, 0);
    }

    EventBus.on("pauseTimer", pause);
    EventBus.on("resetTimer", reset);
    EventBus.on("startTimer", start);
    return () => {
      EventBus.off("pauseTimer", pause);
      EventBus.off("resetTimer", reset);
      EventBus.off("startTimer", start);
    };
  }, []);

  // Manage visual ticks.
  const tickHandle = useRef<NodeJS.Timeout>();
  useEffect(() => {
    if (!remainingMillis) {
      return;
    }

    function tick() {
      setState((curr) => {
        const { remainingMillis } = curr;
        const nextMs = remainingMillis - tickMs;
        if (remainingMillis <= 0 || nextMs <= 0) {
          clearInterval(tickHandle.current);
          return { ...curr, remainingMillis: 0 };
        }
        return { ...curr, remainingMillis: nextMs };
      });
    }

    clearInterval(tickHandle.current);
    tickHandle.current = setInterval(tick, tickMs);

    return () => {
      clearInterval(tickHandle.current);
    };
  }, [remainingMillis]);

  const numBlocks = 5;
  const remainingPct = remainingMillis / totalMillis;
  const running = totalMillis > 0;
  const remainingBlocks = running ? Math.round(numBlocks * remainingPct) : numBlocks;
  const alive = remainingBlocks > 0;

  return (
    <div
      style={{
        marginLeft: 4,
        marginTop: isTheaterMode ? 4 : 36,
        zIndex: 1000,
        position: "fixed",
        display: "flex",
        opacity: 0.5,
      }}
    >
      <div style={{ flex: 0, alignItems: "center", display: "flex", flexDirection: "column" }}>
        <IonIcon
          slot="start"
          icon={timerOutline}
          style={{ color: alive ? Style.colors.actionableFg : "var(--ion-color-step-250)" }}
        />
        <Blocks total={numBlocks} remaining={remainingBlocks} />
      </div>
    </div>
  );
}
