import { IonFooter, IonToolbar } from "@ionic/react";
import React, { useRef } from "react";
import Style from "style";

interface IProps {
  children: React.ReactNode;
  hidden?: boolean;
  style?: Record<string, string | number>;
  isTheaterMode?: boolean;
}
export default function Footer(props: IProps): JSX.Element {
  const { children, isTheaterMode } = props;
  let { style } = props;
  const footerRef = useRef<HTMLIonFooterElement>(null);

  // useHideTabBar();

  style = {
    ...style,
    "--padding-top": 0,
    "--padding-start": 0,
    "--padding-end": 0,
  };

  if (isTheaterMode) {
    style = {
      ...style,
      "--background": "none",
      "--border-style": "none",
    };
  }

  return (
    <IonFooter
      ref={footerRef}
      translucent
      style={{
        backgroundColor: isTheaterMode ? Style.colors.primaryBg : undefined,
      }}
    >
      <IonToolbar style={style}>{children}</IonToolbar>
    </IonFooter>
  );
}
