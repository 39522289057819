import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonProgressBar,
} from "@ionic/react";
import { User } from "@models/user";
import { AsyncVal } from "hooks/util/useAsyncValState";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { trashOutline } from "ionicons/icons";
import L10n from "localization";
import Network from "network";
import React from "react";
import { IPendingGroupJoinRequest } from "./types";

interface IProps {
  pendingRequestsVal: AsyncVal<IPendingGroupJoinRequest[]>;
  fetchPendingRequests: () => Promise<void>;
}
export default function PendingRequestList(props: IProps): JSX.Element | null {
  const { pendingRequestsVal, fetchPendingRequests } = props;

  const [presentToast] = useDismissibleToast();

  if (pendingRequestsVal.loading) {
    return <IonProgressBar type="indeterminate" />;
  }

  const reqs = pendingRequestsVal.val ?? [];
  if (reqs.length < 1) {
    return null;
  }

  function handleRemove(request: IPendingGroupJoinRequest) {
    const confMsg = L10n.localize((s) => s.funcs.groups.confirmCancelPendingRequest)(request.name);
    if (!confirm(confMsg)) {
      return;
    }

    const userId = User.id();
    Network.fetch("DELETE", `/users/groups/${request.id}/join_requests/${userId}`)
      .then(fetchPendingRequests)
      .catch((err) => {
        presentToast({
          message: L10n.localize((s) => s.groups.cancelPendingRequestFailed),
          color: "warning",
          position: "top",
          duration: 3000,
        });
      });
  }

  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.groups.pendingRequests)}</IonListHeader>
      {reqs.map((req) => (
        <IonItem key={req.id}>
          <IonLabel>{req.name}</IonLabel>
          <IonAvatar slot="end">
            <IonButton fill="clear" onClick={() => handleRemove(req)}>
              <IonIcon slot="start" color="danger" icon={trashOutline} />
            </IonButton>
          </IonAvatar>
        </IonItem>
      ))}
    </IonList>
  );
}
