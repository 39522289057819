import React from "react";

const saveKey = "s";

export default function useSaveKeyboardShortcut(saveCard: () => void) {
  React.useEffect(() => {
    let down = false;

    function handleKeyDown(ev: KeyboardEvent) {
      if (down) {
        return;
      }
      if (ev.key === saveKey && (ev.ctrlKey || ev.metaKey)) {
        ev.preventDefault();
        down = true;
        saveCard();
      }
    }

    function handleKeyUp(ev: KeyboardEvent) {
      if (ev.key === saveKey) {
        down = false;
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [saveCard]);
}
