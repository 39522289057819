import Template from "@cardRendering/template";
import BlobStore from "@data/idb/blobStore";
import MapView from "@lib/mapView.js";
import type { IDeck } from "@models/deck";
import type { IKnol } from "@models/knol.js";
import { logAction } from "analytics/action";
import { useDeckFields } from "fields/hooks/useDeckFields";
import Lib from "lib";
import L10n from "localization";
import * as Papa from "papaparse";
import React from "react";
import Style from "style";
import type { ID } from "types/ID.js";
import type { Column, DataGridHandle } from "../../../vendor/react-data-grid/bundle";
import DataGrid, { TextEditor } from "../../../vendor/react-data-grid/bundle.js";
import GridCellContentsRenderer from "./gridCellContentsRenderer";

function templateKeys(templates: string[]): string[] {
  const keys = new Set<string>();

  const keyWrapperRe = /{{[^}]+}}/g;
  templates?.forEach((tmpl) => {
    const wrappers = tmpl.match(keyWrapperRe);
    wrappers?.forEach((wrapper) => {
      const key = wrapper.slice(2, wrapper.length - 2);
      keys.add(key);
    });
  });

  return Array.from(keys.values());
}

const cardIDKey = "$CARD_ID$";

function genEmptyNewRow(columns: string[]) {
  const magicRow: Record<string, string> = {};
  columns.forEach((col) => {
    magicRow[col] = "";
  });
  magicRow[cardIDKey] = Lib.uuid16();
  return magicRow;
}

interface IProps {
  deck: IDeck | undefined;
  filteredKnols: MapView<ID, IKnol>;
  editable: boolean;
  newRows: Record<string, string>[];
  setNewRows: (newRows: Record<string, string>[]) => void;
  edits: Record<string, Record<string, string>>;
  setEdits: (edits: Record<string, Record<string, string>>) => void;
  savedAt?: Date;
  clearedAt?: Date;
  columns: string[];
}
export default function Grid(props: IProps): JSX.Element {
  const { deck, editable, newRows, setNewRows, edits, setEdits, savedAt, clearedAt, columns } =
    props;
  const gridRef = React.useRef<DataGridHandle>(null);
  const fields = useDeckFields(deck);

  function isMagicRow(rowIdx: number) {
    return editable && rowIdx === 0;
  }

  React.useEffect(() => {
    if (editable) {
      setNewRows([genEmptyNewRow(columns)]);
    } else {
      setNewRows([]);
    }
  }, [columns, setNewRows, clearedAt, savedAt, editable]);

  const selectedRowIndex = React.useRef<number>();
  function handleRowClicked(row: { index: number }) {
    const { index } = row;
    selectedRowIndex.current = index;
  }

  // Paste handling.
  React.useEffect(() => {
    function handlePaste(evt: React.ClipboardEvent) {
      const rowIdx = selectedRowIndex.current;
      if (rowIdx === undefined || !isMagicRow(rowIdx)) {
        return;
      }

      const text = evt.clipboardData.getData("Text").trimEnd();
      const csv = Papa.parse<Record<string, any>>(text, { header: true });

      const csvFields = csv.meta.fields ?? [];
      const csvHasAllFields = columns.every((col) => csvFields.includes(col));

      // If this CSV matches the columns, paste as a set of new cards.
      // Otherwise, paste directly into this cell.
      if (csvHasAllFields) {
        evt.preventDefault();

        const newRows = csv.data.map((row: Record<string, any>) => ({
          ...row,
          [cardIDKey]: Lib.uuid16(),
        }));
        setNewRows([genEmptyNewRow(columns), ...newRows]);
      }
    }

    document.addEventListener("paste", handlePaste as any);
    return () => {
      document.removeEventListener("paste", handlePaste as any);
    };
  }, [setNewRows, columns, selectedRowIndex.current, newRows]);

  // Sort columns.
  // 1. Get keys ordered by their sequence in templates.
  // 2. Intersect that with keys fetched from DB.
  // 3. In case DB has any keys that weren't present in 1, append those to end.
  const templates = deck?.layouts?.find((l) => l.id === deck.layout_id)?.templates ?? [];
  const tmplKeys = templateKeys(templates);
  const intersection = tmplKeys.filter((k) => columns.includes(k));
  const remainder = columns.filter((k) => !intersection.includes(k));
  const combined = intersection.concat(remainder).reverse(); // so Front is before Back

  // Hack editor to show blank initial value (for blobs, mainly).
  function Editor(props: any) {
    const { row, column } = props;

    const val = row[column.key];

    let newVal = val;

    // Blank out blobs.
    const blobRe = /^{{blob \w+}}$/;
    if (newVal.match(blobRe)) {
      newVal = "";
    }

    const newRow = { ...row, [column.key]: newVal };

    return <TextEditor {...props} row={newRow} />;
  }

  function GridCellRenderer(props: {
    column: Column<any, any>;
    row: Record<string, any>;
    isCellSelected: boolean;
  }) {
    const { row, column, isCellSelected } = props;
    const rowIdx = row.index;

    // HACK: this allows us to know when the user selected a new cell through keyboard navigation.
    if (isCellSelected) {
      selectedRowIndex.current = rowIdx;
    }

    const { key } = column;
    const val = row[key];
    const cardID = row[cardIDKey];

    const edit = edits[cardID]?.[key];
    const isEdited = edit != null;

    const isNewRow = rowIdx < newRows.length;

    let value = edit ?? val;
    const isEmptyNewRow = isNewRow && !value;
    if (isMagicRow(rowIdx)) {
      value = L10n.localize((s) => s.deck.emptyNewGridCellMessage);
    }

    let color: string | undefined = undefined;
    if (isEmptyNewRow) {
      color = Style.colors.mutedFg;
    } else if (isEdited || isNewRow) {
      color = Style.colors.actionableFg;
    }

    const style = {
      color,
      fontStyle: isEdited || isNewRow ? "italic" : undefined,
      padding: 4,
      height: "100%",
    } as React.CSSProperties;

    const acceptableDropTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif", "image/bmp"];
    function handleDragOver(evt: any) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    function handleDragEnter(evt: any) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    function handleDragLeave(evt: any) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    async function handleDrop(evt: any) {
      evt.stopPropagation();
      evt.preventDefault();

      const { files } = evt.dataTransfer;
      let file: any;
      for (let i = 0; i < files.length; i++) {
        if (acceptableDropTypes.includes(files[i].type)) {
          file = files[i];
          break;
        }
      }
      if (!file || !deck) {
        return;
      }

      const id = Lib.uuid16();
      try {
        await BlobStore.insertBlob({
          id,
          deckID: deck.id,
          blob: file,
          pendingSave: true,
        });
      } catch (err) {
        alert(L10n.localize((s) => s.errors.failedToSaveFile));
        logAction({
          subj: "IndexedDB",
          obj: "blob",
          obj_id: id,
          verb: "insert",
          state: "fail",
        });
        return;
      }

      const val = `{{blob ${id}}}`;

      if (isNewRow) {
        const updatedNewRows = newRows.slice();
        updatedNewRows[rowIdx][key] = val;

        if (isMagicRow(rowIdx)) {
          setNewRows([genEmptyNewRow(columns), ...updatedNewRows]);
        } else {
          setNewRows(updatedNewRows);
        }
      } else {
        const cardEdits = edits[cardID] ?? {};
        const newCardEdit = { ...cardEdits, [key]: val };
        const newEdits = { ...edits, [cardID]: newCardEdit };
        setEdits(newEdits);
      }
    }

    const renderer = React.useMemo(() => Template(`{{${key}}}`, "tmpl", true), [key]);
    const overrideValues = React.useMemo(() => ({ [key]: value }), [key, value]);

    return (
      <div
        className="grid-cell"
        onDragEnter={editable ? handleDragEnter : undefined}
        onDragLeave={editable ? handleDragLeave : undefined}
        onDragOver={editable ? handleDragOver : undefined}
        onDrop={editable ? handleDrop : undefined}
        style={style}
      >
        <GridCellContentsRenderer
          cardID={cardID}
          renderer={renderer}
          values={row}
          overrideValues={overrideValues}
          fields={fields}
        />
      </div>
    );
  }

  const cols = combined.map((col) => ({
    key: col,
    name: col,
    editor: editable ? Editor : undefined,
    editorOptions: {
      editOnClick: true,
    },
    formatter: React.memo(GridCellRenderer),
  }));

  const [rows, setRows] = React.useState<Iterable<Record<string, string>>>([]);
  React.useEffect(() => {
    const rows: Array<Record<string, string>> = [];
    for (const [, knol] of props.filteredKnols) {
      const row: Record<string, string> = {};
      for (const col of columns) {
        row[col] = knol.values?.[col] ?? "";
      }
      row[cardIDKey] = knol.id;
      rows.push(row);
    }
    setRows(rows);
  }, [props.filteredKnols]);

  const combinedRows = [...newRows, ...rows];

  function handleRowsChanged(rows: any[], data: { column: any; indexes: number[] }) {
    const { column, indexes } = data;

    const rowIdx = indexes[0];

    const isNewRow = rowIdx < newRows.length;

    const row = rows[rowIdx];

    const { key } = column;
    const val = row[column.key];

    if (isMagicRow(rowIdx)) {
      setNewRows([genEmptyNewRow(columns), ...rows.slice(0, newRows.length)]);
    } else if (isNewRow) {
      setNewRows(rows.slice(0, newRows.length));
    } else {
      setRows(rows.slice(newRows.length, rows.length));

      const cardID = row[cardIDKey];
      const cardEdits = edits[cardID] ?? {};

      const newCardEdit: Record<string, string> = { ...cardEdits, [key]: val };
      if (val === "") {
        delete newCardEdit[key];
      }
      const newEdits = { ...edits, [cardID]: newCardEdit };

      setEdits(newEdits);
    }
  }

  // HACK: this re-adds rowIdx. See https://github.com/adazzle/react-data-grid/pull/2844#issuecomment-1068336292.
  const indexedRows = React.useMemo(
    () => combinedRows.map((row, index) => ({ ...row, index })),
    [combinedRows],
  );

  return (
    <DataGrid
      ref={gridRef}
      className={Style.currentTheme === "night" ? "rdg-dark" : undefined}
      columns={cols}
      rows={indexedRows}
      style={{ height: "100%" }}
      onRowsChange={handleRowsChanged}
      rowHeight={58}
      onRowClick={handleRowClicked}
      rowKeyGetter={(row: any) => row[cardIDKey]}
      rowClass={() => "aa-grid-row"}
    />
  );
}
