import { PluginListenerHandle } from "@capacitor/core";
import { ConnectionStatus, Network } from "@capacitor/network";
import { useEffect, useState } from "react";

export default function useNetworkStatus() {
  const [status, setStatus] = useState<ConnectionStatus>();
  useEffect(() => {
    Network.getStatus().then((status) => {
      setStatus(status);
    });

    function handleStatusChange(status: ConnectionStatus) {
      setStatus(status);
    }

    let handle: PluginListenerHandle;
    Network.addListener("networkStatusChange", handleStatusChange).then((hnd) => (handle = hnd));

    return () => {
      handle?.remove();
    };
  }, []);

  return status;
}
