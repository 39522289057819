import { showPrompt } from "@components/prompt";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React from "react";
import {
  ISettings,
  Setting,
  getCurrentUserSettings,
  resetSettingsToDefault,
  updateSetting,
} from "settings";
import Style from "style";
import L10n from "../localization";
import EditorSettings from "./settings/editorSettings";
import InterfaceSettings from "./settings/interfaceSettings";
import RemindersSettings from "./settings/remindersSettings";
import ReviewSettings from "./settings/reviewSettings";

export default function SettingsScreen(): JSX.Element {
  const initialSettings = getCurrentUserSettings();
  const [settings, setSettings] = React.useState<ISettings>(initialSettings);

  const resetSettingsPrompt = (): void => {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.settings.confirmReset),
      promptType: "confirm",
      callback: () => {
        resetSettingsToDefault().then(() => {
          Style.resetTheme();
          setSettings(getCurrentUserSettings());
        });
      },
    });
  };

  const updateSettings = <T extends Setting>(s: T, v: ISettings[T]) => {
    updateSetting(s, v)
      .then((newSettings) => setSettings(newSettings))
      .catch((err) => {
        console.error("Failed to update settings:", err);
      });
  };

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.general.settings)}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={resetSettingsPrompt}>
              {L10n.localize((s) => s.actions.reset)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <InterfaceSettings settings={settings} updateSettings={updateSettings} />
        <RemindersSettings settings={settings} updateSettings={updateSettings} />
        <ReviewSettings settings={settings} updateSettings={updateSettings} />
        <EditorSettings settings={settings} updateSettings={updateSettings} />
      </IonContent>
    </>
  );
}
