export default function timeAdjustedScore(score: number, durationMs: number) {
  if (score > 0.9) {
    // easy
    if (durationMs < 800) {
      return 1;
    }
    if (durationMs < 1200) {
      return 0.99;
    }
    if (durationMs < 1500) {
      return 0.98;
    }
    if (durationMs < 2000) {
      return 0.98;
    }
    if (durationMs < 2500) {
      return 0.98;
    }
    if (durationMs < 3000) {
      return 0.98;
    }
    if (durationMs < 4000) {
      return 0.94;
    }
    if (durationMs < 6000) {
      return 0.92;
    }
    if (durationMs < 12000) {
      return 0.9;
    }
    return 0.95;
  }
  if (score >= 0.75) {
    // good
    if (durationMs < 2500) {
      return 0.8;
    }
    if (durationMs < 4000) {
      return 0.77;
    }
    if (durationMs < 6000) {
      return 0.76;
    }
    if (durationMs < 10000) {
      return 0.75;
    }
    return 0.755;
  }
  if (score >= 0.5) {
    // hard
    if (durationMs < 3000) {
      return 0.5;
    }
    if (durationMs < 7000) {
      return 0.55;
    }
    if (durationMs < 14000) {
      return 0.6;
    }
    return 0.52;
  }
  // fail
  if (durationMs < 1500) {
    return 0;
  }
  if (durationMs < 4000) {
    return 0.02;
  }
  if (durationMs < 6000) {
    return 0.04;
  }
  if (durationMs < 10000) {
    return 0.06;
  }
  return 0.08;
}
