import ScreenComponent from "@components/screen";
import { History } from "history";
import { useIsPhone } from "hooks/util/useMediaQuery";
import L10n from "localization";
import React, { useState } from "react";
import CardResults from "./search/cards";
import DeckResults from "./search/deckResults";
import PublicDecksSearchView from "./search/publicDecksSearchView";
import SearchModeSegmentView, { SearchMode } from "./search/searchModeSegmentView";

function Body({
  mode,
  query,
  setQuery,
}: {
  mode: SearchMode;
  query: string;
  setQuery: (q: string) => void;
}) {
  switch (mode) {
    case "cards":
      return <CardResults query={query} />;
    case "decks":
      return <DeckResults query={query} />;
    case "public":
      return <PublicDecksSearchView query={query} setQuery={setQuery} />;
  }
}

const searchModeLocalStorageKey = "searchMode";

export default function SearchScreen(props: { history: History }): JSX.Element {
  const { history } = props;
  const savedQueryEnc = window.location.search.split("?q=")[1];
  const initialQuery = savedQueryEnc ? decodeURIComponent(savedQueryEnc) : "";
  const [query, setQuery] = useState(initialQuery);

  const defaultMode: SearchMode = "public";
  const [mode, setMode] = useState<SearchMode>(
    (localStorage.getItem(searchModeLocalStorageKey) as SearchMode) || defaultMode,
  );
  function handleSetMode(mode: SearchMode) {
    localStorage.setItem(searchModeLocalStorageKey, mode);
    setMode(mode);
  }

  const searchBarLabel = {
    cards: L10n.localize((s) => s.card.yours),
    decks: L10n.localize((s) => s.deck.yours),
    public: L10n.localize((s) => s.search.publicDecks),
  }[mode];

  function handleSearch(newQuery: string) {
    const base = window.location.pathname;
    const newUrl = base + `?q=${encodeURIComponent(newQuery)}`;
    history.push(newUrl);
    setQuery(newQuery);
  }

  const isPhone = useIsPhone();
  const disableLiveSearch = isPhone && mode === "public";

  return (
    <ScreenComponent
      title={L10n.localize((s) => s.actions.search)}
      content={
        <>
          <SearchModeSegmentView mode={mode} setMode={handleSetMode} />
          <Body mode={mode} query={query} setQuery={handleSearch} />
        </>
      }
      searchQuery={query}
      searchDebounceMs={500}
      searchPlaceholder={searchBarLabel}
      onLiveSearch={disableLiveSearch ? undefined : handleSearch}
      onSearch={disableLiveSearch ? handleSearch : undefined}
    />
  );
}
