import { IonButton, IonButtons, IonIcon, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import cyAttr from "lib/cyAttr";
import React from "react";

interface IProps {
  valid?: boolean;
  title: string;
  content: any;
  onCancel: () => void;
  showAcceptButton: boolean;
  onAccept?: () => void;
  acceptText?: string;
  hasPadding?: boolean;
}

export default function ModalDialog(props: IProps) {
  const { valid, content, title, onCancel, onAccept, acceptText, showAcceptButton } = props;

  const style: React.CSSProperties = {
    flex: 1,
    margin: "0 auto",
    width: "100%",
    maxWidth: props?.hasPadding ? "36rem" : "",
    padding: props?.hasPadding ? "12px 16px" : "",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflowY: "auto",
  };

  return (
    <IonPage style={{ marginBottom: "env(safe-area-inset-bottom)" }}>
      <IonToolbar>
        <IonTitle style={{ padding: 0 }}>{title}</IonTitle>
        <IonButtons slot="primary">
          <IonButton onClick={onCancel} {...cyAttr("close-modal-button")}>
            <IonIcon icon={closeCircle} color="medium" />
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <div style={style}>
        <div style={{ height: "100%" }}>{content}</div>
        {showAcceptButton && (
          <IonButton
            style={{ flex: "0 0 auto", margin: "1.5rem 0" }}
            color="primary"
            size="large"
            onClick={onAccept}
            disabled={valid === false}
          >
            {acceptText}
          </IonButton>
        )}
      </div>
    </IonPage>
  );
}
