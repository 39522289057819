import { InputCustomEvent, IonInput, IonItem } from "@ionic/react";
import L10n from "localization";
import React from "react";
import cyAttr from "lib/cyAttr";

interface IProps {
  name: string;
  isEditable?: boolean;
  onDeckNameChange: (name?: string) => void;
}
export default function DeckNameItem(props: IProps): JSX.Element {
  const { isEditable = false, name, onDeckNameChange } = props;

  return isEditable ? (
    <IonItem lines="inset">
      <IonInput
        {...cyAttr("deck-name")}
        disabled={!isEditable}
        label={L10n.localize((s) => s.general.name)}
        placeholder={L10n.localize((s) => s.general.required)}
        value={name ?? ""}
        onIonInput={(e: InputCustomEvent) => onDeckNameChange(e.detail.value ?? undefined)}
        clearInput
        maxlength={255}
      />
    </IonItem>
  ) : (
    <h2>{name}</h2>
  );
}
