import Button from "@components/button";
import LoadingSpinner from "@components/loadingSpinner";
import { showPrompt } from "@components/prompt";
import UnlimitedIcon from "@components/unlimitedIcon";
import BlobStore from "@data/idb/blobStore";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonLoading,
  IonMenuButton,
  IonNote,
  IonProgressBar,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IAccountInfo, User } from "@models/user";
import logEvent from "analytics";
import Device from "device";
import EventBus from "eventBus";
import Globals, { isMacAppStoreElectron } from "globals";
import { History } from "history";
import useAlert from "hooks/util/useAlert";
import useErrorAlert from "hooks/util/useErrorAlert";
import useHasUnlimited from "hooks/util/useHasUnlimited";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { IRestoreProductsResponse } from "iap";
import {
  keyOutline,
  logOutOutline,
  mailOutline,
  personRemoveOutline,
  syncOutline,
  walletOutline,
} from "ionicons/icons";
import L10n from "localization";
import { ISyncStatus, Operation } from "models/operation";
import Network from "network";
import React, { useEffect, useState } from "react";
import { restoreProducts } from "unlimited/ios";
import usePDP from "unlimited/pdp";
import ChangeEmailModal from "./changeEmailModal";
import ChangePasswordModal from "./changePasswordModal";
import ProfileNameItem from "./profileNameItem";
import SyncStatus from "./syncStatus";
import { IProfileProduct } from "./types";
import UpgradeTable from "./upgradeTable";

export default function Account({
  history,
}: {
  history: History;
}): JSX.Element {
  const [isSyncing, setIsSyncing] = useState<boolean>(Operation.isSyncing);
  const [email, setEmail] = React.useState(localStorage["AnkiApp.user.email"]);
  function handleChangeEmail() {
    setEmail(localStorage["AnkiApp.user.email"]);
  }
  const [isLoadingFeatures, setIsLoadingFeatures] = useState<boolean>(false);
  const [lastSyncStatus, setLastSyncStatus] = useState<ISyncStatus | undefined>(undefined);
  const { hasUnlimited, loading: loadingHasUnlimited } = useHasUnlimited();
  const [presentChangePasswordModal] = useIonCardModalWithDismiss(ChangePasswordModal, {});
  const [presentChangeEmailModal] = useIonCardModalWithDismiss(ChangeEmailModal, {
    onDismiss: handleChangeEmail,
  });
  const [products, setProducts] = React.useState<IProfileProduct[]>();
  const [showAlert] = useAlert();
  const [showRestoreIAPError] = useErrorAlert({
    code: "RESTORING_IAPS",
    message: L10n.localize((s) => s.purchasing.iOSIAPRestoreError),
  });
  useEffect(() => {
    const handleSyncBegin = () => {
      setIsSyncing(true);
    };
    const handleSyncComplete = (result: ISyncStatus | undefined) => {
      setIsSyncing(false);
      setLastSyncStatus(result);
    };
    EventBus.on("sync_begin", handleSyncBegin);
    EventBus.on("sync_complete", handleSyncComplete);
    if (!Operation.isSyncing) {
      setIsSyncing(true);
      Operation.syncOverride().finally(() => {
        setIsSyncing(false);
        setLastSyncStatus(Operation.getLastSyncStatus());
      });
    }
    return () => {
      EventBus.off("sync_complete", handleSyncComplete);
      EventBus.off("sync_begin", handleSyncBegin);
    };
  }, []);

  const [accountInfo, setAccountInfo] = React.useState<IAccountInfo>();

  const [loggingOut, setLoggingOut] = React.useState(false);

  const handleLogout = () => {
    showPrompt({
      title: L10n.localize((s) => s.actions.logout),
      prompt: L10n.localize((s) => s.account.confirmLogout),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.logout),
      callback: async () => {
        setLoggingOut(true);

        const abort = (msg: string) => {
          showPrompt({
            title: L10n.localize((s) => s.general.attention),
            prompt: msg,
            promptType: "alert",
          });
          setLoggingOut(false);
        };

        try {
          const [operations, numPendingBlobUploads] = await Promise.all([
            Operation.unsynced(),
            BlobStore.numPendingBlobUploads(),
          ]);
          if (operations.length < 1 && numPendingBlobUploads < 1) {
            await User.logout();
            history.replace("/auth");
            return;
          }

          const keyword = "delete";

          showPrompt({
            promptType: "input",
            title: L10n.localize((s) => s.account.forceLogout),
            prompt: L10n.localize((s) => s.funcs.account.forceLogoutPrompt)(keyword),
            autoCapitalize: "off",
            callback: async (response) => {
              if (response !== keyword) {
                return abort(L10n.localize((s) => s.account.forceLogoutAborted));
              }

              setLoggingOut(true);

              await User.logout();
              history.replace("/auth");
              return;
            },
          });
          setLoggingOut(false);
        } catch {
          await User.logout();
          history.replace("/auth");
          return;
        }
      },
    });
  };

  const fetchAccountInfo = async () => {
    try {
      setAccountInfo(
        await Network.fetch<IAccountInfo>("GET", "/users/account", {
          locale: L10n.currentLocale,
        }),
      );
    } catch {
      // ignore
    }
  };

  const [showPDP] = usePDP({
    source: "profile",
    reason: undefined,
    onPurchaseComplete: fetchAccountInfo,
  });

  useEffect(() => {
    (async () => {
      fetchAccountInfo();
      try {
        setProducts(
          await Network.fetch<IProfileProduct[]>("GET", "/products", {
            locale: L10n.currentLocale,
          }),
        );
      } catch {
        // ignore
      }
    })();
  }, []);

  const handleDeleteAccount = () => {
    showPrompt({
      promptType: "input",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.account.delete),
      title: L10n.localize((s) => s.account.delete),
      prompt: L10n.localize((s) => s.account.confirmDeleteAccount),
      validationRegex: /.+/,
      inputType: "password",
      callback: async (pw) => {
        if (!pw) {
          return;
        }
        try {
          await User.deleteAccount(pw);
        } catch (e) {
          showPrompt({
            title: L10n.localize((s) => s.general.attention),
            prompt: L10n.localize((s) => s.account.errorDeletingAccount),
            promptType: "alert",
          });
        } finally {
          setLoggingOut(false);
        }
      },
    });
  };

  const handleUpgrade = () => {
    showPDP();
  };
  const handleRestoreIAPs = () => {
    setIsLoadingFeatures(true);
    logEvent("restore_iaps", { state: "init" });
    restoreProducts()
      .then((restoreResp: IRestoreProductsResponse) => {
        const numRestored = restoreResp?.responses?.length ?? 0;
        if (restoreResp?.cancelled) {
          logEvent("restore_iaps", { state: "cancelled" });
          // No need to show user feedback. They just clicked the cancel button.
          return;
        }
        logEvent("restore_iaps", {
          state: "done",
          responses: restoreResp.responses,
        });
        if (numRestored > 0) {
          fetchAccountInfo();
          showAlert(L10n.localize((s) => s.account.iapsRestored));
        } else {
          showAlert(L10n.localize((s) => s.account.noIAPsToRestore));
        }
      })
      .catch((nativeErrMsg) => {
        logEvent("restore_iaps", { state: "fail", nativeErrMsg });
        showRestoreIAPError({ nativeErrMsg });
      })
      .finally(() => {
        setIsLoadingFeatures(false);
      });
  };
  if (loggingOut) {
    return <LoadingSpinner />;
  }

  const subscription = accountInfo?.products?.find(
    (p) => p.customer_id && p.customer_id !== "" && p.id === "unlimited_year",
  );

  return (
    <>
      <IonHeader translucent={true}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.general.account)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonList inset>
          <IonItem lines="none">
            <IonIcon icon={syncOutline} slot="start" />
            <IonLabel>{L10n.localize((s) => s.sync.status)}</IonLabel>
            <IonNote style={{ display: "flex", alignItems: "center" }} slot="end">
              <SyncStatus isSyncing={isSyncing} lastSyncStatus={lastSyncStatus} />
            </IonNote>
          </IonItem>
        </IonList>
        <IonList inset className="pb-8">
          <IonLoading
            isOpen={isLoadingFeatures}
            onDidDismiss={() => setIsLoadingFeatures(false)}
            message={L10n.localize((s) => s.general.dataLoadingMessage)}
          />
          {loadingHasUnlimited && <IonProgressBar type="indeterminate" />}
          {hasUnlimited && (
            <IonRow className="ion-justify-content-center">
              <IonItem lines="none">
                <UnlimitedIcon />
              </IonItem>
            </IonRow>
          )}
          {subscription && (
            <IonItem
              lines="none"
              button
              detail
              onClick={() => {
                Device.openExternalLink(
                  `${Globals.apiEndpoint}/users/purchases/stripe/manage?c=${subscription?.customer_id}`,
                );
              }}
            >
              <IonIcon icon={walletOutline} slot="start" />
              <IonLabel>{L10n.localize((s) => s.account.manageSubscription)}</IonLabel>
            </IonItem>
          )}
          {!loadingHasUnlimited && !hasUnlimited && products && (
            <>
              <UpgradeTable products={products} />
              <div>
                <IonButton
                  onClick={handleUpgrade}
                  expand="block"
                  size="large"
                  style={{
                    margin: "8px 12px 0 12px",
                    color: "white",
                  }}
                >
                  {L10n.localize((s) => s.account.upgradeNow)}
                </IonButton>
                {(Globals.platform === "ios" || isMacAppStoreElectron()) && (
                  <div>
                    <Button
                      iconRight={true}
                      iconPadding="0 0.5rem"
                      text={L10n.localize((s) => s.account.restoreIAP)}
                      onClick={handleRestoreIAPs}
                    />
                  </div>
                )}
              </div>
            </>
          )}
        </IonList>
        <IonList inset>
          <IonItem button detail onClick={presentChangeEmailModal}>
            <IonIcon icon={mailOutline} slot="start" />
            <IonLabel>{L10n.localize((s) => s.account.email)}</IonLabel>
            <IonNote slot="end">{email}</IonNote>
          </IonItem>
          <ProfileNameItem />

          <IonItem button detail onClick={presentChangePasswordModal}>
            <IonIcon icon={keyOutline} slot="start" />
            <IonLabel>{L10n.localize((s) => s.account.changePassword)}</IonLabel>
          </IonItem>

          <IonItem button detail onClick={handleDeleteAccount}>
            <IonIcon icon={personRemoveOutline} slot="start" color="danger" />
            <IonLabel color="danger">{L10n.localize((s) => s.account.delete)}</IonLabel>
          </IonItem>
          <IonItem button detail lines="none" onClick={handleLogout}>
            <IonIcon icon={logOutOutline} slot="start" />
            <IonLabel>{L10n.localize((s) => s.actions.logout)}</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </>
  );
}
