import { IonNav } from "@ionic/react";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import React, { useCallback } from "react";
import CardScreenViewController from "./cardScreenViewController";

interface IProps {
  deck: IDeck | undefined;
  knol?: IKnol;
  dismiss: () => void;
  setCanDismiss: (v: boolean) => void;
}
function CardScreenModal(props: IProps): JSX.Element {
  // NOTE: this useCallback is necessary to prevent CardScreenViewController from getting recreated on re-renders.
  const navRoot = useCallback(
    () => (
      <CardScreenViewController
        deck={props.deck}
        knol={props.knol}
        dismiss={props.dismiss}
        setCanDismiss={props.setCanDismiss}
      />
    ),
    [props.knol, props.deck, props.dismiss, props.setCanDismiss],
  );

  return <IonNav root={navRoot} />;
}

export default React.memo(CardScreenModal);
