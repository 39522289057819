import UpgradeBanner from "@components/banner/upgradeBanner";
import ScreenMenu, { MenuButton } from "@components/screenMenu";
import { ISection } from "@components/sidebar";
import { syncOperationsAndRefetchRemoteDecks } from "@data/remoteDecks";
import {
  InfiniteScrollCustomEvent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";
import cyAttr from "@lib/cyAttr";
import ScreenComponent from "components/screen";
import EventBus from "eventBus";
import { History } from "history";
import useErrorAlert from "hooks/util/useErrorAlert";
import { useStoredGroupJoinRequest } from "hooks/util/useGroupJoinRequest";
import { useMediaQueryWhileOnscreen } from "hooks/util/useMediaQuery";
import { addOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useCallback, useState } from "react";
import { BiImport } from "react-icons/bi";
import { LuArchive, LuBookCopy, LuBookPlus, LuFolderTree, LuInbox, LuUsers } from "react-icons/lu";
import { MdOutlinePostAdd } from "react-icons/md";
import { getDeckSortOrder, setDeckSortOrder } from "settings";
import usePDP, { IShowParams } from "unlimited/pdp";
import { BodySection, SortOptions, bodySections } from "./decks/types";
import HomeBody from "./home/homeBody";
import SplitPane from "./home/splitPane";
import { AddToInboxButton, useInboxCaptureFlow } from "./inbox";
import NewStudyGroupButton from "./studyGroups/newStudyGroupButton";

interface IProps {
  history: History;
  onNewDeck: () => void;
  onImportDeck: () => void;
  onNewFolder: () => void;
  onNewCard: () => void;
}
function HomeScreen(props: IProps): JSX.Element {
  const { history } = props;

  useStoredGroupJoinRequest("decks_screen");
  const isNarrowScreen = useMediaQueryWhileOnscreen("(max-width: 991px)");

  const [sortOrder, setSortOrder] = React.useState<SortOptions>(getDeckSortOrder());

  const fallbackSection: BodySection = "decks";
  const cachedSection = sessionStorage.getItem("activeDeckScreenSection") ?? fallbackSection;
  const defaultSection: BodySection = bodySections.includes(cachedSection as BodySection)
    ? (cachedSection as BodySection)
    : fallbackSection;
  const [activeSection, setActiveSectionUncached] = React.useState<BodySection>(defaultSection);

  const setActiveSection = (section: BodySection) => {
    if (isNarrowScreen) {
      switch (section) {
        case "studygroups":
          history.push("/groups");
          return;
        case "archive":
          history.push("/archive");
          return;
        case "inbox":
          history.push("/inbox");
          return;
      }
    }

    sessionStorage.setItem("activeDeckScreenSection", section);
    setActiveSectionUncached(section);
  };

  const [query, setQuery] = React.useState<string>();

  function handleSort(value: SortOptions) {
    if (value) {
      setDeckSortOrder(value).finally(() => {
        setSortOrder(value);
      });
    }
  }

  React.useEffect(() => {
    EventBus.on("deckSortUpdated", handleSort);
    EventBus.on("showPDP", handleShowPDP);
    return () => {
      EventBus.off("deckSortUpdated", handleSort);
      EventBus.on("showPDP", handleShowPDP);
    };
  }, []);

  const sections: ISection[] = [
    {
      id: "decks",
      text: L10n.localize((s) => s.deck.plural),
      icon: <LuBookCopy size={24} style={{ marginRight: 12 }} />,
    },
    {
      id: "studygroups",
      text: L10n.localize((s) => s.groups.studyGroups),
      icon: <LuUsers size={24} style={{ marginRight: 12 }} />,
    },
    {
      id: "archive",
      text: L10n.localize((s) => s.archive.noun),
      icon: <LuArchive size={24} style={{ marginRight: 12 }} />,
    },
    {
      id: "inbox",
      text: L10n.localize((s) => s.general.inbox),
      icon: <LuInbox size={24} style={{ marginRight: 12 }} />,
    },
  ];

  const activeSectionTitle = sections.find((s) => s.id === activeSection)?.text;

  const [showRefreshErrorAlert] = useErrorAlert({ code: "REFETCHING_DECKS" });
  const handleRefresh = useCallback(
    (event: CustomEvent<RefresherEventDetail>) => {
      syncOperationsAndRefetchRemoteDecks()
        .catch((err) => showRefreshErrorAlert(err))
        .finally(() => {
          event.detail.complete();
        });
    },
    [showRefreshErrorAlert],
  );

  const addToInbox = useInboxCaptureFlow();

  let rightButton: JSX.Element | undefined = undefined;
  switch (activeSection) {
    case "studygroups":
      rightButton = <NewStudyGroupButton />;
      break;
    case "inbox":
      rightButton = <AddToInboxButton />;
      break;
    case "decks":
      rightButton = (
        <ScreenMenu icon={addOutline} {...cyAttr("button-new")}>
          <MenuButton
            {...cyAttr("button-new-deck")}
            label={L10n.localize((s) => s.deck.new)}
            icon={<LuBookPlus size={24} />}
            onClick={props.onNewDeck}
          />
          <MenuButton
            label={L10n.localize((s) => s.folder.new)}
            icon={<LuFolderTree size={24} />}
            onClick={props.onNewFolder}
          />
          <MenuButton
            {...cyAttr("button-import-deck")}
            label={L10n.localize((s) => s.general.importDeck)}
            icon={<BiImport size={24} />}
            onClick={props.onImportDeck}
          />
          <MenuButton
            {...cyAttr("button-create-card")}
            label={L10n.localize((s) => s.card.create)}
            icon={<MdOutlinePostAdd size={24} />}
            onClick={props.onNewCard}
          />
          <MenuButton
            {...cyAttr("button-add-to-inbox")}
            label={L10n.localize((s) => s.actions.addToInbox)}
            icon={<LuInbox size={24} />}
            onClick={addToInbox}
          />
        </ScreenMenu>
      );
      break;
  }

  // Initially restrict number of list items shown, for performance.
  const defaultMaxItems = 20;
  const [maxItems, setMaxItems] = useState(defaultMaxItems);
  function showMoreItems() {
    setMaxItems(maxItems + defaultMaxItems);
  }

  let pdpPrompt: string;

  const handleShowPDP = (params: IShowParams) => {
    if (params.reason) {
      pdpPrompt = params.reason();
    }
    showPDP();
  };

  const [showPDP] = usePDP({
    source: "msg",
    reason: () => pdpPrompt,
  });

  const screen = (
    <ScreenComponent
      id="main"
      slug="Home"
      onLiveSearch={activeSection === "inbox" ? undefined : setQuery}
      searchDebounceMs={50}
      leftButton={<div />} // NOTE: necessary to prevent back button on Home screen after signup.
      rightButton={rightButton}
      headerBanner={<UpgradeBanner />}
      content={
        <>
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent />
          </IonRefresher>
          <HomeBody // NOTE: THIS IS THE TOP SECTION NAV AND DECK LIST
            handleSort={handleSort}
            sortOrder={sortOrder}
            activeSection={activeSection}
            setActiveSection={setActiveSection}
            query={query}
            maxItems={maxItems}
            sections={sections.filter((s) => s.id !== "decks")}
            history={props.history}
            onNewDeck={props.onNewDeck}
            onImportDeck={props.onImportDeck}
            onAddToInbox={addToInbox}
          />
          <IonInfiniteScroll
            onIonInfinite={(ev: InfiniteScrollCustomEvent) => {
              showMoreItems();
              ev.target.complete();
            }}
          >
            <IonInfiniteScrollContent />
          </IonInfiniteScroll>
        </>
      }
      title={activeSectionTitle}
    />
  );

  if (isNarrowScreen) {
    return screen;
  }
  return (
    <SplitPane
      screen={screen}
      sections={sections}
      activeSection={activeSection}
      setActiveSection={setActiveSection}
    />
  );
}

export default React.memo(HomeScreen);
