import { folderDisplayString } from "@components/folderWithSubfoldersString";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonNavLink,
  IonNote,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { DeckFields } from "@models/deck";
import logEvent from "analytics";
import richText from "fields/layoutMarket/richText";
import { defaultFields } from "fields/lib";
import { History } from "history";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import L10n from "../../localization";
import { ILayout } from "../../models/layout";
import FoldersNav from "../deckEdit/foldersNav";
import ConfigSpecificOptions from "./configSpecificOptions";
import DeckNameItem from "./deckNameItem";
import { getDefaultFolder } from "./defaultFolder";
import LayoutSelectorList from "./layoutSelectorList";
import SaveButton from "./saveButton";

export interface ILayoutAndFields {
  layout: ILayout;
  fields: DeckFields;
  values?: Record<string, string>;
  description?: string;
}

interface IProps {
  dismiss?: () => Promise<void>;
  history: History;
  onBack?: () => void;
}

export interface IConfigSelection {
  name?: string;
  base?: string;
  description?: string;
  fields: DeckFields;
  values?: Record<string, string>;
}

export default function DeckCreateMainNav(props: IProps) {
  const { dismiss, history } = props;
  const [isDisabled, setIsDisabled] = useState(false);
  const [deckName, setDeckName] = useState(L10n.localize((s) => s.deck.defaultName) as string);
  const [folder, setFolder] = useState<string | undefined>(getDefaultFolder());

  // NOTE: useMemo is necessary to keep the ID stable across renders.
  const dfs = useMemo(() => defaultFields(), []);
  const defaultConfig = useMemo(() => ({ fields: dfs }), [dfs]);
  const defaultItem = richText();

  const [config, setConfig] = useState<IConfigSelection>();

  const resetLayoutSelection = useCallback(() => {
    setConfig(undefined);
  }, []);

  const contentRef = useRef<HTMLIonContentElement>(null);

  useEffect(() => {
    logEvent("visited_create_deck_screen");
  }, []);

  const cancel = useCallback(() => {
    // TODO: confirm cancel.
    dismiss?.();
  }, [dismiss]);

  const setSaving = useCallback(() => {
    setIsDisabled(true);
  }, []);

  const handleNameChange = (name?: string) => {
    setDeckName(name ?? L10n.localize((s) => s.deck.defaultName));
  };

  let configIncomplete = false;
  if (config?.base === "chinese" || config?.base === "language") {
    const frontField = config.fields[0];
    const backField = config.fields[1];
    const frontMissingLang =
      (frontField.type === "chinese" || frontField.type === "text" || frontField.type === "tts") &&
      frontField.attributes?.lang === undefined;
    const backMissingLang =
      (backField.type === "chinese" || backField.type === "text" || backField.type === "tts") &&
      backField.attributes?.lang === undefined;
    if (frontMissingLang || backMissingLang) {
      configIncomplete = true;
    }
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={cancel}>{L10n.localize((s) => s.actions.close)}</IonButton>
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.deck.new)}</IonTitle>
          <IonButtons slot="end">
            <SaveButton
              dismiss={dismiss}
              history={history}
              setSaving={setSaving}
              disabled={isDisabled || !deckName || deckName.length === 0 || configIncomplete}
              deckName={deckName}
              folder={folder}
              config={config ?? defaultConfig}
            />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent ref={contentRef}>
        <IonList>
          <DeckNameItem name={deckName} onDeckNameChange={handleNameChange} isEditable />

          <IonNavLink
            routerDirection="forward"
            component={() => {
              return <FoldersNav folder={folder} onSelect={setFolder} />;
            }}
          >
            <IonItem button detail>
              <IonLabel>
                <h2>{L10n.localize((s) => s.folder.singular)}</h2>
              </IonLabel>
              <IonNote slot="end">
                {folder ? folderDisplayString(folder) : L10n.localize((s) => s.general.none)}
              </IonNote>
            </IonItem>
          </IonNavLink>

          <IonItem lines={config ? undefined : "none"}>
            <IonLabel style={{ flexGrow: 0 }}>
              <h2>{L10n.localize((s) => s.field.type)}</h2>
            </IonLabel>
            <IonNote>{config?.name ?? defaultItem.name}</IonNote>
            {config ? (
              <IonButton onClick={resetLayoutSelection} slot="end" fill="clear">
                {L10n.localize((s) => s.actions.reset)}
              </IonButton>
            ) : undefined}
          </IonItem>

          {config ? (
            <ConfigSpecificOptions config={config} onChange={setConfig} />
          ) : (
            <LayoutSelectorList onSelect={setConfig} defaultItem={defaultItem} />
          )}
        </IonList>
      </IonContent>
    </>
  );
}
