import { backOnlyClass } from "@components/layoutStyle";
import { AnnotationMode } from "fields/editors/annotationModeNav";
import { hasKanji, readingHiraganaToFuriganaHTML, toRawHiragana } from "fields/japaneseLib";
import { hintClass } from "hooks/util/useHint";
import React from "react";

const placeholder = "&nbsp;";

export interface IJapaneseToken {
  reading: string;
  surface_form: string;
}

// This generates a placeholder token to prevent layout shift.
export function nullFuriganaToken(japanese: string): IJapaneseToken[] {
  return [
    {
      surface_form: japanese,
      reading: placeholder,
    },
  ];
}

interface IProps {
  token: IJapaneseToken;
  furiganaMode: AnnotationMode | undefined;
}
export default function JapaneseWithFurigana({ token, furiganaMode }: IProps) {
  const { surface_form, reading } = token;

  let className = "";
  if (furiganaMode === "hint") {
    className = hintClass;
  } else if (furiganaMode === "back") {
    className = backOnlyClass;
  }

  const readingHiragana = toRawHiragana(reading);
  const furi = readingHiraganaToFuriganaHTML(readingHiragana, surface_form);

  // Render a placeholder if no furigana so there's no layout shift either
  // between cards or as the furigana are still loading.
  const html = hasKanji(surface_form) ? furi : placeholder;

  return (
    <ruby>
      {surface_form}
      <rt className={className} dangerouslySetInnerHTML={{ __html: html }} />
    </ruby>
  );
}
