import FieldTypeIcon from "fields/components/fieldTypeIcon";
import { IField } from "fields/fields";
import React, { MouseEvent } from "react";
import useEditFieldType from "./useEditFieldType";

interface IProps {
  field: IField;
  small?: boolean;
}
export default function FieldTypeEditorIcon({ field, small }: IProps): JSX.Element {
  const presentFieldEditor = useEditFieldType(field);
  function handleFieldTypeIconClicked(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    presentFieldEditor();
  }

  return <FieldTypeIcon type={field.type} onClick={handleFieldTypeIconClicked} small={small} />;
}
