import { IonItem, IonList, IonListHeader, IonToggle, ToggleCustomEvent } from "@ionic/react";
import L10n from "localization";
import React from "react";
import { ISettings } from "settings";

interface IProps {
  settings?: ISettings;
  updateSettings: (s: keyof ISettings, v: string | number | boolean) => void;
}
export default function EditorSettings({ settings, updateSettings }: IProps) {
  const handleExpandRichEditorToolbar = (v: boolean): void => {
    if (settings?.expandRichEditorToolbar === v) {
      return;
    }
    updateSettings("expandRichEditorToolbar", v);
  };

  const handleEnableAdvancedFontControls = (v: boolean): void => {
    if (settings?.enableAdvancedFontControls === v) {
      return;
    }
    updateSettings("enableAdvancedFontControls", v);
  };

  const handleHideCardPreview = (v: boolean): void => {
    if (settings?.alwaysHideCardPreview === v) {
      return;
    }
    updateSettings("alwaysHideCardPreview", v);
  };

  return (
    <IonList inset>
      <IonListHeader>{L10n.localize((s) => s.settings.editor)}</IonListHeader>
      <IonItem lines="none">
        <IonToggle
          checked={settings?.expandRichEditorToolbar}
          onIonChange={(e: ToggleCustomEvent) => handleExpandRichEditorToolbar(e.detail.checked)}
        >
          <span className="ion-text-wrap">
            {L10n.localize((s) => s.settings.expandRichEditorToolbar)}
          </span>
        </IonToggle>
      </IonItem>
      <IonItem lines="none">
        <IonToggle
          checked={settings?.enableAdvancedFontControls}
          onIonChange={(e: ToggleCustomEvent) => handleEnableAdvancedFontControls(e.detail.checked)}
        >
          <span className="ion-text-wrap">
            {L10n.localize((s) => s.settings.enableAdvancedFontControls)}
          </span>
        </IonToggle>
      </IonItem>
      <IonItem lines="none">
        <IonToggle
          checked={settings?.alwaysHideCardPreview}
          onIonChange={(e: ToggleCustomEvent) => handleHideCardPreview(e.detail.checked)}
        >
          <span className="ion-text-wrap">
            {L10n.localize((s) => s.settings.alwaysHideCardPreview)}
          </span>
        </IonToggle>
      </IonItem>
    </IonList>
  );
}
