import { useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";

const useHideTabBar = () => {
  useIonViewWillEnter(() => {
    const tabBarEl = document.getElementsByTagName("ion-tab-bar")?.[0];
    if (tabBarEl) {
      tabBarEl.style.display = "none";
    }
  }, []);

  useIonViewDidLeave(() => {
    const tabBarEl = document.getElementsByTagName("ion-tab-bar")?.[0];
    if (tabBarEl) {
      tabBarEl.style.display = "flex";
    }
  }, []);
};

export default useHideTabBar;
