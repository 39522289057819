import { Locales } from "locales";
import L10n from "localization";
import { IField } from "./fields";

export function fieldLang(field?: IField): BFF69 | undefined {
  if (!field) {
    return;
  }

  switch (field.type) {
    case "japanese":
      return "ja-JP";
    case "chinese":
    case "text":
    case "tts":
      return field.attributes?.lang;
  }
}

export const bff69 = [
  "ar-EG",
  "be-BY",
  "bg-BG",
  "ca-ES",
  "cs-CZ",
  "cy-GB",
  "da-DK",
  "de-DE",
  "el-GR",
  "en-AU",
  "en-GB",
  "en-US",
  "es-ES",
  "es-US",
  "et-EE",
  "eu-ES",
  "fa-IR",
  "fi-FI",
  "fr-CA",
  "fr-FR",
  "ga-IE",
  "gd-GB",
  "gl-ES",
  "he-IL",
  "hi-IN",
  "hr-HR",
  "hu-HU",
  "id-ID",
  "is-IS",
  "it-IT",
  "ja-JP",
  "ka-GE",
  "kk-KZ",
  "km-KH",
  "ko-KR",
  "lo-LA",
  "lt-LT",
  "lv-LV",
  "mi-NZ",
  "mk-MK",
  "mn-MN",
  "ms-MY",
  "ne-NP",
  "nl-NL",
  "no-NO",
  "pl-PL",
  "ps-AF",
  "pt-BR",
  "pt-PT",
  "ro-RO",
  "ru-RU",
  "sa-IN",
  "sk-SK",
  "sl-SI",
  "sm-WS",
  "so-SO",
  "sr-RS",
  "sv-SE",
  "sw-TZ",
  "th-TH",
  "tr-TR",
  "ug-CN",
  "uk-UA",
  "ur-PK",
  "uz-UZ",
  "vi-VN",
  "zh-CN",
  "zh-HK",
  "zh-TW",
] as const;

// Our format for language codes. Like BCP 47.
// e.g. en-US, zh-HK, fr-FR, ...
export type BFF69 = (typeof bff69)[number];

export function isBFF69(lang: string): lang is BFF69 {
  return bff69.includes(lang as BFF69);
}

export const validTranslationLangCodes: string[] = [
  "af-ZA",
  "ak-GH",
  "sq-AL",
  "am-ET",
  "ar-EG",
  "hy-AM",
  "as-IN",
  "ay-BO",
  "az-AZ",
  "bm-ML",
  "eu-ES",
  "be-BY",
  "bn-BD",
  "bs-BA",
  "bg-BG",
  "ca-ES",
  "ny-MW",
  "zh-CN",
  "zh-TW",
  "co-FR",
  "hr-HR",
  "cs-CZ",
  "da-DK",
  "dv-MV",
  "nl-NL",
  "et-EE",
  "ee-GH",
  "en-US",
  "tl-PH",
  "fi-FI",
  "fr-FR",
  "fy-NL",
  "gl-ES",
  "lg-UG",
  "ka-GE",
  "de-DE",
  "el-GR",
  "gn-PY",
  "gu-IN",
  "ht-HT",
  "ha-NG",
  "iw-IL",
  "hi-IN",
  "hu-HU",
  "is-IS",
  "ig-NG",
  "id-ID",
  "ga-IE",
  "it-IT",
  "ja-JP",
  "jw-ID",
  "kn-IN",
  "kk-KZ",
  "km-KH",
  "rw-RW",
  "ko-KR",
  "ku-TR",
  "ky-KG",
  "lo-LA",
  "la-VA",
  "lv-LV",
  "ln-CD",
  "lt-LT",
  "lb-LU",
  "mk-MK",
  "mg-MG",
  "ms-MY",
  "ml-IN",
  "mt-MT",
  "mi-NZ",
  "mr-IN",
  "mn-MN",
  "my-MM",
  "ne-NP",
  "no-NO",
  "or-IN",
  "om-ET",
  "ps-AF",
  "fa-IR",
  "pl-PL",
  "pt-BR",
  "pa-IN",
  "qu-PE",
  "ro-RO",
  "ru-RU",
  "sm-WS",
  "sa-IN",
  "gd-GB",
  "sr-RS",
  "st-ZA",
  "sn-ZW",
  "sd-PK",
  "si-LK",
  "sk-SK",
  "sl-SI",
  "so-SO",
  "es-ES",
  "su-ID",
  "sw-TZ",
  "sv-SE",
  "tg-TJ",
  "ta-IN",
  "tt-RU",
  "te-IN",
  "th-TH",
  "ti-ET",
  "ts-ZA",
  "tr-TR",
  "tk-TM",
  "uk-UA",
  "ur-PK",
  "ug-CN",
  "uz-UZ",
  "vi-VN",
  "cy-GB",
  "xh-ZA",
  "yo-NG",
  "zu-ZA",
  "he-IL",
  "jv-ID",
];

export const validTTSLangCodes: BFF69[] = [
  "bg-BG",
  "ca-ES",
  "cs-CZ",
  "da-DK",
  "de-DE",
  "el-GR",
  "en-AU",
  "en-GB",
  "en-US",
  "es-ES",
  "es-US",
  "fi-FI",
  "fr-CA",
  "fr-FR",
  "hi-IN",
  "hu-HU",
  "id-ID",
  "is-IS",
  "it-IT",
  "ja-JP",
  "ko-KR",
  "lv-LV",
  "ms-MY",
  "nl-NL",
  "pl-PL",
  "pt-BR",
  "pt-PT",
  "ro-RO",
  "ru-RU",
  "sk-SK",
  "sr-RS",
  "sv-SE",
  "th-TH",
  "tr-TR",
  "uk-UA",
  "vi-VN",
  "zh-CN",
  "zh-HK",
  "zh-TW",
];

const appLangToBCP47: Record<Locales, BFF69> = {
  de: "de-DE",
  en: "en-US",
  es: "es-ES",
  fr: "fr-FR",
  ja: "ja-JP",
  "pt-br": "pt-BR",
  "zh-cn": "zh-CN",
  "zh-tw": "zh-TW",
};

// Returns BFF69 lang code for current app UI language.
export function appLang(): BFF69 {
  const appLangCode = L10n.getCurrentLocale();
  return appLangToBCP47[appLangCode];
}

export function languageName(lang: BFF69): string | undefined {
  const currCode = appLang();
  const intl = new Intl.DisplayNames([currCode], { type: "language" });
  return intl.of(lang);
}

export interface ILanguage {
  code: string;
  name: string;
}

// TODO: Translate.
export function humanLanguages(): ILanguage[] {
  return bff69.map((code) => ({
    code,
    name: languageName(code) ?? code,
  }));
}

export function ttsLanguages(): ILanguage[] {
  return validTTSLangCodes
    .filter((code) => bff69.includes(code))
    .map((code) => ({
      code,
      name: languageName(code) ?? code,
    }));
}

export function translationLanguages(): ILanguage[] {
  return validTranslationLangCodes
    .filter((code) => isBFF69(code) && bff69.includes(code))
    .map((code) => {
      return {
        code,
        name: isBFF69(code) ? languageName(code) ?? code : code,
      };
    });
}

export const defaultChineseLang = "zh-CN";
export const chineseLangs: string[] = ["zh-CN", "zh-TW"];
export type ChineseLang = "zh-CN" | "zh-TW";

// humanTimeAgo returns a string like "5 minutes ago".
export function humanTimeAgo(time: Date): string | null {
  const justNowThresholdMillis = 60_000;

  const now = new Date();
  const diffMs = now.getTime() - time.getTime();

  if (isNaN(diffMs)) {
    return null;
  }

  const oneMinMillis = 1000 * 60;
  const oneHourMillis = oneMinMillis * 60;
  const oneDayMillis = oneHourMillis * 24;

  let since: string;

  // Say "just now" if the time was really recent.
  if (diffMs < justNowThresholdMillis) {
    since = L10n.localize((s) => s.general.justNow);
  } else if (diffMs < oneHourMillis) {
    const diffMins = diffMs / oneMinMillis;
    const formatter = new Intl.RelativeTimeFormat(appLang(), { style: "narrow" });
    since = formatter.format(-Math.floor(diffMins), "minutes");
  } else if (diffMs < oneDayMillis) {
    const diffHours = diffMs / oneHourMillis;
    const formatter = new Intl.RelativeTimeFormat(appLang(), { style: "narrow" });
    since = formatter.format(-Math.floor(diffHours), "hours");
  } else {
    const diffDays = diffMs / oneDayMillis;
    const formatter = new Intl.RelativeTimeFormat(appLang(), { style: "narrow" });
    since = formatter.format(-Math.floor(diffDays), "days");
  }

  return since;
}
