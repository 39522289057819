export type Bit = 0 | 1;
export type SideVisibility = Bit[];

export const SIDES: Record<string, SideVisibility> = {
  BOTH: [1, 1],
  FRONT: [1, 0],
  BACK: [0, 1],
};

export function parseSides(sidesAttr: string | undefined): SideVisibility {
  if (!sidesAttr) {
    return SIDES.BACK;
  }

  const sides: SideVisibility = [];
  for (let i = 0; i < sidesAttr.length; i++) {
    const b = sidesAttr[i];
    switch (b) {
      case "0":
        sides.push(0);
        break;
      case "1":
        sides.push(1);
        break;
      default:
        return SIDES.BACK;
    }
  }

  return sides;
}

export function sidesToBitString(sides: SideVisibility): string {
  return sides.map((bit) => new String(bit)).join("");
}
