import { useMacAppStoreElectronBackgroundPurchaseHandler } from "electronIAP";
import EventBus from "eventBus";
import useErrorAlert from "hooks/util/useErrorAlert";
import IAPv2, { IAPProductPrices, IBuyProductResponse, hexToUUIDv4 } from "iap";
import { useCallback } from "react";
import { IIAPProduct } from "./ios";
import PDPLogger from "./logger";

interface IPurchaseHandlerProps {
  logger: PDPLogger;
  onPurchaseSuccess: () => void;
  onPurchaseFailure: (err?: string) => void;
  onPurchasePending: () => void;
  iapProducts?: IAPProductPrices;
  userID: string;
  showPurchaseFailError?: (error: unknown) => void;
}

export function genPurchaseHandler({
  logger,
  onPurchaseFailure,
  onPurchaseSuccess,
  onPurchasePending,
  iapProducts,
  userID,
  showPurchaseFailError,
}: IPurchaseHandlerProps) {
  return (product: IIAPProduct) => {
    const extraLogData = {
      product: product.iapProductId,
      products: iapProducts,
    };
    logger.log("purchase_initiated", extraLogData);

    function handlePluginPurchaseSuccess(resp: IBuyProductResponse) {
      switch (resp.state) {
        case "cancelled": {
          logger.log("purchase_cancelled", extraLogData);
          EventBus.emit("iap", "cancelled");
          return;
        }
        case "success": {
          logger.log("purchase_complete", extraLogData);
          onPurchaseSuccess();
          return;
        }
        case "pending": {
          logger.log("purchase_pending", { extraLogData, ...resp });
          onPurchasePending();
          return;
        }
        default: {
          logger.log("iap_buy_complete", { extraLogData, ...resp });
          onPurchaseSuccess();
          return;
        }
      }
    }

    function handlePluginPurchaseError(err: string) {
      logger.log("purchase_failed", { ...extraLogData, err });
      showPurchaseFailError?.(err);
      onPurchaseFailure(err);
    }

    const userIdUUID = hexToUUIDv4(userID);
    if (userIdUUID === null) {
      handlePluginPurchaseError("Invalid userId");
      return;
    }
    return IAPv2.buyProduct({ productId: product.iapProductId, userIdUUID })
      .then(handlePluginPurchaseSuccess)
      .catch(handlePluginPurchaseError);
  };
}

export default function useIAPPurchaseHandler({
  logger,
  onPurchaseFailure,
  onPurchaseSuccess,
  onPurchasePending,
  iapProducts,
  userID,
}: IPurchaseHandlerProps): (product: IIAPProduct) => void {
  const [showPurchaseFailError] = useErrorAlert({ code: "PURCHASING_IAP" });

  const handleBackgroundPurchaseSuccess = useCallback(() => {
    // In the Electron IAP purchase flow (StoreKit 1), the IAP purchase handler
    // just gets notified that the purchase is pending, so we need another
    // listener to determine completion.
    logger.log("purchase_complete");
    onPurchaseSuccess();
  }, [onPurchaseSuccess, logger]);

  useMacAppStoreElectronBackgroundPurchaseHandler(
    handleBackgroundPurchaseSuccess,
    onPurchaseFailure,
  );

  return useCallback(
    (product: IIAPProduct) =>
      genPurchaseHandler({
        logger,
        onPurchaseFailure,
        onPurchaseSuccess,
        onPurchasePending,
        iapProducts,
        userID,
        showPurchaseFailError,
      })(product),
    [
      iapProducts,
      logger,
      onPurchaseFailure,
      onPurchaseSuccess,
      onPurchasePending,
      showPurchaseFailError,
      userID,
    ],
  );
}
