import React from "react";
import Style from "style";

export default function VSplit(props: {
  left: React.ReactElement;
  right: React.ReactElement;
  leftBasis?: string;
}) {
  const { left, right } = props;
  const leftBasis = props.leftBasis ?? "30%";

  const dividerStyle = {
    backgroundColor: Style.colors.divider,
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", maxHeight: "100%" }}>
      <div
        style={{
          flexGrow: 0,
          flexShrink: 0,
          flexBasis: leftBasis,
          maxHeight: "100%",
          overflow: "auto",
        }}
      >
        {left}
      </div>
      <div style={{ flexGrow: 0, flexShrink: 0, flexBasis: Style.hairline, ...dividerStyle }} />
      <div style={{ flex: 1, maxHeight: "100%", overflow: "auto" }}>{right}</div>
    </div>
  );
}
