import L10n from "localization";
import React from "react";
import Style from "style";

function Instructions() {
  return (
    <div
      className="card-edit-instructions"
      style={{
        color: Style.colors.actionableFg,
        fontStyle: "italic",
        fontWeight: 300,
        margin: 4,
        fontSize: 22,
      }}
    >
      {L10n.localize((s) => s.card.clickToEdit)}
    </div>
  );
}

interface IProps {
  label: string;
  onBlur: () => void;
  onClick: () => void;
  children: JSX.Element;
}

export const editableValueClassName = "editable-value";

export default function EditableValueWrapper({
  label,
  onClick,
  onBlur,
  children,
}: IProps): JSX.Element {
  const editorDivRef = React.useRef<HTMLDivElement>(null);

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    evt.stopPropagation();
    onClick();
  };

  const editableColor = Style.colors.actionableFg;

  const style: React.CSSProperties = {
    position: "relative",
    padding: 8,
    paddingTop: 18,
    marginBottom: 8,
    marginLeft: 8,
    marginRight: 8,
    border: `1px dashed ${editableColor}`,
    borderRadius: 6,
    transition: "border 0.5s linear",
    overflow: "auto",
    cursor: "pointer",
  };

  const labelStyle: React.CSSProperties = {
    color: editableColor,
    fontSize: 12,
    position: "absolute",
    top: 6,
    left: 16,
    transition: "color 0.5s linear",

    // HACK: prevent Card's Layout styling from messing too much with label's style.
    fontStyle: "initial",
    fontWeight: "initial",
    lineHeight: "initial",
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="card-edit-label" style={labelStyle}>
        {label}
      </div>
      <div ref={editorDivRef} style={style} onClick={handleClick} onBlur={onBlur}>
        <div className={editableValueClassName}>{children ?? <Instructions />}</div>
      </div>
    </div>
  );
}
