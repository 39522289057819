import idb, { IInboxItem } from "@data/idb";
import { blobHash } from "blobs/lib";
import EventBus from "eventBus";
import { ID } from "types/ID";

let queuedBlobSha256: string | undefined;

export namespace Inbox {
  export function queueItemForGeneration(item: IInboxItem) {
    queuedBlobSha256 = item.sha256;
  }

  export function clearItemQueuedForGeneration() {
    queuedBlobSha256 = undefined;
  }

  export function isItemQueuedForGeneration(): boolean {
    return queuedBlobSha256 !== undefined;
  }

  export async function getItemQueuedForGeneration(): Promise<IInboxItem | undefined> {
    if (!queuedBlobSha256) {
      return;
    }
    return idb.db.get("inbox", queuedBlobSha256);
  }

  export async function getInboxItems(): Promise<IInboxItem[]> {
    const items = await idb.db.getAll("inbox");
    const sorted = items.sort((i1, i2) => i2.created_at.getTime() - i1.created_at.getTime());
    return sorted;
  }

  export async function addBlobToInbox(blob: Blob) {
    const sha256 = await blobHash(blob);
    await idb.db.put("inbox", { sha256, blob, created_at: new Date() });
    EventBus.emit("inboxUpdated", { sha256 });
  }

  export async function deleteItem(item: IInboxItem) {
    await idb.db.delete("inbox", IDBKeyRange.only(item.sha256));
    EventBus.emit("inboxUpdated", { sha256: item.sha256 });
  }

  export async function markItemSaved(item: IInboxItem, deckID: ID) {
    await idb.db.put("inbox", { ...item, saved_at: new Date(), saved_to_deck_id: deckID });
    EventBus.emit("inboxUpdated", { sha256: item.sha256 });
  }
}
