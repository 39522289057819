import NamePromptModal from "@components/namePromptModal";
import { IName, getEmptyNamePlaceholder, getName } from "@data/stores/name";
import { IonIcon, IonItem, IonLabel, IonNote } from "@ionic/react";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { personOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";

export default function ProfileNameItem() {
  const [name, setName] = React.useState<IName | undefined>(undefined);
  const [refreshCounter, setRefreshCounter] = React.useState(0);

  React.useEffect(() => {
    getName().then((name) => setName(name));
  }, [refreshCounter]);

  const placeholder = getEmptyNamePlaceholder();

  function handleDismiss() {
    setRefreshCounter(refreshCounter + 1);
  }

  const [showModal] = useIonCardModalWithDismiss(NamePromptModal, {
    canCancel: true,
    onDismiss: handleDismiss,
  });

  const isEmpty = !name?.first && !name?.last;
  const value = isEmpty
    ? L10n.localize((s) => s.general.none)
    : `${name?.first ?? placeholder.first} ${name?.last ?? ""}`;

  return (
    <IonItem detail button onClick={showModal}>
      <IonIcon icon={personOutline} slot="start" />
      <IonLabel>{L10n.localize((s) => s.general.name)}</IonLabel>
      <IonNote slot="end">{value}</IonNote>
    </IonItem>
  );
}
