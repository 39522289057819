import { IKnol, Knol } from "@models/knol";
import cardPriority from "./cardPriority";
import { Grade } from "./grade";

export const gradesByIncreasingPriority = [
  "NEW",
  "F",
  "E",
  "D",
  "C",
  "B",
  "A",
  "AA",
  "AAA",
] as const;
export type Histogram = {
  [K in (typeof gradesByIncreasingPriority)[number]]: number;
};

export function cardPriorities(cards: IKnol[]): Array<[string, number]> {
  const priorities: Array<[string, number]> = [];
  for (const card of cards) {
    priorities.push([card.id, cardPriority(Knol.GetGrade(card))]);
  }
  return priorities;
}

export function gradeHistogram(cards: IKnol[]): Histogram {
  const histogram: Histogram = { NEW: 0, AAA: 0, AA: 0, A: 0, B: 0, C: 0, D: 0, E: 0, F: 0 };

  for (const cardPriority of cardPriorities(cards)) {
    const priority = cardPriority[1];
    const g = Grade(priority);
    if (g) {
      histogram[g] += 1;
    }
  }
  return histogram;
}
