// iosAvailableIAPProductsMetadata returns the intersection of products offered via iOS IAP and products server knows about.

import useErrorAlert from "hooks/util/useErrorAlert";
import { useEffect, useState } from "react";
import { IIAPProduct, IProductsState } from "./ios";
import { frequencyFor } from "./lib";
import PDPLogger from "./logger";
import { IServerProduct } from "./types";
import IAPv2, { IAPProductPrices } from "iap";

// iOS IAP has very short limit on product metadata, so this join is necessary.
export function iosAvailableIAPProductsMetadata(
  serverProducts: IServerProduct[],
  iapProducts: IAPProductPrices,
): IIAPProduct[] {
  const metadata: Record<string, IIAPProduct> = {};

  // Populate that metadata map.
  for (const serverMeta of serverProducts) {
    const serverProductId = serverMeta.id;
    const iapProductId = iTunesProductIDFor(serverProductId);
    if (iapProductId) {
      const iapPrice = iapProducts[iapProductId];
      if (iapPrice) {
        metadata[iapProductId] = {
          ...serverMeta,
          iapProductId: iapProductId,
          display_price: iapPrice,
          frequency: frequencyFor(serverProductId),
        };
      }
    }
  }

  return Object.values(metadata);
}

function iTunesProductIDFor(serverID: string): string | undefined {
  // NOTE: this is a switch statement rather than a simple property lookup
  // in a const object, because JS autoformatters like to strip the quotes
  // off from around constant property names, which could result in a minifier
  // getting clever and minifying those property names. Don't want that.
  switch (serverID) {
    case "unlimited_year":
      return "unlimited_yearly";
    case "unlimited_lifetime":
      return "unlimited_lifetime";
  }
}

export function productDescriptionFor(products: IIAPProduct[]): string {
  // HACK: to condense the subscription UI so everthing fits on an iPhone,
  // I'm just showing the product description for one product,
  // because they have identical features just different durations.
  return products[0]?.description ?? "";
}

export async function fetchIAPProducts(serverProducts: IServerProduct[]): Promise<IProductsState> {
  return new Promise((resolve, reject) => {
    function handleIAPPluginProductsFetchFailed() {
      reject();
    }

    function handleIAPPluginProductsFetchSuccess(iapProducts: IAPProductPrices) {
      const products = iosAvailableIAPProductsMetadata(serverProducts, iapProducts);
      const description = productDescriptionFor(products);
      resolve({ description, products, iapProducts });
    }

    IAPv2.getProducts()
      .then(handleIAPPluginProductsFetchSuccess)
      .catch(handleIAPPluginProductsFetchFailed);
  });
}

export function useIAPProducts(
  logger: PDPLogger,
  serverProducts: IServerProduct[] | undefined,
  onPurchaseFailure: (err?: string) => void,
): IProductsState | undefined {
  const [productsState, setProductsState] = useState<IProductsState>();
  const [showError] = useErrorAlert({ code: "FETCHING_IAPS" });

  useEffect(() => {
    if (serverProducts === undefined) {
      return;
    }
    fetchIAPProducts(serverProducts)
      .then((state) => {
        logger.log("apple_iap_products_fetched", { ...state });
        setProductsState(state);
      })
      .catch((err) => {
        logger.log("apple_iap_products_fetch_failed");
        showError(err);
        onPurchaseFailure();
      });
  }, [logger, onPurchaseFailure, serverProducts, showError]);

  return productsState;
}
