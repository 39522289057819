import React from "react";
import { IonButton, IonSelect, IonSelectOption, SelectCustomEvent } from "@ionic/react";
import { IconType } from "react-icons";

interface SortOption<T = Value> {
  key: string;
  val: T;
  cssClass?: string;
}

type Value = number | string;
interface ISelectOptionButtonProps<T = Value> {
  onSelect: (val: T) => void;
  border?: boolean;
  value?: T;
  options: SortOption<T>[];
  style?: React.CSSProperties;
  icon?: IconType;
  disabled?: boolean;
  size?: number;
  children: React.ReactNode;
}

export default function SelectOptionButton<T = Value>({
  border,
  onSelect,
  value,
  options,
  style,
  children,
  disabled = false,
}: ISelectOptionButtonProps<T>): JSX.Element {
  const selectRef = React.useRef<HTMLIonSelectElement>(null);

  return (
    <>
      <IonSelect
        disabled={disabled}
        interfaceOptions={{ cssClass: "select-option-popup", reference: "event" }}
        style={{ display: "none" }}
        ref={selectRef}
        label=""
        onIonChange={(e: SelectCustomEvent) => {
          if (!e.detail.value) {
            return;
          }
          const { value } = e.detail;
          onSelect(value);
        }}
        interface="popover"
        value={value}
      >
        {options.map((o: SortOption<T>, i: number) => {
          return (
            <IonSelectOption
              className={o.cssClass}
              key={i}
              value={o.val}
              onClick={() => {
                onSelect(o.val);
              }}
            >
              {o.key}
            </IonSelectOption>
          );
        })}
      </IonSelect>
      <IonButton
        disabled={disabled}
        color="secondary"
        size="small"
        style={{
          "--padding-end": "6px",
          ...style,
        }}
        fill={border ? "outline" : "clear"}
        onClick={(evt: any) => {
          selectRef.current?.open(evt);
        }}
      >
        {children}
      </IonButton>
    </>
  );
}
