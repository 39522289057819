import { DeckFields } from "@models/deck";
import React, { useCallback } from "react";
import ConfigItem from "./configItem";
import { IConfigSelection } from "./main";

interface IProps {
  selected?: boolean;
  base?: string;
  name?: string;
  fields: DeckFields;
  values?: Record<string, string>;
  description?: string;
  onSelect: (cfg: IConfigSelection) => void;
  backOnly?: boolean;
}

function ConfigSelectorItem(props: IProps): JSX.Element {
  const { fields, base, onSelect, name, description, values } = props;
  const handleClick = useCallback(
    () => onSelect({ fields, base, name, description, values }),
    [base, description, fields, name, onSelect, values],
  );

  return <ConfigItem {...props} onClick={handleClick} />;
}

export default React.memo(ConfigSelectorItem);
