export function shuffle<T>(array: T[]): T[] {
  if (array.length < 1) {
    return [];
  }
  // Randomize array element order in-place.
  // Using Fisher-Yates shuffle algorithm.
  // From http://stackoverflow.com/a/12646864.
  for (let i = array.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}
