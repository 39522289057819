import { Capacitor } from "@capacitor/core";
import { Style as SBStyle, StatusBar } from "@capacitor/status-bar";
import color from "color";
import EventBus from "eventBus";
import Globals from "globals";
import React from "react";
import { IoBan, IoStar, IoStarOutline } from "react-icons/io5";

export const StarOutlineIcon = () => <IoStarOutline color="#ffd600" />;
export const StarIcon = () => <IoStar color="#ffd600" />;
export const IgnoreIcon = () => <IoBan color="#FF4136" />;
export const IgnoreInactiveIcon = () => <IoBan />;

interface Window {
  devicePixelRatio: number;
  matchMedia: any;
}

declare let window: Window;

const HAIRLINE =
  typeof window !== "undefined" && window.devicePixelRatio > 1.0 && Globals.platform === "ios"
    ? "0.5px"
    : "1px";

const supportsPrefersColorScheme: boolean =
  typeof window !== "undefined" ? window.matchMedia?.("(prefers-color-scheme)").matches : false;

const gradeColors = {
  MARKED: "rgb(240,240,0)",
  IGNORED: "rgb(145, 145, 145)",
  NEW: "#585394",
  F: "#EB3223",
  E: "#EC5445",
  D: "#E27465",
  C: "#ffc409",
  B: "#5295C2",
  A: "#68CD67",
  AA: "rgb(20,180,0)",
  AAA: "rgb(0,250,0)",
} as const;

const responseColors = {
  fail: "rgb(229, 64, 40)",
  hard: "rgb(241, 141, 5)",
  good: "rgb(0, 161, 203)",
  easy: "rgb(97, 174, 36)",
  auto: "#AAAAAA",
} as const;

const dayTheme = {
  actionableFg: "#25A3FC", // links, buttons, etc...
  actionableMutedFg: "#E6EBFF", // disabled items
  actionableBg: "#3C8FFE", // selected items
  touchBg: color("#3C8FFE").alpha(0.2).string(),
  invalidFg: "red",
  dangerousFg: "red",
  primaryFg: "#000000", // titles, labels, ...
  secondaryFg: "#616161", // non-essential details
  mutedFg: "#616161", // instructions, placeholders
  disabledFg: "#DDDDDD", // disabled content
  primaryBg: "#FFFFFF", // body
  secondaryBg: "#F5F5F5", // cards, list items
  headerBg: "#f8f8f8", // header
  subheaderBg: "#E5E5E5",
  headerFg: "rgb(68, 68, 68)", // screen title
  primaryBarBg: "#f8f8f8", // menu, config bars
  secondaryBarBg: "#f8f8f8", // sub-config bars
  divider: "#ddd",
  barBorder: `${HAIRLINE} solid #ddd`,
  cardBorder: `${HAIRLINE} solid rgba(0,0,0,0.05)`,
  itemMutedBg: "rgba(0,0,0,0.1)",
  backgroundColor: "#FFFFFF",
  boxShadow: "rgba(0, 0, 0, 0.3)",
  dialogShadeColor: "rgba(0,0,0,0.4)",
  ...responseColors,
  neutralBar: "green",
  ttsBg: "lavender",
  grades: gradeColors,
};

type StyleTheme = typeof dayTheme;

const nightTheme: StyleTheme = {
  actionableFg: "#BF7EC6",
  actionableMutedFg: "#8681B5",
  actionableBg: "#8681B5",
  touchBg: "#432A4D",
  invalidFg: "#E25F4A",
  dangerousFg: "#EC5445",
  primaryFg: "#F2F2F2",
  secondaryFg: "#707074",
  mutedFg: "#8F8F94",
  disabledFg: "#8F8F94",
  primaryBg: "#121212",
  secondaryBg: "#1C1C1E",
  headerBg: "#121312",
  subheaderBg: "#39393D",
  headerFg: "#F2F2F2",
  primaryBarBg: "#1C1C1E",
  secondaryBarBg: "#313136",
  divider: "#101011",
  barBorder: `${HAIRLINE} solid #141415`,
  cardBorder: `${HAIRLINE} solid #141415`,
  itemMutedBg: "#3E4044",
  backgroundColor: color(dayTheme.primaryBg).negate().string(),
  boxShadow: "#919195",
  dialogShadeColor: "rgba(0,0,0,0.4)",
  ...responseColors,
  neutralBar: "#BF7EC6",
  ttsBg: "rgba(120,0,155, 0.2)",
  grades: gradeColors,
};

const colorThemes: Record<string, StyleTheme> = {
  day: dayTheme,
  night: nightTheme,
};

export const automaticColorSchemeKey = "automatic";

export type Theme = keyof typeof colorThemes | typeof automaticColorSchemeKey;

const platformDefaultTheme = () => {
  return supportsPrefersColorScheme
    ? window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "night"
      : "day"
    : "day";
};

const colorThemeLocalStorageKey = "AnkiApp.settings.colorScheme";

const getDefaultTheme = (): Theme => {
  if (typeof window === "undefined") {
    return platformDefaultTheme(); // Return default theme if not in a browser environment
  }
  const persisted = localStorage.getItem(colorThemeLocalStorageKey);

  if (persisted === automaticColorSchemeKey) {
    return platformDefaultTheme();
  }

  if (persisted !== null && persisted in colorThemes) {
    return persisted as Theme;
  }

  return platformDefaultTheme();
};

const currentTheme = getDefaultTheme();

const headerHeightPx = 44;
export const footerHeightPx = 56;

class Style {
  themes = colorThemes;
  colors = colorThemes[currentTheme];
  currentTheme = currentTheme;
  defaultTheme = getDefaultTheme();
  headerHeightPx = headerHeightPx;
  headerHeight = headerHeightPx + "px";
  footerHeightPx = footerHeightPx;
  footerHeight = footerHeightPx + "px";
  subFooterHeight = "5rem";
  edgePadding = "0.5rem";
  imageBlobMaxHeight = "70vh";
  imageBlobMaxWidth = "100%";
  imageBlobLoadingOpacity = 0.2;
  hairline = HAIRLINE;

  getSupportsPrefersColorScheme() {
    return supportsPrefersColorScheme;
  }

  resetTheme() {
    localStorage.removeItem(colorThemeLocalStorageKey);
    this.setTheme(platformDefaultTheme());
  }

  getPersistedTheme() {
    return localStorage.getItem(colorThemeLocalStorageKey) || automaticColorSchemeKey;
  }

  setStatusBar(theme: string = this.currentTheme) {
    if (Capacitor.isPluginAvailable("StatusBar")) {
      if (Globals.platform === "android") {
        // Android status bar is placed above app, so let it color itself normally.
        StatusBar.setStyle({ style: SBStyle.Default });
      } else if (theme === "night") {
        StatusBar.setStyle({ style: SBStyle.Dark });
      } else {
        StatusBar.setStyle({ style: SBStyle.Light });
      }
    }
  }

  setTheme(theme = getDefaultTheme()) {
    const _currentTheme = this.currentTheme;

    if (theme === automaticColorSchemeKey) {
      theme = platformDefaultTheme();
    }

    if (theme in colorThemes) {
      this.currentTheme = theme;
      this.colors = colorThemes[theme];

      // Enable dark mode for Ionic components.
      // See https://ionicframework.com/docs/theming/dark-mode#ionic-dark-theme.
      // NOTE: this relies on colors set in app.css.
      document.body.classList.toggle("dark", theme === "night");

      this.setStatusBar(theme);

      if (_currentTheme !== theme) {
        EventBus.emit("themeChange");
      }
    }
  }
}

export function isDarkMode(): boolean {
  return getDefaultTheme() === "night";
}

const style = new Style();

export const StyleContext = React.createContext(style);

export default style;
