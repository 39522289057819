import React from "react";
import "./banner.css";

interface IProps {
  children: React.ReactNode;
}
export default function Banner({ children }: IProps): JSX.Element {
  return (
    <div
      className="header-banner"
      style={{
        // HACK: these are necessary so that the IonTitle doesn't block clicks on the banner.
        position: "sticky",
        zIndex: 31337,
      }}
    >
      {children}
    </div>
  );
}
