import React from "react";
import NestedNav from "@components/nestedNav";
import L10n from "localization";
import highlightjs from "./licenses/highlight.js.txt";
import kuromoji from "./licenses/kuromoji.txt";
import cccedict from "./licenses/cc-cedict.txt";

interface ILicense {
  component: string;
  text: string;
}

const licenses: ILicense[] = [
  {
    component: "highlight.js",
    text: highlightjs,
  },
  {
    component: "kuromoji",
    text: kuromoji,
  },
  {
    component: "CC-CEDICT",
    text: cccedict,
  },
];

export default function Licenses(): JSX.Element {
  const sep = <pre>================================================</pre>;
  return (
    <NestedNav title={L10n.localize((s) => s.profile.licenses)}>
      <div style={{ padding: 8 }}>
        <div style={{ marginBottom: 12 }}>
          AnkiApp © 2013-{new Date().getFullYear()} AnkiApp Inc. All rights reserved.
        </div>
        {licenses.map(({ component, text }, i) => {
          const isLast = i === licenses.length - 1;
          return (
            <div key={component}>
              <pre>{component}</pre>
              <div style={{ fontFamily: "monospace" }}>{text}</div>
              {!isLast ? sep : undefined}
            </div>
          );
        })}
      </div>
    </NestedNav>
  );
}
