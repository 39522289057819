import {
  InputCustomEvent,
  IonInput,
  IonItem,
  IonList,
  IonListHeader,
  IonNavLink,
} from "@ionic/react";
import L10n from "localization";
import React, { useRef } from "react";
import { FieldType, fieldTypeMap, IField } from "fields/fields";
import FieldTypeSelector from "../components/fieldTypeSelector";
import FieldTypeListItem from "fields/components/fieldTypeListItem";
import { IFieldEditorProps } from "fields/fieldType";
import { defaultFieldName, hasDefaultFieldNameForType } from "fields/lib";
import { SourcePipelineNode } from "../sources/types";
import SourceSelectorItem from "../sourceEditors/sourceSelectorItem";

interface IProps extends IFieldEditorProps<IField> {
  children?: React.ReactNode;
}
export default function FieldEditorGeneric({
  field,
  fields,
  onChange,
  creatingConfig,
  children,
}: IProps): JSX.Element {
  function handleNameChange(newName: string) {
    onChange({ ...field, name: newName });
  }

  // HACK: The IonNavLink will create a closure that freezes handleTypeChange in time, so that even after changing fields, the fields as seen by handleTypeChange won't change. Because handleTypeChange just needs to know the fields in order to compute an appropriate default name upon changing a field to a new type, we can give it all it needs by just excluding the current field from the initial set of fields, and computing default field names based upon that set, to avoid name collisions.
  const initName = useRef(field.name);
  const fieldsSansInitialOne = fields.filter((f) => f.name !== initName.current);

  function handleTypeChange(type: FieldType) {
    const newFieldType = fieldTypeMap[type];
    const newField = newFieldType.convert(field);

    // Name field same as type, if it previously had the default name for its type.
    let name = field.name;
    if (creatingConfig && hasDefaultFieldNameForType(field.name, field.type)) {
      name = defaultFieldName(type, fieldsSansInitialOne);
    }

    onChange({ ...newField, name });
  }

  function handleSourcePipelineSelect(source: SourcePipelineNode) {
    onChange({ ...field, source });
  }

  const duplicateName =
    fields.filter((f) => field !== undefined && f.name === field.name).length > 1;

  return (
    <IonList>
      <IonItem>
        <IonInput
          label={L10n.localize((s) => s.general.name)}
          placeholder={L10n.localize((s) => s.general.required)}
          autoFocus
          value={field.name}
          disabled={!creatingConfig}
          onIonInput={(e: InputCustomEvent) => handleNameChange(e.detail.value ?? "")}
          clearInput
          color={duplicateName ? "danger" : undefined}
        />
      </IonItem>

      <IonListHeader>{L10n.localize((s) => s.field.type)}</IonListHeader>
      <IonNavLink
        routerDirection="forward"
        component={() => (
          <FieldTypeSelector
            selectedType={field.type}
            onSelect={handleTypeChange}
            creatingConfig={creatingConfig}
          />
        )}
      >
        <FieldTypeListItem type={field.type} detail />
      </IonNavLink>

      {/* Attributes */}
      {children}

      <SourceSelectorItem onSelect={handleSourcePipelineSelect} field={field} fields={fields} />
    </IonList>
  );
}
