import Device from "device";
import { FieldType, IField } from "fields/fields";
import Globals from "globals";
import { decode } from "html-entities";

export function defaultHumanLang(): string {
  return navigator.language;
}

export class TranslationError extends Error {}

interface ITranslationResponse {
  translatedText: string;
  destination: string;
  source?: string;
}
export async function renderTranslation(
  text: string,
  targetLang = defaultHumanLang(),
  sourceLang?: string,
): Promise<ITranslationResponse | undefined> {
  const token = Device.getToken();
  if (!token) {
    return;
  }
  if (text === "") {
    return {
      translatedText: "",
      destination: targetLang,
      source: sourceLang,
    };
  }
  const fetchURL = new URL(`${Globals.translationEndpoint}/translate`);
  fetchURL.searchParams.set("t", token);
  fetchURL.searchParams.set("q", text);
  if (sourceLang) {
    fetchURL.searchParams.set("source", sourceLang);
  }
  fetchURL.searchParams.set("target", targetLang);

  const resp = await fetch(fetchURL, { headers: {} });
  if (!resp.ok) {
    throw new TranslationError();
  }
  const json = await resp.json();
  const { translatedText, destination, source } = json;
  if (!translatedText || !destination || !source) {
    return undefined;
  }
  return { translatedText: decode(translatedText), destination, source };
}

export function isValidTranslationSourceField(field: IField): boolean {
  const validSourceFieldTypes: FieldType[] = ["text", "japanese", "chinese", "tts"];
  return validSourceFieldTypes.includes(field.type);
}
