import { IonItem, IonLabel, IonNavLink, IonNote } from "@ionic/react";
import L10n from "localization";
import React from "react";
import FontSizeNav from "./fontSizeNav";

interface IProps {
  fontSizePx: number;
  onChange: (px: number) => void;
}
export default function FontSizeSelectorItem({ fontSizePx, onChange }: IProps) {
  return (
    <IonNavLink
      routerDirection="forward"
      component={() => <FontSizeNav fontSizePx={fontSizePx} onFontSizeChange={onChange} />}
    >
      <IonItem button>
        <IonLabel>{L10n.localize((s) => s.general.fontSize)}</IonLabel>
        <IonNote slot="end">{fontSizePx}px</IonNote>
      </IonItem>
    </IonNavLink>
  );
}
