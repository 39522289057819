import React from "react";

// Regular expressions for parsing tags and their attributes.
const tagNameRegexString = "([-a-zA-Z0-9:]+)";
const attrKeyRegexString = "([-a-zA-Z0-9:_]+)";
const attrValRegexString = new RegExp(`\
(?:\
(?:"([^"]*)")|\
(?:'([^']*)')|\
(?:[“”]([^“”]*)[“”])|\
(?:[‘’]([^‘’]*)[‘’])|\
(?:([^\\s\\/>]+))\
)\
`).source;

const tagAttrsRegexString = new RegExp(`\
${attrKeyRegexString}\
\\s*\
=\
\\s*\
${attrValRegexString}\
`).source;

const startTagRegexString = new RegExp(`^\
<\
${tagNameRegexString}\
((?:\\s+(?:${tagAttrsRegexString}))*)\
\\s*\
\\/?>\
`).source;

const endTagRegexString = new RegExp(`^\
</\
(${tagNameRegexString})\
\\s*\
>\
`).source;

const commentTagRegexString = new RegExp(`^\
<!--\
[^]*?\
-->\
`).source;

const START_TAG_REGEX = new RegExp(startTagRegexString);
const END_TAG_REGEX = new RegExp(endTagRegexString);
const COMMENT_TAG_REGEX = new RegExp(commentTagRegexString);
const ATTR_REGEX = new RegExp(tagAttrsRegexString, "g");
const WHITESPACE_REGEX = /^\s+$/;

// These are elements that don't have a closing tag.
// http://www.w3.org/html/wg/drafts/html/master/#void-elements
const VOID_ELEMENTS = [
  "area",
  "base",
  "br",
  "col",
  "embed",
  "hr",
  "img",
  "input",
  "keygen",
  "link",
  "menuitem",
  "meta",
  "param",
  "source",
  "track",
  "wbr",
];

// The <font> tag is not supported by React or HTML5 (http://www.w3.org/TR/html5/obsolete.html#non-conforming-features).
// This plugin converts the <font> tag to be a <span> that has the <font>'s color and size applied via CSS.
interface IFontCompiler extends INodeCompiler {
  sizeToPercent: {
    [key: string]: string;
  };
}

const FontCompiler: IFontCompiler = {
  sizeToPercent: {
    1: "50%",
    2: "80%",
    3: "100%",
    4: "112.5%",
    5: "150%",
    6: "200%",
    7: "300%",
  },
  match(elName: string, attributes) {
    return elName === "font";
  },
  compile(elName: string, attributes, children, recursiveCompiler, keyGen) {
    const percentSize = this.sizeToPercent?.[attributes.size];

    return (context) => {
      // Apply the font tags color, size, and family attributes using CSS.
      const explicitStyle = attributes.style ?? {};
      const style = {
        ...explicitStyle,
        color: attributes.color ?? explicitStyle.color,
        fontSize: percentSize ?? explicitStyle.fontSize,
        fontFamily: attributes.face ?? explicitStyle.fontFamily,
      };

      const props = {
        key: keyGen(),
        style,
      };

      return React.createElement("span", props, children(context));
    };
  },
};

// TODO: strongly type this (hard because it's recursive)
export type ReplateContext = any;

export interface INodeCompiler {
  match(elName: string, attributes: any): boolean;
  compile(
    elName: string,
    attributes: any,
    children: any,
    recursiveCompiler: any,
    keyGen: any,
  ): (context: ReplateContext) => any;
}

// type ContextResolverFunc = (context: ReplateContext) => any;
// type CompileFunc = (input: string) => { children: ContextResolverFunc; remainder: string } | ContextResolverFunc;
type CompileFunc = (input: string) => any; // TODO;

export interface IBodyCompiler {
  regex: RegExp;
  compile: (match: RegExpMatchArray, recursiveCompile: CompileFunc, keyGen: () => number) => (context: ReplateContext) => JSX.Element | null;
}

interface ICompilerOpts {
  body?: IBodyCompiler[];
  node?: INodeCompiler[];
  whitelist?: string[];
  blacklist?: string[];
}

class Compiler {
  static htmlAttrToReactAttr(attrName: string, attrValue: string) {
    // Returns a { key, value } map of given attr, transformed for React.

    // From https://github.com/facebook/react/blob/0.12-stable/src/browser/ui/dom/HTMLDOMPropertyConfig.js#L169.
    let orig;
    let react;
    const DOMAttributeNames: Record<string, string> = {
      acceptCharset: "accept-charset",
      className: "class",
      htmlFor: "for",
      httpEquiv: "http-equiv",
    };
    for (react in DOMAttributeNames) {
      orig = DOMAttributeNames[react];
      if (attrName === orig) {
        return { name: react, value: attrValue };
      }
    }

    // From https://github.com/facebook/react/blob/0.12-stable/src/browser/ui/dom/HTMLDOMPropertyConfig.js#L175.
    const DOMPropertyNames: Record<string, string> = {
      autoCapitalize: "autocapitalize",
      autoComplete: "autocomplete",
      autoCorrect: "autocorrect",
      autoFocus: "autofocus",
      autoPlay: "autoplay",
      encType: "enctype",
      hrefLang: "hreflang",
      radioGroup: "radiogroup",
      spellCheck: "spellcheck",
      srcDoc: "srcdoc",
      srcSet: "srcset",
    };
    for (react in DOMPropertyNames) {
      orig = DOMPropertyNames[react];
      if (attrName === orig) {
        return { name: react, value: attrValue };
      }
    }

    const styleMap: Record<string, string> = {} as Record<string, string>;
    // Special case handling.
    switch (attrName) {
      case "style":
        for (const pair of Array.from((attrValue || "").split(/\s*;\s*/)) as string[]) {
          if (pair === "") {
            continue;
          }
          const [key, val] = Array.from(pair.split(/:\s*/));
          const camelize = (str: string): string => {
            return str.replace(/-./g, match => match.charAt(1).toUpperCase());
          };

          styleMap[camelize(key)] = val;
        }

        return {
          name: "style",
          value: styleMap,
        };
      default:
        return {
          name: attrName,
          value: attrValue,
        };
    }
  }

  static startTagName(startTagRegexMatch: RegExpMatchArray) {
    return startTagRegexMatch[1];
  }

  static startTagAttributes(startTagRegexMatch: RegExpMatchArray) {
    let match;
    let name;
    let value;
    const attrString = startTagRegexMatch[2];

    // Extract raw attributes.
    const rawAttrs: Record<string, any> = {};
    while ((match = ATTR_REGEX.exec(attrString)) != null) {
      name = match[1];

      // One or the other will be undefined depending on what type of quoting was used.
      value = match[2] || match[3] || match[4] || match[5] || match[6];

      rawAttrs[name] = value;
    }

    // Convert raw attributes to React-friendly ones.
    const attrs: Record<string, any> = {};
    for (const key in rawAttrs) {
      if(key == "onclick") {
        continue
      }
      const val = rawAttrs[key];
      ({ name, value } = this.htmlAttrToReactAttr(key, val));
      attrs[name] = value;
    }

    return attrs;
  }

  static startTagIsSelfClosed(startTagRegexMatch: RegExpMatchArray) {
    let needle;
    const tagName = this.startTagName(startTagRegexMatch);

    const tagString = startTagRegexMatch[0];
    const endsWithForwardSlash = tagString[tagString.length - 2] === "/";

    return (
      ((needle = tagName.toLowerCase()), Array.from(VOID_ELEMENTS).includes(needle)) ||
      endsWithForwardSlash
    );
  }

  static endTagRegex(startTagName: string) {
    return new RegExp(`^</\s*${startTagName}\s*>`, "i");
  }

  static wrapChildrenWithContext(children: any) {
    if (children.length === 0) {
      return () => null;
    }
    if (children.length === 1) {
      return (context: ReplateContext) => children[0](context);
    }
    return (context: ReplateContext) => Array.from(children).map((child: any) => child(context));
  }

  bodyCompilers: IBodyCompiler[]; // TODO: type better.
  nodeCompilers: INodeCompiler[];
  tagWhitelist: string[] | undefined;
  tagBlacklist: string[];
  currentKey: number;

  constructor(options: ICompilerOpts = { body: [], node: [] }) {
    this.bodyCompilers = options.body ?? [];
    this.nodeCompilers = [FontCompiler, ...options.node ?? []];
    this.tagWhitelist = options.whitelist;
    this.tagBlacklist = options.blacklist ?? ["script"];
    this.currentKey = 0;
  }

  // Globally-unique (within this compiler) key generator.
  nextKey() {
    const key = this.currentKey;
    this.currentKey += 1;
    return key;
  }

  tagIsPermitted(tagName: string) {
    if (this.tagBlacklist && this.tagBlacklist.includes(tagName)) {
      return false;
    }
    return (this.tagWhitelist === undefined || this.tagWhitelist.includes(tagName));
  }

  // Parameters:
  //   - input is a template string to compile.
  //   - currentTagName is the current tag the compiler is within.
  //
  // Returns either:
  //   - (context) -> null,
  //   - (context) -> ReactElement, or
  //   - (context) -> array of ReactElements.
  compile(input: string, currentTagName: string | null = null) {
    let unmatchedChars: string;
    if (typeof input !== "string") {
      return () => null;
    }

    const children: any[] = [];

    // If this is the initial call, strip surrounding whitespace.
    if (currentTagName === null) {
      input = input.trim();
    }

    // Edge case: empty input.
    if (input.length < 1) {
      if (currentTagName != null) {
        return {
          children: Compiler.wrapChildrenWithContext(children),
          remainder: "",
        };
      }
      return Compiler.wrapChildrenWithContext(children);
    }

    // const currentEndTagRegex = Compiler.endTagRegex(currentTagName);

    unmatchedChars = ""; // This accumulates input characters that are not matched by parsers.

    const makeTextChild = (text: string) => {
      const hasLeadingSpace = text.startsWith(" ");
      const entityTransformed = new DOMParser().parseFromString(text, "text/html").body.textContent;
      return () => hasLeadingSpace ? " " + entityTransformed : entityTransformed;
    };

    // Appends previously-unmatched chars onto children as a string.
    const consumeUnmatchedChars = () => {
      if (!(unmatchedChars.length > 0)) {
        return;
      }
      // console.log "Consuming unmatched chars: #{unmatchedChars}."

      if (unmatchedChars.match(WHITESPACE_REGEX) != null) {
        // This is all whitespace; just discard it.
      } else {
        // Record the current unmatchedChars (frozen in a closure) as a new child.
        const child = makeTextChild(unmatchedChars);
        children.push(child);
      }

      // Reset unmatchedChars.
      unmatchedChars = "";
      return unmatchedChars;
    };

    const consumeMatchedInput = (regexMatch: RegExpMatchArray) => (input = input.substring(regexMatch[0].length));

    const makeChild = (tagName: string, attrs: Record<string, any>, children: (context: ReplateContext) => React.ReactNode) => (context: ReplateContext) =>
      React.createElement(tagName, attrs, children(context));
    const runFirstMatchingBodyCompiler = () => {
      // Attempt to run all compilers against the input.
      // If a match is found, try running the compilers again, starting with the
      // new input that is left over after running the matched compiler.

      for (const compiler of Array.from(this.bodyCompilers) as any[]) {
        let match;
        if ((match = input.match(compiler.regex)) != null) {
          // console.log "Using pluggable compiler: #{match[0]}."

          consumeUnmatchedChars();
          consumeMatchedInput(match);
          children.push(
            compiler.compile(
              match,
              (input: string) => this.compile(input),
              () => this.nextKey(),
            ),
          );

          // Recurse, in case the following input matches another compiler.
          runFirstMatchingBodyCompiler();
          return;
        }
      }
    };

    // Consume input string, recursing into tags as necessary.
    while (input.length > 0) {
      let child;
      let match;
      runFirstMatchingBodyCompiler();
      if (!(input.length > 0)) {
        break;
      }

      if (input.indexOf("</") === 0 && (match = input.match(END_TAG_REGEX)) != null) {
        // console.log "Closing tag: #{currentTagName}."

        let needle;
        const closingTagName = match[1];

        if (closingTagName === currentTagName) {
          // The current tag is being closed.

          consumeUnmatchedChars();
          consumeMatchedInput(match);

          // Pop compilation results up the call stack.
          return {
            children: Compiler.wrapChildrenWithContext(children),
            remainder: input,
          };
        }
        if (((needle = closingTagName.toLowerCase()), Array.from(VOID_ELEMENTS).includes(needle))) {
          // This "closing tag" is really just a self-closing tag.

          consumeMatchedInput(match);

          // Fabricate a result that mimics behavior of a tag with no children.
          child = makeChild(closingTagName, { key: this.nextKey() }, (context: ReplateContext) => null);
          children.push(child);
        } else {
          // This closing tag is not paired with an opening tag.
          // console.log "Encountered an unopened closing tag: #{closingTagName}."

          // Solution: pretend that it was just opened.

          // Subsume previous text content within this auto-paired tag.
          const subChildren = (() => {
            if (unmatchedChars.length > 0) {
              const subChild = makeTextChild(unmatchedChars);

              // Reset unmatchedChars.
              unmatchedChars = "";

              return [subChild];
            }
            return [];
          })();

          child = makeChild(
            closingTagName,
            { key: this.nextKey() },
            Compiler.wrapChildrenWithContext(subChildren),
          );
          children.push(child);

          consumeMatchedInput(match);
        }
      } else if (
        input[0] === "<" &&
        input[1] === "!" &&
        (match = input.match(COMMENT_TAG_REGEX)) != null
      ) {
        // console.log "Consuming comment."

        consumeUnmatchedChars();
        consumeMatchedInput(match);
      } else if (input[0] === "<" && (match = input.match(START_TAG_REGEX)) != null) {
        // console.log "Starting tag: #{Compiler.startTagName(match)}."

        consumeUnmatchedChars();
        consumeMatchedInput(match);

        // Parse the tag's structure.
        const tagName = Compiler.startTagName(match);
        const tagAttrs = Compiler.startTagAttributes(match);
        const isSelfClosed = Compiler.startTagIsSelfClosed(match);

        // Add a unique, React key into attributes.
        const attrs = Object.assign({}, tagAttrs, { key: this.nextKey() });

        const result: any = !isSelfClosed
          ? // Recursively compile the tag.
            this.compile(input, tagName)
          : {
              // Fabricate a result that mimics behavior of a tag with no children.
              children() {
                return null;
              },
              remainder: input,
            };

        // Consume the node's contents and closing tag.

        // Check if this tag is permitted by security policy;
        // if not, discard its contents.
        if (this.tagIsPermitted(tagName)) {
          // First, try pluggable node compilers.
          child = undefined;
          for (const compiler of Array.from(this.nodeCompilers)) {
            if (compiler.match(tagName, tagAttrs)) {
              child = compiler.compile(
                tagName,
                attrs,
                result.children,
                (input: string) => this.compile(input),
                () => this.nextKey(),
              );
              break;
            }
          }

          // If no pluggable compiler matched, default to making a React Element.
          if (child == null) {
            child = makeChild(tagName, attrs, result.children);
          }

          children.push(child);
        }

        input = result.remainder;
      } else {
        // "Body" (either root, or innards of a tag).
        unmatchedChars += input[0];
        input = input.substring(1);
      }
    }

    if (currentTagName != null) {
      // We've reached the end of input without closing the current tag.

      consumeUnmatchedChars();

      // console.log "Auto-closing unclosed tag: #{currentTagName}."
      return {
        children: Compiler.wrapChildrenWithContext(children),
        remainder: input,
      };
    }

    // At this point, we've reached the end of input, at the top-level.

    consumeUnmatchedChars();

    return Compiler.wrapChildrenWithContext(children);
  }
}

export default Compiler;
