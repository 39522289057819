import { useLoadingText } from "@components/loadingText";
import { IonTextarea, TextareaCustomEvent } from "@ionic/react";
import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import { fieldTypeMap } from "fields/fields";
import useAutoFocusIonTextarea from "fields/hooks/useAutoFocusIonTextarea";
import useSetLangIonTextarea from "fields/hooks/useSetLangIonTextarea";
import useValueEditorSourcePipeline from "fields/sources/useSourcePipeline";
import { IFieldChinese } from "fields/types/chinese";
import L10n from "localization";
import React, { useRef } from "react";
// const tokenize = require('chinese-tokenizer').loadFile('./cedict_ts.u8')

export default function FieldValueEditorChinese({
  field,
  name,
  value,
  onChange,
  editorRef,
  autoFocus,
  editable,
}: IFieldValueEditorProps<IFieldChinese>): JSX.Element {
  const parsedVal = value !== undefined ? fieldTypeMap.chinese.loadFML(value) : undefined;
  const chinese = parsedVal?.chinese ?? "";

  function handleChineseChange(newChinese: string) {
    const fml = fieldTypeMap.chinese.dumpFML({ chinese: newChinese });
    onChange(name, fml);
  }

  const pipelineUpdatePending = useValueEditorSourcePipeline(field, onChange);
  const loadingText = useLoadingText(
    pipelineUpdatePending,
    L10n.localize((s) => s.general.processing),
  );

  const ref = useRef<HTMLIonTextareaElement>(null);
  useAutoFocusIonTextarea(autoFocus, ref.current);
  useSetLangIonTextarea("zh", ref);

  const disabled = !editable || pipelineUpdatePending;

  return (
    <FieldValueEditorItem
      name={name}
      field={field}
      editorRef={editorRef}
      onTextPaste={disabled ? undefined : handleChineseChange}
    >
      <IonTextarea
        autoGrow
        ref={ref}
        disabled={disabled}
        value={pipelineUpdatePending ? loadingText : chinese}
        spellCheck
        lang="zh"
        debounce={800}
        rows={1}
        placeholder={L10n.localize((s) => s.general.clickToEdit)}
        onIonInput={(evt: TextareaCustomEvent) => {
          if (!editable) {
            return;
          }
          const val = evt.target.value;
          handleChineseChange(val ?? "");
        }}
      />
    </FieldValueEditorItem>
  );
}
