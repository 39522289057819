import { showPrompt } from "@components/prompt";
import { DeckFields } from "@models/deck";
import { useFieldEditorModal } from "fields/editors/fieldEditorModal";
import { IField } from "fields/fields";
import { FieldsMutation } from "fields/hooks/useFieldsReducer";
import L10n from "localization";
import React from "react";
import FieldListItem from "./fieldListItem";

interface IProps {
  field: IField;
  disabled?: boolean;
  fields: DeckFields;
  dispatch: React.Dispatch<FieldsMutation>;
  isExistingDeck?: boolean;
}
export default function EditableFieldListItem({
  field,
  fields,
  disabled,
  dispatch,
  isExistingDeck,
}: IProps): JSX.Element {
  const { name } = field;

  function handleRemove(fieldName: string) {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.field.confirmRemove),
      promptType: "confirm",
      callback: () => {
        dispatch({ type: "remove", fieldName });
      },
    });
  }

  function handleSave(newField: IField) {
    dispatch({ type: "modify", fieldName: name, field: newField });
  }

  const [present] = useFieldEditorModal({
    initField: field,
    fields,
    name,
    nameEditable: !isExistingDeck,
    onSave: handleSave,
  });

  return (
    <FieldListItem
      key={name}
      field={field}
      onRemove={() => handleRemove(name)}
      dispatch={dispatch}
      disabled={disabled}
      onClick={disabled ? undefined : present}
    />
  );
}
