import { IPromptOptions } from "@components/prompt";
import { AlertButton, AlertInput, IonAlert, IonicSafeString } from "@ionic/react";
import L10n from "localization";
import React, { KeyboardEvent } from "react";

interface IAlertModalProps {
  promptProps: IPromptOptions | null;
  onDidDismiss: () => void;
  onDidPresent: () => void;
  isOpen: boolean;
}
export default function AlertModal(props: IAlertModalProps): JSX.Element {
  const { onDidDismiss, onDidPresent, isOpen, promptProps } = props;

  const titleText = promptProps?.title || L10n.localize((s) => s.actions.confirm);
  const acceptText =
    promptProps?.dangerousConfirmOkButtonText ||
    promptProps?.accept ||
    L10n.localize((s) => s.actions.ok);
  const cancelText = promptProps?.cancel || L10n.localize((s) => s.actions.cancel);

  function isValid(val?: string) {
    if (!promptProps?.validationRegex) {
      return true;
    }

    return !!val?.match(promptProps?.validationRegex);
  }

  // The ref is necessary because Ionic doesn't seem to handle updates to the cssClass on IonAlert,
  // so it's necesary to imperatively modify.
  const ref = React.useRef<HTMLIonAlertElement>(null);
  function handleResponseChange(newVal?: string) {
    if (!ref.current) {
      return;
    }
    ref.current.cssClass = `aa-alert-modal ${!isValid(newVal) ? "alert-invalid" : ""}`;
  }

  function handleOk(inputs: string[]) {
    // Ionic's API for alerts is that the callback for the OK button--this
    // function, receives an array holding the values in each input field in
    // the alert. Currently, we have at most 1 input field in an "alert", and
    // 0 input fields in the case of a confirm or simple alert popup. So if
    // the first input is present, we use that, otherwise the default.
    const val = inputs?.[0] || promptProps?.default;

    if (!isValid(val)) {
      return false; // Prevents button from submitting.
    }

    // setTimeout allows a prompt's callback to show another prompt.
    setTimeout(() => {
      if (typeof val === "string") {
        // Blank out any inputs.
        if (ref.current) {
          ref.current.querySelectorAll("input").forEach((input) => (input.value = ""));
        }
        promptProps?.callback?.(val);
      }
    }, 0);
  }

  const buttons: AlertButton[] = [
    {
      text: acceptText,
      handler: handleOk,
      role: promptProps?.dangerousConfirmOkButtonText ? "destructive" : undefined,

      // HACK: can't change this in response to isValid, so instead applying
      // a class on the top-level IonAlert component, that will combine with
      // this one to disable the button when appropriate.
      cssClass: "potentially-disabled-alert-button",
    },
  ];

  const promptType = promptProps?.promptType ?? "input";

  if (promptType === "confirm" || promptType === "input") {
    buttons.unshift({
      text: cancelText,
      role: "cancel",
      handler: promptProps?.cancelCallback,
    });
  }

  const suggestions = promptProps?.suggestions ?? [];
  const listId = "alert-modal-suggestions-list";

  const inputs: AlertInput[] = [];
  if (promptType === "input") {
    inputs.push({
      type: promptProps?.inputType,
      cssClass: "potentially-invalid-alert-input",
      attributes: {
        onInput: (evt: any) => {
          handleResponseChange(evt?.target?.value);
        },
        value: promptProps?.default,
        onKeyDown: (evt: KeyboardEvent) => {
          // Manually implement submit-on-press-enter.
          if (evt.key === "Enter" && promptProps?.inputType !== "textarea") {
            handleOk([(evt.target as any)?.value]);
            onDidDismiss();
          }
        },
        list: listId,
      },
    });
  }

  function handlePresent() {
    // Auto-focus on input field.
    const input = ref.current?.getElementsByTagName("input")?.[0];
    setTimeout(() => input?.focus(), 0);

    onDidPresent();
  }

  return (
    <>
      <IonAlert
        ref={ref}
        translucent
        animated={false}
        cssClass={!isValid(promptProps?.default) ? "alert-invalid" : undefined}
        isOpen={isOpen}
        onDidDismiss={onDidDismiss}
        onDidPresent={handlePresent}
        header={titleText}
        message={new IonicSafeString(promptProps?.prompt ?? "")}
        buttons={buttons}
        inputs={inputs}
      />
      <datalist id={listId}>
        {suggestions.map((s) => (
          <option key={s} value={s} />
        ))}
      </datalist>
    </>
  );
}
