import React from "react";
import Network from "network";
import { showPrompt } from "@components/prompt";
import L10n from "localization";
import ShareModal from "@components/shareModal";
import logEvent from "analytics";

interface IShareModalProps {
  deckID: string;
  dismiss: () => void;
  onSuccess: () => void;
}
export default function DeckShareModal(props: IShareModalProps): JSX.Element {
  const { deckID, dismiss, onSuccess } = props;

  React.useEffect(() => {
    logEvent("deck_share_showing_modal", { deckID });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const shareHistory =
    localStorage["AnkiApp.shareHistory"] != null
      ? JSON.parse(localStorage["AnkiApp.shareHistory"])
      : [];

  function sendShare(emails: string[], groupIDs: string[]) {
    Network.fetch("POST", "/decks/share", {
      deck_id: deckID,
      emails: emails,
      groups: groupIDs.map((id) => ({ id })),
    })
      .then(onSuccess)
      .catch(() => {
        return showPrompt({
          title: L10n.localize((s) => s.general.attention),
          prompt: L10n.localize((s) => s.error.communication),
          promptType: "alert",
        });
      });
  }

  const lastHistoryIndex = shareHistory.length - 1;
  const prevShareEmails = shareHistory[lastHistoryIndex]?.response?.split(",") ?? [];

  function handleShare(emails: string[], groupIDs: string[]) {
    logEvent("deck_share_handling_share", {
      deckID,
      numEmails: emails.length,
      numGroups: groupIDs.length,
    });

    if (emails.length > 0) {
      const now = new Date().toISOString();
      shareHistory.push({
        response: emails.join(","),
        deck_id: deckID,
        timestamp: now,
      });
      localStorage.setItem("AnkiApp.shareHistory", JSON.stringify(shareHistory));
    }

    sendShare(emails, groupIDs);
    dismiss();
  }

  return <ShareModal defaultEmails={prevShareEmails} onShare={handleShare} onDismiss={dismiss} />;
}
