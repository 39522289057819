import { showPrompt } from "@components/prompt";
import Device from "device";
import { IDBPTransaction, StoreNames } from "idb";
import { updateStudyGoalBadgeCount } from "studyGoals";
import Lib from "../lib";
import L10n from "../localization";
import Network, { NetworkError } from "../network";

type ExtractObjectParameters<OT extends ObjectType, OTType extends OperationType> = Extract<
  Op,
  { object_type: OT; type: OTType }
>["object_parameters"];

export interface IBaseOperation {
  id: string;
  timestamp: string;
  device_id: string;
  object_type: ObjectType;
  created_at: string;
  type: OperationType;
  object_parameters: ExtractObjectParameters<ObjectType, OperationType>;
}

export interface ISyncStatus {
  statusCode: number;
  statusText: string;
  timestamp: string;
  isSyncing: boolean;
}

export type Op =
  | deck.DeckOperation
  | deck_tag.DeckTagOperation
  | knol.KnolOperation
  | knol_tag.KnolTagOperation
  | response.ResponseOperation
  | user_settings.UserSettingsOperation
  | subscription.SubscriptionOperation
  | ContentReportOperation;

// Appliers.
import idb, { IDBAppSchema } from "@data/idb";
import { ContentReportOperation } from "@operations/contentReport";
import BlobStore from "data/idb/blobStore";
import EventBus from "eventBus";
import * as content_report from "operations/contentReport";
import * as deck from "operations/deck";
import * as deck_share from "operations/deckShare";
import * as deck_tag from "operations/deckTag";
import * as knol from "operations/knol";
import * as knol_tag from "operations/knolTag";
import * as response from "operations/response";
import * as subscription from "operations/subscription";
import * as user_settings from "operations/userSettings";
import { User } from "./user";

const LAST_SYNC_STATUS_KEY = "AnkiApp.Operations.lastSyncStatus";

// NOTE: if you add a new operation object type, you must add it here and in the appliers var.
type ObjectType =
  | "content_report"
  | "deck"
  | "deck_share"
  | "deck_tag"
  | "knol"
  | "knol_tag"
  | "response"
  | "subscription"
  | "user_settings";

export type OperationType = "INSERT" | "UPDATE" | "DELETE";

type OperationCallbackFunc = (() => void | Promise<void>) | void;

// Utility to force exhaustive switch statement.
function assertNever(value: never): never {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
}

const appliers = {
  deck,
  deck_share,
  deck_tag,
  content_report,
  knol,
  knol_tag,
  response,
  subscription,
  user_settings,
} as const;

let scheduleID: NodeJS.Timeout | number | undefined = undefined;

const outstandingSyncRequestAborters = new Set<AbortController>();
const callbacksHistoryMap = new Map<string, boolean>();

export namespace Operation {
  export let isSyncing = false;
  export function cancelOutstandingSyncRequests(): void {
    for (const req of outstandingSyncRequestAborters) {
      req.abort();
    }
    clearTimeout(scheduleID);
    isSyncing = false;
  }

  export function getLastSyncStatus(): ISyncStatus | undefined {
    let parsedStatus: ISyncStatus | undefined;
    const s = localStorage.getItem(LAST_SYNC_STATUS_KEY);
    if (s && s !== "") {
      parsedStatus = JSON.parse(s);
    }
    return parsedStatus;
  }

  function setLastSyncStatus(statusCode: number, statusText: string): void {
    const val = {
      statusCode: statusCode,
      statusText: statusText,
      timestamp: new Date().toISOString(),
    };
    localStorage.setItem(LAST_SYNC_STATUS_KEY, JSON.stringify(val));
  }

  export async function unsynced(): Promise<Op[]> {
    try {
      return idb.db.getAll("operations");
    } catch {
      return [];
    }
  }

  export async function count(): Promise<number> {
    return idb.db.count("operations");
  }

  /**
   * Approach: Interleave remote operations with local operations which
   *   occurred after the remote ones, and execute them in-order. This
   *   prevents older remote operations from overwriting newer local ones.
   */
  export async function doSync(): Promise<void> {
    if (!User.isLoggedIn()) {
      return;
    }
    isSyncing = true;
    EventBus.emit("sync_begin");
    const operationIDs: string[] = [];
    const localOps: Op[] = await idb.db.getAll("operations");

    const urlPath = "/operations/";
    const aborter = new AbortController();
    try {
      let responseInserted = false;

      const [operations, { status }] = await Network.fetchWithMetadata<Op[]>({
        action: "POST",
        path: urlPath,
        data: localOps,
        aborter,
      });
      if (status === 204) {
        return;
      }

      const remoteTimestamp = operations?.[0]?.timestamp;
      const mergedOps = [...localOps, ...operations].sort((a, b) =>
        a.timestamp.localeCompare(b.timestamp),
      );
      const tx = idb.db.transaction(Array.from(idb.db.objectStoreNames), "readwrite");
      const opStore = tx.objectStore("operations");

      for (const op of mergedOps) {
        operationIDs.push(op.id);
        if (typeof op.object_parameters === "string") {
          op.object_parameters = JSON.parse(op.object_parameters);
        }
        if (remoteTimestamp && op.timestamp >= remoteTimestamp) {
          await operate(false, [op], tx);
          const { type, object_type } = op;
          if (!responseInserted && type === "INSERT" && object_type === "response") {
            responseInserted = true;
          }
          await opStore.delete(op.id);
        }
      }
      for (const id of operationIDs) {
        await opStore.delete(id);
      }
      await tx.done;
      EventBus.emit("sync", operations || []);
      outstandingSyncRequestAborters.add(aborter);
      setLastSyncStatus(200, "ok");

      if (responseInserted) {
        updateStudyGoalBadgeCount();
      }

      await BlobStore.uploadLocalBlobs();
    } catch (e) {
      if (e instanceof NetworkError && e.statusCode === 400) {
        showPrompt({
          prompt: L10n.localize((s) => s.sync.clientError400Logout),
          promptType: "alert",
          title: L10n.localize((s) => s.general.attention),
          callback: async () => {
            await User.logout();
          },
        });
        setLastSyncStatus(e.statusCode, "");
      } else if (e instanceof NetworkError && e.statusCode >= 500 && e.statusCode <= 599) {
        showPrompt({
          prompt: L10n.localize((s) => s.sync.serverError500),
          promptType: "alert",
          title: L10n.localize((s) => s.general.attention),
        });
        setLastSyncStatus(e.statusCode, "error");
      } else {
        // Don't show user facing message for error
        // that could be temporary or due to network offline.
        setLastSyncStatus(0, "offline");
      }
    } finally {
      isSyncing = false;
      EventBus.emit("sync_complete", getLastSyncStatus());
      outstandingSyncRequestAborters.delete(aborter);
    }
  }

  export async function syncOverride(): Promise<void> {
    if (scheduleID) {
      clearTimeout(scheduleID);
    }
    return doSync();
  }

  export function scheduleSync(): void {
    if (scheduleID) {
      clearTimeout(scheduleID);
    }
    scheduleID = Lib.after(5000, () => {
      doSync().catch(() => {
        // TODO: handle failure.
      });
    });
  }

  async function apply<T extends Op>(
    op: T,
    tx: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
  ): Promise<OperationCallbackFunc> {
    switch (op.object_type) {
      case "content_report": {
        return; // Do nothing.
      }
      case "deck": {
        switch (op.type) {
          case "INSERT":
            return appliers[op.object_type][op.type](op, tx);
          case "UPDATE":
            return appliers[op.object_type][op.type](op, tx);
          case "DELETE":
            return appliers[op.object_type][op.type](op, tx);
          default:
            return;
        }
      }
      case "deck_tag":
        switch (op.type) {
          case "INSERT":
            return appliers[op.object_type][op.type](op, tx);
          case "UPDATE":
            return appliers[op.object_type][op.type](op, tx);
          case "DELETE":
            return appliers[op.object_type][op.type](op, tx);
          default:
            return;
        }
      case "knol": {
        switch (op.type) {
          case "INSERT":
            return appliers[op.object_type][op.type](op, tx);
          case "UPDATE":
            return appliers[op.object_type][op.type](op, tx);
          case "DELETE":
            return appliers[op.object_type][op.type](op, tx);
          default:
            return;
        }
      }
      case "knol_tag": {
        switch (op.type) {
          case "INSERT":
            return appliers[op.object_type][op.type](op, tx);
          case "DELETE":
            return appliers[op.object_type][op.type](op, tx);
          default:
            return;
        }
      }
      case "response": {
        switch (op.type) {
          case "INSERT":
            return appliers[op.object_type][op.type](op, tx);
          default:
            return;
        }
      }
      case "subscription": {
        switch (op.type) {
          case "INSERT":
            return appliers[op.object_type][op.type]();
          default:
            return;
        }
      }
      case "user_settings": {
        switch (op.type) {
          case "UPDATE":
            return appliers[op.object_type][op.type](op);
          case "DELETE":
            return appliers[op.object_type][op.type]();
          default:
            return;
        }
      }
      default:
        return assertNever(op);
    }
  }

  /** Applies an operation specified in this schema:
   * {
   *   id: OPERATION_GUID,
   *   type: ('SELECT', 'INSERT', 'UPDATE', 'DELETE'),
   *   timestamp: ISO8601_UTC_TIMESTAMP,
   *   device_id: DEVICE_GUID,
   *   object_type: OBJECT_TYPE_STRING,
   *   object_parameters: { OPERATION_SPECIFIC_PARAMETERS }
   * }
   */
  export async function operate(
    save: boolean,
    operations: Op[],
    providedTx?: IDBPTransaction<IDBAppSchema, StoreNames<IDBAppSchema>[], "readwrite">,
  ): Promise<void> {
    const localCallbackMap = new Map<string, OperationCallbackFunc>();
    const tx = providedTx ?? idb.db.transaction(Array.from(idb.db.objectStoreNames), "readwrite");
    for (const op of operations) {
      if (save) {
        await tx.objectStore("operations").put({
          ...op,
          object_parameters: JSON.stringify(op.object_parameters),
        } as Op);
      }
      const callback = await apply(op, tx);
      if (callback) {
        localCallbackMap.set(op.id, callback);
      }
    }
    if (!providedTx) {
      await tx.done;
    }
    if (localCallbackMap.size > 0) {
      for (const [id, callback] of localCallbackMap.entries()) {
        if (!callbacksHistoryMap.has(id)) {
          callback?.();
        }
        callbacksHistoryMap.set(id, true);
      }
    }
  }

  export async function operateAndSave(operation: Op): Promise<void> {
    await operate(true, [operation]);
    scheduleSync();
  }

  interface IOperationDefaults {
    id: string;
    timestamp: string;
    device_id: string;
    created_at: string;
  }
  export function operationDefaults(): IOperationDefaults {
    const now = new Date().toISOString();

    return {
      id: Lib.uuid16(),
      timestamp: now,
      created_at: now,
      device_id: Device.getID(),
    };
  }
}
