import { User } from "@models/user";
import { IGroup, IMember } from "./types";

export function sortMembers(members: IMember[]): IMember[] {
  return members.sort((m1, m2) => {
    const [r_24h_1, r_24h_2] = [
      m1.activity.last_24h_reviews_count,
      m2.activity.last_24h_reviews_count,
    ];
    if (r_24h_1 < r_24h_2) {
      return 1;
    }

    if (r_24h_1 > r_24h_2) {
      return -1;
    }

    const [r_7d_1, r_7d_2] = [m1.activity.last_7d_reviews_count, m2.activity.last_7d_reviews_count];
    if (r_7d_1 < r_7d_2) {
      return 1;
    }

    if (r_7d_1 > r_7d_2) {
      return -1;
    }

    return m1.email < m2.email ? 1 : -1;
  });
}

export function userIsOwner(group: IGroup) {
  const { members } = group;
  const me = members.find((member) => member.email === User.email());
  return me?.role === "creator";
}
