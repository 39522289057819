import { SlugContext } from "@components/screen";
import UnlimitedIcon from "@components/unlimitedIcon";
import { IonButton } from "@ionic/react";
import { logAction } from "analytics/action";
import { useAsyncValFromPromiseState } from "hooks/util/useAsyncValState";
import useHasUnlimited from "hooks/util/useHasUnlimited";
import L10n from "localization";
import React from "react";
import usePDP from "unlimited/pdp";
import { User } from "@models/user";

const numResponsesThreshold = 425;

export default function UpgradeBanner({
  narrow,
  showButton = true,
}: {
  narrow?: boolean;
  showButton?: boolean;
}): JSX.Element | null {
  const hasUnlimited = useHasUnlimited();
  const slugContext = React.useContext(SlugContext);
  const [numResponses, fetchNumResponses] = useAsyncValFromPromiseState<number>();
  React.useEffect(() => {
    fetchNumResponses(User.getNumberOfResponsesForUser());
  }, [fetchNumResponses]);

  const [showPDP] = usePDP({
    source: "banner",
    reason: undefined,
  });

  const show =
    hasUnlimited.hasUnlimited === false &&
    typeof numResponses.val === "number" &&
    numResponses.val > numResponsesThreshold;

  React.useEffect(() => {
    if (show) {
      logAction({
        obj: "upgrade_banner",
        verb: "view",
        state: "done",
        loc: slugContext,
      });
    }
  }, [show, slugContext]);

  function handleUpgrade() {
    showPDP();
  }

  if (!show) {
    return null;
  }

  return (
    <div
      onClick={handleUpgrade}
      style={
        showButton ? { display: "flex", flexDirection: "row", alignItems: "center" } : undefined
      }
    >
      <UnlimitedIcon
        style={
          showButton
            ? { height: 32, marginTop: 4, marginBottom: 2 }
            : { height: "initial", marginTop: 0, marginBottom: 0 }
        }
        narrow={narrow}
      />
      {showButton && (
        <IonButton
          // NOTE: no onClick handler here because it's handled on the top-level <div>.
          size="small"
          style={{
            marginLeft: 8,
            height: 25,
          }}
        >
          {L10n.localize((s) => s.account.upgradeNow)}
        </IonButton>
      )}
    </div>
  );
}
