import {
  IonButton,
  IonButtons,
  IonContent,
  IonNote,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import L10n from "localization";
import React from "react";

interface IProps {
  title: string;
  children: React.ReactNode;
  description?: string;
  dismiss: () => void;
}

export default function SettingsModal(props: IProps): JSX.Element {
  const { title, children, dismiss, description } = props;

  return (
    <IonPage>
      <IonToolbar>
        <IonTitle>{title}</IonTitle>
        <IonButtons slot="primary">
          <IonButton onClick={dismiss}>{L10n.localize((s) => s.general.done)}</IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent color="light" style={{ marginBottom: "env(safe-area-inset-bottom)" }}>
        {children}
        {description ? (
          <IonNote>
            <p className="ion-margin-horizontal ion-text-wrap">{description}</p>
          </IonNote>
        ) : undefined}
      </IonContent>
    </IonPage>
  );
}
