import { fieldMap } from "@models/deck";
import { IField } from "fields/fields";
import { fieldLang } from "fields/lang";
import { DeckFieldMap } from "fields/lib";
import { defaultHumanLang } from "fields/sources/translation";
import L10n from "localization";
import React from "react";
import { manualSourcePipelineNode, SourcePipelineNode } from "../sources/types";

function SourcePipelineNode({
  node,
  fmap,
}: {
  node: SourcePipelineNode;
  fmap: DeckFieldMap;
}): JSX.Element {
  if (node === manualSourcePipelineNode) {
    return <div>{L10n.localize((s) => s.field.sourceManual)}</div>;
  }

  switch (node.type) {
    case "ref": {
      const field = fmap[node.name];
      const lang = fieldLang(field) ?? defaultHumanLang();
      if (lang) {
        return (
          <div>
            {node.name} <span style={{ fontFamily: "monospace" }}>{lang}</span>
          </div>
        );
      }
      return <div>{node.name}</div>;
    }
    case "translation":
      return <div>{L10n.localize((s) => s.general.translation)}</div>;
  }
}

interface IProps {
  fields: IField[];
  field: IField;
}

export default function SourcePipeline({ fields, field }: IProps): JSX.Element {
  const fmap = fieldMap(fields);

  const nodes: SourcePipelineNode[] = [];
  const maxDepth = 100;
  let node = field.source;
  let i = 0;
  while (node && i < maxDepth) {
    nodes.push(node);
    i += 1;

    switch (node.type) {
      case "translation": {
        node = node.source;
        break;
      }
      default:
        node = undefined;
    }
  }

  const lang = fieldLang(field);

  return (
    <div style={{ textAlign: "center" }}>
      {nodes.reverse().map((node, i) => {
        return (
          <div key={i}>
            <SourcePipelineNode node={node} fmap={fmap} />
            <div style={{ paddingLeft: 6 }}>&darr;</div>
          </div>
        );
      })}
      <div>
        {L10n.localize((s) => s.field.this)} ({field.name}
        {lang ? <span style={{ fontFamily: "monospace", marginLeft: 4 }}>{lang}</span> : undefined})
      </div>
    </div>
  );
}
