import IDB, {
  IDB_TRUE,
  IQueuedBlob,
  IQueuedBlobSansDeckID,
  IStoredBlob,
  ITypedBlobURL,
} from "@data/idb";
import { TTSRenderParams } from "@lib/ttsParamsHash";
import logEvent from "analytics";
import {
  downloadAndSaveBlobInWorker,
  downloadBlobsWithMaxConcurrency,
} from "blobs/backgroundBlobDownload";
import { uploadBlobsInBackground } from "blobs/backgroundBlobUpload";
import { downloadAndSaveBlobImmediately, downloadBlob } from "blobs/blobNetwork";
import Device from "device";
import {
  handleIndexedDBRefreshRequiredError,
  isIndexedDBRefreshRequiredError,
} from "errors/indexedDBRefreshRequired";
import { downloadTTSBlob } from "lib/tts";
import { getNetworkCredentials } from "network";
import { ID } from "types/ID";
// but here I expose a boolean instead so it's more natural for JS.
interface IBlobInsert extends Omit<IStoredBlob, "pendingSave"> {
  doUpload?: boolean;
  pendingSave?: boolean;
}

interface IGetBlobParams {
  id: ID;
  persistDownloadedBlob?: boolean;
  deckID?: ID;
  ttsParams?: TTSRenderParams;
}

class BlobStoreDB {
  async getBlob({
    id,
    persistDownloadedBlob = false,
    deckID,
    ttsParams,
  }: {
    id: ID;
    persistDownloadedBlob?: boolean;
    deckID?: ID;
    ttsParams?: TTSRenderParams;
  }): Promise<Blob | null> {
    try {
      const storedBlob = await IDB.blobs.get("blobs", id);
      if (storedBlob?.blob) {
        return storedBlob.blob;
      }
    } catch {
      return null;
    }

    // Fall back to network.
    const creds = getNetworkCredentials();
    let netBlob: Blob | null = null;
    if (ttsParams) {
      if (ttsParams.text) {
        netBlob = await downloadTTSBlob({
          deckID: ttsParams.deckID,
          text: ttsParams.text,
          lang: ttsParams.lang,
          preferredGender: ttsParams.preferredGender,
          persist: persistDownloadedBlob,
        });
      } else {
        return null;
      }
    } else if (persistDownloadedBlob && deckID) {
      netBlob = await downloadAndSaveBlobImmediately(IDB.blobs, id, deckID, creds);
    } else {
      netBlob = await downloadBlob(id, creds);
    }
    if (netBlob && netBlob.size > 0) {
      return netBlob;
    }

    return null;
  }

  async enqueueBlobsForDownloadWithDeckIDs(queuedBlobs: IQueuedBlob[]): Promise<void> {
    const tx = IDB.blobs.transaction(["blobs", "queuedBlobs"], "readwrite");
    const store = tx.objectStore("queuedBlobs");
    for (const item of queuedBlobs) {
      await store.put(item);
    }
    await tx.done;
  }

  async enqueueBlobsForDownload(deckID: ID, queuedBlobs: IQueuedBlobSansDeckID[]): Promise<void> {
    const tx = IDB.blobs.transaction(["blobs", "queuedBlobs"], "readwrite");
    const store = tx.objectStore("queuedBlobs");

    for (const { id, ttsParams } of queuedBlobs) {
      const existingQueuedBlob = await store.get(id);
      if (!existingQueuedBlob) {
        await store.add({ id, deckID, ttsParams });
      }
      // If the blob already exists in the queue,
      // we simply continue with the next one
    }
    await tx.done;
  }

  async downloadAndSaveBlobInBackground(id: ID, deckID: ID): Promise<Blob | null> {
    await downloadAndSaveBlobInWorker(id, deckID, Device.getID(), Device.getToken());
    const storedBlob = await IDB.blobs.get("blobs", id);
    if (storedBlob?.blob) {
      return storedBlob.blob;
    }
    return null;
  }

  async downloadQueuedBlobs(
    deckID: ID,
    logDownloadEvent?: (
      step: "downloading_blobs" | "downloaded_blobs",
      data?: Record<string, any>,
    ) => void,
  ) {
    const allBlobs = await IDB.blobs.getAll("queuedBlobs");
    const queue = allBlobs.filter((blob) => blob.deckID === deckID);
    const numBlobs = queue.length;

    logDownloadEvent?.("downloading_blobs", { numBlobs });

    const maxConcurrency = navigator.hardwareConcurrency ?? 4;
    await downloadBlobsWithMaxConcurrency(queue, maxConcurrency, Device.getID(), Device.getToken());

    logDownloadEvent?.("downloaded_blobs");
  }

  // blobURLs tracks generated URLs for blob to support reference counting GC.
  blobURLs: Record<ID, { typedURL: Promise<ITypedBlobURL | null>; refCount: number }> = {};

  async getTypedBlobURLUncached({
    id,
    persistDownloadedBlob = false,
    deckID,
    ttsParams,
  }: IGetBlobParams): Promise<ITypedBlobURL | null> {
    try {
      const blob = await this.getBlob({
        id,
        persistDownloadedBlob,
        deckID,
        ttsParams,
      });
      if (blob) {
        try {
          const url = URL.createObjectURL(blob);
          return { url, type: blob.type };
        } catch {
          // Indicates that blob was invalid.
          // Delete cached blob and retry.
          await IDB.blobs.delete("blobs", id);
          const blob = await this.getBlob({
            id,
            persistDownloadedBlob,
            deckID,
            ttsParams,
          });
          if (blob) {
            const url = URL.createObjectURL(blob);
            return { url, type: blob.type };
          }
        }
      }
    } catch (err) {
      if (isIndexedDBRefreshRequiredError(err)) {
        handleIndexedDBRefreshRequiredError(err);
      } else {
        logEvent("blob_download_failed", {
          err: err instanceof Error ? `${err.name}: ${err.message}` : "???",
          errStack: err instanceof Error ? err.stack : undefined,
          blobID: id,
          function: "getBlobURL",
          deckID,
        });
      }
    }
    return null;
  }

  async getBlobURL(params: IGetBlobParams): Promise<ITypedBlobURL | null> {
    const { id } = params;

    if (id in this.blobURLs) {
      const { typedURL } = this.blobURLs[id];
      this.blobURLs[id].refCount += 1;
      return typedURL;
    }

    this.blobURLs[id] = {
      typedURL: this.getTypedBlobURLUncached(params),
      refCount: 1,
    };
    return this.blobURLs[id].typedURL;
  }

  releaseBlobURL(id: ID): void {
    const releaseDelayMillis = 50;

    const release = async () => {
      if (this.blobURLs[id]?.refCount < 1) {
        const typedURL = await this.blobURLs[id].typedURL;
        if (typedURL) {
          URL.revokeObjectURL(typedURL.url);
          delete this.blobURLs[id];
        }
      }
    };

    if (id in this.blobURLs) {
      this.blobURLs[id].refCount -= 1;
      setTimeout(release, releaseDelayMillis);
    }
  }

  async insertBlob({ id, deckID, blob, pendingSave, sha256, doUpload = false }: IBlobInsert) {
    await IDB.blobs.put("blobs", {
      id,
      deckID,
      blob,
      pendingSave: pendingSave ? IDB_TRUE : undefined,
      sha256,
    });
    if (doUpload) {
      await this.uploadBlobsWithIDs([id]);
    }
  }

  async copyBlob(srcID: ID, destID: ID, destDeckID: ID, destKnolID: ID) {
    const tx = IDB.blobs.transaction(["blobs", "blobUploadQueue"], "readwrite");
    const storedBlob = await tx.objectStore("blobs").get(srcID);
    if (storedBlob) {
      await tx.objectStore("blobs").put({
        id: destID,
        deckID: destDeckID,
        blob: storedBlob?.blob,
      });
      await tx.objectStore("blobUploadQueue").add({
        blobID: destID,
        knolID: destKnolID,
      });
    }
    await tx.done;
  }

  async markBlobsAsSavedAndPendingUpload(ids: ID[], knolID: ID) {
    const tx = IDB.blobs.transaction(["blobs", "blobUploadQueue"], "readwrite");
    const blobsStore = tx.objectStore("blobs");
    for (const id of ids) {
      const blob = await blobsStore.get(id);
      if (blob) {
        const updatedBlob = { ...blob, pendingSave: undefined };
        await blobsStore.put(updatedBlob);
      }
    }
    await Promise.all(
      ids.map((id) => tx.objectStore("blobUploadQueue").put({ blobID: id, knolID })),
    );
    await tx.done;
  }

  async deleteBlobsPendingSave() {
    const transaction = IDB.blobs.transaction("blobs", "readwrite");
    const store = transaction.objectStore("blobs");
    const index = store.index("pendingSave");
    let cursor = await index.openCursor(IDBKeyRange.only(IDB_TRUE));

    while (cursor) {
      await cursor.delete();
      cursor = await cursor.continue();
    }
    await transaction.done;
  }

  async deleteBlobsInDeck(deckID: ID) {
    const tx = IDB.blobs.transaction(["blobs", "queuedBlobs"], "readwrite");
    await Promise.all([
      tx.objectStore("blobs").delete(deckID),
      tx.objectStore("queuedBlobs").delete(deckID),
    ]);
    await tx.done;
  }

  async uploadBlobsWithIDs(ids: ID[]) {
    const tx = IDB.blobs.transaction("blobUploadQueue", "readonly");
    const store = tx.objectStore("blobUploadQueue");
    const allBlobs = await store.getAll();
    const queue = allBlobs.filter((blob) => ids.includes(blob.blobID));
    await tx.done;
    uploadBlobsInBackground(queue, Device.getID(), Device.getToken());
  }

  async uploadLocalBlobs() {
    const queue = await IDB.blobs.getAll("blobUploadQueue");
    uploadBlobsInBackground(queue, Device.getID(), Device.getToken());
  }

  async numPendingBlobUploads(): Promise<number> {
    return IDB.blobs.count("blobUploadQueue");
  }

  async clear() {
    const tx = IDB.blobs.transaction(["blobs", "queuedBlobs", "blobUploadQueue"], "readwrite");
    await Promise.all([
      tx.objectStore("blobs").clear(),
      tx.objectStore("queuedBlobs").clear(),
      tx.objectStore("blobUploadQueue").clear(),
    ]);
    await tx.done;
  }
}

const BlobStore = new BlobStoreDB();
export default BlobStore;
