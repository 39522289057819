import RadioSelectSettingModal, { IRadioSelectOption } from "@components/radioSelectSettingModal";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React from "react";

export const omniReviewDeckSelectionValues = ["1wk", "1mo", "3mo", "all"] as const;
export type OmniReviewDeckSelectionValue = (typeof omniReviewDeckSelectionValues)[number];

interface IProps {
  value: OmniReviewDeckSelectionValue;
  onChange: (val: OmniReviewDeckSelectionValue) => void;
}
export default function OmniReviewDeckSelectorSettingItem({
  value,
  onChange,
}: IProps): JSX.Element {
  const options: IRadioSelectOption<OmniReviewDeckSelectionValue>[] = [
    {
      val: "1wk",
      name: L10n.localize((s) => s.review.omniReviewRecencyCutoff1wkLabel),
      desc: L10n.localize((s) => s.review.omniReviewRecencyCutoff1wkDescription),
    },
    {
      val: "1mo",
      name: L10n.localize((s) => s.review.omniReviewRecencyCutoff1moLabel),
      desc: L10n.localize((s) => s.review.omniReviewRecencyCutoff1moDescription),
    },
    {
      val: "3mo",
      name: L10n.localize((s) => s.review.omniReviewRecencyCutoff3moLabel),
      desc: L10n.localize((s) => s.review.omniReviewRecencyCutoff3moDescription),
    },
    {
      val: "all",
      name: L10n.localize((s) => s.general.all),
      desc: L10n.localize((s) => s.review.omniReviewRecencyCutoffAllDescription),
    },
  ];
  const selection = options.find((opt) => opt.val === value);

  const [showModal] = useIonCardModalWithDismiss(
    RadioSelectSettingModal<OmniReviewDeckSelectionValue>,
    {
      options,
      title: L10n.localize((s) => s.review.omniReviewRecencyCutoffLabel),
      onChange,
      selectedItemVal: value,
      description: L10n.localize((s) => s.review.omniReviewRecencyCutoffModalDescription),
    },
  );

  return (
    <IonItem button onClick={showModal}>
      <IonLabel>
        <h2>{L10n.localize((s) => s.review.omniReviewRecencyCutoffLabel)}</h2>
        <p>{L10n.localize((s) => s.review.omniReviewRecencyCutoffDescription)}</p>
      </IonLabel>
      <IonNote slot="end">
        <div>{selection?.name}</div>
      </IonNote>
    </IonItem>
  );
}
