import { Operation } from "@models/operation";
import {
  IUserSettingsDeleteOperation,
  IUserSettingsUpdateOperation,
} from "@operations/userSettings";
import { SORT_OPTION_DEFAULT, SortOptions } from "@screens/decks/types";
import EventBus from "eventBus";
import { Locales } from "locales";
import L10n from "localization";
import { useState } from "react";
import { defaultStudyGoal, getStudyGoal } from "studyGoals";
import Style from "style";

export interface ISettings {
  currentLocale?: Locales;
  colorScheme?: string;
  defaultCardsPerReview: number;
  autoplayAudio: boolean;
  reviewHapticsEnabled: boolean;
  studyGoal: number;
  hintRevealDelayMillis: number;
  expandRichEditorToolbar: boolean;
  enableAdvancedFontControls: boolean;
  alwaysHideCardPreview: boolean;
  deckSortOrder: SortOptions;
  showTags: boolean;
  isTheaterMode: boolean;
}

export const defaultCardFontSize = 24;

export const studyGoalMax = 10000;
export const minCardFontSize = 8;
export const maxCardFontSize = 48;
export const minCardsPerReview = 1;
export const maxCardsPerReviewWithoutUnlimited = 100;

export const defaultAutoflipTimerMillis = 2500;
export const autoflipTimerMinMillis = 250;
export const autoflipTimerMaxMillis = 5000;

export const defaultHintRevealDelayMillis = 250;
export const minHintRevealDelayMillis = 100;
export const maxHintRevealDelayMillis = 1000;

export const defaultUseGlobCardSearch = false;
export const defaultExpandRichEditorToolbar = false;
export const defaultEnableAdvancedFontControls = false;
export const defaultAlwaysHideCardPreview = false;
export const defaultDeckSortOrder = SORT_OPTION_DEFAULT;

export const defaultCardsPerReview = 10;

export const defaultSettings: ISettings = {
  autoplayAudio: true,
  defaultCardsPerReview: defaultCardsPerReview,
  reviewHapticsEnabled: true,
  studyGoal: defaultStudyGoal,
  hintRevealDelayMillis: defaultHintRevealDelayMillis,
  expandRichEditorToolbar: defaultExpandRichEditorToolbar,
  enableAdvancedFontControls: defaultEnableAdvancedFontControls,
  alwaysHideCardPreview: defaultAlwaysHideCardPreview,
  deckSortOrder: defaultDeckSortOrder,
  showTags: true,
  isTheaterMode: false,
};

export function getCurrentUserSettings(): ISettings {
  return {
    defaultCardsPerReview: getDefaultCardsPerReview(),
    currentLocale: L10n.getCurrentLocale(),
    colorScheme: Style.getPersistedTheme(),
    autoplayAudio: getAutoplayAudio(),
    reviewHapticsEnabled: getHapticsEnabled(),
    studyGoal: getStudyGoal(),
    hintRevealDelayMillis: getHintRevealDelayMillis(),
    expandRichEditorToolbar: getExpandRichEditorToolbar(),
    enableAdvancedFontControls: getEnableAdvancedFontControls(),
    alwaysHideCardPreview: getAlwaysHideCardPreview(),
    deckSortOrder: getDeckSortOrder(),
    showTags: getBooleanSetting("showTags"),
    isTheaterMode: getBooleanSetting("isTheaterMode"),
  };
}

export type Setting = keyof ISettings;

export const updateSetting = async <T extends Setting>(
  setting: T,
  value: ISettings[T],
): Promise<ISettings> => {
  const currentSettings = getCurrentUserSettings();
  if (currentSettings[setting] === value) {
    return currentSettings;
  }
  const updatedSettings = {
    ...currentSettings,
    [setting]: value,
  };
  await updateUserSettings(updatedSettings);
  return updatedSettings;
};

export function localStorageKeyForSetting<T extends Setting>(setting: T): string {
  return `AnkiApp.settings.${setting}`;
}

// Records a setting to local storage, without syncing.
export function setSettingLocally<T extends Setting>(setting: T, value: ISettings[T]) {
  if (value === undefined) {
    localStorage.removeItem(setting);
  } else {
    const key = localStorageKeyForSetting(setting);
    localStorage.setItem(key, String(value));
  }
  if (setting === "deckSortOrder") {
    EventBus.emit("deckSortUpdated", value as ISettings["deckSortOrder"]);
  }
}

// Loads a setting from local storage.
// export const getSettingLocally = function <T extends Setting>(setting: T): ISettings[T] | undefined {
// TODO
//   localStorage.getItem(setting);
// };

export const resetSettingsToDefault = async (): Promise<void> => {
  const op: IUserSettingsDeleteOperation = {
    ...Operation.operationDefaults(),
    type: "DELETE",
    object_type: "user_settings",
    object_parameters: {
      user_id: localStorage["AnkiApp.user.id"],
    },
  };
  return Operation.operateAndSave(op);
};

async function updateUserSettings(settings: ISettings): Promise<void> {
  const op: IUserSettingsUpdateOperation = {
    ...Operation.operationDefaults(),
    type: "UPDATE",
    object_type: "user_settings",
    object_parameters: {
      data: JSON.stringify(settings),
    },
  };
  return Operation.operateAndSave(op);
}

// This TypeScript voodoo filters the set of ISettings keys down to those that
// map to a boolean.
type BooleanSetting = {
  [K in keyof ISettings]: ISettings[K] extends boolean ? K : never;
}[keyof ISettings] &
  keyof ISettings; // Intersection with 'keyof T' to exclude 'never'

// Returns the default for undefined boolean settings, otherwise the boolean value (parsed from string).
export const getBooleanSetting = <T extends BooleanSetting>(setting: T): boolean => {
  const key = localStorageKeyForSetting(setting);
  const value = localStorage.getItem(key);
  return value === undefined || value === null ? defaultSettings[setting] : value === "true";
};

export const getDeckSortOrder = (): SortOptions => {
  const key = localStorageKeyForSetting("deckSortOrder");
  return (localStorage.getItem(key) as SortOptions) ?? defaultDeckSortOrder;
};

export const setDeckSortOrder = async (sortOrder: SortOptions): Promise<void> => {
  await updateSetting("deckSortOrder", sortOrder);
};

export const getDefaultCardsPerReview = (): number => {
  const key = localStorageKeyForSetting("defaultCardsPerReview");
  const num = Number.parseInt(localStorage[key]);
  if (Number.isNaN(num)) {
    return defaultSettings.defaultCardsPerReview;
  }
  if (num < 0) {
    return defaultSettings.defaultCardsPerReview;
  }
  return num;
};

export const getExpandRichEditorToolbar = (): boolean => {
  return getBooleanSetting("expandRichEditorToolbar");
};

export const getEnableAdvancedFontControls = (): boolean => {
  return getBooleanSetting("enableAdvancedFontControls");
};

export const getAlwaysHideCardPreview = (): boolean => {
  return getBooleanSetting("alwaysHideCardPreview");
};

export const getAutoplayAudio = (): boolean => {
  return getBooleanSetting("autoplayAudio");
};

export const getHapticsEnabled = (): boolean => {
  return getBooleanSetting("reviewHapticsEnabled");
};

export const getHintRevealDelayMillis = (): number => {
  const key = localStorageKeyForSetting("hintRevealDelayMillis");
  const millis = Number.parseInt(localStorage[key]);
  if (Number.isNaN(millis)) {
    return defaultSettings.hintRevealDelayMillis;
  }
  if (millis < minHintRevealDelayMillis) {
    return minHintRevealDelayMillis;
  }
  if (millis > maxHintRevealDelayMillis) {
    return maxHintRevealDelayMillis;
  }
  return millis;
};

export const useShowTags = (): [boolean, () => void] => {
  const initialShowTags = getBooleanSetting("showTags");
  const [showTags, setShowTags] = useState(initialShowTags);

  const toggle = () => {
    setShowTags((prevShowTags) => {
      const showTags = !prevShowTags;
      updateSetting("showTags", showTags);
      return showTags;
    });
  };

  return [showTags, toggle];
};
