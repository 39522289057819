import { Histogram } from "./gradeHistogram";

export class Probabilities {
  public static readonly CONDITIONAL_GRADE_PROBABILITIES = {
    NEW: { NEW: 0.2, F: 0.3, E: 0.175, D: 0.175, C: 0.1, B: 0.049, A: 0.001, AA: 0.0, AAA: 0.0 },
    F: { NEW: 0.0, F: 0.4, E: 0.19, D: 0.16, C: 0.125, B: 0.115, A: 0.01, AA: 0.0, AAA: 0.0 },
    E: { NEW: 0.0, F: 0.0, E: 0.45, D: 0.33, C: 0.15, B: 0.05, A: 0.02, AA: 0.0, AAA: 0.0 },
    D: { NEW: 0.0, F: 0.0, E: 0.0, D: 0.5, C: 0.37, B: 0.1, A: 0.03, AA: 0.0, AAA: 0.0 },
    C: { NEW: 0.0, F: 0.0, E: 0.0, D: 0.0, C: 0.5, B: 0.4, A: 0.1, AA: 0.0, AAA: 0.0 },
    B: { NEW: 0.0, F: 0.0, E: 0.0, D: 0.0, C: 0.0, B: 0.7, A: 0.3, AA: 0.0, AAA: 0.0 },
    A: { NEW: 0.0, F: 0.0, E: 0.0, D: 0.0, C: 0.0, B: 0.0, A: 0.9, AA: 0.099, AAA: 0.001 },
    AA: { NEW: 0.0, F: 0.0, E: 0.0, D: 0.0, C: 0.0, B: 0.0, A: 0.0, AA: 0.8, AAA: 0.2 },
    AAA: { NEW: 0.0, F: 0.0, E: 0.0, D: 0.0, C: 0.0, B: 0.0, A: 0.0, AA: 0.0, AAA: 1.0 },
  };
}

export function probBounds(grade: string): Histogram {
  const uppercaseGrade = grade.toUpperCase() as keyof Histogram;
  const gradeProbs = Probabilities.CONDITIONAL_GRADE_PROBABILITIES[uppercaseGrade];

  if (!gradeProbs) {
    throw new Error(`Invalid grade: ${grade}`);
  }

  // Compute boundaries for random sampling.
  const probBounds: Histogram = {
    NEW: 0,
    F: 0,
    E: 0,
    D: 0,
    C: 0,
    B: 0,
    A: 0,
    AA: 0,
    AAA: 0,
  };

  probBounds.NEW = gradeProbs.NEW;
  let prevGrade: keyof Histogram = "NEW";

  const gradesByIncreasingPriorityWithoutNEW = ["F", "E", "D", "C", "B", "A", "AA", "AAA"] as const;
  for (const g of gradesByIncreasingPriorityWithoutNEW) {
    probBounds[g] = probBounds[prevGrade] + gradeProbs[g];
    prevGrade = g;
  }

  return probBounds;
}
