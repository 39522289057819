import FooterButton from "@components/footerButton";
import Globals, { TResponseValue } from "globals";
import { useHint } from "hooks/util/useHint";
import L10n from "localization";
import React from "react";
import Style from "style";

interface IProps {
  enabled: boolean;
  activeSideNum: number;
  handleFlip: () => void;
  handleResponse: (response: TResponseValue) => void;
  isTheaterMode?: boolean;
}

export default function ReviewControls({
  enabled = true,
  activeSideNum = 0,
  handleFlip,
  handleResponse,
  isTheaterMode,
}: IProps): JSX.Element {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    margin: isTheaterMode ? "0 2px 4px 2px" : undefined,
  };

  const hintHandlers = useHint();

  const borderTopSize = 2;

  return activeSideNum === 0 ? (
    <div {...hintHandlers} style={containerStyle}>
      <FooterButton
        cy="flip-button"
        disabled={!enabled}
        onClick={handleFlip}
        borderTopSize={borderTopSize}
        isTheaterMode={isTheaterMode}
      >
        {L10n.localize((s) => s.actions.flip)}
      </FooterButton>
    </div>
  ) : (
    <div style={containerStyle}>
      <FooterButton
        key="fail"
        onClick={() => handleResponse(Globals.RESPONSES.FAIL)}
        color={Style.colors.fail}
        borderTopSize={borderTopSize}
        isTheaterMode={isTheaterMode}
      >
        {L10n.localize((s) => s.response.fail)}
      </FooterButton>
      <FooterButton
        key="hard"
        onClick={() => handleResponse(Globals.RESPONSES.HARD)}
        color={Style.colors.hard}
        borderTopSize={borderTopSize}
        isTheaterMode={isTheaterMode}
      >
        {L10n.localize((s) => s.response.hard)}
      </FooterButton>
      <FooterButton
        key="good"
        onClick={() => handleResponse(Globals.RESPONSES.GOOD)}
        color={Style.colors.good}
        borderTopSize={borderTopSize}
        isTheaterMode={isTheaterMode}
      >
        {L10n.localize((s) => s.response.good)}
      </FooterButton>
      <FooterButton
        key="easy"
        onClick={() => handleResponse(Globals.RESPONSES.EASY)}
        color={Style.colors.easy}
        borderTopSize={borderTopSize}
        isTheaterMode={isTheaterMode}
      >
        {L10n.localize((s) => s.response.easy)}
      </FooterButton>
    </div>
  );
}
