import L10n from "localization";
import { ProductFrequency } from "./types";

export function frequencyFor(serverID: string): ProductFrequency {
  // NOTE: this is a switch statement rather than a simple property lookup
  // in a const object, because JS autoformatters like to strip the quotes
  // off from around constant property names, which could result in a minifier
  // getting clever and minifying those property names. Don't want that.

  switch (serverID) {
    case "unlimited_year":
      return L10n.localize((s) => s.purchasing.yearFrequency);
    case "unlimited_lifetime":
      return null;
    default:
      // NOTE: this treats unknown serverIDs as non-recurring purchases.
      return null;
  }
}
