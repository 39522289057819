import FolderSelector from "@components/folderSelector/folderSelector";
import NestedNav from "@components/nestedNav";
import L10n from "localization";
import React, { useState } from "react";

interface IProps {
  folder?: string;
  onSelect?: (folder?: string) => void;
  confirmRemoval?: boolean;
}
export default function FoldersNav(props: IProps) {
  // HACK: navs have to mirror parent state :(
  const [folder, setFolder] = useState(props.folder);
  function handleSelect(folder?: string) {
    if (!props.onSelect) {
      return;
    }
    setFolder(folder);
    props.onSelect(folder);
  }
  function handleUnselect() {
    if (!props.onSelect) {
      return;
    }
    setFolder(undefined);
    props.onSelect(undefined);
  }

  return (
    <NestedNav title={L10n.localize((s) => s.folder.singular)}>
      <FolderSelector
        selectedFolders={folder ? [folder] : []}
        onAddFolder={props.onSelect ? handleSelect : undefined}
        onDeleteFolder={props.onSelect ? handleUnselect : undefined}
        confirmRemoval={props.confirmRemoval}
      />
    </NestedNav>
  );
}
