import { renderTTS } from "blobs/blobNetwork";
import BlobStore from "data/idb/blobStore";
import Device from "device";

import { ID } from "types/ID";
import { blobHash } from "../blobs/lib";
import { ITTSBlobParamsWithDeckID } from "./ttsParamsHash";

export const ttsIDLength = 64;

const ssmlGenders = ["male", "female"] as const;
export type SSMLGender = (typeof ssmlGenders)[number];

export function isSSMLGender(g: string | undefined): g is SSMLGender {
  if (g === undefined) {
    return true;
  }
  return ssmlGenders.includes(g as SSMLGender);
}

export const ttsSupportedGenders: Record<string, SSMLGender[]> = {
  "bg-BG": ["female"],
  "ca-ES": ["female"],
  "cs-CZ": ["female"],
  "da-DK": ["male", "female"],
  "de-DE": ["female", "male"],
  "el-GR": ["female"],
  "en-AU": ["female", "male"],
  "en-GB": ["female", "male"],
  "en-US": ["male", "female"],
  "es-ES": ["female", "male"],
  "es-US": ["female", "male"],
  "fi-FI": ["female"],
  "fr-CA": ["female", "male"],
  "fr-FR": ["female", "male"],
  "hi-IN": ["female", "male"],
  "hu-HU": ["female"],
  "id-ID": ["female", "male"],
  "is-IS": ["female"],
  "it-IT": ["female", "male"],
  "ja-JP": ["female", "male"],
  "ko-KR": ["female", "male"],
  "lv-LV": ["male"],
  "ms-MY": ["female", "male"],
  "nl-NL": ["male", "female"],
  "pl-PL": ["female", "male"],
  "pt-BR": ["female", "male"],
  "pt-PT": ["female", "male"],
  "ro-RO": ["female"],
  "ru-RU": ["female", "male"],
  "sk-SK": ["female"],
  "sr-RS": ["female"],
  "sv-SE": ["female", "male"],
  "th-TH": ["female"],
  "tr-TR": ["male", "female"],
  "uk-UA": ["female"],
  "vi-VN": ["female", "male"],
  "zh-CN": ["male", "female"],
  "zh-HK": ["female", "male"],
  "zh-TW": ["female", "male"],
};

// The Omit voodoo in the type signature is to allow deckID to be undefined,
// which is necessary during some forms of card rendering. If there's no
// deckID, you just can't persist the blob, but you can fetch it just fine.
export async function downloadTTSBlob({
  deckID,
  text,
  lang,
  preferredGender,
  persist = true,
}: Omit<ITTSBlobParamsWithDeckID, "deckID"> & {
  deckID?: ID;
  persist?: boolean;
}): Promise<Blob | null> {
  const token = Device.getToken();
  if (!token) {
    return null;
  }
  const resp = await renderTTS({ text, lang, preferredGender, token });
  if (!resp) {
    return null;
  }
  const { blob, id } = resp;
  if (persist && deckID) {
    const sha256 = await blobHash(blob);
    await BlobStore.insertBlob({ id, deckID, blob, sha256 });
  }
  return blob;
}
