import Pill from "components/pill";
import React from "react";
import Style from "style";

interface IProps {
  activeIndex: number;
  disabled?: boolean;
  pills: string[];
  style?: React.CSSProperties;
  onPillClick: (index: number) => void;
}

export default function PillBox({
  activeIndex = 0,
  disabled = false,
  pills = [],
  style,
  onPillClick,
}: IProps): JSX.Element {
  const handlePillClick = (pillIndex: number, e: React.UIEvent) => {
    if (disabled) {
      return;
    }
    e.persist();
    e.stopPropagation();
    onPillClick(pillIndex);
  };

  let pillStyle: React.CSSProperties = {
    display: "flex",
    flex: "1 1 auto",
    borderRadius: "6px",
    border: `1px solid ${Style.colors.actionableMutedFg}`,
    textAlign: "center" as const,
    overflow: "hidden",
    marginLeft: Style.edgePadding,
    marginRight: Style.edgePadding,
    marginTop: "6px",
  };

  if (disabled) {
    pillStyle = Object.assign(pillStyle, {
      borderColor: Style.colors.actionableMutedFg,
      color: "white",
    });
  }

  pillStyle = Object.assign(pillStyle, style);

  const flexPills = pills.map((name: string, idx) => (
    <div style={{ display: "flex", flex: "1 1 auto" }} key={name}>
      <Pill
        active={activeIndex === idx}
        disabled={disabled}
        isFinalPill={idx === pills.length - 1}
        key={name}
        onClick={(e) => handlePillClick(idx, e)}
        onTouchStart={(e) => handlePillClick(idx, e)}
      >
        {name}
      </Pill>
    </div>
  ));

  return <div style={pillStyle}>{flexPills}</div>;
}
