import { IEntry } from ".";
import prettify from "./pinyin";
import Trie from "./trie";

function parseLine(line: string): IEntry | null {
  const match = line.match(/^(\S+)\s(\S+)\s\[([^\]]+)\]\s\/(.+)\//);
  if (!match) {
    return null;
  }

  const [, traditional, simplified, pinyin, english] = match;

  const pinyinReplaced = pinyin.replace(/u:/g, "ü");
  const pinyinPretty = prettify(pinyinReplaced);

  return { traditional, simplified, pinyin, pinyinPretty, english };
}

export default class Cedict {
  simplifiedTrie!: Trie;
  traditionalTrie!: Trie;
  load(contents: string) {
    this.simplifiedTrie = new Trie();
    this.traditionalTrie = new Trie();

    const lines = contents.split("\n");

    for (const line of lines) {
      if (line.trim() === "" || line[0] === "#") {
        continue;
      }

      const entry = parseLine(line);
      if (!entry) {
        continue;
      }

      this.simplifiedTrie.push(entry.simplified, entry);
      this.traditionalTrie.push(entry.traditional, entry);
    }
  }

  get(word: string, traditional = false): IEntry[] {
    return traditional ? this.traditionalTrie.get(word) : this.simplifiedTrie.get(word);
  }

  getPrefix(word: string, traditional = false) {
    return traditional ? this.traditionalTrie.getPrefix(word) : this.simplifiedTrie.getPrefix(word);
  }
}
