import SideBar, { ISection } from "@components/sidebar";
import { useMediaQueryWhileOnscreen } from "hooks/util/useMediaQuery";
import React from "react";
import { BodySection } from "../decks/types";

interface IProps {
  activeSection: BodySection;
  sections: ISection[];
  setActiveSection: (section: BodySection) => void;
  children: React.ReactElement | null;
}
export default function HomeSection(props: IProps): JSX.Element | null {
  const { sections, activeSection, setActiveSection, children } = props;
  // TODO: extract magic number. This was tuned to match <IonSplitPane>.
  const isNarrowScreen = useMediaQueryWhileOnscreen("(max-width: 991px)");
  if (isNarrowScreen) {
    return (
      <>
        <SideBar
          sections={sections}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
        />
        {children}
      </>
    );
  }

  return children;
}
