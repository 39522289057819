import { DeckFields, IDeck } from "@models/deck";
import { Layout } from "@models/layout";
import { IField } from "fields/fields";
import { useMemo } from "react";

export function useDeckFields(
  deck: IDeck | undefined,
  inferFieldsForLegacyDecks = false,
): DeckFields | undefined {
  return useMemo(() => {
    if (deck?.config?.fields) {
      return deck.config.fields;
    }
    if (!inferFieldsForLegacyDecks) {
      return;
    }

    // No config, so infer the fields.
    const fieldNames = new Set<string>();
    for (const layout of deck?.layouts ?? []) {
      const templates = layout.templates;
      const frontFields = Layout.extractFields(templates[0]);
      for (const f of frontFields) {
        fieldNames.add(f);
      }
      const backFields = Layout.extractFields(templates[1]);
      for (const f of backFields) {
        fieldNames.add(f);
      }
    }

    const fields: IField[] = Array.from(fieldNames).map((name, i) => {
      return {
        type: "richtext",
        name,
        sides: [i === 0 ? 1 : 0, 1],
      };
    });
    return fields;
  }, [deck, inferFieldsForLegacyDecks]);
}
