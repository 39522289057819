import BlobStore from "@data/idb/blobStore";
import {
  IonAccordion,
  IonAccordionGroup,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  useIonAlert,
  useIonLoading,
} from "@ionic/react";
import { IDeck } from "@models/deck";
import { Operation } from "@models/operation";
import { IDeckInsertOperation } from "@operations/deck";
import { IKnolInsertOperation } from "@operations/knol";
import Device from "device";
import EventBus from "eventBus";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import { IParsedDeck, parseDeckFML } from "fml/parser";
import Globals from "globals";
import { History } from "history";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { folderOutline, helpCircleOutline } from "ionicons/icons";
import JSZip from "jszip";
import Lib from "lib";
import L10n from "localization";
import React from "react";
import { ID } from "types/ID";

const IMPORT_HELP_ARTICLE_PATH = "solutions/f7c77364/how-to-import-ankiapp-decks-zip-xml-/";

async function saveDeck(deckID: ID, parsedDeck: IParsedDeck): Promise<IDeck> {
  const now = new Date();

  const deckOp: IDeckInsertOperation = {
    ...Operation.operationDefaults(),
    type: "INSERT",
    object_type: "deck",
    object_parameters: {
      id: deckID,
      status: 1, // private
      name: parsedDeck.name,
      description: "",
      created_at: now.toISOString(),
      modified_at: now.toISOString(),
      tags: parsedDeck.tags.map((t) => {
        return { name: t };
      }),
      config: {
        fields: parsedDeck.fields,
      },
    },
  };

  const deck = {
    id: deckID,
    tags: parsedDeck.tags,
    name: parsedDeck.name,
    layout_id: MAGIC_LAYOUT_ID,
    description: "",
    created_at: now,
    modified_at: now,
  } as IDeck;

  await Operation.operateAndSave(deckOp);

  return deck;
}

async function saveCards(deckID: ID, parsedDeck: IParsedDeck): Promise<void> {
  const opQueue: IKnolInsertOperation[] = [];

  const now = new Date().toISOString();
  for (let i = 0; i < parsedDeck.cards.length; i++) {
    const parsedCard = parsedDeck.cards[i];
    const knolOp: IKnolInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "knol",
      object_parameters: {
        deck_id: deckID,
        knol_id: Lib.uuid16(),
        created_at: now,
        modified_at: now,
        knol_tags: parsedCard.tags,
        values: parsedCard.values,
        response_type_id: Globals.basicResponseType.id,
      },
    };
    opQueue.push(knolOp);
  }
  await Operation.operate(false, opQueue);
}

export default function ImportFML({
  dismiss,
  history,
}: {
  dismiss?: () => void;
  history: History;
}) {
  const fileInput = React.useRef<HTMLInputElement>(null);
  const [showLoading, hideLoading] = useIonLoading();
  const [showToast] = useDismissibleToast();
  const [presentAlert] = useIonAlert();

  const showParseResult = (lvl: "error" | "warning", msg: string): void => {
    presentAlert({
      header: lvl,
      message: msg,
      buttons: ["OK"],
    });
  };

  const textArea = React.useRef<HTMLTextAreaElement>(null);

  async function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.currentTarget;
    const file = files?.[0];
    if (!file) {
      return;
    }
    // ZIP file
    if (file.type === "application/zip") {
      const zip = new JSZip();
      const unzipped = await zip.loadAsync(file);
      const xml = unzipped.file(/.+\.xml/);
      if (xml.length < 1 || xml.length > 1) {
        showParseResult(
          "error",
          "Missing or incorrect number of deck XML files. See 'Need Help' for additional guidance.",
        );
        return;
      }
      const fmlText = await xml[0].async("string");
      const parseResult = parseDeckFML(fmlText);
      const parsedDeck = parseResult.val;
      if (parseResult.errors && parseResult.errors.length > 0) {
        showParseResult(
          "error",
          parseResult.errors
            .map((e) => e.msg)
            .join()
            .split("\n")[0],
        );
        return;
      }
      if (parsedDeck) {
        const blobFolder = unzipped.folder("blobs");
        const deckID = Lib.uuid16();
        if (blobFolder) {
          // biome-ignore lint/complexity/noForEach: blobFolder doesn't return an iterator
          blobFolder.forEach(async (f) => {
            const blob = await blobFolder.file(f)?.async("blob");
            if (blob) {
              await BlobStore.insertBlob({
                id: f,
                deckID,
                blob,
                pendingSave: false,
                sha256: f,
                doUpload: true,
              });
            }
          });
        }
        const deck = await saveDeck(deckID, parsedDeck);
        await saveCards(deckID, parsedDeck);
        hideLoading();
        showToast({
          message: L10n.localize((s) => s.import.success),
          color: "success",
          duration: 2000,
        });
        dismiss?.();
        history.push(`/decks/local/${deck.id}`);
        return;
      }
      return;
    }
    // NON zip file
    const fml = await file.text();
    const parseResult = parseDeckFML(fml);
    if (parseResult.errors && parseResult.errors.length > 0) {
      showParseResult("error", parseResult.errors.map((e) => e.msg).join("\n"));
    }
    if (parseResult.warnings && parseResult.warnings.length > 0) {
      showParseResult("warning", parseResult.warnings.map((e) => e.msg).join("\n"));
    }
    const parsedDeck = parseResult.val;
    if (parsedDeck) {
      try {
        showLoading(L10n.localize((s) => s.general.dataLoadingMessage));
        const deckID = Lib.uuid16();
        const deck = await saveDeck(deckID, parsedDeck);
        await saveCards(deckID, parsedDeck);
        hideLoading();
        EventBus.emit("deckAdded", { ID: deck.id });
        showToast({
          message: L10n.localize((s) => s.import.success),
          color: "success",
          duration: 2000,
        });
        dismiss?.();
        history.push(`/decks/local/${deck.id}`);
      } catch (err) {
        showParseResult(
          "error",
          L10n.localize((s) => s.error.internal),
        );
      }
    }
  }

  const handleImportText = async () => {
    if (textArea?.current) {
      try {
        const parseResult = parseDeckFML(textArea.current.value);
        if (parseResult.errors && parseResult.errors.length > 0) {
          showParseResult(
            "error",
            parseResult.errors
              .map((e) => e.msg)
              .join()
              .split("\n")[0],
          );
          return;
        }
        const parsedDeck = parseResult.val;
        if (parsedDeck) {
          showLoading(L10n.localize((s) => s.general.dataLoadingMessage));
          const deckID = Lib.uuid16();
          const deck = await saveDeck(deckID, parsedDeck);
          await saveCards(deckID, parsedDeck);
          hideLoading();
          EventBus.emit("deckAdded", { ID: deck.id });
          showToast({
            message: L10n.localize((s) => s.import.success),
            color: "success",
            duration: 2000,
          });
          textArea.current.value = "";
        }
      } catch (err) {
        showParseResult(
          "error",
          L10n.localize((s) => s.error.internal),
        );
      }
    }
  };

  function formatXml(xml: string, tab = "\t") {
    // tab = optional indent value, default is tab (\t)
    let formatted = "";
    let indent = "";
    for (const node of xml.split(/>\s*</)) {
      if (node.match(/^\/\w/)) indent = indent.substring(tab.length); // decrease indent by one 'tab'
      formatted += `${indent}<${node}>\r\n`;
      if (node.match(/^<?\w[^>]*[^/]$/)) indent += tab; // increase indent
    }
    return formatted.substring(1, formatted.length - 3);
  }

  const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    const paste = e.clipboardData?.getData("text").replaceAll("\\n", "");
    if (textArea.current && paste) {
      textArea.current.value = formatXml(paste, "  ");
    }
  };

  const handleHelp = () => {
    Device.openExternalLink(Globals.helpEndpoint + IMPORT_HELP_ARTICLE_PATH);
  };

  return (
    <IonList inset={true}>
      <IonListHeader>AnkiApp Deck Import</IonListHeader>
      <IonItem key="1">
        <IonLabel className="ion-text-wrap">
          Import a deck from a valid AnkiApp deck file (.zip or .xml).
        </IonLabel>
        <IonButton slot="end" color="light secondary" onClick={handleHelp} size="default">
          <IonIcon slot="start" icon={helpCircleOutline} />
          {L10n.localize((s) => s.import.needHelp)}
        </IonButton>
      </IonItem>
      <IonItem
        key="2"
        button
        onClick={() => {
          fileInput.current?.click();
        }}
      >
        <IonIcon
          slot="start"
          color="primary"
          icon={folderOutline}
          onClick={() => {
            fileInput.current?.click();
          }}
        />
        <IonLabel color="primary">{L10n.localize((s) => s.import.chooseFile)}</IonLabel>
        <input
          type="file"
          accept=".xml,.zip"
          value=""
          ref={fileInput}
          onChange={handleFileSelected}
          style={{ display: "none" }}
        />
      </IonItem>
      <IonItem key="3">
        <IonAccordionGroup style={{ width: "100%" }} color="default">
          <IonAccordion value="first">
            <IonItem slot="header" color="default" className="no-margin-accordion">
              <IonLabel color="medium">Advanced</IonLabel>
            </IonItem>
            <IonList slot="content" lines="none">
              <IonItem lines="none">
                <textarea
                  autoCorrect="off"
                  autoCapitalize="off"
                  spellCheck={false}
                  onPaste={handlePaste}
                  style={{
                    fontFamily: "'SF Mono', monospace",
                    fontSize: 10,
                    width: "100%",
                    height: 300,
                    fontWeight: 500,
                  }}
                  ref={textArea}
                />
              </IonItem>
              <IonItem lines="none">
                <IonButton size="default" onClick={handleImportText} slot="end" color="danger">
                  Import from text
                </IonButton>
              </IonItem>
            </IonList>
            <IonItem />
          </IonAccordion>
        </IonAccordionGroup>
      </IonItem>
    </IonList>
  );
}
