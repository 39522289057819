import PercentToGradeLetter from "@core/percentToGradeLetter";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React from "react";
import Style from "style";
import Letter from "./gradeLetter";

// For grades:
// undefined — loading, don't show.
// null — this also means NEW.
// NaN — this means NEW.
// an actual number — it's a grade!

function Percent(props: { percent: number; countUp?: boolean; showPercent?: boolean }) {
  const { percent } = props;

  const signStyle = {
    fontSize: "50%",
    opacity: 0.5,
  };

  return (
    <span>
      <span>{Math.floor(100 * percent)}</span>
      <span style={signStyle}>%</span>
    </span>
  );
}

interface IProps {
  fontSize?: any;
  showLetter?: boolean;
  showPercent?: boolean;
  percent: number | undefined;
}

export default function Grade(props: IProps): JSX.Element | null {
  const { fontSize = undefined, showLetter = true, showPercent = true, percent } = props;

  let style: React.CSSProperties;

  if (percent === undefined) {
    // Must handle this specially because isNaN(undefined) == true.
    return (
      <div
        style={{
          color: Style.colors.mutedFg,
          fontSize: "3.5rem",
          textTransform: "uppercase",
        }}
      >
        ...
      </div>
    );
  }

  if (percent === null || Number.isNaN(percent)) {
    // It's NEW.
    style = {
      color: Style.colors.grades.NEW,
      fontSize: fontSize,
      textTransform: "uppercase",
    };
    return (
      <span style={style} {...cyAttr("grade")}>
        {L10n.localize((s) => s.grade.NEW)}
      </span>
    );
  }

  if (!showLetter && !showPercent) {
    // Nothing to see, here.
    return null;
  }

  const letter = PercentToGradeLetter(percent);

  style = {
    fontSize: fontSize,
    color: letter ? Style.colors.grades[letter] : undefined,
  };

  return (
    <span style={style} {...cyAttr("grade")}>
      {showPercent && <Percent percent={percent} showPercent={showPercent} />}
      {showLetter && <Letter letter={letter} />}
    </span>
  );
}
