import { IonInput, IonTextarea } from "@ionic/react";
import ModalDialog from "components/modalDialog";
import EventBus from "eventBus";
import { useIsPhone } from "hooks/util/useMediaQuery";
import L10n from "localization";
import React from "react";
import Style from "style";
import { promptHideEventName } from "./prompt";

function Error() {
  const outerStyle: React.CSSProperties = {
    backgroundColor: Style.colors.dialogShadeColor,
    display: "table",
    height: "100%",
    left: "0",
    overflow: "hidden",
    position: "fixed",
    top: "0",
    width: "100%",
    zIndex: 99999,
  };

  const isPhone = useIsPhone();

  const innerStyle: React.CSSProperties = {
    display: "table-cell",
    textAlign: "center" as const,
    verticalAlign: isPhone ? "top" : "middle",
  };

  return (
    <div style={outerStyle}>
      <div style={innerStyle}>{">_<"}</div>
    </div>
  );
}

interface IProps {
  accept: any;
  autoCapitalize: "off" | "none" | "on" | "sentences" | "words" | "characters";
  callback: (response: any) => void;
  cancel: any;
  cancelCallback: () => void;
  content?: React.ReactElement;
  default: string;
  inputType: "password" | "text" | "textarea" | "email";
  prompt: string;
  promptType: "input" | "alert" | "confirm" | "custom" | "dangerousConfirm";
  title: string;
  validationRegex: any;
  onHide?: () => void;
  suggestions: string[];
  hasPadding?: boolean;
  useChips: boolean;
}

interface IState {
  hasError: boolean;
  response: string;
  valid: boolean | null | undefined;
}

export default class PromptDialog extends React.Component<IProps, IState> {
  static defaultProps = {
    accept: null,
    autoCapitalize: "off",
    callback: () => null,
    cancel: null,
    cancelCallback: () => null,
    default: "",
    inputType: "text",
    prompt: "",
    promptType: "input",
    title: null,
    validationRegex: null,
    suggestions: [],
    useChips: false,
  };

  state = {
    hasError: false,
    response: this.props.default,
    valid: this.props.validationRegex
      ? this.isValid(this.props.default, this.props.validationRegex)
      : undefined,
  };

  inputRef: React.RefObject<any>;

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
  }

  isValid(value: string, validationRegex: RegExp) {
    return !!value.match(validationRegex);
  }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true });
  }

  componentDidMount = () => {
    window.addEventListener("keypress", this.handleKeyPress, true);
  };

  modalDidShow = () => {
    if (this.props.promptType === "input") {
      setTimeout(() => this.inputRef.current?.focus(), 0);
    }
  };

  componentWillUnmount = () => {
    return window.removeEventListener("keypress", this.handleKeyPress, true);
  };

  handleKeyPress = (e: KeyboardEvent) => {
    if (e.which === 27) {
      // esc
      this.cancel();
      e.preventDefault();
      return e.stopPropagation();
    } else if (e.which === 13) {
      e.preventDefault();
      e.stopPropagation();
      return this.accept();
    }
  };

  onCancel = () => {
    return this.cancel();
  };

  handleIgnore = () => {
    if (this.props.promptType !== "confirm") {
      return this.cancel();
    }
  };

  onAccept = () => {
    return this.accept();
  };

  onHide = () => {
    if (this.props.onHide) {
      return this.props.onHide();
    }
  };

  cancel = () => {
    return this.setState(
      (state, props) => ({ response: props.default }),
      () => {
        this.onHide();
        EventBus.emit(promptHideEventName);

        this.props.cancelCallback?.();
      },
    );
  };

  accept = () => {
    const { response } = this.state;

    if (this.props.validationRegex) {
      if (!this.isValid(response, this.props.validationRegex)) {
        return;
      }
    }

    return this.setState(
      (state, props) => ({ response: props.default }),
      () => {
        this.onHide();
        EventBus.emit(promptHideEventName);
        return this.props.callback(response);
      },
    );
  };

  onChange = (newValue: any) => {
    return this.setState((state, props) => {
      return {
        response: newValue,
        valid: props.validationRegex ? this.isValid(newValue, props.validationRegex) : null,
      };
    });
  };

  render() {
    if (this.state.hasError) {
      return <Error />;
    }

    const titleText = this.props.title || L10n.localize((s) => s.actions.confirm);
    const acceptText = this.props.accept || L10n.localize((s) => s.actions.ok);

    const promptInput =
      this.props.inputType === "textarea" ? (
        <IonTextarea
          ref={this.inputRef}
          autoCapitalize={this.props.autoCapitalize}
          onChange={this.onChange}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              this.onAccept();
            }
          }}
          value={this.state.response}
        />
      ) : (
        <IonInput
          ref={this.inputRef}
          type={this.props.inputType}
          autoCapitalize={this.props.autoCapitalize}
          onChange={this.onChange}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              this.onAccept();
            }
          }}
          value={this.state.response}
        />
      );

    const promptText = (
      <div
        style={{
          color: Style.colors.primaryFg,
          textAlign: "center" as const,
        }}
        dangerouslySetInnerHTML={{ __html: this.props.prompt }}
      />
    );

    return (
      <ModalDialog
        valid={this.state.valid}
        title={titleText}
        hasPadding={this.props.hasPadding}
        content={
          this.props.promptType === "input"
            ? promptInput
            : this.props.promptType === "custom"
              ? this.props.content
              : promptText
        }
        onCancel={this.cancel}
        showAcceptButton={this.props.promptType !== "custom"}
        onAccept={this.accept}
        acceptText={acceptText}
      />
    );
  }
}
