import listeningAudio from "./audio";
import chineseRecognition from "./chinese";
import codeProduction from "./code";
import imageRecognition from "./image";
import japaneseRecognition from "./japanese";
import languageRecognition from "./language";
import equationTeX from "./tex";
import { IMarketItem } from "./types";

export function languageLayoutsWithFields(): IMarketItem[] {
  return [languageRecognition(), japaneseRecognition(), chineseRecognition()];
}

export function mediaLayoutsWithFields(): IMarketItem[] {
  return [imageRecognition(), listeningAudio()];
}

export function programmingLayoutsWithFields(): IMarketItem[] {
  return [codeProduction()];
}

export function mathLayoutsWithFields(): IMarketItem[] {
  return [equationTeX()];
}
