import { Operation } from "@models/operation";
import { IKnolInsertOperation, IKnolUpdateOperation } from "@operations/knol";
import { DeckFieldMap } from "fields/lib";
import Lib from "lib";
import { ID } from "types/ID";
import { replaceBlobElementsWithPlaceholders } from "./lib";

export async function genCardInsertOrEditOperation({
  deckId,
  values,
  tags,
  knolID,
  fmap,
}: {
  deckId: string;
  values: Record<string, string>;
  tags: string[];
  knolID?: ID;
  fmap: DeckFieldMap;
}): Promise<IKnolInsertOperation | IKnolUpdateOperation> {
  const valuesWithBlobsReplacedByPlaceholders: Record<string, string> = {};
  for (const key in values) {
    const rawValue = values[key];
    valuesWithBlobsReplacedByPlaceholders[key] = rawValue;
    const type = fmap[key]?.type;
    if (type === "richtext" || type === undefined) {
      valuesWithBlobsReplacedByPlaceholders[key] = replaceBlobElementsWithPlaceholders(rawValue);
    }
  }
  if (knolID) {
    return {
      ...Operation.operationDefaults(),
      type: "UPDATE",
      object_type: "knol",
      object_parameters: {
        knol_id: knolID ?? Lib.uuid16(),
        deck_id: deckId,
        values: valuesWithBlobsReplacedByPlaceholders,
        knol_tags: tags,
      },
    };
  }
  return {
    ...Operation.operationDefaults(),
    type: "INSERT",
    object_type: "knol",
    object_parameters: {
      knol_id: Lib.uuid16(),
      deck_id: deckId,
      modified_at: new Date().toISOString(),
      created_at: new Date().toISOString(),
      values: valuesWithBlobsReplacedByPlaceholders,
      knol_tags: tags,
    },
  };
}
