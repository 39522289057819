import { showPrompt } from "@components/prompt";
import logEvent from "analytics";
import L10n from "localization";

export const supportsRecording =
  navigator.mediaDevices &&
  typeof navigator.mediaDevices.getUserMedia === "function" &&
  MediaRecorder
    ? true
    : false;

export async function recordAudio(onStop: (blob: Blob) => void): Promise<void> {
  logEvent("record_audio_pressed");

  if (!supportsRecording) {
    logEvent("record_audio_not_supported");
    alert(L10n.localize((s) => s.general.audioRecordingNotSupported));
    return;
  }

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

    const chunks: Blob[] = [];
    const recorder = new MediaRecorder(stream);
    recorder.ondataavailable = (e) => {
      if (e.data) {
        chunks.push(e.data);
      }
    };
    recorder.onstop = () => {
      const mimeType = "audio/mp4";
      const blob = new Blob(chunks, { type: mimeType });
      onStop(blob);
      stream.getTracks().forEach((track) => track.stop());
      logEvent("record_audio_stopped", {
        numBytes: blob.size,
        mimeType,
      });
    };
    recorder.start(1000);

    showPrompt({
      prompt: L10n.localize((s) => s.general.stopRecording),
      promptType: "alert",
      title: L10n.localize((s) => s.general.recording),
      callback: () => {
        recorder.stop();
      },
    });
  } catch (err) {
    alert("Failed to record.");
  }
}
