export type Platform = "web" | "ios" | "android";

declare const PLATFORM_TYPE: Platform;
declare const CLIENT_VERSION: string;
declare const CLIENT_VERSION_HASH: string;
declare const API_ENDPOINT: string;
declare const BLOBS_ENDPOINT: string;
declare const PERF_PROFILE: string;
declare const SENTRY_DSN: string;
declare const SENTRY_RELEASE: string;
declare const CLIENT_ENV: string;
declare const HELP_ENDPOINT: string;
declare const CONTACT_ENDPOINT: string;
declare const DEVICE_NAME: string;
declare const TTS_ENDPOINT: string;
declare const TRANSLATION_ENDPOINT: string;

const RESPONSES = {
  FAIL: 0,
  HARD: 1,
  GOOD: 2,
  EASY: 3,
  AUTO: 4,
} as const;

export type TResponse = keyof typeof RESPONSES;
export type TResponseValue = (typeof RESPONSES)[TResponse];

const { FAIL, HARD, GOOD, EASY, AUTO } = RESPONSES;
export const baseScores: Record<TResponseValue, number> = {
  [FAIL]: 0,
  [HARD]: 0.5,
  [GOOD]: 0.75,
  [EASY]: 0.95,
  [AUTO]: -1,
};

// Struct to track which X-AnkiApp-Msg IDs have been shown.
// Used to suppress showing the same message multiple times per session.
const msgIDsShownInSession: Record<string, boolean> = {};

export const MAX_FILE_BYTES = 10 * 1024 * 1024;

export const preInstalledLayoutIDs = {
  frontToBackLayoutID: "b38854d62caf44d3bb36a8d9609b004e",
  backToFrontLayoutID: "61d97f8c245f46f0a91950e4f4d60ec5",
  legacyFrontToBackLayoutID: "acf3bd5e3fc94d64a9b21d7a531a6563",
  legacyBackToFrontLayoutID: "a4abf3245c0340c39e43216fcd714dfc",
} as const;

const Globals = {
  apiEndpoint: API_ENDPOINT,
  blobsEndpoint: BLOBS_ENDPOINT,
  ttsEndpoint: TTS_ENDPOINT,
  translationEndpoint: TRANSLATION_ENDPOINT,
  helpEndpoint: HELP_ENDPOINT,
  contactEndpoint: CONTACT_ENDPOINT,
  deviceNameOverride: DEVICE_NAME, // For manually specifying a name for Mac or Windows builds
  swipingScreen: false,
  updatingDecks: false,
  remoteDeckCache: null,
  wasUpgradeNagShown: false,
  msgIDsShownInSession,
  decksDatabase: null,
  blobsDatabase: null,
  environment: CLIENT_ENV,
  version: CLIENT_VERSION,
  versionHash: CLIENT_VERSION_HASH,
  platform: PLATFORM_TYPE,
  sentryDSN: SENTRY_DSN,
  sentryRelease: SENTRY_RELEASE,
  datadogRUMToken: "pub278c7029addc1006ed38a26bf86c5b12",
  datadogAPPID: "e7689556-839e-4a97-83b8-77278f13648e",
  datadogClientToken: "pube8c9360bb24993369d5747794cfff8d1",
  ROW_TYPE: { DECK: 1, SUBSCRIPTION: 3 },
  basicResponseType: {
    id: "5b4f816026f511e2aac3001e52fffe46",
    name: "basic",
  },
  dbSchemaVersion: 3, // 3: Added DecksKnol deck_id index
  RESPONSES,
  RESPONSE_NAMES: {
    0: "fail",
    1: "hard",
    2: "good",
    3: "easy",
    4: "auto",
  },
  tabletWidthThresholdPx: 768,
  desktopWidthThresholdPx: 992,
  largeWidthThresholdPx: 1200,
  emailValidationRegex: new RegExp(
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i,
  ), // From http://www.regular-expressions.info/email.html.
  NUM_DASHBOARD_RESPONSE_HISTORY_DAYS: 7,
  perfProfile: PERF_PROFILE,
  feedbackPromptReviewThreshold: 800,
} as const;

export type TResponseName = (typeof Globals.RESPONSE_NAMES)[TResponseValue];

export function isMacCatalyst(): boolean {
  return Globals.deviceNameOverride === "mac-catalyst";
}

export function isMacAppStoreElectron(): boolean {
  return Globals.deviceNameOverride === "mac-app-store-electron";
}

export default Globals;
