import L10n from "localization";

export const fieldNames = {
  Speech: () => L10n.localize((s) => s.preInstalledLayouts.speechField),
  Meaning: () => L10n.localize((s) => s.preInstalledLayouts.meaningField),
  Image: () => L10n.localize((s) => s.preInstalledLayouts.imageField),
  Back: () => L10n.localize((s) => s.general.back),
  Front: () => L10n.localize((s) => s.general.front),
  Hint: () => L10n.localize((s) => s.field.hint),
  Equation: () => L10n.localize((s) => s.preInstalledLayouts.equationField),
  Code: () => L10n.localize((s) => s.preInstalledLayouts.codeField),
  Japanese: () => L10n.localize((s) => s.field.typeJapanese),
  Chinese: () => L10n.localize((s) => s.field.typeChinese),
  Translation: () => L10n.localize((s) => s.general.translation),
  Text: () => L10n.localize((s) => s.field.typeText),
};
