import React from "react";
import Katex from "katex";
import "@css/katex.min.css";

const inlineMathModeRegex = /^\s*\$\s*(.*)\s*\$\s*/;

interface ITexWrapperProps {
  children: React.ReactElement;
}
export default function TexWrapper({ children }: ITexWrapperProps): React.ReactElement {
  const el = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!el.current) {
      return;
    }

    let tex = el.current.textContent ?? "";

    // Strip leading and trailing $'s from TeX string. In TeX, surrounding something with $'s puts it into math mode, but KaTeX comes in math mode by default, so that will cause an error like "KaTeX parse error: $ within math mode".
    const match = tex.match(inlineMathModeRegex);
    if (match?.[1]) {
      tex = match[1];
    }

    try {
      Katex.render(tex, el.current);
    } catch {
      // Failed to parse tex.
    }
  }, []);

  return <div ref={el}>{children}</div>;
}
