import { Capacitor } from "@capacitor/core";
import { Keyboard, KeyboardInfo, KeyboardResize, KeyboardResizeOptions } from "@capacitor/keyboard";
import Globals from "globals";
import { useEffect } from "react";

export default function useDisableKeyboardScroll(
  onShow?: (heightPx: number) => void,
  onHide?: () => void,
) {
  useEffect(() => {
    if (!Capacitor.isPluginAvailable("Keyboard")) {
      return;
    }
    if (Globals.platform !== "ios") {
      return;
    }

    function handleShow(info: KeyboardInfo) {
      onShow?.(info.keyboardHeight);
    }

    function handleHide() {
      onHide?.();
    }

    let resizeMode: KeyboardResizeOptions;
    async function engage() {
      resizeMode = await Keyboard.getResizeMode();
      await Keyboard.setResizeMode({ mode: KeyboardResize.None });
      await Keyboard.setScroll({ isDisabled: true });
      Keyboard.addListener("keyboardWillShow", handleShow);
      Keyboard.addListener("keyboardWillHide", handleHide);
    }

    async function disengage() {
      await Keyboard.setResizeMode({ mode: resizeMode.mode });
      await Keyboard.setScroll({ isDisabled: false });
      Keyboard.removeAllListeners();
    }

    engage();
    return () => {
      disengage();
    };
  }, []);
}
