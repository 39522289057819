import { IonButton, useIonAlert } from "@ionic/react";
import { createFolder, formFullFolderPath, join } from "@models/folder";
import L10n from "localization";
import React from "react";
import { useHistory } from "react-router";

export function useNewFolderHandler(underFolder?: string) {
  const [present] = useIonAlert();
  const history = useHistory();

  function handleNewFolder() {
    const inputName = "folder";
    present({
      header: L10n.localize((s) => s.folder.singular),
      inputs: [{ name: inputName }],
      buttons: [
        {
          text: L10n.localize((s) => s.actions.cancel),
          role: "cancel",
        },
        {
          text: L10n.localize((s) => s.actions.ok),
          role: "confirm",
        },
      ],
      onDidDismiss: async (evt) => {
        if (evt?.detail && evt.detail.role !== "cancel") {
          const folderName = evt.detail.data?.values?.[inputName];
          if (folderName) {
            const folderPath = underFolder ? [underFolder] : [];
            folderPath.push(folderName);
            await createFolder(join(folderPath));
            const fullPath = formFullFolderPath(folderName, underFolder);
            history.push(`/folders/${encodeURIComponent(fullPath)}`);
          }
        }
      },
    });
  }

  return handleNewFolder;
}

export default function NewFolderButton(): JSX.Element {
  const handleNewFolder = useNewFolderHandler();
  return <IonButton onClick={handleNewFolder}>{L10n.localize((s) => s.actions.new)}</IonButton>;
}
