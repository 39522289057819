import CardPreview from "@cardRendering/cardPreview";
import LayoutStyle from "@components/layoutStyle";
import { IonIcon } from "@ionic/react";
import { debounce } from "@lib/debounce";
import { mapIterable, takeFirstN } from "@lib/iterable";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { useIsPhone } from "hooks/util/useMediaQuery";
import { checkmarkCircle } from "ionicons/icons";
import React, { useCallback, useState } from "react";
import { ID } from "types/ID";

interface IProps {
  deck: IDeck;
  filteredKnols: MapView<ID, IKnol>;
  activeSide: 0 | 1;
  cardMarginPx: number;
  cardWidthPx: number;
  cardHeightPx: number;
  fontSizePx: number;
  query: string | undefined;
  inSelectMode?: boolean;
  toggleKnolSelected: (knolID: ID) => void;
  selectedKnolIDs: Set<ID>;
}
export default function CardHolder(props: IProps): JSX.Element {
  const { deck, filteredKnols, activeSide, query, cardWidthPx, cardHeightPx, cardMarginPx } = props;

  if (!deck || !filteredKnols) {
    return <></>;
  }

  const cardHolder = React.useRef<HTMLDivElement>(null);
  const isPhone = useIsPhone();

  const initMaxEls = 160;
  const [maxNumEls, setMaxNumEls] = useState(initMaxEls);
  const firstNKnols = takeFirstN(props.filteredKnols, maxNumEls);
  const cardEls = Array.from(
    mapIterable(firstNKnols, ([, knol]) => {
      const selected = props.selectedKnolIDs.has(knol.id);
      return (
        <div key={knol.id} style={{ position: "relative" }}>
          <CardPreview
            key={knol.id}
            query={query}
            visibleSide={activeSide}
            deck={deck}
            knol={knol}
            showHeader
            isClickable
            cardWidth={cardWidthPx}
            cardHeight={cardHeightPx}
            onClick={props.inSelectMode ? () => props.toggleKnolSelected(knol.id) : undefined}
            disableAudioControlsRendering={isPhone}
          />
          {selected && props.inSelectMode ? (
            <div
              onClick={() => props.toggleKnolSelected(knol.id)}
              style={{
                zIndex: 100,
                position: "absolute",
                cursor: "pointer",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255, 255, 255, 0.5)",
              }}
            >
              <IonIcon
                icon={checkmarkCircle}
                color="primary"
                size="large"
                style={{ position: "absolute", bottom: 12, right: 12 }}
              />
            </div>
          ) : undefined}
        </div>
      );
    }),
  );

  const layoutStyles = [] as JSX.Element[];
  const layouts = deck?.layouts ?? [];
  for (const layout of layouts) {
    layoutStyles.push(
      <LayoutStyle
        key={layout.id}
        layoutID={layout.id}
        css={layout.style || ""}
        fontSize={props.fontSizePx}
      />,
    );
  }

  const handleScroll = useCallback(() => {
    const [deb] = debounce(() => {
      if (!cardHolder.current) {
        return;
      }
      const { scrollTop, scrollHeight, clientHeight } = cardHolder.current;
      if (scrollTop + clientHeight >= scrollHeight - clientHeight * 0.1) {
        setMaxNumEls((n) => n + 50);
      }
    }, 25);
    return deb();
  }, []);

  const style = {
    overflowY: "auto",
    overflowX: "hidden",
    textAlign: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: cardMarginPx * 2,
    padding: cardMarginPx * 2,
  } as React.CSSProperties;

  return (
    <>
      {layoutStyles}
      <div ref={cardHolder} style={style} onScroll={handleScroll}>
        {cardEls}
      </div>
    </>
  );
}
