import * as Sentry from "@sentry/browser";
import { isIndexedDBRefreshRequiredMessage } from "./indexedDBRefreshRequired";

export default function sentryBeforeSend(
  event: Sentry.ErrorEvent,
): Sentry.ErrorEvent | PromiseLike<Sentry.ErrorEvent> | null {
  const firstExceptionValue = event?.exception?.values?.[0]?.value;

  // Filter out this useless message so it doesn't blow our quota.
  if (firstExceptionValue?.includes("ResizeObserver loop limit exceeded")) {
    return null;
  }

  if (isIndexedDBRefreshRequiredMessage(firstExceptionValue)) {
    return null;
  }

  if (firstExceptionValue?.includes("Notifications are not allowed for this application")) {
    return null;
  }

  // Extract message from SQLError's.
  if (event.message?.toString() === "[object SQLError]") {
    // HACK: when there's an error in WebSQL, it previously showed up in Sentry as "[object SQLError]".
    event.message = (event.message as unknown as SQLError).message as string;
  }

  // TODO: Repro and handle these too.
  // The above handling caught exceptions that were SQLErrors.
  // Below handles promise rejections that reject with a SQLError, e.g. https://ankiapp.sentry.io/issues/1473534860/events/b6f2485f1a0b4b0fb4b22635c4e97b14/?project=1955958&query=is%3Aunresolved+app_version%3A7.3.7&referrer=previous-event&sort=freq&statsPeriod=14d&stream_index=2.
  // if ((event as any).reason?.toString() === "[object SQLError]") {
  //   event.message = (event.reason as unknown as SQLError).message as string;
  // }

  return event;
}
