export function rootFolders(folders: string[]): string[] {
  const roots = new Set<string>();
  for (const folder of folders) {
    const root = folder.split(subFolderMagicSeparator)[0];
    roots.add(root);
  }
  return Array.from(roots);
}

export const subFolderMagicSeparator = "::";
export const stringWithoutSubfolderMagicSeparatorRegex = new RegExp("^(?!.*::).*$");
