import { useState } from "react";

// HACK: this takes a state val and it's updater, and splits it,
// like the Unix tee command, returning a mirrored copy of the val
// and an updater that will update both the vals simultaneously.

// This is useful for Ionic's <IonNavLink>'s, which don't propagate
// state changes down to the components they render.

export default function useStateTee<T>(val: T, setVal: React.Dispatch<T>): [T, React.Dispatch<T>] {
  const [tee, setTee] = useState(val);
  const handleChange = (newVal: T) => {
    setTee(newVal);
    setVal(newVal);
  };

  return [tee, handleChange];
}
