import NestedNav from "@components/nestedNav";
import React from "react";
import L10n from "localization";
import FontSizeControl from "@components/fontSizeControl";
import useStateTee from "hooks/util/useStateTee";

interface IProps {
  fontSizePx: number;
  onFontSizeChange: (px: number) => void;
}
export default function FontSizeNav({ fontSizePx, onFontSizeChange }: IProps) {
  // HACK: Ionic's Navs don't update properly when their props change :(
  const [px, setPx] = useStateTee(fontSizePx, onFontSizeChange);

  return (
    <NestedNav title={L10n.localize((s) => s.general.fontSize)}>
      <FontSizeControl fontSizePx={px} onFontSizeChange={setPx} />
    </NestedNav>
  );
}
