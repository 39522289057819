import MiddleEllipse from "@components/middleEllipse";
import { MenuButton } from "@components/screenMenu";
import Subtitle from "@components/subtitle";
import { IonButton, IonList, IonNavLink } from "@ionic/react";
import cyAttr from "@lib/cyAttr";
import { Deck, DeckFields, IDeck, fieldMap } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import CardPreview from "cardRendering/cardPreview";
import ScreenComponent from "components/screen";
import { useIsLegacyDeck } from "fields/magicLayout";
import FieldValueEditor from "fields/valueEditors/fieldValueEditor";
import useKeyboardHeight from "hooks/useKeyboardHeight";
import {
  banOutline,
  bandageOutline,
  bugOutline,
  closeCircleOutline,
  copyOutline,
  eyeOutline,
  pricetagsOutline,
  star,
  starOutline,
} from "ionicons/icons";
import trimFinalPathComponent from "lib/trimFinalPathComponent";
import L10n from "localization";
import React, { useCallback, useRef } from "react";
import { getAlwaysHideCardPreview } from "settings";
import CardPreviewNav from "./cardPreviewNav";
import { EditorRefMap } from "./cardScreenViewController";
import Info from "./info";

interface IProps {
  deck: IDeck | undefined;
  knol?: IKnol;
  onClose: () => void;
  onReportCard?: () => void;
  onAddTag?: () => void;
  addTag?: (tag: string) => void;
  removeTag?: (tag: string) => void;
  onCopyCard?: (knols: IKnol[]) => Promise<void>;
  onRemoveCard?: () => void;
  isStarred?: boolean;
  isIgnored?: boolean;
  // Maybe can split these out for non-editable case.
  onSave?: () => void;
  tags: string[];
  editorRefs?: React.MutableRefObject<EditorRefMap>;
  fieldOrder?: string[];
  fields: DeckFields;
  handleChange: (key: string, value: string) => void;
  handleRegisterBlob: (id: string, blob: Blob) => Promise<string>;
  editingValues: Record<string, string>;
  edited: boolean;
}

export default function CardScreenView({
  deck,
  knol,
  onClose,
  onReportCard,
  onAddTag,
  onCopyCard,
  onRemoveCard,
  addTag,
  removeTag,
  editorRefs,
  fieldOrder,
  fields,
  handleChange,
  handleRegisterBlob,
  editingValues,
  tags,
  onSave,
  edited,
}: IProps) {
  const editable = deck?.status === Deck.STATUS_PRIVATE;
  const deckDisplayName = Deck.displayName(deck?.name || "");
  const legacy = useIsLegacyDeck(deck);
  const isStarred = tags?.includes(Knol.MARKED_TAG);
  const isIgnored = tags?.includes(Knol.IGNORE_TAG);

  // Editor
  const fmap = fieldMap(fields);

  // Used for scrolling to active field when keyboard opens.
  const activeEditor = useRef<string>();
  const handleEditorFocus = useCallback((name: string) => {
    activeEditor.current = name;
  }, []);

  // Smoothly scroll to active field when keyboard opens.
  const handleKeyboardShow = useCallback(() => {
    if (activeEditor.current) {
      const editor = editorRefs?.current[activeEditor.current];
      editor?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [editorRefs?.current]);
  const kbHeightPx = useKeyboardHeight(handleKeyboardShow);

  const handleFieldClick = useCallback(
    (key: string) => {
      const ed = editorRefs?.current?.[key]?.current;
      if (!ed) {
        return;
      }
      // Try focusing on whatever text input is available.
      ed.getElementsByTagName("input")?.[0]?.focus();
      ed.getElementsByTagName("textarea")?.[0]?.focus();
      // Focus on TinyMCE.
      ed.getElementsByTagName("iframe")?.[0]?.contentDocument?.body?.focus();
    },
    [editorRefs?.current],
  );

  const allRichText = fields.every((field) => field.type === "richtext");
  const hidePreview = legacy || allRichText || getAlwaysHideCardPreview();

  return (
    <ScreenComponent
      title={L10n.localize((s) => s.card.singular)}
      noBigTitle
      defaultBackLink={trimFinalPathComponent(location.pathname)}
      leftButton={
        editable ? (
          <IonButton onClick={onClose} color={edited ? "danger" : undefined}>
            {edited
              ? L10n.localize((s) => s.actions.cancel)
              : L10n.localize((s) => s.actions.close)}
          </IonButton>
        ) : (
          // HACK: prevents back button from appearing.
          <></>
        )
      }
      rightButton={
        editable && onSave ? (
          <IonButton disabled={!edited} onClick={onSave}>
            {L10n.localize((s) => s.actions.save)}
          </IonButton>
        ) : (
          <IonButton onClick={onClose}>{L10n.localize((s) => s.general.done)}</IonButton>
        )
      }
      content={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingBottom: "env(safe-area-inset-bottom)",
          }}
        >
          <Subtitle text={<MiddleEllipse text={deckDisplayName} />} />
          {hidePreview || !deck ? undefined : (
            <div
              style={{ height: 320, flexDirection: "row", display: "flex", padding: 12, gap: 12 }}
            >
              <CardPreview
                isClickable
                onClick={() => null}
                fill
                cardWidth={"48%"}
                disableAudioControlsRendering // Don't render TTS until save. Rationale is that the user can't change it anyway, so we can avoid firing a bunch of intermediate requests and dealing with state changes this way.
                deck={deck}
                persistDownloadedBlobs
                editable={editable}
                disableTouchEvents={false}
                onFieldClick={handleFieldClick}
                autoplayAudio={false}
                knol={knol}
                editingValues={editingValues}
                unfold
                border
              />
            </div>
          )}
          <IonList {...cyAttr("field-editors")}>
            {fieldOrder?.map((name, i) => {
              const field = fmap[name];
              const value = editingValues?.[name];
              return (
                <FieldValueEditor
                  key={name}
                  field={field}
                  value={value}
                  name={name}
                  onChange={handleChange}
                  registerBlob={handleRegisterBlob}
                  editorRef={editorRefs?.current[name]}
                  onFocus={handleEditorFocus}
                  autoFocus={i === 0}
                  editable={editable}
                />
              );
            })}
          </IonList>
          {removeTag ? <Info tags={tags} knol={knol} removeTag={removeTag} /> : undefined}
          <IonList>
            {onReportCard ? (
              <MenuButton
                detail={false}
                label={L10n.localize((s) => s.actions.reportError)}
                icon={bugOutline}
                onClick={onReportCard}
              />
            ) : undefined}
            {onCopyCard ? (
              <MenuButton
                detail={false}
                label={L10n.localize((s) => s.actions.copy)}
                icon={copyOutline}
                onClick={async () => await onCopyCard(knol ? [knol] : [])}
              />
            ) : undefined}
            {onAddTag ? (
              <MenuButton
                detail={false}
                label={L10n.localize((s) => s.tag.singular)}
                icon={pricetagsOutline}
                onClick={onAddTag}
              />
            ) : undefined}
            {!!removeTag && !!addTag ? (
              <MenuButton
                detail={false}
                label={
                  isStarred
                    ? L10n.localize((s) => s.actions.unstar)
                    : L10n.localize((s) => s.actions.star)
                }
                icon={isStarred ? star : starOutline}
                onClick={() => {
                  isStarred ? removeTag(Knol.MARKED_TAG) : addTag(Knol.MARKED_TAG);
                }}
              />
            ) : undefined}
            {!!removeTag && !!addTag ? (
              <MenuButton
                detail={false}
                label={
                  isIgnored
                    ? L10n.localize((s) => s.actions.unignore)
                    : L10n.localize((s) => s.actions.ignore)
                }
                icon={isIgnored ? bandageOutline : banOutline}
                onClick={() => {
                  isIgnored ? removeTag(Knol.IGNORE_TAG) : addTag(Knol.IGNORE_TAG);
                }}
              />
            ) : undefined}
            {deck && deck.config ? (
              <IonNavLink
                routerDirection="forward"
                component={() => (
                  <CardPreviewNav
                    deck={deck}
                    knol={{
                      id: knol?.id ?? "preview",
                      deck_id: deck.id,
                      values: { ...knol?.values, ...editingValues },
                    }}
                  />
                )}
              >
                <MenuButton
                  detail
                  label={L10n.localize((s) => s.actions.preview)}
                  icon={eyeOutline}
                />
              </IonNavLink>
            ) : undefined}
            {onRemoveCard ? (
              <MenuButton
                lines="none"
                detail={false}
                label={L10n.localize((s) => s.actions.remove)}
                icon={closeCircleOutline}
                color="danger"
                onClick={onRemoveCard}
              />
            ) : undefined}
          </IonList>
          <div style={{ height: kbHeightPx }} />
        </div>
      }
    />
  );
}
