import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";

// NOTE: this file handles deeplinks by triggering the router with the correct path.
// The difference between this file and src/ts/screens/deeplink.tsx is that that one
// handles being routed to the deeplink path, so the web app can use it too.

const deeplinkTLD = "ankiapp.com";
export default function DeeplinkHandler(): null {
  const history = useHistory();

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      const { url } = event;
      const pathAndQueryArgs = url.split(deeplinkTLD)[1];
      if (pathAndQueryArgs) {
        history.push(pathAndQueryArgs);
      }
    });
  }, [history]);

  return null;
}
