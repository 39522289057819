import React from "react";
import Empty from "@components/empty";
import {
  IonAlert,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonProgressBar,
  IonicSafeString,
} from "@ionic/react";
import { trophyOutline } from "ionicons/icons";
import L10n from "localization";
import { IMilestone } from "@models/user";

export function milestonePop(
  headline?: string,
  achieved_at?: string,
  description?: string,
  iconURL?: string,
): IonicSafeString {
  return new IonicSafeString(`
  <div style="text-align: center;">
    <img src="${iconURL}" alt="Milestone Icon" style="width: 128px; height: 128px; margin-bottom: 4px;"/>
    <div style="font-size: 16px; font-weight: bold; padding-bottom: 4px;">${headline}</div>
    <div style="padding: 4px 0px">${achieved_at?.split("T")[0]}</div>
    <div style="font-size: 14px; padding: 12px; background-color: var(--ion-background-color); border-radius: 12px;">${description}</div>
</div>`);
}

export interface IMilestones {
  [key: string]: IMilestone;
}

export const formatMilestoneDate = (date?: Date) => {
  if (!date) {
    return "";
  }
  return new Intl.DateTimeFormat(L10n.currentLocale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

export default function Milestones({ milestones }: { milestones: IMilestones | undefined | null }) {
  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.general.milestones)}</IonListHeader>
      {milestones === undefined ? (
        <IonProgressBar type="indeterminate" />
      ) : milestones !== null && Object.keys(milestones).length === 0 ? (
        <Empty text={L10n.localize((s) => s.general.milestonesNone)} />
      ) : (
        milestones &&
        Object.entries(milestones)
          .sort(([, milestoneA], [, milestoneB]) => {
            const dateA = new Date(milestoneA.achieved_at);
            const dateB = new Date(milestoneB.achieved_at);
            return dateB.getTime() - dateA.getTime();
          })
          .map(([key, milestone]) => (
            <React.Fragment key={key}>
              <IonItem id={`present-${key}`}>
                {milestone.icon ? (
                  <img
                    src={milestone.icon}
                    alt={milestone.description || key}
                    slot="start"
                    style={{ width: "24px", height: "24px" }}
                  />
                ) : (
                  <IonIcon icon={trophyOutline} slot="start" />
                )}
                <IonLabel>{milestone.headline || key}</IonLabel>
                <div>{formatMilestoneDate(milestone.achievedAtDate)}</div>
              </IonItem>
              <IonAlert
                trigger={`present-${key}`}
                message={milestonePop(
                  milestone.headline ?? "",
                  formatMilestoneDate(milestone.achievedAtDate),
                  milestone.description ?? "",
                  milestone.icon ?? "",
                )}
                buttons={[L10n.localize((s) => s.actions.ok)]}
              />
            </React.Fragment>
          ))
      )}
    </IonList>
  );
}
