import React from "react";
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { Op } from "@models/operation";

export default function DebugOperation({ op }: { op: Op }): JSX.Element {
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton />
          </IonButtons>
          <IonTitle>Operation</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>ID</IonLabel>
            <IonNote>{op.id}</IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>Type</IonLabel>
            <IonNote>{op.type}</IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>Object Type</IonLabel>
            <IonNote>{op.object_type}</IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>Created At</IonLabel>
            <IonNote>{op.created_at}</IonNote>
          </IonItem>
          <IonItem>
            <IonLabel>Timestamp</IonLabel>
            <IonNote>{op.timestamp}</IonNote>
          </IonItem>
        </IonList>
        <h2>Parameters</h2>
        <p style={{ fontFamily: "monospace" }}>{op.object_parameters as any}</p>
      </IonContent>
    </>
  );
}
