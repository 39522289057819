import { ToastOptions, useIonToast } from "@ionic/react";
import { HookOverlayOptions } from "@ionic/react/dist/types/hooks/HookOverlayOptions";
import L10n from "localization";
import { useCallback } from "react";

type PresentFunc = (options: ToastOptions & HookOverlayOptions) => Promise<void>;
export default function useDismissibleToast(): [PresentFunc, () => Promise<void>] {
  const [ionPresent, dismiss] = useIonToast();

  const present: PresentFunc = useCallback(
    (options) => {
      return ionPresent({
        ...{
          swipeGesture: "vertical",
          buttons: [
            {
              text: L10n.localize((s) => s.actions.ok),
              role: "cancel",
              side: "end",
            },
          ],
        },
        ...options,
      });
    },
    [ionPresent],
  );

  return [present, dismiss];
}
