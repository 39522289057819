import React from "react";
import Style from "style";

function Donut({
  radius,
  strokeWidth,
  strokeColor,
  progress,
  style,
}: {
  radius: number;
  strokeWidth: number;
  strokeColor: string;
  progress: number;
  style: React.CSSProperties;
}) {
  const circumference = 2 * Math.PI * radius;
  const dashArray = `${circumference} ${circumference}`;
  const dashOffset = circumference * progress;

  const s = radius + strokeWidth;

  return (
    <svg width={s * 2} height={s * 2} style={style}>
      <circle
        cx={radius + strokeWidth}
        cy={radius + strokeWidth}
        r={radius}
        fill="transparent"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
        strokeDasharray={dashArray}
        strokeDashoffset={dashOffset}
        transform={`rotate(-90 ${s} ${s}) scale(1,-1) translate(0, -${s * 2})`}
        // NOTE: the scale and transform mirror horizontally.
      />
    </svg>
  );
}

function Pie({
  progress,
  color,
  style,
}: {
  progress: number;
  color?: string;
  style?: React.CSSProperties;
}) {
  const a = (progress * 360) % 360;
  const r = (a * Math.PI) / 180;
  const x = Math.sin(r) * 125;
  const y = Math.cos(r) * -125;
  const mid = a > 180 ? "1" : "0";
  const res = `M 0 0 v -125 A 125 125 1 ${mid} 1 ${x} ${y} z`;

  return (
    <svg height="20" key="pie" style={style} viewBox="0 0 256 256" width="20">
      <circle
        cx={128}
        cy={128}
        key="crust"
        r={128}
        style={{
          fill: color || Style.colors.secondaryFg,
        }}
      />
      <path
        d={res}
        key="filling"
        style={{ fill: Style.colors.subheaderBg }}
        transform="translate(128, 128)"
      />
    </svg>
  );
}

interface IProps {
  progress: number;
  color?: string;
  style?: React.CSSProperties;
  donut?: boolean;
}

export default function ProgressPie({ progress, color, style, donut }: IProps) {
  const pieStyle = {
    opacity: 0.5,
    zIndex: 9,
    marginLeft: 8,
    ...style,
  } as React.CSSProperties;

  if (donut) {
    return (
      <Donut
        radius={7}
        progress={progress}
        style={pieStyle}
        strokeColor={color || Style.colors.secondaryFg}
        strokeWidth={2}
      />
    );
  }

  return <Pie style={pieStyle} progress={progress} color={color} />;
}
