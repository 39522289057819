import Grade from "@components/grade";
import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
} from "@ionic/react";
import { IDeck } from "@models/deck";
import DeckDownloadScreenFC from "@screens/deckDownload";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import { trash } from "ionicons/icons";
import React, { useMemo } from "react";
import { IoCloudOutline } from "react-icons/io5";
import { useHistory } from "react-router";
import { useGradePercent } from "./useGradePercent";

interface IStudyGroupDeckItemProps {
  key: string;
  deck: IDeck;
  name: string;
  type: string;
  href?: string;
  onRemoveDeck?: (deckId: string) => void;
}

export default function StudyGroupDeckItem(props: IStudyGroupDeckItemProps): JSX.Element {
  const { name, href, type, onRemoveDeck, deck } = props;

  const decks = useMemo(() => [deck], [deck]);
  const gradePct = useGradePercent(decks, false);

  const history = useHistory();
  const [presentDownloadDeckModal] = useIonCardModalWithDismiss(DeckDownloadScreenFC, {
    deckID: deck.id,
    history: history,
  });

  const isNonLocal = !["local", "group", "shared-local"].includes(type);

  return (
    <IonItemSliding>
      <IonItem
        detail
        onClick={() => {
          if (isNonLocal) {
            presentDownloadDeckModal();
          } else if (href) {
            history.push(href);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <IonLabel>{name}</IonLabel>
        <IonNote slot="end">
          {isNonLocal ? (
            <IoCloudOutline size={24} opacity={0.5} />
          ) : (
            <Grade percent={gradePct} fontSize="1rem" showPercent={true} />
          )}
        </IonNote>
      </IonItem>
      {onRemoveDeck && (
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={() => onRemoveDeck(deck.id)}>
            <IonIcon slot="icon-only" icon={trash} />
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
}
