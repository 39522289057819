import { IonLabel, IonListHeader, IonProgressBar, useIonAlert } from "@ionic/react";
import { IMilestone } from "@models/user";
import { ReviewHistory } from "lib/streaks";
import L10n from "localization";
import React from "react";
import { getStudyGoal } from "studyGoals";
import moment from "../../../node_modules/moment/moment";
import Style from "../style";
import { IMilestones, formatMilestoneDate, milestonePop } from "./stats/milestones";

const sidePadding = 4;

interface ICalendarDayProps {
  key: string | number;
  dayNum?: number;
  date?: string;
  numReviews: number;
  isToday?: boolean;
  isFuture?: boolean;
  spacer: boolean;
  milestones?: Map<string, IMilestone[]>;
}

class CalendarDay extends React.Component<ICalendarDayProps> {
  static defaultProps = {
    numReviews: 0,
    dayNum: -1,
    date: "",
    spacer: false,
  };

  render() {
    const { numReviews, spacer, isFuture, isToday, date, milestones } = this.props;

    let opacity = 1.0;
    if (numReviews > 0) {
      opacity = 0.4 + 0.6 * (Math.min(numReviews, 100.0) / 100.0);
    } else if (isFuture) {
      opacity = 0.6;
    }

    const goal = getStudyGoal();
    const metGoal = goal > 0 ? numReviews >= goal : numReviews > 0;

    const starSize = "1rem";

    const isMilestone =
      date && milestones && milestones.has(date) && (milestones.get(date) ?? []).length > 0;

    return (
      <div
        style={{
          display: "inline-block",
          width: "3rem",
          height: "2rem",
          fontSize: "1.1rem",
          textAlign: "right",
          marginTop: sidePadding,
          paddingRight: sidePadding,
          fontWeight: numReviews > 0 ? "bold" : undefined,
          position: "relative",
        }}
      >
        {spacer ? null : (
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: 2,
              border: isMilestone
                ? "2px solid yellow"
                : isToday
                  ? `1px dotted ${Style.colors.actionableFg}`
                  : undefined,
            }}
          >
            <div
              data-date={this.props.date}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: 2,
                backgroundColor: metGoal
                  ? Style.colors.actionableFg
                  : numReviews > 0
                    ? `${Style.colors.actionableBg}FF` // HEXA to add opacity
                    : Style.colors.headerBg,
                opacity,
              }}
            >
              {metGoal && (
                <svg
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "calc(50% - 2px)",
                    transform: "translate(-50%, -50%)",
                    width: starSize,
                    height: starSize,
                  }}
                  viewBox="0 0 24 24"
                >
                  <path
                    data-date={this.props.date}
                    d="M12 .587l3.668 7.431 8.332 1.21-6.001 5.845 1.416 8.265L12 18.896l-7.415 4.442 1.416-8.265-6.001-5.845 8.332-1.21z"
                    fill={Style.colors.headerBg}
                  />
                </svg>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

interface MonthProps {
  yr: number;
  mo: number;
  dateMap: ReviewHistory;
  milestones?: Map<string, IMilestone[]>;
}

const Month: React.FC<MonthProps> = ({ yr, mo, dateMap, milestones }) => {
  const monthStyle = {
    marginTop: 6,
    flex: "0 0 auto",
    marginLeft: 8,
  };

  const weekStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, "0");
  const day = now.getDate().toString().padStart(2, "0");
  const todayStr = `${year}-${month}-${day}`;

  const weeks: JSX.Element[] = [];
  let week: JSX.Element[] = [];

  const firstDateOfMonth = new Date(yr, mo, 1);

  // Pad the first week
  for (let d = 0; d < firstDateOfMonth.getDay(); d++) {
    week.push(<CalendarDay key={`spacer-${d}`} spacer={true} />);
  }

  const lastDateOfMonth = new Date(yr, mo + 1, 0);
  for (let day = 1; day <= lastDateOfMonth.getDate(); day++) {
    const currentDate = new Date(yr, mo, day);
    const ds = currentDate.toISOString().substring(0, 10);
    const num = dateMap.get(ds);
    week.push(
      <CalendarDay
        key={day}
        dayNum={day}
        date={ds}
        numReviews={num ?? 0}
        isToday={ds === todayStr}
        isFuture={ds > todayStr}
        milestones={milestones}
      />,
    );

    // Advance weeks if necessary.
    if (currentDate.getDay() === 6) {
      weeks.push(
        <div key={day} style={weekStyle}>
          {week}
        </div>,
      );
      week = [];
    }
  }

  if (week.length > 0) {
    // Pad the last week.
    let x = 0;
    while (week.length < 7) {
      week.push(<CalendarDay key={`finalspacer-${x}`} spacer={true} />);
      x += 1;
    }
    weeks.push(
      <div key="final-week" style={weekStyle}>
        {week}
      </div>,
    );
  }

  const monthName = new Intl.DateTimeFormat(L10n.getCurrentLocale(), { month: "long" }).format(
    firstDateOfMonth,
  );
  const yearName = new Intl.DateTimeFormat(L10n.getCurrentLocale(), { year: "numeric" }).format(
    firstDateOfMonth,
  );

  return (
    <div>
      <IonListHeader
        style={{
          paddingLeft: sidePadding,
          paddingRight: sidePadding,
        }}
      >
        <IonLabel
          style={{
            textAlign: "start",
            borderBottom: "4px solid var(--ion-color-light)",
            paddingBottom: 2,
          }}
        >
          <span style={{ marginLeft: 0, marginRight: 6 }}>{monthName}</span>
          <span style={{ color: "var(--ion-color-medium)" }}>{yearName}</span>
        </IonLabel>
      </IonListHeader>
      <div style={monthStyle}>{weeks}</div>
    </div>
  );
};

const processMilestones = (
  milestones: IMilestones | null | undefined,
): Map<string, IMilestone[]> => {
  const milestonesMap = new Map<string, IMilestone[]>();
  if (!milestones) {
    return milestonesMap;
  }

  Object.entries(milestones).forEach(([, milestone]) => {
    const dateKey = milestone.achieved_at;

    if (!milestonesMap.has(dateKey)) {
      milestonesMap.set(dateKey, []);
    }

    const existingMilestones = milestonesMap.get(dateKey);
    if (existingMilestones) {
      existingMilestones.push(milestone);
    }
  });

  return milestonesMap;
};

interface ICalendarProps {
  reviewHistory?: ReviewHistory | null;
  lastSyncedAt?: Date;
  milestones?: IMilestones | null;
}
export function Calendar(props: ICalendarProps) {
  const { reviewHistory, milestones } = props;
  const [presentAlert] = useIonAlert();

  if (!reviewHistory) {
    const now = new Date();
    const currentMonth = now.getMonth(); // Current month
    const currentYear = now.getFullYear(); // Current year

    // Previous month calculations
    const prevMonthDate = new Date(currentYear, currentMonth - 1, 1);
    const prevMonth = prevMonthDate.getMonth();
    const prevYear = prevMonthDate.getFullYear();

    const dateMapCurrentMonth = new Map();
    const currentMonthDays = new Date(currentYear, currentMonth + 1, 0).getDate();
    for (let day = 1; day <= currentMonthDays; day++) {
      const date = new Date(currentYear, currentMonth, day).toISOString().substring(0, 10);
      dateMapCurrentMonth.set(date, 0);
    }

    const milestonesMap = processMilestones(milestones);

    const monthComponents = [];

    const dateMapLastMonth = new Map();
    const lastMonthDays = new Date(prevYear, prevMonth + 1, 0).getDate();
    for (let day = 1; day <= lastMonthDays; day++) {
      const date = new Date(prevYear, prevMonth, day).toISOString().substring(0, 10);
      dateMapLastMonth.set(date, 0);
    }
    monthComponents.push(
      <Month
        key={prevYear + prevMonth}
        yr={prevYear}
        mo={prevMonth}
        dateMap={dateMapLastMonth}
        milestones={milestonesMap}
      />,
    );

    monthComponents.push(
      <Month
        key={currentYear + currentMonth}
        yr={currentYear}
        mo={currentMonth}
        dateMap={dateMapCurrentMonth}
        milestones={milestonesMap}
      />,
    );

    return (
      <>
        <div
          ref={(el) => {
            if (!el) {
              return;
            }
            el.scrollLeft = el.scrollWidth;
          }}
          style={{ display: "flex", overflowX: "scroll" }}
        >
          {monthComponents}
        </div>
        <IonProgressBar type="indeterminate" />
      </>
    );
  }

  const milestonesMap = processMilestones(milestones);

  const dateMap = reviewHistory;

  const today = moment(); // current date
  const thisMonth = today.month();
  const thisYear = today.year();

  // Determine the earliest month in reviewHistory or default to last month if reviewHistory is less extensive.
  let earliestReviewDate =
    reviewHistory && reviewHistory.size > 0
      ? moment(Array.from(reviewHistory.keys())[0])
      : moment().subtract(1, "months");

  // Adjust earliestReviewDate if it is the current month, ensure including the last month too
  if (earliestReviewDate.year() === thisYear && earliestReviewDate.month() === thisMonth) {
    earliestReviewDate = earliestReviewDate.subtract(1, "months"); // Move back to the last month
  }

  // Loop from earliestReviewDate to today (inclusive of the last month and the current month)
  const monthEls: React.ReactElement[] = [];
  for (let yr = earliestReviewDate.year(); yr <= thisYear; yr++) {
    // Start from the month of the earliestReviewDate or January if in a new year beyond the earliest review year.
    const startMonth = yr === earliestReviewDate.year() ? earliestReviewDate.month() : 0;
    // End in December or thisMonth if in the current year.
    const endMonth = yr === thisYear ? thisMonth : 11;

    for (let mo = startMonth; mo <= endMonth; mo++) {
      monthEls.push(
        <Month key={`${yr}-${mo}`} yr={yr} mo={mo} dateMap={dateMap} milestones={milestonesMap} />,
      );
    }
  }

  const handleCalendarClick = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLElement;
    const date = target.getAttribute("data-date");
    if (milestones) {
      Object.values(milestones).forEach((milestone) => {
        if (milestone.achieved_at === date) {
          presentAlert({
            buttons: [L10n.localize((s) => s.actions.ok)],
            message: milestonePop(
              milestone.headline ?? "",
              formatMilestoneDate(milestone.achievedAtDate),
              milestone.description ?? "",
              milestone.icon ?? "",
            ),
          });
        }
      });
    }
  };

  return (
    <div
      ref={(el) => {
        if (!el) {
          return;
        }
        el.scrollLeft = el.scrollWidth;
      }}
      style={{ display: "flex", overflowX: "scroll" }}
      onClick={handleCalendarClick}
    >
      {monthEls}
    </div>
  );
}
