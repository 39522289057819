import Button from "@components/button";
import SettingsModal from "@components/settingsModal";
import {
  InputCustomEvent,
  IonInput,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonText,
  RadioGroupCustomEvent,
} from "@ionic/react";
import { cardsPerReviewAllValue } from "@models/deckSettings";
import useHasUnlimited from "hooks/util/useHasUnlimited";
import L10n from "localization";
import React from "react";
import { maxCardsPerReviewWithoutUnlimited, minCardsPerReview } from "settings";
import usePDP from "unlimited/pdp";

const quickOptions = [10, 25, 50, 100, 200, cardsPerReviewAllValue];

interface IProps {
  dismiss: () => void;
  numTotalCards: number;
  cardsPerReview: number;
  onChange: (num: number) => void;
}
export default function CardsPerSessionSettingModal({
  dismiss,
  numTotalCards,
  cardsPerReview,
  onChange,
}: IProps) {
  const noCards = numTotalCards < 1;
  const { hasUnlimited } = useHasUnlimited();

  const showUpgradeMessage =
    !hasUnlimited &&
    (cardsPerReview > maxCardsPerReviewWithoutUnlimited ||
      (cardsPerReview === cardsPerReviewAllValue &&
        numTotalCards > maxCardsPerReviewWithoutUnlimited));

  const upgradeReason = () =>
    L10n.localize((s) => s.funcs.unlimited.cardsPerSessionUpgradePrompt)(
      maxCardsPerReviewWithoutUnlimited,
    );

  const [showPDP] = usePDP({
    source: "cards_per_session_setting",
    reason: upgradeReason,
  });

  function handleUpgrade() {
    showPDP({
      reason: upgradeReason,
    });
  }

  return (
    <SettingsModal
      title={L10n.localize((s) => s.card.perSession)}
      dismiss={dismiss}
      description={L10n.localize((s) => s.card.cardsPerSessionDescription)}
    >
      <IonList inset>
        <IonItem>
          <IonInput
            style={{
              textAlign: "end",
              color: quickOptions.includes(cardsPerReview)
                ? "var(--ion-color-medium)"
                : "var(--ion-color-primary)",
            }}
            disabled={noCards}
            placeholder={
              cardsPerReview === cardsPerReviewAllValue
                ? L10n.localize((s) => s.general.all)
                : String(cardsPerReview)
            }
            type="number"
            inputmode="decimal"
            min={minCardsPerReview}
            value={cardsPerReview === cardsPerReviewAllValue ? null : cardsPerReview}
            onIonInput={(e: InputCustomEvent) => {
              if (e.detail.value) {
                onChange(Number.parseInt(e.detail.value, 10));
              }
            }}
          >
            {/* This is a hack to color the label separately from the input text. */}
            <IonText slot="label" color="dark">
              {L10n.localize((s) => s.general.custom)}
            </IonText>
          </IonInput>
        </IonItem>
        <IonRadioGroup
          value={cardsPerReview}
          onIonChange={(e: RadioGroupCustomEvent) => {
            onChange(Number.parseInt(e.detail.value, 10));
          }}
        >
          {quickOptions.map((val) => {
            return (
              <IonItem key={`quick-${val}`}>
                <IonRadio value={val}>
                  {val === cardsPerReviewAllValue ? L10n.localize((s) => s.general.all) : val}
                </IonRadio>
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
      {showUpgradeMessage && (
        <div style={{ textAlign: "center", marginTop: 24 }}>
          {upgradeReason?.()}
          <Button onClick={handleUpgrade} text={L10n.localize((s) => s.account.upgradeNow)} />
        </div>
      )}
    </SettingsModal>
  );
}
