import { IonIcon } from "@ionic/react";
import AudioBlob from "cardRendering/audioBlob";
import { IFieldValueProps } from "fields/fieldType";
import { fieldTypeMap } from "fields/fields";
import { IFieldAudio, sampleAudioID, sampleAudioURL } from "fields/types/audio";
import useAsyncBlobVal from "hooks/util/useAsyncBlobVal";
import { radioOutline } from "ionicons/icons";
import { visibleOn } from "lib/orientation";
import React from "react";

export default function AudioValue({
  field,
  ctx,
  options,
  value,
  blobIdToObjectUrl,
}: IFieldValueProps<IFieldAudio>): JSX.Element | null {
  const parsedVal = fieldTypeMap.audio.loadFML(value);
  const id = parsedVal?.id;
  const audioVal = useAsyncBlobVal(id, id === sampleAudioID, sampleAudioURL, blobIdToObjectUrl);

  if (!audioVal) {
    return null;
  }

  if (options.disableAudioControlsRendering) {
    return <IonIcon icon={radioOutline} />;
  }

  const { sideNum } = ctx;
  const visible =
    sideNum === undefined ? true : visibleOn(sideNum, field.sides, options.orientation);
  return <AudioBlob id={audioVal.id} url={audioVal?.url} preventOrchestration={!visible} />;
}
