import { useNamePrompt } from "@components/namePromptModal";
import { showPrompt } from "@components/prompt";
import { getName } from "@data/stores/name";
import L10n from "localization";
import Network from "network";
import { useCallback } from "react";

interface INewStudyGroupRequest {
  name: string;
}

export interface INewStudyGroupResponse {
  id: string;
  name: string;
  created_at: string;
  share_url: string;
}

export function useNewGroupPrompt(
  fetchGroups?: () => void,
): () => Promise<INewStudyGroupResponse | undefined> {
  const onDismiss = useCallback(() => {
    fetchGroups?.();
  }, [fetchGroups]);

  const showNamePromptModal = useNamePrompt({ canCancel: false, onDismiss });

  const showGroupNamePrompt = useCallback(async (): Promise<INewStudyGroupResponse | undefined> => {
    return new Promise((resolve, reject) => {
      showPrompt({
        promptType: "input",
        title: L10n.localize((s) => s.groups.studyGroup),
        prompt: L10n.localize((s) => s.general.name),
        validationRegex: /.+/,
        default: L10n.localize((s) => s.groups.defaultName),
        cancelCallback: () => {
          resolve(undefined);
        },
        callback: async (response) => {
          const name = response?.trim() ?? "";
          if (!name) {
            reject();
          }

          try {
            const payload: INewStudyGroupRequest = {
              name,
            };
            const resp = await Network.fetch<INewStudyGroupResponse>(
              "POST",
              "/users/groups",
              payload,
            );
            resolve(resp);
          } catch {
            showPrompt({
              prompt: L10n.localize((s) => s.error.internal),
              promptType: "alert",
              title: L10n.localize((s) => s.general.attention),
            });
            reject();
          }
        },
      });
    });
  }, []);

  const show = useCallback(async (): Promise<INewStudyGroupResponse | undefined> => {
    const name = await getName();

    try {
      const resp = await showGroupNamePrompt();

      const nameNotSet = name.first === undefined;
      if (nameNotSet) {
        showNamePromptModal({
          canDismiss: async () => {
            const n = await getName();
            return n.first !== undefined;
          },
        });
      }

      return resp;
    } catch {
      return;
    }
  }, [showGroupNamePrompt, showNamePromptModal]);

  return show;
}
