import React from "react";
import Style from "style";

interface IProps {
  loading?: boolean;
  disableZoom?: boolean;
  blobId: string;
  url: string;
}
export default function ImageBlob(props: IProps): JSX.Element {
  const { disableZoom = false, loading = false, blobId, url } = props;

  const [state, setState] = React.useState<{ zoomed: boolean; zoomX?: number; zoomY?: number }>({
    zoomed: false,
    zoomX: undefined,
    zoomY: undefined,
  });

  function handleClick(evt: React.MouseEvent<HTMLImageElement>) {
    if (disableZoom === true) {
      return;
    }

    if (state.zoomed) {
      setState({ zoomed: false });
    } else {
      setState({
        zoomX: evt.nativeEvent.offsetX,
        zoomY: evt.nativeEvent.offsetY,
        zoomed: true,
      });
    }
  }

  let style: React.CSSProperties = {
    maxWidth: Style.imageBlobMaxWidth,
    maxHeight: Style.imageBlobMaxHeight,
    opacity: loading ? Style.imageBlobLoadingOpacity : undefined,
  };

  if (state.zoomed) {
    style = {
      ...style,
      transformOrigin: `${state.zoomX}px ${state.zoomY}px`,
      transform: "scale3d(3,3,1)",
    };
  }

  return <img data-blob-id={blobId} style={style} onClick={handleClick} src={url} />;
}
