import React from "react";

export interface AsyncVal<T> {
  val?: T;
  loading: boolean;
  error?: Error | string;
}

export interface GuaranteedAsyncVal<T> {
  val: T;
  loading: boolean;
  error?: Error | string;
}

export function useAsyncValState<T>(
  initVal?: T,
): [AsyncVal<T>, (next: T) => void, (err: Error) => void] {
  const [state, setState] = React.useState<AsyncVal<T>>({
    val: initVal,
    loading: true,
    error: undefined,
  });

  const setErr = React.useCallback(
    (err: Error) => {
      setState({ val: initVal, loading: false, error: err });
    },
    // NOTE: if initVal is an object or array, and it's included in the deps, then setErr would cause an infinite loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const setVal = React.useCallback((val: T) => {
    setState({ val, loading: false, error: undefined });
  }, []);

  return [state, setVal, setErr];
}

export function useAsyncValFromPromiseState<T>(
  initVal?: T,
): [AsyncVal<T>, (fetcher: Promise<T>) => Promise<void>] {
  const [state, setVal, setErr] = useAsyncValState(initVal);

  const fetch = React.useCallback(
    (fetcher: Promise<T>): Promise<void> => {
      return fetcher.then(setVal).catch((err) => {
        setErr(err);
      });
    },
    [setErr, setVal],
  );

  return [state, fetch];
}
