import EventBus from "eventBus";
import { Setting, defaultDeckSortOrder, setSettingLocally } from "settings";
import { IBaseOperation } from "../models/operation";

export interface IUserSettingsUpdateOperation extends IBaseOperation {
  object_type: "user_settings";
  type: "UPDATE";
  object_parameters: {
    user_id?: string;
    data: string;
  };
}

export interface IUserSettingsDeleteOperation extends IBaseOperation {
  object_type: "user_settings";
  type: "DELETE";
  object_parameters: {
    user_id?: string;
  };
}

export type UserSettingsOperation = IUserSettingsDeleteOperation | IUserSettingsUpdateOperation;

const DELETE = () => {
  // reset settings to default
  for (const key of Object.keys(localStorage)) {
    const match = key.match(/AnkiApp.settings.(.+)/);
    if (match) {
      localStorage.removeItem(match[0]);
    }
  }
  return () => {
    EventBus.emit("deckSortUpdated", defaultDeckSortOrder);
  };
};

const UPDATE = (op: IUserSettingsUpdateOperation) => {
  const settings = JSON.parse(op.object_parameters.data || "{}");
  for (const [k, v] of Object.entries(settings)) {
    setSettingLocally(k as Setting, v as string);
  }
};

export { DELETE, UPDATE };
