import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { IDeckConfig } from "@models/deck";
import { FieldsContext } from "@screens/deckCreate";
import FieldList, { IFieldVisibility } from "fields/components/fieldList";
import { isValidField } from "fields/lib";
import cyAttr from "lib/cyAttr";
import React, { useContext } from "react";
import L10n from "../../localization";
import ConfigPreview from "./configPreview";

export type VisibilityMap = Record<string, IFieldVisibility>;

interface IProps {
  base?: string;
  dismiss: () => void;
  onSave: (cfg: IDeckConfig) => void;
  onCancel?: () => void;
}

export default function DeckConfigNav(props: IProps) {
  const { base } = props;
  const { fields, dispatch: fieldsDispatch } = useContext(FieldsContext);
  const config = { fields, base };

  const handleSave = async () => {
    props.onSave(config);
    props.dismiss();
  };

  const screenTitle = L10n.localize((s) => s.general.config);
  const fullSlug = `${screenTitle}-screen`;

  const hasInvalidFields = fields.some((f) => !isValidField(f));
  const hasNoFields = fields.length < 1;

  const isSaveDisabled = hasInvalidFields || hasNoFields;

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            {
              <IonButton
                color="danger"
                onClick={() => {
                  props.onCancel?.();
                  props.dismiss();
                }}
              >
                {L10n.localize((s) => s.actions.cancel)}
              </IonButton>
            }
          </IonButtons>
          <IonTitle>{screenTitle}</IonTitle>
          <IonButtons slot="end">
            <IonButton disabled={isSaveDisabled} onClick={handleSave}>
              {L10n.localize((s) => s.actions.save)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent {...cyAttr(fullSlug)}>
        <FieldList fields={fields} dispatch={fieldsDispatch} isExistingDeck={false} />
        <IonList>
          <IonListHeader>{L10n.localize((s) => s.general.preview)}</IonListHeader>
          <ConfigPreview config={config} />
        </IonList>
      </IonContent>
    </>
  );
}
