import { showPrompt } from "@components/prompt";
import { IonItem, IonList, IonListHeader } from "@ionic/react";
import L10n from "localization";
import React from "react";
import Empty from "./empty";
import Tag from "./tag";

interface IProps {
  tags: string[];
  removeTag?: (tag: string) => void;
  confirmRemoval?: boolean;
}

const TagsSection: React.FunctionComponent<IProps> = (props) => {
  const { tags, removeTag, confirmRemoval = false } = props;

  function handleRemove(tag: string) {
    if (confirmRemoval) {
      showPrompt({
        title: L10n.localize((s) => s.actions.confirm),
        prompt: L10n.localize((s) => s.tag.confirmRemoval),
        promptType: "confirm",
        callback: () => {
          removeTag?.(tag);
        },
      });
      return;
    }

    removeTag?.(tag);
  }

  const items = tags.map((tag: string) => {
    return <Tag key={tag} name={tag} onDelete={removeTag ? () => handleRemove(tag) : undefined} />;
  });

  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.tag.plural)}</IonListHeader>
      {items.length > 0 ? (
        <IonItem lines="none">
          <div style={{ display: "flex", flexWrap: "wrap" }}>{items}</div>
        </IonItem>
      ) : (
        <IonItem lines="none">
          <div style={{ margin: "0 auto" }}>
            <Empty marginTop="0.2rem" marginBottom="0.2rem" />
          </div>
        </IonItem>
      )}
    </IonList>
  );
};

export default TagsSection;
