import VerticallySplitLayout from "@components/verticallySplitLayout";
import { IonItem, IonLabel, IonList, IonListHeader, IonNote } from "@ionic/react";
import { ICardResponse } from "@screens/review/types";
import Num from "components/number";
import ResponseHistogram from "components/responseHistogram";
import { TResponseName } from "globals";
import { millis2TimeString } from "lib";
import L10n from "localization";
import React from "react";
import DeckStats from "./deckStats";

export type ResponseCounts = {
  [name in TResponseName]?: number;
};

export function responseStats(responses: ICardResponse[]) {
  const responseCounts: ResponseCounts = {};
  responses.forEach(({ response }) => {
    responseCounts[response] = (responseCounts[response] ?? 0) + 1;
  });

  const totalReviews = responses.length;

  let totalMillis = 0;
  responses.forEach(({ durationMs }) => {
    totalMillis += durationMs;
  });

  return {
    totalReviews,
    totalMillis,
    responseCounts,
  };
}

interface SessionStatsProps {
  totalMillis: number;
  responseCounts: ResponseCounts;
  totalReviews: number;
  numReviewsToday?: number;
  studyGoal?: number;
  gradeChangeToday: number | null;
  numAverageReviewsPerDay: number | undefined;
  splitView: boolean;
  isOmniReview: boolean;
}

export default function SessionStats({
  totalMillis,
  responseCounts,
  totalReviews,
  numReviewsToday,
  studyGoal,
  gradeChangeToday,
  numAverageReviewsPerDay,
  splitView,
  isOmniReview,
}: SessionStatsProps) {
  const totalTimeStr = millis2TimeString(totalMillis);

  const getContentLayout = () => {
    if (splitView) {
      return (
        <VerticallySplitLayout
          left={
            <React.Fragment key="leftComponents">
              {isOmniReview ? sessionStats() : deckStatsSection()}
              {isOmniReview || splitView ? studyGoalSection() : null}
            </React.Fragment>
          }
          right={
            <>
              {!isOmniReview && sessionStats()}
              <ResponseHistogram
                height={isOmniReview ? "8rem" : undefined}
                counts={responseCounts}
              />
            </>
          }
        />
      );
    }
    return (
      <React.Fragment key="singleColumnLayout">
        {...(isOmniReview
          ? [sessionStats(), studyGoalSection()]
          : [deckStatsSection(), studyGoalSection(), sessionStats()])}
        <ResponseHistogram key="resp-historgram" counts={responseCounts} />
      </React.Fragment>
    );
  };

  const sessionStats = () => (
    <IonList key="sessionStatsList">
      <IonListHeader>{L10n.localize((s) => s.review.session)}</IonListHeader>
      {commonSessionItems()}
    </IonList>
  );

  const studyGoalSection = () => (
    <IonList key="studyGoalSection">
      <IonListHeader>{L10n.localize((s) => s.general.studyGoal)}</IonListHeader>
      <IonItem lines={splitView ? "none" : undefined}>
        <IonLabel>{L10n.localize((s) => s.review.numToday)}</IonLabel>
        <IonNote slot="end">
          <Num num={numReviewsToday} />
          {studyGoal && `/${studyGoal}`}
        </IonNote>
      </IonItem>
    </IonList>
  );

  const deckStatsSection = () =>
    !isOmniReview ? (
      <React.Fragment key="deckStatsSection">
        {splitView && <IonListHeader>{L10n.localize((s) => s.deck.singular)}</IonListHeader>}
        <DeckStats
          gradeChangeToday={gradeChangeToday}
          numAverageReviewsPerDay={numAverageReviewsPerDay}
        />
      </React.Fragment>
    ) : null;

  const commonSessionItems = () => (
    <React.Fragment key="sessionItems">
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.review.total)}</IonLabel>
        <IonNote slot="end">
          <Num num={totalReviews} />
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.review.totalTime)}</IonLabel>
        <IonNote slot="end">{totalTimeStr}</IonNote>
      </IonItem>
    </React.Fragment>
  );

  return <div>{getContentLayout()}</div>;
}
