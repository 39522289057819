import NestedNav from "@components/nestedNav";
import React from "react";
import L10n from "localization";
import { IonIcon, IonItem, IonLabel, IonList, IonNote } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import TranslationSourceModal from "./translationSourceModal";
import { IField } from "fields/fields";
import useStateTee from "hooks/util/useStateTee";
import {
  SourcePipelineNode,
  manualSourcePipelineNode,
  ISourcePipelineRefNode,
  ISourcePipelineTranslationNode,
} from "../sources/types";
import RefFieldSelectorModal from "./refFieldSelectorModal";
import { validRefFields } from "./refFieldSelectorItem";
import { fieldLang } from "fields/lang";

interface IProps {
  fields: IField[];
  field: IField;
  onSelect: (source: SourcePipelineNode) => void;
}

export default function SourceNav({ field, fields, onSelect }: IProps) {
  const [src, setSrc] = useStateTee(field.source, onSelect);

  const lang = fieldLang(field);

  function handleTranslationSourceSelect(name: string | undefined) {
    if (!name) {
      return;
    }

    const refFieldSource: ISourcePipelineRefNode = {
      type: "ref",
      name,
    };

    const sourcePipeline: ISourcePipelineTranslationNode = {
      type: "translation",
      source: refFieldSource,
    };

    setSrc(sourcePipeline);
  }

  function handleManualClick() {
    setSrc(manualSourcePipelineNode);
  }

  const [presentTranslationModal] = useIonCardModalWithDismiss(TranslationSourceModal, {
    fields,
    field,
    onSelect: handleTranslationSourceSelect,
    targetLang: lang,
    src,
  });
  function handleTranslationClick() {
    presentTranslationModal();
  }

  function handleRefSourceSelect(name: string | undefined) {
    if (!name) {
      return;
    }

    const refFieldSource: ISourcePipelineRefNode = {
      type: "ref",
      name,
    };

    setSrc(refFieldSource);
  }

  const refField = src?.type === "ref" ? src.name : undefined;
  const [presentRefModal] = useIonCardModalWithDismiss(RefFieldSelectorModal, {
    onSelect: handleRefSourceSelect,
    refField,
    refFields: validRefFields(fields, field.name),
  });
  function handleRefClick() {
    presentRefModal();
  }

  return (
    <NestedNav title={L10n.localize((s) => s.field.source)}>
      <IonList>
        <IonItem button detail={false} onClick={handleManualClick}>
          <IonLabel>
            <h2>{L10n.localize((s) => s.field.sourceManual)}</h2>
            <p>{L10n.localize((s) => s.field.sourceManualDescription)}</p>
          </IonLabel>
          {src === manualSourcePipelineNode ? (
            <IonIcon icon={checkmarkOutline} slot="end" />
          ) : undefined}
        </IonItem>

        <IonItem button detail={false} onClick={handleTranslationClick}>
          <IonLabel>
            <h2>{L10n.localize((s) => s.general.translation)}</h2>
            <p>{L10n.localize((s) => s.field.sourceTranslationDescription)}</p>
          </IonLabel>
          {src?.type === "translation" ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
        </IonItem>

        <IonItem button detail={false} onClick={handleRefClick}>
          <IonLabel>
            <h2>{L10n.localize((s) => s.field.sourceReference)}</h2>
            <p>{L10n.localize((s) => s.field.sourceReferenceDescription)}</p>
          </IonLabel>
          <IonNote slot="end">{refField}</IonNote>
          {src?.type === "ref" ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
        </IonItem>
      </IonList>
    </NestedNav>
  );
}
