import { DeckFields, fieldMap } from "@models/deck";
import { IParsedCard, parseCards } from "./parseCards";
import { parseFields } from "./parseFields";
import { IField } from "fields/fields";

export interface IParsedDeck {
  name: string;
  tags: string[];
  fields: DeckFields;
  cards: IParsedCard[];
}

export interface IParseMessage {
  msg: string;
}

export interface IParseResult<T> {
  val: T | null;
  errors?: IParseMessage[];
  warnings?: IParseMessage[];
}

export function parseDeckFML(fml: string): IParseResult<IParsedDeck> {
  const warnings: IParseMessage[] = [];

  const parser = new DOMParser();
  const parsed = parser.parseFromString(fml, "text/xml");

  const errNode = parsed.querySelector("parsererror");
  if (errNode) {
    return {
      val: null,
      errors: [{ msg: `Parse Error: ${errNode.textContent}` }],
    };
  }

  const topLevelEls = parsed.children;
  if (topLevelEls.length !== 1) {
    return {
      val: null,
      errors: [{ msg: "Failed to parse top-level elements." }],
    };
  }

  const deckNode = topLevelEls[0];
  if (deckNode.nodeName !== "deck") {
    return {
      val: null,
      errors: [
        { msg: `Expected to find <deck> at top-level. Instead found <${deckNode.nodeName}>` },
      ],
    };
  }

  const deckName = deckNode.getAttribute("name");
  if (!deckName) {
    return {
      val: null,
      errors: [{ msg: "Deck node missing name attribute." }],
    };
  }

  let tags: string[] = [];
  const deckTags = deckNode.getAttribute("tags");
  if (deckTags) {
    tags = decodeURI(deckTags).split(",");
  }

  const deckNodeChildren = Array.from(deckNode.children);

  // Legacy decks will not have fields. Instead, we parse
  // the deck without fields explicitly set and then
  // present the deck config editor to let the user
  // restore "missing" fields and assign them a side.
  const fieldsResult = parseFields(deckNodeChildren);
  warnings.push(...(fieldsResult.warnings ?? []));
  const fields = fieldsResult.val ?? ([] as IField[]);
  const fmap = fieldMap(fields);
  const cardsResult = parseCards(deckNodeChildren, fmap);
  warnings.push(...(cardsResult.warnings ?? []));
  const cards = cardsResult.val;
  if (!cards) {
    return {
      val: null,
      errors: cardsResult.errors,
      warnings,
    };
  }

  const deck: IParsedDeck = {
    name: deckName,
    tags,
    fields,
    cards,
  };

  return {
    val: deck,
    errors: [],
    warnings,
  };
}
