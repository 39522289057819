import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonNote,
} from "@ionic/react";
import { addCircle, warningOutline } from "ionicons/icons";
import React from "react";

interface IProps {
  type: "warning" | "error";
  title: string;
  description: string;
  unusedFields?: Record<string, number>;
  onResurrectField: (name: string) => void;
}

export default function FieldsWarningOrError({
  type,
  title,
  description,
  unusedFields,
  onResurrectField,
}: IProps) {
  return (
    <IonCard color={type === "error" ? "danger" : "warning"}>
      <IonCardHeader>
        <IonCardTitle style={{ display: "flex", alignItems: "center" }}>
          <IonIcon icon={warningOutline} style={{ marginRight: 8 }} />
          <IonLabel>{title}</IonLabel>
        </IonCardTitle>
      </IonCardHeader>
      <IonCardContent>{description}</IonCardContent>
      {unusedFields && (
        <IonList lines="full">
          {Object.entries(unusedFields).map(([field, value]) => (
            <IonItem key={field} button onClick={() => onResurrectField(field)}>
              <IonIcon icon={addCircle} color="success" slot="start" />
              <IonLabel>{field}</IonLabel>
              <IonNote slot="end">{value}</IonNote>
            </IonItem>
          ))}
        </IonList>
      )}
    </IonCard>
  );
}
