import React from "react";

export default function useRefreshCounter(): [number, () => void] {
  const [displayCount, setDisplayCount] = React.useState(0);
  const forceRefresh = React.useCallback(() => {
    setDisplayCount(displayCount + 1);
  }, [displayCount]);

  return [displayCount, forceRefresh];
}
