import EventBus from "eventBus";
import { TouchEvent, useEffect, useRef } from "react";
import { getHintRevealDelayMillis } from "settings";

export const hintClass = "hint";

export function useHintClass(): React.RefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hintEl = ref.current;
    if (!hintEl) {
      return;
    }

    function showHint() {
      hintEl?.classList.add(hintClass);
    }

    function hideHint() {
      hintEl?.classList.remove(hintClass);
    }

    EventBus.on("showHint", showHint);
    EventBus.on("hideHint", hideHint);

    return () => {
      EventBus.off("showHint", showHint);
      EventBus.off("hideHint", hideHint);
    };
  }, [ref]);

  return ref;
}

export function useHint(): {
  onMouseDown: () => void;
  onMouseUp: () => void;
  onMouseOut: () => void;
  onTouchStart: () => void;
  onTouchEnd: () => void;
  onTouchMove: React.TouchEventHandler<HTMLDivElement>;
} {
  const longPressThresholdMillis = getHintRevealDelayMillis();
  const timeoutHandle = useRef<NodeJS.Timeout>();

  function handleLongPress() {
    EventBus.emit("showHint");
  }

  function handleRelease() {
    EventBus.emit("hideHint");
  }

  function handleTouchMove(evt: TouchEvent<HTMLDivElement>) {
    if (!timeoutHandle.current) {
      return;
    }
    const touch = evt.touches[0];
    const el = evt.currentTarget;
    if (!el || !touch) {
      return;
    }
    const bounds = el.getBoundingClientRect();
    const outOfBounds =
      touch.clientX < bounds.left ||
      touch.clientX > bounds.right ||
      touch.clientY < bounds.top ||
      touch.clientY > bounds.bottom;
    if (outOfBounds) {
      EventBus.emit("hideHint");
    } else {
      EventBus.emit("showHint");
    }
  }

  function start() {
    timeoutHandle.current = setTimeout(handleLongPress, longPressThresholdMillis);
  }

  function cancel() {
    clearTimeout(timeoutHandle.current);
    handleRelease();
  }

  return {
    onMouseDown: start,
    onMouseUp: cancel,
    onMouseOut: cancel,
    onTouchStart: start,
    onTouchEnd: cancel,
    onTouchMove: handleTouchMove,
  };
}
