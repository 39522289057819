import { DeckFields } from "@models/deck";
import { FlipStabilizationSetting } from "@screens/deck/flipStabilizationSelectorItem";
import { IFieldVisibility } from "fields/components/fieldList";
import { visibleOn } from "lib/orientation";
import { Orientation } from "./deckSettings";

export type VisibilityMap = Record<string, IFieldVisibility>;

type LayoutID = string;
export type LayoutMap = Record<LayoutID, ILayout>;

export const invisibleClass = "invisible";

export interface ILayout {
  id: string;
  name: string;
  knol_keys?: string[];
  templates: string[];
  style?: string;
  status: number;
  response_type_id: string;
}

export interface IDBLayout extends ILayout {
  templatesJson: string;
  isDefault: boolean;
}

export function isValidCSSClassName(str: string): boolean {
  // Check if the string starts with a letter
  if (!str.match(/^[a-zA-Z]/)) {
    return false;
  }

  // Check if the string contains only valid characters
  if (!str.match(/^[a-zA-Z0-9_-]*$/)) {
    return false;
  }

  // Check if the string contains any consecutive hyphens or underscores
  if (str.match(/[-_]{2,}/)) {
    return false;
  }

  // Check if the string is not a reserved word
  const reservedWords = ["none", "inherit", "initial", "unset", "important"];
  if (reservedWords.includes(str.toLowerCase())) {
    return false;
  }

  return true;
}

export namespace Layout {
  export const STATUS_PUBLIC = 0; // not editable
  export const STATUS_PRIVATE = 1; // editable

  export const ALL_LAYOUT_VALUE = "ALL";

  export const DEFAULT_FIELDS = ["front", "back"];
  export const REVERSED_DEFAULT_FIELDS = ["back", "front"];

  export const FRONT_SIDE_MACRO = "FrontSide";

  export const frontSidePrefixRegex = new RegExp(
    `^({{${FRONT_SIDE_MACRO}}})|({{[${FRONT_SIDE_MACRO}]}})`,
  );

  export function startsWithFrontSideMacro(template: string): boolean {
    return template.match(Layout.frontSidePrefixRegex) !== null;
  }

  // Dynamically alter templates to include back on front, but hidden,
  // so that flipping from front to back doesn't have any layout shift
  // that vertically centering would otherwise cause.
  export function imageStabilizedTemplates(
    templates: [string, string],
    flipStabilization: FlipStabilizationSetting,
  ): [string, string] {
    const front = templates[0] ?? "";
    const back = templates[1] ?? "";

    const backIncludesFront = Layout.startsWithFrontSideMacro(back);
    if (!backIncludesFront) {
      return templates;
    }

    const backSansFront = back.replace(Layout.frontSidePrefixRegex, "");
    const frontWithBackHidden = [
      front,
      flipStabilization === "stabilize"
        ? `<div class="${invisibleClass}">`
        : `<div style="display: none">`,
      backSansFront,
      "</div>",
    ].join("");
    const backNotHidden = [front, "<div>", backSansFront, "</div>"].join("");

    return [frontWithBackHidden, backNotHidden];
  }

  export function extractFields(template: string): string[] {
    // Regular expression to match bracketed or unbracketed interpolation strings.
    const re = /{{(?:(?:\[([^\]]+?)\])|([^}]+?))}}/g;
    const fields: string[] = [];
    let match: RegExpExecArray | null = null;
    do {
      match = re.exec(template);
      if (match) {
        const field = match[1] || match[2];
        if (field !== Layout.FRONT_SIDE_MACRO) {
          fields.push(field);
        }
      }
    } while (match);
    return fields;
  }

  export function fieldOrder(keys: string[], templates: string[]): string[] {
    const frontFields = Layout.extractFields(templates[0]);
    const backFields = Layout.extractFields(templates[1]);

    const order: string[] = [];

    const pushField = (field: string) => {
      if (keys.includes(field)) {
        if (!order.includes(field)) {
          order.push(field);
        }
      }
    };

    // Order fields based on their order of appearance within the back template,
    // including fields from the front template if the FrontSide macro is used.
    for (const field of backFields) {
      if (field === Layout.FRONT_SIDE_MACRO) {
        for (const ff of frontFields) {
          pushField(ff);
        }
      } else {
        pushField(field);
      }
    }

    for (const field of keys) {
      if (!order.includes(field)) {
        order.push(field);
      }
    }

    return order;
  }

  // Returns the next knol key in sequence.
  export function nextKey(currentKey: string, keys: string[], templates: string[]): string | null {
    const order = Layout.fieldOrder(keys, templates);
    const index = order.indexOf(currentKey);
    if (index === -1) {
      return null;
    }
    const nextIndex = (index + 1) % order.length;
    return order[nextIndex];
  }

  export function fieldHTML(fieldName: string, hidden: boolean): string {
    const classes = [fieldName];
    if (hidden) {
      classes.push(invisibleClass);
    }
    const valid = classes.filter(isValidCSSClassName);
    const classStr = valid.join(" ");
    return `<div class='${classStr}'>{{${fieldName}}}</div>`;
  }

  export function defaultVisibilityMapFor(fieldNames: string[]): VisibilityMap {
    const vizMap: VisibilityMap = {};
    fieldNames.forEach((name, i) => {
      vizMap[name] = {
        front: i === 0,
        back: true,
      };
    });
    return vizMap;
  }

  export function templatesFor(
    fields: DeckFields,
    orientation: Orientation = "normal",
  ): [string, string] {
    const fieldNames = fields.map((field) => field.name);

    const vizMap: VisibilityMap = {};
    for (const field of fields) {
      vizMap[field.name] = {
        front: visibleOn(0, field.sides, orientation),
        back: visibleOn(1, field.sides, orientation),
      };
    }

    return defaultTemplates(fieldNames, vizMap);
  }

  export function defaultTemplates(
    fieldNames: string[],
    visibilityMap?: VisibilityMap,
  ): [string, string] {
    const vizMap = visibilityMap ?? Layout.defaultVisibilityMapFor(fieldNames);
    const bothSidesFields = fieldNames.filter((name) => vizMap[name]?.front && vizMap[name]?.back);

    const backOnlyFields = fieldNames.filter((name) => !vizMap[name]?.front && vizMap[name]?.back);

    const frontOnlyFields = fieldNames.filter((name) => vizMap[name]?.front && !vizMap[name]?.back);

    const front = [
      ...bothSidesFields.map((name) => Layout.fieldHTML(name, false)),
      ...frontOnlyFields.map((name) => Layout.fieldHTML(name, false)),
    ].join("\n");

    if (frontOnlyFields.length > 0) {
      // Can't use FrontSide macro, or it will repeat these on back.
      const explicitBack = [
        ...bothSidesFields.map((name) => Layout.fieldHTML(name, false)),
        "<hr />",
        ...backOnlyFields.map((name) => Layout.fieldHTML(name, false)),
      ].join("\n");
      return [front, explicitBack];
    }

    const back = [
      `{{${Layout.FRONT_SIDE_MACRO}}}`,
      "<hr />",
      ...backOnlyFields.map((name) => Layout.fieldHTML(name, false)),
    ].join("\n");

    return [front, back];
  }

  export function defaultCss(fields: string[]) {
    const valueCss = (name: string) => `.${name} {\n\n}\n`;
    const css: string[] = [];
    for (const f of fields) {
      css.push(valueCss(f));
    }
    return css.join("\n");
  }
}
