import Empty from "@components/empty";
import SelectOptionButton from "@components/selectOptionButton";
import { IonItem, IonList } from "@ionic/react";
import { basename } from "@models/folder";
import DeckListItem from "@screens/decks/deckListItem";
import { IDeckOrFolderRow, useDeckList } from "hooks/data/deckList";
import React, { useState } from "react";
import sortRows from "../decks/sortRows";
import { SortOptions, sortMenuOptions, sortOptionStringFromKey } from "../decks/types";

export function deckListRowMatchesQuery(row: IDeckOrFolderRow, query: string): boolean {
  if (!row.name) {
    return false;
  }
  if (!query) {
    return true;
  }
  const haystack = row.type === "folder" ? row.name : basename(row.name);
  return haystack.toLowerCase().includes(query.toLowerCase());
}

export default function DeckResults(props: { query: string }) {
  const { query } = props;

  const { rows: resultRows } = useDeckList({ includeArchived: true });

  const matchingRows = query
    ? resultRows.filter((r) => deckListRowMatchesQuery(r, query))
    : resultRows;

  const [sortOrder, setSortOrder] = useState<SortOptions>("alphaAsc");
  const sorted = sortRows(sortOrder, matchingRows);

  if (!query) {
    return null;
  }

  const items = sorted.map((row) => (
    <DeckListItem key={row.type === "deck" ? row.deck.id : row.name} row={row} />
  ));

  return (
    <IonList>
      <IonItem lines="none">
        <SelectOptionButton
          options={sortMenuOptions()}
          value={sortOrder}
          onSelect={setSortOrder}
          style={{ margin: "4px" }}
        >
          {sortOptionStringFromKey(sortOrder)}
        </SelectOptionButton>
      </IonItem>
      {items.length < 1 && <Empty style={{ margin: 0 }} />}
      {items.length > 0 && <IonList>{items}</IonList>}
    </IonList>
  );
}
