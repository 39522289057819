import { logSearchEvent } from "analytics/search";
import Network from "network";
import React from "react";
import { IBrowseCategory } from "./category";

export default function usePublicDecksData(): [IBrowseCategory[] | null, Error | undefined] {
  const [categories, setCategories] = React.useState<IBrowseCategory[] | null>(null);
  const [error, setError] = React.useState<Error>();
  React.useEffect(() => {
    const aborter = new AbortController();
    let isAborted = false;
    Network.fetch<{ categories: IBrowseCategory[] }>("GET", "/decks/browse", undefined, aborter)
      .then((data) => {
        try {
          logSearchEvent("received_categories", {
            categories: data?.categories?.map((cat) => cat.name),
          });
        } catch {
          // Meh.
        }
        data?.categories?.sort((a, b) => {
          if (a.score === b.score) {
            return a.name.localeCompare(b.name);
          } else if (!a.score || !b.score) {
            return 0;
          } else {
            return b.score - a.score;
          }
        });
        if (!isAborted) {
          setCategories(data?.categories);
        }
      })
      .catch((e) => setError(e));
    return () => {
      isAborted = true;
      aborter.abort();
    };
  }, []);
  return [categories, error];
}
