import { IonItem, IonLabel, IonNavLink, IonNote } from "@ionic/react";
import { IField } from "fields/fields";
import L10n from "localization";
import React from "react";
import SourceNav from "./sourceNav";
import SourcePipeline from "./sourcePipeline";
import { SourcePipelineNode, manualSourcePipelineNode } from "../sources/types";

interface IProps {
  fields: IField[];
  field: IField;
  onSelect: (source: SourcePipelineNode) => void;
}
export default function SourceSelectorItem({ fields, field, onSelect }: IProps) {
  return (
    <IonNavLink
      routerDirection="forward"
      component={() => <SourceNav field={field} fields={fields} onSelect={onSelect} />}
    >
      <IonItem detail button>
        <IonLabel>
          <h2>{L10n.localize((s) => s.field.source)}</h2>
          <p className="ion-text-wrap">{L10n.localize((s) => s.field.sourceDescription)}</p>
        </IonLabel>
        <IonNote style={{ marginTop: 6, marginBottom: 6 }} slot="end">
          {field.source === manualSourcePipelineNode ? (
            L10n.localize((s) => s.general.manual)
          ) : (
            <SourcePipeline field={field} fields={fields} />
          )}
        </IonNote>
      </IonItem>
    </IonNavLink>
  );
}
