import LayoutStyle from "@components/layoutStyle";
import { MapLike } from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { ILayout } from "@models/layout";
import { ICardResponse } from "@screens/review/types";
import { useIsPhone } from "hooks/util/useMediaQuery";
import React from "react";
import { ID } from "types/ID";
import ResponseCard from "./responseCard";

export const maxRecapCardPreviews = 50;

export interface ICardsViewProps {
  decks: MapLike<ID, IDeck> | undefined;
  knols: MapLike<ID, IKnol> | undefined;
  responses: ICardResponse[];
  knolIDToLayout: Map<ID, ILayout>;
  forceRefreshCounter?: number;
}
export default function CardsView({
  decks,
  knols,
  responses,
  forceRefreshCounter,
  knolIDToLayout,
}: ICardsViewProps): JSX.Element {
  const isPhone = useIsPhone();

  const style = {
    textAlign: "center",
    display: "flex",
    flexWrap: isPhone ? "nowrap" : "wrap",
    flexDirection: "row",
    overflowX: "auto",
  } as React.CSSProperties;

  return (
    <div style={style}>
      {responses.slice(0, maxRecapCardPreviews).map((resp, idx) => {
        const knol = knols?.get(resp.knol.id) ?? resp.knol;
        const deck = decks?.get(resp.knol.deck_id);
        if (!deck) {
          return null;
        }
        const layout = knolIDToLayout.get(knol.id);
        return (
          <div key={`${knol.id}-${idx}`} style={{ paddingBottom: 8 }}>
            <LayoutStyle
              layoutID={layout?.id}
              css={layout?.style ?? ""}
              fontSize={deck?.user_config?.settings?.cardFontSize}
            />
            <ResponseCard
              layoutID={resp.layoutID}
              knol={knol}
              deck={deck}
              response={resp.response}
              durationMs={resp.durationMs}
              forceRefreshCounter={forceRefreshCounter}
            />
          </div>
        );
      })}
    </div>
  );
}
