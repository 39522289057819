import { IDeck } from "models/deck";
import React from "react";
import HistoryResponses from "./historyResponses";
import HistoryStats from "./historyStats";

export default function DeckScreenHistorySection(props: {
  deck?: IDeck;
  widescreenChartHeight?: string;
  knolsLastUpdated?: Date;
}) {
  const { deck, widescreenChartHeight, knolsLastUpdated } = props;
  return (
    <>
      <HistoryStats deck={deck} knolsLastUpdated={knolsLastUpdated} />
      <HistoryResponses
        deckID={deck?.id}
        knolsLastUpdated={knolsLastUpdated}
        widescreenChartHeight={widescreenChartHeight}
      />
    </>
  );
}
