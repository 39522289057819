import React, { useCallback } from "react";
import { IonNav } from "@ionic/react";
import { DeckFields, IDeckConfig } from "@models/deck";
import useFieldsReducer from "fields/hooks/useFieldsReducer";
import DeckConfigNav from "./deckConfigNav";
import { FieldsContext } from "@screens/deckCreate";

interface IProps {
  base?: string;
  fields: DeckFields;
  dismiss: () => void;
  onSave: (cfg: IDeckConfig) => void;
  onCancel?: () => void;
}

export default function DeckConfigScreen(props: IProps): JSX.Element {
  const { dismiss, onSave, onCancel, base } = props;
  const [fields, fieldsDispatch] = useFieldsReducer(props.fields);

  // NOTE: this useCallback is necessary to prevent DeckConfigNav from getting
  // recreated on re-renders (which will happen when fields state changes).
  const navRoot = useCallback(
    () => <DeckConfigNav {...{ dismiss, onSave, onCancel, base }} />,
    [dismiss, onSave, onCancel, base],
  );

  return (
    <FieldsContext.Provider value={{ fields, dispatch: fieldsDispatch }}>
      <IonNav root={navRoot} />
    </FieldsContext.Provider>
  );
}
