import FieldEditorGeneric from "fields/editors/fieldEditorGeneric";
import { IFieldType } from "fields/fieldType";
import { IBaseField } from "fields/lib";
import { parseSides } from "fields/sides";
import FieldValueEditorCode from "fields/valueEditors/fieldValueEditorCode";
import TexValue from "fields/values/texValue";
import L10n from "localization";
import { TbMath } from "react-icons/tb";

export const texFieldType: IFieldType<"tex"> = {
  name: () => L10n.localize((s) => s.field.typeTeX),
  description: () => L10n.localize((s) => s.field.typeTeXDescription),
  icon: TbMath,
  sampleValue: () => "$-b \\pm \\sqrt{b^2 - 4ac} \\over 2a$ \\bye",
  fmlTag: "tex",
  type: "tex",
  parseField(attrs, srcs): IFieldTeX {
    return {
      name: attrs["name"],
      type: this.type,
      sides: parseSides(attrs["sides"]),
      source: srcs[0],
    };
  },

  loadFML(fml) {
    return fml;
  },

  dumpFML(value) {
    return value;
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: TexValue,
  editorComponent: FieldEditorGeneric,
  valueEditorComponent: FieldValueEditorCode,

  convert(field) {
    return { ...field, type: this.type, attributes: undefined };
  },
};

export interface IFieldTeX extends IBaseField {
  type: "tex";
}
