import useStudyGroups from "@screens/studyGroups/useStudyGroups";
import InlineLoadingSpinner from "components/inlineLoadingSpinner";
import Globals from "globals";
import L10n from "localization";
import React from "react";
import Style from "style";
import { useNewGroupPrompt } from "../screens/studyGroups/newModal";

import {
  CheckboxChangeEventDetail,
  IonCheckboxCustomEvent,
  IonSearchbarCustomEvent,
  SearchbarInputEventDetail,
} from "@ionic/core/components";
import {
  CheckboxCustomEvent,
  IonAvatar,
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { mailOutline, personOutline, trashOutline } from "ionicons/icons";
import cyAttr from "lib/cyAttr";

interface IShareListItemProps {
  email: string;
  onRemove: (email: string) => void;
}
function ShareListItem(props: IShareListItemProps) {
  const { email, onRemove } = props;

  function handleRemove() {
    onRemove(email);
  }

  return (
    <IonItem>
      <IonAvatar
        slot="start"
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <IonIcon icon={personOutline} />
      </IonAvatar>
      {email}
      <IonAvatar slot="end">
        <IonButton fill="clear" onClick={handleRemove}>
          <IonIcon size="large" slot="start" color="danger" icon={trashOutline} />
        </IonButton>
      </IonAvatar>
    </IonItem>
  );
}

interface IEditableEmailListProps {
  emails: string[];
  setEmails: (emails: string[]) => void;
  title?: string;
}
export function EditableEmailList(props: IEditableEmailListProps): JSX.Element {
  const { emails, setEmails, title = L10n.localize((s) => s.sharing.emails) } = props;

  const [val, setVal] = React.useState<string | null | undefined>("");

  function handleValChange(evt: IonSearchbarCustomEvent<SearchbarInputEventDetail>) {
    setVal(evt.detail.value);
  }

  function handleAddEmail(evt: { preventDefault: () => void; stopPropagation: () => void }) {
    if (!isValid) {
      return;
    }
    evt.preventDefault();
    evt.stopPropagation();

    const valEmails = val?.split(",").map((email) => email.trim());
    const newEmails = valEmails?.filter((email) => !emails.includes(email)) || [];

    if (newEmails.length === 0) {
      return;
    }

    // NOTE: email validation is handled by the input component.

    setEmails([...newEmails, ...emails]);
    setVal("");
  }

  function handleRemoveEmail(email: string) {
    setEmails(emails.filter((e) => e !== email));
  }

  const isValid = val ? Globals.emailValidationRegex.test(val) : false;

  return (
    <div
      style={{
        flex: "1 1 auto",
        marginTop: "1rem",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <IonList>
        <IonItemDivider>{title}</IonItemDivider>
        <IonItem className="input-add-email">
          <IonSearchbar
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                handleAddEmail(e);
              }
            }}
            {...cyAttr("share-modal-email-input")}
            color={!isValid && val !== "" ? "danger" : undefined}
            value={val}
            onIonInput={handleValChange}
            type="email"
            placeholder={L10n.localize((s) => s.account.email)}
            searchIcon={mailOutline}
          />
          <IonButton
            fill="clear"
            size="large"
            onClick={handleAddEmail}
            type="submit"
            disabled={!isValid}
          >
            {L10n.localize((s) => s.actions.add)}
          </IonButton>
        </IonItem>
      </IonList>
      <IonList style={{ height: "100%", overflow: "scroll" }}>
        {emails.map((email) => (
          <ShareListItem key={email} email={email} onRemove={handleRemoveEmail} />
        ))}
      </IonList>
    </div>
  );
}

function StudyGroupsEmpty({ fetchGroups }: { fetchGroups: () => void }) {
  const style = {
    color: Style.colors.primaryFg,
    textAlign: "center",
    marginTop: 16,
    marginBottom: 12,
  } as React.CSSProperties;

  const presentNewPrompt = useNewGroupPrompt(fetchGroups);

  return (
    <div style={style}>
      <div style={{ marginBottom: 4 }}>
        {L10n.localize((s) => s.sharing.studyGroupsDescription)}
      </div>
      <IonButton fill="clear" expand="full" onClick={presentNewPrompt}>
        {L10n.localize((s) => s.sharing.addStudyGroup)}
      </IonButton>
    </div>
  );
}

interface IStudyGroupsListProps {
  checkedGroupIDs: Set<string>;
  uncheckGroupID: (id: string) => void;
  checkGroupID: (id: string) => void;
}
function StudyGroupsList(props: IStudyGroupsListProps) {
  const { checkedGroupIDs, checkGroupID, uncheckGroupID } = props;
  const [groups, loadingState, fetchGroups] = useStudyGroups();

  function handleChange(id: string, e: IonCheckboxCustomEvent<CheckboxChangeEventDetail>) {
    if (e.detail.checked) {
      checkGroupID(id);
    } else {
      uncheckGroupID(id);
    }
  }

  const isEmpty = !loadingState.loading && groups.length < 1;

  return (
    <IonList>
      <IonItemDivider>{L10n.localize((s) => s.groups.studyGroups)}</IonItemDivider>
      {loadingState.loading && (
        <div style={{ textAlign: "center", margin: "8px 0" }}>
          <InlineLoadingSpinner />
        </div>
      )}
      {isEmpty && <StudyGroupsEmpty fetchGroups={fetchGroups} />}
      {groups.map((g) => {
        const checked = checkedGroupIDs.has(g.id);

        return (
          <IonItem key={g.id}>
            <IonLabel>{g.name}</IonLabel>
            <IonCheckbox
              checked={checked}
              onIonChange={(e: CheckboxCustomEvent) => handleChange(g.id, e)}
            />
          </IonItem>
        );
      })}
    </IonList>
  );
}

interface IShareModalProps {
  onDismiss: () => void;
  onShare: (emails: string[], groupIDs: string[]) => void;
  defaultEmails?: string[];
}
export default function ShareModal(props: IShareModalProps) {
  const { onDismiss, defaultEmails, onShare } = props;

  const [emails, setEmails] = React.useState<string[]>(defaultEmails ?? []);

  const [checkedGroupIDs, setCheckedGroupIDs] = React.useState(new Set<string>());

  function handleShare() {
    onShare(emails, Array.from(checkedGroupIDs));
  }

  return (
    <IonPage style={{ marginBottom: "env(safe-area-inset-bottom)" }}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.actions.share)}</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={onDismiss}>{L10n.localize((s) => s.actions.cancel)}</IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton
              onClick={handleShare}
              disabled={emails.length < 1 && checkedGroupIDs.size < 1}
            >
              {L10n.localize((s) => s.actions.share)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StudyGroupsList
          checkedGroupIDs={checkedGroupIDs}
          checkGroupID={(id) => {
            checkedGroupIDs.add(id);
            setCheckedGroupIDs(new Set(checkedGroupIDs));
          }}
          uncheckGroupID={(id) => {
            checkedGroupIDs.delete(id);
            setCheckedGroupIDs(new Set(checkedGroupIDs));
          }}
        />

        <EditableEmailList emails={emails} setEmails={setEmails} />
      </IonContent>
    </IonPage>
  );
}
