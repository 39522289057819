import { IonSkeletonText } from "@ionic/react";
import React from "react";

type IProps = {
  numRows: number;
  numCols: number;
  style?: React.CSSProperties;
};

export default function LoadingTable({ numRows, numCols, style }: IProps) {
  return (
    <table style={{ width: "100%", ...style }}>
      <tbody>
        {Array.from({ length: numRows }, (_, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: numCols }, (_, colIndex) => (
              <td key={colIndex} style={{ height: 24, padding: 6 }}>
                <IonSkeletonText
                  animated={true}
                  style={
                    { "--border-radius": 0 } as React.CSSProperties & { "--border-radius": number }
                  }
                />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
