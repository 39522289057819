import { DeckFields, IDeckConfig } from "@models/deck";
import DeckConfigScreen from "@screens/deckConfig/deckConfig";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import React, { useCallback } from "react";
import ConfigItem from "./configItem";
import { IConfigSelection } from "./main";

interface IProps {
  selected?: boolean;
  base?: string;
  name?: string;
  fields: DeckFields;
  values?: Record<string, string>;
  description?: string;
  onSave: (cfg: IConfigSelection) => void;
  backOnly?: boolean;
}

export default function ConfigEditorItem(props: IProps) {
  const { name, fields, base, onSave, description, values } = props;
  const handleSave = useCallback(
    (cfg: IDeckConfig) => {
      onSave({
        name,
        description,
        base: cfg.base,
        fields: cfg.fields,
        values,
      });
    },
    [description, name, onSave, values],
  );
  const [presentConfigModal] = useIonCardModalWithDismiss(DeckConfigScreen, {
    fields,
    base,
    onSave: handleSave,
  });

  return <ConfigItem {...props} onClick={presentConfigModal} />;
}
