import Grade from "@components/grade";
import MiddleEllipse from "@components/middleEllipse";
import { IndexedDBBoolean } from "@data/idb";
import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
  IonSpinner,
} from "@ionic/react";
import { humanTimeAgo } from "fields/lang";
import { RowType } from "hooks/data/deckList";
import { archiveOutline, closeCircleOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";
import { FcFolder } from "react-icons/fc";
import { IoCloudOutline } from "react-icons/io5";
import { LuArchiveRestore, LuBookCopy, LuFileEdit, LuFiles, LuPlaySquare } from "react-icons/lu";

function EndIcon({
  type,
  local,
  archived,
  downloading,
  hideGrade,
  hideStatusIcon,
  gradePct,
}: {
  type: RowType;
  local?: number;
  downloading?: boolean;
  archived?: boolean;
  hideGrade?: boolean;
  hideStatusIcon: boolean;
  gradePct?: number;
}) {
  if (downloading) {
    return <IonSpinner name="crescent" />;
  }
  if (archived) {
    return <LuArchiveRestore size={24} />;
  }
  if (!local && type === "deck" && !hideStatusIcon) {
    return <IoCloudOutline size={24} opacity={0.25} />;
  }
  const folderWithGrade = type === "folder" && !!gradePct;
  const showGrade = !hideGrade && (folderWithGrade || type === "deck");
  const gradeLoading = gradePct !== undefined || gradePct === null;
  if (showGrade && gradeLoading) {
    return <Grade percent={gradePct} fontSize="1rem" showPercent={true} />;
  }
  return null;
}

interface IProps {
  key: string;
  hideGrade?: boolean;
  hideStatusIcon: boolean;
  hideModifiedAt?: boolean;
  hideMetadata?: boolean;
  height?: string;
  numKnols?: number;
  numDecks?: number;
  downloadStatus?: string;
  type: RowType;
  name: string;
  description?: string;
  timestamp?: Date;
  modifiedAt?: Date;
  savedKnols?: number;
  hideTimestamp?: boolean;
  leftIcon?: React.ReactElement;
  folderPath?: string; // Full folder "path" like "Folder::Subfolder". Necessary for computing subfolder grades.
  local?: IndexedDBBoolean;
  shared?: IndexedDBBoolean;
  downloading?: boolean;
  archived?: boolean;
  gradePct?: number;
  onDelete?: () => void;
  onArchive?: () => void;
  onClick?: () => void;
}

export default function DeckItem(props: IProps): JSX.Element {
  const {
    timestamp,
    name,
    type,
    hideGrade = false,
    numDecks,
    numKnols,
    hideStatusIcon,
    hideTimestamp,
    hideModifiedAt,
    leftIcon,
    local,
    archived,
  } = props;

  const timeAgo = !timestamp ? null : humanTimeAgo(new Date(timestamp));

  const isEarliestPossibleModifiedAt = props.modifiedAt === new Date("0001-01-01T00:00:00Z");
  const modifiedAtAgo =
    props.modifiedAt === undefined
      ? undefined
      : !props.modifiedAt || isEarliestPossibleModifiedAt
        ? L10n.localize((s) => s.general.never)
        : humanTimeAgo(props.modifiedAt);

  return (
    <IonItemSliding>
      {props.onDelete ? (
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={props.onDelete}>
            <IonIcon slot="icon-only" color="light" icon={closeCircleOutline} />
          </IonItemOption>
        </IonItemOptions>
      ) : props.onArchive ? (
        <IonItemOptions side="end">
          <IonItemOption color="warning" onClick={props.onArchive}>
            <IonIcon slot="icon-only" color="light" icon={archiveOutline} />
          </IonItemOption>
        </IonItemOptions>
      ) : undefined}
      <IonItem onClick={props.onClick} button detail={!archived}>
        {leftIcon ? <IonNote slot="start">{leftIcon}</IonNote> : undefined}
        {type === "folder" && <FcFolder size={32} style={{ marginRight: "12px" }} />}
        <IonLabel
          style={{
            maxWidth: "calc(100vw - 160px)", // HACK: this keeps the detail arrow etc.. from wrapping to a new line.
          }}
        >
          <h2>
            <MiddleEllipse text={name} />
          </h2>
          {props.hideMetadata ? undefined : (
            <div style={{ color: "var(--ion-color-medium)" }}>
              {props.description && (
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {props.description}
                </div>
              )}
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginTop: 2,
                  minHeight: 26, // Prevent UI jump when last reviewed at loads in.
                }}
              >
                {numDecks !== undefined && (
                  <>
                    <LuBookCopy style={{ marginRight: 2, opacity: 0.75 }} />
                    <span style={{ marginRight: 8 }}>
                      {new Intl.NumberFormat(L10n.currentLocale).format(numDecks)}
                    </span>
                  </>
                )}
                {numKnols !== undefined && (
                  <>
                    <LuFiles style={{ marginRight: 2, opacity: 0.75 }} />
                    <span style={{ marginRight: 8 }}>
                      {new Intl.NumberFormat(L10n.currentLocale).format(numKnols)}
                    </span>
                  </>
                )}
                {!hideTimestamp && (
                  <div style={{ display: "flex", flex: "1" }}>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      {modifiedAtAgo && !hideModifiedAt ? (
                        <>
                          <span>
                            <LuFileEdit style={{ marginTop: 4, marginRight: 2, opacity: 0.75 }} />
                          </span>
                          <span>{modifiedAtAgo}</span>
                        </>
                      ) : undefined}
                    </span>
                    {timeAgo && (
                      <span
                        style={{
                          marginLeft: 8,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          <LuPlaySquare style={{ marginTop: 6, marginRight: 2, opacity: 0.75 }} />
                        </span>
                        <span>{timeAgo}</span>
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </IonLabel>
        <IonNote slot="end">
          <EndIcon
            type={type}
            archived={archived}
            local={local}
            downloading={props.downloading}
            hideStatusIcon={hideStatusIcon}
            hideGrade={hideGrade}
            gradePct={props.gradePct}
          />
        </IonNote>
      </IonItem>
    </IonItemSliding>
  );
}
