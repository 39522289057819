import Footer from "@components/footer";
import LoadingSpinner from "@components/loadingSpinner";
import { IonContent, IonPage, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import { StyleReactProps } from "@ionic/react/dist/types/components/react-component-lib/interfaces";
import { ionicMode, isAuthed } from "ionicApp";
import cyAttr from "lib/cyAttr";
import React from "react";
import { Redirect } from "react-router-dom";
import Style from "style";
import ErrorBoundary from "./errorBoundary";
import Header from "./screenHeader";
import ThemedScreen from "./themedScreen";

export const SlugContext = React.createContext<string>("");

interface IProps {
  title?: string | React.ReactElement;
  subtitle?: string;
  confirmCancel?: boolean;
  backIsCancel?: boolean;
  backText?: string;
  onBack?: () => void;
  content: React.ReactNode;
  leftButton?: React.ReactElement;
  rightButton?: React.ReactElement;
  searchRightButton?: React.ReactElement;
  helpQuery?: string;
  bottomButton?: React.ReactElement;
  disabled?: boolean;
  authRequired?: boolean;
  defaultBackLink?: string;
  searchQuery?: string;
  searchPlaceholder?: string;
  onSearch?: (query: string) => void;
  onLiveSearch?: (query: string) => void;
  searchDebounceMs?: number;
  noBigTitle?: boolean;
  headerBanner?: React.ReactNode;
  id?: string;
  slug?: string;
  ellipsizeTitle?: boolean;
  contentStyle?: StyleReactProps["style"];
  fullscreen?: boolean;
  isTheaterMode?: boolean;
  footerStyle?: Record<string, string | number>;
}
export default function Screen(props: IProps): JSX.Element {
  const {
    headerBanner,
    title,
    defaultBackLink,
    backIsCancel,
    backText,
    confirmCancel,
    leftButton,
    rightButton,
    onBack,
    searchQuery,
    searchPlaceholder,
    onSearch,
    onLiveSearch,
    searchRightButton,
    bottomButton,
    noBigTitle,
    searchDebounceMs,
    id,
    slug,
    content,
    authRequired = true,
    disabled,
    contentStyle,
    fullscreen = true,
    isTheaterMode,
  } = props;

  // HACK: the iOS status bar is supposed to React to the color of the view underneath it,
  // but that appears to be flaky at least with Ionic (or something we're doing?).
  // This resets the status bar color, to its current setting, which causes fixes the
  // issue where it ends up being a white status bar on white background color or vice versa.
  useIonViewWillEnter(() => {
    Style.setStatusBar();
  }, []);
  useIonViewWillLeave(() => {
    Style.setStatusBar();
  }, []);

  React.useEffect(() => {
    function handleColorSchemeChange() {
      const theme = Style.getPersistedTheme();
      Style.setTheme(theme);
    }

    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");

    // NOTE: ?. is necessary because old versions of Safari (e.g. that on iOS 13.0) don't support mediaQuery.addEventListener.
    mediaQuery.addEventListener?.("change", handleColorSchemeChange);
    return () => {
      mediaQuery.removeEventListener?.("change", handleColorSchemeChange);
    };
  }, []);

  if (authRequired && !isAuthed()) {
    return <Redirect to="/auth" />;
  }

  const shouldShowHeader = title || onBack || rightButton || searchQuery;

  // Disable iOS big title and collapsing small title mechanism for win/android mode.
  const hideBigTitle = noBigTitle || ionicMode === "md";

  const fullSlug = `${slug ?? title}-screen`;
  return (
    <SlugContext.Provider value={fullSlug}>
      <IonPage {...cyAttr(fullSlug)} id={id}>
        {shouldShowHeader && (
          <Header
            banner={headerBanner}
            collapse={hideBigTitle ? undefined : false}
            title={title}
            defaultBackLink={defaultBackLink}
            backIsCancel={backIsCancel}
            backText={backText}
            confirmCancel={confirmCancel}
            leftButton={leftButton}
            rightButton={rightButton}
            searchQuery={searchQuery}
            searchPlaceholder={searchPlaceholder}
            onSearch={onSearch}
            onLiveSearch={onLiveSearch}
            searchRightButton={searchRightButton}
            searchDebounceMs={searchDebounceMs}
            isTheaterMode={isTheaterMode}
          />
        )}

        <ErrorBoundary>
          <IonContent
            fullscreen={fullscreen}
            style={
              {
                "--ion-background-color": isTheaterMode ? Style.colors.primaryBg : undefined,
                ...contentStyle,
              } as React.CSSProperties & { "--ion-background-color": string }
            }
          >
            {shouldShowHeader && !hideBigTitle && (
              <Header
                collapse
                title={title}
                defaultBackLink={defaultBackLink}
                backIsCancel={backIsCancel}
                backText={backText}
                confirmCancel={confirmCancel}
                leftButton={leftButton}
                rightButton={rightButton}
                searchQuery={searchQuery}
                searchPlaceholder={searchPlaceholder}
                onSearch={onSearch}
                onLiveSearch={onLiveSearch}
                searchRightButton={searchRightButton}
                searchDebounceMs={searchDebounceMs}
                isTheaterMode={isTheaterMode}
              />
            )}

            {disabled && (
              <div
                style={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  height: "100%",
                  left: 0,
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  zIndex: 99998, // below pop-up prompt
                }}
              >
                <LoadingSpinner />
              </div>
            )}

            <ThemedScreen screen={content} />
          </IonContent>

          {bottomButton && (
            <Footer isTheaterMode={isTheaterMode} style={props.footerStyle}>
              {bottomButton}
            </Footer>
          )}
        </ErrorBoundary>
      </IonPage>
    </SlugContext.Provider>
  );
}
