import { subFolderMagicSeparator } from "@data/lib/folders";
import { getAllFoldersIn, parseSubfolders } from "@models/folder";
import { AsyncVal, useAsyncValState } from "hooks/util/useAsyncValState";
import { useEffect } from "react";
import { superCache } from "./superCache";
import { archiveTag } from "@models/deck";

export function useFolders(inFolder?: string | null): AsyncVal<string[]> {
  const [folders, setFolders, setErr] = useAsyncValState<string[]>();
  useEffect(() => {
    async function update() {
      const fs = getAllFoldersIn(inFolder);
      const nonArchiveFolders = fs.filter(
        (f) => f !== archiveTag && !f.startsWith(archiveTag + subFolderMagicSeparator),
      );
      setFolders(nonArchiveFolders);
    }
    update();
    superCache.events.on("folderUpdated", update);
    return () => {
      superCache.events.off("folderUpdated", update);
    };
  }, [inFolder, setErr, setFolders]);
  return folders;
}

export interface IFolderItem {
  name: string;
  total_decks?: number;
  timestamp?: string;
  modified_at: string;
}

export function rootFolder(folder?: string, underFolder = ""): string | undefined {
  if (folder === undefined) {
    return;
  }
  if (underFolder && !folder.startsWith(underFolder + subFolderMagicSeparator)) {
    return;
  }
  const suffix = underFolder
    ? folder.substring(underFolder.length + subFolderMagicSeparator.length)
    : folder;
  const subfolders = parseSubfolders(suffix);
  return subfolders[0];
}
