import {
  IonItem,
  IonLabel,
  IonNavLink,
  IonNote,
  RadioGroupChangeEventDetail,
  RadioGroupCustomEvent,
} from "@ionic/react";
import React from "react";
import RadioSelectSettingNav from "./radioSelectSettingNav";

export interface IRadioSelectOption<T> {
  name: string;
  val: T;
  desc?: string;
}

interface IProps<T> {
  title: string;
  options: IRadioSelectOption<T>[];
  selectedItemVal: string;
  onChange: (val: T) => void;
  disabled?: boolean;
  description?: string;
}

export default function RadioSelectSettingNavItem<T extends string>(props: IProps<T>): JSX.Element {
  const { disabled, description, options, selectedItemVal, title, onChange } = props;

  const selection = options.find((opt) => opt.val === selectedItemVal);

  const handleChange = async (
    val: T,
    ev: RadioGroupCustomEvent<RadioGroupChangeEventDetail<any>>,
  ) => {
    onChange(val);
    const ionNav = (ev.target as Element).closest("ion-nav");
    if (ionNav) {
      // Call the pop method on ionNav.
      // Assuming ionNav is an IonNav instance, the pop method returns a Promise.
      // Use await to wait for the Promise to resolve.
      await (ionNav as HTMLIonNavElement).pop();
    }
  };

  return (
    <IonNavLink
      routerDirection="forward"
      component={() => (
        <RadioSelectSettingNav<T>
          title={title}
          selectedItemVal={selectedItemVal}
          onChange={handleChange}
          options={options}
        />
      )}
    >
      <IonItem button disabled={disabled}>
        <IonLabel>
          <h2>{title}</h2>
          {description ? <p>{description}</p> : undefined}
        </IonLabel>
        <IonNote>{selection?.name}</IonNote>
      </IonItem>
    </IonNavLink>
  );
}
