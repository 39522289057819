import cyAttr from "lib/cyAttr";
import React from "react";

interface IProps {
  disabled?: boolean;
  style: React.CSSProperties;
  url: string;
  className?: string;
  maintainOpacity: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}
export default class Clickable extends React.Component<IProps> {
  static defaultProps = {
    maintainOpacity: false,
    preventUnfocus: false,
    url: null,
  };

  constructor(props: IProps) {
    super(props);
    this.myRef = React.createRef();
  }

  myRef: React.RefObject<HTMLDivElement>;

  onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (this.props.disabled) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (this.props.url) {
      location.href = this.props.url;
    } else {
      this.props.onClick?.(e);
    }
    return null;
  };

  onMouseDown = () => {
    return this.onTouchStart();
  };

  onMouseUp = () => {
    if (this.myRef.current) {
      this.myRef.current.style.opacity = "1";
    }
  };

  onTouchStart = () => {
    if (this.props.disabled) {
      return;
    }
    if (!this.props.maintainOpacity) {
      if (this.myRef.current) {
        this.myRef.current.style.opacity = "0.5";
      }
    }
  };

  onTouchEnd = (e: React.TouchEvent<HTMLDivElement>) => {
    if (this.props.disabled) {
      return;
    }
    if (this.myRef.current) {
      this.myRef.current.style.opacity = "1";
    }

    if (e.target === this.myRef.current) {
      return this.onClick(e as unknown as React.MouseEvent<HTMLDivElement>);
    }

    if (e.nativeEvent.changedTouches) {
      const finalPoint = e.nativeEvent.changedTouches[0];
      const finalEl = document.elementFromPoint(finalPoint.clientX, finalPoint.clientY);
      if (this.myRef.current?.contains(finalEl)) {
        return this.onClick(e as unknown as React.MouseEvent<HTMLDivElement>);
      }
    }
  };

  onPointerDown = () => {
    return this.onTouchStart();
  };

  onPointerUp = (e: React.PointerEvent<HTMLDivElement>) => {
    if (this.props.disabled) {
      return;
    }
    if (this.myRef.current) {
      this.myRef.current.style.opacity = "1";
    }

    if (e.target === this.myRef.current) {
      return this.onClick(e);
    }

    const finalEl = document.elementFromPoint(e.clientX, e.clientY);

    if (this.myRef.current?.contains(finalEl)) {
      return this.onClick(e);
    }
  };

  render() {
    let props = {
      className: this.props.className,
      href: this.props.url || "#",
      onDragStart(e: React.DragEvent<HTMLDivElement>) {
        return e.preventDefault();
      },
      ref: this.myRef,
      style: this.props.style,
      ...cyAttr(this.props.disabled ? "disabled" : "enabled"),
    };

    props = {
      ...props,
      ...{
        onClick: this.onClick,
        onMouseDown: this.onMouseDown,
        onMouseUp: this.onMouseUp,
        onTouchEnd: this.onTouchEnd,
        onTouchStart: this.onTouchStart,
      },
    };

    return <div {...props}>{this.props.children}</div>;
  }
}
