import NestedNav from "@components/nestedNav";
import { IonList } from "@ionic/react";
import L10n from "localization";
import React, { useState } from "react";
import { FieldType, fieldTypes } from "fields/fields";
import FieldTypeListItem from "./fieldTypeListItem";

// You can't change to or from these types (unless this is a not-yet-created deck).
// Doing so would cause data loss.
const changeProhibitedTypes: FieldType[] = ["audio", "image"];

export function disableField(
  srcType: FieldType,
  destType: FieldType,
  creatingConfig?: boolean,
): boolean {
  const disableOtherTypes = changeProhibitedTypes.includes(srcType) && !creatingConfig;
  const invalidTargetType = !creatingConfig && changeProhibitedTypes.includes(destType);
  return destType !== srcType && (disableOtherTypes || invalidTargetType);
}

interface IProps {
  selectedType: FieldType;
  onSelect: (type: FieldType) => void;
  creatingConfig?: boolean;
}
export default function FieldTypeSelector({ selectedType, onSelect, creatingConfig }: IProps) {
  // HACK: passing state around in IonNav isn't working.
  // This mirrors local and parent state. :/
  const [currType, setCurrType] = useState(selectedType);
  function handleSelect(newType: FieldType) {
    setCurrType(newType);
    onSelect(newType);
  }

  return (
    <NestedNav title={L10n.localize((s) => s.field.type)}>
      <IonList>
        {fieldTypes.map((type) => {
          const selected = type === currType;
          return (
            <FieldTypeListItem
              key={type}
              type={type}
              selected={selected}
              onClick={() => handleSelect(type)}
              disabled={disableField(currType, type, creatingConfig)}
            />
          );
        })}
      </IonList>
    </NestedNav>
  );
}
