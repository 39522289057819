import React from "react";
import { isDarkMode } from "style";

export const preventOrchestrationDatasetKey = "preventOrchestration";

interface IAudioBlobProps {
  disabled?: boolean;
  id?: string;
  url?: string;
  preventOrchestration?: boolean;
}
export default function AudioBlob({
  id,
  url,
  disabled,
  preventOrchestration,
}: IAudioBlobProps): React.ReactElement {
  const style: React.CSSProperties = {
    pointerEvents: disabled ? "none" : undefined,

    // HACK: without these, the <audio> tag was collapsing in the layout market.
    width: "100%",
    maxWidth: 360,
    minWidth: 120,
    alignSelf: "center",
  };

  return (
    <span className="audio-containment-context">
      <span className={`audio-container ${isDarkMode() ? "dark-mode" : "light-mode"}`}>
        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
        <audio
          controls
          autoPlay={false}
          data-blob-id={id}
          src={disabled ? undefined : url}
          style={style}
          data-prevent-orchestration={preventOrchestration}
        />
      </span>
    </span>
  );
}
