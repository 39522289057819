import NestedNav from "@components/nestedNav";
import React from "react";
import L10n from "localization";
import useStateTee from "hooks/util/useStateTee";
import { IField } from "fields/fields";
import RefFieldSelector from "./refFieldSelector";

interface IProps {
  refField: string | undefined;
  refFields: IField[];
  onSelect: (newRefField: string | undefined) => void;
  disabledFields?: string[];
}
export default function RefFieldNav({ refField, refFields, onSelect, disabledFields }: IProps) {
  // HACK: Ionic's Navs don't update properly when their props change :(
  const [selectedRefField, selectRefField] = useStateTee(refField, onSelect);

  return (
    <NestedNav title={L10n.localize((s) => s.field.translationSource)}>
      <RefFieldSelector
        refField={selectedRefField}
        refFields={refFields}
        onSelect={selectRefField}
        disabledFields={disabledFields}
      />
    </NestedNav>
  );
}
