import L10n from "localization";
import React from "react";
import Device from "device";
import Globals from "globals";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenuButton,
  IonNav,
  IonNavLink,
  IonNote,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  briefcaseOutline,
  bugOutline,
  documentTextOutline,
  fingerPrintOutline,
} from "ionicons/icons";
import Logo from "@screens/auth/logo";
import Debug from "./debug";
import Licenses from "./licenses";

const numLogoClicksToUnlockDebug = 10;

export default function About(): JSX.Element {
  const clientVersion = Globals.version || "";
  const clientVersionHash = Globals.versionHash || "";

  const [numLogoClicks, setNumLogoClicks] = React.useState(0);
  function incLogoClicks() {
    setNumLogoClicks(numLogoClicks + 1);
  }
  const showDebug = numLogoClicks >= numLogoClicksToUnlockDebug;

  const root = (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.general.about)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <Logo
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          onClick={incLogoClicks}
        />
        <IonList inset>
          <IonListHeader>
            <IonLabel>{L10n.localize((s) => s.general.device)}</IonLabel>
          </IonListHeader>
          <IonItem>
            <IonLabel>{L10n.localize((s) => s.general.id)}</IonLabel>
            <IonNote slot="end">{Device.getID().substring(0, 6)}</IonNote>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>{L10n.localize((s) => s.general.version)}</IonLabel>
            <IonNote slot="end">{`${clientVersion} (${clientVersionHash})`}</IonNote>
          </IonItem>
        </IonList>
        <IonList inset style={{ marginTop: 32 }}>
          <IonNavLink routerDirection="forward" component={() => <Licenses />}>
            <IonItem detail button>
              <IonIcon icon={briefcaseOutline} slot="start" />
              <IonLabel>{L10n.localize((s) => s.profile.licenses)}</IonLabel>
            </IonItem>
          </IonNavLink>
          <IonItem
            detail
            button
            onClick={() =>
              Device.openExternalLink("https://www.ankiapp.com/terms.html?s=app&l=profile")
            }
          >
            <IonIcon icon={documentTextOutline} slot="start" />
            <IonLabel>{L10n.localize((s) => s.account.termsOfService)}</IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            detail
            button
            onClick={() =>
              Device.openExternalLink("https://www.ankiapp.com/privacy.html?s=app&l=profile")
            }
          >
            <IonIcon icon={fingerPrintOutline} slot="start" />
            <IonLabel>{L10n.localize((s) => s.account.privacyPolicy)}</IonLabel>
          </IonItem>
        </IonList>
        {showDebug && (
          <IonList inset>
            <IonNavLink routerDirection="forward" component={() => <Debug />}>
              <IonItem button detail lines="none">
                <IonIcon icon={bugOutline} slot="start" />
                <IonLabel>{L10n.localize((s) => s.general.debug)}</IonLabel>
              </IonItem>
            </IonNavLink>
          </IonList>
        )}
      </IonContent>
    </>
  );

  return <IonNav root={() => root} />;
}
