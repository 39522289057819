// HACK: This functionality lets a caller set the default folder that the
// DeckCreateMainNav will use, which is useful when imperatively showing
// the modal (because you can't pass props at modal display time).

let defaultFolder: string | undefined = undefined;

export function setDefaultFolder(folder: string) {
  defaultFolder = folder;
}

export function getDefaultFolder(): string | undefined {
  const folder = defaultFolder;
  defaultFolder = undefined;
  return folder;
}
