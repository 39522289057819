import { showPrompt } from "@components/prompt";
import ScreenMenu, { MenuButton } from "@components/screenMenu";
import { Deck, IDeck } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import { IContentReportInsertOperation } from "@operations/contentReport";
import { useCardModal } from "@screens/card";
import {
  banOutline,
  createOutline,
  filmOutline,
  flag,
  playSkipForwardOutline,
  pricetag,
  pricetags,
  pricetagsOutline,
  starOutline,
} from "ionicons/icons";
import React from "react";
import L10n from "../../localization";
import { Operation } from "../../models/operation";
import { IReviewCardContent } from "./types";

interface IToggleTagMenuItemProps {
  isToggle: boolean;
  cardTags: string[] | undefined;
  knolId: string;
  setCardTags: (update: (cardTags: string[]) => string[]) => void;
  tag: string;
  icon: string;
  label: string;
}

interface IMarkOrIgnoreCardMenuItemProps {
  isToggle: boolean;
  cardTags: string[] | undefined;
  knolId: string;
  setCardTags: (update: (cardTags: string[]) => string[]) => void;
}

function ToggleTagMenuItem(props: IToggleTagMenuItemProps): React.ReactElement {
  const { cardTags, knolId, setCardTags, tag } = props;
  const isActive = cardTags?.includes(tag);

  function toggleCardTag() {
    const addTag = (tag: string) => doAddTag(knolId, tag, setCardTags);
    const removeTag = (tag: string) => doRemoveTag(knolId, tag, setCardTags);

    if (cardTags?.includes(tag)) {
      removeTag(tag);
    } else {
      addTag(tag);
    }
  }

  const { icon, label } = props;
  return (
    <MenuButton
      toggled={isActive}
      label={label}
      icon={icon}
      onClick={toggleCardTag}
      detail={false}
    />
  );
}

function MarkCardMenuItem(props: IMarkOrIgnoreCardMenuItemProps): React.ReactElement {
  return (
    <ToggleTagMenuItem
      {...props}
      tag={Knol.MARKED_TAG}
      icon={starOutline}
      label={L10n.localize((s) => s.actions.star)}
    />
  );
}

function IgnoreMenuItem(props: IMarkOrIgnoreCardMenuItemProps): React.ReactElement {
  return (
    <ToggleTagMenuItem
      {...props}
      isToggle={true}
      tag={Knol.IGNORE_TAG}
      icon={banOutline}
      label={L10n.localize((s) => s.actions.ignore)}
    />
  );
}

function TagCardMenuItem(props: {
  knolId: string;
  setCardTags: (update: (cardTags: string[]) => string[]) => void;
  setIsAcceptingUserInput: (arg0: boolean) => void;
}): React.ReactElement {
  const { knolId, setCardTags, setIsAcceptingUserInput } = props;

  const addTag = (tag: string) => doAddTag(knolId, tag, setCardTags);

  function handleTagCard() {
    setIsAcceptingUserInput(true);

    return showPrompt({
      promptType: "input",
      title: L10n.localize((s) => s.tag.singular),
      prompt: "",
      validationRegex: /.+/,
      onHide: () => setIsAcceptingUserInput(false),
      callback: (response) => {
        const tagName = response?.trim().toLowerCase() ?? "";
        if (tagName === "") {
          return;
        }
        addTag(tagName);
      },
    });
  }

  return (
    <MenuButton
      label={L10n.localize((s) => s.tag.singular)}
      icon={pricetag}
      onClick={handleTagCard}
      detail={false}
    />
  );
}

function ShowTagsMenuItem(props: {
  showTags: boolean;
  toggleShowTags: () => void;
  isToggle: boolean;
}): React.ReactElement {
  const { showTags, toggleShowTags } = props;
  return (
    <MenuButton
      toggled={showTags}
      label={L10n.localize((s) => s.tag.show)}
      icon={showTags ? pricetagsOutline : pricetags}
      onClick={toggleShowTags}
      detail={false}
    />
  );
}

function SkipMenuItem(props: {
  disabled: boolean;
  skipToRecap: () => void;
}): React.ReactElement {
  const { disabled, skipToRecap } = props;

  return (
    <MenuButton
      disabled={disabled}
      detail
      label={L10n.localize((s) => s.general.recap)}
      icon={playSkipForwardOutline}
      onClick={skipToRecap}
    />
  );
}

function EditCardMenuItem(props: {
  reloadPresentationData: () => void;
  deck: IDeck | undefined;
  knol: IKnol;
  setIsAcceptingUserInput: (arg0: boolean) => void;
}): React.ReactElement {
  const { reloadPresentationData, deck, knol, setIsAcceptingUserInput } = props;

  function handleDismiss() {
    setIsAcceptingUserInput(false);
    reloadPresentationData();
  }

  const [presentCardEditModal] = useCardModal({
    knol,
    deck,
    onDismiss: handleDismiss,
  });

  function handleClick() {
    presentCardEditModal();
    setIsAcceptingUserInput(true);
  }

  return (
    <MenuButton
      label={L10n.localize((s) => s.card.edit)}
      icon={createOutline}
      onClick={handleClick}
    />
  );
}

function ReportCardMenuItem(props: {
  disabled: boolean;
  knolId: string;
  setPromptIsShowing: (show: boolean) => void;
  layoutID: string | undefined;
}): React.ReactElement {
  const { disabled, knolId, setPromptIsShowing, layoutID } = props;

  function handleReportCard() {
    setPromptIsShowing(true);

    return showPrompt({
      promptType: "input",
      title: L10n.localize((s) => s.actions.reportError),
      prompt: L10n.localize((s) => s.review.reportPrompt),
      validationRegex: /.+/,
      inputType: "textarea",
      onHide: () => setPromptIsShowing(false),
      callback: (response) => {
        const comment = response?.trim();

        const now = new Date().toISOString();
        const op: IContentReportInsertOperation = {
          ...Operation.operationDefaults(),
          type: "INSERT",
          object_type: "content_report",
          object_parameters: {
            knol_id: knolId,
            layout_id: layoutID ?? "TODO",
            report_type: undefined,
            comment,
            timestamp: now,
          },
        };
        Operation.operate(true, [op]);
      },
    });
  }

  return (
    <MenuButton
      disabled={disabled}
      lines="none"
      label={L10n.localize((s) => s.actions.reportError)}
      icon={flag}
      onClick={handleReportCard}
      detail={false}
    />
  );
}

function doAddTag(
  knolId: string,
  tag: string,
  setCardTags: (update: (cardTags: string[]) => string[]) => void,
) {
  Knol.AddTag(knolId, tag).then(() => {
    setCardTags((cardTags: string[]) => {
      if (!cardTags.includes(tag)) {
        cardTags.push(tag);
      }
      return cardTags;
    });
  });
}

function doRemoveTag(
  knolId: string,
  tag: string,
  setCardTags: (update: (cardTags: string[]) => string[]) => void,
) {
  Knol.DeleteTag(knolId, tag).then(() => {
    setCardTags((cardTags: string[]) => {
      return cardTags.filter((t) => t !== tag);
    });
  });
}

interface IMenuProps {
  cardContent: IReviewCardContent;
  isFirstCard: boolean;
  skipToRecap: () => void;
  showTags: boolean;
  toggleShowTags: () => void;
  setPromptIsShowing: (show: boolean) => void;
  setModalIsShowing: (v: boolean) => void;
  reloadPresentationData: () => void;
  toggleTheaterMode: () => void;
  isTheaterMode: boolean;
  onOpen?: () => void;
  onClose?: () => void;
}

export default function Menu(props: IMenuProps): JSX.Element | null {
  const {
    cardContent,
    isFirstCard,
    skipToRecap,
    showTags,
    toggleShowTags,
    setPromptIsShowing,
    setModalIsShowing,
    reloadPresentationData,
    toggleTheaterMode,
    isTheaterMode,
    onOpen,
    onClose,
  } = props;

  const allowEdit = cardContent.deck?.status === Deck.STATUS_PRIVATE;
  const allowCardReport = cardContent.deck?.status === Deck.STATUS_PUBLIC;

  const reload = reloadPresentationData;
  if (!cardContent.knol) {
    return null;
  }

  return (
    <ScreenMenu dotID="theater_mode_review_screen_menu" onOpen={onOpen} onClose={onClose}>
      <SkipMenuItem disabled={isFirstCard} skipToRecap={skipToRecap} />
      {allowEdit && (
        <EditCardMenuItem
          reloadPresentationData={reload}
          deck={cardContent.deck}
          knol={cardContent.knol}
          setIsAcceptingUserInput={setModalIsShowing}
        />
      )}
      <MarkCardMenuItem
        isToggle={true}
        cardTags={cardContent.knol.tags}
        knolId={cardContent.knol.id}
        setCardTags={reload}
      />
      <IgnoreMenuItem
        isToggle={true}
        cardTags={cardContent.knol.tags}
        knolId={cardContent.knol.id}
        setCardTags={reload}
      />
      <TagCardMenuItem
        knolId={cardContent.knol.id}
        setCardTags={reload}
        setIsAcceptingUserInput={setPromptIsShowing}
      />
      <ShowTagsMenuItem isToggle={true} showTags={showTags} toggleShowTags={toggleShowTags} />
      {allowCardReport && (
        <ReportCardMenuItem
          disabled={!allowCardReport}
          knolId={cardContent.knol.id}
          setPromptIsShowing={setPromptIsShowing}
          layoutID={cardContent.layout?.id}
        />
      )}
      <MenuButton
        toggled={isTheaterMode}
        label={L10n.localize((s) => s.review.isTheaterMode)}
        icon={filmOutline}
        onClick={toggleTheaterMode}
        detail={false}
        lines="none"
        dotID="theater_mode_menu_item"
      />
    </ScreenMenu>
  );
}
