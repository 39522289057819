import CardPreview from "@cardRendering/cardPreview";
import Button from "@components/button";
import Empty from "@components/empty";
import ErrorBoundary from "@components/errorBoundary";
import LayoutStyle from "@components/layoutStyle";
import ProgressPie from "@components/progressPie";
import {
  IonButton,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonNote,
  IonSpinner,
} from "@ionic/react";
import { mapIterable, takeFirstN } from "@lib/iterable";
import MapView from "@lib/mapView";
import { Deck, IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import { ILayout } from "@models/layout";
import { useCardModal } from "@screens/card";
import { useIsPhone } from "hooks/util/useMediaQuery";
import { arrowForward } from "ionicons/icons";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React, { useRef } from "react";
import { ID } from "types/ID";

const NUM_MAX_CARD_PREVIEWS = 10;
const cardHeight = 160;
const cardWidth = 128;
const cardMargin = 24;

function NoCards(props: {
  deck?: IDeck;
  onDismissCardCreateModal?: () => void;
}) {
  const [presentCardCreateModal] = useCardModal({
    deck: props.deck,
    onDismiss: props.onDismissCardCreateModal,
  });
  return (
    <>
      {props.deck && (
        <IonButton fill="clear" expand="full" onClick={presentCardCreateModal}>
          {L10n.localize((s) => s.card.create)}
        </IonButton>
      )}
    </>
  );
}

export function MoreButton(props: {
  deckID: string;
  query?: string;
}): JSX.Element {
  const { deckID, query } = props;

  let href = `/decks/local/${deckID}/cards`;
  if (query) {
    href += `?q=${encodeURIComponent(query)}`;
  }

  return (
    <IonButton
      {...cyAttr("more-menu-button")}
      style={{
        flexShrink: 0,
        width: cardWidth,
        height: cardHeight,
        margin: cardMargin,
      }}
      size="large"
      fill="clear"
      routerLink={href}
    >
      <IonIcon icon={arrowForward} />
    </IonButton>
  );
}

export default function CardsSection(props: {
  loading: boolean;
  deck?: IDeck;
  knols?: MapView<ID, IKnol>;
  filteredKnols: MapView<ID, IKnol>;
  isDownloading?: boolean;
  handleDismissCardCreateModal: () => void | undefined;
}): JSX.Element {
  const { deck, isDownloading, handleDismissCardCreateModal } = props;

  const layoutMap: Record<ID, ILayout> = {};
  for (const layout of deck?.layouts ?? []) {
    layoutMap[layout.id] = layout;
  }

  const editable = deck?.status === Deck.STATUS_PRIVATE;

  const numCards = props.knols?.size ?? 0;
  const noMatchingCards =
    props.filteredKnols !== undefined && numCards > 0 && props.filteredKnols.size < 1;

  const noCards =
    !props.loading &&
    props.knols !== undefined &&
    props.knols.size === 0 &&
    isDownloading === false;

  const newCardSwipeOptionRef = useRef<HTMLIonItemSlidingElement>(null);
  const [presentCardCreateModal] = useCardModal({
    deck,

    onDismiss: () => {
      newCardSwipeOptionRef.current?.close();
    },
  });

  const handleResetFilters = () => {
    if (!deck) {
      return;
    }
    Deck.setSettings(deck, { reviewTags: [], reviewGrades: [] });
  };

  const cardCountDisplay = isDownloading ? (
    <IonSpinner name="crescent" />
  ) : props.filteredKnols !== undefined && numCards !== undefined ? (
    `${new Intl.NumberFormat(L10n.currentLocale).format(
      props.filteredKnols.size,
    )}/${new Intl.NumberFormat(L10n.currentLocale).format(numCards)}`
  ) : (
    "..."
  );

  const activeLayout = deck?.layouts?.find((layout) => layout.id === deck?.layout_id);
  const orientation = deck?.user_config?.settings?.cardOrientation;
  const isPhone = useIsPhone();

  return (
    <>
      <IonItemSliding ref={newCardSwipeOptionRef}>
        <IonItemOptions side="end" onIonSwipe={presentCardCreateModal}>
          <IonItemOption color="success" expandable onClick={presentCardCreateModal}>
            {L10n.localize((s) => s.actions.new)}
          </IonItemOption>
        </IonItemOptions>
        <IonItem
          mode="ios"
          lines="none"
          routerLink={`/decks/local/${deck?.id}/cards`}
          disabled={isDownloading}
        >
          <IonLabel style={{ fontWeight: 700, fontSize: 22 }}>
            <div style={{ placeItems: "center", display: "inline-flex" }}>
              {L10n.localize((s) => s.card.plural)}
              {isDownloading && <ProgressPie progress={1 / 100} />}
            </div>
          </IonLabel>
          <IonNote slot="end">{cardCountDisplay}</IonNote>
        </IonItem>
      </IonItemSliding>
      <ErrorBoundary>
        <div
          style={{
            overflowX: "hidden",
            minHeight: cardHeight + cardMargin * 2,
          }}
        >
          {noCards && editable ? (
            <NoCards onDismissCardCreateModal={handleDismissCardCreateModal} deck={deck} />
          ) : undefined}
          {noMatchingCards ? (
            <>
              <Empty text={L10n.localize((s) => s.deck.noMatchingCards)} style={{ margin: 0 }} />
              <Button
                text={L10n.localize((s) => s.deck.resetFilters)}
                onClick={handleResetFilters}
              />
            </>
          ) : undefined}
          <LayoutStyle
            key={activeLayout?.id ?? deck?.layout_id}
            layoutID={activeLayout?.id ?? deck?.layout_id}
            css={activeLayout?.style ?? ""}
            fontSize={deck?.user_config?.settings?.cardFontSize}
          />
          {/* This is the cards section wrapper div */}
          <div
            style={{
              overflowX: "auto",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 12,
              textAlign: "center",
              paddingBottom: 4,
              paddingRight: 16,
              paddingLeft: 16, // This makes the left border of the first card visible.
              marginLeft: 0,
            }}
          >
            {deck &&
              Array.from(
                mapIterable(
                  takeFirstN(props.filteredKnols.values(), NUM_MAX_CARD_PREVIEWS),
                  (knol) => {
                    return (
                      <CardPreview
                        key={knol.id}
                        visibleSide={orientation === "reversed" ? 1 : 0}
                        deck={deck}
                        knol={knol}
                        isClickable
                        showHeader
                        disableAudioControlsRendering={isPhone}
                      />
                    );
                  },
                ),
              )}
            {deck && props.filteredKnols.size > NUM_MAX_CARD_PREVIEWS && (
              <MoreButton deckID={deck.id} />
            )}
          </div>
        </div>
      </ErrorBoundary>
    </>
  );
}
