import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { defaultDeckSettings } from "@models/deckSettings";
import { BACK, FRONT } from "@screens/session";
import EventBus from "eventBus";
import { useReducer } from "react";
import { getHapticsEnabled } from "settings";
import { IReviewSession } from "./initReviewSession";

interface ILoadingAction {
  type: "loading";
}

interface IErrorAction {
  type: "error";
  error: unknown;
}

interface IBeginAction {
  type: "begin";
  session: IReviewSession;
}

interface IFlipAction {
  type: "flip";
}

interface IAdvanceAction {
  type: "advance";
}

interface ISkipToRecapAction {
  type: "skipToRecap";
}

interface IUpdatePresentationDataAction {
  type: "updatePresentationData";
}

interface ISetPausedAction {
  type: "setPaused";
  paused: boolean;
}

export type ScreenMode = "review" | "recap" | "empty" | "gate";

interface IActiveCard {
  index: number;
  side: 0 | 1;
  startTimeMs: number;
}

export interface IReviewSessionState extends IReviewSession {
  loading: boolean;
  error?: unknown;
  screenMode: ScreenMode;
  activeCard: IActiveCard;
  presentationDataTimestamp: Date;
  paused: boolean;
}

type Action =
  | ILoadingAction
  | IErrorAction
  | IBeginAction
  | IFlipAction
  | IAdvanceAction
  | ISkipToRecapAction
  | IUpdatePresentationDataAction
  | ISetPausedAction;

function sessionReducer(state: IReviewSessionState, action: Action): IReviewSessionState {
  switch (action.type) {
    case "loading": {
      // NOTE: defaultState has loading: true.
      return defaultState();
    }
    case "error": {
      return { ...defaultState(), error: action.error, loading: false };
    }
    case "begin": {
      return {
        ...action.session,
        activeCard: {
          index: 0,
          side: FRONT as 0 | 1,
          startTimeMs: new Date().getTime(),
        },
        presentationDataTimestamp: new Date(),
        loading: false,
        paused: false,
        screenMode: "review",
      };
    }
    case "updatePresentationData": {
      return { ...state, presentationDataTimestamp: new Date() };
    }
    case "flip": {
      if (state.screenMode !== "review") {
        return state;
      }
      if (getHapticsEnabled()) {
        Haptics.impact({ style: ImpactStyle.Light }).catch(() => {
          // Whatever.
        });
      }
      return { ...state, activeCard: { ...state.activeCard, side: BACK } };
    }
    case "advance": {
      if (state.screenMode !== "review") {
        return state;
      }
      const numCards = state.knols.length;
      const nextCardIndex = state.activeCard.index + 1;
      const reviewComplete = nextCardIndex >= numCards;

      if (reviewComplete) {
        if (getHapticsEnabled()) {
          Haptics.notification().catch(() => {
            // Whatever.
          });
        }
        return { ...state, screenMode: "recap" };
      }

      return {
        ...state,
        activeCard: {
          ...state.activeCard,
          side: FRONT,
          index: nextCardIndex,
          startTimeMs: new Date().getTime(),
        },
      };
    }
    case "skipToRecap":
      return { ...state, screenMode: "recap" };
    case "setPaused":
      if (action.paused) {
        EventBus.emit("pauseTimer");
      } else {
        EventBus.emit("resumeTimer");
      }
      return { ...state, paused: action.paused };
  }
}

const defaultState = (): IReviewSessionState => {
  return {
    screenMode: "review",
    decks: new Map(),
    knolIDToLayout: new Map(),
    knolMap: new Map(),
    knols: [],
    cardOrientations: {},
    loading: true,
    error: undefined,
    paused: false,
    settings: defaultDeckSettings(),
    activeCard: {
      index: 0,
      side: FRONT as 0 | 1,
      startTimeMs: new Date().getTime(),
    },
    presentationDataTimestamp: new Date(),
  };
};

export function useSessionReducer(): [IReviewSessionState, React.Dispatch<Action>] {
  return useReducer(sessionReducer, defaultState());
}
