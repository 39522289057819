import { folderDisplayString } from "@components/folderWithSubfoldersString";
import Num from "@components/number";
import Reveal from "@components/reveal";
import UntrustedHtmlWithLinksPermitted from "@components/untrustedHtmlWithLinksPermitted";
import {
  IonButton,
  IonChip,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonNote,
  IonProgressBar,
} from "@ionic/react";
import { Deck } from "@models/deck";
import { trimFolderPrefix } from "@models/folder";
import { IDeckResponse } from "@screens/deckDownload";
import { isLegacyDeck } from "fields/magicLayout";
import { closeCircleOutline, warningOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";
import Style from "style";
import CardPreviews from "./cardPreviews";

interface IProps {
  handleDelete?: () => void;
  isDeleting: boolean;
  isDownloading: boolean;
  deck?: IDeckResponse;
}

export default function Content(props: IProps): JSX.Element {
  const { handleDelete, isDeleting, deck, isDownloading } = props;

  const desc = !deck || !deck.description ? "" : UntrustedHtmlWithLinksPermitted(deck.description);

  const description = (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.general.description)}</IonListHeader>
      <IonItem lines="none">
        <IonLabel className="ion-text-wrap">
          {desc ? (
            desc
          ) : (
            <span style={{ color: Style.colors.secondaryFg }}>
              {L10n.localize((s) => s.general.none)}
            </span>
          )}
          {deck && deck.status === Deck.STATUS_THIRD_PARTY && (
            <>
              <hr key="sep" />
              <Reveal key="reveal">
                <span
                  key="attribution"
                  style={{
                    fontSize: "small",
                    fontStyle: "italic",
                    color: "grey",
                  }}
                >
                  {L10n.localize((s) => s.deck.thirdPartyAttribution)}
                </span>
              </Reveal>
            </>
          )}
        </IonLabel>
      </IonItem>
    </IonList>
  );

  const legacy = isLegacyDeck(deck?.layouts ?? []);
  const noTags = (deck?.tags || []).length === 0;

  // NOTE: only treating a single tag as folder now (the first one)!
  const folder = deck?.tags?.[0] ?? "";

  const stats = (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.general.info)}</IonListHeader>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.card.plural)}</IonLabel>
        <IonNote color="medium" slot="end">
          <Num num={deck?.num_knols} />
        </IonNote>
      </IonItem>
      <IonItem>
        <IonLabel>{L10n.localize((s) => s.general.updated)}</IonLabel>
        <IonNote color="medium" slot="end">
          {deck?.modified_at &&
            Intl.DateTimeFormat(L10n.getCurrentLocale(), {
              dateStyle: "medium",
            }).format(new Date(deck.modified_at))}
        </IonNote>
      </IonItem>

      {legacy ? (
        <IonItem>
          <IonLabel>{L10n.localize((s) => s.layout.plural)}</IonLabel>
          <IonNote color="medium" slot="end">
            {(deck?.layouts || []).length === 0 ? (
              <span style={{ fontStyle: "italic" }}>{L10n.localize((s) => s.general.none)}</span>
            ) : (
              deck?.layouts?.map((layout: any, idx: number) => {
                return <div key={layout.name + idx}>{layout.name}</div>;
              })
            )}
          </IonNote>
        </IonItem>
      ) : undefined}

      <IonItem lines="none">
        <IonLabel>{L10n.localize((s) => s.folder.singular)}</IonLabel>
        <IonNote slot="end">
          {noTags ? L10n.localize((s) => s.general.none) : folderDisplayString(folder)}
        </IonNote>
      </IonItem>
    </IonList>
  );

  const canDelete = deck?.status && handleDelete && deck.status === Deck.STATUS_PRIVATE;

  // Handle case where deck has "F::D" style folder prefix on the deck name.
  const displayName = deck ? trimFolderPrefix(folder, deck.name) : "";

  return (
    <>
      <IonListHeader style={{ marginTop: 8, marginBottom: 8 }}>{displayName}</IonListHeader>
      {!deck?.layouts ||
        (deck?.layouts.length < 1 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <IonChip style={{ pointerEvents: "none" }} color="warning">
              <IonIcon icon={warningOutline} />
              <IonLabel color="warning">
                {L10n.localize((s) => s.deck.noLayouts).toLocaleUpperCase()}
              </IonLabel>
            </IonChip>
          </div>
        ))}
      <div>
        <div style={{ width: "100%", minHeight: 320 }}>
          {isDownloading ? (
            <IonProgressBar type="indeterminate" />
          ) : (
            deck && (
              <CardPreviews
                knols={deck?.knols || []}
                isLoading={false}
                visibleSide={0}
                deck={{
                  ...deck,
                  layout_id: deck.layout_id ?? deck.layouts?.[0].id,
                  modified_at: new Date(deck?.modified_at || new Date()),
                  created_at: new Date(deck?.created_at || new Date()),
                }}
              />
            )
          )}
        </div>
        {description}
        <div>{stats}</div>
        {canDelete && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <IonButton
              size="small"
              style={{ padding: "16px 0px" }}
              fill="outline"
              onClick={handleDelete}
              color="danger"
              disabled={isDeleting}
            >
              <IonIcon slot="start" icon={closeCircleOutline} />
              {L10n.localize((s) => s.actions.delete)}
            </IonButton>
          </div>
        )}
      </div>
    </>
  );
}
