import { IGrade } from "@models/knol";

export default function cardPriority(card: IGrade): number {
  if (typeof card.score_mean !== "number") {
    return Number.NaN;
  }
  if (typeof card.score_standard_deviation !== "number") {
    return Number.NaN;
  }

  return Math.max(0, 1.0 - (card.score_mean - card.score_standard_deviation * 0.5));
}
