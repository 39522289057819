import { IBaseOperation } from "@models/operation";

export interface IContentReportInsertOperation extends IBaseOperation {
  object_type: "content_report";
  type: "INSERT";
  object_parameters: {
    knol_id: string;
    layout_id: string;
    report_type?: string;
    comment?: string;
    timestamp: string;
  };
}

export type ContentReportOperation = IContentReportInsertOperation;
