import { IAccountInfo } from "@models/user";
import { setName } from "data/stores/name";
import EventBus from "eventBus";
import { User } from "models/user";
import Network from "network";
import { useEffect, useState } from "react";

interface IFeatures {
  ai_card_create?: AICardCreateFlag;
}

interface AICardCreateFlag {
  max_tokens: number;
  with_image: boolean;
  with_prompt: boolean;
}

interface ICompleteFeatureFlags {
  review_gate: ReviewGateFlag;
  beta_channel: BetaFlag;
  features: IFeatures;
}

// The actual feature flags object from the server may not include any of them.
export type IFeatureFlags = Partial<ICompleteFeatureFlags>;

type FeatureFlagName = keyof ICompleteFeatureFlags;
type FeatureFlag = ICompleteFeatureFlags[FeatureFlagName];

// TypeScript representation of the `reviewGateFlag` struct
export interface ReviewGateFlag {
  user_created_at_threshold: string;
  num_daily_reviews_warn_threshold?: number;
  num_daily_reviews_gate_threshold?: number;
  num_total_reviews_warn_threshold?: number;
}

interface BetaFlag {
  has_opted_in: boolean;
  is_eligible: boolean;
}

interface IFlagCache {
  userCreatedAt: string;
  hasUnlimited: boolean;
  flags?: IFeatureFlags;
}

interface IFlagWithContext<T extends FeatureFlag> {
  flag?: T;
  context?: {
    userCreatedAt: string;
    hasUnlimited: boolean;
  };
  error: boolean;
  fetching: boolean;
}

const flagCacheLocalStorageKey = "flagCache";

class Flags {
  flagCache: IFlagCache | undefined = undefined;
  private fetching = false;
  id = Math.random();

  constructor() {
    // Load from cache, if possible.
    try {
      const json = localStorage.getItem(flagCacheLocalStorageKey);
      if (json) {
        // TODO: runtime type-check this (and network response too).
        const cache = JSON.parse(json) as IFlagCache;
        this.flagCache = cache;
      }
    } catch {
      // Oh well.
    }
  }

  invalidateFlagCache(): void {
    this.flagCache = undefined;
    EventBus.emit("flagCacheInvalidated");
  }

  async updateFlagCache(): Promise<void> {
    if (!User.isLoggedIn()) {
      return;
    }

    try {
      this.fetching = true;
      const info = await Network.fetch<IAccountInfo>("GET", "/users/account?flags=true");
      this.flagCache = {
        userCreatedAt: info.created_at,
        hasUnlimited: info.has_unlimited,
        flags: info.flags,
      };
      this.fetching = false;

      // Cache results.
      localStorage.setItem(flagCacheLocalStorageKey, JSON.stringify(this.flagCache));

      // Set name.
      const { name_first, name_last } = info;
      await setName({ first: name_first, last: name_last });

      EventBus.emit("flagCacheUpdated");
    } catch (e) {
      console.error("Failed to fetch flags", e);
      this.fetching = false;
    }
  }

  getFlagAndContext<T extends keyof ICompleteFeatureFlags>(
    name: T,
  ): IFlagWithContext<ICompleteFeatureFlags[T]> {
    const needsRefetch = !this.flagCache && !this.fetching;

    if (needsRefetch) {
      this.updateFlagCache();
    }

    if (this.fetching) {
      return {
        flag: undefined,
        context: undefined,
        error: false,
        fetching: true,
      };
    }

    // Typecheck and extract context.
    if (typeof this.flagCache?.userCreatedAt !== "string") {
      return {
        flag: undefined,
        context: undefined,
        error: true,
        fetching: false,
      };
    }
    if (typeof this.flagCache?.hasUnlimited !== "boolean") {
      return {
        flag: undefined,
        context: undefined,
        error: true,
        fetching: false,
      };
    }
    const { userCreatedAt, hasUnlimited } = this.flagCache;

    // Typecheck flag.
    if (this.flagCache?.flags == null) {
      return {
        flag: undefined,
        context: undefined,
        error: true,
        fetching: false,
      };
    }

    if (typeof this.flagCache?.flags !== "object") {
      return {
        flag: undefined,
        context: undefined,
        error: true,
        fetching: false,
      };
    }

    // if (name in this.flagCache.flags === false) {
    //   return {
    //     flag: undefined,
    //     context: undefined,
    //     error: true,
    //     fetching: false,
    //   };
    // }

    return {
      flag: this.flagCache.flags[name],
      context: { userCreatedAt, hasUnlimited },
      error: false,
      fetching: false,
    };
  }
}

const flags = new Flags();

export function useFlagAndContext<T extends keyof IFeatureFlags>(
  name: T,
): IFlagWithContext<ICompleteFeatureFlags[T]> | undefined {
  const [flag, setFlag] = useState<IFlagWithContext<ICompleteFeatureFlags[T]>>();
  useEffect(() => {
    function update() {
      const f = flags.getFlagAndContext(name);
      setFlag(f);
    }
    update();
    EventBus.on("flagCacheUpdated", update);
    return () => {
      EventBus.off("flagCacheUpdated", update);
    };
  }, [name]);
  return flag;
}

export default flags;
