import EventBus from "eventBus";
import React from "react";
import Style, { StyleContext } from "../style";

interface IProps {
  screen: React.ReactNode;
}

export default function ThemedScreen({ screen }: IProps): React.ReactElement {
  const [style, setStyle] = React.useState(Style);

  const updateStyle = () => {
    setStyle(Style);
  };

  // Subscribe to color scheme changes. Note: the final [] param means only run once.
  // See https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect.
  React.useEffect(() => {
    EventBus.on("themeChange", updateStyle);
    const unhook = () => {
      EventBus.off("themeChange", updateStyle);
    };
    return unhook;
  }, []);

  return <StyleContext.Provider value={style}>{screen}</StyleContext.Provider>;
}
