import { IonIcon } from "@ionic/react";
import { checkmark } from "ionicons/icons";
import React from "react";

interface IPitchProps {
  description: string;
  reason?: string;
}
export default function Pitch(props: IPitchProps) {
  const { description, reason } = props;

  const points = description.split("\n").map((point) => point.replace(/^•/, "").trim());

  return (
    <>
      {reason && <div style={{ marginTop: 8 }}>{reason}</div>}
      <ul
        style={{
          marginTop: 12,
          marginLeft: 8,
          lineHeight: "24px",
          listStyleType: "none",
          listStyle: "none",
          padding: 0,
        }}
      >
        {points.map((item, idx) => (
          <li key={idx} style={{ display: "flex", alignItems: "center" }}>
            <IonIcon icon={checkmark} style={{ marginRight: 4, opacity: 1 }} color="success" />
            {item}
          </li>
        ))}
      </ul>
    </>
  );
}
