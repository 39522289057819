import { Photo } from "@capacitor/camera";
import { showPrompt } from "@components/prompt";
import { IonNote } from "@ionic/react";
import { getPhoto } from "@lib/camera";
import { logAction } from "analytics/action";
import { blobHash } from "blobs/lib";
import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import { fieldTypeMap } from "fields/fields";
import { IFieldImage } from "fields/types/image";
import Lib from "lib";
import L10n from "localization";
import React from "react";

export async function pickImage(): Promise<{ id: string; blob: Blob; image: Photo } | null> {
  try {
    const image = await getPhoto({ webUseInput: undefined });
    const base64 = image.base64String;
    if (!base64) {
      return null;
    }

    const mimeType = `image/${image.format}`;
    const blob = Lib.base64ToBlob(base64, mimeType, 512);
    const id = await blobHash(blob);
    return { id, blob, image };
  } catch (err) {
    if (err instanceof Error && err.message.startsWith("User denied access")) {
      showPrompt({
        prompt: L10n.localize((s) => s.errors.photoPermissionDenied),
        promptType: "alert",
        title: L10n.localize((s) => s.general.attention),
      });
      return null;
    }
    const cancelled = err instanceof Error && err.message === "User cancelled photos app";
    if (cancelled) {
      return null;
    }

    // Failed to pick photo.
    const msg = err instanceof Error ? err.message : "";
    logAction({ loc: "card_editor", verb: "pick", obj: "image", state: "fail", extra: { msg } });
  }

  return null;
}

export default function FieldValueEditorImage({
  field,
  name,
  value,
  onChange,
  registerBlob,
  editorRef,
  editable,
}: IFieldValueEditorProps<IFieldImage>): JSX.Element {
  async function handleClick() {
    const res = await pickImage();
    if (res) {
      const { id, blob, image } = res;
      await registerBlob(id, blob);
      const fml = fieldTypeMap.image.dumpFML({ id, type: image.format });
      onChange(name, fml);
    }
  }

  return (
    <FieldValueEditorItem
      name={name}
      field={field}
      button
      detail
      onClick={editable ? handleClick : undefined}
      editorRef={editorRef}
    >
      {editable ? <IonNote>{L10n.localize((s) => s.actions.choose)}</IonNote> : undefined}
    </FieldValueEditorItem>
  );
}
