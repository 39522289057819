import InlineLoadingSpinner from "@components/inlineLoadingSpinner";
import Globals from "globals";
import L10n from "localization";
import Network from "network";
import { QR } from "qr-svg";
import React from "react";
import Style from "style";
import PDPLogger from "unlimited/logger";
import { IProduct } from "unlimited/types";
import CTAButton from "./ctaButton";
import Product from "./product";
import UnlimitedBadge from "./unlimitedBadge";

interface IStripeCheckoutResponse {
  url: string;
}

export default function StripeQRDialog(props: {
  email: string;
  userID: string;
  product: IProduct;
  onComplete: () => void;
  onCancel: () => void;
  logger?: PDPLogger;
}) {
  const { email, userID, product, onComplete, onCancel, logger } = props;

  const [stripeCheckoutURL, setStripeCheckoutURL] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchSession = async () => {
      const checkoutData = {
        product_id: product.id,
        client_version: Globals.version,
        email,
        user_id: userID,
      };
      const response = await Network.fetch<IStripeCheckoutResponse>(
        "POST",
        "/users/purchases/stripe/checkout",
        checkoutData,
      );
      setStripeCheckoutURL(response.url);
    };
    fetchSession();
  }, [email, product.id, userID]);

  function handleQRClick() {
    logger?.log("clicked_stripe_QR_code", { product });
    if (stripeCheckoutURL) {
      window.open(stripeCheckoutURL);
    }
  }

  const px = 256;
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <UnlimitedBadge style={{ maxWidth: 300 }} />
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: 12 }}
      >
        <Product product={product} />
        <div style={{ marginLeft: 8 }}>
          (
          <a onClick={onCancel} style={{ cursor: "pointer" }}>
            {L10n.localize((s) => s.actions.change)}
          </a>
          )
        </div>
      </div>
      <div>{L10n.localize((s) => s.purchasing.scanOrClickToPay)}</div>
      <div
        style={{
          display: "flex",
          position: "relative",
          margin: 16,
          padding: 8,
          width: px + 24,
          height: px + 24,
          border: `4px dashed ${Style.colors.actionableFg}`,
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 8,
        }}
      >
        {!stripeCheckoutURL ? (
          <InlineLoadingSpinner />
        ) : (
          <div
            className="disabled"
            onClick={handleQRClick}
            style={{
              cursor: "pointer",
              display: "flex",
              width: px,
              height: px,
              position: "absolute",
              zIndex: 10,
            }}
          >
            <div
              style={{
                borderRadius: 4,
                backgroundColor: "white",
                height: px,
                width: px,
              }}
              dangerouslySetInnerHTML={{ __html: QR(stripeCheckoutURL) }}
            />
          </div>
        )}
      </div>
      <div style={{ marginTop: 12 }} />
      <CTAButton isTheaterMode onClick={onComplete}>
        {L10n.localize((s) => s.purchasing.clickWhenDone)}
      </CTAButton>
    </div>
  );
}
