import { IonLabel, IonSegment, IonSegmentButton, SegmentCustomEvent } from "@ionic/react";
import L10n from "localization";
import React from "react";

export type SearchMode = "cards" | "decks" | "public";

export default function SearchModeSegmentView({
  mode,
  setMode,
}: {
  mode: SearchMode;
  setMode: (m: SearchMode) => void;
}): JSX.Element {
  return (
    <div className="ion-padding-horizontal ion-padding-bottom">
      <IonSegment
        value={mode}
        onIonChange={(e: SegmentCustomEvent) => setMode(e.detail.value as SearchMode)}
      >
        <IonSegmentButton value="cards">
          <IonLabel>{L10n.localize((s) => s.card.plural)}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="decks">
          <IonLabel>{L10n.localize((s) => s.deck.mine)}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="public">
          <IonLabel>{L10n.localize((s) => s.search.publicDecks)}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
    </div>
  );
}
