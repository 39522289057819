import React from "react";
import { Products } from "./products";
import { IPDPContentProps } from "./pdpContent";

interface IProps extends IPDPContentProps {
  description: string;
}
export default function AndroidPDPContent({ userID, email, reason, description }: IProps) {
  return (
    <Products
      reason={reason}
      description={description}
      onPurchase={() => null}
      products={[]}
      gateway={undefined}
      userID={userID}
      email={email}
    />
  );
}
