import SideBar, { ISection } from "@components/sidebar";
import { IonContent, IonHeader, IonMenu, IonSplitPane, IonTitle, IonToolbar } from "@ionic/react";
import L10n from "localization";
import React from "react";
import { BodySection } from "../decks/types";

export default function SplitPane({
  screen,
  sections,
  activeSection,
  setActiveSection,
}: {
  screen: React.ReactElement;
  sections: ISection[];
  activeSection: string;
  setActiveSection: (section: BodySection) => void;
}) {
  const sectionSelector = (
    <SideBar
      sections={sections}
      activeSection={activeSection}
      setActiveSection={(s: BodySection) => setActiveSection(s)}
    />
  );

  return (
    <IonSplitPane contentId="main">
      <IonMenu contentId="main">
        <IonHeader>
          <IonToolbar>
            <IonTitle>{L10n.localize((s) => s.general.home)}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonHeader translucent collapse="condense">
            <IonToolbar>
              <IonTitle size="large">{L10n.localize((s) => s.general.home)}</IonTitle>
            </IonToolbar>
          </IonHeader>
          {sectionSelector}
        </IonContent>
      </IonMenu>

      {screen}
    </IonSplitPane>
  );
}
