import React from "react";
import { IField } from "fields/fields";
import { IonPage, IonToolbar, IonTitle, IonButtons, IonButton, IonContent } from "@ionic/react";
import L10n from "localization";
import RefFieldSelector from "./refFieldSelector";

interface IProps {
  refField: string | undefined;
  refFields: IField[];
  onSelect: (newRefField: string | undefined) => void;
  dismiss: () => void;
}

export default function RefFieldSelectorModal({
  onSelect,
  refField,
  refFields,
  dismiss,
}: IProps): JSX.Element {
  async function handleSave() {
    dismiss();
    onSelect(refField);
  }

  const saveDisabled = !refField;

  return (
    <IonPage>
      <IonToolbar>
        <IonTitle>{L10n.localize((s) => s.general.translation)}</IonTitle>
        <IonButtons slot="secondary">
          <IonButton onClick={dismiss}>{L10n.localize((s) => s.actions.cancel)}</IonButton>
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton onClick={handleSave} disabled={saveDisabled}>
            {L10n.localize((s) => s.actions.save)}
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <RefFieldSelector refField={refField} refFields={refFields} onSelect={onSelect} />
      </IonContent>
    </IonPage>
  );
}
