import React from "react";
import Style from "style";

interface IStatRowProps {
  label?: React.ReactNode;
  bottomBorder?: boolean;
  center?: boolean;
  children?: React.ReactNode;
}
export default function StatRow({
  label = null,
  center = false,
  bottomBorder = true,
  children,
}: IStatRowProps): React.ReactElement {
  const style: React.CSSProperties = {
    display: "flex",
    flex: "1 1 auto",
    flexWrap: "nowrap",
    borderBottom: bottomBorder ? Style.colors.barBorder : undefined,
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
    marginLeft: "1rem",
  };

  const labelStyle: React.CSSProperties = {
    display: "flex",
    flex: "1 1 auto",
    color: Style.colors.primaryFg,
    textAlign: "left" as const,
    alignSelf: "center",
  };

  const statStyle: React.CSSProperties = {
    display: "flex",
    flex: "1 1 auto",
    color: Style.colors.secondaryFg,
    textAlign: "right" as const,
    marginRight: "0.5rem",
    alignItems: "center",
    justifyContent: center ? "center" : "flex-end",
  };

  return (
    <div style={style}>
      <div style={labelStyle}>{label}</div>
      <div style={statStyle}>{children}</div>
    </div>
  );
}
