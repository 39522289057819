import { IonButton, IonIcon } from "@ionic/react";
import { IDeck } from "@models/deck";
import { ILayout } from "@models/layout";
import CardPreview from "cardRendering/cardPreview";
import Globals from "globals";
import { useIsPhone } from "hooks/util/useMediaQuery";
import { closeCircleOutline } from "ionicons/icons";
import Lib from "lib";
import L10n from "localization";
import React from "react";
import Style from "style";
import EditLayoutFields from "./layoutEditor/editLayoutFields";
import EditLayoutInfo from "./layoutEditor/editLayoutInfo";
import EditLayoutStyle from "./layoutEditor/editLayoutStyle";
import EditLayoutTemplates from "./layoutEditor/editLayoutTemplates";
import PillBox from "./pillBox";
import VerticallySplitLayout from "./verticallySplitLayout";

interface IProps {
  editable?: boolean;
  isCreating?: boolean;
  fields?: string[];
  name: string;
  templates: string[];
  css?: string;
  onNameChange: (name: string) => void;
  onFieldsChange: (fields: string[]) => void;
  onTemplatesChange: (templates: string[]) => void;
  onCssChange: (css: string) => void;
  onDelete?: () => void;
  deck?: IDeck;
}
export default function EditLayout(props: IProps): JSX.Element {
  const [mode, setMode] = React.useState("info");
  const isPhone = useIsPhone();

  const isDeletable = props.editable && !props.isCreating;
  const values: Record<string, string> = {};
  if (props.fields) {
    for (const field of props.fields) {
      values[field] = field;
    }
  }

  const deckLayout: ILayout = {
    id: props.deck?.layout_id || "",
    name: props.name,
    templates: props.templates,
    style: props.css,
    status: props.deck?.status || 0,
    response_type_id: Globals.basicResponseType.id,
  };

  const deck = {
    ...props.deck,
    id: props.deck?.id ?? Lib.uuid16(),
    name: props.deck?.name ?? "",
    layouts: [deckLayout],
    layout_id: deckLayout.id,
    modified_at: props.deck?.modified_at ?? new Date(),
    created_at: props.deck?.created_at ?? new Date(),
  };

  const preview = (
    <div style={{ padding: 8, display: "flex", flexDirection: "column", height: "90%", gap: 16 }}>
      <CardPreview
        deck={deck}
        knol={undefined}
        stack
        isClickable={false}
        unfold
        cardHeight={"50%"}
        cardWidth={"100%"}
        editingValues={values}
      />
    </div>
  );
  const info = (
    <>
      <EditLayoutInfo
        name={props.name}
        editable={props.editable}
        onNameChange={props.onNameChange}
      />
      <EditLayoutFields
        fields={props.fields}
        editable={props.editable}
        onFieldsChange={props.onFieldsChange}
      />
      <EditLayoutTemplates
        templates={props.templates}
        editable={props.editable}
        onTemplatesChange={props.onTemplatesChange}
      />
      <EditLayoutStyle css={props.css} editable={props.editable} onCssChange={props.onCssChange} />
      {isDeletable && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <IonButton
            size="small"
            style={{ padding: "16px 0px" }}
            fill="outline"
            onClick={props.onDelete}
            color="danger"
          >
            <IonIcon slot="start" icon={closeCircleOutline} />
            {L10n.localize((s) => s.actions.delete)}
          </IonButton>
        </div>
      )}
    </>
  );
  if (!isPhone) {
    return <VerticallySplitLayout left={info} right={preview} />;
  } else {
    return (
      <>
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1000,
            backgroundColor: Style.colors.secondaryBg,
          }}
        >
          <PillBox
            pills={[L10n.localize((s) => s.general.info), L10n.localize((s) => s.general.preview)]}
            activeIndex={mode === "info" ? 0 : 1}
            onPillClick={(index) => {
              setMode(index === 0 ? "info" : "preview");
            }}
          />
        </div>
        {mode === "info" ? info : preview}
      </>
    );
  }
}
