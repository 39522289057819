import { Sha256 } from "@aws-crypto/sha256-browser";

export async function blobHash(blob: Blob): Promise<string> {
  const buf = await blob.arrayBuffer();

  const hasher = new Sha256();
  hasher.update(buf);

  const hash = await hasher.digest();
  const arr = Array.from(new Uint8Array(hash));
  const hex = arr.map((byte) => byte.toString(16).padStart(2, "0")).join("");
  return hex;
}
