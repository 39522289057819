import idb from "@data/idb";
import { IonList, IonListHeader } from "@ionic/react";
import { DeckSetting, IDeckSettings } from "@models/deckSettings";
import FontSizeSettingItem from "@screens/deck/fontSizeSettingItem";
import OrientationSelectorItem from "@screens/deck/orientationSelectorItem";
import EventBus from "eventBus";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import React, { useCallback } from "react";
import CardsPerSessionSettingItem from "../deck/cardsPerSessionSettingItem";
import FlipStabilizationSelectorItem from "../deck/flipStabilizationSelectorItem";
import ReviewModeSettingItem from "../deck/reviewModeSettingItem";
import TimerSettingItem from "../deck/timerSettingItem";

async function setFolderSetting<T extends DeckSetting>(
  folder: string,
  setting: T,
  value: IDeckSettings[T],
) {
  const tx = idb.db.transaction(["folders"], "readwrite");
  const store = tx.objectStore("folders");

  const row = await store.get(folder);
  if (!row) {
    store.add({ name: folder });
  }

  const settings: Partial<IDeckSettings> = row?.settings ?? {};
  settings[setting] = value;
  await idb.update(idb.db, "folders", folder, { settings });

  EventBus.emit("folderUpdated", { name: folder });

  await tx.done;
}

function useFolderSettingChangeHandler<T extends DeckSetting>(
  folder: string,
  setting: T,
  handler: (err: Error) => void,
) {
  return useCallback(
    async (value: IDeckSettings[T]) => {
      setFolderSetting(folder, setting, value).catch(handler);
    },
    [folder, setting, handler],
  );
}

interface IProps {
  folder: string;
  numTotalCards: number;
  allDecksInFolderAreModern?: boolean;
  settings: IDeckSettings | undefined;
}
export default function SettingsSection(props: IProps): JSX.Element {
  const { folder, numTotalCards, allDecksInFolderAreModern, settings } = props;

  const [handler] = useErrorAlert({ code: "CHANGING_SETTINGS" });
  const handleReviewModeChange = useFolderSettingChangeHandler(folder, "reviewMode", handler);
  const handleTimerSettingsChanged = useFolderSettingChangeHandler(folder, "reviewTimer", handler);
  const handleCardFontSizeChanged = useFolderSettingChangeHandler(folder, "cardFontSize", handler);
  const handleCardsPerReviewChange = useFolderSettingChangeHandler(
    folder,
    "cardsPerReview",
    handler,
  );
  const handleCardOrientationSelected = useFolderSettingChangeHandler(
    folder,
    "cardOrientation",
    handler,
  );
  const handleFlipStabilizationSelected = useFolderSettingChangeHandler(
    folder,
    "flipStabilization",
    handler,
  );

  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.general.settings)}</IonListHeader>
      {settings !== undefined ? (
        <>
          <CardsPerSessionSettingItem
            numTotalCards={numTotalCards}
            cardsPerReview={settings.cardsPerReview}
            onChange={handleCardsPerReviewChange}
          />
          <FontSizeSettingItem
            fontSizePx={settings.cardFontSize}
            onChange={handleCardFontSizeChanged}
          />
          <TimerSettingItem
            timerSettings={settings.reviewTimer}
            onChange={handleTimerSettingsChanged}
          />
          <ReviewModeSettingItem
            reviewMode={settings.reviewMode}
            cardsPerReview={settings.cardsPerReview}
            setReviewMode={handleReviewModeChange}
          />
          {allDecksInFolderAreModern ? (
            <>
              <OrientationSelectorItem
                orientation={settings.cardOrientation}
                onChange={handleCardOrientationSelected}
              />
              <FlipStabilizationSelectorItem
                setting={settings.flipStabilization}
                onChange={handleFlipStabilizationSelected}
              />
            </>
          ) : undefined}
        </>
      ) : undefined}
    </IonList>
  );
}
