import { isMacAppStoreElectron } from "globals";

interface IWindow extends Window {
  electronDev: {
    showTools: () => void;
  };
}

declare let window: IWindow;

export function showElectronDevTools() {
  if (isMacAppStoreElectron()) {
    window.electronDev?.showTools();
  }
}
