import { User } from "@models/user";
import Globals from "globals";
import { getGlobalTotalReviews } from "hooks/data/responseHistory";

declare let NativeNotifications: any;

export const defaultStudyGoal = 10;
export const studyGoalMax = 10000;

const localStorageKey = "AnkiApp.settings.studyGoal";

export function getStudyGoal(): number {
  return Number.parseInt(localStorage[localStorageKey], 10) || defaultStudyGoal;
}

function setBadgeCount(count: number): Promise<number> {
  const goal = getStudyGoal();

  return new Promise((resolve, reject) => {
    NativeNotifications.setBadgeCount(count, goal, resolve, reject);
  });
}

async function remainingReviews(): Promise<number> {
  const goal = getStudyGoal();

  const count = await getGlobalTotalReviews();
  if (count >= goal) {
    return 0;
  }

  return goal - count;
}

export async function updateStudyGoalBadgeCount(): Promise<void> {
  if (Globals.platform !== "ios") {
    return;
  }

  try {
    if (User.isLoggedIn()) {
      const remaining = await remainingReviews();
      await setBadgeCount(remaining);
    } else {
      await setBadgeCount(0);
    }
  } catch (err) {
    // Too bad.
  }
}
