import { IonTextarea, TextareaCustomEvent } from "@ionic/react";
import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import useAutoFocusIonTextarea from "fields/hooks/useAutoFocusIonTextarea";
import { IFieldCode } from "fields/types/code";
import { IFieldTeX } from "fields/types/tex";
import L10n from "localization";
import React, { useRef } from "react";

export default function FieldValueEditorCode({
  field,
  name,
  value,
  onChange,
  editorRef,
  autoFocus,
  editable,
}: IFieldValueEditorProps<IFieldCode | IFieldTeX>): JSX.Element {
  const ref = useRef<HTMLIonTextareaElement>(null);
  useAutoFocusIonTextarea(autoFocus, ref.current);

  return (
    <FieldValueEditorItem name={name} field={field} editorRef={editorRef}>
      <IonTextarea
        ref={ref}
        value={value}
        readonly={!editable}
        autoGrow
        spellCheck
        rows={1}
        style={{ fontFamily: "monospace" }}
        placeholder={L10n.localize((s) => s.general.clickToEdit)}
        onIonInput={(evt: TextareaCustomEvent) => {
          if (!editable) {
            return;
          }
          const val = evt.target.value;
          if (val !== null && val !== undefined) {
            onChange(name, val);
          }
        }}
      />
    </FieldValueEditorItem>
  );
}
