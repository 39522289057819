import Disconnected from "@components/disconnected";
import Empty from "@components/empty";
import { IonList, IonProgressBar } from "@ionic/react";
import L10n from "localization";
import React from "react";
import { useEffect, useState } from "react";
import Categories from "./categories";
import DeckResult from "./deckResult";
import { ISearchResultSourceSansDecks, useSearchResults } from "./useSearchResults";

interface IProps {
  query: string;
  setQuery: (q: string) => void;
}

export default function PublicDecksSearchView({ query, setQuery }: IProps): JSX.Element {
  const [activeSources, setActiveSources] = useState<Record<string, boolean>>({});

  const results = useSearchResults(query);

  const sourceMap: Record<string, ISearchResultSourceSansDecks> = {};
  for (const source of results.val?.sources ?? []) {
    sourceMap[source.name] = source;
  }

  useEffect(() => {
    const activeSourceMap: Record<string, boolean> = {};
    for (const source of results.val?.sources ?? []) {
      activeSourceMap[source.name] = true;
    }
    setActiveSources(activeSourceMap);
  }, [results.val?.sources]);

  const filteredDecks =
    results.val?.decks.filter((deck) => {
      const source = deck.source;
      if (!source) {
        return true;
      }
      return activeSources[source];
    }) ?? [];

  const deckResultEls = filteredDecks.map((deck) => {
    return <DeckResult key={deck.id} deck={deck} sources={sourceMap} query={query} />;
  });

  const noQuery = query === "";
  const { loading, error } = results;

  const noDecks = !loading && filteredDecks.length < 1;

  if (error) {
    return <Disconnected />;
  }
  if (noQuery) {
    return <Categories search={setQuery} />;
  }
  return (
    <>
      {loading ? (
        <IonProgressBar type="indeterminate" />
      ) : noDecks ? (
        <Empty text={noQuery ? L10n.localize((s) => s.general.enterSearchQuery) : undefined} />
      ) : (
        <IonList>{deckResultEls}</IonList>
      )}
    </>
  );
}
