import { IonLoading } from "@ionic/react";
import EventBus, { IProgressEventParams, ProgressEvent } from "eventBus";
import L10n from "localization";
import React, { useEffect, useState } from "react";

interface IProps {
  message?: string;
  eventName: ProgressEvent;
  delayMillis?: number; // Prevent display for a little bit to avoid flashing when loading is very quick.
}
export default function LoadingIndicator({
  eventName,
  message = L10n.localize((s) => s.general.dataLoadingMessage),
  delayMillis = 250,
}: IProps): JSX.Element {
  const [delayIsOver, setDelayIsOver] = useState(delayMillis ? false : true);
  useEffect(() => {
    if (!delayMillis) {
      return;
    }
    function show() {
      setDelayIsOver(true);
    }
    const handle = setTimeout(show, delayMillis);
    return () => {
      clearTimeout(handle);
    };
  }, [delayMillis]);

  const ref = React.useRef<HTMLIonLoadingElement>(null);

  useEffect(() => {
    async function update({ numerator, denominator }: IProgressEventParams) {
      const content = ref.current?.querySelector(".loading-content");
      if (content) {
        const formatter = new Intl.NumberFormat(L10n.currentLocale);
        const num = formatter.format(numerator);
        if (numerator && denominator) {
          const denom = formatter.format(denominator);
          content.innerHTML = `${message} (${num}/${denom})`;
        } else if (numerator) {
          content.innerHTML = `${message} (${num})`;
        } else {
          content.innerHTML = message;
        }
      }
    }

    EventBus.on(eventName, update);
    return () => {
      EventBus.off(eventName, update);
    };
  }, [ref, message, eventName]);

  const show = delayIsOver;
  return <IonLoading ref={ref} isOpen={show} message={message} />;
}
