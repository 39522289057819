import { IKnol } from "@models/knol";
import drawAroundGrade from "./drawAroundGrade";
import { highestPriorityGrade } from "./highestPriorityGrade";

export default function draw(cards: IKnol[], limit: number): string[] {
  const grade = highestPriorityGrade(cards);
  if (grade === null) {
    return [];
  }
  return drawAroundGrade(cards, grade, limit);
}
