import strings from "./strings/strings";
import funcs from "./strings/funcs";

export const locales = ["de", "en", "es", "fr", "ja", "pt-br", "zh-cn", "zh-tw"] as const;
export type Locales = (typeof locales)[number];

export type L10nStringFunc = (...args: string[]) => string;
export type L10nNumberFunc = (n: number) => string;
export type L10nFun = L10nStringFunc | L10nNumberFunc;
export type L10nValue = string | L10nFun;

interface IEnglishOnlyL10nLeaf {
  en: L10nValue;
}

export const isL10nNumberFunc = (t: L10nFun): t is L10nNumberFunc => {
  if (typeof t === "number") {
    return true;
  }
  return false;
};

// NOTE: this must be updated if you add/remove a locale.
export interface ICompleteL10nLeaf<T = L10nValue> {
  de: T;
  en: T;
  es: T;
  fr: T;
  ja: T;
  "pt-br": T;
  "zh-cn": T;
  "zh-tw": T;
}

export interface IL10nLeaf<T = L10nValue> {
  de?: T;
  en: T;
  es?: T;
  fr?: T;
  ja?: T;
  "pt-br"?: T;
  "zh-cn"?: T;
  "zh-tw"?: T;
}

// This union allows you to define a leaf with only an English translation.
export type L10nLeaf = IEnglishOnlyL10nLeaf | ICompleteL10nLeaf; //Record<"en", L10nValue> | Record<Locales, L10nValue>;

export type LocaleTree = Record<string, Record<string, L10nLeaf>>;

// export const strings: Record<string, Record<string, L10nLeaf>> = {
export const combinedStrings = {
  ...strings,
  funcs,
} as const;
