import { IReviewGateConfig, ReviewGateLevel } from "./reviewGate";

// NOTE: this is in a separate file from the rest of reviewgate to make unit testing easier.

export interface IReviewGateInputs {
  numReviewsToday: number;
  numReviewsTotal: number;
  userCreatedAt?: string;
}

export function thresholdsMet({
  dailyThreshold,
  totalThreshold,
  numToday,
  numTotal,
}: {
  dailyThreshold: number | undefined;
  totalThreshold: number | undefined;
  numToday: number;
  numTotal: number;
}): boolean {
  // If neither threshold is defined, don't warn,
  if (dailyThreshold === undefined && totalThreshold === undefined) {
    return false;
  }

  // With at least one threshold active, treat a threshold as passing if it's not set.
  const totalMet = totalThreshold === undefined || numTotal >= totalThreshold;
  const dailyMet = dailyThreshold === undefined || numToday >= dailyThreshold;

  return totalMet && dailyMet;
}

export default function evalReviewGateConditions(
  thresholds: IReviewGateConfig,
  inputs: IReviewGateInputs,
): ReviewGateLevel | null {
  const { user_created_at_threshold } = thresholds;
  const { userCreatedAt } = inputs;

  if (user_created_at_threshold && userCreatedAt && userCreatedAt < user_created_at_threshold) {
    return null;
  }

  const warn = thresholdsMet({
    dailyThreshold: thresholds.num_daily_reviews_warn_threshold,
    totalThreshold: thresholds.num_total_reviews_warn_threshold,
    numToday: inputs.numReviewsToday,
    numTotal: inputs.numReviewsTotal,
  });
  const gate =
    thresholds.num_daily_reviews_gate_threshold &&
    inputs.numReviewsToday >= thresholds.num_daily_reviews_gate_threshold;

  if (gate && warn) {
    return "gate";
  }
  if (warn) {
    return "warn";
  }

  return null;
}
