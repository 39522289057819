import React, { useState } from "react";
import L10n from "localization";
import {
  IonContent,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonPage,
  IonList,
  IonLabel,
  IonNote,
  IonItem,
  IonNav,
} from "@ionic/react";
import { IField } from "fields/fields";
import RefFieldSelectorItem from "./refFieldSelectorItem";
import { SourcePipelineNode } from "../sources/types";
import { isValidTranslationSourceField } from "fields/sources/translation";

interface IProps {
  dismiss: () => void;
  fields: IField[];
  field: IField;
  onSelect: (name: string | undefined) => void;
  targetLang?: string;
  src: SourcePipelineNode;
}
function TranslationSourceRoot(props: IProps): JSX.Element {
  const { dismiss, fields, field, onSelect, targetLang, src } = props;

  async function handleSave() {
    dismiss();
    onSelect(refField);
  }

  let initRefField: string | undefined = undefined;
  if (src?.type === "translation") {
    if (src.source?.type === "ref") {
      initRefField = src.source.name;
    }
  }

  const [refField, setRefField] = useState<string | undefined>(initRefField);
  function handleRefFieldSelect(name: string | undefined) {
    setRefField(name);
  }

  const saveDisabled = !refField || refField === initRefField;

  const disabledSourceFields = fields
    .filter((f) => !isValidTranslationSourceField(f))
    .map((f) => f.name);

  return (
    <IonPage>
      <IonToolbar>
        <IonTitle>{L10n.localize((s) => s.general.translation)}</IonTitle>
        <IonButtons slot="secondary">
          <IonButton onClick={dismiss}>{L10n.localize((s) => s.actions.cancel)}</IonButton>
        </IonButtons>
        <IonButtons slot="primary">
          <IonButton onClick={handleSave} disabled={saveDisabled}>
            {L10n.localize((s) => s.actions.save)}
          </IonButton>
        </IonButtons>
      </IonToolbar>
      <IonContent>
        <IonList>
          <IonItem>
            <IonLabel>
              <h2>{L10n.localize((s) => s.general.language)}</h2>
            </IonLabel>
            <IonNote slot="end">{targetLang ?? L10n.localize((s) => s.general.none)}</IonNote>
          </IonItem>
          <RefFieldSelectorItem
            fields={fields}
            field={field}
            onSelect={handleRefFieldSelect}
            initRefField={refField}
            required
            disabledFields={disabledSourceFields}
          />
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default function TranslationSourceModal(props: IProps): JSX.Element {
  return <IonNav root={() => <TranslationSourceRoot {...props} />} />;
}
