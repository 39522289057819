import { useNamePrompt } from "@components/namePromptModal";
import { User } from "@models/user";
import { IGroupMetadata } from "@screens/studyGroups/types";
import logEvent from "analytics";
import { getName } from "data/stores/name";
import EventBus from "eventBus";
import L10n from "localization";
import Network from "network";
import React from "react";
import { getSessionStorage, removeSessionStorage } from "storage";
import useDismissibleToast from "./useDismissibleToast";

export const groupJoinTokenStorageKey = "group_join_token";
export const groupLinkJoinAnalyticsKey = "group_link_join";

function useGroupJoinToken(): string | undefined {
  const [token, setToken] = React.useState<string>();

  React.useEffect(() => {
    function loadToken() {
      getSessionStorage(groupJoinTokenStorageKey).then(setToken);
    }

    loadToken();

    EventBus.on("groupJoinTokenSet", loadToken);
    return () => {
      EventBus.off("groupJoinTokenSet", loadToken);
    };
  }, []);

  return token;
}

export function useGroupJoinRequest(): () => void {
  const [present, dismiss] = useDismissibleToast();
  const token = useGroupJoinToken();

  React.useEffect(() => {
    if (!token) {
      return;
    }

    Network.fetch<IGroupMetadata>("GET", "/users/groups", {
      share_token: token,
    })
      .then((meta) => {
        present({
          message: L10n.localize((s) => s.funcs.groups.joinRequestPreAuthMessage)(meta.name),
          position: "top",
        });
        logEvent(groupLinkJoinAnalyticsKey, {
          step: "pre_auth_message_shown",
          token,
        });
      })
      .catch(() => {
        present({
          message: L10n.localize((s) => s.groups.joinRequestTokenInvalid),
          color: "warning",
          position: "top",
        });
        logEvent(groupLinkJoinAnalyticsKey, {
          step: "pre_auth_invalid_token_message_shown",
          token,
        });
      });
  }, [present, token, dismiss]);

  return dismiss;
}

export function useStoredGroupJoinRequest(source: "decks_screen" | "unsupported_screen"): void {
  const [presentToast] = useDismissibleToast();
  const showNamePromptModal = useNamePrompt({ canCancel: false });
  const token = useGroupJoinToken();

  React.useEffect(() => {
    if (!User.isLoggedIn() || !token) {
      return;
    }

    const aborter = new AbortController();

    async function sendJoinRequest() {
      function showSuccessToast(groupName: string) {
        presentToast({
          message: L10n.localize((s) => s.funcs.groups.joinRequestSent)(groupName),
          position: "top",
          duration: 5000,
          color: "success",
        });
      }

      function showFailToast(groupName: string) {
        presentToast({
          message: L10n.localize((s) => s.funcs.groups.joinRequestFailed)(groupName),
          position: "top",
          duration: 5000,
          color: "warning",
        });
      }

      if (!User.isLoggedIn() || !token) {
        return;
      }

      const name = await getName();
      if (!name?.first) {
        logEvent(groupLinkJoinAnalyticsKey, {
          step: "join_aborted_due_to_missing_user_name",
          source,
          token,
        });
        return;
      }

      async function sendRequest(token: string): Promise<void> {
        const meta = await Network.fetch<IGroupMetadata | null>(
          "GET",
          "/users/groups",
          {
            share_token: token,
          },
          aborter,
        ).catch((error) => {
          logEvent(groupLinkJoinAnalyticsKey, {
            step: "metadata_fetch_failed",
            token,
            error,
            source,
          });
        });
        if (!meta) {
          // Request aborted.
          return;
        }
        if (!meta.id) {
          showFailToast(meta.name);
          logEvent(groupLinkJoinAnalyticsKey, {
            step: "metadata_fetch_invalid_response",
            token,
            source,
          });
          return;
        }

        try {
          await Network.fetch("POST", `/users/groups/${meta.id}/join_requests`, { token }, aborter);
          showSuccessToast(meta.name);
          logEvent(groupLinkJoinAnalyticsKey, {
            step: "join_request_sent",
            token,
            source,
          });
        } catch {
          showFailToast(meta.name);
          logEvent(groupLinkJoinAnalyticsKey, {
            step: "join_request_failed",
            token,
            source,
          });
        }
      }

      sendRequest(token).finally(() => {
        removeSessionStorage(groupJoinTokenStorageKey).then(() => {
          EventBus.emit("groupJoinTokenSet");
          logEvent(groupLinkJoinAnalyticsKey, {
            step: "token_cleared",
            token,
            source,
          });
        });
      });
    }

    getName().then((name) => {
      const nameNotSet = name.first === undefined;
      if (nameNotSet) {
        showNamePromptModal({
          onDismiss: sendJoinRequest,
          canDismiss: async () => {
            const n = await getName();
            return n.first !== undefined;
          },
        });
        logEvent(groupLinkJoinAnalyticsKey, {
          step: "name_prompt_shown",
          token,
          source,
        });
      } else {
        sendJoinRequest();
      }
    });

    return () => {
      aborter.abort();
    };
  }, [presentToast, showNamePromptModal, source, token]);
}
