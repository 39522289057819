import { useEffect } from "react";

export default function useSetLangIonTextarea(
  lang: string | undefined,
  textarea: React.RefObject<HTMLIonTextareaElement>,
) {
  useEffect(() => {
    if (lang && textarea.current) {
      textarea.current.getInputElement().then((textarea) => {
        textarea.lang = lang;
      });
    }
  }, [lang, textarea]);
}
