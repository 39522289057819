import { SIDES } from "fields/sides";
import L10n from "localization";
import { fieldNames } from "./fieldNames";
import { IMarketItem } from "./types";

export default function standard(): IMarketItem {
  return {
    base: "simple",
    name: L10n.localize((s) => s.preInstalledLayouts.simple),
    fields: [
      {
        name: fieldNames.Front(),
        type: "text",
        sides: SIDES.BOTH,
      },
      {
        name: fieldNames.Back(),
        type: "text",
        sides: SIDES.BACK,
      },
    ],
    description: L10n.localize((s) => s.layout.defaultDescription),
  };
}
