import { IonList, IonProgressBar } from "@ionic/react";
import React from "react";
import Category, { IBrowseCategory } from "./category";
import usePublicDecksData from "./usePublicDecksData";

export default function Categories({ search }: { search: (query: string) => void }): JSX.Element {
  const [categories] = usePublicDecksData();

  const loading = categories === null;
  if (loading) {
    return <IonProgressBar type="indeterminate" />;
  }

  return (
    <IonList style={{ width: "100%" }}>
      {categories?.map((cat: IBrowseCategory) => (
        <Category key={cat.name} cat={cat} search={search} />
      ))}
    </IonList>
  );
}
