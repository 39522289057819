import { fieldTypeMap, IField } from "fields/fields";
import React from "react";
import { IFieldValueProps } from "fields/fieldType";

export default function FieldValue(props: IFieldValueProps<IField>): JSX.Element | null {
  switch (props.field.type) {
    case "audio": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "chinese": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "code": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "image": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "japanese": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "richtext": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "tex": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "text": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
    case "tts": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueComponent;
      return React.createElement(component, { ...props, field });
    }
  }
}
