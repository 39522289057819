import L10n from "localization";
import React from "react";
import Style from "style";
import { IProduct } from "unlimited/types";
import Price from "./price";

export default function Product<T extends IProduct>(props: { product: T }) {
  const { product } = props;

  const buttonName = product.name.split(/[:：]/)[1]?.trim() ?? product.name;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        margin: "12px 0",
      }}
    >
      <div
        style={{
          flex: "1 0 auto",
          textAlign: "left",
          alignSelf: "center",
          textTransform: "none",
          color: Style.colors.primaryFg,
        }}
      >
        <div style={{ fontSize: 18, fontWeight: 500 }}>{buttonName}</div>
        <div
          style={{
            flex: "1 0 auto",
            textAlign: "left",
            fontSize: 16,
            fontWeight: 300,
            marginTop: 4,
          }}
        >
          <Price displayPrice={product.display_price} frequency={product.frequency} />
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center", fontSize: 22 }}>
        {L10n.localize((s) => s.actions.upgrade)}
      </div>
    </div>
  );
}
