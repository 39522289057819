import logEvent from "analytics";
import { userLocale } from "localization";
import { ID } from "types/ID";
import { genTraceID } from "./action";

type SearchEventStep =
  | "received_categories"
  | "received_results"
  | "clicked_category"
  | "downloaded_result"
  | "clicked_result";

let traceID: ID;
export function logSearchEvent(step: SearchEventStep, extra: object) {
  if (step === "received_results") {
    traceID = genTraceID();
  }
  logEvent("search_event", { ...extra, step, trace_id: traceID, locale: userLocale() });
}
