import { User } from "@models/user";
import { ID } from "types/ID";
import logEvent from "analytics";
import Lib from "lib";

type State =
  | "init" // entered funnel
  | "step" // progressed through funnel
  | "done" // success
  | "quit" // aborted
  | "fail"; // failure

interface IBaseAction {
  verb: string; // what was done
  obj: string; // “picture” (who/what did they do it to)
  state: State;
  loc?: string; // where did it happen
  trace_id?: ID;
  extra?: unknown;
}

interface IImplicitActionLogData extends IBaseAction {
  subj?: string; //“user” (who did it, typically “user”, maybe it’s a bot)
  step?: string; // Funnel step label.
  subj_id?: ID; // 42 (which user did it)
  obj_id?: ID; // 1337 (which specific thing did they do it to, if applicable)
}

interface IActionLogData extends IImplicitActionLogData {
  subj: string;
}

export function genTraceID(): ID {
  return Lib.uuid16();
}

function nameAction(action: IActionLogData): string {
  const { subj, verb, obj, state } = action;
  const name = `${subj}_${verb}_${obj}`;
  switch (state) {
    case "init":
      return `${name}_begun`;
    case "fail":
      return `${name}_failed`;
    case "quit":
      return `${name}_aborted`;
    default:
      return name;
  }
}

export async function logAction(action: IImplicitActionLogData) {
  const { verb, subj = "user", subj_id = User.id(), obj, state, extra, ...rest } = action;

  const data: IActionLogData = {
    verb,
    subj,
    subj_id,
    obj,
    state,
    extra,
    ...rest,
  };

  const name = nameAction(data);
  logEvent(name, data as any);
}
