import CodeEditor from "@components/codeEditor";
import { IonItem, IonList, IonListHeader } from "@ionic/react";
import L10n from "localization";
import React from "react";

interface IProps {
  editable?: boolean;
  css?: string;
  onCssChange: (css: string) => void;
}

export default function EditLayoutStyle({
  editable = false,
  css = "",
  onCssChange = () => {
    return;
  },
}: IProps): JSX.Element {
  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.general.style)}</IonListHeader>
      <IonItem>
        <CodeEditor
          lineNumbers={true}
          mode="css"
          onChange={onCssChange}
          readOnly={!editable}
          theme="solarized"
          value={css}
        />
      </IonItem>
    </IonList>
  );
}
