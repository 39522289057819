import React from "react";
import L10n from "localization";
import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";
import { checkmarkOutline } from "ionicons/icons";
import { IField } from "fields/fields";
import FieldListItem from "fields/components/fieldListItem";

interface IProps {
  refField: string | undefined;
  refFields: IField[];
  onSelect: (newRefField: string | undefined) => void;
  disabledFields?: string[];
}
export default function RefFieldSelector({
  refField,
  refFields,
  onSelect,
  disabledFields,
}: IProps) {
  const defaultRefField = undefined;
  const defaultSelected = refField === defaultRefField;

  return (
    <IonList>
      <IonItem button detail={false} onClick={() => onSelect(defaultRefField)}>
        <IonLabel>
          <h2>{L10n.localize((s) => s.general.none)}</h2>
        </IonLabel>
        {defaultSelected ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
      </IonItem>
      {refFields.map((field) => {
        const { name } = field;
        const selected = name === refField;
        return (
          <FieldListItem
            key={name}
            field={field}
            onClick={() => onSelect(name)}
            selected={selected}
            detail={false}
            disabled={disabledFields?.includes(name)}
          />
        );
      })}
    </IonList>
  );
}
