import TextFieldLangSelector from "fields/components/textFieldLangSelector";
import { IFieldEditorProps } from "fields/fieldType";
import { IBaseTextField } from "fields/lib";
import React from "react";
import { defaultCardFontSize } from "settings";
import FieldEditorGeneric from "./fieldEditorGeneric";
import FontSizeSelectorItem from "./fontSizeSelectorItem";

export default function FieldEditFormattedText({
  fields,
  field,
  onChange,
  creatingConfig,
}: IFieldEditorProps<IBaseTextField>): JSX.Element {
  const fontSizePx = field.attributes?.fontSizePx ?? defaultCardFontSize;
  function handleFontSizeChange(px: number) {
    onChange({
      ...field,
      attributes: {
        ...field.attributes,
        fontSizePx: px,
      },
    });
  }

  return (
    <FieldEditorGeneric
      fields={fields}
      field={field}
      onChange={onChange}
      creatingConfig={creatingConfig}
    >
      <TextFieldLangSelector field={field} onChange={onChange} langCode={field.attributes?.lang} />
      <FontSizeSelectorItem fontSizePx={fontSizePx} onChange={handleFontSizeChange} />
    </FieldEditorGeneric>
  );
}
