import Globals, { TResponseValue } from "globals";
import React from "react";
import { ScreenMode } from "./useSessionReducer";

export default function useKeyboardShortcutHandler(
  promptIsShowing: boolean,
  modalIsShowingRef: React.RefObject<boolean>,
  activeSideNum: 0 | 1,
  handleFlip: () => void,
  handleResponse: (response: TResponseValue) => void,
  screenMode: ScreenMode,
): void {
  React.useEffect(() => {
    let _isMounted = true;

    function handleKeyPress(e: KeyboardEvent) {
      if (promptIsShowing || !_isMounted || modalIsShowingRef.current || screenMode !== "review") {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      const responses = {
        "1": Globals.RESPONSES.FAIL,
        "2": Globals.RESPONSES.HARD,
        "3": Globals.RESPONSES.GOOD,
        "4": Globals.RESPONSES.EASY,
      };

      switch (e.key) {
        case " ":
          if (activeSideNum === 0) {
            return handleFlip();
          }
          break;
        case "1":
        case "2":
        case "3":
        case "4":
          if (activeSideNum === 1) {
            return handleResponse(responses[e.key]);
          }
          break;
      }
    }

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      _isMounted = false;
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [activeSideNum, promptIsShowing, handleFlip, handleResponse, screenMode, modalIsShowingRef]);
}
