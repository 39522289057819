import FieldEditorGeneric from "fields/editors/fieldEditorGeneric";
import { IBaseField } from "fields/lib";
import { IFieldType } from "fields/fieldType";
import { parseFML, serializeFML } from "fields/fml";
import FieldValueEditorImage from "fields/valueEditors/fieldValueEditorImage";
import ImageValue from "fields/values/imageValue";
import L10n from "localization";
import { RxImage } from "react-icons/rx";
import { parseSides } from "fields/sides";

export const sampleImageID = "$$SAMPLE$$";
export const sampleImageType = "image/png";
const sampleImageFilename = "Gray739-emphasizing-hippocampus.png";
export const sampleImageURL = `/${sampleImageFilename}`;

export const imageFieldType: IFieldType<"image"> = {
  name: () => L10n.localize((s) => s.field.typeImage),
  description: () => L10n.localize((s) => s.field.typeImageDescription),
  icon: RxImage,
  sampleValue() {
    return this.dumpFML({ id: sampleImageID, type: sampleImageType });
  },
  fmlTag: "img",
  type: "image",
  parseField(attrs, srcs): IFieldImage {
    return {
      name: attrs["name"],
      type: this.type,
      sides: parseSides(attrs["sides"]),
      source: srcs[0],
    };
  },

  loadFML(fml) {
    const res = parseFML({ tagName: this.fmlTag, attrList: ["id", "type"], fml, requireTag: true });
    if (!res) {
      return null;
    }
    const { attrs } = res;
    if (!attrs?.id || !attrs?.type) {
      return null;
    }
    return { id: attrs.id, type: attrs.type };
  },

  dumpFML({ id, type }) {
    return serializeFML({ tagName: this.fmlTag, attrs: { id, type } });
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: ImageValue,
  editorComponent: FieldEditorGeneric,
  valueEditorComponent: FieldValueEditorImage,

  convert(field) {
    return { ...field, type: this.type, attributes: undefined };
  },
};

export interface IFieldImage extends IBaseField {
  type: "image";
}

export interface IImageFieldValue {
  id: string;
  type: string;
}
