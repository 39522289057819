import React from "react";
import Style from "style";

export default function VerticallySplitLayout({
  left,
  right,
}: {
  left?: JSX.Element;
  right?: JSX.Element;
}): JSX.Element {
  const style: React.CSSProperties = {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "row",
    flexWrap: "nowrap",
    height: "100%",
  };

  const sideStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 50%",
    height: "100%",
    overflow: "auto",
  };

  const dividerStyle: React.CSSProperties = {
    display: "flex",
    flex: `0 0 ${Style.hairline}`,
    backgroundColor: Style.colors.divider,
    margin: "0.5rem 0.5rem",
  };

  return (
    <div style={style}>
      <div style={sideStyle}>{left}</div>
      <div style={dividerStyle} />
      <div style={sideStyle}>{right}</div>
    </div>
  );
}
