import color from "color";
import Num from "components/number";
import { useIsPhone } from "hooks/util/useMediaQuery";
import React from "react";
import Style from "style";

function heightFunction(bars: IBar[]) {
  let maxCount: number = Number.NaN;
  for (const bar of bars) {
    maxCount = Math.max(maxCount, bar.shadowCount ?? bar.count) || bar.count;
  }
  return (bar: IBar) => {
    if (Number.isNaN(maxCount) || maxCount === 0 || maxCount === undefined) {
      return "0%";
    }
    return `${Math.round((bar.count / maxCount) * 100)}%`;
  };
}

function shadowHeightFunction(bars: IBar[]) {
  let maxCount: number = Number.NaN;
  for (const bar of bars) {
    maxCount = Math.max(maxCount, bar.shadowCount ?? bar.count) || bar.count;
  }
  return (bar: IBar) => {
    if (Number.isNaN(maxCount) || maxCount === 0 || maxCount === undefined) {
      return "0%";
    }
    return `${Math.round(((bar.shadowCount ?? bar.count) / maxCount) * 100)}%`;
  };
}

export interface IBar {
  class?: keyof typeof Style.colors.grades;
  count: number;
  shadowCount?: number;
  label: string;
  hideEmpty?: boolean;
  color?: string;
  onClick?: () => void;
  highlighted?: boolean;
}

function Bar(props: {
  bar: IBar;
  count: boolean;
  height?: string;
  actionable?: boolean;
  grow?: boolean;
  barHeight: (bar: IBar) => string;
  shadowBarHeight?: (bar: IBar) => string;
}) {
  const { bar, count, height, actionable, grow, barHeight, shadowBarHeight } = props;
  const isPhone = useIsPhone();

  const style: React.CSSProperties = {
    height: height,
    verticalAlign: "bottom",
    border: bar.highlighted ? `2px dashed ${Style.colors.actionableFg}` : "2px solid transparent",
    cursor: bar.onClick ? "pointer" : undefined,
  };

  const barColor =
    bar.color !== undefined
      ? bar.color
      : // Legacy support for styling bars by "class".
        bar.class !== undefined
        ? Style.colors.grades[bar.class]
        : actionable || bar.onClick
          ? Style.colors.actionableFg
          : Style.colors.neutralBar;

  const countStyle: React.CSSProperties = {
    color: bar.count === 0 ? Style.colors.mutedFg : Style.colors.primaryFg,
    fontSize: isPhone ? "3vmin" : undefined,
    textAlign: "center",
  };
  const shadowCountStyle: React.CSSProperties = {
    ...countStyle,
    color: "transparent", // NOTE: transparent so that it still gets pushed down by number, by the same amount the non-shadow bar would.
  };

  const barStyle: React.CSSProperties = {
    boxShadow: `1px 1px 1px ${Style.colors.boxShadow}`,
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    backgroundColor: color(barColor).alpha(0.7).string(),
    border: `1px solid ${barColor}`,
    height: barHeight(bar),
    transition: grow ? "all 300ms cubic-bezier(0.6, 0, 0.1, 1.0)" : undefined,
    width: "100%",
  };
  const shadowBarStyle: React.CSSProperties = {
    ...barStyle,
    height: shadowBarHeight?.(bar),
    backgroundColor: bar.shadowCount === bar.count ? "transparent" : Style.colors.itemMutedBg,
    border: `1px solid ${Style.colors.itemMutedBg}`,
  };

  const stackedBarStyle: React.CSSProperties = {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  };

  return (
    <td style={style} onClick={bar.onClick}>
      <div style={{ position: "relative", height: "100%" }}>
        {shadowBarHeight ? (
          <div style={{ ...stackedBarStyle, zIndex: 1 }}>
            {count && bar.shadowCount ? (
              <div style={shadowCountStyle}>
                <Num num={bar.shadowCount} abbreviate={true} />
              </div>
            ) : undefined}
            <div style={shadowBarStyle} />
          </div>
        ) : undefined}
        <div style={{ ...stackedBarStyle, zIndex: 10 }}>
          {count && bar.count > 0 ? (
            <div style={countStyle}>
              <Num num={bar.count} abbreviate={true} />
            </div>
          ) : undefined}
          <div style={barStyle} />
        </div>
      </div>
    </td>
  );
}

interface IBarChartProps {
  actionable?: boolean;
  grow?: boolean;
  height?: string;
  showLabels?: boolean;
  bars: IBar[];
  count: boolean;
  isSmallFont?: boolean;
}
export default function BarChart({
  actionable = false,
  grow = true,
  height = "5rem",
  showLabels = true,
  bars,
  count,
  isSmallFont = false,
}: IBarChartProps): React.ReactElement {
  const barHeight = heightFunction(bars);
  const hasShadowBars = bars.every((bar) => bar.shadowCount !== undefined);
  const shadowBarHeight = hasShadowBars ? shadowHeightFunction(bars) : undefined;
  const isPhone = useIsPhone();

  const barEls: JSX.Element[] = [];
  for (let i = 0; i < bars.length; i++) {
    const bar = bars[i];
    if (bar.count === 0 && bar.hideEmpty === true) {
      continue;
    }

    barEls.push(
      <Bar
        key={`bar-${i}`}
        bar={bar}
        count={count}
        height={height}
        actionable={actionable}
        grow={grow}
        barHeight={barHeight}
        shadowBarHeight={shadowBarHeight}
      />,
    );
  }

  const labels: JSX.Element[] = [];
  for (let i = 0; i < bars.length; i++) {
    const bar = bars[i];
    if (bar.count === 0 && bar.hideEmpty === true) {
      continue;
    }

    const labelColor = bar.highlighted ? Style.colors.actionableFg : Style.colors.primaryFg;

    const style = {
      color: labelColor,
      paddingTop: showLabels ? "0.5rem" : undefined,
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: bar.highlighted ? 500 : 400,
      opacity: 0.7,
      textAlign: "center",
      whiteSpace: "nowrap",
      cursor: bar.onClick !== undefined ? "pointer" : undefined,
      fontSize: isSmallFont ? "12px" : undefined,
    } as React.CSSProperties;

    labels.push(
      <td
        key={`label-${i}`}
        style={style}
        onClick={bar.onClick}
        dangerouslySetInnerHTML={{ __html: bar.label || "" }}
      />,
    );
  }

  const style = {
    width: "100%",
    marginTop: showLabels ? "1.5rem" : undefined,
    padding: showLabels && !isPhone ? Style.edgePadding : undefined,
    tableLayout: "fixed",
    borderSpacing: 1,
    borderCollapse: "separate",
  } as React.CSSProperties;

  return (
    <table style={style}>
      <tbody>
        <tr>{barEls}</tr>
        <tr>{labels}</tr>
      </tbody>
    </table>
  );
}
