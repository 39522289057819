import { useIonViewWillEnter } from "@ionic/react";
import { IDeck } from "@models/deck";
import { decksGradePercent } from "@models/folder";
import React, { useEffect, useState } from "react";

// useGradePercent will return NaN when a deck is NEW, and undefined when loading.
export function useGradePercent(
  decks: IDeck[] | undefined,
  hideGrade: boolean,
): number | undefined {
  const [renderCounter, setRenderCounter] = useState(0);
  useIonViewWillEnter(() => {
    setRenderCounter(renderCounter + 1);
  }, [renderCounter]);

  const [gradePercent, setGradePercent] = React.useState<number>();
  useEffect(() => {
    if (hideGrade || !decks) {
      return;
    }

    function updateGradePercent() {
      if (hideGrade || !decks) {
        return;
      }

      decksGradePercent(decks)
        .then((gradePct) => {
          setGradePercent(gradePct);
        })
        .catch(() => {
          // TODO.
        });
    }

    updateGradePercent();
  }, [hideGrade, decks, renderCounter]);

  return gradePercent;
}
