import { Grade } from "./grade";
import gradePriorityFloor from "./gradePriorityFloor";

export function priorityToGradeLetter(priority: number): Grade | null {
  if (isNaN(priority)) {
    return "NEW";
  }
  if (priority >= gradePriorityFloor("F")) {
    return "F";
  }
  if (priority >= gradePriorityFloor("E")) {
    return "E";
  }
  if (priority >= gradePriorityFloor("D")) {
    return "D";
  }
  if (priority >= gradePriorityFloor("C")) {
    return "C";
  }
  if (priority >= gradePriorityFloor("B")) {
    return "B";
  }
  if (priority >= gradePriorityFloor("A")) {
    return "A";
  }
  if (priority >= gradePriorityFloor("AA")) {
    return "AA";
  }
  if (priority >= gradePriorityFloor("AAA")) {
    return "AAA";
  }
  return null;
}
