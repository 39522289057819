import { IKnol } from "@models/knol";
import { Grade } from "./grade";
import { gradeHistogram } from "./gradeHistogram";

export function highestPriorityGrade(cards: IKnol[]): Grade | null {
  const hist = gradeHistogram(cards);

  const gradesOrderedByPriorityDescending = [
    "NEW",
    "F",
    "E",
    "D",
    "C",
    "B",
    "A",
    "AA",
    "AAA",
  ] as Grade[];
  for (const grade of gradesOrderedByPriorityDescending) {
    if (hist[grade] > 0) {
      return grade;
    }
  }
  return null;
}
