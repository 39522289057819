import React from "react";
import { codeLanguages } from "./languageNav";
import FieldEditorGeneric from "./fieldEditorGeneric";
import { IFieldCode } from "fields/types/code";
import { IFieldEditorProps } from "fields/fieldType";
import { LanguageSelectorItem } from "./languageSelectorItem";

export default function FieldEditCode({
  fields,
  field,
  onChange,
  creatingConfig,
}: IFieldEditorProps<IFieldCode>): JSX.Element {
  function handleLanguageChange(lang: string | undefined) {
    onChange({
      ...field,
      attributes: {
        ...field.attributes,
        lang,
      },
    });
  }

  const langCode = field.attributes?.lang;
  const langs = codeLanguages();

  return (
    <FieldEditorGeneric
      fields={fields}
      field={field}
      onChange={onChange}
      creatingConfig={creatingConfig}
    >
      <LanguageSelectorItem
        field={field}
        langCode={langCode}
        langs={langs}
        showLangCode={false}
        onChange={handleLanguageChange}
      />
    </FieldEditorGeneric>
  );
}
