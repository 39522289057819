import Device from "device";
import React from "react";
import Style from "style";
import Replate, { ReplateContext, type INodeCompiler } from "vendor/replate";

// Compiles untrusted HTML into safe React components, with links
// in the HTML activated, but all other tags discarded.
export default function UntrustedHtmlWithLinksPermitted(
  untrustedHtml: string,
  preventClick = false,
) {
  const linkCompiler: INodeCompiler = {
    match(nodeName, attrs) {
      return nodeName === "a";
    },
    compile: (elName, attrs, children, recursiveCompile, keyGen) => {
      const style = {
        color: Style.colors.actionableFg,
        cursor: "pointer",
      };

      function render(context: ReplateContext) {
        // NOTE: <span> is used because <a> wasn't allowing onClick to work.
        return (
          <span
            key={keyGen()}
            onClick={(evt) => {
              if (preventClick) {
                evt.preventDefault();
                return;
              }
              Device.openExternalLink(attrs.href);
            }}
            style={!preventClick ? style : undefined}
          >
            {children(context)}
          </span>
        );
      }

      return render;
    },
  };

  const descriptionCompiler = new Replate({
    whitelist: ["a"],
    node: [linkCompiler],
  });
  const template = descriptionCompiler.compile(untrustedHtml);

  if (typeof template === "function") {
    return template({});
  }
}
