import { IonCard, IonCardContent, IonCardSubtitle } from "@ionic/react";
import { DeckFields } from "@models/deck";
import ConfigPreview from "@screens/deckConfig/configPreview";
import React from "react";
import Style from "style";

interface IProps {
  selected?: boolean;
  name?: string;
  fields: DeckFields;
  values?: Record<string, string>;
  description?: string;
  onClick: () => void;
  backOnly?: boolean;
}

function ConfigItem({
  name,
  fields,
  selected,
  values,
  onClick,
  description,
  backOnly,
}: IProps): JSX.Element {
  const style = React.useMemo(() => {
    const borderColor = selected ? Style.colors.actionableFg : Style.colors.secondaryFg;
    return {
      width: backOnly ? 220 : 340,
      marginTop: 4,
      marginBottom: 8,
      marginLeft: 0,
      marginRight: 16,
      boxShadow: "none",
      padding: 8,
      border: `1px solid ${borderColor}`,
      flexShrink: 0,
    };
  }, [selected]);

  return (
    <IonCard button onClick={onClick} style={style}>
      <MemoInnards
        name={name}
        fields={fields}
        values={values}
        description={description}
        backOnly={backOnly}
      />
    </IonCard>
  );
}

interface IInnardsProps {
  name?: string;
  fields: DeckFields;
  values?: Record<string, string>;
  description?: string;
  backOnly?: boolean;
}
function Innards({ name, fields, values, description, backOnly }: IInnardsProps) {
  return (
    <div style={{ textAlign: "center", paddingLeft: 8 }} className={`config-${name}`}>
      <IonCardSubtitle style={{ textAlign: "center" }}>{name}</IonCardSubtitle>
      <ConfigPreview
        config={{ fields }}
        values={values}
        height={160}
        width={backOnly ? 180 : 140}
        mini
        unclickable
        disableAudioControlsRendering
        backOnly={backOnly}
      />
      <IonCardContent style={{ padding: "8px 0", fontSize: "small", textAlign: "left" }}>
        {description}
      </IonCardContent>
    </div>
  );
}

const MemoInnards = React.memo(Innards);

export default React.memo(ConfigItem);
