import IconInput from "@components/iconInput";
import { showPrompt } from "@components/prompt";
import { IonButton, IonList } from "@ionic/react";
import Globals from "globals";
import { keyOutline, mailOutline } from "ionicons/icons";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import { User } from "models/user";
import React from "react";

interface IProps {
  disabled: boolean;
  onLogin: (email: string, password: string) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
}
export default function Login(props: IProps) {
  const { disabled, onLogin, email, setEmail, password, setPassword } = props;

  function handleForgotPass() {
    return showPrompt({
      promptType: "input",
      callback: (eml) => {
        if (!eml) {
          return;
        }
        User.resetPassword(eml.trim().toLocaleLowerCase())
          .then(() => {
            showPrompt({
              prompt: L10n.localize((s) => s.account.forgotPasswordSuccess),
              promptType: "alert",
              title: L10n.localize((s) => s.general.attention),
            });
          })
          .catch((status) => {
            showPrompt({
              prompt:
                status === 502
                  ? L10n.localize((s) => s.error.communication)
                  : L10n.localize((s) => s.account.forgotPasswordFailure),
              promptType: "alert",
              title: L10n.localize((s) => s.general.attention),
            });
          });
      },
      default: email || "",
      inputType: "email",
      prompt: L10n.localize((s) => s.account.forgotPasswordPrompt),
      title: L10n.localize((s) => s.account.forgotPassword),
    });
  }

  const submitDisabled = disabled || email.length < 1 || password.length < 1;

  function handleLogin(e: React.FormEvent) {
    e.preventDefault();
    if (submitDisabled) {
      return;
    }
    onLogin(email, password);
  }

  const mode = Globals.platform === "ios" ? "ios" : "md";

  return (
    <form id="login-form" onSubmit={handleLogin}>
      <IonList>
        <IconInput
          id="login-email"
          icon={mailOutline}
          value={email}
          type="email"
          disabled={disabled}
          autocomplete="username"
          placeholder={L10n.localize((s) => s.account.email)}
          onChange={(v: string) => setEmail(v)}
        />
        <IconInput
          id="login-password"
          icon={keyOutline}
          value={password}
          type="password"
          disabled={disabled}
          onSubmit={handleLogin}
          autocomplete="current-password"
          placeholder={L10n.localize((s) => s.account.password)}
          onChange={(v: string) => setPassword(v)}
        />
      </IonList>

      <IonButton
        {...cyAttr("login-button")}
        disabled={submitDisabled}
        onClick={handleLogin}
        style={{ marginTop: 12 }}
        expand="block"
        shape={mode === "ios" ? "round" : undefined}
      >
        {L10n.localize((s) => s.account.logIn)}
      </IonButton>
      <IonButton fill="clear" onClick={handleForgotPass} disabled={disabled}>
        {L10n.localize((s) => s.account.forgotPassword)}
      </IonButton>
      <input type="submit" hidden />
    </form>
  );
}
