import { useIonViewDidEnter, useIonViewDidLeave, useIonViewWillEnter } from "@ionic/react";
import MapView from "@lib/mapView";
import { IDeck } from "@models/deck";
import { IDeckSettings, defaultDeckSettings } from "@models/deckSettings";
import { IKnol, Knol } from "@models/knol";
import { filterIterable } from "@screens/deck/cards/browseCardsContent";
import {
  loadOmniReviewDeckIDBlacklist,
  loadPersistedOmniReviewFilter,
  loadPersistedOmniReviewSettings,
  loadRecentDecks,
} from "@screens/omniReview";
import EventBus from "eventBus";
import { useEffect, useRef, useState } from "react";
import { ID } from "types/ID";
import { superCache } from "./superCache";

interface IDeckSpec {
  deckID: ID;
}
interface IFolderSpec {
  folder: string;
}
export type LensSpec = "omni" | IDeckSpec | IFolderSpec;
export function isOmniSpec(lensSpec?: LensSpec): lensSpec is "omni" {
  return lensSpec === "omni";
}
export function isDeckSpec(spec?: LensSpec): spec is IDeckSpec {
  return (spec as IDeckSpec)?.deckID !== undefined;
}
export function isFolderSpec(spec?: LensSpec): spec is IFolderSpec {
  return (spec as IFolderSpec)?.folder !== undefined;
}

interface ILensOptions {
  excludeIgnored?: boolean;
  sortKnols?: boolean;
  listenWhenOffscreen?: boolean;
  skipRefilterKnolsOnUpdate?: boolean;
  sessionID?: ID; // Change this to force a re-filtering of knols.
}

export function useLens(spec?: LensSpec, opts?: ILensOptions) {
  const settings = useRef<IDeckSettings>(defaultDeckSettings());
  const [settingsUpdatedAt, setSettingsUpdatedAt] = useState<Date>();
  const unfilteredDecks = useRef<MapView<ID, IDeck>>(new MapView());
  const decks = useRef<MapView<ID, IDeck>>(new MapView());
  const [decksUpdatedAt, setDecksUpdatedAt] = useState<Date>();
  const knols = useRef<MapView<ID, IKnol>>(new MapView());
  const filteredKnols = useRef<MapView<ID, IKnol>>(new MapView());
  const [knolsUpdatedAt, setKnolsUpdatedAt] = useState<Date>();

  // TODO: rather than an overall loading state, better? to just use
  // undefined for the initial value of each of the above pieces of data.
  const [loading, setLoading] = useState(true);

  // Listen for screen transitions to prevent refetching data while offscreen.
  const [onscreen, setOnscreen] = useState(false);
  useIonViewWillEnter(() => {
    setOnscreen(true);
  }, []);
  useIonViewDidEnter(() => {
    setOnscreen(true);
  }, []);
  useIonViewDidLeave(() => {
    setOnscreen(false);
  }, []);

  useEffect(() => {
    if (!spec || (!onscreen && !opts?.listenWhenOffscreen)) {
      return;
    }

    function fetchSettings() {
      if (isOmniSpec(spec)) {
        settings.current = loadPersistedOmniReviewSettings();
      } else if (isFolderSpec(spec)) {
        settings.current = {
          ...defaultDeckSettings(),
          ...superCache.folders?.get(spec.folder)?.settings,
        };
      } else if (isDeckSpec(spec)) {
        settings.current = {
          ...defaultDeckSettings(),
          ...superCache.decks?.get(spec.deckID)?.user_config?.settings,
        };
      }
      setSettingsUpdatedAt(new Date());
    }

    async function fetchDecks() {
      if (isOmniSpec(spec)) {
        try {
          const recency = loadPersistedOmniReviewFilter();
          const recentDecks = await loadRecentDecks(recency);
          const deckIDs = new Set(recentDecks.map((d) => d.id));
          unfilteredDecks.current.setKeys(deckIDs);

          const blacklist = loadOmniReviewDeckIDBlacklist();
          const filteredDeckIDs = new Set<ID>(filterIterable(deckIDs, (id) => !blacklist.has(id)));
          decks.current.setKeys(filteredDeckIDs);
        } catch {
          // Uh oh.
        }
      } else if (isFolderSpec(spec)) {
        const deckIDs = superCache.decksInFolder(spec.folder);
        decks.current.setKeys(deckIDs ?? new Set());
      } else if (isDeckSpec(spec)) {
        decks.current.setKeys(new Set([spec.deckID]));
      }
      setDecksUpdatedAt(new Date());
    }

    function fetchKnols() {
      const knolIDs = new Set<ID>();
      for (const deckID of decks.current.keys()) {
        const kIDs = superCache.deckIDToKnolIDs?.get(deckID);
        for (const id of kIDs ?? []) {
          knolIDs.add(id);
        }
      }
      knols.current.setKeys(knolIDs);
      filterKnols();
      setKnolsUpdatedAt(new Date());
    }

    function filterKnols() {
      const filteredKnolIDs = superCache.filteredKnolIDsFor(
        knols.current.keys(),
        settings.current,
        opts?.excludeIgnored,
        opts?.sortKnols,
      );
      if (filteredKnolIDs) {
        filteredKnols.current.setKeys(filteredKnolIDs);
      }
    }

    async function handleDeckUpdate(opts: { ID: ID }) {
      if (decks.current.keys().size < 1) {
        await fetchDecks();
      }
      if (!decks.current.has(opts.ID)) {
        return;
      }
      setDecksUpdatedAt(new Date());
      fetchSettings();
      fetchKnols();
    }

    function handleKnolUpdate({ knolID, deckID }: { knolID: ID; deckID: ID }) {
      if (decks.current.has(deckID)) {
        const knol = knols.current.get(knolID);
        const needsRefilter = knol?.tags?.includes(Knol.IGNORE_TAG);
        if (knols.current.has(knolID) && opts?.skipRefilterKnolsOnUpdate && !needsRefilter) {
          // Just notify consumer that knols have new content—don't refetch everything.
          setKnolsUpdatedAt(new Date());
          return;
        }
        fetchKnols();
      }
    }

    async function handleDeckMoved() {
      if (isFolderSpec(spec)) {
        // Just recompute all decks in the current folder.
        await fetchDecks();
        fetchKnols();
      }
    }

    function handleFolderUpdate(opts?: { name: string }) {
      if (isFolderSpec(spec) && spec.folder === opts?.name) {
        fetchSettings();
        fetchKnols();
      }
    }

    if (superCache.decks) {
      unfilteredDecks.current.map = superCache.decks;
      decks.current.map = superCache.decks;
    }
    if (superCache.knols) {
      knols.current.map = superCache.knols;
      filteredKnols.current.map = knols.current;
    }
    fetchSettings();
    fetchDecks().then(() => {
      fetchKnols();
      setLoading(false);
    });

    superCache.events.on("knolUpdated", handleKnolUpdate);
    superCache.events.on("deckUpdated", handleDeckUpdate);
    superCache.events.on("deckMoved", handleDeckMoved);
    superCache.events.on("folderUpdated", handleFolderUpdate);
    if (isOmniSpec(spec)) {
      EventBus.on("omniReviewSettingsChanged", fetchSettings);
      EventBus.on("omniReviewRecencyChanged", fetchDecks);
    }
    return () => {
      setLoading(true);
      superCache.events.off("knolUpdated", handleKnolUpdate);
      superCache.events.off("deckUpdated", handleDeckUpdate);
      superCache.events.off("deckMoved", handleDeckMoved);
      superCache.events.off("folderUpdated", handleFolderUpdate);
      if (isOmniSpec(spec)) {
        EventBus.off("omniReviewSettingsChanged", fetchSettings);
        EventBus.off("omniReviewRecencyChanged", fetchDecks);
      }
    };
  }, [
    spec,
    onscreen,
    opts?.listenWhenOffscreen,
    opts?.skipRefilterKnolsOnUpdate,
    opts?.excludeIgnored,
    opts?.sortKnols,
  ]);

  return {
    settings,
    settingsUpdatedAt,
    unfilteredDecks: unfilteredDecks.current,
    decks: decks.current,
    knols: knols.current,
    filteredKnols: filteredKnols.current,
    decksUpdatedAt,
    knolsUpdatedAt,
    loading,
  };
}
