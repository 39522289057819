import { AlertInput, useIonAlert } from "@ionic/react";
import { Deck, IDeck, archiveTag } from "@models/deck";
import { IKnol, Knol } from "@models/knol";
import logEvent from "analytics";
import { MAGIC_LAYOUT_ID } from "fields/magicLayout";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import useErrorAlert from "hooks/util/useErrorAlert";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import L10n from "localization";
import { useCallback } from "react";

export default function useCopyKnols(deck: IDeck | undefined) {
  const [showCopyCardModal, onDismiss] = useIonAlert();
  const [presentCopyCardToast] = useDismissibleToast();
  const [showCopyCardError] = useErrorAlert({ code: "LOADING_DECKS" });
  const copyCards = useCallback(
    async (knols: IKnol[]) => {
      let decks: IDeck[];
      try {
        decks = (await Deck.GetAll()).filter((deck) => !deck.tags?.includes(archiveTag));
      } catch (err) {
        showCopyCardError(err);
        return;
      }

      const privateDecks = decks.filter((d) => d.status === Deck.STATUS_PRIVATE);

      const otherDecks = privateDecks.filter(
        (d) =>
          d.id !== deck?.id &&
          (deck?.layout_id === MAGIC_LAYOUT_ID ? d.layout_id === MAGIC_LAYOUT_ID : true),
      );

      const sortedDecks = otherDecks.sort((d1, d2) =>
        (d1.name || "").toLowerCase() < (d2.name || "").toLowerCase() ? -1 : 1,
      );
      showCopyCardModal({
        translucent: true,
        header: L10n.localize((s) => s.actions.copy),
        message: L10n.localize((s) => s.card.copyCompatibilityWarning),
        inputs: sortedDecks.map((d) => ({
          name: d.id,
          type: "radio",
          label: d.name,
          value: d.id,
        })) as AlertInput[],
        buttons:
          sortedDecks.length === 0
            ? [
                {
                  text: L10n.localize((s) => s.card.noSuitableDeck),
                  role: "destructive",
                  cssClass: "dangerous-confirm",
                  handler: onDismiss,
                },
              ]
            : [
                {
                  text: L10n.localize((s) => s.actions.cancel),
                  role: "cancel",
                  cssClass: "secondary",
                  handler: onDismiss,
                },
                {
                  text: L10n.localize((s) => s.actions.copy),
                  handler: (deckID) => {
                    if (!deckID || knols.length < 1) {
                      return false;
                    }

                    if (!knols.every((knol) => knol.values)) {
                      return;
                    }
                    const promises = knols.map((knol) => Knol.Copy(knol, deckID));
                    Promise.all(promises)
                      .then(() => {
                        logEvent("card_copied");
                        presentCopyCardToast({
                          message: L10n.localize((s) => s.general.done),
                          icon: checkmarkCircleOutline,
                          color: "success",
                          duration: 1000,
                        });
                      })
                      .catch(() => {
                        presentCopyCardToast({
                          message: L10n.localize((s) => s.error.internal),
                          icon: closeCircleOutline,
                          color: "danger",
                          duration: 4000,
                        });
                      });
                    onDismiss();
                  },
                },
              ],
      });
    },
    [deck],
  );
  return copyCards;
}
