import { useIsPhone } from "hooks/util/useMediaQuery";
import cyAttr from "lib/cyAttr";
import React from "react";
import Style from "style";

export default function UnlimitedIcon({
  style,
  narrow,
}: {
  style?: React.CSSProperties;
  narrow?: boolean;
}): JSX.Element {
  const isPhone = useIsPhone();

  const theme = Style.currentTheme === "night" ? "dark" : "light";
  const widthSuffix = narrow && isPhone ? "-narrow" : "";
  const iconSrc = `/unlimited-icon-${theme}${widthSuffix}.png`;

  return (
    <img
      alt="unlimited icon"
      {...cyAttr("unlimited-icon")}
      src={iconSrc}
      style={{
        alignSelf: "center",
        maxHeight: narrow ? 48 : 72,
        ...style,
      }}
    />
  );
}
