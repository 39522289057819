import FieldEditCode from "fields/editors/fieldEditorCode";
import { IBaseField } from "fields/lib";
import { IFieldType } from "fields/fieldType";
import FieldValueEditorCode from "fields/valueEditors/fieldValueEditorCode";
import L10n from "localization";
import CodeValue from "fields/values/codeValue";
import { IoCodeSlash } from "react-icons/io5";
import { parseSides } from "fields/sides";

export const codeFieldType: IFieldType<"code"> = {
  name: () => L10n.localize((s) => s.field.typeCode),
  description: () => L10n.localize((s) => s.field.typeCodeDescription),
  icon: IoCodeSlash,
  sampleValue: () => `printf("👋, 🌎\n");`,
  fmlTag: "code",
  type: "code",
  parseField(attrs, srcs): IFieldCode {
    return {
      name: attrs["name"],
      type: this.type,
      sides: parseSides(attrs["sides"]),
      source: srcs[0],
      attributes: {
        lang: attrs["lang"],
      },
    };
  },

  loadFML(fml) {
    return fml;
  },

  dumpFML(value) {
    return value;
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: CodeValue,
  editorComponent: FieldEditCode,
  valueEditorComponent: FieldValueEditorCode,

  convert(field) {
    return { ...field, type: this.type, attributes: undefined };
  },

  valid(field) {
    if (!field.attributes?.lang) {
      return false;
    }
    return true;
  },
};

export interface IFieldCode extends IBaseField {
  type: "code";
  attributes?: {
    lang?: string;
  };
}
