import { IonButton, IonButtons, IonContent, IonHeader, IonTitle, IonToolbar } from "@ionic/react";
import { Deck } from "@models/deck";
import { useFolders } from "hooks/data/folders";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import NestedFolderView from "./nestedFolderView";

export function useFolderSelectorModal({
  deckID,
  onSave,
}: {
  deckID: string;
  onSave: (folder?: string, initialFolder?: string) => void;
}): [() => void, () => void] {
  const [present, dismiss] = useIonCardModalWithDismiss(FolderSelectorModal, { deckID, onSave });

  return [present, dismiss];
}

interface IProps {
  deckID: string;
  dismiss: () => void;
  onSave: (folder?: string, initialFolder?: string) => void;
}
export default function FolderSelectorModal({ deckID, dismiss, onSave }: IProps): JSX.Element {
  const [selectedFolder, setSelectedFolder] = useState<string>();
  const [initialFolder, setInitialFolder] = useState<string>();

  useEffect(() => {
    Deck.find(deckID).then((deck) => {
      const folder = deck?.tags?.[0];
      setSelectedFolder(folder);
      setInitialFolder(folder);
    });
  }, [deckID]);

  const selectedFolders = useMemo(() => {
    const sfs: string[] = [];
    if (selectedFolder) {
      sfs.push(selectedFolder);
    }
    return sfs;
  }, [selectedFolder]);

  const allFolderNames = useFolders();
  const folders = selectedFolder
    ? [selectedFolder].concat(allFolderNames.val ?? [])
    : allFolderNames.val ?? [];

  const handleClick = useCallback(
    (folder: string) => {
      if (folder === selectedFolder) {
        setSelectedFolder(undefined);
      } else {
        setSelectedFolder(folder);
      }
    },
    [selectedFolder],
  );
  const handleAddFolder = useCallback((folder?: string) => {
    setSelectedFolder(folder);
  }, []);

  const handleSave = useCallback(() => {
    onSave(selectedFolder, initialFolder);
    dismiss();
  }, [dismiss, initialFolder, onSave, selectedFolder]);

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={dismiss}>{L10n.localize((s) => s.actions.close)}</IonButton>
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.actions.move)}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={handleSave}
              disabled={selectedFolder === initialFolder}
              {...cyAttr("save-button")}
            >
              {L10n.localize((s) => s.actions.save)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent {...cyAttr("folder-selector-screen")}>
        <NestedFolderView
          loading={allFolderNames.loading}
          folders={folders}
          selectedFolders={selectedFolders}
          onClick={handleClick}
          onAddFolder={handleAddFolder}
        />
        <div style={{ height: "env(safe-area-inset-bottom)" }} />
      </IonContent>
    </>
  );
}
