import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonNav,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { DeckFields } from "@models/deck";
import { FieldsContext } from "@screens/deckCreate";
import { IField } from "fields/fields";
import useFieldsReducer, { FieldsMutation } from "fields/hooks/useFieldsReducer";
import { isValidField } from "fields/lib";
import useConfirmCancellationDialog from "hooks/util/useConfirmCancellationDialog";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback, useContext, useState } from "react";
import FieldEditor from "./fieldEditor";

interface IProps {
  dismiss: () => void;
  initField: IField;
  name: string;
  nameEditable?: boolean;
  onSave: (newField: IField) => void;
}

export default function FieldEditorModal({
  initField,
  dismiss,
  name,
  nameEditable,
  onSave,
}: IProps): JSX.Element {
  // HACK: name is the "primary key" we use to lookup the field from the in-memory store.
  // BUT, we can change the name on this screen, which breaks the lookup.
  // SO, we track the current (mutated) name in local state, and use that.
  const [localName, setLocalName] = useState(name);
  const { fields, dispatch } = useContext(FieldsContext);
  const field = fields.find((f) => f.name === localName);

  const handleChange = useCallback(
    (newField: IField) => {
      if (!field) {
        return;
      }
      setLocalName(newField.name);
      dispatch({ type: "modify", fieldName: localName, field: newField });
    },
    [localName, field, dispatch],
  );

  function handleSave() {
    if (field) {
      onSave(field);
    }
    dismiss();
  }

  const presentConfirmCancel = useConfirmCancellationDialog(dismiss);
  const isEdited = JSON.stringify(initField) !== JSON.stringify(field);
  function handleClose() {
    if (isEdited) {
      presentConfirmCancel();
    } else {
      dismiss();
    }
  }

  const fieldInvalid = !isValidField(field);

  const duplicateName =
    fields.filter((f) => field !== undefined && f.name === field.name).length > 1;
  const noName = !field?.name;
  const saveDisabled = noName || duplicateName || fieldInvalid;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.field.singular)}</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={handleClose} color={isEdited ? "danger" : undefined}>
              {isEdited
                ? L10n.localize((s) => s.actions.cancel)
                : L10n.localize((s) => s.actions.close)}
            </IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton disabled={saveDisabled} onClick={handleSave}>
              {L10n.localize((s) => s.actions.save)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ marginBottom: "env(safe-area-inset-bottom)" }}>
        {field && (
          <FieldEditor
            fields={fields}
            field={field}
            onChange={handleChange}
            creatingConfig={nameEditable}
          />
        )}
      </IonContent>
    </IonPage>
  );
}

interface IFieldEditorModalNavProps extends IProps {
  fields: DeckFields;
  onSave: (newField: IField) => void;
}
function FieldEditorModalNav({
  fields,
  initField,
  dismiss,
  name,
  nameEditable,
  onSave,
}: IFieldEditorModalNavProps) {
  // This sets up a local copy of the fields, which do not modify the parent's.
  // When the users saves, then we propagate up just the field they modified.
  const [localFields, dispatch] = useFieldsReducer(fields);
  function dispatchToSelfAndParent(mut: FieldsMutation) {
    dispatch(mut);
  }

  const navRoot = useCallback(
    () => (
      <FieldEditorModal
        initField={initField}
        dismiss={dismiss}
        name={name}
        nameEditable={nameEditable}
        onSave={onSave}
      />
    ),
    [initField, dismiss, name, nameEditable, onSave, FieldEditorModal],
  );

  return (
    <FieldsContext.Provider value={{ fields: localFields, dispatch: dispatchToSelfAndParent }}>
      <IonNav root={navRoot} />
    </FieldsContext.Provider>
  );
}

export function useFieldEditorModal({
  initField,
  name,
  fields,
  nameEditable,
  onSave,
}: {
  name: string;
  initField: IField;
  fields: DeckFields;
  nameEditable?: boolean;
  onSave: (newField: IField) => void;
}): [() => void, () => void] {
  const [present, dismiss] = useIonCardModalWithDismiss(FieldEditorModalNav, {
    name,
    fields,
    initField,
    nameEditable,
    onSave,
  });

  return [present, dismiss];
}
