import { IonButton } from "@ionic/react";
import { Deck } from "@models/deck";
import { History } from "history";
import useErrorAlert from "hooks/util/useErrorAlert";
import React from "react";
import Lib from "../../lib";
import L10n from "../../localization";
import { IConfigSelection } from "./main";

interface ISaveButtonProps {
  disabled: boolean;
  setSaving: () => void;
  deckName: string;
  deckDescription?: string;
  folder?: string;
  history: History;
  config: IConfigSelection;
  dismiss?: (data?: any, role?: string) => Promise<void>;
}
export default function SaveButton({
  disabled,
  setSaving,
  deckName,
  deckDescription,
  folder,
  history,
  dismiss,
  config,
}: ISaveButtonProps) {
  const [showSaveDeckError] = useErrorAlert({ code: "CREATING_DECK" });

  function onSave() {
    setSaving();
    const { description, values, ...strippedConfig } = config;

    const deckId = Lib.uuid16();
    Deck.insert(deckId, deckName, deckDescription, strippedConfig, folder)
      .then(async () => {
        await dismiss?.(undefined, "save");
        history.push(`/decks/local/${deckId}`);
      })
      .catch((err) => {
        showSaveDeckError(err);
      });
  }

  return (
    <IonButton onClick={onSave} disabled={disabled}>
      {L10n.localize((s) => s.actions.save)}
    </IonButton>
  );
}
