import { Grade } from "./grade";

// The floor is inclusive (i.e. grade >= its priority floor).
export default function gradePriorityFloor(grade: Grade): number {
  switch (grade) {
    case "F":
      return 0.5;
    case "E":
      return 0.4;
    case "D":
      return 0.3;
    case "C":
      return 0.2;
    case "B":
      return 0.1;
    case "A":
      return 0.04;
    case "AA":
      return 0.02;
    case "AAA":
      return 0.0;
    default:
      return 0.0;
  }
}
