// Async wrapper around localStorage/sessionStorage.
// Use this to ease the transition to an alternate storage mechanism that doesn't risk being wiped when the OS feels like it.

export const setLocalStorage = async (key: string, val: string): Promise<void> => {
  localStorage.setItem(key, val);
};

export const getLocalStorage = async (key: string): Promise<string | undefined> => {
  return localStorage.getItem(key) ?? undefined;
};

export const removeLocalStorage = async (key: string): Promise<void> => {
  return localStorage.removeItem(key);
};

export const setSessionStorage = async (key: string, val: string): Promise<void> => {
  sessionStorage.setItem(key, val);
};

export const getSessionStorage = async (key: string): Promise<string | undefined> => {
  return sessionStorage.getItem(key) ?? undefined;
};

export const removeSessionStorage = async (key: string): Promise<void> => {
  sessionStorage.removeItem(key);
};
