import {
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonReorder,
} from "@ionic/react";
import { IField, fieldTypeMap } from "fields/fields";
import { FieldsMutation } from "fields/hooks/useFieldsReducer";
import { defaultFieldType, isValidField } from "fields/lib";
import { checkmarkOutline, removeCircle, warningOutline } from "ionicons/icons";
import React from "react";
import FieldTypeIcon from "./fieldTypeIcon";

export function fieldTypeWithLanguage({ field }: { field: IField }): string {
  const fieldType = fieldTypeMap[field.type] ?? fieldTypeMap[defaultFieldType];
  const typeName = fieldType.name();
  switch (field.type) {
    case "text":
    case "richtext":
    case "tts": {
      const lang = field.attributes?.lang;
      if (lang) {
        return `${typeName} (${lang})`;
      }
      return typeName;
    }
    default:
      return typeName;
  }
}

interface IProps {
  field: IField;
  editing?: boolean;
  onRemove?: () => void;
  disabled?: boolean;
  onClick?: () => void;
  selected?: boolean;
  detail?: boolean;
  dispatch?: React.Dispatch<FieldsMutation>;
}
export default function FieldListItem({
  field,
  editing,
  onRemove,
  disabled,
  onClick,
  selected,
  detail,
}: IProps): JSX.Element {
  let showDetail = !editing && !disabled;
  if (detail === false) {
    showDetail = false;
  }

  const invalid = !isValidField(field);

  return (
    <IonItemSliding disabled={disabled} id={field.name}>
      <IonItem button={!disabled} detail={showDetail} disabled={disabled} onClick={onClick}>
        <FieldTypeIcon type={field.type} />
        <IonLabel>
          <h2>{field.name}</h2>
          <p>{fieldTypeWithLanguage({ field })}</p>
        </IonLabel>
        {!disabled ? <IonReorder slot="end" /> : undefined}

        {invalid ? <IonIcon icon={warningOutline} color="warning" slot="end" /> : undefined}
        {selected ? <IonIcon icon={checkmarkOutline} slot="end" /> : undefined}
      </IonItem>
      {!disabled && (
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={onRemove}>
            <IonIcon slot="icon-only" icon={removeCircle} />
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
}
