import { IonItem, IonSelect, IonSelectOption, SelectCustomEvent } from "@ionic/react";
import { BFF69 } from "fields/lang";
import { SSMLGender, ttsSupportedGenders } from "lib/tts";
import L10n from "localization";
import React from "react";

interface IProps {
  gender: SSMLGender | undefined;
  onChange: (g: SSMLGender | undefined) => void;
  langCode: BFF69 | undefined;
}
export default function VoiceSelectorItem({ gender, onChange, langCode }: IProps) {
  if (!langCode) {
    return null;
  }
  const supportedGenders = ttsSupportedGenders[langCode];
  if (!supportedGenders || supportedGenders.length < 2) {
    return null;
  }

  const femaleSupported = supportedGenders.includes("female");
  const maleSupported = supportedGenders.includes("male");

  return (
    <IonItem>
      <IonSelect
        value={gender ?? null}
        label={L10n.localize((s) => s.field.ttsPreferredVoice)}
        onIonChange={(evt: SelectCustomEvent) => onChange(evt.target.value)}
      >
        <IonSelectOption value={null}>{L10n.localize((s) => s.general.default)}</IonSelectOption>
        {femaleSupported ? (
          <IonSelectOption value="female">
            {L10n.localize((s) => s.field.genderFemale)}
          </IonSelectOption>
        ) : null}
        {maleSupported ? (
          <IonSelectOption value="male">{L10n.localize((s) => s.field.genderMale)}</IonSelectOption>
        ) : null}
      </IonSelect>
    </IonItem>
  );
}
