import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSegment,
  IonSegmentButton,
  IonSelect,
  IonSelectOption,
  SegmentCustomEvent,
  SelectCustomEvent,
} from "@ionic/react";
import { moonOutline, sunnyOutline, timeOutline } from "ionicons/icons";
import React from "react";
import { ISettings } from "settings";
import Style, { Theme, automaticColorSchemeKey } from "style";
import { Locales } from "locales";
import L10n from "localization";

interface IProps {
  settings?: ISettings;
  updateSettings: (s: keyof ISettings, v: string | number | boolean) => void;
}
export default function InterfaceSettings({ settings, updateSettings }: IProps) {
  const handleColorSchemeChange = (newValue: Theme): void => {
    Style.setTheme(newValue);
    updateSettings("colorScheme", newValue);
  };

  const handleLocaleChange = (v: Locales): void => {
    if (L10n.currentLocale === v) {
      return;
    }
    L10n.switchLocale(v);
    updateSettings("currentLocale", v);
  };

  const dayThemeValue: Theme = "day";
  const nightThemeValue: Theme = "night";

  return (
    <IonList className="pb-8" inset>
      <IonListHeader>{L10n.localize((s) => s.general.interface)}</IonListHeader>
      <IonItem>
        <IonSelect
          label={L10n.localize((s) => s.general.language)}
          name="locale"
          interface="popover"
          value={settings?.currentLocale}
          onIonChange={(e: SelectCustomEvent) => handleLocaleChange(e.detail.value)}
        >
          {Object.entries(L10n.localeNames)
            .sort()
            .map(([locale, name]) => {
              return (
                <IonSelectOption key={locale} value={locale}>
                  {name}
                </IonSelectOption>
              );
            })}
        </IonSelect>
      </IonItem>
      <IonItem lines="none">
        <IonLabel>{L10n.localize((s) => s.general.colorScheme)}</IonLabel>
      </IonItem>
      <div style={{ margin: "0px 8px" }}>
        <IonSegment
          style={{ marginTop: "4px" }}
          value={settings?.colorScheme}
          onIonChange={(e: SegmentCustomEvent) => {
            const newScheme = e.detail.value as Theme;
            handleColorSchemeChange(newScheme);
          }}
        >
          <IonSegmentButton value={dayThemeValue}>
            <IonLabel>{L10n.localize((s) => s.settings.day)}</IonLabel>
            <IonIcon icon={sunnyOutline} />
          </IonSegmentButton>
          <IonSegmentButton value={nightThemeValue}>
            <IonLabel>{L10n.localize((s) => s.settings.night)}</IonLabel>
            <IonIcon icon={moonOutline} />
          </IonSegmentButton>
          <IonSegmentButton value={automaticColorSchemeKey}>
            <IonLabel>{L10n.localize((s) => s.settings.auto)}</IonLabel>
            <IonIcon icon={timeOutline} />
          </IonSegmentButton>
        </IonSegment>
      </div>
    </IonList>
  );
}
