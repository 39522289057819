import L10n from "localization";

export const SORT_OPTION_DEFAULT = "lastSeenAsc" as SortOptions;

export function sortOptionStringFromKey(key: SortOptions): string {
  switch (key) {
    case "alphaAsc":
      return `▲ ${L10n.localize((s) => s.sort.name)}`;
    case "alphaDesc":
      return `▼ ${L10n.localize((s) => s.sort.name)}`;
    case "modifiedAtAsc":
      return `▲ ${L10n.localize((s) => s.sort.modifiedAt)}`;
    case "modifiedAtDesc":
      return `▼ ${L10n.localize((s) => s.sort.modifiedAt)}`;
    case "lastSeenAsc":
      return `▲ ${L10n.localize((s) => s.sort.lastSeen)}`;
    case "lastSeenDesc":
      return `▼ ${L10n.localize((s) => s.sort.lastSeen)}`;
    case "foldersFirstAsc":
      return `▲ ${L10n.localize((s) => s.sort.nameFoldersFirst)}`;
    case "foldersFirstDesc":
      return `▼ ${L10n.localize((s) => s.sort.nameFoldersFirst)}`;
    case "decksFirstAsc":
      return `▲ ${L10n.localize((s) => s.sort.nameDecksFirst)}`;
    case "decksFirstDesc":
      return `▼ ${L10n.localize((s) => s.sort.nameDecksFirst)}`;
    default:
      return "";
  }
}

export function sortMenuOptions(): Array<{ key: string; val: SortOptions }> {
  return [
    { key: sortOptionStringFromKey("alphaAsc"), val: "alphaAsc" },
    { key: sortOptionStringFromKey("alphaDesc"), val: "alphaDesc" },
    { key: sortOptionStringFromKey("modifiedAtAsc"), val: "modifiedAtAsc" },
    { key: sortOptionStringFromKey("modifiedAtDesc"), val: "modifiedAtDesc" },
    { key: sortOptionStringFromKey("lastSeenAsc"), val: "lastSeenAsc" },
    { key: sortOptionStringFromKey("lastSeenDesc"), val: "lastSeenDesc" },
    { key: sortOptionStringFromKey("foldersFirstAsc"), val: "foldersFirstAsc" },
    { key: sortOptionStringFromKey("foldersFirstDesc"), val: "foldersFirstDesc" },
    { key: sortOptionStringFromKey("decksFirstAsc"), val: "decksFirstAsc" },
    { key: sortOptionStringFromKey("decksFirstDesc"), val: "decksFirstDesc" },
  ];
}

export type SortOptions =
  | "alphaAsc"
  | "alphaDesc"
  | "lastSeenAsc"
  | "lastSeenDesc"
  | "modifiedAtAsc"
  | "modifiedAtDesc"
  | "foldersFirstAsc"
  | "foldersFirstDesc"
  | "decksFirstAsc"
  | "decksFirstDesc";

export const bodySections = ["decks", "studygroups", "archive", "inbox"] as const;
export type BodySection = (typeof bodySections)[number];
