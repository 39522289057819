import { showPrompt } from "@components/prompt";
import L10n from "localization";
import logEvent from "analytics";

export const surveyShowedCacheKey = "PDPCancelSurveyShowed";

export default function showPDPCancelSurvey() {
  const alreadyPrompted = !!localStorage.getItem(surveyShowedCacheKey);
  if (alreadyPrompted) {
    return;
  }
  localStorage.setItem(surveyShowedCacheKey, "true");

  const prompt = L10n.localize((s) => s.feedback.pdpCancelSurveyPrompt);

  function handleFeedback(feedback: string | null) {
    logEvent("pdp_cancel_survey_feedback", { prompt, feedback });
  }

  showPrompt({
    promptType: "input",
    title: L10n.localize((s) => s.feedback.feedback),
    prompt,
    callback: handleFeedback,
  });
}
