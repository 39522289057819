import CardPreview from "@cardRendering/cardPreview";
import Empty from "@components/empty";
import { IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import L10n from "localization";
import React from "react";

interface IProps {
  isLoading: boolean;
  knols: IKnol[];
  visibleSide?: 0 | 1;
  deck: IDeck;
}
export default function CardPreviews(props: IProps): JSX.Element {
  const { isLoading, knols, visibleSide = 0, deck } = props;

  const [activeSide, setActiveSide] = React.useState<0 | 1>(visibleSide);

  const previewsHolderStyle: React.CSSProperties = {
    overflowX: "scroll",
    overflowY: "hidden",
    whiteSpace: "nowrap",
    width: "100%",
    padding: 12,
    height: "20rem",
    display: "flex",
    flexDirection: "row",
    gap: 16,
  };

  const previewInnerWrapperStyle = {
    display: "flex",
    height: "100%",
  };

  const noCards = !isLoading && deck && knols.length < 1;
  return (
    <div style={previewsHolderStyle}>
      {noCards && <Empty text={L10n.localize((s) => s.card.none)} marginTop="20px" center />}
      {!noCards &&
        deck &&
        knols.map((knol) => {
          return (
            <div key={knol.id} style={previewInnerWrapperStyle}>
              <CardPreview
                autoplayAudio={false}
                deck={deck}
                onClick={() => setActiveSide(activeSide === 0 ? 1 : 0)}
                isClickable
                knol={{
                  ...knol,
                  deck_id: deck.id,
                  created_at: new Date(knol.created_at || new Date()),
                  modified_at: new Date(knol.modified_at || new Date()),
                }}
                border
                visibleSide={activeSide}
                rubberbandScroll={false}
                persistDownloadedBlobs={false}
              />
            </div>
          );
        })}
    </div>
  );
}
