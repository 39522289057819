import type { Grade } from "@core/grade";
import MapView from "@lib/mapView";
import type { IDeck } from "@models/deck";
import { type IKnol, Knol } from "@models/knol";
import type { ILayout } from "@models/layout";
import Empty from "components/empty";
import L10n from "localization";
import React from "react";
import type { ID } from "types/ID";
import CardHolder from "./cardHolder";
import FilterBar from "./filterBar";
import Grid from "./grid";

function cardMarginPx() {
  const x = 4;
  if (window.innerWidth > 600) {
    return x * 1;
  }
  return x;
}

function optimalCardWidth(
  containerWidthPx: number,
  minCardWidthPx: number | null = null,
  borderPx: number | null = null,
) {
  // This computes the card width that packs in the maximum number
  // of cards into each row, subject to a minimum card width
  // constraint. Approach:
  //
  // 1) Compute the maximum cards that can be fit into a row.
  // 2) Increase the card width to the point where it would be
  //    necessary to reduce the number of cards per row.
  // 3) That is the optimum width.
  if (!minCardWidthPx) {
    minCardWidthPx = 148;
  }
  if (!borderPx) {
    borderPx = 1;
  }
  if (containerWidthPx > 600) {
    minCardWidthPx *= 1.7;
  }

  const marginPx = cardMarginPx();

  const extraWidthPx = marginPx * 2 + borderPx * 2;
  const effectiveContainerWidthPx = containerWidthPx - marginPx;
  const maxCardsPerRow = Math.floor(effectiveContainerWidthPx / (minCardWidthPx + extraWidthPx));
  const w = Math.floor(effectiveContainerWidthPx / maxCardsPerRow);
  return w - extraWidthPx;
}

function cardWidthPx() {
  return optimalCardWidth(window.innerWidth);
}

function cardHeightPx() {
  return Math.round(cardWidthPx() * 1.2);
}

export function* filterIterable<T>(
  iterable: Iterable<T>,
  predicate: (x: T) => boolean | undefined,
): Generator<T> {
  for (const item of iterable) {
    if (predicate(item)) {
      yield item;
    }
  }
}

interface IProps {
  deck: IDeck | undefined;
  filteredKnols: MapView<ID, IKnol>;
  screenMode: "cards" | "grid";
  inSelectMode?: boolean;
  toggleKnolSelected: (knolID: ID) => void;
  selectedKnolIDs: Set<ID>;
  savedAt: Date | undefined;
  query: string | undefined;
  newRows: Array<Record<string, string>>;
  setNewRows: (rows: Array<Record<string, string>>) => void;
  edits: Record<string, Record<string, string>>;
  setEdits: (edits: Record<string, Record<string, string>>) => void;
  clearedAt: Date | undefined;
  editable: boolean;
  columns: string[];
  layouts?: ILayout[];
  setScreenMode: (mode: "cards" | "grid") => void;
  modified: boolean;
  browseGrades: Grade[];
  browseTags: string[];
  sortOrder: string;
  fontSizePx: number;
}

export default function BrowseCardsContent(props: IProps) {
  const {
    deck,
    screenMode,
    savedAt,
    // sortOrder,
    query,
    browseTags,
    browseGrades,
    fontSizePx,
  } = props;

  if (!deck) {
    return <></>;
  }

  const activeSide = 1; // Show back so that search results show match on front or back

  const matchingKnols = new MapView(props.filteredKnols, props.filteredKnols.keys());
  if (query) {
    const matchingIDs = new Set<ID>();
    for (const [id, knol] of props.filteredKnols) {
      if (Knol.matchesQuery(knol, query)) {
        matchingIDs.add(id);
      }
    }
    matchingKnols.setKeys(matchingIDs);
  }

  const { newRows, setNewRows, edits, setEdits, clearedAt, editable, columns } = props;

  let content: any;
  switch (screenMode) {
    case "cards":
      content = (
        <CardHolder
          query={query}
          activeSide={activeSide}
          deck={deck}
          filteredKnols={matchingKnols}
          cardMarginPx={cardMarginPx()}
          cardWidthPx={cardWidthPx()}
          cardHeightPx={cardHeightPx()}
          fontSizePx={fontSizePx}
          inSelectMode={props.inSelectMode}
          toggleKnolSelected={props.toggleKnolSelected}
          selectedKnolIDs={props.selectedKnolIDs}
        />
      );
      break;
    case "grid":
      content = (
        <Grid
          deck={deck}
          filteredKnols={matchingKnols}
          editable={editable}
          newRows={newRows}
          setNewRows={setNewRows}
          edits={edits}
          setEdits={setEdits}
          savedAt={savedAt}
          clearedAt={clearedAt}
          columns={columns}
        />
      );
      break;
  }

  const showEmpty = props.filteredKnols.size < 1 && screenMode !== "grid";

  // NOTE: position: absolute and inset: 0 were necessary to prevent a weird shortening of the scrolling area when the iOS keyboard popped open. This works in conjunction with fullscreen={false} on the <IonContent> container, otherwise the cards and grid extend under the toolbars.
  const style: React.CSSProperties = {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  };

  return (
    <div style={style}>
      <div>
        <FilterBar tags={browseTags} grades={browseGrades} />
      </div>

      {showEmpty && <Empty key="empty" text={L10n.localize((s) => s.card.none)} />}
      {!showEmpty && content}
    </div>
  );
}
