import { showPrompt } from "@components/prompt";
import { ILoginResponse } from "@models/user";
import ScreenComponent from "components/screen";
import Globals from "globals";
import L10n from "localization";
import { User } from "models/user";
import React from "react";
import Login from "screens/auth/login";
import Logo from "screens/auth/logo";
import Signup from "screens/auth/signup";
import Support from "screens/auth/support";
import Style from "style";

import LoadingIndicator from "@components/loadingIndicator";
import idb from "@data/idb";
import { IonSegment, IonSegmentButton, IonText, SegmentChangeEventDetail } from "@ionic/react";
import useHideTabBar from "hooks/useHideTabBar";
import { useGroupJoinRequest } from "hooks/util/useGroupJoinRequest";
import { useIsPhone } from "hooks/util/useMediaQuery";
import { useHistory } from "react-router";

function Version() {
  return (
    <IonText
      style={{ display: "block", marginTop: 16 }}
      color="medium"
    >{`v${Globals.version} (${Globals.versionHash})`}</IonText>
  );
}

export default function AuthScreen(): JSX.Element {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [passwordConfirm, setPasswordConfirm] = React.useState("");

  const defaultScreenMode = "signup";
  const [screenMode, setScreenMode] = React.useState(defaultScreenMode);
  const [loadingState, setLoadingState] = React.useState({
    loading: false,
    disabled: false,
  });
  const history = useHistory();

  const isPhone = useIsPhone();

  const dismissGroupJoinToast = useGroupJoinRequest();

  useHideTabBar();

  React.useEffect(() => {
    // Re-initialize localStorage and IDB.
    // Any version difference detection and work should be done during app init.
    localStorage.clear();
    localStorage["AnkiApp.version"] = Globals.version;
    localStorage["AnkiApp.database.schema"] = Globals.dbSchemaVersion;
    const clearIDB = async () => {
      await idb.deleteDB(idb.db);
      await idb.deleteDB(idb.blobs);
    };
    clearIDB();
  }, []);

  React.useEffect(() => {
    function handleBackButton() {
      (navigator as any).app.exitApp();
    }
    document.addEventListener("backbutton", handleBackButton, false);

    return () => {
      document.removeEventListener("backbutton", handleBackButton, false);
    };
  }, []);

  function handleAuthFailure(message: string) {
    showPrompt({
      prompt: message,
      promptType: "alert",
      title: L10n.localize((s) => s.general.attention),
    });

    setLoadingState({ disabled: false, loading: false });
  }

  function handleLogin(email: string, password: string) {
    dismissGroupJoinToast();
    setLoadingState({ disabled: true, loading: true });

    User.authorize("login", email, password)
      .then(async (response: ILoginResponse) => {
        try {
          await User.doLogin(response);
          setLoadingState({ disabled: false, loading: false });
          history.replace("/home");
        } catch {
          handleAuthFailure(L10n.localize((s) => s.error.internal));
        }
      })
      .catch((e) => {
        if (e?.statusCode === 0) {
          return handleAuthFailure(L10n.localize((s) => s.auth.error));
        }
        if (e?.statusCode === 400) {
          // bad request (account already exists?)
          return handleAuthFailure(L10n.localize((s) => s.auth.error400));
        }
        if (e?.statusCode === 401) {
          return handleAuthFailure(L10n.localize((s) => s.auth.error401));
        }
        if (e?.statusCode === 403) {
          setLoadingState({ disabled: false, loading: false });
          return handleAuthFailure(L10n.localize((s) => s.auth.error));
        }
        if (e?.statusCode === 404) {
          return handleAuthFailure(L10n.localize((s) => s.auth.error404));
        }
        if (e?.statusCode === 500) {
          // bad gateway (invalid response from the upstream server)
          return handleAuthFailure(L10n.localize((s) => s.auth.error502));
        }
        if (e?.statusCode === 502) {
          // bad gateway (invalid response from the upstream server)
          return handleAuthFailure(L10n.localize((s) => s.auth.error502));
        }
        if (e?.statusCode === 503) {
          // service unavailable
          return handleAuthFailure(L10n.localize((s) => s.auth.error503));
        }
        return handleAuthFailure(L10n.localize((s) => s.auth.error));
      });
  }

  function handleSignup(email: string, password: string) {
    dismissGroupJoinToast();
    setLoadingState({ disabled: true, loading: true });

    // TODO: response type should be ICreateUserResponse
    User.authorize("create", email, password)
      .then((response: ILoginResponse) => {
        User.doLogin(response)
          .then(() => {
            setLoadingState({ disabled: false, loading: false });
            history.push("/home");
          })
          .catch(() => {
            const errCode = "E0002"; // This is totally arbitrary; should just be distinct from others to help us debug.
            handleAuthFailure(`${L10n.localize((s) => s.error.communication)} [${errCode}]`);
          });
      })
      .catch((e) => {
        if (e?.statusCode === 409) {
          handleAuthFailure(L10n.localize((s) => s.auth.error409));
        } else if (e?.statusCode === 400) {
          handleAuthFailure(L10n.localize((s) => s.auth.signupError400));
        } else {
          const message = `${L10n.localize((s) => s.auth.error)} [${e?.statusCode}]`;
          handleAuthFailure(message);
        }
      });
  }

  const containerStyle = {
    alignItems: "center",
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    textAlign: "center",
    top: 0,
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%23d1d5db' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E")`,
  } as React.CSSProperties;

  const innerStyle = {
    alignItems: "center",
    borderRadius: "10px",
    border: "1px solid #DDD",
    maxWidth: "40rem",
    minWidth: 280,
    minHeight: 550, // Prevents UI jump when switching between signup/login.
    backgroundColor: Style.colors.backgroundColor,
    padding: isPhone ? "1.2rem" : "1.2rem 32px",
    transition: "opacity 1500ms",
  } as React.CSSProperties;

  const contentStyle = { textAlign: "center" } as React.CSSProperties;

  let centerContent: any;
  switch (screenMode) {
    case "login":
      centerContent = (
        <Login
          disabled={loadingState.disabled}
          onLogin={handleLogin}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
        />
      );
      break;
    case "signup":
      centerContent = (
        <Signup
          disabled={loadingState.disabled}
          onSignup={handleSignup}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          passwordConfirm={passwordConfirm}
          setPasswordConfirm={setPasswordConfirm}
        />
      );
      break;
  }

  const content = (
    <div style={containerStyle}>
      <div style={innerStyle}>
        {loadingState.loading ? (
          <LoadingIndicator eventName="loginResponsesInsertedBatch" />
        ) : undefined}
        <Logo />
        <IonSegment
          style={{ marginBottom: 12, marginTop: 8 }}
          value={screenMode}
          onIonChange={(e: CustomEvent<SegmentChangeEventDetail>) => {
            const val = e.detail.value;
            setScreenMode(typeof val === "string" ? val : defaultScreenMode);
          }}
        >
          <IonSegmentButton value="signup">
            {L10n.localize((s) => s.account.signUp)}
          </IonSegmentButton>
          <IonSegmentButton value="login">{L10n.localize((s) => s.account.logIn)}</IonSegmentButton>
        </IonSegment>
        <div style={contentStyle}>{centerContent}</div>
        <Support />
        <Version />
      </div>
    </div>
  );

  return <ScreenComponent authRequired={false} content={content} />;
}
