export type ReviewHistory = Map<string, number>;

export function reviewStreak(
  hist?: ReviewHistory | undefined | null,
  studyGoal = 0,
  today: string = new Date().toISOString().substring(0, 10),
): number {
  if (!hist) {
    return Number.NaN;
  }
  if (hist.size === 0) {
    return 0;
  }

  // Convert a string to a Date object
  const toDateObject = (dateString: string): Date => {
    return new Date(dateString);
  };

  // Format a Date object to a string 'YYYY-MM-DD'
  const formatDate = (date: Date): string => {
    return date.toISOString().substring(0, 10);
  };

  // Subtract days from a date string
  const subtractDays = (dateString: string, days: number): string => {
    const date = toDateObject(dateString);
    date.setDate(date.getDate() - days);
    return formatDate(date);
  };

  const effectiveStudyGoal = studyGoal > 0 ? studyGoal : 1;

  const yesterday = subtractDays(today, 1);
  if (
    (!hist.has(today) || (hist.get(today) || 0) < effectiveStudyGoal) &&
    (!hist.has(yesterday) || (hist.get(yesterday) || 0) < effectiveStudyGoal)
  ) {
    return 0;
  }

  let streak = hist.has(today) && hist.get(today)! >= effectiveStudyGoal ? 1 : 0;

  let dateToCheck = yesterday;
  while (hist.has(dateToCheck) && hist.get(dateToCheck)! >= effectiveStudyGoal) {
    streak += 1;
    dateToCheck = subtractDays(dateToCheck, 1);
  }

  return streak;
}
