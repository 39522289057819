import IDB from "@data/idb";
import { AlertInput, useIonAlert } from "@ionic/react";
import { Deck, IDeck } from "@models/deck";
import L10n from "localization";
import useErrorAlert from "./useErrorAlert";

export default function useLocalPrivateDeckSelect({
  onSelect,
  header,
  message,
}: {
  onSelect: (deck: IDeck) => void;
  header: string;
  message?: string;
}): () => Promise<void> {
  const [showDeckSelect] = useIonAlert();
  const [showError] = useErrorAlert({ code: "LOADING_DECKS" });
  async function selectDeck() {
    try {
      const allDecks = await IDB.db.getAll("decks");
      const decks = allDecks
        .filter((deck) => deck.local && deck.status === Deck.STATUS_PRIVATE)
        .sort((d1, d2) => (d1.modified_at < d2.modified_at ? -1 : 1));

      const inputs: AlertInput[] = decks.map((deck) => {
        return {
          type: "radio",
          name: deck.name,
          value: deck,
          label: deck.name,
          checked: decks.length === 0,
        };
      });

      const noDecks = decks.length < 1;

      showDeckSelect({
        header,
        message: noDecks ? L10n.localize((s) => s.deck.noneEditableLocal) : message,
        inputs,
        buttons: [
          L10n.localize((s) => s.actions.cancel),
          {
            text: L10n.localize((s) => s.actions.ok),
            handler: (deck: IDeck) => {
              onSelect(deck);
            },
          },
        ],
      });
    } catch (err) {
      showError(err);
    }
  }

  return selectDeck;
}
