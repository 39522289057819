import {
  IonButton,
  IonItem,
  IonList,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  SelectCustomEvent,
  TextareaCustomEvent,
} from "@ionic/react";
import { time, timeOutline } from "ionicons/icons";
import L10n from "localization";
import React, { useCallback, useEffect, useState } from "react";
import { LuPaperclip, LuWand2 } from "react-icons/lu";

const PROMPT_HISTORY_KEY = "promptHistory";

interface IProps {
  disabled: boolean;
  maxChars: number;
  prompt: string | null | undefined;
  setPrompt: (prompt: string | null | undefined) => void;
  generate: () => void;
  pickPhoto: () => void;
  enableText: boolean | undefined;
  enablePhoto: boolean | undefined;
}

export default function PromptInput({
  disabled,
  prompt,
  setPrompt,
  generate,
  maxChars,
  pickPhoto,
  enableText,
  enablePhoto,
}: IProps) {
  const [promptHistory, setPromptHistory] = useState<{ prompt: string; timestamp: number }[]>([]);
  const [selectValue, setSelectValue] = useState<string | null>(null); // control prompt IonSelect value

  useEffect(() => {
    const storedHistory = localStorage.getItem(PROMPT_HISTORY_KEY);
    if (storedHistory) {
      try {
        setPromptHistory(JSON.parse(storedHistory));
      } catch (err) {
        // Catch of shame.
      }
    }
  }, []);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      // Check if CMD (or CTRL for non-Mac users) is pressed along with ENTER
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        handleGenerate();
        return;
      }
    },
    [generate],
  );

  const handleGenerate = () => {
    if (prompt) {
      const existingIndex = promptHistory.findIndex((entry) => entry.prompt === prompt);

      if (existingIndex !== -1) {
        const updatedHistory = [...promptHistory];
        updatedHistory[existingIndex] = { prompt, timestamp: Date.now() };
        setPromptHistory(updatedHistory);
        localStorage.setItem(PROMPT_HISTORY_KEY, JSON.stringify(updatedHistory));
      } else {
        const newPromptHistory = [{ prompt, timestamp: Date.now() }, ...promptHistory.slice(0, 49)];
        setPromptHistory(newPromptHistory);
        localStorage.setItem(PROMPT_HISTORY_KEY, JSON.stringify(newPromptHistory));
      }
    }
    generate();
  };

  const handleSelectHistory = (selectedPrompt: string | null | undefined) => {
    setPrompt(selectedPrompt);
    setSelectValue(null);
  };

  return (
    <IonList>
      <IonItem lines="none" className="prompt-input-item">
        <IonButton
          slot="start"
          aria-label="attach"
          size="default"
          fill="clear"
          disabled={disabled || !enablePhoto}
          onClick={pickPhoto}
          style={{ "--padding-start": 0, "--padding-end": 0 }}
        >
          <LuPaperclip size={24} />
        </IonButton>
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <IonTextarea
            rows={1}
            autoGrow
            placeholder={L10n.localize((s) => s.general.promptPlaceholderText)}
            value={prompt}
            onIonInput={(e: TextareaCustomEvent) => setPrompt(e.detail.value)}
            disabled={disabled || !enableText}
            onKeyDown={handleKeyDown}
            counter
            maxlength={maxChars}
            style={{ marginBottom: 8, maxHeight: 240, overflowY: "auto" }}
          />
          <IonSelect
            className="gen-history"
            label={L10n.localize((s) => s.general.history)}
            value={selectValue}
            placeholder=""
            toggleIcon={timeOutline}
            expandedIcon={time}
            onIonChange={(e: SelectCustomEvent) => {
              handleSelectHistory(e.detail.value);
            }}
            disabled={disabled || promptHistory.length === 0}
            style={{ marginLeft: 8, maxWidth: "150px" }}
          >
            {promptHistory.map((entry, idx) => (
              <IonSelectOption key={idx} value={entry.prompt} className="genPopup">
                {`${entry.prompt} (${new Date(entry.timestamp).toLocaleString()})`}
              </IonSelectOption>
            ))}
          </IonSelect>
        </div>
        <IonButton
          slot="end"
          aria-label={L10n.localize((s) => s.actions.generate)}
          size="default"
          onClick={handleGenerate}
          disabled={disabled}
          style={{ marginLeft: 8 }}
        >
          <LuWand2 size={24} />
        </IonButton>
      </IonItem>
    </IonList>
  );
}
