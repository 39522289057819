import { IonItem, IonLabel } from "@ionic/react";
import { IField } from "fields/fields";
import FieldTypeEditorIcon from "fields/valueEditors/fieldTypeEditorIcon";
import PasteButton from "fields/valueEditors/pasteButton";
import { isMacCatalyst } from "globals";
import { useIsPhoneImmediately } from "hooks/util/useMediaQuery";
import cyAttr from "lib/cyAttr";
import React from "react";
import Style from "style";
import FieldTypeIcon from "./fieldTypeIcon";

const defaultStyle: React.CSSProperties = {
  paddingTop: 4,
};

const richTextStyle = {
  ...defaultStyle,
  "--padding-start": 0,
  "--padding-end": 0,
  "--inner-padding-start": 0,
  "--inner-padding-end": 0,
};

function fieldLang(field: IField | undefined): string | undefined {
  switch (field?.type) {
    case "text":
    case "tts":
    case "code":
    case "chinese":
      return field?.attributes?.lang;
  }
}

export function fieldNameWithLang(field: IField | undefined, name: string): string {
  const lang = fieldLang(field);
  return `${name} ${lang ? ` (${lang})` : ""}`;
}

interface IProps extends React.ComponentProps<typeof IonItem> {
  field: IField | undefined;
  name: string;
  editorRef?: React.RefObject<HTMLIonItemElement>;
  onTextPaste?: (text: string) => void;
}
export default function FieldValueEditorItem(props: IProps): JSX.Element {
  const { field, name, children, onTextPaste } = props;

  const isPhone = useIsPhoneImmediately();

  const isLegacy = !field;

  const showPasteButton = onTextPaste !== undefined && !isMacCatalyst();
  const widen = field?.type === "richtext" && isPhone;

  return (
    <IonItem
      {...props}
      ref={props.editorRef}
      {...cyAttr(`field-editor-${name}`)}
      style={widen ? richTextStyle : defaultStyle}
      lines={field?.type === "richtext" ? "none" : undefined}
    >
      <div style={{ display: "flex", paddingLeft: widen ? 12 : undefined }}>
        {isLegacy ? (
          <FieldTypeIcon type="richtext" small />
        ) : (
          <FieldTypeEditorIcon field={field} small />
        )}
        <IonLabel
          position="stacked"
          style={{
            fontVariant: "small-caps",
            color: Style.colors.secondaryFg,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 0, // HACK: this makes the text align nicely with the icon.
          }}
        >
          {fieldNameWithLang(field, name)}
        </IonLabel>
      </div>
      {children}
      {showPasteButton ? <PasteButton onPaste={onTextPaste} /> : undefined}
    </IonItem>
  );
}
