import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import { IFieldRichText } from "fields/types/richText";
import React, { useCallback } from "react";
import RichTextEditor from "./richTextEditor";

interface IProps extends Omit<IFieldValueEditorProps<IFieldRichText>, "field"> {
  field: IFieldRichText | undefined;
}
export default function FieldValueEditorRichText(props: IProps): JSX.Element {
  const { name, onChange, field } = props;

  const handleChange = useCallback(
    (val: string) => {
      onChange(name, val);
    },
    [name, onChange],
  );

  return (
    <FieldValueEditorItem name={name} field={field} editorRef={props.editorRef}>
      <RichTextEditor {...props} onChange={handleChange} />
    </FieldValueEditorItem>
  );
}
