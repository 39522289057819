import DeckDownloadManager from "deckDownloadManager";
import EventBus from "eventBus";
import { useEffect, useState } from "react";
import { ID } from "types/ID";

export type DeckDownloadState = "unknown" | "downloading" | "complete";
export default function useDeckDownloadState(deckID: ID): DeckDownloadState {
  const downloading = DeckDownloadManager.isDownloading(deckID);
  const [state, setState] = useState<DeckDownloadState>(downloading ? "downloading" : "unknown");
  useEffect(() => {
    function updateStatus() {
      const downloading = DeckDownloadManager.isDownloading(deckID);
      setState(downloading ? "downloading" : "unknown");
    }

    function setComplete(evt: { deckID: ID }) {
      if (evt.deckID === deckID) {
        setState("complete");
      }
    }

    EventBus.on("deckDownloadStatusChange", updateStatus);
    EventBus.on("deckDownloadComplete", setComplete);
    return () => {
      EventBus.off("deckDownloadStatusChange", updateStatus);
      EventBus.off("deckDownloadComplete", setComplete);
    };
  }, [deckID]);
  return state;
}
