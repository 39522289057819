import { DotID, useClearedAtAndRecordImpression } from "dots";
import React, { useState } from "react";

const maxDelayMillis = 150;

export function Dot({
  style,
  size = 12,
  color = "var(--ion-color-primary)",
  slot,
}: {
  style?: React.CSSProperties;
  size?: number;
  color?: React.CSSProperties["backgroundColor"];
  slot?: string;
}) {
  return (
    <div
      slot={slot}
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        backgroundColor: color,
        boxShadow: "rgba(0, 0, 0, 0.15) -1.5px 1.5px 2px",
        ...style,
      }}
    />
  );
}

interface IProps {
  id: DotID;
  numBounces?: number;
  slot?: "start" | "end";
}
export default function InfoDot({ id, numBounces = 1, slot = "start" }: IProps) {
  const [animationDelay] = useState(Math.floor(Math.random() * (maxDelayMillis + 1)));
  const { loading, val: clearedAt } = useClearedAtAndRecordImpression(id);
  if (loading || clearedAt) {
    return null;
  }
  return (
    <Dot
      style={{
        animation: "bounce 250ms ease-in-out",
        animationDelay: `${animationDelay}ms`,
        animationIterationCount: numBounces,
        // animationDuration: "400ms",
        marginRight: 4,
      }}
    />
  );
}
