import FieldEditorGeneric from "fields/editors/fieldEditorGeneric";
import { IBaseField } from "fields/lib";
import { IFieldType } from "fields/fieldType";
import { parseFML, serializeFML } from "fields/fml";
import FieldValueEditorAudio from "fields/valueEditors/fieldValueEditorAudio";
import AudioValue from "fields/values/audioValue";
import L10n from "localization";
import { FaRegFileAudio } from "react-icons/fa";
import { parseSides } from "fields/sides";

export const sampleAudioID = "$$SAMPLE$$";
export const sampleAudioType = "audio/mp3";
const sampleAudioFilename = "FurElise.mp3";
export const sampleAudioURL = "/" + sampleAudioFilename;

export const audioFieldType: IFieldType<"audio"> = {
  name: () => L10n.localize((s) => s.field.typeAudio),
  description: () => L10n.localize((s) => s.field.typeAudioDescription),
  icon: FaRegFileAudio,
  sampleValue() {
    return this.dumpFML({ id: sampleAudioID, type: sampleAudioType });
  },
  fmlTag: "audio",
  type: "audio",
  parseField(attrs, srcs): IFieldAudio {
    return {
      name: attrs["name"],
      type: this.type,
      sides: parseSides(attrs["sides"]),
      source: srcs[0],
    };
  },

  loadFML(fml) {
    const res = parseFML({ tagName: this.fmlTag, attrList: ["id", "type"], fml, requireTag: true });
    if (!res) {
      return null;
    }
    const { attrs } = res;
    if (!attrs?.id || !attrs?.type) {
      return null;
    }
    return { id: attrs.id, type: attrs.type };
  },

  dumpFML({ id, type }) {
    return serializeFML({ tagName: this.fmlTag, attrs: { id, type } });
  },

  updateValue(field, value) {
    return Promise.resolve(this.dumpFML(value));
  },

  valueComponent: AudioValue,
  editorComponent: FieldEditorGeneric,
  valueEditorComponent: FieldValueEditorAudio,

  convert(field) {
    return { ...field, type: this.type, attributes: undefined };
  },
};

export interface IFieldAudio extends IBaseField {
  type: "audio";
}

export interface IAudioFieldValue {
  id: string;
  type: string;
}
