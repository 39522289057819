import { IDeckOrFolderRow, RowType } from "hooks/data/deckList";
import { SortOptions } from "./types";

interface ISortable {
  name: string;
  timestamp?: Date;
  modified_at?: Date;
}

export default function sortRows<T extends ISortable>(
  order: SortOptions | null | undefined,
  rows: T[],
): T[] {
  function parseName(name: string): { prefix: string; number: number | null } {
    const match = name.match(/^(\D*)(\d*)$/);
    const prefix = match ? match[1] : name; // Everything before a number.
    const number = match && match[2] ? Number.parseInt(match[2], 10) : null; // The numerical part, if any.
    return { prefix, number };
  }

  function sortByName(a: ISortable, b: ISortable, doReverse = false): number {
    const parsedA = parseName(a.name);
    const parsedB = parseName(b.name);

    if (parsedA.prefix < parsedB.prefix) return doReverse ? 1 : -1;
    if (parsedA.prefix > parsedB.prefix) return doReverse ? -1 : 1;

    if (parsedA.number !== null && parsedB.number !== null) {
      if (parsedA.number < parsedB.number) return doReverse ? 1 : -1;
      if (parsedA.number > parsedB.number) return doReverse ? -1 : 1;
    }

    return 0;
  }

  function isDeckRow(obj: any): obj is IDeckOrFolderRow {
    return "type" in obj && typeof obj.type === "string";
  }

  function sortByTypeAndName<T extends ISortable>(
    rows: T[],
    typeOrder: RowType[],
    nameOrder: boolean,
  ): T[] {
    return rows.sort((a, b) => {
      if (isDeckRow(a) && isDeckRow(b)) {
        const typeIndexA = typeOrder.indexOf(a.type);
        const typeIndexB = typeOrder.indexOf(b.type);
        if (typeIndexA !== typeIndexB) {
          return typeIndexA - typeIndexB;
        }
      }
      return sortByName(a, b, !nameOrder);
    });
  }

  function sortByTimestamp(a?: Date, b?: Date, doReverse = false) {
    if (!a && !b) {
      return 0;
    }
    if (!a) {
      return doReverse ? 1 : -1;
    }
    if (!b) {
      return doReverse ? -1 : 1;
    }
    if (a < b) {
      return doReverse ? 1 : -1;
    }
    if (a > b) {
      return doReverse ? -1 : 1;
    }
    return 0;
  }

  function sortByLastSeen<T extends ISortable>(decks: T[], oldestToNewest: boolean) {
    return decks.sort((a, b) => {
      return sortByTimestamp(a.timestamp, b.timestamp, oldestToNewest);
    });
  }

  function sortByModifiedAt<T extends ISortable>(decks: T[], oldestToNewest: boolean) {
    return decks.sort((a, b) => {
      const r = sortByTimestamp(a.modified_at, b.modified_at, oldestToNewest);
      if (r === 0) {
        return sortByName(a, b, oldestToNewest);
      }
      return r;
    });
  }

  switch (order) {
    case "modifiedAtAsc":
      return sortByModifiedAt(rows, true);
    case "modifiedAtDesc":
      return sortByModifiedAt(rows, false);
    case "lastSeenAsc":
      return sortByLastSeen(rows, true);
    case "lastSeenDesc":
      return sortByLastSeen(rows, false);
    case "alphaAsc":
      return rows.sort((a, b) => sortByName(a, b, false));
    case "alphaDesc":
      return rows.sort((a, b) => sortByName(a, b, true));
    case "foldersFirstAsc":
      return sortByTypeAndName(rows, ["folder", "deck"], true);
    case "foldersFirstDesc":
      return sortByTypeAndName(rows, ["folder", "deck"], false);
    case "decksFirstAsc":
      return sortByTypeAndName(rows, ["deck", "folder"], false);
    case "decksFirstDesc":
      return sortByTypeAndName(rows, ["deck", "folder"], true);
    default:
      return rows;
  }
}
