import React from "react";
import { InputCustomEvent, IonIcon, IonInput, IonItem } from "@ionic/react";
import { AutocompleteTypes, TextFieldTypes } from "@ionic/core";
import { helpCircleOutline } from "ionicons/icons";

interface IProps {
  id?: string;
  icon: string;
  disabled?: boolean;
  value: string | number;
  placeholder: string;
  type: TextFieldTypes;
  autocomplete: AutocompleteTypes;
  onChange: (v: string) => void;
  onSubmit?: (e: any) => void;
}

export default function IconInput({
  id,
  icon = helpCircleOutline,
  disabled = false,
  placeholder,
  type,
  autocomplete,
  value,
  onChange,
  onSubmit,
}: IProps): JSX.Element {
  const [isFocused, setIsFocused] = React.useState(false);

  const handleChange = (event: InputCustomEvent): void => {
    const newVal = event.detail.value;
    if (newVal) {
      onChange(newVal);
    }
  };

  const handleFocus = (): void => {
    setIsFocused(true);
  };

  const handleBlur = (): void => {
    setIsFocused(false);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLIonInputElement>) => {
    if (e.key === "Enter") {
      onSubmit?.(e);
    }
  };

  return (
    <IonItem id={id}>
      <IonIcon icon={icon} slot="start" size="small" color={isFocused ? "primary" : "medium"} />
      <IonInput
        style={{ flex: "1 0 auto", paddingLeft: 6 }}
        disabled={disabled}
        type={type}
        autocomplete={autocomplete}
        autoCapitalize="off"
        autocorrect="off"
        value={value}
        onKeyUp={handleKeyUp}
        onIonFocus={handleFocus}
        onIonBlur={handleBlur}
        onIonInput={handleChange}
        placeholder={placeholder}
      />
    </IonItem>
  );
}
