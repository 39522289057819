const DELETE = (): Promise<void> => {
  return Promise.resolve();
};

const INSERT = (): Promise<void> => {
  // do nothing
  return Promise.resolve();
};

export { DELETE, INSERT };
