import CodeEditor from "@components/codeEditor";
import { IonItem, IonList, IonListHeader } from "@ionic/react";
import L10n from "localization";
import React from "react";

interface EditLayoutTemplatesProps {
  editable?: boolean;
  templates?: string[];
  onTemplatesChange?: (templates: string[]) => void;
}

const EditLayoutTemplates: React.FC<EditLayoutTemplatesProps> = ({
  editable = false,
  templates = ["", ""],
  onTemplatesChange = (templates: string[]) => {},
}) => {
  return (
    <IonList>
      <IonListHeader>{L10n.localize((s) => s.layout.frontStructure)}</IonListHeader>
      <IonItem>
        <CodeEditor
          mode="htmlmixed"
          theme="solarized"
          lineNumbers={true}
          readOnly={!editable}
          onChange={(val) => {
            onTemplatesChange([val, templates[1]]);
          }}
          value={templates[0]}
        />
      </IonItem>
      <IonListHeader>{L10n.localize((s) => s.layout.backStructure)}</IonListHeader>
      <IonItem>
        <CodeEditor
          mode="htmlmixed"
          theme="solarized"
          lineNumbers={true}
          readOnly={!editable}
          onChange={(val) => {
            onTemplatesChange([templates[0], val]);
          }}
          value={templates[1]}
        />
      </IonItem>
    </IonList>
  );
};

export default EditLayoutTemplates;
