import idb, { minDateString } from "@data/idb";
import { Operation } from "@models/operation";
import { IResponseInsertOperation } from "@operations/response";
import { IResponseHistory } from "hooks/data/responseHistory";
import { updateStudyGoalBadgeCount } from "studyGoals";
import { ID } from "types/ID";
import moment from "../../../node_modules/moment/moment";
import Device from "../device";
import { IKnol, Knol } from "./knol";

export interface IResponse {
  device_id: string;
  knol_id: string;
  deck_id: string;
  layout_id: string;
  duration_ms: number;
  created_at: string;
  score: number;
  score_mean: number | null;
  score_standard_deviation: number | null;
  last_response_at: string | null;
}

export function dateToYYYYMMDD(date: Date): string {
  const yyyy = date.getFullYear();
  const mm = (date.getMonth() + 1).toString().padStart(2, "0");
  const dd = date.getDate().toString().padStart(2, "0");
  return `${yyyy}-${mm}-${dd}`;
}

export namespace Response {
  export async function record(
    knol: IKnol,
    layoutID: ID,
    score: number,
    duration: number,
  ): Promise<void> {
    let prevTime: string | null;
    let prevMean: number | null;
    let prevStandardDeviation: number | null;
    const kwg = Knol.GetGrade(knol);

    if (kwg.last_response_at) {
      prevTime = moment.utc(kwg.last_response_at).format();
    } else {
      prevTime = null;
    }

    if (kwg.score_mean) {
      prevMean = kwg.score_mean;
    } else {
      prevMean = null;
    }

    if (kwg.score_standard_deviation) {
      prevStandardDeviation = kwg.score_standard_deviation;
    } else {
      prevStandardDeviation = null;
    }

    const now = new Date().toISOString();

    const r = {
      device_id: Device.getID(),
      knol_id: knol.id,
      deck_id: knol.deck_id,
      layout_id: layoutID,
      duration_ms: duration,
      created_at: now,
      score,
      score_mean: prevMean,
      score_standard_deviation: prevStandardDeviation,
      last_response_at: prevTime,
    };

    const op: IResponseInsertOperation = {
      ...Operation.operationDefaults(),
      type: "INSERT",
      object_type: "response",
      object_parameters: r,
    };

    await Operation.operateAndSave(op);
    updateStudyGoalBadgeCount();
  }

  export async function AllHistory(): Promise<IResponseHistory> {
    const tx = idb.db.transaction("responses", "readonly");
    const store = tx.objectStore("responses");
    const index = store.index("created_at");

    const history: IResponseHistory = new Map();

    function processRow(resp: IResponse) {
      const createdAtDate = new Date(resp.created_at);
      const dateStr = dateToYYYYMMDD(createdAtDate);

      let dateMap = history.get(dateStr);
      if (!dateMap) {
        dateMap = new Map();
        history.set(dateStr, dateMap);
      }

      const deckId = resp.deck_id;
      const deckCount = dateMap.get(deckId) ?? 0;
      dateMap.set(deckId, deckCount + 1);
    }

    const batchSize = 1000;
    let batch = await index.getAll(IDBKeyRange.lowerBound(minDateString), batchSize);
    while (batch.length > 0) {
      for (const resp of batch) {
        processRow(resp);
      }
      const last = batch[batch.length - 1];
      batch = await index.getAll(IDBKeyRange.lowerBound(last.created_at, true), batchSize);
    }

    await tx.done;
    return history;
  }

  export async function totalCount() {
    return idb.db.count("responses");
  }
}
