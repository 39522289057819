import { IonSpinner } from "@ionic/react";
import cyAttr from "lib/cyAttr";
import React from "react";

export default function InlineLoadingSpinner(props: {
  margin?: string;
  size?: number;
}): JSX.Element {
  return (
    <IonSpinner
      name="crescent"
      color="primary"
      style={{
        background: "rgba(210, 210, 210, 0.1)",
        borderRadius: 16,
      }}
      key="inline-loading-spinner"
      {...cyAttr("inline-loading-spinner")}
    />
  );
}
