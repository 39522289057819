import logEvent from "analytics";
import Globals, { isMacAppStoreElectron } from "globals";
import Style from "style";

export default class PDPLogger {
  gateway: "stripe" | "apple" | null = null;
  reason: string | undefined;
  source: string;
  context: Record<string, unknown>;

  constructor(reason: string | undefined, source: string, context: Record<string, unknown> = {}) {
    switch (Globals.platform) {
      case "web":
        this.gateway = "stripe";
        break;
      case "ios":
        this.gateway = "apple";
        break;
    }

    if (isMacAppStoreElectron()) {
      this.gateway = "apple";
    }

    this.reason = reason;
    this.source = source;
    this.context = context;
  }

  log(step: string, extra: Record<string, unknown> = {}) {
    const eventName = "purchase_funnel_event";
    logEvent(eventName, {
      ...extra,
      ...this.context,
      reason: this.reason,
      source: this.source,
      gateway: this.gateway,
      colorTheme: Style.currentTheme,
      step,
    });
  }
}
