import React, { useCallback, useEffect, useRef, useState } from "react";

interface IState {
  fullWidth: number | undefined;
  initWidth: number | undefined;
}

const backwardsChars: { [key: string]: string } = {
  "(": ")",
  ")": "(",
  "{": "}",
  "}": "{",
  "[": "]",
  "]": "[",
  "「": "」",
  "」": "「",
  "<": ">",
  ">": "<",
};

function reverseText(text: string | undefined): string {
  if (!text) {
    return "";
  }
  return text
    .split("")
    .map((char) => {
      return backwardsChars[char] ?? char;
    })
    .reverse()
    .join("");
}

function MiddleEllipse({ text, style }: { text?: string; style?: React.CSSProperties }) {
  const leftRef = useRef<HTMLDivElement>(null);

  const [state, setState] = useState<IState>({ fullWidth: undefined, initWidth: undefined });
  const { fullWidth, initWidth } = state;

  const overflow = initWidth && fullWidth && fullWidth > initWidth;

  const sharedStyle: React.CSSProperties = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    padding: 0,
    margin: 0,
    textOverflow: "ellipsis",
    width: overflow ? fullWidth / 2 : undefined,
  };

  const setDimensions = useCallback(() => {
    setState({
      fullWidth: leftRef.current?.scrollWidth,
      initWidth: leftRef.current?.offsetWidth,
    });
  }, []);
  useEffect(() => {
    if (!leftRef.current) {
      return;
    }
    const observer = new ResizeObserver(setDimensions);
    observer.observe(leftRef.current);
    return () => observer.disconnect();
  }, [leftRef.current]);
  useEffect(() => {
    setDimensions();
  }, [text]);

  return (
    <div style={{ display: "flex", flexDirection: "row", ...style }}>
      <div ref={leftRef} style={sharedStyle}>
        {text}
      </div>
      {overflow ? (
        <div
          style={{
            ...sharedStyle,
            ...{
              direction: "rtl",
              unicodeBidi: "bidi-override",
              textOverflow: "clip",
            },
          }}
        >
          {reverseText(text)}
        </div>
      ) : undefined}
    </div>
  );
}

export default MiddleEllipse;
