import SortMenuButton from "@components/selectOptionButton";
import { IonButton, IonList, IonListHeader, IonProgressBar } from "@ionic/react";
import Disconnected from "components/disconnected";
import Empty from "components/empty";
import { IDeckOrFolderRow } from "hooks/data/deckList";
import L10n from "localization";
import React from "react";
import Style from "style";
import DeckListItem from "./deckListItem";
import { SortOptions, sortMenuOptions, sortOptionStringFromKey } from "./types";

export default function LocalAndRemoteDecks(props: {
  sortOrder?: SortOptions;
  loadingLocal: boolean;
  loadingRemote: boolean;
  sortedRows: IDeckOrFolderRow[];
  totalRows: number;
  handleSort: (s: SortOptions) => void;
  errorLoadingRemoteDecks: boolean;
  presentGetStartedModal: () => void;
}) {
  const { loadingLocal, loadingRemote, sortedRows, totalRows, errorLoadingRemoteDecks } = props;

  if (loadingLocal && loadingRemote) {
    return <IonProgressBar type="indeterminate" />;
  }

  if (!loadingLocal && !loadingRemote && !errorLoadingRemoteDecks) {
    if (totalRows < 1) {
      return (
        <>
          <Empty marginTop="1rem" marginBottom="1rem" text={L10n.localize((s) => s.deck.none)} />
          <IonButton fill="clear" expand="full" onClick={props.presentGetStartedModal}>
            {L10n.localize((s) => s.getStarted.getStarted)}
          </IonButton>
        </>
      );
    }
    if (sortedRows.length < 1) {
      // No search results.
      return (
        <Empty
          marginTop="1rem"
          marginBottom="1rem"
          text={L10n.localize((s) => s.search.noResults)}
        />
      );
    }
  }

  const remoteMessage = loadingRemote ? (
    <IonProgressBar type="indeterminate" />
  ) : errorLoadingRemoteDecks ? (
    <Disconnected
      text={L10n.localize((s) => s.deck.remoteOffline)}
      style={{
        paddingLeft: Style.edgePadding,
        paddingRight: Style.edgePadding,
        marginTop: 24,
      }}
    />
  ) : null;

  const items = sortedRows.map((r) => (
    <DeckListItem key={r.type + (r.type === "deck" ? r.deck.id : r.name)} row={r} />
  ));

  return (
    <IonList>
      {props.sortOrder && (
        <IonListHeader
          lines="full"
          style={{
            color: Style.colors.mutedFg,
            fontSize: "14px",
            marginTop: 4,
            fontWeight: 500,
            flexDirection: "column",
            minHeight: "auto",
          }}
        >
          <SortMenuButton
            options={sortMenuOptions()}
            value={props.sortOrder}
            onSelect={props.handleSort}
            style={{ margin: "4px" }}
          >
            {sortOptionStringFromKey(props.sortOrder)}
          </SortMenuButton>
        </IonListHeader>
      )}
      {items}
      {remoteMessage}
    </IonList>
  );
}
