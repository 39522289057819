import { IonList, IonProgressBar } from "@ionic/react";
import L10n from "localization";
import React, { useCallback, useEffect, useState } from "react";
import Empty from "../empty";
import FolderTreeNodeListItem, { hierarchifyFolders } from "./folderTreeNodeListItem";
import NewFolderItem from "./newFolderItem";
import NoFolderItem from "./noFolderItem";

interface IProps {
  loading?: boolean;
  folders: string[];
  selectedFolders: string[];
  onClick: (folder: string) => void;
  onAddFolder?: (folder?: string) => void;
}

export default function NestedFolderView({
  loading,
  folders,
  selectedFolders,
  onClick,
  onAddFolder,
}: IProps) {
  // Keep a cache of all folders that have been seen, so they don't disappear as the user changes selection.
  const [allFoldersSeen, setAllFoldersSeen] = useState<string[]>(folders);
  useEffect(() => {
    const set = new Set<string>(allFoldersSeen);
    folders.forEach((folder) => set.add(folder));
    setAllFoldersSeen(Array.from(set));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders]);

  const isSelected = useCallback(
    (fullFolder: string) => {
      return selectedFolders.includes(fullFolder);
    },
    [selectedFolders],
  );

  const tree = hierarchifyFolders(allFoldersSeen);

  const noFolders = !loading && folders.length < 1 && allFoldersSeen.length < 1;

  return (
    <IonList>
      {loading ? <IonProgressBar type="indeterminate" /> : undefined}
      {!loading && onAddFolder ? <NewFolderItem onAdd={onAddFolder} /> : undefined}
      {!loading && onAddFolder ? (
        <NoFolderItem onSelect={onAddFolder} selected={selectedFolders.length < 1} />
      ) : undefined}
      {noFolders ? (
        <Empty
          text={L10n.localize((s) => s.folder.none)}
          style={{ margin: 0, marginTop: "1rem" }}
        />
      ) : undefined}
      {tree.map((node) => {
        return (
          <FolderTreeNodeListItem
            key={node.folder}
            {...node}
            level={0}
            onClick={onClick}
            isSelected={isSelected}
            onAddFolder={onAddFolder}
          />
        );
      })}
    </IonList>
  );
}
