import { useLoadingText } from "@components/loadingText";
import TipDot from "@components/tipDot";
import { IonTextarea, TextareaCustomEvent } from "@ionic/react";
import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import { fieldTypeMap } from "fields/fields";
import useAutoFocusIonTextarea from "fields/hooks/useAutoFocusIonTextarea";
import useSetLangIonTextarea from "fields/hooks/useSetLangIonTextarea";
import useValueEditorSourcePipeline from "fields/sources/useSourcePipeline";
import { IFieldJapanese } from "fields/types/japanese";
import L10n from "localization";
import React, { useRef } from "react";

export default function FieldValueEditorJapanese({
  field,
  name,
  value,
  onChange,
  editorRef,
  autoFocus,
  editable,
}: IFieldValueEditorProps<IFieldJapanese>): JSX.Element {
  const parsedVal = value !== undefined ? fieldTypeMap.japanese.loadFML(value) : undefined;
  const japanese = parsedVal?.japanese ?? "";

  function handleJapaneseChange(newJapanese: string) {
    const fml = fieldTypeMap.japanese.dumpFML({ japanese: newJapanese });
    onChange(name, fml);
  }

  const pipelineUpdatePending = useValueEditorSourcePipeline(field, onChange);
  const loadingText = useLoadingText(
    pipelineUpdatePending,
    L10n.localize((s) => s.general.processing),
  );

  const ref = useRef<HTMLIonTextareaElement>(null);
  useAutoFocusIonTextarea(autoFocus, ref.current);
  useSetLangIonTextarea("ja-JP", ref);

  const disabled = editable === false || pipelineUpdatePending;

  return (
    <>
      <FieldValueEditorItem
        name={name}
        field={field}
        editorRef={editorRef}
        onTextPaste={disabled ? undefined : handleJapaneseChange}
      >
        <IonTextarea
          autoGrow
          ref={ref}
          disabled={disabled}
          value={pipelineUpdatePending ? loadingText : japanese}
          spellCheck
          rows={1}
          placeholder={L10n.localize((s) => s.general.clickToEdit)}
          onIonInput={(evt: TextareaCustomEvent) => {
            if (!editable) {
              return;
            }
            const val = evt.target.value;
            handleJapaneseChange(val ?? "");
          }}
        />
      </FieldValueEditorItem>
      <TipDot
        dotID="japanese_furigana_override"
        message={L10n.localize((s) => s.field.furiganaOverrideTip)}
      />
    </>
  );
}
