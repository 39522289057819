import { IQueuedBlob } from "@data/idb";
import logEvent from "analytics";
import { IBlobDownloaderMessage } from "workers/blobDownloader";
import { IBlobWorkerResult, blobWorkerSuccess } from "./blobNetwork";
import { handleIndexedDBRefreshRequiredErrorIfNecessary } from "errors/indexedDBRefreshRequired";

export default class DownloadPool {
  workers: Promise<void>[];
  queue: IQueuedBlob[] = [];
  deviceID: string;
  deviceToken: string | null;
  tickListener?: () => void; // Gets notified when a task is processed.

  constructor(
    queue: IQueuedBlob[],
    concurrency: number,
    deviceID: string,
    deviceToken: string | null,
    tickListener?: () => void,
  ) {
    this.deviceID = deviceID;
    this.deviceToken = deviceToken;
    this.queue = queue;
    this.workers = new Array(concurrency);
    for (let i = 0; i < concurrency; i++) {
      this.workers[i] = this.worker();
    }
    this.tickListener = tickListener;
  }

  async start(): Promise<void> {
    await Promise.all(this.workers);
  }

  async process(downloader: Worker, item: IQueuedBlob): Promise<void> {
    return new Promise((resolve, reject) => {
      downloader.onmessage = (ev: MessageEvent<IBlobWorkerResult>) => {
        if (ev.data.status === blobWorkerSuccess) {
          resolve();
        } else {
          reject(ev.data.error);
        }
      };

      downloader.onerror = (ev: ErrorEvent) => {
        reject(ev);
      };

      const downloaderMessage: IBlobDownloaderMessage = {
        id: item.id,
        deckID: item.deckID,
        ttsParams: item.ttsParams,
        deviceID: this.deviceID,
        deviceToken: this.deviceToken,
      };
      downloader.postMessage(downloaderMessage);
    });
  }

  async worker(): Promise<void> {
    const downloader = new Worker("/workers/blobDownloader.js");

    while (this.queue.length > 0) {
      const item = this.queue.shift();
      if (!item) {
        continue;
      }
      try {
        await this.process(downloader, item);
      } catch (err) {
        await handleIndexedDBRefreshRequiredErrorIfNecessary(err);

        // Ignore error. Blob will be redownloaded on-demand.
        logEvent("blob_download_failed", {
          err: err instanceof Error ? `${err.name}: ${err.message}` : "???",
          errStack: err instanceof Error ? err.stack : undefined,
          blobID: item.id,
          deckID: item.deckID,
        });
      } finally {
        this.tickListener?.();
      }
    }

    downloader.terminate();
  }
}
