import { IonItem, IonTextarea, TextareaCustomEvent } from "@ionic/react";
import UntrustedHtmlWithLinksPermitted from "components/untrustedHtmlWithLinksPermitted";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import React from "react";

interface IProps {
  description?: string;
  onDeckDescriptionChange: (description?: string) => void;
  isEditable?: boolean;
  lines?: "none" | "full" | "inset" | undefined;
}
export default function DeckDescriptionItem(props: IProps): JSX.Element {
  const { isEditable = false, description, onDeckDescriptionChange, lines } = props;

  const desc = UntrustedHtmlWithLinksPermitted(description ?? "");

  const descStyle = {
    marginTop: "0.5rem",
    width: "100%",
    textOverflow: "ellipsis",
    overflowX: "hidden" as const,
  };

  return (
    <IonItem lines={lines}>
      {isEditable ? (
        <IonTextarea
          {...cyAttr("deck-edit-description")}
          label={L10n.localize((s) => s.general.description)}
          disabled={!isEditable}
          rows={1}
          autoGrow
          value={description}
          placeholder={L10n.localize((s) => s.general.optional)}
          onIonInput={(e: TextareaCustomEvent) =>
            onDeckDescriptionChange(e.detail.value ?? undefined)
          }
          style={{ fontSize: 16 }}
          maxlength={2047}
        />
      ) : (
        <div style={descStyle}>{desc}</div>
      )}
    </IonItem>
  );
}
