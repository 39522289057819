import { IonIcon, IonItem, IonLabel, IonNavLink, IonNote } from "@ionic/react";
import { IField } from "fields/fields";
import { ILanguage, fieldLang } from "fields/lang";
import { validateTranslationPipelineLang } from "fields/lib";
import { includesPipelineNodeType } from "fields/sources/lib";
import { warningOutline } from "ionicons/icons";
import L10n from "localization";
import React from "react";
import LanguageNav from "./languageNav";

interface IProps {
  field: IField;
  langCode: string | undefined;
  langs: ILanguage[];
  defaultLangCode?: string;
  onChange: (code: string | undefined) => void;
  disabled?: boolean;
  showLangCode?: boolean;
  label?: string;
  langRequired?: boolean;
}
export function LanguageSelectorItem({
  field,
  langCode,
  langs,
  defaultLangCode,
  onChange,
  disabled,
  showLangCode,
  label,
  langRequired,
}: IProps) {
  const langName = langs.find((lang) => lang.code === langCode)?.name;

  // TODO: refactor. Ugh.
  const defaultInvalid =
    includesPipelineNodeType(field.source, "translation") ||
    field.type === "chinese" ||
    field.type === "tts" ||
    field.type === "code";

  // TODO: delegate this logic to field type.
  const missingLang =
    (langRequired && !langCode) ||
    validateTranslationPipelineLang(field) === false ||
    (field.type === "chinese" && !fieldLang(field)) ||
    (field.type === "tts" && !fieldLang(field)) ||
    (field.type === "code" && !field.attributes?.lang);

  const item = (
    <IonItem button disabled={disabled}>
      <IonLabel>{label ?? L10n.localize((s) => s.general.language)}</IonLabel>
      <IonNote slot="end" color={missingLang ? "warning" : undefined}>
        {missingLang
          ? L10n.localize((s) => s.general.required)
          : langName ?? L10n.localize((s) => s.general.default)}
      </IonNote>
      {missingLang ? <IonIcon icon={warningOutline} color="warning" slot="end" /> : undefined}
    </IonItem>
  );

  if (disabled) {
    return item;
  }

  const handleChange = async (
    lang: string | undefined,
    ev: React.MouseEvent<HTMLIonItemElement>,
  ) => {
    onChange(lang);
    const ionNav = (ev.target as Element).closest("ion-nav");
    if (ionNav) {
      // Call the pop method on ionNav.
      // Assuming ionNav is an IonNav instance, the pop method returns a Promise.
      // Use await to wait for the Promise to resolve.
      await (ionNav as HTMLIonNavElement).pop();
    }
  };

  return (
    <IonNavLink
      routerDirection="forward"
      component={() => (
        <LanguageNav
          defaultInvalid={defaultInvalid}
          langCode={langCode}
          defaultLangCode={defaultLangCode}
          langs={langs}
          onSelect={handleChange}
          showLangCode={showLangCode}
        />
      )}
    >
      {item}
    </IonNavLink>
  );
}
