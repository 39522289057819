import React from "react";
import { IonChip, IonLabel } from "@ionic/react";
import Style from "style";
import { Grade } from "@core/grade";

export default function GradeBubble(props: { grade: Grade }) {
  const { grade } = props;
  return (
    <IonChip style={{ pointerEvents: "none" }}>
      <IonLabel
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: Style.colors.grades[grade],
        }}
      >
        {grade}
      </IonLabel>
    </IonChip>
  );
}
