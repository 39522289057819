// MapView exposes a read-only Map interface for an underlying map limited by a subset of keys.
export type MapLike<K, V> = Map<K, V> | MapView<K, V>;
export default class MapView<K, V> {
  private _map?: MapLike<K, V>;
  private _keys?: Set<K>;
  constructor(map?: MapLike<K, V>, keys?: Set<K>) {
    if (map) {
      this._map = map;
    }
    if (keys) {
      this._keys = keys;
    }
  }

  public set map(m: MapLike<K, V>) {
    this._map = m;
  }

  public setKeys(k: Set<K>) {
    this._keys = k;
  }

  public keys() {
    return this._keys ?? new Set();
  }

  public has(k: K) {
    return this._keys?.has(k);
  }

  public get(k: K): V | undefined {
    if (!this._keys?.has(k)) {
      return undefined;
    }
    return this._map?.get(k);
  }

  public get size(): number {
    if (!this._keys || !this._map) {
      return 0;
    }
    let count = 0;
    for (const key of this._keys) {
      if (this._map.has(key)) {
        count++;
      }
    }
    return count;
  }

  [Symbol.iterator](): Iterator<[K, V]> {
    if (!this._map || !this._keys) {
      return {
        next(): IteratorResult<[K, V]> {
          return { value: null, done: true };
        },
      };
    }
    const map = this._map;
    const keyIterator = this._keys[Symbol.iterator]();
    return {
      next(): IteratorResult<[K, V]> {
        let iteration = keyIterator.next();
        while (!iteration.done) {
          const key = iteration.value;
          const val = map.get(key);
          if (val) {
            return {
              value: [key, val],
              done: false,
            };
          }
          // Else, keep iterating through the keys
          iteration = keyIterator.next();
        }
        // Once all keys have been iterated over, mark iteration as done
        return { value: null, done: true };
      },
    };
  }

  public *values(): Iterable<V> {
    for (const [, v] of this) {
      yield v;
    }
  }
}
