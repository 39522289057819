import DeviceStatus from "@components/deviceStatus";
import { showPrompt } from "@components/prompt";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  IonTitle,
  IonToolbar,
  RefresherEventDetail,
  useIonLoading,
} from "@ionic/react";
import logEvent from "analytics";
import Device from "device";
import { mailOutline } from "ionicons/icons";
import L10n from "localization";
import Network from "network";
import { ID } from "types/ID";
import React = require("react");

export interface IDeviceItem {
  id: string;
  name: string;
  client_version: {
    major: string;
    minor: string;
    patch: string;
  };
  platform: string;
  last_synced_at: string;
}

const numDevicesBatchSize = 25;
let globalDevices: IDeviceItem[];

export default function Devices() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [devices, setDevices] = React.useState<IDeviceItem[]>(globalDevices);
  const [maxNumVisibleDevices, setMaxNumVisibleDevices] = React.useState(numDevicesBatchSize);
  const [presentLoading, dismissLoading] = useIonLoading();

  const fetchDevices = async () => {
    try {
      globalDevices = await Network.fetch<IDeviceItem[]>("GET", "/users/devices");
      setDevices(globalDevices);
    } catch (error) {
      // do nothing
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      fetchDevices();
    };
    if (!globalDevices) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, []);

  const handleDelete = async (id: ID) => {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.account.deleteDeviceConfirm),
      promptType: "dangerousConfirm",
      dangerousConfirmOkButtonText: L10n.localize((s) => s.actions.delete).toLocaleUpperCase(
        L10n.getCurrentLocale(),
      ),
      callback: async () => {
        presentLoading();
        try {
          await Network.fetch("DELETE", `/users/devices/${id}`);
          setDevices(devices?.filter((d) => d.id !== id));
        } catch {
        } finally {
          setTimeout(() => {
            dismissLoading();
          }, 50);
        }
      },
    });
  };

  const deviceRowsFromDevices = devices
    ?.sort((d1: IDeviceItem, d2: IDeviceItem) =>
      !d1.last_synced_at ? 1 : d1.last_synced_at < d2.last_synced_at ? 1 : -1,
    )
    .map((d) => {
      return (
        <DeviceStatus
          key={d.id}
          id={d.id}
          isThisDevice={d.id === Device.getID()}
          name={d.name}
          clientVersion={d.client_version}
          platform={d.platform}
          lastSyncedAt={d.last_synced_at}
          handleDelete={handleDelete}
        />
      );
    });

  const emailDownloadLinksClicked = () => {
    Network.fetch("POST", "/users/send_download_links")
      .then(() => {
        alert(L10n.localize((s) => s.general.emailSent));
      })
      .catch(() => {
        // Whatever.
        alert(L10n.localize((s) => s.general.emailSendFailed));
      });

    logEvent("send_download_links_clicked");
  };

  const hasOtherDevices = devices && devices?.length > 1;
  const moreDevicesThanVisible = (devices?.length ?? 0) > maxNumVisibleDevices;

  const handleRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    await fetchDevices();
    event.detail.complete();
  };

  const devicesToShow = deviceRowsFromDevices?.slice(0, maxNumVisibleDevices);
  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{L10n.localize((s) => s.general.devices)}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen color="light">
        <IonRefresher slot="fixed" onIonRefresh={handleRefresh} disabled={loading}>
          <IonRefresherContent />
        </IonRefresher>
        <IonList className="custom-inset">
          {loading && <IonProgressBar type="indeterminate" />}
          {devicesToShow}
          {moreDevicesThanVisible && (
            <IonItem key="show-more" lines="none">
              <IonButton
                fill="clear"
                expand="full"
                style={{ width: "100%" }}
                onClick={() => setMaxNumVisibleDevices(maxNumVisibleDevices + numDevicesBatchSize)}
              >
                {L10n.localize((s) => s.actions.showMore)}
              </IonButton>
            </IonItem>
          )}
          {!loading && !hasOtherDevices && (
            <IonItem lines="none">
              <IonLabel className="ion-text-wrap">
                {L10n.localize((s) => s.general.multiPlatformPitch)}
                <p>{L10n.localize((s) => s.general.emailMeDownloadLinks)}</p>
              </IonLabel>
              <IonButton onClick={emailDownloadLinksClicked}>
                <IonIcon size="large" slot="icon-only" icon={mailOutline} />
              </IonButton>
            </IonItem>
          )}
        </IonList>
      </IonContent>
    </>
  );
}
