import SettingsModal from "@components/settingsModal";
import { IonRadioGroupCustomEvent } from "@ionic/core";
import {
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  IonRange,
  RadioGroupChangeEventDetail,
  RangeCustomEvent,
} from "@ionic/react";
import { ITimerSettings, TimerMode, timerModes } from "@models/deckSettings";
import L10n from "localization";
import React from "react";
import { autoflipTimerMaxMillis, autoflipTimerMinMillis } from "settings";
import { millisToSeconds } from "./timerSettingItem";

interface IProps {
  dismiss: () => void;
  timerSettings: ITimerSettings;
  onChange: (settings: ITimerSettings) => void;
}
export default function TimerSettingModal({ dismiss, timerSettings, onChange }: IProps) {
  function handleRadioChange(ev: IonRadioGroupCustomEvent<RadioGroupChangeEventDetail<TimerMode>>) {
    const mode = ev.detail.value;
    if (timerModes.includes(mode)) {
      onChange({ millis: timerSettings.millis, mode });
    }
  }

  return (
    <SettingsModal
      title={L10n.localize((s) => s.review.timer)}
      dismiss={dismiss}
      description={L10n.localize((s) => s.review.timerDescription)}
    >
      <IonList inset>
        <IonRadioGroup value={timerSettings.mode} onIonChange={handleRadioChange}>
          <IonItem>
            <IonRadio value="off">{L10n.localize((s) => s.general.off)}</IonRadio>
          </IonItem>
          <IonItem>
            <IonRadio value="semi-auto">{L10n.localize((s) => s.review.autoFlip)}</IonRadio>
          </IonItem>
          <IonItem>
            <IonRadio value="full-auto">{L10n.localize((s) => s.review.autoAdvance)}</IonRadio>
          </IonItem>
        </IonRadioGroup>
        <IonItem lines="none">
          <IonRange
            aria-label="Automatic Flip Timer, in seconds"
            disabled={timerSettings.mode === "off"}
            min={autoflipTimerMinMillis}
            max={autoflipTimerMaxMillis}
            step={250}
            value={timerSettings.millis}
            snaps={true}
            ticks
            onIonInput={(e: RangeCustomEvent) =>
              onChange({ ...timerSettings, millis: e.detail.value as number })
            }
            pin
            pinFormatter={millisToSeconds}
          >
            <IonLabel slot="start" style={{ flexGrow: 0 }}>
              {millisToSeconds(autoflipTimerMinMillis)}
            </IonLabel>
            <IonLabel slot="end" style={{ flexGrow: 0 }}>
              {millisToSeconds(autoflipTimerMaxMillis)}
            </IonLabel>
          </IonRange>
        </IonItem>
      </IonList>
    </SettingsModal>
  );
}
