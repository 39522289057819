import FontSizeControl from "@components/fontSizeControl";
import SettingsModal from "@components/settingsModal";
import { useClearDot } from "dots";
import L10n from "localization";
import React from "react";

interface IProps {
  dismiss: () => void;
  fontSizePx: number;
  handleFontSizeChange: (px: number) => void;
}
export default function FontSizeSettingModal(props: IProps): JSX.Element {
  const { dismiss, fontSizePx, handleFontSizeChange } = props;

  useClearDot("review_font_size_setting");

  return (
    <SettingsModal
      title={L10n.localize((s) => s.general.fontSize)}
      dismiss={dismiss}
      description={L10n.localize((s) => s.settings.fontSizeDescription)}
    >
      <FontSizeControl fontSizePx={fontSizePx} onFontSizeChange={handleFontSizeChange} inset />
    </SettingsModal>
  );
}
