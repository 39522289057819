import { showPrompt } from "@components/prompt";
import {
  InputChangeEventDetail,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonList,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import Globals from "globals";
import cyAttr from "lib/cyAttr";
import L10n from "localization";
import Network from "network";
import React from "react";
import Style from "style";

export default function ChangeEmailModal({ dismiss }: { dismiss: () => void }) {
  const [newEmail, setNewEmail] = React.useState("");
  function handleNewEmailChange(evt: CustomEvent<InputChangeEventDetail>) {
    if (evt.detail.value) {
      setNewEmail(evt.detail.value);
    }
  }

  const [password, setPassword] = React.useState("");
  function handlePasswordChange(evt: CustomEvent<InputChangeEventDetail>) {
    if (evt.detail.value) {
      setPassword(evt.detail.value);
    }
  }

  const emailInvalid = !Globals.emailValidationRegex.test(newEmail);
  const passwordInvalid = password.length < 1;
  const disabled = emailInvalid || passwordInvalid;

  async function handleSubmit() {
    try {
      const params = {
        new_email: newEmail,
        password,
      };

      try {
        await Network.fetch("POST", "/users/account/email", params);
        localStorage["AnkiApp.user.email"] = newEmail;
      } catch {
        showPrompt({
          title: L10n.localize((s) => s.general.attention),
          prompt: L10n.localize((s) => s.account.changeEmailError),
          promptType: "alert",
        });
      }

      dismiss();
    } catch {
      showPrompt({
        prompt: L10n.localize((s) => s.error.internal),
        promptType: "alert",
        title: L10n.localize((s) => s.general.attention),
      });
    }
  }

  return (
    <IonContent
      {...cyAttr("change-email-modal")}
      style={
        { "--background": "var(--ion-color-light)" } as React.CSSProperties & {
          "--background": string;
        }
      }
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{L10n.localize((s) => s.account.changeEmail)}</IonTitle>
          <IonButtons slot="secondary">
            <IonButton onClick={dismiss}>{L10n.localize((s) => s.actions.cancel)}</IonButton>
          </IonButtons>
          <IonButtons slot="primary">
            <IonButton
              onClick={handleSubmit}
              disabled={disabled}
              style={{ textTransform: "capitalize" }}
            >
              {L10n.localize((s) => s.actions.change)}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonList style={{ marginTop: 32 }}>
        <IonItem>
          <IonInput
            label={L10n.localize((s) => s.account.newEmail)}
            value={newEmail}
            onIonInput={handleNewEmailChange}
            type="email"
            required
            style={{
              color: newEmail !== "" && emailInvalid ? Style.colors.dangerousFg : undefined,
            }}
          />
        </IonItem>

        <IonItem>
          <IonInput
            label={L10n.localize((s) => s.account.currentPassword)}
            value={password}
            onIonInput={handlePasswordChange}
            type="password"
            required
          />
        </IonItem>
      </IonList>
    </IonContent>
  );
}
