import { ICardResponse } from "@screens/review/types";

export function didJustHitStudyGoal(
  responses: ICardResponse[],
  studyGoal: number,
  numAllDeckReviewsToday?: number,
): boolean {
  if (!numAllDeckReviewsToday) {
    return false;
  }

  const numResponses = responses.length;
  const hitStudyGoal = numAllDeckReviewsToday >= studyGoal;
  const prevBelowStudyGoal = numAllDeckReviewsToday - numResponses < studyGoal;
  const justHitStudyGoal = !!studyGoal && hitStudyGoal && prevBelowStudyGoal;
  return justHitStudyGoal;
}
