import React from "react";
import UnlimitedIcon from "../components/unlimitedIcon";

export default function UnlimitedBadge(props: { style?: React.CSSProperties }) {
  const { style } = props;
  return (
    <div style={{ textAlign: "center", ...style }}>
      <UnlimitedIcon style={{ maxWidth: "100%", height: 52 }} />
    </div>
  );
}
