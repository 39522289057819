import { IonButton, useIonAlert } from "@ionic/react";
import { blobHash } from "blobs/lib";
import FieldValueEditorItem from "fields/components/fieldValueEditorItem";
import { IFieldValueEditorProps } from "fields/fieldType";
import { fieldTypeMap } from "fields/fields";
import { IFieldAudio } from "fields/types/audio";
import { MAX_FILE_BYTES } from "globals";
import { recordAudio } from "lib/audio";
import L10n from "localization";
import React from "react";

export default function FieldValueEditorAudio({
  field,
  name,
  value,
  onChange,
  registerBlob,
  editorRef,
  editable,
}: IFieldValueEditorProps<IFieldAudio>): JSX.Element {
  const [presentAlert] = useIonAlert();
  const fileInput = React.useRef<HTMLInputElement>(null);

  async function saveRecording(blob: Blob) {
    const id = await blobHash(blob);
    await registerBlob(id, blob);

    const fml = fieldTypeMap.audio.dumpFML({ id, type: blob.type });
    onChange(name, fml);
  }

  async function handleFileSelected(e: React.ChangeEvent<HTMLInputElement>) {
    const { files } = e.currentTarget;
    const file = files?.[0];
    if (!file) {
      return;
    }

    if (file.size > MAX_FILE_BYTES) {
      presentAlert(L10n.localize((s) => s.error.fileTooLarge));
      return;
    }

    const type = file.type.split("/")[0];
    if (type !== "audio") {
      return;
    }

    const id = await blobHash(file);
    await registerBlob(id, file);

    const fml = fieldTypeMap.audio.dumpFML({ id, type: file.type });
    onChange(name, fml);
  }

  return (
    <FieldValueEditorItem name={name} field={field} editorRef={editorRef}>
      {!editable ? undefined : (
        <>
          <IonButton fill="clear" onClick={() => recordAudio(saveRecording)}>
            {L10n.localize((s) => s.actions.record)}
          </IonButton>
          <IonButton fill="clear" onClick={() => fileInput.current?.click()}>
            {L10n.localize((s) => s.actions.choose)}
          </IonButton>
          <input
            type="file"
            accept=".mp3,.m4a,.wav,.ogg"
            value=""
            ref={fileInput}
            onChange={handleFileSelected}
            style={{ display: "none" }}
          />
        </>
      )}
    </FieldValueEditorItem>
  );
}
