import Button from "@components/button";
import { InputCustomEvent, IonInput, IonItem, IonList, IonListHeader } from "@ionic/react";
import Globals, { isMacCatalyst } from "globals";
import L10n from "localization";
import React from "react";
import { ISettings } from "settings";
import { studyGoalMax, updateStudyGoalBadgeCount } from "studyGoals";

type INativeNotification = {
  openSettings: (func1: { (): void }, func2: { (): void }) => void;
};

declare let NativeNotifications: INativeNotification;

interface IProps {
  settings?: ISettings;
  updateSettings: (s: keyof ISettings, v: string | number | boolean) => void;
}
export default function RemindersSettings({ settings, updateSettings }: IProps) {
  const handleStudyGoalChange = (v: number): void => {
    if (settings?.studyGoal === v) {
      return;
    }
    const studyGoal = Math.min(v, studyGoalMax);
    updateSettings("studyGoal", studyGoal);
    updateStudyGoalBadgeCount();
  };

  return (
    <IonList inset>
      <IonListHeader>{L10n.localize((s) => s.general.reminders)}</IonListHeader>
      <IonItem lines="none">
        <IonInput
          label={L10n.localize((s) => s.general.studyGoal)}
          value={settings?.studyGoal}
          type="number"
          min="0"
          max={studyGoalMax.toString()}
          style={{ textAlign: "end" }}
          color="primary"
          debounce={200}
          onIonInput={(e: InputCustomEvent) =>
            handleStudyGoalChange(parseInt(e.detail.value ?? "0"))
          }
        />
      </IonItem>
      {Globals.platform === "ios" && !isMacCatalyst() ? (
        <Button
          text={L10n.localize((s) => s.general.openSettings)}
          onClick={() => {
            NativeNotifications.openSettings(
              () => null,
              () => null,
            );
          }}
        />
      ) : undefined}
    </IonList>
  );
}
