declare let navigator: any;

export default class Notification {
  public static alert(message: string): void {
    if ("notification" in window.navigator) {
      navigator.notification.alert(message);
    } else {
      window.alert(message);
    }
  }
}
