import SettingsModal from "@components/settingsModal";
import {
  IonItem,
  IonLabel,
  IonList,
  IonRadio,
  IonRadioGroup,
  RadioGroupCustomEvent,
} from "@ionic/react";
import React from "react";

export interface IRadioSelectOption<T> {
  name: string;
  val: T;
  desc?: string;
}

interface IProps<T> {
  dismiss: () => void;
  title: string;
  options: IRadioSelectOption<T>[];
  selectedItemVal: string;
  onChange: (val: T) => void;
  description?: string;
}

export default function RadioSelectSettingModal<T extends string>(props: IProps<T>): JSX.Element {
  const { dismiss, options, selectedItemVal, title, onChange, description } = props;

  return (
    <SettingsModal title={title} dismiss={dismiss} description={description}>
      <IonList inset>
        <IonRadioGroup
          value={selectedItemVal}
          onIonChange={(e: RadioGroupCustomEvent) => onChange(e.detail.value)}
        >
          {options.map(({ name, val, desc }) => {
            return (
              <IonItem key={val}>
                <IonRadio justify="space-between" value={val}>
                  <IonLabel className="ion-text-wrap">
                    {name}
                    {desc ? <p>{desc}</p> : undefined}
                  </IonLabel>
                </IonRadio>
              </IonItem>
            );
          })}
        </IonRadioGroup>
      </IonList>
    </SettingsModal>
  );
}
