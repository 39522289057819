import { IonNav } from "@ionic/react";
import { IDeck } from "@models/deck";
import { useDeckFieldsReducer } from "fields/hooks/useFieldsReducer";
import React, { useCallback } from "react";
import { FieldsContext } from "./deckCreate";
import DeckEditNav from "./deckEdit/deckEditNav";

export default function DeckEditScreen({
  deck,
  dismiss,
}: {
  deck?: IDeck;
  dismiss: () => void;
}): JSX.Element {
  if (!deck) {
    return <></>;
  }
  const [fields, dispatch, , edited] = useDeckFieldsReducer(deck, false);

  // NOTE: this useCallback is necessary to prevent DeckEditNav from getting
  // recreated on re-renders (which will happen when fields state changes).
  const navRoot = useCallback(() => <DeckEditNav deck={deck} dismiss={dismiss} />, [deck, dismiss]);

  return (
    <FieldsContext.Provider value={{ fields, dispatch, modified: edited }}>
      <IonNav root={navRoot} />
    </FieldsContext.Provider>
  );
}
