import idb from "@data/idb";
import logEvent from "analytics";
import EventBus from "eventBus";
import { useAsyncValState } from "hooks/util/useAsyncValState";
import { useEffect } from "react";

export type DotID =
  | "beta_screen"
  | "generate_cards_menu_item"
  | "generate_cards_deck_screen_menu"
  | "generate_cards_modal_suggestions"
  | "inbox_body"
  | "inbox_deck_select_modal"
  | "japanese_furigana_override"
  | "cards_per_session_setting"
  | "review_tags_setting"
  | "review_font_size_setting"
  | "review_timer_setting"
  | "review_orientation_setting"
  | "review_mode_setting"
  | "flip_stabilization_setting"
  | "theater_mode_menu_item"
  | "theater_mode_review_screen_menu";

export async function clearDot(id: DotID): Promise<void> {
  const tx = idb.dots.transaction(["dots"], "readwrite");
  const store = tx.objectStore("dots");
  const dot = await store.get(id);
  if (!dot || !dot.clearedAt) {
    await store.put({ ...dot, id, clearedAt: new Date() });
    EventBus.emit("dotCleared", { dotID: id });
    logEvent("dot_funnel", { dotID: id, step: "cleared" });
  }
  await tx.done;
}

export async function recordDotImpression(id: DotID): Promise<void> {
  const tx = idb.dots.transaction(["dots"], "readwrite");
  const store = tx.objectStore("dots");
  const dot = await store.get(id);
  if (dot?.firstSeenAt) {
    return;
  }
  await store.put({ ...dot, id, firstSeenAt: new Date() });
  await tx.done;
  logEvent("dot_funnel", { dotID: id, step: "first_impression" });
}

export async function getClearedAt(id: DotID): Promise<Date | undefined> {
  const row = await idb.dots.get("dots", id);
  return row?.clearedAt;
}

export function useClearedAtAndRecordImpression(id: DotID | undefined) {
  const [clearedAt, setClearedAt, setError] = useAsyncValState<Date | undefined>();
  useEffect(() => {
    if (!id) {
      return;
    }

    recordDotImpression(id).catch(() => {
      // Don't care.
    });

    function update({ dotID }: { dotID: DotID }) {
      if (id !== dotID) {
        return;
      }
      getClearedAt(id).then(setClearedAt).catch(setError);
    }

    update({ dotID: id });
    EventBus.on("dotCleared", update);
    return () => {
      EventBus.off("dotCleared", update);
    };
  }, [id, setClearedAt, setError]);
  return clearedAt;
}

export function useClearDot(id: DotID) {
  useEffect(() => {
    clearDot(id).catch(() => {});
  }, [id]);
}
