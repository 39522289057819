import { useIsPhone } from "hooks/util/useMediaQuery";
import L10n from "localization";
import React from "react";

export default function CTA() {
  const isPhone = useIsPhone();
  const style = {
    marginTop: 8,
    marginBottom: 4,
    fontSize: isPhone ? 24 : 32,
  };

  return <h1 style={style}>{L10n.localize((s) => s.account.iOSUpgrade)}</h1>;
}
