import BarChart, { IBar } from "@components/barChart";
import { Grade, isGrade } from "@core/grade";
import { Deck, IDeck } from "@models/deck";
import { IKnol } from "@models/knol";
import useErrorAlert from "hooks/util/useErrorAlert";
import L10n from "localization";
import React, { useCallback } from "react";

const emptyHistogram = {
  IGNORED: 0,
  MARKED: 0,
  NEW: 0,
  F: 0,
  E: 0,
  D: 0,
  C: 0,
  B: 0,
  A: 0,
};

interface IProps {
  deck: IDeck | undefined;
  numIgnored?: number;
  numMarked?: number;
  knols?: Iterable<IKnol>;
  filteredKnols?: Iterable<IKnol>;
  height?: string;
  selectedGrades?: Grade[];
}
export default function GradeHistogram(props: IProps): JSX.Element {
  const { deck, numIgnored = 0, numMarked = 0, height, selectedGrades } = props;
  let histogram = emptyHistogram;
  let shadowHistogram = emptyHistogram;

  if (props.filteredKnols) {
    histogram = Deck.gradeHistogram(props.filteredKnols);
  }
  if (props.knols) {
    shadowHistogram = Deck.gradeHistogram(props.knols);
  }

  histogram.IGNORED = numIgnored;
  histogram.MARKED = numMarked;

  const label = (grade: string) => {
    if (grade === "IGNORED") {
      return L10n.localize((s) => s.card.ignoredCards);
    }
    if (grade === "MARKED") {
      return L10n.localize((s) => s.card.starred);
    }
    if (isGrade(grade)) {
      return L10n.localize((s) => s.grade[grade]);
    }
    return "";
  };

  const [showToggleGradeError] = useErrorAlert({ code: "TOGGLING_REVIEW_GRADE" });
  const toggleSelected = useCallback(
    (grade: Grade) => {
      const selectedGradesSet = new Set(selectedGrades);
      if (selectedGradesSet.has(grade)) {
        selectedGradesSet.delete(grade);
      } else {
        selectedGradesSet.add(grade);
      }
      Deck.setSetting(deck, "reviewGrades", Array.from(selectedGradesSet)).catch((err) => {
        showToggleGradeError(err);
      });
    },
    [deck, selectedGrades, showToggleGradeError],
  );

  const bars: IBar[] = [];
  const grades = ["IGNORED", "MARKED", "NEW", "F", "E", "D", "C", "B", "A"] as const;
  for (const grade of grades) {
    bars.push({
      class: grade,
      count: histogram[grade],
      shadowCount: shadowHistogram[grade],
      label: label(grade),
      onClick: () => {
        toggleSelected(grade as Grade);
      },
      hideEmpty: grade === "IGNORED" || grade === "MARKED" ? true : undefined,
      highlighted: selectedGrades?.includes(grade as Grade),
    });
  }

  return <BarChart height={height} bars={bars} count={true} grow={true} />;
}
