import L10n from "localization";
import { useEffect, useState } from "react";

const maxDots = 3;

export function loadingTextWithDots(text: string, dotChar: string, numDots = maxDots): string {
  const dots = dotChar.repeat(numDots);
  return `${text}${dots}`;
}

export function useLoadingText(
  loading: boolean,
  text: string,
  dotChar = L10n.localize((s) => s.general.dotChar),
  millis = 150,
) {
  const [numDots, setNumDots] = useState(0);
  useEffect(() => {
    function updateDots() {
      setNumDots((numDots + 1) % (maxDots + 1));
    }
    let handle: NodeJS.Timeout;
    if (loading) {
      handle = setInterval(updateDots, millis);
    }
    return () => {
      clearInterval(handle);
    };
  }, [loading, millis, numDots]);
  return loadingTextWithDots(text, dotChar, numDots);
}
