import InfoDot from "@components/infoDot";
import { IonItem, IonLabel, IonNote } from "@ionic/react";
import { ITimerSettings } from "@models/deckSettings";
import { clearDot } from "dots";
import { useIonCardModalWithDismiss } from "hooks/util/useIonModalWithDismiss";
import L10n from "localization";
import React, { useCallback } from "react";
import TimerSettingModal from "./timerSettingModal";

export function millisToSeconds(millis: number) {
  return `${millis / 1000}s`;
}

interface IProps {
  timerSettings: ITimerSettings;
  onChange: (settings: ITimerSettings) => void;
}
export default function TimerSettingItem({ timerSettings, onChange }: IProps): JSX.Element {
  let note = L10n.localize((s) => s.general.off);
  switch (timerSettings.mode) {
    case "semi-auto":
      note = `${L10n.localize((s) => s.review.autoFlip)} (${millisToSeconds(
        timerSettings.millis,
      )})`;
      break;
    case "full-auto":
      note = `${L10n.localize((s) => s.review.autoAdvance)} (${millisToSeconds(
        timerSettings.millis,
      )})`;
      break;
  }

  const dotID = "review_timer_setting";

  const [showModal] = useIonCardModalWithDismiss(TimerSettingModal, {
    timerSettings,
    onChange,
  });
  const show = useCallback(async () => {
    clearDot(dotID).catch(() => {});
    showModal();
  }, [showModal]);

  return (
    <IonItem button onClick={show}>
      <InfoDot id={dotID} />
      <IonLabel>{L10n.localize((s) => s.review.timer)}</IonLabel>
      <IonNote slot="end">{note}</IonNote>
    </IonItem>
  );
}
