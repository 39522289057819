import EventBus from "eventBus";
import flags from "featureFlags";
import React from "react";

// When hasUnlimited is undefined it's either loading or there was an error.
export default function useHasUnlimited(): {
  error: boolean;
  loading: boolean;
  hasUnlimited?: boolean;
} {
  const defaultVal = {
    error: false,
    loading: true,
  };

  const [state, setState] = React.useState<{
    error: boolean;
    loading: boolean;
    hasUnlimited?: boolean;
  }>(defaultVal);

  React.useEffect(() => {
    function update() {
      if (flags.flagCache?.hasUnlimited === undefined) {
        setState(defaultVal);
        flags
          .updateFlagCache()
          .then(() => {
            const hasUnlimited = flags.flagCache?.hasUnlimited;
            const error = hasUnlimited === undefined;
            setState({ error, loading: false, hasUnlimited });
          })
          .catch(() => {
            setState({ error: true, loading: false });
          });
      } else {
        setState({ error: false, loading: false, hasUnlimited: flags.flagCache?.hasUnlimited });
      }
    }

    update();

    EventBus.on("flagCacheInvalidated", update);
    return () => {
      EventBus.off("flagCacheInvalidated", update);
    };
  }, []);

  return state;
}
