import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { showPrompt } from "@components/prompt";
import ScreenComponent from "@components/screen";
import ScreenMenu, { MenuButton } from "@components/screenMenu";
import {
  IonItem,
  IonLabel,
  IonListHeader,
  IonNote,
  IonProgressBar,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from "@ionic/react";
import { User } from "@models/user";
import logEvent from "analytics";
import EventBus from "eventBus";
import { History } from "history";
import useDismissibleToast from "hooks/util/useDismissibleToast";
import { groupLinkJoinAnalyticsKey } from "hooks/util/useGroupJoinRequest";
import { createOutline, exitOutline, shareOutline, trashOutline } from "ionicons/icons";
import L10n from "localization";
import Network from "network";
import { usePushNotifPermissionRequest } from "pushnotifs";
import React from "react";
import JoinRequestList from "./studyGroups/joinRequestList";
import { userIsOwner } from "./studyGroups/lib";
import Members from "./studyGroups/members";
import { useShareDeckModal } from "./studyGroups/shareDeckModal";
import SharedDecks from "./studyGroups/sharedDecks";
import { IGroup, IMember } from "./studyGroups/types";
import { useStudyGroup } from "./studyGroups/useStudyGroups";

function Invites(props: { group: IGroup }) {
  const { group } = props;
  const { invites } = group;

  const sortedInvites = (invites ?? []).sort((i1, i2) => (i1.created_at < i2.created_at ? -1 : 1));

  return (
    <>
      {sortedInvites.length > 0 && (
        <IonListHeader>
          <IonLabel>{L10n.localize((s) => s.groups.invited)}</IonLabel>
        </IonListHeader>
      )}

      {sortedInvites.map((invite, idx) => {
        return (
          <IonItem style={{ flex: "0 0 auto" }} key={idx}>
            {invite.email}
          </IonItem>
        );
      })}
    </>
  );
}

interface IMenuProps {
  group?: IGroup;
  fetchGroup: () => void;
  onDismiss: () => void;
  openShareModal: () => void;
}
function Menu(props: IMenuProps) {
  const { group, fetchGroup, onDismiss, openShareModal } = props;

  if (!group) {
    return null;
  }
  const { id, name } = group;

  function handleLeaveGroup() {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.funcs.groups.leaveGroupQuestion)(name),
      promptType: "confirm",
      callback: async () => {
        try {
          await Network.fetch("DELETE", `/users/groups/${id}/users/${User.id()}`);
          EventBus.emit("leftStudyGroup", { groupID: id });
          onDismiss();
        } catch {
          showPrompt({
            prompt: L10n.localize((s) => s.error.internal),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
        }
      },
    });
  }

  function handleDeleteGroup() {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.groups.confirmDelete),
      promptType: "confirm",
      callback: async () => {
        Network.fetch("DELETE", `/users/groups/${id}`)
          .then(() => {
            EventBus.emit("deletedStudyGroup", { groupID: id });
            onDismiss();
          })
          .catch(() => {
            showPrompt({
              prompt: L10n.localize((s) => s.error.internal),
              promptType: "alert",
              title: L10n.localize((s) => s.general.attention),
            });
          });
      },
    });
  }

  async function handleSave(newName: string | null, emails: string[]) {
    const invites = emails.map((e) => {
      return { email: e };
    });
    try {
      await Network.fetch("PATCH", `/users/groups/${id}`, {
        name: newName ?? name,
        invites,
      });
      fetchGroup();
    } catch {
      showPrompt({
        prompt: L10n.localize((s) => s.error.internal),
        promptType: "alert",
        title: L10n.localize((s) => s.general.attention),
      });
    }
  }

  function showRenamePrompt() {
    showPrompt({
      promptType: "input",
      title: L10n.localize((s) => s.groups.studyGroup),
      prompt: L10n.localize((s) => s.general.name),
      validationRegex: /.+/,
      default: name,
      callback: (response) => {
        const newName = response?.trim() ?? "";
        if (newName === "" || newName === name) {
          return;
        }

        handleSave(newName, []);
      },
    });
  }

  const isOwner = userIsOwner(group);

  return (
    <ScreenMenu>
      {isOwner && (
        <MenuButton
          label={L10n.localize((s) => s.actions.rename)}
          icon={createOutline}
          onClick={showRenamePrompt}
        />
      )}
      <MenuButton
        label={L10n.localize((s) => s.sharing.shareDeck)}
        icon={shareOutline}
        detail={false}
        onClick={openShareModal}
      />
      <MenuButton
        label={L10n.localize((s) => s.groups.leaveGroup)}
        icon={exitOutline}
        detail={false}
        onClick={handleLeaveGroup}
      />
      {isOwner && (
        <MenuButton
          lines="none"
          detail={false}
          label={L10n.localize((s) => s.groups.delete)}
          color="danger"
          icon={trashOutline}
          onClick={handleDeleteGroup}
        />
      )}
    </ScreenMenu>
  );
}

function Body(props: {
  group?: IGroup;
  loading: boolean;
  refetchGroup: () => void;
  openShareModal: () => void;
}) {
  const { group, loading, refetchGroup, openShareModal } = props;

  const shareUrl = group?.share_url;
  const shareUrlForDisplay = shareUrl?.replace(/^https:\/\//, "");
  const hasShareUrl = !!shareUrl;
  async function handleShareLink() {
    if (!shareUrlForDisplay) {
      return;
    }

    if (Capacitor.isPluginAvailable("Share") && (await Share.canShare()).value) {
      try {
        await Share.share({ url: shareUrl });

        logEvent(groupLinkJoinAnalyticsKey, {
          step: "link_shared",
          group_id: group?.id,
          group_name: group?.name,
          shareUrl,
        });
      } catch {
        // Share canceled.
      }
    } else {
      if (shareUrl) {
        // NOTE: should work in Chrome without extra permissions.
        // May need to revisit if we expand browser support.
        try {
          await navigator.clipboard.writeText(shareUrl);

          alert(L10n.localize((s) => s.sharing.copiedStudyGroupShareLink));

          logEvent(groupLinkJoinAnalyticsKey, {
            step: "link_copied",
            group_id: group?.id,
            group_name: group?.name,
            shareUrl,
          });
        } catch {
          logEvent(groupLinkJoinAnalyticsKey, {
            step: "link_copy_failed",
            group_id: group?.id,
            group_name: group?.name,
            shareUrl,
          });
        }
      }
    }
  }

  const handleKickMember = (member: IMember) => {
    if (!group) {
      return;
    }
    if (member.role === "creator") {
      return;
    }
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.funcs.groups.kickMemberQuestion)(member.email, group.name),
      promptType: "confirm",
      callback: async () => {
        try {
          await Network.fetch("DELETE", `/users/groups/${group.id}/users/${member.id}`);
          refetchGroup();
        } catch {
          showPrompt({
            prompt: L10n.localize((s) => s.error.internal),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
        }
      },
    });
  };

  return loading || !group ? (
    <IonProgressBar type="indeterminate" />
  ) : (
    <>
      <IonItem
        lines="none"
        detail
        onClick={handleShareLink}
        style={{ cursor: hasShareUrl ? "pointer" : undefined }}
        detailIcon={shareOutline}
      >
        <IonLabel style={{ flexBasis: "content", flexShrink: 0 }}>
          {L10n.localize((s) => s.actions.share)}
        </IonLabel>
        <IonNote
          slot="end"
          color={hasShareUrl ? "primary" : undefined}
          style={{
            overflowX: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontFamily: hasShareUrl ? "monospace" : undefined,
            marginRight: 8,
            fontSize: 14,
          }}
        >
          {shareUrlForDisplay ?? L10n.localize((s) => s.general.none)}
        </IonNote>
      </IonItem>
      <JoinRequestList group={group} onChange={refetchGroup} />
      <Members group={group} onKickMember={handleKickMember} />
      <Invites group={group} />
      <SharedDecks group={group} fetchGroup={refetchGroup} openShareModal={openShareModal} />
    </>
  );
}

export function StudyGroupScreen(props: {
  id: string;
  history: History;
}): JSX.Element {
  const { id, history } = props;
  const [group, fetchGroup] = useStudyGroup(id);

  React.useEffect(() => {
    function handlePushNotif({ groupID }: { groupID: string }) {
      if (groupID === id) {
        fetchGroup();
      }
    }

    EventBus.on("studyGroupPushNotifReceived", handlePushNotif);
    return () => {
      EventBus.off("studyGroupPushNotifReceived", handlePushNotif);
    };
  }, [id, fetchGroup]);

  usePushNotifPermissionRequest();

  function handleDismiss() {
    history.replace("/groups");
  }

  function handleRefresh(event: CustomEvent<RefresherEventDetail>) {
    fetchGroup().finally(() => {
      event.detail.complete();
    });
  }

  function handleShareSuccess() {
    fetchGroup();
    showToast({
      color: "success",
      duration: 200,
      message: L10n.localize((s) => s.general.done),
    });
  }
  const [showShareModal] = useShareDeckModal(group.val?.id ?? "", handleShareSuccess);
  const [showToast] = useDismissibleToast();

  return (
    <ScreenComponent
      title={group.val?.name}
      helpQuery="groups"
      content={
        group.error ? null : (
          <>
            <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
              <IonRefresherContent />
            </IonRefresher>
            <Body
              group={group.val}
              loading={group.loading}
              refetchGroup={fetchGroup}
              openShareModal={showShareModal}
            />
          </>
        )
      }
      defaultBackLink="/groups"
      rightButton={
        !group.loading ? (
          <Menu
            onDismiss={handleDismiss}
            group={group.val}
            fetchGroup={fetchGroup}
            openShareModal={showShareModal}
          />
        ) : undefined
      }
    />
  );
}
