import Empty from "@components/empty";
import { showPrompt } from "@components/prompt";
import { IDB_TRUE } from "@data/idb";
import { IonButton, IonLabel, IonList, IonListHeader } from "@ionic/react";
import { Deck, IDeck } from "@models/deck";
import EventBus from "eventBus";
import L10n from "localization";
import Network from "network";
import React from "react";
import { ID } from "types/ID";
import StudyGroupDeckItem from "../decks/studyGroupDeckItem";
import { userIsOwner } from "./lib";
import { IGroup } from "./types";

function useIsLocalDeck(id: string): boolean {
  const [local, setLocal] = React.useState(false);

  React.useEffect(() => {
    function setStatus() {
      Deck.find(id)
        .then((deck) => {
          setLocal(deck?.local === IDB_TRUE);
        })
        .catch(() => {
          // Uh oh.
        });
    }

    setStatus();

    function handleStatusChange({ deckID }: { deckID: string }) {
      if (deckID === id) {
        setStatus();
      }
    }
    function handleDeleteOrRemove({ deckIDs }: { deckIDs: string[] }) {
      for (const deckID of deckIDs) {
        if (deckID === id) {
          setStatus();
          return;
        }
      }
    }

    EventBus.on("deckDownloadStatusChange", handleStatusChange);
    EventBus.on("deckDeleted", handleDeleteOrRemove);
    EventBus.on("deckRemoved", handleDeleteOrRemove);
    return () => {
      EventBus.off("deckDownloadStatusChange", handleStatusChange);
      EventBus.off("deckDeleted", handleDeleteOrRemove);
      EventBus.off("deckRemoved", handleDeleteOrRemove);
    };
  }, [id]);

  return local;
}

function SharedDeck(props: {
  deck: IDeck;
  onRemoveDeck?: (deckId: ID) => void;
}) {
  const { deck, onRemoveDeck } = props;
  const { id } = deck;

  const isLocal = useIsLocalDeck(id);

  return (
    <StudyGroupDeckItem
      key={id}
      deck={deck}
      name={deck.name}
      type={isLocal ? "shared-local" : "shared-remote"}
      href={`/decks/local/${id}`}
      onRemoveDeck={onRemoveDeck}
    />
  );
}

export default function SharedDecks(props: {
  group: IGroup;
  fetchGroup: () => void;
  openShareModal: () => void;
}) {
  const { group, fetchGroup, openShareModal } = props;
  const { decks } = group;

  const isOwner = userIsOwner(group);

  function handleRemoveDeck(deckId: string) {
    showPrompt({
      title: L10n.localize((s) => s.actions.confirm),
      prompt: L10n.localize((s) => s.groups.confirmRemoveDeck),
      promptType: "confirm",
      callback: async () => {
        try {
          await Network.fetch("DELETE", `/users/groups/${group.id}/decks/${deckId}`);
          fetchGroup();
        } catch {
          showPrompt({
            prompt: L10n.localize((s) => s.error.internal),
            promptType: "alert",
            title: L10n.localize((s) => s.general.attention),
          });
        }
      },
    });
  }

  return (
    <IonList>
      <IonListHeader>
        <IonLabel>{L10n.localize((s) => s.deck.shared)}</IonLabel>
      </IonListHeader>

      {(decks ?? []).length < 1 && (
        <>
          <Empty
            marginTop="2px"
            actionEl={
              <IonButton onClick={openShareModal} fill="clear">
                {L10n.localize((s) => s.sharing.shareDeck)}
              </IonButton>
            }
          />
        </>
      )}
      {(decks ?? []).map((deck) => (
        <SharedDeck
          key={deck.id}
          deck={deck}
          onRemoveDeck={isOwner ? handleRemoveDeck : undefined}
        />
      ))}
    </IonList>
  );
}
