import IonicApp from "ionicApp";
import React from "react";
import { createRoot } from "react-dom/client";

export default function mount() {
  const container = document.getElementById("root");
  if (!container) {
    // TODO: send sentry exception.
    return;
  }
  const root = createRoot(container);
  root.render(<IonicApp />);
}
