// To add a new field type,
// 1. Add a file in the types directory, exporting
//   a) an object of type IFieldType<TField, TFieldValue>,
//   b) that type, TField (named appropriately).
// 2. Add that exported IFieldType object to fieldTypeMap.
// 3. Add the type name for that type to fieldTypes.
// 4. Add that TField type to the IField union.
// 5. Satisfy all the requirements of conformance to IFieldType
import { audioFieldType, IAudioFieldValue, IFieldAudio } from "./types/audio";
import { codeFieldType, IFieldCode } from "./types/code";
import { IFieldImage, IImageFieldValue, imageFieldType } from "./types/image";
import { IFieldJapanese, IJapaneseFieldValue, japaneseFieldType } from "./types/japanese";
import { IFieldRichText, richTextFieldType } from "./types/richText";
import { IFieldChinese, chineseFieldType, IChineseFieldValue } from "./types/chinese";
import { IFieldTeX, texFieldType } from "./types/tex";
import { IFieldText, textFieldType } from "./types/text";
import { IFieldTTS, ITTSFieldValue, ttsFieldType } from "./types/tts";
import { IFieldType } from "./fieldType";

// Defines order of fields in field selector list.
export const fieldTypes = [
  // Comment is just to prevent this from formatting onto a single line.
  "text",
  "richtext",
  "tts",
  "audio",
  "image",
  "chinese",
  "japanese",
  "code",
  "tex",
] as const;

export type FieldType = (typeof fieldTypes)[number];

export type FieldMap = {
  chinese: IFieldChinese;
  code: IFieldCode;
  image: IFieldImage;
  text: IFieldText;
  richtext: IFieldRichText;
  tts: IFieldTTS;
  tex: IFieldTeX;
  audio: IFieldAudio;
  japanese: IFieldJapanese;
};

export type FieldValueMap = {
  chinese: IChineseFieldValue;
  code: string;
  image: IImageFieldValue;
  text: string;
  richtext: string;
  tts: ITTSFieldValue;
  tex: string;
  audio: IAudioFieldValue;
  japanese: IJapaneseFieldValue;
};

export type IField = FieldMap[FieldType];

export type FieldTypeMap = { [K in FieldType]: IFieldType<K> };
export const fieldTypeMap: FieldTypeMap = {
  chinese: chineseFieldType,
  code: codeFieldType,
  image: imageFieldType,
  text: textFieldType,
  richtext: richTextFieldType,
  tts: ttsFieldType,
  tex: texFieldType,
  audio: audioFieldType,
  japanese: japaneseFieldType,
};

export type DeckFieldMap = Record<string, IField>;

export const defaultFieldType: FieldType = "text";

export type FML = string;
