import { fieldTypeMap, IField } from "fields/fields";
import { IFieldValueEditorProps } from "fields/fieldType";
import React from "react";
import FieldValueEditorRichText from "./fieldValueEditorRichText";

function FieldValueEditor(props: IFieldValueEditorProps<IField>): JSX.Element {
  if (!props.field) {
    return <FieldValueEditorRichText {...props} field={undefined} />;
  }

  switch (props.field.type) {
    case "audio": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "chinese": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "code": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "image": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "japanese": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "richtext": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "tex": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "text": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
    case "tts": {
      const { field } = props;
      const component = fieldTypeMap[field.type].valueEditorComponent;
      return React.createElement(component, { ...props, field });
    }
  }
}

export default React.memo(FieldValueEditor);
