import { useIonAlert } from "@ionic/react";
import L10n from "localization";
import { useCallback } from "react";

export default function useConfirmCancellationDialog(handler: () => void) {
  const [presentConfirmCancel] = useIonAlert();
  const presentDialog = useCallback(() => {
    presentConfirmCancel({
      header: L10n.localize((s) => s.general.attention),
      message: L10n.localize((s) => s.general.confirmDiscardChange),
      buttons: [
        {
          role: "cancel",
          text: L10n.localize((s) => s.general.no),
        },
        {
          text: L10n.localize((s) => s.general.yes),
          handler,
        },
      ],
    });
  }, []);
  return presentDialog;
}
