import LoadingSpinner from "@components/loadingSpinner";
import { IonSpinner } from "@ionic/react";
import EventBus from "eventBus";
import L10n from "localization";
import React from "react";
import Style from "style";
import PDPLogger from "unlimited/logger";
import { IProduct } from "unlimited/types";
import CTA from "./cta";
import FinePrint from "./finePrint";
import Pitch from "./pitch";
import ProductButton from "./productButton";
import StripeQRDialog from "./stripeQRDialog";
import UnlimitedBadge from "./unlimitedBadge";

interface IProps<T extends IProduct> {
  reason?: string;
  description: string;
  onPurchase: (product: T) => void;
  showAppleTerms?: boolean;
  products: T[];
  productsLoading?: boolean;
  onForcePastAuthgate?: () => void;
  gateway?: "stripe" | "apple";
  userID: string;
  email: string;
  logger?: PDPLogger;
}

export function Products<T extends IProduct>(props: IProps<T>): JSX.Element {
  const {
    showAppleTerms,
    description,
    products,
    productsLoading,
    onPurchase,
    reason,
    gateway,
    email,
    userID,
    logger,
  } = props;

  const [loading, setLoading] = React.useState(false);
  const [waitingForOffsiteCheckout, setWaitingForOffsiteCheckout] = React.useState<T | null>(null);

  React.useEffect(() => {
    function handleIAPEvent(status: string) {
      if (status === "cancelled") {
        setLoading(false);
      }
    }

    EventBus.on("iap", handleIAPEvent);

    return () => {
      EventBus.off("iap", handleIAPEvent);
    };
  }, [setLoading, waitingForOffsiteCheckout]);

  function handlePurchase(product: T) {
    if (gateway === "stripe") {
      const extraLogData = {
        product: product.id,
        products,
      };
      logger?.log("purchase_initiated", extraLogData);

      setWaitingForOffsiteCheckout(product);
    } else {
      setLoading(true);
      onPurchase(product);
    }
  }

  const style: React.CSSProperties = {
    color: Style.colors.primaryFg,
  };

  if (waitingForOffsiteCheckout) {
    const product = waitingForOffsiteCheckout;
    return (
      <div style={style}>
        <StripeQRDialog
          email={email}
          userID={userID}
          product={product}
          onComplete={() => onPurchase(product)}
          onCancel={() => setWaitingForOffsiteCheckout(null)}
          logger={logger}
        />
      </div>
    );
  }

  if (!gateway) {
    // No way to purchase.
    return (
      <div style={style}>
        <CTA />
        <Pitch reason={reason} description={description} />
        <h4 style={{ userSelect: "text" }}>{L10n.localize((s) => s.account.upgradePitch)}</h4>
        {loading && <LoadingSpinner />}
      </div>
    );
  }

  return (
    <div style={style}>
      <UnlimitedBadge />
      {description ? <Pitch reason={reason} description={description} /> : undefined}
      <div style={{ marginTop: 36 }}>
        {products.map((product: T) => (
          <ProductButton
            key={product.id}
            product={product}
            loading={loading}
            onPurchase={handlePurchase}
          />
        ))}
        {productsLoading ? (
          <div style={{ textAlign: "center" }}>
            <IonSpinner />
          </div>
        ) : undefined}
      </div>
      <FinePrint showAppleTerms={showAppleTerms} />
      {loading && <LoadingSpinner />}
    </div>
  );
}
