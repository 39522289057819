const grades = ["NEW", "AAA", "AA", "A", "B", "C", "D", "E", "F"] as const;
export type Grade = (typeof grades)[number];

export function isGrade(s: string): s is Grade {
  return grades.includes(s as any);
}

export function Grade(priority: number): Grade | null {
  if (Number.isNaN(priority)) {
    return "NEW";
  }
  if (priority < 0) {
    return null;
  }
  if (priority < 0.02) {
    return "AAA";
  }
  if (priority < 0.04) {
    return "AA";
  }
  if (priority < 0.1) {
    return "A";
  }
  if (priority < 0.2) {
    return "B";
  }
  if (priority < 0.3) {
    return "C";
  }
  if (priority < 0.4) {
    return "D";
  }
  if (priority < 0.5) {
    return "E";
  }
  return "F";
}
