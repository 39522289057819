import { IQueuedBlob } from "@data/idb";
import { ID } from "types/ID";
import DownloadPool from "./downloadPool";

export async function downloadBlobsWithMaxConcurrency(
  queue: IQueuedBlob[],
  concurrency: number,
  deviceID: ID,
  deviceToken: string,
  tickListener?: () => void,
): Promise<void> {
  if (queue.length < 1) {
    return;
  }

  const pool = new DownloadPool(queue, concurrency, deviceID, deviceToken, tickListener);
  return pool.start();
}

export async function downloadAndSaveBlobInWorker(
  id: ID,
  deckID: ID,
  deviceID: ID,
  deviceToken: string,
): Promise<void> {
  return downloadBlobsWithMaxConcurrency([{ id, deckID }], 1, deviceID, deviceToken);
}
