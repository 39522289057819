import { logSearchEvent } from "analytics/search";
import { AsyncVal } from "hooks/util/useAsyncValState";
import Network from "network";
import { useEffect, useState } from "react";

export interface IDeckSearchResult {
  score: number;
  id: string;
  name: string;
  description: string;
  num_cards: number;
  tags: string[];
  uri: string;
  modified_at: Date;
  created_at: Date;
  layout_id: string;
  source?: string;
}

export interface ISearchResultSourceSansDecks {
  name: string;
  attribution: string;
  icon_uri: string;
  num_results: number;
}

export interface ISearchResultSource extends ISearchResultSourceSansDecks {
  decks: IDeckSearchResult[];
}

export interface ISearchResponse {
  sources: ISearchResultSource[];
}

export interface ISearchResult {
  decks: IDeckSearchResult[];
  sources: ISearchResultSourceSansDecks[];
}

export function useSearchResults(q: string): AsyncVal<ISearchResult> {
  const [results, setResults] = useState<AsyncVal<ISearchResult>>({
    loading: true,
    val: {
      decks: [],
      sources: [],
    },
    error: undefined,
  });

  useEffect(() => {
    const aborter = new AbortController();

    const page = 0; // TODO: implement paging.

    async function fetchResults(q: string, page: number): Promise<ISearchResult> {
      setResults({ loading: true, error: undefined, val: undefined });
      const resp = await Network.fetch<ISearchResponse>(
        "GET",
        "/decks/search",
        { query: q, page },
        aborter,
      );
      const { sources } = resp;

      let decks: IDeckSearchResult[] = [];
      for (const source of sources) {
        for (const deck of source.decks) {
          deck.source = source.name; // Assign the source name to each deck
        }
        decks = decks.concat(source.decks);
      }

      decks.sort((d1, d2) => d2.score - d1.score); // Sort the decks by their score

      const sourceList: ISearchResultSourceSansDecks[] = sources.map(
        ({ name, attribution, icon_uri, num_results }) => ({
          name,
          attribution,
          icon_uri,
          num_results,
        }),
      );

      logSearchEvent("received_results", {
        query: q,
        num_results: decks.length,
      });

      return { decks, sources: sourceList };
    }

    if (q.length > 0) {
      fetchResults(q, page)
        .then((val) => {
          setResults({ loading: false, error: undefined, val });
        })
        .catch((err) => {
          setResults({ loading: false, error: err, val: undefined });
        });
    }

    return () => {
      setResults({ loading: true, error: undefined, val: undefined });
      aborter.abort();
    };
  }, [q]);

  return results;
}
