import InlineLoadingSpinner from "components/inlineLoadingSpinner";
import React from "react";

export default function LoadingSpinner(): JSX.Element {
  const outerStyle = {
    alignContent: "center" as const,
    alignItems: "center" as const,
    display: "flex",
    height: "100%",
    justifyContent: "center" as const,
    left: "0",
    overflow: "hidden",
    position: "fixed",
    top: "49px",
    width: "100%",
    zIndex: 9999,
  } as React.CSSProperties;

  return (
    <div style={outerStyle}>
      <InlineLoadingSpinner margin="initial" />
    </div>
  );
}
