import { missingBlobTag } from "@cardRendering/missingBlob";
import { ITypedBlobURL } from "@data/idb";
import { DeckFields, fieldMap } from "@models/deck";
import { Knol } from "@models/knol";
import { IKnolValueBlob } from "@operations/knol";
import logEvent from "analytics";
import { blobHash } from "blobs/lib";
import Value, { BLOB_TYPE_ERROR_LOADING, IRenderingContext } from "cardRendering/value";
import BlobStore from "data/idb/blobStore";
import { IField } from "fields/fields";
import Lib, { sanitizeKnolValue } from "lib";
import L10n from "localization";
import ReactDOMServer from "react-dom/server";
import Style from "style";
import { ID } from "types/ID";

export type CardBlobs = Record<string, IKnolValueBlob>;

export async function preloadBlobURLsInPlace(
  value: string,
  urls: Record<ID, ITypedBlobURL | null>,
): Promise<void> {
  const blobIDs = Knol.blobIdsPresentInString(value);
  const promises = blobIDs.map(async (id) => {
    try {
      const res = await BlobStore.getBlobURL({ id });
      if (res) {
        urls[id] = res;
      }
    } catch (err) {
      logEvent("blob_preload_failed", {
        err: err instanceof Error ? `${err.name}: ${err.message}` : "???",
        errStack: err instanceof Error ? err.stack : undefined,
        blobID: id,
      });
      urls[id] = { type: BLOB_TYPE_ERROR_LOADING, url: "" };
    }
  });
  await Promise.all(promises);
}

export function renderValueToStaticMarkup(
  value: string,
  values: Record<string, string>,
  blobs: CardBlobs,
  blobIdToUrl: Record<ID, ITypedBlobURL>,
  field: IField | undefined,
  fieldMap: Record<string, IField>,
): string {
  // NOTE: it is imperative that each blob that is rendered by this mechanism has a 'data-blob-id' property set, otherwise, when editing, that blob will be turned into static markup, a change to the the editor's content will send it up the chain statically (via @handleChange), and the reference to the blob itself will be lost, resulting in a "broken link" representation of the blob when it is saved.

  if (value !== "") {
    const valueEl = Value({
      value,
      values,
      blobIdToObjectUrl: blobIdToUrl,
      options: {
        disableTouchEvents: true,
        persistDownloadedBlobs: false,
      },
      field: field?.type === "richtext" ? undefined : field, // HACK to use StandardValue for RichText fields to get blob replacement working (I don't know why it wasn't working the other way).
      fieldMap,
    });
    const context: IRenderingContext = {};
    if (typeof valueEl === "function") {
      const el = valueEl(context);
      const markup = ReactDOMServer.renderToStaticMarkup(el);
      return markup;
    }
  }
  return "";
}

export function replaceBlobElementsWithPlaceholders(html: string): string {
  const parser = new DOMParser();
  const parsed = parser.parseFromString(html, "text/html");

  const blobTags = Array.from(new Set(["img", "audio", missingBlobTag]));

  blobTags.forEach((tag) => {
    const els = parsed.getElementsByTagName(tag);
    Array.from(els).forEach((el) => {
      if (el instanceof HTMLElement) {
        const blobId = el.dataset?.blobId;
        if (blobId && el.parentNode) {
          const replacement = document.createTextNode(`{{blob ${blobId}}}`);
          el.parentNode.replaceChild(replacement, el);
        }
      }
    });
  });

  return sanitizeKnolValue(parsed.body.innerHTML);
}

export async function replaceBlobPlaceholdersWithElementsInPlace(
  values: Record<string, string>,
  blobs: CardBlobs,
  blobIdToUrl: Record<ID, ITypedBlobURL>,
  fields: DeckFields | undefined,
): Promise<unknown> {
  const fmap = fieldMap(fields);
  const vmap = values;

  const promises = Object.entries(values).map(async ([key, val]) => {
    // renderValueToStaticMarkup will escape HTML entities like apostrophe, so just skip it unless this is a rich text field.
    const field = fmap[key];
    const shouldRenderToStaticMarkup = field?.type === "richtext" || field?.type === undefined;
    if (!shouldRenderToStaticMarkup) {
      return;
    }

    try {
      await preloadBlobURLsInPlace(val, blobIdToUrl);
    } catch (err) {
      console.log("Failed to preload blobs", err);
    }

    values[key] = renderValueToStaticMarkup(val, vmap, blobs, blobIdToUrl, field, fmap);
  });
  return Promise.all(promises);
}

export const isSame = (o1: Record<string, string>, o2: Record<string, string>): boolean => {
  if (!o1 && !o2) {
    return true;
  }
  if (!o1 || !o2) {
    return false;
  }
  const o1keys = Object.keys(o1);
  const o2keys = Object.keys(o2);
  if (o1keys.length !== o2keys.length) {
    return false;
  }
  for (const k of o1keys) {
    if (o1[k] !== o2[k]) {
      return false;
    }
  }
  return true;
};

export function mimeTypeFromDataUrl(url: string): string | undefined {
  return url.match(/^data:(.*?);/)?.[1];
}

export function base64DataFromDataUrl(url: string): string {
  return url.replace(/^data:.*?;base64,/, "");
}

function img2canvas(img: HTMLImageElement): HTMLCanvasElement {
  // NOTE: "natural" isTheaterModeensions are used because the image's size is
  // restricted for display, but we want the full-size data.
  const [w, h] = Array.from([img.naturalWidth, img.naturalHeight]);
  const canvas = document.createElement("canvas");
  canvas.width = w;
  canvas.height = h;
  const context = canvas.getContext("2d");
  if (!context) {
    throw "Failed to get canvas context to extract image data.";
  }
  context.drawImage(img, 0, 0);
  return canvas;
}

function isDataURL(src: string): boolean {
  return src.search(/^data/) !== -1;
}

async function registerLoadedImage(
  img: HTMLImageElement,
  registerBlob: (id: string, blob: Blob) => Promise<string>,
) {
  const { src } = img;

  if (!img.src) {
    // Nothing to register.
    return;
  }

  try {
    let dataUrl;
    if (isDataURL(src)) {
      dataUrl = src;
    } else {
      // Extract image data using canvas.

      // TODO: skip the canvas copying step if the image was initially
      // specified using a data URL.
      const canvas = img2canvas(img);
      const url = canvas.toDataURL();
      canvas.remove();
      dataUrl = url;
    }

    // TODO: extract data as a Blob, which can be converted directly to an
    // Object URL, then convert to Base 64 at time of serialization.
    // See https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob.

    // Register the blob at the top level.
    const mimeType = mimeTypeFromDataUrl(dataUrl);
    const base64 = base64DataFromDataUrl(dataUrl);
    if (!mimeType) {
      throw "Failed to parse image mime type.";
    }
    const blob = Lib.base64ToBlob(base64, mimeType, 512);
    const blobId = await blobHash(blob);
    const url = await registerBlob(blobId, blob);

    img.style.opacity = "1.0";
    img.dataset.blobLoading = "false";
    img.dataset.blobId = blobId;

    // Prevent infinite loop caused by image reloading when src changed.
    img.onload = null;

    // Use the blob URL, so the browser can garbage-collect the source image.
    img.src = url;
  } catch (e) {
    // This can happen if not running in a native app (i.e. web client), and
    // we get a DOMSecurityException.

    // TODO: see if this security exception can be avoided with Content
    // Security Policy (http://www.html5rocks.com/en/tutorials/security/content-security-policy/#source-whitelists).

    // Prevent image from showing, so user doesn't get disappointed when they
    // logout and log back in, and their image is missing.
    img.parentNode?.removeChild(img);

    throw e;
  }
}

async function convertNonBlobImageToBlob(
  img: HTMLImageElement,
  registerBlob: (id: string, blob: Blob) => Promise<string>,
): Promise<void> {
  const alreadyRegistered = img.dataset.blobId !== undefined;
  const isRemoteImage = img.src.startsWith("http");
  if (alreadyRegistered || isRemoteImage) {
    return;
  }

  // Restrict image isTheaterModeensions to match final output.
  img.style.maxWidth = Style.imageBlobMaxWidth;
  img.style.maxHeight = Style.imageBlobMaxHeight;

  img.style.opacity = `${Style.imageBlobLoadingOpacity}`;
  img.dataset.blobLoading = "true";

  if (img.complete === true || isDataURL(img.src)) {
    await registerLoadedImage(img, registerBlob);
  } else {
    return new Promise((resolve, reject) => {
      img.onload = () => {
        registerLoadedImage(img, registerBlob).then(resolve).catch(reject);
      };
    });
  }
}

export async function convertNonBlobImagesToBlobs(
  imgs: HTMLImageElement[],
  registerBlob: (id: string, blob: Blob) => Promise<string>,
): Promise<void> {
  const promises = Array.from(imgs).map(async (img) => {
    try {
      await convertNonBlobImageToBlob(img, registerBlob);
    } catch (err) {
      alert(L10n.localize((s) => s.errors.failedToLoadImage));
    }
  });
  await Promise.all(promises);
}
