import { IonListHeader, IonLabel } from "@ionic/react";
import React from "react";
import L10n from "../../localization";
import { sortMembers } from "./lib";
import MemberView from "./memberView";
import { IGroup, IMember } from "./types";
import { User } from "@models/user";
import Empty from "@components/empty";

export default function Members(props: {
  group: IGroup;
  onKickMember: (member: IMember) => void;
}) {
  const { group, onKickMember } = props;
  const { members } = group;

  const sortedMembers = sortMembers(members ?? []);

  const justMe = sortedMembers.length === 1 && sortedMembers[0].email === User.email();

  return (
    <>
      <IonListHeader>
        <IonLabel>{L10n.localize((s) => s.groups.members)}</IonLabel>
      </IonListHeader>
      {justMe ? (
        <Empty text={L10n.localize((s) => s.groups.noOtherMembers)} />
      ) : (
        sortedMembers.map((m, idx) => (
          <MemberView
            key={m.id}
            member={m}
            isOwner={m.role === "creator"}
            isLast={idx === sortedMembers.length - 1}
            onKickMember={onKickMember}
          />
        ))
      )}
    </>
  );
}
